import React, {useEffect, useState} from 'react';
import BaseContent from '../../components/Common/BaseContent';
import {Alert, Badge, Button, Col, Container, Nav, NavItem, Row} from "reactstrap";
import Form from "./components/Form";
import {SURVEY_DEFAULTS} from "./components/consts";
import classnames from "classnames";
import {connect} from "react-redux";
import {clearSurveyPost, createSurvey, deleteSurvey, getSurvey, updateSurvey} from "../../store/survey/actions";
import {Redirect, useParams} from "react-router";
import toastr from "toastr";
import Responses from "./responses";
import DeleteDialog from "../Containers/DeleteDialog";
import {getAxiosDefaultConfig} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
import {SendDialog} from "./components/SendDialog/SendDialog";
import {Link} from "react-router-dom";
import Activity from "./Activity";
import moment from "moment";

const SurveyEditNew = (props) => {
  const {uuid,page} = useParams()

  const {context, getSurvey, createSurvey, updateSurvey, clearSurveyPost} = props;

  const isLoadingCreate = context.Survey.create.isLoading
  const resultCreate = context.Survey.create.successResult

  const isLoadingDelete = context.Survey.delete.isLoading

  const {result, isLoadingGet} = context.Survey.get
  // eslint-disable-next-line
  const isLoadingUpdate =  context.Survey.update.isLoading

  const [surveyData, setSurveyData] = useState()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleted, setDeleted] = useState()
  const [deleteLoading, setDeleteLoading] = useState()
  const [openSend, setOpenSend] = useState(false)
  const [changed, setChanged] = useState(false)
  const [totalResponses, setTotalResponses] = useState(surveyData&&surveyData.responseCount)

  const isLoading = isLoadingCreate || isLoadingGet || !surveyData || isLoadingUpdate || isLoadingDelete || deleteLoading

  const [breadcrumbs,setBreadcrumbs] = useState(
    [
      {title: 'Surveys', link: '/surveys'},
      {title: 'New', link: '/surveys/new'}
    ]
  )

  const [activeTab, setActiveTab] = useState(page==='responses'?2:1);

  useEffect(()=>{
    switch (page) {
      case 'responses': {
        if (page==='new') {
          return false
        }
        setActiveTab(2)
        break;
      }
      case 'activity': {
        setActiveTab(3)
        break;
      }
      default:
        setActiveTab(1)
    }
  },[page])

  // Updates breadcrumbs to show /edit or /new links
  const updateBreadcrumbs = () => {
    let newBreadcrumbs
    if (uuid) {
      newBreadcrumbs = [
        {title: 'Surveys', link: '/surveys'}
      ]
    } else {
      newBreadcrumbs = [
        {title: 'Surveys', link: '/surveys'},
        {title: 'New', link: '/surveys/new'}
      ]
    }
    if (surveyData&&surveyData.label) {
      newBreadcrumbs.push({title: `${changed?'*':''}${surveyData.label}`, link: uuid?`/surveys/edit/${uuid}`:'#'})
    }
    setBreadcrumbs(newBreadcrumbs)
  }

  // Load defaults or load from BE
  useEffect(()=>{
    if (uuid) {
      getSurvey(uuid)
    } else {
      let tomorrow = moment().add(1,'day');
      setChanged(true)
      setSurveyData({
        ...SURVEY_DEFAULTS,
        sections: [
          {
            id:`section-${new Date().valueOf()}`,
            title: '',
            description: '',
            questions: []
          }
        ],
        closeAt: tomorrow
      })
    }
    // eslint-disable-next-line
  },[uuid])

  useEffect(()=>{

    return ()=>{
      clearSurveyPost()
    }
    // eslint-disable-next-line
  },[])

  // Load from BE update
  useEffect(()=>{
    if (result&&result.uuid&&uuid) {
      setSurveyData({
        ...result,
        openAt: result.openAt+'Z',
        closeAt: result.closeAt+'Z'
      })
      setTotalResponses(result.responseCount)
    }
    // eslint-disable-next-line
  },[result])

  // Update breadcrumbs appropriately
  useEffect(()=>{
    updateBreadcrumbs()
    // eslint-disable-next-line
  },[surveyData])

  useEffect(()=>{
    updateBreadcrumbs()
    // eslint-disable-next-line
  },[changed])

  const setFields = (newFields) => {
    const currentData = {...surveyData}
    currentData.questions = newFields
    setSurveyData(currentData)
    setChanged(true)
    // console.log('set fields')
  }

  const setSurvey = (value) => {
    setSurveyData(value)
    setChanged(true)
  }

  const saveSurvey = () => {
    const payLoad = {
      ...surveyData,
      lockedMessage: surveyData.lockedMessage!==''?surveyData.lockedMessage:`This survey is not accepting responses.`
    }
    if (!uuid) {
      createSurvey(payLoad)
    } else {
      updateSurvey({...payLoad,id:uuid})
      setChanged(false)
    }
  }

  const handleDelete = () => {

    setDeleteLoading(true)
    axios.delete(`${API_URL}/addons/srv/surveys/${uuid}`,getAxiosDefaultConfig())
      .then((response) => {
        setDeleteLoading(false);
        setDeleted(true)
        toastr.success(`Successfully deleted survey`);
      })
      .catch(error => {
        setDeleteLoading(false);
        toastr.error(error.message)
      })
  }

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  useEffect(()=>{
    if (resultCreate&&resultCreate.uuid) {
      setSurveyData({...resultCreate})
      clearSurveyPost()
      toastr.success(`Successfully saved survey`);
    }
    // console.log(context.Survey)
    // eslint-disable-next-line
  },[resultCreate])


  const closeDate = new Date(surveyData&&surveyData.closeAt)
  const openDate = new Date(surveyData&&surveyData.openAt)
  const nowDate = new Date()
  const dateExpired = !(closeDate > nowDate)
  const willOpen = (openDate>nowDate)

  return (
    <BaseContent
      // isLoading={isLoading}
      breadcrumbs={breadcrumbs}
      renderActions={()=><Button color={'success'} onClick={()=>setOpenSend(true)}> <i className='bx bx-send'></i> &nbsp; Share </Button>}
    >
      {<SendDialog open={openSend} handleClose={()=>setOpenSend(false)} surveyData={surveyData} key={surveyData} />}
      {isLoading&&<div className='custom-loading-overlay'> </div>}
      {surveyData&&surveyData.uuid&&!uuid&&<Redirect to={`/surveys/edit/${surveyData.uuid}`} />}
      {deleted&&<Redirect to={`/surveys`} />}
      <DeleteDialog open={openDeleteDialog} handleClose={closeDeleteDialog} handleDelete={handleDelete} />

      {surveyData&&surveyData.locked && uuid && <>
        <Row>
          <Col xs={12}>
            <Alert color="warning">
              <Row>
                <Col>
                  This survey is not accepting responses.{activeTab!==2?<> To allow, visit <Link to={`/surveys/edit/${uuid}/responses`}> the Responses tab. </Link> </>:''}
                </Col>
              </Row>
            </Alert>
          </Col>
        </Row>
        </>
      }

      {surveyData && dateExpired && uuid && !surveyData.locked && <>
        <Row>
          <Col xs={12}>
            <Alert color="danger">
              <Row>
                <Col>
                  This survey closed at {moment(closeDate).format("DD/MM/YYYY, h:mm a")}
                </Col>
              </Row>
            </Alert>
          </Col>
        </Row>
      </>
      }

      {surveyData && willOpen && uuid && !surveyData.locked && <>
        <Row>
          <Col xs={12}>
            <Alert color="success">
              <Row>
                <Col>
                  This survey will open at {moment(surveyData.openAt).format("DD/MM/YYYY, h:mm a")}
                </Col>
              </Row>
            </Alert>
          </Col>
        </Row>
      </>
      }

      <Row className={'justify-content-center survey-nav'} style={{marginBottom: 16}}>
        <Col >
          <div>
            <Nav tabs style={{display:'block'}}>
              <NavItem>
                <Link to={`/surveys/edit/${uuid}/survey`} className={classnames({ active: activeTab === 1, 'nav-link':true })} > Survey </Link>
              </NavItem>
              <NavItem >
                <Link to={!!uuid&&`/surveys/edit/${uuid}/responses`} className={classnames({ active: activeTab === 2, 'nav-link':true, 'link-disabled': !uuid})} > Responses {totalResponses!==0&&<Badge color="secondary">{totalResponses}</Badge>}</Link>
              </NavItem>
              <NavItem >
                <Link to={!!uuid&&'new'&&`/surveys/edit/${uuid}/activity`} className={classnames({ active: activeTab === 3, 'nav-link':true, 'link-disabled': !uuid})} > Activity </Link>
              </NavItem>
            </Nav>
          </div>
        </Col>
      </Row>

      <Container style={{paddingTop: 30, minHeight: 640}}>

        {activeTab===1&&<form aria-disabled={isLoading}>
          {surveyData&&<Form
            fields={surveyData.questions}
            setFields={setFields}
            label={surveyData.label}
            survey={surveyData}
            setSurvey={setSurvey}
            disabled={isLoading}
          />}
        </form>}

        {activeTab===2&&<>
          <Responses surveyData={surveyData} key={surveyData} setSurvey={setSurvey} setTotalResponses={(v)=>setTotalResponses(v)} />
        </>}

        {activeTab===3&&<Activity />}

      </Container>
      {uuid&&<Button
        title={'Delete survey'}
        color={'danger'}
        onClick={()=>setOpenDeleteDialog(true)}
        className={
          'btn-radius d-flex align-items-center justify-content-center shadow-lg'
        }
        style={{
          position: 'fixed',
          margin: 16,
          bottom: 90,
          right: 16,
          borderRadius: 50,
          width: 40,
          height: 40,
          zIndex: 1
        }}
      >
        <i className='bx bxs-trash font-size-16'></i>
      </Button>}
      <Button
        title={!changed?'No changes to save':'Save changes'}
        color={'primary'}
        onClick={saveSurvey}
        className={
          'btn-radius d-flex align-items-center justify-content-center shadow-md'
        }
        style={{
          position: 'fixed',
          margin: 16,
          bottom: 16,
          right: 16,
          borderRadius: 50,
          width: 60,
          height: 60,
          zIndex: 1
        }}
        disabled={!changed}
      >
        <i className='bx bxs-save font-size-24'></i>
      </Button>
    </BaseContent>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getSurvey,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  clearSurveyPost
})(SurveyEditNew);
