import React, {useCallback, useEffect, useState} from "react";
import
  {
    Card, Col, Form, FormGroup, Input, Label, Row,
    //Button, Modal, ModalHeader, ModalBody, ModalFooter
  } from "reactstrap";
//import {Link} from "react-router-dom";
import {Progress} from "reactstrap";
//Dropzone
import Dropzone from "react-dropzone";
import {connect} from "react-redux";
import axios from "axios";
import {formatBytes, getAxiosDefaultConfig} from "../../utils";
import {ApiEndpoint} from "../../store/core/endpoint";
import {EntityType} from "../../store/core/entityType";
import {withRouter} from "react-router";
import BaseDialog from "../../components/Common/BaseDialog";
import Select from "react-select";
import {Schema} from "../../store/core/schema";
import {useAcceptedTypes, useForm} from "../../helpers/hooks";
//import { Box } from "@material-ui/core";

function CreateDataset(props) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const [form, handleChange, updateInitial] = useForm(Schema[EntityType.DataStream]);
  const parentContainer = props.context.Container.get.result;
  const accepted = useAcceptedTypes(parentContainer, props.context, props.open);
  const [uploadProcess, setUploadProcess] = useState(null);
  const [progressEventStatus, setProgressEventStatus] = useState([]);
  const [uploadState, setUploadState] = useState([]);

  // componentWillReceiveProps(nextProps, nextContext) {
  //   if (nextProps.open) {
  //     this.setState({ ...Schema[EntityType.DataStream] });
  //   }
  // }

  useEffect(() => {
    updateInitial(Schema[EntityType.DataStream]);
    setError(null);
    setSelectedFiles([]);
  }, [props.open, updateInitial]);

  const handleAcceptedFiles = useCallback((files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setSelectedFiles(files);
  }, []);

  const [upLoadPercentage, setUpLoadPercentage] = useState([]);
  const [cancel, setCancel] = useState("");

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setCancel(source);
  }, []);

  const fileUpload = async () => {
    setUploadProcess(true);
    const url = ApiEndpoint[EntityType.DataStream];
    const {label,type} = form;
    const params = `?to=${props.thisContainerUuid}`;
    const finalUrl = url + params;
    const axiosConfig = getAxiosDefaultConfig();

    // formData.append("file", selectedFiles[0]);
    for (let i = 0; i < selectedFiles.length; i++) {
      const formData = new FormData();
      const data = {type, label: selectedFiles.length > 1 ? selectedFiles[i].name : label ? label : selectedFiles[i].name, properties: []};
      formData.append("file", selectedFiles[i]);

      formData.append(
        "data",
        new Blob([JSON.stringify(data)], {
          type: "application/json",
          label: selectedFiles[i].name,
        }),
      );
      try {
        setBusy(true);
        axios
          .post(finalUrl, formData, {
            ...axiosConfig,
            cancelToken: cancel.token,
            onUploadProgress: (progressEvent) => {
              let newPercent = upLoadPercentage;
              newPercent[i] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUpLoadPercentage(newPercent);

              let newStatus = [...progressEventStatus];
              newStatus[i] = {id: i, loaded: progressEvent.loaded, total: progressEvent.total};
              setProgressEventStatus(newStatus);

              let newUploadState = uploadState;
              newUploadState[i] = true;
              setUploadState(newUploadState);
            },
          })
          .then((res) => {
            let newUploadState = uploadState;
            newUploadState[i] = false;
            setUploadState(newUploadState);
            // console.log(res);
            // console.log(newUploadState);
            const isTrue = newUploadState.some((item) => item === true);
            if (!isTrue) {
              setUpLoadPercentage([]);
              setUploadProcess(false);
              setProgressEventStatus([]);
              setBusy(false);
              setError(null);
              props.refreshContainer();
              props.handleClose();
              setTimeout(() => {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                setCancel(source);
              }, 2000);
            }
          });
      } catch (e) {
        setBusy(false);
        setError(e.message || "Something went wrong");
        setUploadProcess(false);
        props.refreshContainer();
      }
    }
    setBusy(false);
    setError(null);
    props.refreshContainer();
  };

  const cancelUpload = () => {
    cancel.cancel("Operation canceled by the user.");
    setUpLoadPercentage([]);
    setUploadProcess(false);
    setProgressEventStatus([]);
    setBusy(false);
    setError(null);
    props.refreshContainer();
    props.handleClose();
    setTimeout(() => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      setCancel(source);
    }, 2000);
  };

  // useEffect(() => {
  //   console.log(progressEventStatus);
  //     console.log(uploadState);
  // }, [progressEventStatus, uploadState, upLoadPercentage]);

  const renderDropZone = () => {
    const typeOptions = accepted.dataStreamTypes.map((type) => ({
      label: type.name,
      value: type.code,
    }));
    const selectedType = typeOptions.find((x) => x.value === form.type);
    return (
      <React.Fragment>
        <div className="kyc-doc-verification mb-3">
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Label</Label>
                  <Input type="text" className="form-control" placeholder="Enter label" value={form.label} onChange={handleChange("label")} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Type</Label>
                  <Select placeholder="Select type" value={selectedType} options={typeOptions} onChange={handleChange("type")} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <Dropzone
            // accept="application/zip"
            onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
            multiple
          >
            {({getRootProps, getInputProps}) => (
              <div className="dropzone">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                  </div>
                  <h3>Drop files here or click to upload.</h3>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                      </Col>
                      <Col>
                        <p className="mb-0">
                          {f.name} <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      {uploadProcess && <Col className="col-auto">{upLoadPercentage[i]}% completed</Col>}
                    </Row>
                    {uploadProcess && (
                      <Progress style={{height: "20px", margin: "2px"}} value={upLoadPercentage[i]}>
                        {upLoadPercentage[i]}%
                      </Progress>
                    )}
                  </div>
                </Card>
              );
            })}

            {/* {uploadProcess && (
              <Modal
                isOpen={uploadProcess}
                toggle={() => {
                  setUploadProcess({modal: !uploadProcess});
                }}
                role="dialog"
                autoFocus={true}
                size="lg"
                centered={true}
              >
                <ModalHeader
                  style={{background: "#536a7d"}}
                  toggle={() => {
                    setUploadProcess(false);
                    cancelUpload();
                  }}
                >
                  {" "}
                  <span style={{color: "#fff", fontSize: "20px", display: "inline-block"}}>Uploading files...</span>
                </ModalHeader>
                <ModalBody style={{border: "none", borderRadius: "0px"}}>
                  <span style={{marginBottom: "30px", fontSize: "17px", display: "block"}}>Please wait until the selected files are uploaded to the server.</span>
                  <br />
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                            </Col>
                            <Col>
                              <p className="mb-0">
                                {f.name} <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                            <Col className="col-auto">
                               {progressEventStatus[i]?.total <= 1000000
                                ? parseFloat((progressEventStatus[i]?.loaded / 1000).toFixed(2)) + " of " + parseFloat((progressEventStatus[i]?.total / 1000).toFixed(2)) + " KΒ "
                                : progressEventStatus[i]?.total >= 1000000
                                ? parseFloat((progressEventStatus[i]?.loaded / 1000000).toFixed(2)) + " of " + parseFloat((progressEventStatus[i]?.total / 1000000).toFixed(2)) + " MΒ "
                                : ""}
                              {upLoadPercentage[i]}% completed
                            </Col>
                          </Row>
                           <Progress style={{height: "20px", margin: "2px"}} value={Math.round((progressEventStatus[i]?.loaded * 100) / progressEventStatus[i]?.total)}>
                            {Math.round((progressEventStatus[i]?.loaded * 100) / progressEventStatus[i]?.total)}%
                          </Progress> }
                          <Progress style={{height: "20px", margin: "2px"}} value={upLoadPercentage[i]}>
                            {upLoadPercentage[i]}%
                          </Progress>
                        </div>
                      </Card>
                    );
                  })}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      setUploadProcess(false);
                      cancelUpload();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            )} */}
          </div>
        </div>
      </React.Fragment>
    );
  };

  // const isValid = form.label && form.type && selectedFiles[0];
  const isValid = form.type && selectedFiles[0] !== undefined;
  return (
    <BaseDialog
      title={"Upload datastream"}
      isValid={isValid}
      size={"lg"}
      busy={busy}
      errorMessage={error}
      dismissError={() => setError(null)}
      handleClose={props.handleClose}
      open={props.open}
      primaryAction={fileUpload}
      setUploadProcess={setUploadProcess}
      uploadProcess={uploadProcess}
      cancelUpload={cancelUpload}
    >
      {renderDropZone()}
    </BaseDialog>
  );
}

const mapStatetoProps = (state) => {
  return {
    context: state,
  };
};

export default withRouter(connect(mapStatetoProps, null)(CreateDataset));
