import React from 'react'

const PermissionItem = (props) => {
  const {handleChange,checked,permission} = props
return <tr onClick={handleChange} style={{cursor:'pointer'}}>
  <th>
    <input type={'checkbox'} checked={checked} onChange={handleChange}/>
  </th>
  <td>
    <h5 className="font-size-12 mb-0">{permission.name}</h5>
  </td>
</tr>
}

export default PermissionItem