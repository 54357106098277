import {Row} from "reactstrap";
import React from "react";
import BusyIndicator from "../../../../components/Common/BusyIndicator";

const Loader = () =>{
  return <Row style={{minHeight:250, position: 'relative'}} className='d-flex justify-content-center align-items-center align-content-center'>
    <div className='d-flex'>
      <BusyIndicator busy={true}/>
    </div>
  </Row>
}

export default Loader
