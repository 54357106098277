import React, {useState} from 'react';
import {Row, Col, Alert, Button, Container} from 'reactstrap';
import Breadcrumbs from './Breadcrumb';
import BusyIndicator from './BusyIndicator';
import ErrorBoundary from './ErrorBoundary';

const ContainerCreateButton = (props) => {
  const [openMenu, setOpenMenu] = useState(false)
  return <span onBlur={() => setOpenMenu(!openMenu)}>
              <Row className={`createMenuShow ${openMenu ? '' : 'createMenuHide'}`}
                   style={{bottom: props.isRoot ? '-30px' : '20px'}}>
                {!props.isRoot && <Col className={'col-lg-12 createMenuItem'} onClick={props.handeleUploadDataStream}>
                  <i className='bx bx-cloud-upload font-size-14 mr-2'/> Upload DataStream
                </Col>
                }
                <Col className={'col-lg-12 createMenuItem'} onClick={props.handleCreate}>
                 <i className='bx bx-folder-plus font-size-14 mr-2'/> Create {props.entityType}
                </Col>
              </Row>
            <Button
              disabled={props.isLoading}
              color={'primary'}
              onClick={() => setOpenMenu(!openMenu)}
              className={
                'btn-radius d-flex align-items-center justify-content-center shadow-lg'
              }
              style={{
                position: 'fixed',
                margin: 16,
                bottom: 0,
                right: 0,
                borderRadius: 50,
                width: 60,
                height: 60,
              }}
            >
              <i className="bx bx-plus font-size-24"/>
            </Button>
            </span>
}

const BaseContent = (props) => {
  const containerRoute = window.location.pathname.includes('containers')

  const {
    isLoading = false,
    title = '',
    breadcrumbs = [],
    handleCreate,
    renderActions,
    error = null,
    paths,
    entityActions,
    entityType,
    page = true,
    // isRoot,
    // handeleUploadDataStream
  } = props;
  return (
    <ErrorBoundary>
      <div className={page?`page-content`:``}>
        <Container fluid>
          {(breadcrumbs || paths) && (
            <Breadcrumbs
              paths={paths}
              entityActions={entityActions}
              title={title}
              renderActions={renderActions}
              breadcrumbs={breadcrumbs}
              entityType={entityType}
            />
          )}
          {error && error ? <Alert color="danger">{error}</Alert> : null}
          <div>{props.children}</div>
          {/*<ScrollLoader loading={isLoading} />*/}
          <BusyIndicator busy={isLoading}/>
          {handleCreate && (
              containerRoute && <ContainerCreateButton {...props}/>
          )
          }
          {handleCreate && (
            !containerRoute && <Button
                  disabled={isLoading}
                  color={'primary'}
                  onClick={handleCreate}
                  className={
                    'btn-radius d-flex align-items-center justify-content-center shadow-lg'
                  }
                  style={{
                    position: 'fixed',
                    margin: 16,
                    bottom: 0,
                    right: 0,
                    borderRadius: 50,
                    width: 60,
                    height: 60,
                  }}
                >
                  <i className="bx bx-plus font-size-24"/>
                </Button>
          )}
        </Container>
      </div>
    </ErrorBoundary>
  );
};

export default BaseContent;
