import React, {useEffect, useState} from 'react'
import {assetFull} from '../../../../../utils'
import Loader from '../../../../Surveys/responses/tableView/Loader'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'

const PreviewImage = (props)=>{
  const {uuid,from,element,selectedSlide,handleChange,slides,storyScreen} = props
  const [image,setImage] = useState('')
  useEffect(()=>{
    setImage('')
      assetFull(uuid, setImage,from)
    return ()=>{
      setImage('')
    }
    //eslint-disable-next-line
  },[uuid])

  const setInitialImageWidth = (img) =>{
    let finalWidth
    let finalHeight
    if (storyScreen.width < img.width || storyScreen.height < img.height) {
      finalWidth = img.width * 0.5
      finalHeight = img.height * 0.5
    }
    else {
      finalWidth = img.width
      finalHeight = img.height
    }
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((elementFound) => elementFound.id === element.id)
    if (elementIndex !== -1) {
      const elementsTmp = [...slidesTmp[slideIndex].elements]
      elementsTmp[elementIndex].width = finalWidth
      elementsTmp[elementIndex].height = finalHeight
      elementsTmp[elementIndex].data.dimensionsAreSet = true
      slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
      handleChange(slidesTmp)
    }
  }

  useEffect(()=>{
    if (image !== '')
    {

      let img = document.createElement("img")
      img.setAttribute("src", image)
      if (img.complete) { // was cached
        if (!element?.data?.dimensionsAreSet)
        setInitialImageWidth(img)
      }
      else { // wait for decoding
        img.onload = function() {
          if (!element?.data?.dimensionsAreSet)
          setInitialImageWidth(img)
        }
      }
      // }
    }
    //eslint-disable-next-line
  },[image])


  if (!image)
    return <Loader/>

  return <img className={'nonDraggableImg'} src={image} width={'100%'} height={'100%'} alt={`selected ${element?.id}`}/>
}

const RenderElement = (props) =>{
  const {element,slides,handleChange,selectedSlide,storyScreen,style} = props
  const {type}=element
  const [data,setData] = useState({})

  useEffect(()=>{
    setData(element.data)
  },[element])



  const findElementType = () =>{
    switch (type) {
      case 'TEXT':
        return <div style={{color:data.color,fontSize:data.fontSize,textAlign:data.textAlign,textDecoration:data?.textDecoration,fontStyle:data?.fontStyle,fontFamily:data?.fontFamily,fontWeight:data?.fontWeight,maxHeight: '100%',whiteSpace: 'pre-line',wordBreak: 'break-word',overflow: 'hidden'}}>{data.text}</div>
      case 'TITLE':
        return <div style={{color:data.color,fontSize:data.fontSize,textAlign:data.textAlign,fontFamily:data.fontFamily,fontWeight:data.fontWeight,maxHeight: '100%',wordBreak: 'break-word',overflow: 'hidden',textDecoration:data?.textDecoration}}>{data.text}</div>
      case 'IMAGE':
        return <>
          {data.uuid &&
              <PreviewImage  element ={element} uuid={data.uuid} from={data.from} slides = {slides} handleChange={handleChange} selectedSlide={selectedSlide} storyScreen={storyScreen}/>
          }
          {!data?.uuid &&
          <div style={{backgroundColor: '#fff',display:'flex', justifyContent:'center',alignItems:'center',alignContent:'center',height: '100%'}}>
            <i className='bx bx-image imageElement'/>
          </div>
          }
          </>
      case 'YOUTUBE':
        return <div style={{backgroundColor:'#000',width:'100%',display:'flex',height:'100%',alignItems:'center',justifyContent:'center', fontSize:'36px',fontWeight:500, color:'#fff'}}>
          <Row style={{textAlign:'center'}}>
            <Col lg={12}>
              <i style={{fontSize:100}} className={'bx bxl-youtube'}/>
            </Col>
            <Col lg={12}>
              YouTube Video
            </Col>
            {element?.data?.id &&
              <Col xs={12} className='mt-1'>
              <img className={'nonDraggableImg'} src ={`http://i3.ytimg.com/vi/${element.data.id}/hqdefault.jpg`} width={'40%'} height={'100%'} alt={`Youtube video ${element.data.id}`}/>
              </Col>
            }
          </Row>
        </div>
      case 'VIMEO':
        return <div style={{backgroundColor:'#fff',width:'100%',display:'flex',height:'100%',alignItems:'center',justifyContent:'center', fontSize:'36px',fontWeight:500, color:'#9d9999'}}>
            <Row style={{alignContent:'center',justifyContent:'center'}}>
              <Col lg={12}>
                Vimeo Video
              </Col>
              {element?.data?.id &&
              <Col lg={12}>
                {`(${element?.data?.id})`}
              </Col>
              }
            </Row>
        </div>
      default:
        return <div>Unknown Element Type</div>
    }
  }


  return <div key={props.key}  className={'handleElement'}
              style={{...style,transform:`rotate(${element.rotation}deg)`,width:'100%',height:'100%'}}>{findElementType()}</div>
}

export default  RenderElement
