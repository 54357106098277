import React from 'react'
import {Row, Col} from 'reactstrap'


const UserCard = (props) => {
  const {user} = props
  const {firstname,lastname} = user

  return <Col lg={'auto'} className='ml-2'>
        <Row className='justify-content-start align-content-center'>
          <Col xs='auto'>
            <h6>{firstname} {lastname}</h6>
          </Col>
      </Row>
    </Col>
}

export default UserCard