import React, {useEffect, useState} from 'react'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import PermissionItem from './PermissionItem'
import {Table} from 'reactstrap'

const PermissionsList = (props) => {
  const {allPermissions, form,handleChange,title,logo} = props
  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    if (form.iamPermissions)
      setPermissions(form.iamPermissions.map(p=>p.code))
  }, [form])

  const addRemovePermission = (code)=>{
    if(permissions.find(p=>p===code))
    {
      const removePermissions = form.iamPermissions.filter((p)=>p.code!==code)
      handleChange('iamPermissions')(removePermissions)
    }
    else {
      const addPermissions = [...form.iamPermissions]
      addPermissions.push(allPermissions?.find(p=>p.code===code))
      handleChange('iamPermissions')(addPermissions)
    }
  }

  return <>
    <Row>
      <Col lg={12}>
        <div className="table-responsive">
          <Table className="project-list-table table-nowrap table-centered table-borderless table-zebra">
            <thead>
            <tr>
              <th scope="col" style={{width: '100px'}}>
                <img src={logo} width='40px' height='40px' style={{borderRadius:10}} alt={title}/>
              </th>
              <th scope="col">{title}</th>
            </tr>
            </thead>
            <tbody>
            {allPermissions?.map((p) => {
              return <PermissionItem key={p.code} checked={permissions.includes(p.code)} handleChange={()=>addRemovePermission(p.code)} permission ={p}/>})
            }
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  </>
}


export default PermissionsList