import React, {useState} from 'react'
import {motion} from 'framer-motion'
const story1Variants = {
  hide:{
    fillOpacity:1
  },
  show:{
    fillOpacity: [1,0],
    transition: {
      duration: 2
    }
  }
}
const story2Variants = {
  hide:{
    fillOpacity:0
  },
  show:{
    fillOpacity: [0,1],
    transition: {
      delay:1,
      duration: 1
    }
  }
}
const timelineBulletVariants = {
  hide: {
    x: 0
  },
  show: {
    x: [0,40],
    transition: {
      duration: 2,
      ease: 'easeIn'
    }
  }
}

const TimelineAnimateSVG = (props) => {
  const [hovered, setHovered] = useState(false)
  return <motion.div layout style={{width: '100%', height: '180px'}}>
    <motion.svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 300 166.9"
                width={'100%'}
                height={'100%'}
                onHoverStart={() => setHovered(true)} onHoverEnd={() => setHovered(false)}
    >
      <g id="timeline">
        <path className="st0" d="M285.45,148.15H13.99c-0.54,0-0.98-0.44-0.98-0.98l0,0c0-0.54,0.44-0.98,0.98-0.98h271.46
		c0.54,0,0.98,0.44,0.98,0.98l0,0C286.43,147.71,285.99,148.15,285.45,148.15z"/>
        <circle className="st0" cx="32" cy="147.17" r="1.95"/>
        <circle className="st0" cx="227.09" cy="147.17" r="1.95"/>
        <circle className="st0" cx="266.94" cy="147.17" r="1.95"/>
        <circle className="st0" cx="198.08" cy="147.17" r="1.95"/>
        <circle className="st0" cx="140.57" cy="147.17" r="1.95"/>
        <circle className="st0" cx="87.02" cy="147.17" r="1.95"/>
        <circle className="st0" cx="57.72" cy="147.17" r="1.95"/>
        <circle className="st0" cx="127.47" cy="147.17" r="1.95"/>
      </g>
      <g id="timelineBullet">
        <motion.circle variants={timelineBulletVariants} animate={hovered ? 'show' : 'hide'} className="st1" cx="17.86"
                       cy="147.17" r="5.09"/>
      </g>
      <g id="timeStory1">
        <motion.path variants={story1Variants} animate={hovered?'show':'hide'} fillOpacity={0} className="st0" d="M263.85,129.51H36.15c-6.6,0-12-5.4-12-12V29.28c0-6.6,5.4-12,12-12h227.71c6.6,0,12,5.4,12,12v88.23
		C275.85,124.11,270.45,129.51,263.85,129.51z"/>
      </g>
      <g id="timeStory2">
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M135.26,122.78H34.95c-6.6,0-12-5.4-12-12V36.01c0-6.6,5.4-12,12-12h100.31c6.6,0,12,5.4,12,12v74.78
		C147.26,117.38,141.86,122.78,135.26,122.78z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M244.42,46.67h-81.55c-1.24,0-2.25-1.01-2.25-2.25v0c0-1.24,1.01-2.25,2.25-2.25h81.55
		c1.24,0,2.25,1.01,2.25,2.25v0C246.67,45.65,245.66,46.67,244.42,46.67z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M278.66,56.18H161.09c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C279.14,55.97,278.92,56.18,278.66,56.18z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M278.66,61.06H161.09c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C279.14,60.85,278.92,61.06,278.66,61.06z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M278.66,65.94H161.09c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C279.14,65.73,278.92,65.94,278.66,65.94z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M278.66,70.82H161.09c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C279.14,70.61,278.92,70.82,278.66,70.82z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M278.66,75.7H161.09c-0.26,0-0.48-0.22-0.48-0.48V72.7c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C279.14,75.49,278.92,75.7,278.66,75.7z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M278.66,80.58H161.09c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C279.14,80.37,278.92,80.58,278.66,80.58z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M278.66,85.46H161.09c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C279.14,85.25,278.92,85.46,278.66,85.46z"/>
        <motion.path fillOpacity = {0} variants={story2Variants} animate={hovered?'show':'hide'} className="st2" d="M278.66,90.34H161.09c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C279.14,90.13,278.92,90.34,278.66,90.34z"/>
      </g>
    </motion.svg>
  </motion.div>
}

export default TimelineAnimateSVG