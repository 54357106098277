import React from 'react'
import BaseContent from '../../../../components/Common/BaseContent'

const breadCrumbs = [{title: 'Templates', link: '/templates'}]

const TemplatesRoot = (props) =>{

  return <BaseContent
    breadcrumbs={breadCrumbs}
  >
  </BaseContent>

}

export default TemplatesRoot