import React, {useEffect, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {connect} from "react-redux";
import {starContainer} from "../../store/container/actions";


const ActionsContextPropMenu = ({container, position, isShown, setIsShown, toggleDelete, starContainer, ...props}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [showSelectedContainer, setShowSelectedContainer] = useState({});

  useEffect(() => {
    setOpen(true);
    setShowSelectedContainer(container);
  }, [showSelectedContainer, container]);

  const toggle = () => {
    if (dropdownOpen) {
      setIsShown(false);
    }
    setOpen(!dropdownOpen);
  };

  const handleEditClick = (e) => {
      e.preventDefault();
      props.handleClick()
    //props.setOpenEditProp(!props.openEditProp);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    props.setDeleteProp(!props.deleteProp);
  };

  const handleUsageClick = (e) => {
    e.preventDefault();
    props.handleUsage();
  };

  const handleUsageCmClick = (e) => {
    e.preventDefault();
    props.handleUsageCm();
  };

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{
          zIndex: 1000,
          position: "fixed",
          top: position.overflow ? position.y - 40 : position.y,
          left: position.x,
        }}
      >
        <DropdownToggle caret style={{display: "none"}}>
          test
        </DropdownToggle>
        <DropdownMenu
          style={{
            height: position.overflow ? "140px" : "auto",
            overflow: position.overflow ? "auto" : "",
          }}
        >
          <DropdownItem className={"d-flex align-items-center"} onClick={(e) => handleEditClick(e)}>
            <i className="bx bx-edit mr-2 font-size-18" /> Edit
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem className={"d-flex align-items-center"} onClick={(e) => handleUsageClick(e)}>
            <i className="bx bx-data mr-2 font-size-18" /> Find usages
          </DropdownItem>
          <DropdownItem className={"d-flex align-items-center"} onClick={(e) => handleUsageCmClick(e)}>
            <i className="bx bxs-data mr-2 font-size-18" /> Find usages (Content Model)
          </DropdownItem>
          {/* <DropdownItem divider />
          <DropdownItem className="text-danger d-flex align-items-center" onClick={(e) => handleDeleteClick(e)}>
            <i className="bx bx-trash mr-2 font-size-18" />
            Delete
          </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default connect(null, {
  starContainer,
})(ActionsContextPropMenu);
