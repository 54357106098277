import React, {useEffect} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import {useForm} from "../../../helpers/hooks";
import EditDialog from "../../../components/Common/EditDialog";

const PropertyDialog = (props) => {
  const { entity, open } = props;

  const [form, handleChange, updateInitial] = useForm({});

  useEffect(() => {
    updateInitial(entity);
  }, [entity, updateInitial, open]);

  return (
    <EditDialog {...props} size={"md"} form={form}>
      <form>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter label"
            value={form.name}
            onChange={handleChange("name")}
          />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter description"
            value={form.description}
            onChange={handleChange("description")}
          />
        </FormGroup>
        <FormGroup>
          <Label>Color</Label>
          <Input
            type="color"
            className="form-control"
            // placeholder="Enter description"
            value={form.color}
            onChange={handleChange("color")}
          />
        </FormGroup>
        <FormGroup>
          <Label>Scope</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter description"
            value={form.scope}
            onChange={handleChange("scope")}
          />
        </FormGroup>
      </form>
    </EditDialog>
  );
};

export default PropertyDialog;
