import React, { useEffect, useState } from "react";
import BaseContent from "../../components/Common/BaseContent";
import { connect } from "react-redux";
import {
  clearDataStream,
  getDataStream,
  updateDataStream,
} from "../../store/dataStream/actions";
import {
  clearContainer,
  clearContainers,
  getContainer,
} from "../../store/container/actions";
import EntitySidebar from "../../components/Common/entity-sidebar";
import { useDataStreams, useToggle } from "../../helpers/hooks";
import Toolbar from "../../components/Common/Toolbar";
import { Schema } from "../../store/core/schema";
import { EntityType } from "../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import { Card, CardBody } from "reactstrap";
import FilePreview from "./FilePreview";
import {ObjectType} from "../../constants/enums";
import {toggleRightSidebar} from "../../store/layout/actions";

const entityType = EntityType.DataStream;

const DataStreamView = (props) => {
  const {
    getDataStream,
    clearDataStream,
    getContainer,
    clearContainer,
    clearContainers,
    toggleRightSidebar
  } = props;
  const { context, updateDataStream } = props;
  const { result, error, isLoading } = props.context.DataStream.get;
  const {showRightSidebar} = props.context.Layout
  const uuid = props.match.params.id;
  const thisContainer = props.context.Container.get.result;
  const parentContainer = result.containers && result.containers[0]
  const propertyGroups = props.context.PropertyGroup.getAll.result;
  const containerUuid = parentContainer && parentContainer.uuid;
  const dataStreamTypes = props.context.DataStreamType.getAll.result;
  const [entity, setEntity] = useState(Schema[entityType]);
  const [openModal, handleToggle] = useToggle();
  const { busy, preview, tags } = useDataStreams(uuid);

  useEffect(() => {
    getDataStream(uuid);
    clearContainer();
    return () => {
      clearDataStream();
    };
  }, [clearContainer, clearDataStream, getDataStream, uuid]);

  useEffect(() => {
    if (containerUuid) getContainer(containerUuid);
    return () => {
      clearContainer();
      clearContainers();
    };
  }, [clearContainer, clearContainers, containerUuid, getContainer]);

  const breadcrumbs = [
    { title: "Content", link: "/containers" },
    ...(thisContainer.paths && thisContainer.paths[0]
      ? thisContainer.paths[0].nodes.map((parent) => ({
          title: parent.label,
          link: `/containers/${parent.uuid}`,
        }))
      : []),
    { title: thisContainer.label, link: `/containers/${thisContainer.uuid}` },
    { title: result.label, link: "#" },
  ];

  const handleEditClick = (dataStream) => {
    setEntity(dataStream);
    handleToggle();
  };

  const handleToolbarEditClick = () => {
    handleEditClick(result);
  };

  const contentClass = showRightSidebar
    ? "content-with-sidebar"
    : "content-with-no-sidebar";

  const entityActions = [
    { title: "Edit", action: handleToolbarEditClick, icon: 'bx-edit' },
    // { title: "Rights", action: null, icon: "bx-user-plus" },
    // { title: "Share", action: null, icon: "bx-link" },
  ];

  return (
    <div className={contentClass}>
      <BaseContent
        title={"My Files"}
        error={error}
        isLoading={isLoading}
        paths={breadcrumbs}
        entityType={entityType}
        entityActions={entityActions}
        renderActions={() => (
          <Toolbar
            togglePanel={toggleRightSidebar}
            handleEdit={handleToolbarEditClick}
          />
        )}
      >
        <Card style={{ height: "calc(100vh - 195px)" }}>
          <CardBody>
            <FilePreview fileBase64={preview} fileType={result && result.mimeType} />
          </CardBody>
        </Card>
        <EntitySidebar
          isFolder={false}
          open={showRightSidebar}
          entity={result}
          entityType={entityType}
          handleClose={toggleRightSidebar}
          entityTags={tags}
          objectType={ObjectType.dataStream}
          busy={busy}
          showProperties={true}
          isLoadingCurrent={isLoading}
        />
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={openModal}
          handleClose={handleToggle}
          // createEntity={createGroup}
          updateEntity={updateDataStream}
          context={context.DataStream}
          dataStreamTypes={dataStreamTypes}
          propertyGroups={propertyGroups}
        />
      </BaseContent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getDataStream,
  clearDataStream,
  clearContainer,
  clearContainers,
  getContainer,
  updateDataStream,
  toggleRightSidebar
})(DataStreamView);
