import {Col, Row} from "reactstrap";
import React from "react";

const NoResponses = () =>{
  return <Row style={{margin: 0}}>
    <Col xs={12} style={{textAlign: 'center'}}>
      <div className='d-flex align-items-center align-content-center justify-content-center' style={{minHeight: 250}}>
        <div className='d-flex font-size-20'>
          No responses
        </div>
      </div>
    </Col>
  </Row>
}

export default NoResponses
