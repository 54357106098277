import {all, fork, put, takeEvery} from "redux-saga/effects";
// Login Redux States
import {FORGET_PASSWORD} from "./actionTypes";
import {userForgetPasswordError} from "./actions";
//Include Both Helper File with needed methods

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
