import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import {styled} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";

const SortTable = ({...props}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("label");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(0);
  }, [props.content]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.content.length) : 0;
  console.log(props.content[0]);
  const columns = props.content[0].uuid
    ? [
        {id: "label", label: "Label"},
        {id: "uuid", label: ""},
      ]
    : [{id: "label", label: "Name"}];

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function EnhancedTableHead(props) {
    const {order, orderBy, rowCount, onRequestSort} = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow style={{borderBottom: "2px solid #ccc"}}>
          {columns.map((headCell) => (
            <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{fontWeight: "600", fontSize: "15px", lineHeight: "30px"}}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    const {numSelected} = props;

    return (
      <Toolbar
        sx={{
          pl: {sm: 2},
          pr: {xs: 1, sm: 1},
          ...(numSelected > 0 && {
            bgcolor: (theme) => theme.palette.primary.main,
          }),
        }}
      ></Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  return (
    <div className="container-fluid" style={{maxWidth: "100%"}}>
      <TableContainer>
        <Table style={{margin: "20px 0", border: "1px solid #ddd", borderRadius: "5px"}} aria-labelledby="UsageList" size={"small"}>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={props.content?.length} />
          <TableBody>
            {stableSort(props.content, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell component="th" id={labelId} scope="row" colSpan={props.isContainer ? "1" : "2"}>
                      {row.label ? row.label : row.name}
                    </TableCell>
                    {row.label && props.isContainer && (
                      <TableCell align="right">
                        <a href={`../../containers/${row.uuid}`} target="_blank" rel="noopener noreferrer">
                          <i className="bx bx-link"></i>
                        </a>
                      </TableCell>
                    )}
                  </StyledTableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={props.content.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />
    </div>
  );
};

const UsagesReport = ({classes, ...props}) => {
  const [containersUsages, setContainersUsages] = useState([]);
  const [datastreamsUsages, setDatastreamsUsages] = useState([]);
  const [isContainer, setIsContainer] = useState(true);

  useEffect(() => {
    setContainersUsages(props.usages.containers ? props.usages.containers : props.usages.containerTypes);
    setDatastreamsUsages(props.usages.datastreams ? props.usages.datastreams : props.usages.datastreamTypes);
    if (props.usages.containers?.length > 0 || props.usages.containerTypes?.length > 0) {
      setIsContainer(true);
    } else {
      setIsContainer(false);
    }
  }, [props.usages]);

  return (
    <React.Fragment>
      <Modal centered size={"lg"} isOpen={props.open} toggle={props.handleClose}>
        <ModalHeader toggle={props.handleClose} style={{textAlign: "center", color: "#fff"}}>
          Usages of property <strong>{props.usages.displayName}</strong>
          {props.usages.containerTypes || props.usages.datastreamTypes ? " in Content Model" : ""}
        </ModalHeader>
        <ModalBody>
          <div direction="row" spacing={1} style={{width: "100%", textAlign: "right", padding: "0 12px"}}>
            {containersUsages.length > 0 && (
              <Chip
                size="small"
                style={{color: isContainer ? "#fff" : "#818181", backgroundColor: isContainer ? "#556ee6" : "#fff", border: isContainer ? "1px solid #556ee6" : "1px solid #adb5bd"}}
                label={props.usages.containerTypes?"Container Types":"Containers"}
                onClick={() => setIsContainer(true)}
                onDelete={(e) => setIsContainer(true)}
                deleteIcon={
                  <div
                    style={{
                      color: "rgb(85 110 230)",
                      marginRight: "6px",
                      background: "rgb(219 225 255)",
                      borderRadius: "50%",
                      textAlign: "center",
                      fontSize: "11px",
                      lineHeight: "16px",
                      verticalAlign: "middle",
                    }}
                  >
                    {containersUsages.length}
                  </div>
                }
              />
            )}{" "}
            {datastreamsUsages.length > 0 && (
              <Chip
                size="small"
                style={{color: !isContainer ? "#fff" : "#818181", backgroundColor: !isContainer ? "#556ee6" : "#fff", border: !isContainer ? "1px solid #556ee6" : "1px solid #adb5bd"}}
                label={props.usages.datastreamTypes? "Datastream Types":"Datastreams"}
                onClick={() => setIsContainer(false)}
                onDelete={(e) => setIsContainer(false)}
                deleteIcon={
                  <div
                    style={{
                      color: "rgb(85 110 230)",
                      marginRight: "6px",
                      background: "rgb(219 225 255)",
                      borderRadius: "50%",
                      textAlign: "center",
                      fontSize: "11px",
                      lineHeight: "16px",
                      verticalAlign: "middle",
                    }}
                  >
                    {datastreamsUsages.length}
                  </div>
                }
              />
            )}
          </div>
          <SortTable content={isContainer ? containersUsages : datastreamsUsages} isContainer={isContainer} />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              props.setUsages();
              props.handleClose(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default UsagesReport;
