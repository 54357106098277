import React, {createRef, useEffect, useState} from 'react';
import {
  Input,
} from 'reactstrap'
import {SearchOperators} from '../../constants/enums'
import {connect} from 'react-redux'

const MoveLinkFireInput = (props) => {

  const {onFire, onChange, timeout, placeholder, value, onClick, queryFilter} = props
  const [isTyping, setIsTyping] = useState(false)
  const [dynamicPlaceholder,] = useState(placeholder)
  const ref = createRef()

  useEffect(() => {
    queryFilter(SearchOperators.container)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    onFire()
    //eslint-disable-next-line
  }, [isTyping])

  const handleChange = (e) => {
    clearTimeout(ref.current);
    setIsTyping(true)
    onChange(e.target.value);
    ref.current = setTimeout(() => {
      setIsTyping(false)
    }, timeout || 1000);
  };

  return (<>
          <Input
            type="text"
            value={value}
            onClick={onClick}
            placeholder={dynamicPlaceholder}
            onChange={handleChange}
          />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, null)(MoveLinkFireInput)