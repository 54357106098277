import {EntityType} from "../core/entityType";
import {Operation} from "../core/operation";

export const entityType = EntityType.Story;
export const entityOperations = [
  Operation.getAll,
  Operation.get,
  Operation.create,
  Operation.update,
  Operation.delete,
];
