import React, {Fragment, useEffect, useRef, useState} from 'react'
import BaseContent from '../../../../components/Common/BaseContent'
import {connect} from 'react-redux'
import {getVocabulary,updateVocabulary} from '../../../../store/vocabulary/actions'
import TermsList from './components/TermsList'
import AddTerm from './components/AddTerm'
import axios from 'axios'
import {API_URL} from '../../../../config'
import {getAxiosDefaultConfig} from '../../../../utils'
import toastr from 'toastr'
import EditVocabularyModal from './components/EditVocabularyModal'
import {Redirect} from 'react-router'
import DeleteDialog from '../../../Containers/DeleteDialog'
import ImportTerms from './components/ImportTerms'
import PaginationComponent from 'react-reactstrap-pagination'
import {Col, Input, UncontrolledTooltip} from 'reactstrap'
const breadcrumbsInit = [
  { title: "Vocabularies", link: "/settings/content-model/vocabularies" },
];
const EditVocabulary = (props) => {
  const {context,getVocabulary} = props
  const {result:vocabulary,isLoading,error} = context.Vocabulary.get
  const updateContext = context.Vocabulary.update
  const [loading,setLoading] = useState(false)
  const [breadCrumbs,setBreadCrumbs] = useState(breadcrumbsInit)
  const [showAddTerm,setShowAddTerm] = useState(false)
  const [openEditVoc,setOpenEditVoc]=useState(false)
  const [deleteVoc,setDeleteVoc] = useState(false)
  const [deleteTerms,setDeleteTerms] = useState(false)
  const [checkedTerms,setCheckedTerms] = useState([])
  // eslint-disable-next-line
  const [redirectToVocabularies,setRedirectToVocabularies] = useState(false)
  const [openImport,setOpenImport] = useState(false)
  const [termsPage,setTermsPage] = useState(0)
  const uuid = props.match.params.id
  const [terms,setTerms] = useState([])
  const [termsFlatData,setTermsFlatData] = useState([])
  const searchTimeout = useRef()

  useEffect(()=>{
    if(uuid) {
      getVocabulary(uuid)
      getTerms()
    }
    // eslint-disable-next-line
  },[uuid])

  useEffect(()=>{
    if(updateContext.error)
      toastr.error(updateContext.error)
    else if (updateContext.successResult)
    {
      toastr.success('Successfully Updated')
    }
  },[updateContext])

  useEffect(()=>{
    if(vocabulary?.name) {
      const breadcrumbsInit = [
        { title: "Vocabularies", link: "/settings/content-model/vocabularies" },
      ];
      setBreadCrumbs([...breadcrumbsInit, {title: vocabulary?.name, link: '#'}])
    }
    // eslint-disable-next-line
  },[vocabulary?.name])

  const handleUpdateVocabulary = (values)=>{
    axios.put(`${API_URL}/vocabularies/${uuid}`,values,getAxiosDefaultConfig())
      .then(() => {
        getVocabulary(uuid)
        getTerms()
        toastr.success(`Successfully updated`);
      })
      .catch(error => {
        toastr.error(error.message)
      })
  }
  //
  // const handleDeleteVocabulary = () =>{
  //   axios.delete(`${API_URL}/vocabularies/${uuid}`,getAxiosDefaultConfig())
  //     .then(()=>{
  //       setRedirectToVocabularies(true)
  //     })
  //     .catch(error => {
  //       toastr.error(error.message)
  //     })
  // }

  const createTerm = (values) =>{
    axios.post(`${API_URL}/vocabularies/${uuid}/terms`,values,getAxiosDefaultConfig())
      .then(() => {
        getVocabulary(uuid)
        getTerms()
        toastr.success(`Successfully created`);
      })
      .catch(error => {
        toastr.error(error.message)
      })
  }

  const updateTerm = (values) =>{
    const {termId,label,termUri} = values
    const payload = {label,termUri}
    axios.put(`${API_URL}/vocabularies/${uuid}/terms/${termId}`,payload,getAxiosDefaultConfig())
      .then(() => {
        getTerms()
        toastr.success(`Successfully updated`);
      })
      .catch(error => {
        toastr.error(error.message)
      })
  }

  const deleteTerm = (termId) =>{

    axios.delete(`${API_URL}/vocabularies/${uuid}/terms/${termId}`,getAxiosDefaultConfig())
      .then(() => {
        getTerms()
        toastr.success(`Successfully deleted`);
      })
      .catch(error => {
        toastr.error(error.message)
      })
  }

  const getTerms = ()=>{
    axios.get(`${API_URL}/vocabularies/${uuid}/terms?page=${termsPage}`,getAxiosDefaultConfig())
      .then((response) => {
        setTerms(response.data.content)
        setTermsFlatData(response.data)
      })
      .catch(error => {
        toastr.error(error.message)
      })
  }

  const searchTerm = (searchTerm) =>{
    clearTimeout(searchTimeout.current)
    if (searchTerm.length > 2) {
      setLoading(true)
      searchTimeout.current = setTimeout(() => {
        axios.get(`${API_URL}/vocabularies/${uuid}/terms?q=${searchTerm}`, getAxiosDefaultConfig()).then((response) => {
          setTerms(response.data.content)
          setLoading(false)
        }).catch((e)=>{
          toastr.error(e)
          setLoading(false)
        })

      }, 600)
    } else if (searchTerm === '') {
      setLoading(true)
      axios.get(`${API_URL}/vocabularies/${uuid}/terms?q=`, getAxiosDefaultConfig()).then((response) => {
        setTerms(response.data.content)
        setLoading(false)
      }).catch((e)=>{
        toastr.error(e)
        setLoading(false)
      })
    }
  }

  const handleSelectPage = (page) =>{
    setTermsPage(page-1)
  }

  useEffect(()=>{
    getTerms()
    // eslint-disable-next-line
  },[termsPage])
  return <BaseContent
    breadcrumbs={breadCrumbs}
    error={error}
    isLoading={isLoading || loading}
    renderActions={() => (
      <Fragment>
        <button
          type="button"
          className="btn font-size-18"
          data-toggle="fullscreen"
          onClick={()=>setShowAddTerm(!showAddTerm)}
          disabled={isLoading}
        >
          <i className="bx bx-plus-circle" />
        </button>
        <button
          type="button"
          className="btn font-size-18"
          data-toggle="fullscreen"
          onClick={()=>setOpenImport(!openImport)}
          disabled={isLoading}
          id={'import-terms'}
        >
          <i className="bx bx-upload" />
        </button>
        <UncontrolledTooltip target={'import-terms'} placement="bottom">
          Import Terms from file
        </UncontrolledTooltip>
        <button
          type="button"
          className="btn font-size-18"
          data-toggle="fullscreen"
          onClick={()=>setOpenEditVoc(!openEditVoc)}
          disabled={isLoading}
          id={'edit-vocabulary'}
        >
          <i className="bx bxs-pencil" />
        </button>
        <UncontrolledTooltip target={'edit-vocabulary'} placement="bottom">
          Edit Vocabulary
        </UncontrolledTooltip>
        <button
          type="button"
          className="btn font-size-18"
          data-toggle="fullscreen"
          disabled={checkedTerms.length<=0 || isLoading}
          onClick={()=>setDeleteTerms(!deleteTerms)}
          id={'delete-terms'}
        >
          <i className="bx bxs-trash" />
        </button>
        <UncontrolledTooltip target={'delete-terms'} placement="bottom">
          Delete selected Terms
        </UncontrolledTooltip>
        <Input
          disabled={isLoading}
          onChange={(e)=>searchTerm(e.target.value)}
          placeholder={'Search a term...'}
        />
      </Fragment>
        )}
  >

    {/* List */}

    {terms.length>0 && <TermsList terms={terms} updateTerm={updateTerm} deleteTerm={deleteTerm} setChecked={setCheckedTerms}/>}
    {!isLoading && terms.length===0 && <Col><div className='font-weight-bold font-size-14'>There are no terms</div></Col>}

    {/* Modals */}
  <AddTerm open={showAddTerm} handleCreate={createTerm} handleClose = {()=>setShowAddTerm(!showAddTerm)} uuid={uuid}/>
  <EditVocabularyModal vocabulary={vocabulary} open={openEditVoc} handleClose={()=>setOpenEditVoc(!openEditVoc)} handleUpdate={handleUpdateVocabulary} handleDelete={()=>setDeleteVoc(!deleteVoc)}/>
    {redirectToVocabularies && <Redirect to={`/settings/content-model/vocabularies`}/>}
    <DeleteDialog open={deleteVoc} title={'Delete Confirmation'} handleClose={()=>setDeleteVoc(!deleteVoc)} question={`Are you sure you want to delete this vocabulary?`}/>
    <DeleteDialog open={deleteTerms} title={'Delete Confirmation'} handleClose={()=>setDeleteTerms(!deleteTerms)} question={`Are you sure you want to delete all ${checkedTerms.length} terms?`}/>
  <ImportTerms open={openImport} handleClose={()=>setOpenImport(!openImport)} uuid={uuid} refresh={getTerms}/>

  {/*Pagination */}
  <Col xs={{size:6, offset:6}} className='responses-navigation'>
      <PaginationComponent totalItems={termsFlatData?.totalElements} pageSize={termsFlatData?.pageable?.pageSize} onSelect={handleSelectPage} />
    </Col>
  </BaseContent>
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {getVocabulary,updateVocabulary})(EditVocabulary);
