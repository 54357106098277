import React from 'react'
import {Col, Container, Row} from 'reactstrap'
import {ReactComponent as NoData} from '../../assets/images/moon.svg'

const NoStars = () =>{
  return  <Container style={{borderRadius:'350px',backgroundColor:'rgb(221,221,225)',padding:'80px',height:'350px',width:'350px'}} className='mb-4'>
    <Row>
      <Col xs={12} className='mt-n2'>
        <NoData width='180px' height='180px'/>
      </Col>
        <Col xs={12} className='text-center mt-2'>
          <p className='h5'> No Starred items found</p>
        </Col>
    </Row>
  </Container>
}

export default NoStars
