import React, {useEffect} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import {useForm} from "../../../../helpers/hooks";
import EditDialog from "../../../../components/Common/EditDialog";

const PropertyDialog = (props) => {
  const { entity, open } = props;

  const [form, handleChange, updateInitial] = useForm({});

  useEffect(() => {
    updateInitial(entity);
  }, [entity, updateInitial, open]);

  return (
    <EditDialog {...props} size={"md"} form={form}>
      <form>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter label"
            value={form.name}
            onChange={handleChange("name")}
          />
        </FormGroup>
        <FormGroup>
          <Label>Code</Label>
          <Input
            type="text"
            disabled={form.id}
            className="form-control"
            // placeholder="Enter label"
            value={form.code}
            onChange={handleChange("code")}
          />
        </FormGroup>
        <FormGroup>
          <Label>Prefix</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter description"
            value={form.prefix}
            onChange={handleChange("prefix")}
          />
        </FormGroup>
        <FormGroup>
          <Label>Url</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter description"
            value={form.url}
            onChange={handleChange("url")}
          />
        </FormGroup>
      </form>
    </EditDialog>
  );
};

export default PropertyDialog;
