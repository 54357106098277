import {useParams} from "react-router";
import React, {useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../config";
import {getAxiosDefaultConfig} from "../../../../utils";
import BaseDialog from "../../../../components/Common/BaseDialog";
import Container from "reactstrap/lib/Container";
import StoriesRoot from "../../Stories/stories-root";

export const SelectStoryDialog = (props) => {
  const {id} = useParams()

  const {open, handleClose} = props
  const [busy, setBusy] = useState(false)
  const [selectedStory, setSelectedStory] = useState()

  const assignStory = () => {
    setBusy(true)
    axios.post(`${API_URL}/str/websites/${id}/link`,{
      storyId: selectedStory
    },getAxiosDefaultConfig())
      .then((response) => {
        setBusy(false);
        handleClose(true)
      })
      .catch(error => {
        setBusy(false);
      })
  }

  const handleSelectStory = (uuid) => setSelectedStory(uuid)

  return <BaseDialog
    title={'Push story'}
    // secondaryAction={handleClose}
    // handleEditTitle={handleEditTitle}
    primaryAction={()=>assignStory()}
    primaryActionTitle={'Assign'}
    isValid={!!selectedStory}
    // deleteAction={
    //   deleteEntity && form.id ? () => setOpenDelete(true) : null
    // }
    busy={busy}
    open={open}
    handleClose={handleClose}
    scrollable={true}
    size='xl'
    // contents={contents}
  >
    <Container style={{minHeight: '300px'}}>

      <StoriesRoot browser selectStory={handleSelectStory} selectedStory={selectedStory} />

    </Container>


  </BaseDialog>
}
