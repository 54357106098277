import {Button, Col} from "reactstrap";
import React, {useState} from "react";


const ListOfText = (props) => {
  const {fieldNumber} = props
  const {questionTitle,answerCount,answerSummary} = props.field
  const [limit, setLimit] = useState(10)

  const showMore = () => {
    let newLimit = limit
    newLimit += 20
    setLimit(newLimit)
  }

  const answersLeft = answerSummary.length-limit

  return <>
    <Col xs={12}>
      <div style={{fontSize: 14, marginTop: -8, marginBottom: 8, fontWeight: 'bold'}}>{fieldNumber+1}. {questionTitle}</div>
      <div style={{fontSize: 14, marginTop: -8, marginBottom: 8}}>{answerCount} responses</div>
    </Col>

    <Col xs={12}>

      <ul class="list-group mt-2">
        {answerSummary.slice(0,limit).map((answer,i)=><li key={i} class="list-group-item">
          <h6>{answer}</h6>
        </li>)}
          {/*{answerSummary.slice(limit).map((answer,i)=><li key={i}>*/}
          {/*  <h6>{answer}</h6>*/}
          {/*</li>)}*/}
      </ul>

    </Col>

      <div className='d-flex justify-content-center align-content-center align-items-center w-100 mt-4'>
        <div className='d-flex'>
          {(answerSummary.length>10&&answersLeft>0)&&<Button onClick={showMore}> View more ({answersLeft}) </Button>}
        </div>
      </div>
  </>
}

export default ListOfText
