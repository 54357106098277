import {withRouter} from "react-router-dom";
import {FIELD_COMPONENTS} from "./consts";
import * as React from "react";
import {Container} from "reactstrap";
import FieldHeader from "./common/FieldHeader";
import FieldFooter from "./common/FieldFooter";

// This will render the appropriate type of field
// Check components/fields
const FieldRenderer = (props) => {
  const {selected, answerable, field} = props
  const {type} = field
  const isEditing = !answerable && selected

  if (!FIELD_COMPONENTS[type]) return <> </>

  return <>
    <Container style={{textAlign:'left', position:'relative'}}>
      {isEditing&&<FieldHeader {...props} />}
      { React.createElement(FIELD_COMPONENTS[props.field.type], {...props}) }
    </Container>
    {isEditing&&<FieldFooter {...props} />}
  </>
}

export default withRouter(FieldRenderer);
