import React, {useEffect, useRef, useState} from 'react'
import {Button, Col, Container, FormGroup, Input, Label, Row, UncontrolledTooltip} from 'reactstrap'
import {ClickAwayListener, Divider, Slider, TextField} from '@material-ui/core'
import AssetsDialog from '../../../../components/Common/AssetsDialog'
import {assetThumb, getAxiosDefaultConfig} from '../../../../utils'
import {SketchPicker} from 'react-color';
import SlideAssetsDialog from './components/SlideAssetsDialog'
import TextElementOptions from './components/ConfigurationBlock/TextElementOptions'
import TitleElementOptions from './components/ConfigurationBlock/TitleElementOptions'
import Select from 'react-select'
import {ANIMATION_LEAVE_TYPES, ANIMATION_ENTER_TYPES} from '../../../../constants/enums'
import toastr from 'toastr'
import {Autocomplete} from '@material-ui/lab'
import axios from 'axios'
import {API_URL} from '../../../../config'
import YoutubeEditBlock from './ElementsEditDialog/YoutubeEditBlock'

const GeneralBlock = (props) => {
  const {configurationBlocks, handleChangeConfigurationBlocks,storyTags,setStoryTags,allStoryTags,loading} = props
  const [value, setValue] = useState([]);
  const [options,setOptions] = useState([])
  const searchTimeout = useRef()

  useEffect(()=> {

    if (allStoryTags.length>0) {
      const allStoryTagsTmp = JSON.parse(JSON.stringify([...allStoryTags]))
      const options = allStoryTagsTmp.map((item)=>{
        return {label: `${item.scope}:${item.name}`,id:item.id}
      })
      setOptions(options)

      const storyTagsTmp = JSON.parse(JSON.stringify([...storyTags]))

      if (storyTagsTmp.length > 0 && storyTagsTmp[0].scope)
      {
        const storyTagsData = storyTagsTmp.map((item)=>{
          return {label: `${item.scope}:${item.name}`,id:item.id}
        })
        setValue(storyTagsData)
      }
      else
        setValue(storyTagsTmp)

    }
    //eslint-disable-next-line
  },[allStoryTags])


 const addTag = (newValue) =>{
   setStoryTags(newValue)
   setValue(newValue)
 }

 const searchTag = (searchTerm) =>{
   clearTimeout(searchTimeout.current)
   if (searchTerm.length > 2) {
     searchTimeout.current = setTimeout(() => {
       axios.get(`${API_URL}/story_tags?q=${searchTerm}`, getAxiosDefaultConfig()).then((response) => {
         const options = response.data.content.map((item)=>{
           return {label: `${item.scope}:${item.name}`,id:item.id}
         })
         setOptions(options)
       }).catch((e)=>{
         toastr.error(e)
       })

     }, 600)
   } else if (searchTerm === '') {
     axios.get(`${API_URL}/story_tags?q=`, getAxiosDefaultConfig()).then((response) => {
       const options = response.data.content.map((item)=>{
         return {label: `${item.scope}:${item.name}`,id:item.id}
       })
       setOptions(options)
     }).catch((e)=>{
       toastr.error(e)
     })
   }
 }

  const handleIsTemplate = () =>{
    handleChangeConfigurationBlocks({isTemplate:!configurationBlocks.isTemplate})
  }

  return <>
    <Row className='mb-2'>
      <Col lg={12}>
        <div className='font-size-13 font-weight-bold mb-2'>Title</div>
      </Col>
      <Col lg={12}>
        <Input value={configurationBlocks?.title}
               onChange={(e) => handleChangeConfigurationBlocks({title: e.target.value})}/>
      </Col>
    </Row>
    <Row className='mb-2'>
      <Col lg={12}>
        <div className='font-size-13 font-weight-bold mb-2'>Description</div>
      </Col>
      <Col lg={12}>
        <Input type='textarea' value={configurationBlocks?.description}
               onChange={(e) => handleChangeConfigurationBlocks({description: e.target.value})}/>
      </Col>
    </Row>
    <Row className='mb-2'>
      <Col lg={12}>
        <div className='font-size-13 font-weight-bold mb-2'>Screen Type</div>
      </Col>
    </Row>
    <Row className={'align-items-center align-content-center justify-content-start'}>
      <Col lg={4}>
        <div className='font-size-13 font-weight-light mb-2'>Orientation</div>
      </Col>
      <Col lg={'auto'}>
        <div className='font-size-14 font-weight-bold' style={{textTransform:'capitalize'}}> {configurationBlocks?.orientation?.toLowerCase()} </div>
      </Col>
    </Row>
    <Row className='mb-2 align-items-center align-content-center justify-content-start'>
      <Col lg={4}>
        <div className='font-size-13 font-weight-light mb-2 mt-2'>Aspect Ratio</div>
      </Col>
      <Col lg={'auto'}>
        <div className='font-size-14 font-weight-bold'> {configurationBlocks?.aspectRatio} </div>
      </Col>
    </Row>
    <Row className='mb-2 align-items-center align-content-center justify-content-start'>
      <Col lg={12}>
        <FormGroup>
          <Label>Tags</Label>
          <Autocomplete
            multiple
            id="size-small-filled-multi"
            size="small"
            options={options}
            disabled={loading}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option?.label}
            filterOptions={(options) => options}
            value={value}
            onInput={(event)=>searchTag(event.target.value)}
            onChange={(event, newValue) => addTag(newValue)}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              );
            }}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row className='mb-2 align-items-center align-content-center justify-content-start'>
      <Col lg={12}>
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input checkbox" id="checkbox_1"
                 checked={configurationBlocks?.isTemplate}
                 onChange={handleIsTemplate}/>
          <label className="custom-control-label" htmlFor="checkbox_1">Save as template</label>
        </div>
      </Col>
    </Row>
  </>
}

const StoryCover = (props) => {
  const {configurationBlocks, handleChange} = props
  const [open, setOpen] = useState(false)
  const [thumb, setThumb] = useState(null)
  useEffect(() => {
    if (configurationBlocks?.coverPage) {
      assetThumb(configurationBlocks.coverPage, setThumb,'stories')
    }
//eslint-disable-next-line
  }, [configurationBlocks?.coverPage])
  return <>
    <Col lg={'auto'} style={{
      width: '200px',
      height: '200px',
      margin: '20px auto',
      border: '1px dashed #d2d2d2',
      borderRadius: '10px',
      padding: '20px',
      cursor: 'pointer',
    }}
         onClick={() => setOpen(!open)}
    >
      {thumb && <img src={thumb} alt={'cover'} width={'100%'} height={'100%'}/>}
      {!thumb && <i className='bx bx-image' style={{fontSize: '150px'}}/>}
    </Col>
    <AssetsDialog open={open} handleChange={handleChange} handleClose={() => setOpen(!open)} {...props}/>
  </>
}

const OptionBox = (props) => {
  const {title, icon, selected} = props

  return <Col lg={4} className='mb-4' style={{cursor: 'pointer'}} {...props}>
    <Container style={{textAlign: 'center'}}>
      <Row>
        <Col lg={12} className='font-size-24'>
          <i className={`${icon}`} style={{color: selected ? '#556ee6' : ''}}/>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className='font-size-10'>{title}</div>
        </Col>
      </Row>
    </Container>
  </Col>
}


const ImageElementOptions = (props) => {
  const {selectedElement, setSelectedElement,setOpenElementEditAction, openElementEditAction, slides, selectedSlide, handleChange} = props
  const [showAssetsDialog, setShowAssetsDialog] = useState(false)
  const [imageFile, setImageFile] = useState(null)
  const [imageOrigin, setImageOrigin] = useState('')
  const [thumb, setThumb] = useState(null)
  const [rotation, setRotation] = useState(0)
  const [lockAspectRatio, setLockAspectRatio] = useState(true)

  useEffect(() => {
    assetThumb(imageFile, setThumb, imageOrigin)
    //eslint-disable-next-line
  }, [imageFile])

  useEffect(()=>{
    if (selectedElement.id && openElementEditAction.id)
    {
      setShowAssetsDialog(true)
      setOpenElementEditAction({})
    }
    //eslint-disable-next-line
  },[selectedElement.id,openElementEditAction])

  useEffect(() => {
    setImageFile(selectedElement?.data?.uuid)
    setImageOrigin(selectedElement?.data?.from)
    setLockAspectRatio(selectedElement?.data?.lockAspectRatio)
    setRotation(selectedElement.rotation)
    //eslint-disable-next-line
  }, [selectedElement.id])

  const handleImageChange = (imageUuid, assetFrom) => {
    setImageFile(imageUuid)
    setImageOrigin(assetFrom)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.uuid = imageUuid
    elementsTmp[elementIndex].data.from = assetFrom
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const increaseRotation = () => {
    let increasedRotation = 0
    if (rotation >= 315) {
      increasedRotation = 0
      setRotation(increasedRotation)
    } else {
      increasedRotation = rotation + 45
      setRotation(increasedRotation)
    }

    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].rotation = increasedRotation
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }
  const decreaseRotation = () => {
    let decreasedRotation = 0
    if (rotation <= -315) {
      decreasedRotation = 0
      setRotation(decreasedRotation)
    } else {
      decreasedRotation = rotation - 45
      setRotation(decreasedRotation)
    }

    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].rotation = decreasedRotation
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleLockAspectRatio = () => {
    setLockAspectRatio(!lockAspectRatio)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.lockAspectRatio = !lockAspectRatio
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleDeleteElement = () => {
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementsTmp = slidesTmp[slideIndex].elements.filter((element) => element.id !== selectedElement.id)
    // console.log('on delete', elementsTmp)
    // console.log('seleceted element on delete', selectedElement.id)
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
    setSelectedElement({})
  }

  return <>
    <Col lg={12} className='mt-2'>
      <div className='font-size-13 font-weight-bold mb-2'>Image</div>
    </Col>

    <Col lg={12}>
      <div className='slideBackgroundImage' onClick={() => setShowAssetsDialog(!showAssetsDialog)}>
        {thumb && <img src={thumb} alt={'cover'} width={'100%'} height={'100%'}/>}
        {!thumb && <i className='bx bx-image' style={{fontSize: '80px'}}/>}
      </div>
    </Col>

    <Col lg={12} className='mt-2'>
      <div className="custom-control custom-switch">
        <input type="checkbox" className="custom-control-input checkbox" id="checkbox_1"
               checked={lockAspectRatio}
               onChange={handleLockAspectRatio}/>
        <label className="custom-control-label" htmlFor="checkbox_1">Lock Aspect Ratio</label>
      </div>
    </Col>
    <Divider className='mt-1 mb-1'/>
    <Col lg={12} className={'mt-2'}>
      <div className='font-size-13 font-weight-bold mb-2'>Rotate</div>
    </Col>
    <div className='d-flex'>
      <Col lg={'auto'} className={'mr-2'}>
        <Button color='secondary' onClick={decreaseRotation}>
          <i className='bx bx-rotate-left'/>
        </Button>
      </Col>
      <Col lg={'auto'} onClick={increaseRotation}>
        <Button color='secondary'>
          <i className='bx bx-rotate-right'/>
        </Button>
      </Col>
    </div>
    <Col lg={12} className='mt-4'>
      <i className='bx bx-trash warning-color font-size-24 mdi-cursor-pointer' onClick={handleDeleteElement}/>
    </Col>

    <SlideAssetsDialog open={showAssetsDialog} handleChange={handleImageChange}
                       handleClose={() => {
                         setShowAssetsDialog(!showAssetsDialog)
                         setOpenElementEditAction({})
                       }}/>
  </>
}

const ElementConfigBlock = (props) => {
  const {slides, selectedEditElement,openElementEditAction,setOpenElementEditAction, selectedSlide, setSelectedEditElement, handleChange} = props

  const showElementOptions = () => {
    switch (selectedEditElement.type) {
      case 'TEXT':
        return <TextElementOptions openElementEditAction={openElementEditAction} slides={slides} selectedElement={selectedEditElement} selectedSlide={selectedSlide}
                                   handleChange={handleChange} setSelectedElement={setSelectedEditElement}/>
      case 'TITLE':
        return <TitleElementOptions slides={slides} selectedElement={selectedEditElement} selectedSlide={selectedSlide}
                                    handleChange={handleChange} setSelectedElement={setSelectedEditElement}/>
      case 'IMAGE':
        return <ImageElementOptions setOpenElementEditAction={setOpenElementEditAction} openElementEditAction={openElementEditAction} slides={slides} selectedElement={selectedEditElement} selectedSlide={selectedSlide}
                                    handleChange={handleChange} setSelectedElement={setSelectedEditElement}/>
      case 'YOUTUBE':
        return <YoutubeEditBlock setOpenElementEditAction={setOpenElementEditAction} openElementEditAction={openElementEditAction} slides={slides} selectedElement={selectedEditElement} selectedSlide={selectedSlide}
                                 handleChange={handleChange} setSelectedElement={setSelectedEditElement}/>
      default:
        return <div>Wrong Element Type</div>
    }
  }

  if (!selectedEditElement.id)
    return <Row className='mb-2'>
      <Col lg={12}>
        <div className='font-size-13 font-weight-bold mb-2'>No element selected yet</div>
      </Col>
    </Row>
  return <Row className='mb-2'>
    {showElementOptions()}
  </Row>
}

const SlidesConfigBlock = (props) => {
  const {selectedSlide, slides, handleChange} = props
  const [slideName, setSlideName] = useState('')
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [slideBackgroundColor, setSlideBackgroundColor] = useState('#fff')
  const [slideBackgroundImage, setSlideBackgroundImage] = useState(null)

  const [thumb, setThumb] = useState(null)
  const [imageOrigin, setImageOrigin] = useState('')
  const [showAssetsDialog, setShowAssetsDialog] = useState(false)

  useEffect(() => {
    assetThumb(slideBackgroundImage, setThumb, imageOrigin)
    //eslint-disable-next-line
  }, [slideBackgroundImage])

  useEffect(() => {
    setSlideBackgroundColor(selectedSlide.background?.color)
    setSlideName(selectedSlide.name)
    if (selectedSlide?.background?.uuid)
      setSlideBackgroundImage(selectedSlide.background?.uuid)
    else {
      setThumb(null)
      setSlideBackgroundImage(null)
    }
    //eslint-disable-next-line
  }, [selectedSlide.id])


  // const handleSaveSlideTemplate = () =>{
  //   const initializedElementsValues = JSON.parse(JSON.stringify([...slideElements])) //deep array clone
  //
  //   initializedElementsValues.forEach((currentElement)=>{
  //     if (currentElement.type === 'TEXT')
  //       currentElement.data.text ='Enter your text'
  //     else if (currentElement.type === 'IMAGE')
  //     {
  //       currentElement.data.uuid = ''
  //       currentElement.data.from = ''
  //     }
  //       currentElement.id = makeid()
  //   })
  //
  //   const payload = {
  //     title:selectedSlide.name,
  //     description:`Template from story ${configurationBlocks.title}`,
  //     //TODO: To be changed, implemented it due to old data after refactoring
  //     storyType:configurationBlocks.storyType ?configurationBlocks.storyType : 'SLIDESHOW',
  //     aspectRatio:configurationBlocks.aspectRatio,
  //     orientation:configurationBlocks.orientation,
  //     slideDetails:{
  //       duration:selectedSlide.duration,
  //       animation:selectedSlide.animation,
  //       elements: [...initializedElementsValues]
  //     }
  //   }
  //
  //   if (initializedElementsValues.length >0 )
  //   {
  //     axios
  //       .post(`${API_URL}/slide_templates`, payload, getAxiosDefaultConfig())
  //       .then(() => {
  //         toastr.success('Slide template saved')
  //       })
  //       .catch((e)=>{
  //         toastr.error(e)
  //         console.log(e)
  //       });
  //   }
  //   else {
  //     toastr.warning('There are no Elements')
  //   }
  // }

  const handleSlideBackgroundColor = (val) => {
    setSlideBackgroundColor(val.hex)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    slidesTmp[slideIndex] = {
      ...slidesTmp[slideIndex],
      background: {...slidesTmp[slideIndex].background, color: val.hex}
    }
    handleChange(slidesTmp)
  }

  const handleChangeTitle = (e) => {
    setSlideName(e.target.value)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], name: e.target.value}
    handleChange(slidesTmp)
  }

  const handleSlideBackgroundImage = (imageUuid, assetFrom) => {
    setImageOrigin(assetFrom)
    setSlideBackgroundImage(imageUuid)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    slidesTmp[slideIndex] = {
      ...slidesTmp[slideIndex],
      background: {
        ...slidesTmp[slideIndex].background,
        uuid: imageUuid,
        from: assetFrom,
        size: 'cover',
        position: 'center center'
      }
    }
    handleChange(slidesTmp)
  }

  const handleClearImage = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSlideBackgroundImage(null)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], background: {...slidesTmp[slideIndex].background, uuid: null}}
    handleChange(slidesTmp)
  }

  const handleClearColor = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSlideBackgroundColor('#fff')
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], background: {...slidesTmp[slideIndex].background, color: '#fff'}}
    handleChange(slidesTmp)
  }

  if (!selectedSlide.id)
    return <Row className='mb-2'>
      <Col lg={12}>
        <div className='font-size-13 font-weight-bold mb-2'>No slide selected yet</div>
      </Col>
    </Row>

  return <>
    <Row className='mb-2' onClick={() => setShowColorPicker(false)}>
      <Col lg={12}>
        <div className='font-size-13 font-weight-bold mb-2'>Title</div>
      </Col>
      <Col lg={12}>
        <Input value={slideName} onChange={handleChangeTitle}/>
      </Col>
    </Row>
    <Row className='mb-2'>
      <Col lg={12}>
        <div className='font-size-13 font-weight-bold mb-2'>Background</div>
      </Col>
      <Col lg={12}>
        <div className='font-size-13 font-weight-light mb-2'>Background Color</div>
      </Col>
      <Col lg={12}>
        <div className='slidePickColor' style={{backgroundColor: slideBackgroundColor}}
             onClick={() => setShowColorPicker(!showColorPicker)}/>
        {showColorPicker &&
        <ClickAwayListener onClickAway={() => setShowColorPicker(!showColorPicker)}>
          <SketchPicker
            color={slideBackgroundColor}
            disableAlpha={true}
            onChangeComplete={handleSlideBackgroundColor}
          />
        </ClickAwayListener>
        }
        {slideBackgroundColor && slideBackgroundColor !== '#fff' &&
        <i className='bx bx-trash font-size-24 mdi-cursor-pointer' style={{position: 'absolute', bottom: 0, right: 10}}
           onClick={handleClearColor}/>}
      </Col>
      <Col lg={12} className='mt-2'>
        <div className='font-size-13 font-weight-light mb-2'>Background Image</div>
      </Col>

      <Col lg={12}>
        <div className='slideBackgroundImage' onClick={() => setShowAssetsDialog(!showAssetsDialog)}>
          {thumb &&
          <img src={thumb} alt={'cover'} width={'100%'} height={'100%'}/>
          }
          {!thumb && <i className='bx bx-image' style={{fontSize: '80px'}}/>}
        </div>
        {thumb &&
        <i className='bx bx-trash font-size-24 mdi-cursor-pointer' style={{position: 'absolute', bottom: 0, right: 10}}
           onClick={handleClearImage}/>
        }
      </Col>
      {/*<Col lg={12} className='mt-4'>*/}
      {/*  <Button color='primary' onClick={handleSaveSlideTemplate}>Save as Template</Button>*/}
      {/*</Col>*/}
      <SlideAssetsDialog open={showAssetsDialog} handleChange={handleSlideBackgroundImage}
                         handleClose={() => setShowAssetsDialog(!showAssetsDialog)}/>
    </Row>
  </>
}
const TimeLineThumb = (props) =>{
  const {element} = props

  const [currentThumb,setCurrentThumb] = useState(null)

  useEffect(()=>{
    assetThumb(element.data.uuid,setCurrentThumb,element.data.from)
    //eslint-disable-next-line
  },[element.uuid])

  if (!currentThumb)
    return <div style={{borderRadius:'5px',width:'20px',height:'20px',display:'flex',alignItems:'center',alignContent:'center',justifyContent:'center',border:'1px solid #000',backgroundColor:'red'}}/>

  return <div style={{borderRadius:'5px',width:'20px',height:'20px',display:'flex',alignItems:'center',alignContent:'center',justifyContent:'center',border:'1px solid #000'}} id={`element-${element.id}`}>
          <img src={currentThumb} width={'18px'} alt={`element-${element.id}`}/>
          <UncontrolledTooltip target={`element-${element.id}`} placement={'top'}>
            <img src={currentThumb} width={'100%'} alt={`element-${element.id}`}/>
          </UncontrolledTooltip>
        </div>
}
const TimelineConfigBlock = (props) => {
  const {slides, selectedSlide, handleChange} = props
  const [elements, setElements] = useState([])
  const [duration, setDuration] = useState(3)
  useEffect(() => {
    if (slides && selectedSlide.id) {
      const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
      if (slideIndex !== -1) {
        const slidesTmp = [...slides]
        // const elementIndex = slidesTmp[slideIndex]?.elements.findIndex((element) => element.id === selectedElement.id)
        // const elementsTmp = [...slidesTmp[slideIndex]?.elements]
        setElements(slidesTmp[slideIndex]?.elements)
        setDuration(slidesTmp[slideIndex].duration)
      }
    }
    //eslint-disable-next-line
  }, [slides, selectedSlide.id])


  return <Row>
    <Col lg={12}>
      <div className='font-size-13 font-weight-bold mb-2'>Elements Timeline</div>
    </Col>
    <Col lg={12} className='timeLineContainer'>
      {selectedSlide.id && elements.map((element) => {
        return <div className='elementAnimationRow' key={`${selectedSlide.id}-${element.id}`}>
          <>
            {element.type === 'TEXT' &&
            <>
              <div className='text-muted font-size-14 text-truncate'>{element.data.text}</div>
            </>}
            {element.type === 'YOUTUBE' &&
            <>
              <div className='text-muted font-size-14'>{`${element.type}(${element.data.id})`}</div>
            </>}
            {element.type === 'IMAGE' &&
            <>
              <TimeLineThumb element={element}/>
            </>}
            <ElementTimelineBlock slideDuration={duration} slideId={selectedSlide.id} element={element}
                                  handleChange={handleChange} slides={slides}/>
          </>
        </div>
      })}
    </Col>
  </Row>
}

const ElementTimelineBlock = (props) => {
  const {element, slideDuration, slides, slideId, handleChange} = props
  const [value, setValue] = useState([0, 20])

  useEffect(() => {
    setValue([element.animation.enterAnimationDelay / 1000, element.animation.leaveAnimationDelay / 1000])
    //eslint-disable-next-line
  }, [element.id])

  const handleChangeTime = (event, newValue) => {
    setValue(newValue)
    const slideIndex = slides.findIndex((slide) => slide.id === slideId)
    if (slideIndex !== -1) {
      const slidesTmp = [...slides]
      const elementIndex = slidesTmp[slideIndex]?.elements.findIndex((foundElement) => foundElement.id === element.id)
      const elementsTmp = [...slidesTmp[slideIndex]?.elements]
      elementsTmp[elementIndex].animation.enterAnimationDelay = newValue[0] * 1000
      elementsTmp[elementIndex].animation.leaveAnimationDelay = newValue[1] * 1000
      slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
      handleChange(slidesTmp)
    }
  }

  function valuetext(value) {
    return `${value} s`;
  }

  return <Slider
    value={value}
    onChange={handleChangeTime}
    max={slideDuration / 1000}
    step={0.25}
    valueLabelDisplay="auto"
    aria-labelledby="range-slider"
    getAriaValueText={valuetext}
    defaultValue={value}
  />

}

const AnimationConfigBlock = (props) => {
  const {slides, handleChange, selectedSlide, selectedElement} = props
  const [, setElements] = useState([])
  const [slideAnimation, setSlideAnimation] = useState({enterAnimation: null, leaveAnimation: null, duration: 0})
  const [elementAnimation, setElementAnimation] = useState({
    enterAnimation: null,
    leaveAnimation: null,
    enterTime: 0,
    duration: 0
  })
  const [slideMinimumDuration, setSlideMinimumDuration] = useState(5)
  useEffect(() => {
    if (slides && selectedSlide.id) {
      const slideIndex = slides?.findIndex((slide) => slide.id === selectedSlide.id)
      if (slideIndex !== -1) {
        const slidesTmp = [...slides]
        setElements(slidesTmp[slideIndex]?.elements)
        const slideEnterAnimation = ANIMATION_ENTER_TYPES.filter((animationType) => animationType.value === slidesTmp[slideIndex]?.animation?.enterAnimation)
        const slideLeaveAnimation = ANIMATION_LEAVE_TYPES.filter((animationType) => animationType.value === slidesTmp[slideIndex]?.animation?.leaveAnimation)
        setSlideAnimation({
          enterAnimation: slideEnterAnimation[0],
          leaveAnimation: slideLeaveAnimation[0],
          duration: slidesTmp[slideIndex]?.duration / 1000
        })
        setSlideMinimumDuration(slidesTmp[slideIndex]?.animation?.editorSlideDuration)
        if (selectedElement.id) {
          const elementIndex = slidesTmp[slideIndex]?.elements?.findIndex((element) => element.id === selectedElement.id)
          const elementsTmp = [...slidesTmp[slideIndex]?.elements]
          setElementAnimation({...elementAnimation, duration: elementsTmp[elementIndex]?.editorElementDuration})
          const elementEnterAnimation = ANIMATION_ENTER_TYPES.filter((animationType) => animationType.value === elementsTmp[elementIndex]?.animation?.enterAnimation)
          const elementLeaveAnimation = ANIMATION_LEAVE_TYPES.filter((animationType) => animationType.value === elementsTmp[elementIndex]?.animation?.leaveAnimation)
          // const elementDuration = elementsTmp[elementIndex]?.animation?.editorElementDuration
          setElementAnimation({
            ...elementAnimation,
            enterAnimation: (elementEnterAnimation[0] ? elementEnterAnimation[0] : null),
            leaveAnimation: elementLeaveAnimation[0] ? elementLeaveAnimation[0] : null,
          })
        } else {
          setElementAnimation({enterAnimation: 0, enterTime: 0, leaveAnimation: 0, duration: 0})
        }
      }
    }
    //eslint-disable-next-line
  }, [slides, selectedSlide.id, selectedElement.id])

  const handleChangeSlideEnterAnimation = (animationSelected) => {
    setSlideAnimation({...slideAnimation, enterAnimation: animationSelected})
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    slidesTmp[slideIndex].animation = {...slidesTmp[slideIndex].animation, enterAnimation: animationSelected.value}
    handleChange(slidesTmp)
  }

  const handleChangeSlideLeaveAnimation = (animationSelected) => {
    setSlideAnimation({...slideAnimation, leaveAnimation: animationSelected})
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    slidesTmp[slideIndex].animation = {...slidesTmp[slideIndex].animation, leaveAnimation: animationSelected.value}
    handleChange(slidesTmp)
  }

  const handleChangeElementEnterAnimation = (animationSelected) => {
    setElementAnimation({...elementAnimation, enterAnimation: animationSelected})
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].animation = {
      ...elementsTmp[elementIndex].animation,
      enterAnimation: animationSelected.value
    }
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeElementLeaveAnimation = (animationSelected) => {
    setElementAnimation({...elementAnimation, leaveAnimation: animationSelected})
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].animation = {
      ...elementsTmp[elementIndex].animation,
      leaveAnimation: animationSelected.value
    }
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleSlideDuration = (event) => {
    const slideDuration = event.target.value //users duration
    setSlideAnimation({...slideAnimation, duration: slideDuration})
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    slidesTmp[slideIndex].duration = parseInt(slideDuration) * 1000
    slidesTmp[slideIndex].animation.leaveAnimationDelay = slidesTmp[slideIndex].duration + (slidesTmp[slideIndex].animation.enterAnimationDelay - 1000)
  }


  return <Row>
    <Col lg={12}>
      <div className='font-size-13 font-weight-bold mb-2'>Slide Animation</div>
    </Col>
    <Col lg={4}>
      <div className='font-size-13 font-weight-light mb-1'>Enter Animation</div>
    </Col>
    <Col lg={8}>
      <FormGroup>
        <Select
          value={slideAnimation?.enterAnimation}
          options={ANIMATION_ENTER_TYPES}
          isDisabled={!selectedSlide.id}
          isOptionDisabled={!selectedSlide.id}
          placeholder="Select Animation"
          onChange={handleChangeSlideEnterAnimation}
        />
      </FormGroup>
    </Col>
    <Col lg={4}>
      <div className='font-size-13 font-weight-light mb-1'>Leave Animation</div>
    </Col>
    <Col lg={8} className='mb-1'>
      <FormGroup>
        <Select
          value={slideAnimation?.leaveAnimation}
          options={ANIMATION_LEAVE_TYPES}
          isDisabled={!selectedSlide.id}
          isOptionDisabled={!selectedSlide.id}
          placeholder="Select Animation"
          onChange={handleChangeSlideLeaveAnimation}
        />
      </FormGroup>
    </Col>
    <Col lg={9}>
      <div className='font-size-13 font-weight-light mb-1'>Slide Duration (seconds)</div>
    </Col>
    <Col lg={3}>
      <FormGroup>
        <Input disabled={!selectedSlide.id} min={slideMinimumDuration} type={'number'} value={slideAnimation?.duration}
               onChange={handleSlideDuration}/>
      </FormGroup>
    </Col>
    <Col lg={12}>
      <div className='font-size-13 font-weight-bold mb-2'>Selected Element Animation</div>
    </Col>
    <Col lg={4}>
      <div className='font-size-13 font-weight-light mb-1'>Enter Animation</div>
    </Col>
    <Col lg={8}>
      <FormGroup>
        <Select
          value={elementAnimation?.enterAnimation}
          options={ANIMATION_ENTER_TYPES}
          isDisabled={!selectedElement.id}
          isOptionDisabled={!selectedElement.id}
          placeholder="Select Animation"
          onChange={handleChangeElementEnterAnimation}
        />
      </FormGroup>
    </Col>
    <Col lg={4}>
      <div className='font-size-13 font-weight-light mb-1'>Leave Animation</div>
    </Col>
    <Col lg={8}>
      <FormGroup>
        <Select
          value={elementAnimation?.leaveAnimation}
          options={ANIMATION_LEAVE_TYPES}
          isDisabled={!selectedElement.id}
          isOptionDisabled={!selectedElement.id}
          placeholder="Select Animation"
          onChange={handleChangeElementLeaveAnimation}
        />
      </FormGroup>
    </Col>
    {/*<Col lg={12}>*/}
    {/*  <div className='font-size-13 font-weight-bold mb-2'>Elements Animation</div>*/}
    {/*</Col>*/}
    {/*<Col lg={12}>*/}
    {/*  {selectedSlide.id && elements.map((element)=>{*/}
    {/*    return <div className='elementAnimationRow' key={element.id} onClick={()=> {*/}
    {/*      setSelectedElement(element)*/}
    {/*      console.log(element)*/}
    {/*    }}>{element.type}</div>*/}
    {/*  })}*/}
    {/*</Col>*/}
  </Row>
}

const StoryConfigurationBlock = (props) => {
  const [selected, setSelected] = useState('general')
  const {
    handleUpdate,
    slides,
    loading,
    selectedSlide,
    openElementEditAction,
    setOpenElementEditAction,
    storyTags,
    setStoryTags,
    allStoryTags,
    configurationBlocks,
    selectedEditElement,
    setSelectedEditElement,
    handleChangeConfigurationBlocks,
    handleChangeSlide,
    handleDeleteItem
  } = props

  useEffect(() => {
    if (selectedEditElement?.id) {
      setSelected('element')
    }
  }, [selectedEditElement])

  return <div style={{
    right: '0',
    top: '70px',
    width: '380px',
    position: 'fixed',
    height: 'calc(100vh - 5.5em)',
    backgroundColor: 'white',
    padding: '5px'
  }}>
    <Container>
      <Row className='mb-4'>
        <Col lg={12}>
          <div className='font-size-15 font-weight-bold'>Settings</div>
        </Col>
      </Row>
      <Row>
        <OptionBox title='Story Settings' icon='bx bx-book-content' selected={selected === 'general'}
                   onClick={() => setSelected('general')} {...props}/>
        <OptionBox title='Cover Page' icon='bx bx-image' selected={selected === 'cover'}
                   onClick={() => setSelected('cover')} {...props}/>
        <OptionBox title='Animation' icon='bx bx-timer' selected={selected === 'animations'}
                   onClick={() => setSelected('animations')} {...props}/>
        <OptionBox title='Edit Slide' icon='bx bx-layer' selected={selected === 'slide'}
                   onClick={() => setSelected('slide')} {...props}/>
        <OptionBox title='Edit Element' icon='bx bxs-widget' selected={selected === 'element'}
                   onClick={() => setSelected('element')} {...props}/>
        <OptionBox title='Timeline' icon='bx bxs-layer' selected={selected === 'timeline'}
                   onClick={() => setSelected('timeline')} {...props}/>
      </Row>
      <Row className='mt-4 mb-2'>
        <Col lg={12}>
          <Divider/>
        </Col>
      </Row>
      <div style={{height: 'auto', overflow: 'auto',overflowX:'hidden', minHeight: '50px', maxHeight: 'calc(100vh - 400px)'}}>
        <Row style={{display: selected === 'cover' ? '' : 'none'}}>
          <StoryCover configurationBlocks={configurationBlocks} handleChange={handleChangeConfigurationBlocks}/>
        </Row>

        {<div style={{display: selected === 'slide' ? '' : 'none'}}>
          <SlidesConfigBlock slides={slides} configurationBlocks={configurationBlocks} selectedSlide={selectedSlide}
                             handleChange={handleChangeSlide}/>
        </div>}

        {<div style={{display: selected === 'animations' ? '' : 'none'}}>
          <AnimationConfigBlock slides={slides} setSelectedElement={setSelectedEditElement}
                                selectedElement={selectedEditElement} selectedSlide={selectedSlide}
                                handleChange={handleChangeSlide}/>
        </div>}

        {<div style={{display: selected === 'timeline' ? '' : 'none'}}>
          <TimelineConfigBlock slides={slides} setSelectedElement={setSelectedEditElement}
                               selectedElement={selectedEditElement} selectedSlide={selectedSlide}
                               handleChange={handleChangeSlide}/>
        </div>}

        {selected === 'general' &&
        <GeneralBlock configurationBlocks={configurationBlocks} storyTag={storyTags}
          setStoryTags={setStoryTags}
                      allStoryTags={allStoryTags}
                      {...props}/>
        }

        {selected === 'element' &&
        <ElementConfigBlock slides={slides} selectedEditElement={selectedEditElement}
                            setSelectedEditElement={setSelectedEditElement}
                            openElementEditAction={openElementEditAction}
                            setOpenElementEditAction={setOpenElementEditAction}
                            selectedSlide={selectedSlide}
                            handleChange={handleChangeSlide}/>
        }

        <div style={{position: 'absolute', bottom: '40px', width: '100%'}}
             className='d-flex justify-content-center align-items-center align-content-center'>
          <div className='d-flex'>
            <Button color='danger' onClick={handleDeleteItem} disabled={loading} style={{marginRight: '10px'}}>Delete
              story</Button>
            <Button color='primary' onClick={handleUpdate} disabled={loading}>Save Changes</Button>
          </div>
        </div>
      </div>
    </Container>
  </div>

}

export default StoryConfigurationBlock
