import React from "react";
import {useHistory, useParams} from "react-router";
import moment from "moment";

const ResponseListItem = ({ item }) => {
  const {uuid} = useParams()
  let history = useHistory()
  let creationDate = new Date(item.createdAt+'Z')
  return (
    <tr className='c-item-list' onClick={()=>history.push(`/surveys/edit/${uuid}/responses/list/${item.uuid}`)}>
      <td>
        {moment(creationDate).format("DD/MM/YYYY, h:mm a")}
      </td>
      <td>
        {item.email?item.email:'-'}
      </td>
      <td>
        {item.firstName?item.firstName:'-'}
      </td>
      <td>
        {item.lastName?item.lastName:'-'}
      </td>
      <td>
        {item.organization?item.organization:'-'}
      </td>
    </tr>
  );
};

export default ResponseListItem;
