import React from 'react';
import {withRouter} from "react-router-dom";
import MultipleChoices from "../common/MultipleChoices";

const LinearScale = (props) => {
  const {field, fieldNumber} = props

  return <MultipleChoices field={field} fieldNumber={fieldNumber} />
}

export default withRouter(LinearScale);
