import React, {useCallback, useEffect, useMemo, useState} from 'react';
import BaseContent from "../../../../components/Common/BaseContent";
import {connect} from "react-redux";
import {Schema} from "../../../../store/core/schema";
import {EntityType} from "../../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import VocCardSettings from "./components/VocCardSettings";
import {useForm, useListToggle, useToggle, useContainers} from "../../../../helpers/hooks";
import ListSettings from '../../../../components/Common/ListSettings'
import ListToggle from '../../../../components/Common/ListToggle'
import axios from 'axios'
import {API_URL} from '../../../../config'
import {getAxiosDefaultConfig} from '../../../../utils'
import toastr from 'toastr'
import BaseDialog from '../../../../components/Common/BaseDialog'
import {Col} from 'reactstrap'
import PaginationComponent from 'react-reactstrap-pagination'
import EditVocabularyModal from "./components/EditVocabularyModal";
import DeleteDialog from "../../../Containers/DeleteDialog";
import ImportTerms from "./components/ImportTerms";
import UsagesReport from "./components/UsagesReport";

const entityType = EntityType.Vocabulary;

const breadcrumbs = [
  { title: "Settings", link: "/settings" },
  { title: "Content Model", link: "/settings/content-model" },
  { title: "Vocabularies", link: "#" },
];

const Vocabularies = (props) =>
{
  const uuid = props.match.params.id;
  const containerGetter = props.context.Container.get;
  const {context} = props;
  const currentProperty = context.Vocabulary.get.result;
  const [openModal, toggleModal] = useToggle(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const [isList, toggleList] = useListToggle();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [vocabularies, setVocabularies] = useState([]);
  const [vocabulariesFlat, setVocabulariesFlat] = useState([]);
  const [vocPage, setVocPage] = useState(0);
  const [selectedVocs, setSelectedVocs] = useState([]);
  const selectedVoc = selectedVocs[0];

  const [vocabularyForm, handleChangeVocabulary, updateInitial] = useForm({});

  const [openEditVoc, setOpenEditVoc] = useState(false);
  const [deleteVoc, setDeleteVoc] = useState(false);

  const [openImport, setOpenImport] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [termsPage, setTermsPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [terms, setTerms] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [termsFlatData, setTermsFlatData] = useState([]);

  const [openUsages, setOpenUsages] = useState(false);
  const [usages, setUsages] = useState();

  const {handleContainerClick, handleContextClick} = useContainers({
    history: props.history,
    thisContainerUuid: uuid,
    setEntity,
    containerGetter,
  });

  const handleVocClick = (route) => {
    props.history.push({
      pathname: route,
    });
  };

  const handleVocSelect = (e, setting) => {
    const multi = e.metaKey || e.ctrlKey;
    setSelectedVocs((prevState) => {
      return !multi ? [setting] : prevState.includes(setting.uuid) ? prevState.filter((x) => x !== setting.uuid) : [...prevState, setting];
    });
  };

  const handleContextVocClick = (e, setting) => {
    setSelectedVocs((prevState) => {
      const exist = prevState.includes(setting.uuid);
      if (prevState.length > 1 && exist) {
        return [...prevState.filter((x) => x !== setting.uuid), setting.uuid];
      } else {
        return [setting];
      }
    });
  };

  const handlePageClick = useCallback(() => {
    setSelectedVocs([selectedVoc] || []);
  }, [selectedVoc]);

  const getAllVocabularies = () => {
    axios
      .get(`${API_URL}/vocabularies?page=${vocPage}`, getAxiosDefaultConfig())
      .then((response) => {
        setVocabularies(response.data.content);
        setVocabulariesFlat(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(true);
        setIsLoading(false);
        toastr.error(e);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getAllVocabularies();
    // eslint-disable-next-line
  }, []);

  const handleEntityClick = useCallback(
    (entity) => {
      setEntity(entity);
      toggleModal();
    },
    [toggleModal],
  );

  useEffect(() => {
    setEntity(currentProperty);
  }, [currentProperty]);

  const handleCreate = useCallback(() => {
    setEntity(Schema[entityType]);
    toggleModal();
  }, [toggleModal]);

  const handleCreateVocabulary = () => {
    setIsLoading(true);
    axios
      .post(`${API_URL}/vocabularies`, vocabularyForm, getAxiosDefaultConfig())
      .then(() => {
        setIsLoading(false);
        getAllVocabularies();
        toggleModal();
        updateInitial({});
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
        toastr.error(error);
      });
  };

  const handleUpdateVocabulary = (values) => {
    setIsLoading(true);
    axios
      .put(`${API_URL}/vocabularies/${selectedVoc.uuid}`, values, getAxiosDefaultConfig())
      .then(() => {
        setIsLoading(false);
        getAllVocabularies();
        toastr.success(`Successfully updated`);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleDeleteVocabulary = () => {
    setIsLoading(true);
    axios
      .delete(`${API_URL}/vocabularies/${selectedVoc.uuid}`, {
        ...getAxiosDefaultConfig(),
        data: {},
      })
      .then(() => {
        toastr.success(`Successfully deleted`);
        setIsLoading(false);
        getAllVocabularies();
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleExport = () => {
    setIsLoading(true);
    const payload = {
      format: "XLSX",
    };
    axios
      .post(`${API_URL}/vocabularies/${selectedVoc.uuid}/export`, payload, getAxiosDefaultConfig())
      .then((response) => {
        let xlsxURI = window.URL.createObjectURL(response[0]);
        let downloadLink = document.createElement("a");
        downloadLink.href = xlsxURI;
        downloadLink.download = selectedVoc.name ? `voc-${selectedVoc.name}.xlsx` : `voc-${selectedVoc?.label}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toastr.success(`Successfully exported`);
        setIsLoading(false);
      })
      .catch((error) => {
        toastr.error(error.message);
        setIsLoading(false);
      });
  };

  const handleUsage = async () => {
    const payload = {};
    await axios
      .post(`${API_URL}/vocabularies/${selectedVoc.uuid}/usages`, payload, getAxiosDefaultConfig())
      .then((res) => {
        if (res.data.containers.length > 0 || res.data.datastreams.length > 0) {
          setOpenUsages(true);
          setUsages(res.data);
        } else {
          toastr.warning("No usage of the selected vocabulary");
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };  

  const handleUsageCm = async () => {
    const payload = {};
    await axios
      .post(`${API_URL}/vocabularies/${selectedVoc.uuid}/usages_cm`, payload, getAxiosDefaultConfig())
      .then((res) => {
        if (res.data.containerTypes.length > 0 || res.data.datastreamTypes.length > 0) {
          setOpenUsages(true);
          setUsages(res.data);
        } else {
          toastr.warning("No usage of the selected vocabulary in content model");
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };



  const getTerms = () => {
    axios
      .get(`${API_URL}/vocabularies/${uuid}/terms?page=${termsPage}`, getAxiosDefaultConfig())
      .then((response) => {
        setTerms(response.data.content);
        setTermsFlatData(response.data);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const cards = useMemo(() => {
    return vocabularies?.map((vocabulary) => ({
      label: vocabulary.name,
      icon: "bx-book",
      route: `/settings/content-model/vocabulary/${vocabulary.uuid}`,
      handleClick: () => handleEntityClick(vocabulary),
      uuid: vocabulary.uuid,
      description: vocabulary.description,
      name: vocabulary.name,
      vocUri: vocabulary.vocUri,
      id: vocabulary.id,
    }));
  }, [handleEntityClick, vocabularies]);

  const list = useMemo(() => {
    return vocabularies?.map((vocabulary) => ({
      label: vocabulary.name,
      _description: vocabulary.description,
      _vocUri: vocabulary.vocUri,
      handleClick: () => handleEntityClick(vocabulary),
      uuid: vocabulary.uuid,
      description: vocabulary.description,
      name: vocabulary.name,
      vocUri: vocabulary.vocUri,
      id: vocabulary.id,
    }));
  }, [handleEntityClick, vocabularies]);

  const listColumns = [
    {name: "Name", prop: "label"},
    {name: "Description", prop: "_description"},
    {name: "Vocabulary URI", prop: "_vocUri"},
  ];

  const handleSelectPage = (page) => {
    setVocPage(page - 1);
  };

  return (
    <div onClick={() => handlePageClick()} style={{minHeight: "100vh"}} onContextMenu={(e) => e.preventDefault()}>
      <BaseContent
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
        handleContextClick={handleContextClick}
        handleContainerClick={handleContainerClick}
      >
        {!isList && (
          <VocCardSettings
            settings={cards}
            handleVocClick={handleVocClick}
            handleVocSelect={handleVocSelect}
            handleContextVocClick={handleContextVocClick}
            selectedVoc={selectedVoc}
            setOpenEditVoc={setOpenEditVoc}
            openEditVoc={openEditVoc}
            setDeleteVoc={setDeleteVoc}
            deleteVoc={deleteVoc}
            setOpenImport={setOpenImport}
            openImport={openImport}
            handleExport={handleExport}
            handleUsage={handleUsage}
            handleUsageCm={handleUsageCm}
          />
        )}
        <EditVocabularyModal
          vocabulary={selectedVoc}
          setSelectedVocs={setSelectedVocs}
          open={openEditVoc}
          handleClose={() => setOpenEditVoc(!openEditVoc)}
          handleUpdate={handleUpdateVocabulary}
          handleDelete={() => setDeleteVoc(!deleteVoc)}
        />
        <DeleteDialog open={deleteVoc} title={"Delete Confirmation"} handleDelete={handleDeleteVocabulary} handleClose={() => setDeleteVoc(!deleteVoc)} question={`Are you sure you want to delete this vocabulary?`} />
        <ImportTerms open={openImport} handleClose={() => setOpenImport(!openImport)} uuid={selectedVoc?.uuid} refresh={getTerms} />
        {isList && <ListSettings contents={list} columns={listColumns} />}
        <BaseDialog
          title={"Create a vocabulary"}
          open={openModal}
          deleteAction={
            entity?.uuid
              ? () => {
                  handleDeleteVocabulary(entity);
                }
              : ""
          }
          primaryActionTitle={"Save"}
          primaryAction={
            entity?.uuid
              ? () => {
                  handleUpdateVocabulary(entity);
                }
              : () => {
                  handleCreateVocabulary(entity);
                }
          }
          extraClassName={"modal-full-height"}
          size={"lg"}
          handleClose={toggleModal}
        >
          <EntityDialog form={vocabularyForm} handleChange={handleChangeVocabulary} entityType={entityType} />
        </BaseDialog>
        {/*Pagination */}
        <Col xs={{size: 6, offset: 6}} className="responses-navigation">
          <PaginationComponent totalItems={vocabulariesFlat?.totalElements} pageSize={vocabulariesFlat?.pageable?.pageSize} onSelect={handleSelectPage} />
        </Col>
      </BaseContent>
      {usages && <UsagesReport selectedVoc={selectedVoc} open={openUsages} handleClose={() => setOpenUsages()} usages={usages} setUsages={setUsages} />}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {})(Vocabularies);
