import React, {useState} from "react";
import {Badge, Table} from "reactstrap";
import {starContainer} from "../../store/container/actions";
import {connect} from "react-redux";
import {useFiltered} from "../../helpers/hooks";
import moment from "moment";
import ActionsContextMenu from "./ActionsContextMenu";
import OverflowTip from "../../components/Common/OverflowTip";
import ArrowUpIcon from "@icons/material/ArrowUpIcon";
import ArrowDownIcon from "@icons/material/ArrowDownIcon";

function ContainerList(props) {
  const [query, setQuery] = useState("");
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
  const [menuIndex, setMenuIndex] = useState(null);
  const results = useFiltered(props.containers, query, ["label"]);
  const handleContextMenu = (e, container, key) => {
    e.preventDefault();
    props.handleContextClick(e, container);
    setPointerPosition({x: e.clientX, y: e.clientY});
    setMenuIndex(key);
  };

  const selectedContainer = {
    ...(menuIndex !== null && props.containers[menuIndex]),
    properties: props.currentContainer.properties,
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Name");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleTableHeadSort = (value) => {
    const isAsc = orderBy === value && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(value);
  };
  

  return (
    <React.Fragment>
      <div className={"w-100"}>
        <div className={"search-box-container"}>
          <form>
            <input type="text" className="form-control" value={query} placeholder={"Search.."} onChange={(e) => setQuery(e.target.value)} />
          </form>
        </div>
        <div className={"table-responsive"}>
          <Table className="project-list-table table-nowrap table-centered table-borderless">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th
                  scope="col"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    handleTableHeadSort("label");
                  }}
                >
                  Label{" "}
                  {orderBy === "label" ? (
                    order === "asc" ? (
                      <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                    ) : (
                      <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                    )
                  ) : (
                    <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                  )}
                </th>
                <th scope="col">Type</th>
                <th
                  scope="col"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    handleTableHeadSort("createdAt");
                  }}
                >
                  Created At{" "}
                  {orderBy === "createdAt" ? (
                    order === "asc" ? (
                      <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                    ) : (
                      <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                    )
                  ) : (
                    <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                  )}
                </th>
                <th scope="col">Creator</th>
                <th
                  scope="col"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    handleTableHeadSort("starred");
                  }}
                >
                  Status{" "}
                  {orderBy === "starred" ? (
                    order === "asc" ? (
                      <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                    ) : (
                      <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                    )
                  ) : (
                    <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {results &&
                stableSort(results, getComparator(order, orderBy)).map((container, key) =>
                {
                  const bgColor = container.color !== null && container.color !== undefined && container.color !== "#fff" ? `${container.color}8C` : "#fff";
                  let subtitle = "";
                  const cc = container.childCount;
                  const fc = container.datastreamCount;
                  if (cc && fc) {
                    subtitle = cc + ` folder${cc > 1 ? "s" : ""} and ` + fc + ` file${fc > 1 ? "s" : ""}`;
                  } else if (cc) {
                    subtitle = cc + ` folder${cc > 1 ? "s" : ""}`;
                  } else if (fc) {
                    subtitle = fc + ` file${fc > 1 ? "s" : ""}`;
                  } else {
                    subtitle = "";
                  }
                  const selected = props.isSelected(container.uuid);
                  const borderColor = selected ? "primary" : "light";
                  return (
                    <tr
                      // className={`${props.isSelected(container.uuid) && "bg-light"}`}
                      className={`cursor-pointer border border-${borderColor} ${props.isSelected(container.uuid) && "bg-border-primary"}`}
                      // style={{position: "relative", backgroundColor: `${container.color}8C`}}
                      style={{position: "relative", backgroundColor: bgColor}}
                      key={key}
                      onContextMenu={(e) => {
                        handleContextMenu(e, container, key);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (menuIndex !== key) {
                          props.handleContainerSelect(e, container);
                        }
                      }}
                      onDoubleClick={() => props.handleContainerClick(container)}
                    >
                      {/*isChecked(container.uuid)*/}
                      <td>{key + 1}</td>
                      <td style={{width:"100%",maxWidth:"650px",display: "inline-block"}}>
                        <h5 className="text-truncate font-size-14" id={"label" + key}>
                          <i className="font-weight-bold bx bx-folder mr-1 font-size-18" style={{display:"inline", float:"left"}}/>
                          <OverflowTip>{container.label}</OverflowTip>
                          {container.published && <i className="font-weight-bold bx bxs-check-circle text-success ml-1" />}
                        </h5>
                        <p className="text-muted mb-0">{subtitle}</p>
                      </td>
                      <td>
                        <Badge color={"primary"} pill>
                          {container.type.name}
                        </Badge>
                      </td>
                      <td>{moment(container.createdAt).format("Do MMMM YYYY")}</td>
                      <td>{`${container.createdBy.firstname} ${container.createdBy.lastname}`}</td>
                      <td>
                        {container.starred && <i className={`font-weight-bold bx bxs-star font-size-20`} style={{color: "#ffca00"}} />}
                        {!container.starred && (
                          <i
                            className={`font-weight-bold bx bxs-star font-size-20 text-light`}
                            // style={{ color: "#ffca00" }}
                          />
                        )}
                      </td>
                      {menuIndex === key && <ActionsContextMenu container={selectedContainer} setShowDeleteModal={props.setShowDeleteModal} setIsShown={setMenuIndex} position={pointerPosition} {...props} />}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(null, {
  starContainer,
})(ContainerList);
