import React, { useState } from "react";
import BaseContent from "../../components/Common/BaseContent";
import { Table } from "reactstrap";
import { usePaging } from "../../helpers/hooks";
import { API_URL } from "../../config";
import MoreButton from "../../components/Common/MoreButton";
import StorageListRow from "./storage-list-row";

const breadcrumbs = [{ title: "Storage", link: "#" }];

function StorageFiles() {
  const url = API_URL + "/storage/details";
  const [page, setPage] = useState(0);
  const [isLoading, data, content] = usePaging(url, {
    page,
    size: 20,
    identifier: "uuid",
  });

  return (
    <BaseContent isLoading={isLoading && content.length === 0} breadcrumbs={breadcrumbs}>
      <div className="table-responsive">
        <Table className="project-list-table table-nowrap table-centered table-borderless">
          <thead>
            <tr>
              <th scope="col"/>
              <th scope="col">Name</th>
              <th scope="col">Size</th>
              <th scope="col">Created at</th>
              <th scope="col">Creator</th>
            </tr>
          </thead>
          <tbody>
            {content.map((file, key) => (
              <StorageListRow key={key} index={key} file={file} />
            ))}
          </tbody>
        </Table>
      </div>
      <MoreButton
        isLoading={isLoading}
        handleMore={() => setPage(page + 1)}
        hasMore={!data.last}
      />
    </BaseContent>
  );
}

export default StorageFiles;
