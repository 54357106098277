import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Table} from "reactstrap";
import BusyIndicator from "../../components/Common/BusyIndicator";
import ContactListItem from "./ContactListItem";
import {EditAddNewContact} from "./dialogs/EditAddNewContact";
import ImportContacts from "./dialogs/ImportContacts";

const ContactsList = ({contacts, isLoading, reload, setSelectedEmails, selectedEmails, addressBook, isSearch}) => {

  const [editNewContactOpen, setContactOpen] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)
  const [importOpen, setImportOpen] = useState(false)
  const [checkedAll, setCheckedAll] = useState(false)

  useEffect(()=>{
    if (checkedAll) {
      setSelectedEmails([...contacts.content.map((c)=>c.uuid)])
    } else {
      setSelectedEmails([])
    }
    // eslint-disable-next-line
  },[checkedAll])

  const toggleContact = (uuid) => {
    const newEmails = [...selectedEmails]
    const emailIndex = selectedEmails.indexOf(uuid)
    if (emailIndex!==-1) {
      newEmails.splice(emailIndex,1)
    } else {
      newEmails.push(uuid)
    }
    setSelectedEmails(newEmails)
  }

  const handleContactClose = (refresh) => {
    setContactOpen(false)
    setSelectedContact(null)
    if (refresh) {
     reload&&reload()
    }
  }

  const handleContactsClose = (refresh) => {
    setImportOpen(false)
    if (refresh) {
      reload&&reload()
    }
  }

  const selectContact = (contact) => {
    setSelectedContact(contact)
  }

  useEffect(()=>{
    if (selectedContact) {
      setContactOpen(true)
    }
  },[selectedContact])

  if (isLoading) return <> </>

  return <>
    <EditAddNewContact open={editNewContactOpen} handleClose={handleContactClose} existingContact={selectedContact} />
    <ImportContacts open={importOpen} handleClose={handleContactsClose} />
      {contacts&&contacts.content&&contacts.content.length===0&&!isSearch&&<Row className='justify-content-center align-content-center' style={{minHeight:'70vh'}}>

        <Col xs={5}>
          <Row className='justify-content-center align-content-center'>
            <Col xs='auto'>
              <i className='bx bxs-contact' style={{fontSize: 72}}></i>
            </Col>
          </Row>
          <Row className='justify-content-center align-content-center mt-4'>
            <Col xs='auto'>
              <h5>No contacts yet</h5>
            </Col>
          </Row>
          <Row className='justify-content-center align-content-center mt-4'>
            <Col xs='auto'>
              <Button className='' color='primary' onClick={()=>setContactOpen(true)}> <i className='bx bxs-user-plus'></i> &nbsp; Create contact </Button>
            </Col>
            <Col xs='auto'>
              <Button className='' color='primary' onClick={()=>setImportOpen(true)}> <i className='bx bx-upload'></i>&nbsp; Import contacts </Button>
            </Col>
          </Row>
        </Col>

      </Row>}

      <Row xs={12} >
        <div className="table-responsive">
          {!isLoading&&contacts.content&&contacts.content.length!==0&&<Table className="project-list-table table-nowrap table-centered table-borderless table-zebra">
            <thead>
            <tr>
              <th scope="col" style={{ width: "100px" }}>
                <input
                  type={"checkbox"}
                  checked={checkedAll}
                  value={checkedAll}
                  onChange={(e)=>setCheckedAll(e.target.checked)}
                />
              </th>
              <th scope="col">First name</th>
              <th scope="col">Last name</th>
              <th scope="col">Email</th>
              <th scope="col">Organization</th>
            </tr>
            </thead>
            <tbody>
            {!isLoading&&contacts&&contacts.content.map((item, key) => (
              <ContactListItem
                key={key}
                item={item}
                index={key}
                selectContact={selectContact}
                selected={selectedEmails.includes(item.uuid)}
                toggleContact={toggleContact}
                addressBook={addressBook}
              />
            ))}
            </tbody>
          </Table>}

          {!isLoading&&contacts&&contacts.content.length===0&&isSearch&&<>
            <Row className='justify-content-center align-content-center' style={{minHeight:'70vh', width:'100%'}}>

              <Col xs={5}>
                <Row className='justify-content-center align-content-center'>
                  <Col xs='auto'>
                    <i className='bx bx-search-alt font-size-42'></i>
                  </Col>
                </Row>

                <Row className='justify-content-center align-content-center mt-2'>
                  <Col xs='auto'>
                    <h5>No matching results</h5>
                  </Col>
                </Row>
              </Col>

            </Row>
          </>}

          {isLoading&&<Row className='d-flex justify-content-center align-items-center align-content-center' style={{minHeight:320,width:'100%', position: 'relative'}}>
            <div className='d-flex'>
              <BusyIndicator busy={true}/>
            </div>
          </Row>}
        </div>
      </Row>

    {!addressBook&&<>
    <Button
      title={'Import contacts'}
      color={'secondary'}
      onClick={()=>setImportOpen(true)}
      className={
        'btn-radius d-flex align-items-center justify-content-center shadow-lg'
      }
      style={{
        position: 'fixed',
        margin: 16,
        bottom: 90,
        right: 16,
        borderRadius: 50,
        width: 40,
        height: 40,
        zIndex: 1
      }}
    >
      <i className='bx bx-upload font-size-16'></i>
    </Button>

    <Button
      title={'Add new contact'}
      color={'primary'}
      onClick={()=>setContactOpen(true)}
      className={
        'btn-radius d-flex align-items-center justify-content-center shadow-md'
      }
      style={{
        position: 'fixed',
        margin: 16,
        bottom: 16,
        right: 16,
        borderRadius: 50,
        width: 60,
        height: 60,
        zIndex: 1
      }}
      // disabled={!changed}
    >
      <i className='bx bx-plus font-size-24'></i>
    </Button></>}
  </>

};

export default ContactsList;
