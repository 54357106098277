import React, {useEffect, useState} from 'react';
import BaseContent from '../../../components/Common/BaseContent';
import WebsitesList from "./WebsitesList";
import axios from "axios";
import {API_URL} from "../../../config";
import {getAxiosDefaultConfig} from "../../../utils";
import Loader from "./components/Loader";
import {Col, Container, Row} from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import {useLocation} from "react-router";

const breadcrumbs = [{title: 'Websites', link: '/websites'}]

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Websites = (props) => {

  let query = useQuery()

  const pageVar = query.get('page')
  const [page, setPage] = useState(pageVar?pageVar:0)
  const [websites, setWebsites] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const getAllWebsites = (page) => {
    setIsLoading(true)
    axios.get(`${API_URL}/str/websites?page=${page}`,getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setWebsites(data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  useEffect(()=>{
    getAllWebsites(page)
    // eslint-disable-next-line
  },[page])

  const handleSelectPage = (page) => {
    setPage(page-1)
  }

  return (
    <BaseContent
      // isLoading={isLoading||!websites}
      breadcrumbs={breadcrumbs}
    >
      {(isLoading||!websites)&&<Loader />}

      {websites&&<Container style={{paddingTop: 30, minHeight: '60vh'}}>

      <WebsitesList result={websites.content} isLoading={isLoading} />

        <Row>
          <Col xs={{size:6,offset:6}} className='listing-navigation'>
            <PaginationComponent defaultActivePage={websites.pageable.pageNumber+1} totalItems={websites.totalElements} pageSize={websites.pageable.pageSize} onSelect={handleSelectPage} />
          </Col>
        </Row>

      </Container>}

    </BaseContent>
  )
}

export default Websites
