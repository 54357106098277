import React, {useState} from 'react'
import BaseDialog from '../../../../../components/Common/BaseDialog'
import {FormGroup} from '@material-ui/core'
import {Input, Label} from 'reactstrap'

const AddTerm = (props) =>{
  const {open,handleClose,handleCreate} = props
  const [values,setValues] = useState({termId:'',label:'',termUri:''})
  return <BaseDialog
    title={'Add a term'}
    primaryAction={()=>{
      handleCreate(values)
      handleClose()
    }}
    primaryActionTitle={'Add'}
    open={open}
    handleClose={handleClose}
    scrollable={true}
    size='md'
    // contents={contents}
  >
    <FormGroup>
      <Label>Term Id</Label>
      <Input type={'text'} onChange={(e)=>{setValues({...values,termId: e.target.value})}}/>
    </FormGroup>
    <FormGroup>
      <Label>Label</Label>
      <Input type={'text'} onChange={(e)=>{setValues({...values,label: e.target.value})}}/>
    </FormGroup>
    <FormGroup>
      <Label>Term Uri</Label>
      <Input type={'text'} onChange={(e)=>{setValues({...values,termUri: e.target.value})}}/>
    </FormGroup>
  </BaseDialog>
}

export default AddTerm