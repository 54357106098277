import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import "./assets/css/custom.css";
import { loginSuccess, logoutUser } from "./store/auth/login/actions";
import { getAllContainerTypes } from "./store/containerType/actions";
import { getAllDataStreamTypes } from "./store/dataStreamType/actions";
import { getAllProperties } from "./store/property/actions";
import { getAllPropertyTypes } from "./store/propertyType/actions";
import { HOME_PAGE } from "./config";
import { getAllUsers } from "./store/user/actions";
import { getAllTags } from "./store/tag/actions";
import { getAllGroups } from "./store/group/actions";
import {getAllPermissions} from './store/permissions/actions'
import {getAllMyPermissions} from './store/myPermissions/actions'
import { ToastContainer } from "react-toastr";
import "toastr/build/toastr.min.css";
import { useEntityErrorToast, useAppValidation } from "./helpers/hooks";
import { getAllNamespaces } from "./store/namespace/actions";
import { getAllPropertyGroups } from "./store/propertyGroup/actions";
import { getAllQualifiers } from "./store/qualifier/actions";
import { getAllSystemSettings } from "./store/systemSetting/actions";
import {getMyProfile} from './store/myProfile/actions'
import '../node_modules/react-quill/dist/quill.snow.css'

function App(props) {
  const {
    loginSuccess,
    getAllContainerTypes,
    getAllDataStreamTypes,
    getAllProperties,
    getAllPropertyTypes,
    getAllTags,
    getAllUsers,
    getAllGroups,
    getAllNamespaces,
    getAllPropertyGroups,
    getAllQualifiers,
    getAllPermissions,
    getAllMyPermissions,
    context,
    logoutUser,
    getAllSystemSettings,
    getMyProfile,
  } = props;

  const isValidating = useAppValidation(loginSuccess, logoutUser);

  React.useEffect(() => {
    if (context.Login.isAuth) {
      getAllContainerTypes();
      getAllDataStreamTypes();
      getAllProperties();
      getAllPropertyTypes();
      getAllUsers();
      getAllTags();
      getAllGroups();
      getAllNamespaces();
      getAllPropertyGroups();
      getAllQualifiers();
      getAllSystemSettings();
      getAllPermissions();
      getAllMyPermissions();
      getMyProfile();
    }
  }, [
    context.Login.isAuth,
    getAllContainerTypes,
    getAllDataStreamTypes,
    getAllGroups,
    getAllProperties,
    getAllPropertyTypes,
    getAllTags,
    getAllUsers,
    getAllNamespaces,
    getAllPropertyGroups,
    getAllQualifiers,
    getAllSystemSettings,
    getAllPermissions,
    getAllMyPermissions,
    getMyProfile,
  ]);

  useEntityErrorToast(props.context.Container);
  useEntityErrorToast(props.context.DataStream);

  if (isValidating) {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
    );
  }

  // const withNoMenu = ['surveys','screens','contacts']
  const withNoMenu = []

  return (
    <React.Fragment>
      <ToastContainer className={"toast-top-right"} />
      <BrowserRouter basename={HOME_PAGE}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              exact
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              noLeftSideBar={withNoMenu.filter((a)=>route.path.includes(a)).length!==0}
            />
          ))}
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  loginSuccess,
  logoutUser,
  getAllContainerTypes,
  getAllDataStreamTypes,
  getAllProperties,
  getAllPropertyTypes,
  getAllUsers,
  getAllTags,
  getAllGroups,
  getAllNamespaces,
  getAllPropertyGroups,
  getAllQualifiers,
  getAllSystemSettings,
  getAllPermissions,
  getAllMyPermissions,
  getMyProfile,
})(App);
