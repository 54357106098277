import React, {useEffect, useState} from 'react'
import {googleFonts} from '../../../../../../constants/enums'
import {Col, FormGroup, Input, Row} from 'reactstrap'
import {ClickAwayListener} from '@material-ui/core'
import {SketchPicker} from 'react-color'
import Select from 'react-select'

const TitleElementOptions = (props) => {
  const {selectedElement, slides, selectedSlide, handleChange} = props
  const [elementText, setElementText] = useState('')
  const [fontSize, setFontSize] = useState()
  const [textAlign, setTextAlign] = useState()
  const [fontColor,setFontColor] = useState(null)
  const [fontFamily,setFontFamily] = useState(null)
  const [fontWeight,setFontWeight] = useState(null)
  const [showColorPicker, setShowColorPicker] = useState(false)

  useEffect(() => {

    setElementText(selectedElement?.data?.text)
    setFontSize(selectedElement?.data?.fontSize)
    setFontWeight(selectedElement?.data?.fontWeight)
    setTextAlign(selectedElement?.data?.textAlign)
    setFontColor(selectedElement?.data?.color)
    const selectedFont = googleFonts.filter((googleFont)=>googleFont.value === selectedElement?.data?.fontFamily)
    if (selectedFont[0])
      setFontFamily(selectedFont[0])
    //eslint-disable-next-line
  }, [selectedElement.id])

  const handleChangeText = (e) => {
    setElementText(e.target.value)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))

    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.text = e.target.value

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeFontSize = (e) => {
    setFontSize(e.target.value)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.fontSize = parseInt(e.target.value)

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeFontColor = (val) => {
    setFontColor(val.hex)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.color = val.hex
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeTextAlignment = (align) => {
    setTextAlign(align)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]

    elementsTmp[elementIndex].data.textAlign = align

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeFontWeight = (e) => {
    const weight = e.target.value
    setFontWeight(weight)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.fontWeight = weight

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeFontFamily = (family) => {
    const selectedFont = googleFonts.filter((googleFont)=>googleFont.value === family.value)
    if (selectedFont[0])
      setFontFamily(selectedFont[0])
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.fontFamily = family.value

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  return <>
    <Col lg={12}>
      <div className='font-size-13 font-weight-bold mb-2'>Title</div>
    </Col>
    <Col lg={12}>
      <Input value={elementText} onChange={handleChangeText}/>
    </Col>
    <Col lg={4}>
      <Row className='align-items-center'>
        <Col lg={12}>
          <div className='font-size-13 font-weight-bold mt-2 mb-2'>Text Color</div>
        </Col>
        <Col lg={12}>
          <div className='slidePickColor' style={{backgroundColor: fontColor}}
               onClick={() => setShowColorPicker(!showColorPicker)}/>
          {showColorPicker &&
          <ClickAwayListener onClickAway={() => setShowColorPicker(!showColorPicker)}>
            <SketchPicker
              color={fontColor}
              disableAlpha={true}
              onChangeComplete={handleChangeFontColor}
            />
          </ClickAwayListener>
          }
        </Col>
      </Row>
    </Col>
    <Col lg={4}>
      <Row className='align-items-center'>
        <Col lg={12}>
          <div className='font-size-13 font-weight-bold mt-2 mb-2'>Font Size</div>
        </Col>
        <Col lg={10}>
          <Input type={'number'} value={fontSize} onChange={handleChangeFontSize}/>
        </Col>
      </Row>
    </Col>
    <Col lg={4}>
      <Row className='align-items-center'>
        <Col lg={12}>
          <div className='font-size-13 font-weight-bold mt-2 mb-2'>Font Weight</div>
        </Col>
        <Col lg={10}>
          <Input type={'number'} value={fontWeight} onChange={handleChangeFontWeight}/>
        </Col>
      </Row>
    </Col>
    <Col lg={12}>
      <div className='font-size-13 font-weight-bold mt-2 mb-2'>Text Alignment</div>
    </Col>
    <Col lg={12} className={'mt-2'}>
      <Row>
        <Col lg={'auto'} onClick={() => handleChangeTextAlignment('left')}>
          <i
            className={`bx bx-align-left font-size-18 ${textAlign === 'left' ? 'textAlignmentButtonSelected' : 'textAlignmentButton'}`}/>
        </Col>
        <Col lg={'auto'} onClick={() => handleChangeTextAlignment('center')}>
          <i
            className={`bx bx-align-middle font-size-18 ${textAlign === 'center' ? 'textAlignmentButtonSelected' : 'textAlignmentButton'}`}/>
        </Col>
        <Col lg={'auto'} onClick={() => handleChangeTextAlignment('right')}>
          <i
            className={`bx bx-align-right font-size-18 ${textAlign === 'right' ? 'textAlignmentButtonSelected' : 'textAlignmentButton'}`}/>
        </Col>
        <Col lg={'auto'} onClick={() => handleChangeTextAlignment('justify')}>
          <i
            className={`bx bx-align-justify font-size-18 ${textAlign === 'justify' ? 'textAlignmentButtonSelected' : 'textAlignmentButton'}`}/>
        </Col>
      </Row>
    </Col>
    <Col lg={12}>
      <div className='font-size-13 font-weight-bold mt-2 mb-2'>Font Family</div>
    </Col>
    <Col lg={8}>
      <FormGroup>
        <Select
          value={fontFamily}
          onChange={handleChangeFontFamily}
          options={googleFonts}
          placeholder="Select Font Family"
        />
      </FormGroup>
    </Col>
  </>
}

export default TitleElementOptions
