import React, {useCallback, useEffect, useRef, useState} from 'react'
import BaseDialog from '../../../../../components/Common/BaseDialog'
import {Col, Input, Row} from 'reactstrap'
import Dropzone from 'react-dropzone'
import {formatBytes, getAxiosDefaultConfig} from '../../../../../utils'

import {ApiEndpoint} from '../../../../../store/core/endpoint'
import {EntityType} from '../../../../../store/core/entityType'
import axios from 'axios'
import AssetThumb from '../../../../../components/Common/AssetThumb'
import {API_URL} from '../../../../../config'
import toastr from 'toastr'
import PaginationComponent from 'react-reactstrap-pagination'
import Loader from '../../components/Loader'

const SlideAssetsDialog = (props) => {
  const {open, handleClose,multiple} = props
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [activeTab, setActiveTab] = useState(0)
  const [, setBusy] = useState(false);
  const [selected, setSelected] = useState('')
  const [assetFrom,setAssetFrom] = useState('')
  const [repoxStoreAssets,setRepoxStoreAssets] = useState([])
  const [repoxStorePage,setRepoxStorePage] = useState(0)
  const [storiesAssets,setStoriesAssets] = useState([])
  const [storiesPage,setStoriesPage] = useState(0)
  const [uploadFileLoading,setUploadFileLoading] = useState(false)
  const [searchRepoxText,setSearchRepoxText] = useState('')

  const searchTimeoutRepox = useRef()

  const handleChange = (val) => {
    const value = val.target.value
    setSearchRepoxText(value)
    if (value.length > 3) {
      clearTimeout(searchTimeoutRepox.current)
      searchTimeoutRepox.current = setTimeout(() => {
        setRepoxStorePage(0)
        getAllRepoxAssets()
      }, 800)
    } else if (value === '' || value.length <=0) {
      setRepoxStorePage(0)
      getAllRepoxAssets()
    }
  }

  const getAllRepoxAssets = useCallback(() =>{
    if (open) {
      axios.get(`${API_URL}/search_assets?q=image&page=${repoxStorePage}&label=${searchRepoxText}`, getAxiosDefaultConfig())
        .then((response) => {
          setRepoxStoreAssets(response.data)
        })
        .catch((e) => {
          toastr.error(e)
        });
    }
  },[open,repoxStorePage,searchRepoxText])

  useEffect(() => {
    getAllRepoxAssets()
    //eslint-disable-next-line
  }, [open,repoxStorePage])

  useEffect(() => {
    if (open) {
      axios.get(`${API_URL}/assets?page=${storiesPage}`, getAxiosDefaultConfig())
        .then((response) => {
          setStoriesAssets(response.data)
        })
        .catch((e) => {
          toastr.error(e)
        });
    }
    //eslint-disable-next-line
  }, [open,storiesPage])

  useEffect(()=>{
    if (open)
    {
      setSelected('')
    }
    return ()=>{
      setStoriesPage(0)
      setRepoxStorePage(0)
    }
    //eslint-disable-next-line
  },[open])


  const handleAcceptedFiles = useCallback((files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  }, []);

  const fileUpload = async () => {
    const url = `${ApiEndpoint[EntityType.Assets]}/upload`;
    const axiosConfig = getAxiosDefaultConfig();
    const formData = new FormData();
    setUploadFileLoading(true)
    const data = {type: selectedFiles[0].type, label: selectedFiles[0].name, properties: []};
    formData.append("file", selectedFiles[0]);
    formData.append(
      "data",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    try {
      setBusy(true);
      await axios.post(url, formData, axiosConfig);
      setUploadFileLoading(false)
      setBusy(false);
      setActiveTab(1)
      setStoriesPage(storiesAssets.totalPages - 1)
    } catch (e) {
      toastr.error(e)
      setUploadFileLoading(false)
      setBusy(false);
    }
  };

  useEffect(() => {
    selectedFiles.length > 0 && fileUpload()
    // eslint-disable-next-line
  }, [selectedFiles])

  const loadNextRepoxAssets = (page) =>{
    setRepoxStorePage(page - 1)
  }

  const loadNextStoriesAssets = (page) =>{
    setStoriesPage(page - 1)
  }

  const renderStepOne = () => (
    <>
      <Row className='align-content-end align-items-end' style={{flexDirection:'column'}}>
        <Col xs={4}>
          <div className='text-muted font-size-12 font-weight-bold mb-2'>Search</div>
        </Col>
        <Col xs={4}>
          <Input name={'search'} id={'search'} type={'text'} value={searchRepoxText} onInput={handleChange}/>
        </Col>
      </Row>
    <Row>
    {repoxStoreAssets?.content?.map((asset) => {
      return <AssetThumb fileName={asset.label} key={`store-${asset.uuid}`} from={'store'} asset={asset} selected={selected} setSelected={setSelected} setAssetFrom={setAssetFrom}/>
    })}
  </Row>
      <Row>
        <PaginationComponent totalItems={repoxStoreAssets?.totalElements} pageSize={repoxStoreAssets?.pageable?.pageSize} defaultActivePage={repoxStorePage+1} onSelect={loadNextRepoxAssets} />
      </Row>
  </>
  )

  const renderStepTwo = () => (
    <>
      <Row>
        {storiesAssets?.content?.map((asset) => {
          return <AssetThumb fileName={asset.label} key={`store-${asset.uuid}`} from={'stories'} asset={asset} selected={selected} setSelected={setSelected} setAssetFrom={setAssetFrom}/>
        })}
      </Row>
      <Row>
        <PaginationComponent totalItems={storiesAssets?.totalElements} pageSize={storiesAssets?.pageable?.pageSize} defaultActivePage={storiesPage+1} onSelect={loadNextStoriesAssets} />
      </Row>
    </>
  )

  const renterStepThree = () => (
    <Row>
      <Col lg={12}>
        {!uploadFileLoading &&
        <Dropzone
          accept={".png, .jpeg, .jpg, .gif, .bmp"}
          multiple={false}
          onDrop={handleAcceptedFiles}
        >
          {({getRootProps, getInputProps}) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                </div>
                <h3>Drop files here or click to upload</h3>
                <h6>Select a file from your computer</h6>
              </div>
            </div>
          )}
        </Dropzone>
        }
        {uploadFileLoading &&
          <Row>
          <Col lg={12}>
        <Loader/>
          </Col>
            <Col lg={12} className='mt-2 mb-2 text-muted font-size-24 font-weight-bold' style={{textAlign:'center'}}>
              Uploading file...
            </Col>
          </Row>
        }
      </Col>
    </Row>
  )

  const contents = [renderStepOne(), renderStepTwo(), renterStepThree()];

  return <BaseDialog
    scrollable={true}
    extraClassName={"modal-full-height"}
    open={open}
    handleClose={() => {
      handleClose()
      setSelected('')
    }
    }
    size={'lg'}
    primaryAction={() => {
      props.handleChange(selected,assetFrom)
      handleClose()
    }}
    primaryActionTitle={'Import asset'}
    primaryActionDisable={selected === ''}
    changeTab={activeTab}
    tabs={[
      {title: 'Repox Store Content'},
      {title: 'Media Content'},
      {title: 'Upload an image'},
    ]}
    contents={contents}
  />

}

export default SlideAssetsDialog
