import React, {useEffect, useState} from "react";
import {FIELD_DEFAULTS} from "../consts";
import ShortAnswer from "../fields/ShortAnswer";

export const LastnameTypeField = (props) => {
  const {setLastname, lastName, formDisabled} = props

  const [field,setField] = useState({
    ...FIELD_DEFAULTS.SHORT_ANSWER,
    required: true,
    title: 'Last name',
    value: lastName
  })

  useEffect(()=>{
    if (field) {
      if (field.errors&&field.errors.required) {
        setLastname(null)
      } else {
        setLastname(field.value)
      }
    }
    // eslint-disable-next-line
  },[field])

  return <ShortAnswer field={field} setField={setField} answerable={!lastName} standalone formDisabled={formDisabled} />
}
