import React from 'react';
import BaseContent from '../../../components/Common/BaseContent';
import {Button, Container, Row} from "reactstrap";


const ScreenApp = (props) => {

  return (
    <BaseContent>

      <Container style={{paddingTop: 30, minHeight: '60vh', padding:64}} >
        <Row xs={12} md={3} className='justify-content-center align-items-center align-content-center'>

          <div className='c-item-wrapper d-flex text-center justify-content-center align-items-center align-content-center flex-column'>
            <div className='d-flex'>
              <span className="mdi mdi-microsoft-windows" style={{fontSize:48}}> </span>
            </div>
            <div className='d-flex p-4 text-center'>
              <Button color='info' href={`https://server-dev.repox.io/repox-stories-viewer/repox-stories-viewer.exe`}> Download for Windows</Button>
            </div>
          </div>

          <div className='c-item-wrapper d-flex text-center justify-content-center align-items-center align-content-center flex-column'>
            <div className='d-flex'>
              <span className="mdi mdi-linux" style={{fontSize:48}}> </span>
            </div>
            <div className='d-flex p-4 text-center'>
              <Button color='info' href={`https://server-dev.repox.io/repox-stories-viewer/repox-stories-viewer.AppImage`}> Download for Linux</Button>
            </div>
          </div>

          <div className='c-item-wrapper d-flex text-center justify-content-center align-items-center align-content-center flex-column'>
            <div className='d-flex'>
              <span className="mdi mdi-apple" style={{fontSize:48}}> </span>
            </div>
            <div className='d-flex p-4 text-center'>
              <Button color='info'> Download for MacOS</Button>
            </div>
          </div>

        </Row>
      </Container>

    </BaseContent>
  )
}

export default ScreenApp
