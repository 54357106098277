import React from 'react'
import {Divider, Grid} from "@material-ui/core";
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';
import BatteryStdIcon from '@material-ui/icons/BatteryStd';

const MobileMockup = (props) =>{
  return <div style={{position:'relative',backgroundColor:'#3a3939',height:600,width:300,borderRadius:10}}>
      <div style={{position:'absolute',top:10,backgroundColor:'#fff',height:560,width:280,left:10,borderRadius:5}}>
        <div style={{position:'absolute',top:0,width:'100%',padding:10}}>
          <Grid container alignContent={'space-between'} style={{fontSize:10}}>
            <Grid item xs={9}>00:00</Grid>
            <Grid item xs={1}><SignalCellularAltIcon fontSize={'inherit'}/></Grid>
            <Grid item xs={1}><SignalWifi4BarIcon fontSize={'inherit'}/></Grid>
            <Grid item xs={1}><BatteryStdIcon fontSize={'inherit'}/></Grid>
          </Grid>
          <Divider style={{marginTop:10}}/>
        </div>
        <div style={{position:'absolute',top:36}}>
          {props.children}
        </div>
      </div>
  </div>
}

export default MobileMockup