import React, {useCallback, useEffect, useState} from "react";
import {Col, Input, Row} from "reactstrap";
import Container from "reactstrap/lib/Container";
import 'react-multi-email/style.css';
import BaseDialog from "../../../components/Common/BaseDialog";
import axios from "axios";
import {API_URL} from "../../../config";
import {getAxiosDefaultConfig} from "../../../utils";
import toastr from 'toastr'

const initialValue = {
  firstName: '',
  lastName: '',
  email: '',
  organization: ''
}

export const EditAddNewContact = (props) => {

  const {open, handleClose, existingContact} = props
  const [saving, setSaving] = useState(false)
  const [contact,setContact] = useState(initialValue)

  useEffect(()=>{
    setContact(
      existingContact?{...existingContact}:initialValue
    )
    // eslint-disable-next-line
  },[open])

  const addContact = useCallback(() => {
    setSaving(true)
    axios.post(`${API_URL}/addons/srv/contacts`,contact,getAxiosDefaultConfig())
      .then((response) => {
        setSaving(false);
        handleClose(true)
        toastr.success(`Successfully added new contact`);
      })
      .catch(error => {
        setSaving(false);
        toastr.error(error.message)
      })
    // eslint-disable-next-line
  },[contact])

  const updateContact = useCallback(() => {
    setSaving(true)
    const {firstName,lastName,email,organization} = contact
    const payLoad = {
      firstName,
      lastName,
      email,
      organization
    }
    axios.put(`${API_URL}/addons/srv/contacts/${contact.uuid}`,payLoad,getAxiosDefaultConfig())
      .then((response) => {
        setSaving(false);
        handleClose(true)
        toastr.success(`Successfully saved contact`);
      })
      .catch(error => {
        setSaving(false);
        toastr.error(error.message)
      })
    // eslint-disable-next-line
  },[contact])

  const saveContact = () => {
    if (existingContact) {
      updateContact()
    } else {
      addContact()
    }
  }

  const deleteContact = () => {
    setSaving(true)
    axios.delete(`${API_URL}/addons/srv/contacts/${contact.uuid}`,getAxiosDefaultConfig())
      .then((response) => {
        setSaving(false);
        handleClose(true)
        toastr.success(`Successfully deleted contact`);
      })
      .catch(error => {
        setSaving(false);
        toastr.error(error.message)
      })
  }

  const [invalidEmail, setInvalidEmail] = useState(false)

  const setField = (value,field) => {
    setContact({...contact,[field]:value})
    if (field==='email') {
      setInvalidEmail(!validEmail(value))
    }
  }

  const validEmail = (email) => {
    // eslint-disable-next-line
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase())
  }

  const requiredFieldsFilled = validEmail(contact.email)&&contact.firstName!==''&&contact.lastName!==''

  return <BaseDialog
    title={existingContact?'Edit contact':'Create new contact'}
    // secondaryAction={handleClose}
    // handleEditTitle={handleEditTitle}
    primaryAction={saveContact}
    primaryActionTitle={`Save`}
    // secondaryAction={secondaryAction&&secondaryAction.action}
    // secondaryActionTitle={secondaryAction&&secondaryAction.title}
    deleteAction={existingContact?deleteContact:null}
    isValid={requiredFieldsFilled}
    busy={saving}
    open={open}
    handleClose={()=>handleClose(false)}
    scrollable={true}
    // contents={contents}
  >

  <Container>
    {/*<Row>*/}
    {/*  <Col xs={12} style={{fontSize: 128}}>*/}
    {/*    <i className='bx bxs-user-circle'></i>*/}
    {/*  </Col>*/}
    {/*</Row>*/}
    <Row className='mt-2'>
      <Col xs={6}>
        <Input type="text" name={`firstName`} id={`firstName`}
               placeholder={`First name*`}
               value={contact.firstName}
               onChange={(e)=>setField(e.target.value,'firstName')}
        />
      </Col>
      <Col xs={6}>
        <Input type="text" name={`lastName`} id={`lastName`}
               placeholder={`Last name*`}
               value={contact.lastName}
               onChange={(e)=>setField(e.target.value,'lastName')}
        />
      </Col>
    </Row>
    <Row className='mt-2'>
      <Col xs={12}>
        <Input type="text" name={`email`} id={`email`}
               placeholder={`Email*`}
               value={contact.email}
               onChange={(e)=>setField(e.target.value,'email')}
               invalid={invalidEmail}
        />
      </Col>
    </Row>
    <Row className='mt-2'>
      <Col xs={12}>
        <Input type="text" name={`organization`} id={`organization`}
               placeholder={`Organization`}
               value={contact.organization}
               onChange={(e)=>setField(e.target.value,'organization')}
        />
      </Col>
    </Row>
  </Container>


  </BaseDialog>
}

