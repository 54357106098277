import React, {useEffect, useState} from 'react'
import {Col, Row} from 'reactstrap'
import BaseDialog from '../../../../../components/Common/BaseDialog'
import {storyDownloadOptions} from '../../../../../constants/enums'
import {downloadFileGeneral, getAxiosDefaultConfig, getEmail} from '../../../../../utils'
import {API_URL} from '../../../../../config'
import axios from 'axios'
import toastr from 'toastr'


const ElementBlock = (props) => {
  const {title, description, icon,isSelected,type,isDisabled,setSelected} = props

  return <Col xs={4} style={{
    border: isSelected?  '1px solid rgb(85, 110, 230)' : '1px solid #d2d2d2',
    boxShadow: '#d2d2d2 0px 1px 3px 0px',
    borderRadius: '10px',
    width: '160px',
    height: '160px',
    margin: '5px',
    cursor: isDisabled?'not-allowed':'pointer',
    padding:'10px',
    textAlign: 'center'
  }}
              onClick={()=>!isDisabled && setSelected(type)}
  >
    <Row>
      <Col lg={12} style={{fontSize:'30px'}}>
        <i className={`${icon}`} />
      </Col>
      <Col lg={12}>
        <div className='font-weight-bold mt-1 mb-1' style={{userSelect:'none'}}> {title}</div>
      </Col>
      <Col lg={12}>
        <div style={{fontSize: '9px',userSelect:'none'}} className='font-weight-light'> {description}</div>
      </Col>
    </Row>
  </Col>
}

const DownloadModal = (props) =>{
  const {open,uuid,handleClose,loadingExport, setLoadingExport} = props
  const [selectedElementType,setSelectedElementType] = useState(null)
  const [recipientEmail,setRecipientEmail] = useState('')

  useEffect(()=>{
    if(open)
    {
      setSelectedElementType(null)
      setRecipientEmail(getEmail())
    }
    //eslint-disable-next-line
  },[open])


  const exportStory = () => {
    if (!loadingExport && uuid)
      downloadFileGeneral(`${API_URL}/stories/${uuid}/export`, loadingExport, setLoadingExport, {format: 'VIEWER'})
  }

  const exportStoryImages = (resolution) => {
    if (!loadingExport && uuid)
      downloadFileGeneral(`${API_URL}/stories/${uuid}/export`, loadingExport, setLoadingExport, {format: 'SLIDE_IMAGE',resolution:resolution})
  }

  const exportStoryVideo = (resolution) =>{
    axios.post(`${API_URL}/stories/${uuid}/export_video`,{resolution,recipient:recipientEmail},getAxiosDefaultConfig())
      .then(()=>{
        toastr.success('Downloading')
      })
      .catch((e)=>{
        toastr.error(e.message)
      })
  }

  const handleDownload = () =>{

    const downloadType = selectedElementType.split('_')

    if (downloadType[0] === 'VIDEO')
    {
      exportStoryVideo(downloadType[1])
      handleClose()
    }

    else if (downloadType[0] === 'STATIC')
    {
      exportStoryImages(downloadType[1])
      handleClose()
    }

    else
    {
      exportStory()
      handleClose()
    }
  }
  return <BaseDialog
    scrollable={true}
    title={'Download Story'}
    open={open}
    handleClose={() => {
      handleClose()
    }}
    size={'lg'}
    primaryAction={handleDownload}
    primaryActionTitle={'Export'}
    primaryActionDisabled={selectedElementType === null}
    // tabChange={activeTab}
  >
    <Row className={'justify-content-center'}>
      {storyDownloadOptions.map((element)=>{
        return <ElementBlock key={element.type} isDisabled={element.disabled} isSelected={selectedElementType===element.type} type={element.type} setSelected={setSelectedElementType} title={element.title} description={element.description} icon={element.icon}/>
      })}
    </Row>
  </BaseDialog>


}

export default DownloadModal;