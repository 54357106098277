import React, {useEffect,useCallback, useMemo, useState} from "react";
import BaseContent from "../../../../components/Common/BaseContent";
import {connect} from "react-redux";
import {Schema} from "../../../../store/core/schema";
import {EntityType} from "../../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import {
  getDataStreamType,
  createDataStreamType,
  deleteDataStreamType,
  updateDataStreamType,
} from "../../../../store/dataStreamType/actions";
import CardSettings from "../../../../components/Common/card-settings";
import {getIconForDatastream} from "../../../../utils";
import {createPropertyGroup, deletePropertyGroup, updatePropertyGroup} from "../../../../store/propertyGroup/actions";
import PropertyGroupDialog from "../PropertyGroups/EntityDialog";
import ListToggle from "../../../../components/Common/ListToggle";
import ListSettings from "../../../../components/Common/ListSettings";
import {useListToggle} from "../../../../helpers/hooks";

const entityType = EntityType.DataStreamType;

const DataStreamTypes = (props) => {
  const {
    context,
    createDataStreamType,
    updateDataStreamType,
    deleteDataStreamType,
    getDataStreamType,
    createPropertyGroup,
    updatePropertyGroup,
    deletePropertyGroup
  } = props;
  const { result, isLoading, error } = context.DataStreamType.getAll;
  const currentDataStreamType = context.DataStreamType.get.result;
  const properties = context.Property.getAll.result;
  const propertyGroups = context.PropertyGroup.getAll.result;
  const [openModal, setOpenModal] = useState(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const [openPropertyGroupModal, setOpenPropertyGroupModal] = useState(false);
  const [groupEntity, setGroupEntity] = useState(Schema[EntityType.PropertyGroup])
  const [isList, toggleList] = useListToggle();

  // const handleEntityClick = useCallback((entity) => {
  //   setEntity(entity);
  //   setOpenModal(true);
  // }, []);
  const handleEntityClick = useCallback(
    (entity) => {
      getDataStreamType(entity.code);
      setOpenModal(true);
    },
    [getDataStreamType]
  );

  useEffect(()=>{
    setEntity(currentDataStreamType)
  },[currentDataStreamType])

  const handleCreate = () => {
    setEntity(Schema[entityType]);
    setOpenModal(true);
  };

  const handleCreatePropertyGroup = () => {
    setGroupEntity(Schema[EntityType.PropertyGroup])
    setOpenPropertyGroupModal(true);
  };

  const handleEditPropertyGroup = (group) => {
    setGroupEntity(group)
    setOpenPropertyGroupModal(true);
  };

  const toggleModal = () => {
    setOpenModal((prevState) => !prevState);
    setEntity([])
  };

  const breadcrumbs = [
    { title: "Settings", link: "/settings" },
    { title: "Content Model", link: "/settings/content-model" },
    { title: "Datastream Types", link: "#" },
  ];

  const cards = useMemo(() => {
    return result.map((dataStreamType) => ({
      label: dataStreamType.name,
      icon: getIconForDatastream(dataStreamType.name),
      code: dataStreamType.code,
      handleClick: () => handleEntityClick(dataStreamType),
    }));
  }, [handleEntityClick, result]);

  const list = useMemo(() => {
    return result.map((dataStreamType) => ({
      label: dataStreamType.name,
      icon: getIconForDatastream(dataStreamType.name),
      code: dataStreamType.code,
      handleClick: () => handleEntityClick(dataStreamType),
    }));
  }, [handleEntityClick, result]);

  const listColumns = [
    {name: "Icon", prop: "icon"},
    {name: "Name", prop: "label"},
    {name: "Code", prop: "code"},
  ];

  return (
    <>
      <BaseContent
        title={"Datastream Types"}
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
      >
        {!isList && <CardSettings settings={cards}/>}
        {isList && <ListSettings contents={list} columns={listColumns}/>}
        <EntityDialog
          entity={entity}
          open={openModal}
          entityType={entityType}
          handleClose={toggleModal}
          createEntity={createDataStreamType}
          updateEntity={updateDataStreamType}
          deleteEntity={deleteDataStreamType}
          context={context.DataStreamType}
          properties={properties}
          propertyGroups={propertyGroups}
          handleCreatePropertyGroup={handleCreatePropertyGroup}
          handleEditPropertyGroup={handleEditPropertyGroup}
        />
        <PropertyGroupDialog
            entity={groupEntity}
            entityType={EntityType.PropertyGroup}
            open={openPropertyGroupModal}
            handleClose={() => setOpenPropertyGroupModal(false)}
            createEntity={createPropertyGroup}
            updateEntity={updatePropertyGroup}
            deleteEntity={deletePropertyGroup}
            context={context.PropertyGroup}
        />
      </BaseContent>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  updateDataStreamType,
  createDataStreamType,
  getDataStreamType,
  deleteDataStreamType,
  createPropertyGroup,
  updatePropertyGroup,
  deletePropertyGroup
})(DataStreamTypes);
