import React from "react";
import moment from "moment";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

const TrashListItem = ({ item, isChecked, handleCheck, handleRemove, handlePutBack }) => {
  return (
    <tr>
      <th>
        <input type={"checkbox"} checked={isChecked} onChange={handleCheck} />
      </th>
      <td>
        <h5 className="font-size-14 mb-0">{item.label}</h5>
      </td>
      <td>
        <span className="badge badge-primary">{item.objectType}</span>
      </td>
      {/*<td>15 Oct, 19</td>*/}
      <td>{moment(item.deletedAt).format("DD-MM-YYYY, hh:mm")}</td>
      <td>
        {item.user.firstname} {item.user.lastname}
      </td>
      <td>
        <UncontrolledDropdown style={{cursor:"pointer"}}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => handlePutBack(item.uuid)}>Restore</DropdownItem>
            <DropdownItem onClick={() => handleRemove(item.uuid)}>Delete</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};

export default TrashListItem;
