import {API_URL} from "../../config";
import {EntityType} from './entityType'

export const ApiEndpoint = {
    [EntityType.Container]: API_URL + "/containers",
    [EntityType.ContainerType]: API_URL + "/container_types",
    [EntityType.DataStreamType]: API_URL + "/datastream_types",
    [EntityType.DataStream]: API_URL + "/datastreams",
    [EntityType.Property]: API_URL + "/properties",
    [EntityType.PropertyType]: API_URL + "/property_types",
    [EntityType.Utility]: API_URL + "/utils",
    [EntityType.Label]: API_URL + "/labels",
    [EntityType.User]: API_URL + "/users",
    [EntityType.Group]: API_URL + "/groups",
    [EntityType.Tag]: API_URL + "/tags",
    [EntityType.Search] :API_URL + "/search",
    [EntityType.StorageInformation] : API_URL + "/storage/info",
    [EntityType.Assets] :API_URL + '/assets',
    [EntityType.Story]:API_URL + '/stories',
    [EntityType.Trash] : API_URL + "/trash",
    [EntityType.Namespace] : API_URL + "/property_namespaces",
    [EntityType.PropertyGroup] : API_URL + "/property_groups",
    [EntityType.Archive] : API_URL + "/archive",
    [EntityType.Qualifier] : API_URL + "/property_qualifiers",
    [EntityType.SystemSetting] : API_URL + "/system_settings",
    [EntityType.Export] : API_URL + "/export",
    [EntityType.Surveys] : API_URL + "/addons/srv/surveys",
    [EntityType.SurveyResponses] : API_URL + "/addons/srv/surveys",
    [EntityType.Permissions] : API_URL + "/iam/permissions",
    [EntityType.MyPermissions] : API_URL + "/my/permissions",
    [EntityType.MyProfile] : API_URL + "/my/profile",
    [EntityType.Vocabulary] : API_URL + "/vocabularies",
    [EntityType.Term] : API_URL + "/vocabularies",
}
