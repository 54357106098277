import React, { createRef, useEffect, useMemo, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import { Divider } from "@material-ui/core";
import { SearchOperators } from "../../constants/enums";
import { connect } from "react-redux";
import { createMap } from "../../utils";

const FireInput = (props) => {
  const {
    onFire,
    onChange,
    timeout,
    placeholder,
    value,
    onClick,
    queryFilter,
    context,
    searchField,
    fulltextType,
    setFulltextType,
    fulltextToggleOpen,
    setFulltextToggleOpen
  } = props;
  const systemSettings = context.SystemSetting.getAll;
  const allProperties = context.Property.getAll.result;
  const [isTyping, setIsTyping] = useState(false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [containerToggleOpen, setContainerToggleOpen] = useState(false);
  const [datastreamToggleOpen, setDatastreamToggleOpen] = useState(false);
  const [dynamicPlaceholder, setDynamicPlaceholder] = useState(placeholder);
  const [selectedFilter, setSelectedFilter] = useState("All");


  const ref = createRef();

  useEffect(() => {
    queryFilter(SearchOperators.default);
    //eslint-disable-next-line
  }, []);

  const containerProperties = useMemo(() => {
    if (systemSettings?.result.length > 0) {
      const containerPropMap = createMap(systemSettings.result);
      const containerProps = containerPropMap.get(
        "search.container_properties"
      );
      const containerPropsArray = containerProps.settingValue.split(";");
      const searchOption = [];
      containerPropsArray.forEach((dataProp) => {
        searchOption.push(
          ...allProperties.filter(
            (prop) => prop.propertyKey === dataProp.trim()
          )
        );
      });
      return searchOption;
    }
    //eslint-disable-next-line
  }, [systemSettings]);

  const datastreamProperties = useMemo(
    () => {
      if (systemSettings?.result.length > 0) {
        const datastreamPropMap = createMap(systemSettings.result);
        const datastreamProps = datastreamPropMap.get(
          "search.datastreams_properties"
        );
        const datastreamPropsArray = datastreamProps.settingValue.split(";");
        const searchOption = [];
        datastreamPropsArray.forEach((dataProp) => {
          searchOption.push(
            ...allProperties.filter(
              (prop) => prop.propertyKey === dataProp.trim()
            )
          );
        });
        return searchOption;
      }
    },
    //eslint-disable-next-line
    [systemSettings]
  );

  useEffect(() => {
    onFire();
    //eslint-disable-next-line
  }, [isTyping]);

  const handleChange = (e) => {
    clearTimeout(ref.current);
    setIsTyping(true);
    onChange(e.target.value);
    ref.current = setTimeout(() => {
      setIsTyping(false);
    }, timeout || 1000);
  };

  return (
    <Container className="form-control d-flex">
      <Dropdown isOpen={toggleOpen} toggle={() => setToggleOpen(!toggleOpen)}>
        <DropdownToggle className="btn mb-2 btn-sm" style={{width: "max-content", marginTop: -2}} color={"link"}>
          {selectedFilter} <i className="bx bx-chevron-down" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            style={{padding: 8}}
            onClick={() => {
              queryFilter(SearchOperators.default);
              setSelectedFilter("All");
              setDynamicPlaceholder(() => "Search all...");
            }}
          >
            <div className="font-size-13">All</div>
          </DropdownItem>
          <Divider />
          <DropdownItem
            style={{padding: 8}}
            onClick={() => {
              queryFilter(SearchOperators.container);
              setSelectedFilter("Container");
              setDynamicPlaceholder(() => "Search containers...");
            }}
          >
            <div className="font-size-13">Container</div>
          </DropdownItem>
          {containerProperties && containerProperties.length > 0 && (
            <>
              <Dropdown direction={"right"} isOpen={containerToggleOpen} toggle={() => setContainerToggleOpen(!containerToggleOpen)}>
                <DropdownToggle style={{width: "100%", padding: 8, alignItems: "center"}} color={"inherit"} className={"d-flex"}>
                  <i className="bx bx-subdirectory-right" /> Property <i className="bx bx-chevron-right ml-auto" />
                </DropdownToggle>
                <DropdownMenu direct>
                  {containerProperties.map((containerProp) => {
                    return (
                      <DropdownItem
                        style={{padding: 8}}
                        key={containerProp.propertyKey}
                        onClick={() => {
                          setToggleOpen(!toggleOpen);
                          setContainerToggleOpen(!containerToggleOpen);
                          setSelectedFilter(containerProp.displayName);
                          queryFilter(SearchOperators.container_property);
                          searchField(containerProp.propertyKey);
                          setDynamicPlaceholder("Search container property...");
                        }}
                      >
                        {containerProp.displayName}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <Divider />
            </>
          )}
          <DropdownItem
            style={{padding: 8}}
            onClick={() => {
              queryFilter(SearchOperators.datastream);
              setSelectedFilter("Datastream");
              setDynamicPlaceholder(() => "Search datastreams...");
            }}
          >
            <div className="font-size-13">Datastream</div>
          </DropdownItem>
          {datastreamProperties && datastreamProperties.length > 0 && (
            <>
              <Dropdown direction={"right"} isOpen={datastreamToggleOpen} toggle={() => setDatastreamToggleOpen(!datastreamToggleOpen)}>
                <DropdownToggle style={{width: "100%", padding: 8, alignItems: "center"}} color={"inherit"} className={"d-flex"}>
                  <i className="bx bx-subdirectory-right" /> Property <i className="bx bx-chevron-right ml-auto" />
                </DropdownToggle>
                <DropdownMenu direct>
                  {datastreamProperties.map((datastreamProp) => {
                    return (
                      <DropdownItem
                        style={{padding: 8}}
                        key={datastreamProp.propertyKey}
                        onClick={() => {
                          setToggleOpen(!toggleOpen);
                          setDatastreamToggleOpen(!datastreamToggleOpen);
                          setSelectedFilter(datastreamProp.displayName);
                          queryFilter(SearchOperators.datastream_property);
                          searchField(datastreamProp.propertyKey);
                          setDynamicPlaceholder("Search datastream property...");
                        }}
                      >
                        {datastreamProp.displayName}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <Divider />
            </>
          )}
          <DropdownItem
            style={{padding: 8}}
            onClick={() => {
              queryFilter(SearchOperators.datastream_filetype);
              setSelectedFilter("Filetype");
              setDynamicPlaceholder(() => "Search filetypes...");
            }}
          >
            <div className="font-size-13">Filetype</div>
          </DropdownItem>
          <DropdownItem
            style={{padding: 8}}
            onClick={() => {
              queryFilter(SearchOperators.fulltext);
              setSelectedFilter("Fulltext");
              setDynamicPlaceholder(() => "Search fulltext matching any term...");
            }}
          >
            <div className="font-size-13">Fulltext</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {selectedFilter === "Fulltext" && (
        <Dropdown isOpen={fulltextToggleOpen} toggle={() => setFulltextToggleOpen(!fulltextToggleOpen)}>
          <DropdownToggle className="btn mb-2 btn-sm" style={{width: "max-content", marginTop: -2}} color={"link"}>
            {fulltextType} <i className="bx bx-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem style={{ padding: 8 }} onClick={() =>
            {
              queryFilter(SearchOperators.fulltext);
              setFulltextType("OR");
              setDynamicPlaceholder(() => "Search fulltext matching any term...");
            }}>
              <div className="font-size-13">OR</div>
            </DropdownItem>
            <DropdownItem style={{ padding: 8 }} onClick={() =>
            {
              queryFilter(SearchOperators.fulltext);
              setFulltextType("AND");
              setDynamicPlaceholder(() => "Search fulltext matching all terms...");
            }}>
              <div className="font-size-13">AND</div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      <Input type="text" value={value} onClick={onClick} style={{marginTop: -8}} placeholder={dynamicPlaceholder} onChange={handleChange} className={"inputTransparent"} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, null)(FireInput);
