import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import {compareProp, createMap, makeid} from "../../utils";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
} from "reactstrap";
import Accordian from "./Accordion";
// fake data generator
const getItems = (items, offset = 0) =>
  items.map((k) => ({
    id: makeid(),
    content: k,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 2,
  borderRadius: 8,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  borderRadius: 8,
  minWidth: 250,
});

function SortableList(props) {
  const {
    state,
    setState,
    properties,
    propertyGroups,
    form,
    handleCreatePropertyGroup,
    handleEditPropertyGroup,
  } = props;

  const [query, setQuery] = useState("");

  const groupMap = useMemo(() => createMap(propertyGroups), [propertyGroups]);

  const [openAddProp, setOpenAddProp] = React.useState(null);

  const toggleProp = (prop) => {
    setOpenAddProp((prevState) => (prevState !== null ? null : prop));
  };

  const availablePropertyGroups = useMemo(() => {
    return propertyGroups.filter((g) => !state.find((x) => x.groupId === g.id));
  }, [propertyGroups, state]);

  const availableProperties = useMemo(() => {
    const addedProps = [];
    state.forEach((group) => {
      group.properties.forEach((property) => {
        addedProps.push(property.content.propertyKey);
      });
    });
    return properties.filter((x) => !addedProps.includes(x.propertyKey));
  }, [properties, state]);

  useEffect(() => {
    const groups = _.chain(form.properties)
      .groupBy("groupId")
      .map((value, key) => ({
        groupId: parseInt(key),
        properties: getItems(value.sort(compareProp)),
      }))
      .value();
    setState(groups);
  }, [form.properties, setState]);

  useEffect(() => {
    setState((prevState) => prevState.filter((x) => groupMap.has(x.groupId)));
  }, [groupMap, setState]);

  function handleUpdateProperty(ind, index, prop, value) {
    const newState = state.map((group, groupIndex) => {
      if (groupIndex === ind) {
        return {
          ...group,
          properties: group.properties.map((item, itemIndex) => {
            if (itemIndex === index) {
              return {
                ...item,
                content: {
                  ...item.content,
                  [prop]: value,
                },
              };
            }
            return item;
          }),
        };
      }
      return group;
    });
    setState(newState);
  }

  function handleRemoveProperty(ind, index) {
    const newState = [...state];
    newState[ind].properties.splice(index, 1);
    setState(newState);
  }

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(
        state[sInd].properties,
        source.index,
        destination.index
      );
      const newState = [...state];
      newState[sInd].properties = items;
      setState(newState);
    } else {
      const result = move(
        state[sInd].properties,
        state[dInd].properties,
        source,
        destination
      );
      const newState = [...state];
      newState[sInd].properties = result[sInd];
      newState[dInd].properties = result[dInd];
      setState(newState);
    }
  }

  const addGroup = (groupId) => {
    setState([...state, { groupId, properties: [] }]);
  };

  const removeGroup = (groupId) => {
    setState((prevState) => prevState.filter((x) => x.groupId !== groupId));
  };

  const addItem = (ind, property) => {
    const newState = [...state];

    newState[ind].properties.push({
      id: makeid(),
      content: {
        ...property,
        editable:true,
        groupId: state[ind].id,
        position: state[ind].properties.length,
      },
    });
    setState(newState);
  };

  return (
    <div>
      {/*<PropertyGroup {...props} />*/}
      <div style={{ display: "flex", flex: 1, alignItems: "flex-start" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((group, ind) => (
            <Droppable key={ind} droppableId={`${ind}`}>
              {(provided, snapshot) => {
                const currentGroup = groupMap.get(group.groupId);
                if (!currentGroup) return null;
                return (
                  <div
                    ref={provided.innerRef}
                    className={"bg-light bordered ml-2"}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <div className={"d-flex"}>
                      <CardTitle style={{ flex: 1 }}>
                        {ind + 1}. {currentGroup.name}{" "}
                        <i
                          className="bx bx-edit"
                          onClick={() => handleEditPropertyGroup(currentGroup)}
                        />
                      </CardTitle>
                      <Dropdown
                        isOpen={openAddProp === ind}
                        toggle={() => toggleProp(ind)}
                      >
                        <DropdownToggle
                          disabled={availableProperties.length === 0}
                          className="btn mb-2 btn-sm"
                          color={"primary"}
                        >
                          Add <i className="bx bx-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <FormGroup className={"m-2"}>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={query}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                          </FormGroup>
                          <div style={{ maxHeight: 300, overflow: "auto" }}>
                            {availableProperties
                              .filter((property) => property.displayName.toLowerCase().includes(query.toLowerCase()))
                              .map((property) => {
                                return (
                                  <DropdownItem
                                    onClick={() => addItem(ind, property)}
                                  >
                                    {property.displayName}
                                  </DropdownItem>
                                );
                              })}
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    {group.properties.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <SortableItem
                              property={item.content}
                              ind={ind}
                              index={index}
                              value={item.content.displayName}
                              handleRemoveProperty={() =>
                                handleRemoveProperty(ind, index)
                              }
                              handleUpdateProperty={handleUpdateProperty}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <Button
                      color={"light"}
                      className={"ml-auto"}
                      onClick={() => removeGroup(group.groupId)}
                    >
                      <i className="bx bx-trash font-size-18" />
                    </Button>
                  </div>
                );
              }}
            </Droppable>
          ))}
        </DragDropContext>
        <GroupsCard
          availablePropertyGroups={availablePropertyGroups}
          addGroup={addGroup}
          handleEditPropertyGroup={handleEditPropertyGroup}
          handleCreatePropertyGroup={handleCreatePropertyGroup}
        />
      </div>
    </div>
  );
}

export { SortableList };
function BooleanProp({ label, value, handleChange, id }) {
  return (
    <div className="custom-control custom-switch mb-2 mr-3 z-depth-0" dir="ltr">
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        checked={value}
      />
      <label
        className="custom-control-label"
        htmlFor={id}
        onClick={handleChange}
      >
        {label}
      </label>
    </div>
  );
}

const SortableItem = (props) => {
  const {
    property,
    handleUpdateProperty,
    value,
    handleRemoveProperty,
    ind,
    index,
  } = props;
  const toggleMandatory = () => {
    handleUpdateProperty(ind, index, "mandatory", !property.mandatory);
  };
  const toggleEditable = () => {
    handleUpdateProperty(ind, index, "editable", !property.editable);
  };
  const toggleRepeatable = () => {
    handleUpdateProperty(ind, index, "repeatable", !property.repeatable);
  };

  return (
    <div>
      <Accordian title={value}>
        <div className={"d-flex flex-column mt-2"}>
          <BooleanProp
            label={"Mandatory"}
            value={property.mandatory}
            handleChange={toggleMandatory}
            id={property.key + "_mandatory"}
          />
          <BooleanProp
            label={"Editable"}
            value={property.editable}
            handleChange={toggleEditable}
            id={property.key + "editable"}
          />
          <BooleanProp
            label={"Repeatable"}
            value={property.repeatable}
            handleChange={toggleRepeatable}
            id={property.key + "repeatable"}
          />
        </div>
        <div className={"flex-fill d-flex"}>
          <Button
            color={"danger"}
            size={"sm"}
            className={"flex-fill"}
            onClick={handleRemoveProperty}
          >
            Delete
          </Button>
        </div>
      </Accordian>
    </div>
  );
};

function GroupsCard(props) {
  const [openAddGroup, setOpenAddGroup] = React.useState(null);
  const toggleGroup = (prop) => {
    setOpenAddGroup((prevState) => (prevState !== null ? null : prop));
  };

  const {
    availablePropertyGroups,
    addGroup,
    handleEditPropertyGroup,
    handleCreatePropertyGroup,
  } = props;
  return (
    <Card className={"bg-light bordered ml-2"} style={getListStyle(false)}>
      <CardTitle>Add groups</CardTitle>
      <CardBody className={"d-flex flex-column"}>
        {availablePropertyGroups.map((group) => {
          return (
            <div className="btn-group mb-2 w-100" key={group.id}>
              <ButtonDropdown
                className={"w-100"}
                isOpen={openAddGroup === group.id}
                toggle={() => toggleGroup(group.id)}
              >
                <Button
                  id="caret"
                  color="primary"
                  className={"w-100"}
                  outline
                  onClick={() => addGroup(group.id)}
                >
                  {group.name}
                </Button>
                <DropdownToggle caret color="primary" outline>
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => addGroup(group.id)}>
                    <i className="bx bx-plus" /> Add
                  </DropdownItem>
                  <DropdownItem onClick={() => handleEditPropertyGroup(group)}>
                    <i className="bx bx-edit" /> Edit
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          );
        })}
        <Button
          color={"primary"}
          className={"mb-2"}
          onClick={handleCreatePropertyGroup}
        >
          Create new
        </Button>
      </CardBody>
    </Card>
  );
}
