import {withRouter} from "react-router-dom";
import React from 'react';
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {FIELD_DEFAULTS, FIELD_TYPE, FIELD_TYPES} from "../consts";

const FieldHeaderSelected = (props) => {
  const {field, setField} = props
  const {title,description,type} = field

  const changeTitle = (e) => {
    const value = e.target.value
    setField({
      ...field,
      title: value
    })
  }

  // Handle defaults on change type
  const changeType = (e) => {
    const value = e.target.value

    // See if you need to change Title/Description
    const titleWasChanged = field.title!==FIELD_DEFAULTS[field.type].title
    const descriptionWasChanged = field.description!==''

    // Allow transformation from multiple choice to checkboxes
    if ((field.type===FIELD_TYPE.MULTIPLE_CHOICE||field.type===FIELD_TYPE.CHECKBOXES) &&
      (value===FIELD_TYPE.MULTIPLE_CHOICE || value===FIELD_TYPE.CHECKBOXES))
    {
      setField({
        ...field,
        ...FIELD_DEFAULTS[value],
        title: titleWasChanged?field.title:FIELD_DEFAULTS[value].title,
        description: descriptionWasChanged?field.description:FIELD_DEFAULTS[value].description,
        details: {...field.details}
      })
    } else {

      let newField = {
        ...field
      }

      // Clear any other type of values for specific types
      newField.details&&
      delete newField.details;

      newField.value&&
      delete newField.value;

      newField = {
        ...newField,
        ...FIELD_DEFAULTS[value],
        title: titleWasChanged?field.title:FIELD_DEFAULTS[value].title,
        description: descriptionWasChanged?field.description:FIELD_DEFAULTS[value].description,
      }
      setField(newField)
    }

  }

  const changeDescription = (e) => {
    const value = e.target.value
    setField({
      ...field,
      description: value
    })
  }

  return <Row>
    <Col xs={12} sm={{size:8}}>
      <FormGroup>
        <Label for="title">Title</Label>
        <Input type="text" name="title" id="title" placeholder={'Title'} value={title} onChange={changeTitle} autoFocus={true}/>
      </FormGroup>
    </Col>
    <Col xs={12} sm={{size:4}}>
      <FormGroup>
        <Label for="typeSelect">Type</Label>
        <Input type="select" name="type" id="typeSelect" onChange={changeType} defaultValue={type}>
          {FIELD_TYPES.map((fieldType,i)=><option value={fieldType.value} key={i}>{fieldType.label}</option>)}
        </Input>
      </FormGroup>
    </Col>
    <Col xs={12} sm={{size:8}}>
      <FormGroup>
        <Label for="title">Description</Label>
        <Input type="text" name="description" id="description" placeholder={'Description'} value={description} onChange={changeDescription}/>
      </FormGroup>
    </Col>
  </Row>
}

const FieldHeader = (props) => {
  const {field, selected} = props
  const {title} = field

  if (selected) {
    return <FieldHeaderSelected {...props} />
  }

  return <Row>
      <Col xs={{size:12}}>
        <FormGroup>
          <Label for="title">{title}</Label>
        </FormGroup>
      </Col>
    </Row>
}

export default withRouter(FieldHeader);
