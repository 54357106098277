import BaseDialog from "../../../../components/Common/BaseDialog";
import React, {useEffect, useState} from "react";
import {Button, Col, Input, Label, Nav, NavItem, NavLink, Row, Table} from "reactstrap";
import classnames from "classnames";
import Container from "reactstrap/lib/Container";
import {getAxiosDefaultConfig, getTenantId} from "../../../../utils";
import {useParams} from "react-router";
import toastr from "toastr";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import {API_URL} from "../../../../config";
import axios from "axios";
import {handleError} from "../../../../store/core/api";
import {useDropzone} from "react-dropzone";
import AddressBook from "../../../Contacts/dialogs/AddressBook";

const SelectContacts = (props) => {

  const {open, handleClose, contacts, setSelectedContacts} = props

  const [checkedAll, setCheckedAll] = useState()
  const [selectedEmails, setSelectedEmails] = useState([])

  const toggleEmail = (email) => {
    const newEmails = [...selectedEmails]
    const emailIndex = contacts.indexOf(email)
    if (emailIndex!==-1) {
      newEmails.splice(emailIndex,1)
    } else {
      newEmails.push(email)
    }
    setSelectedEmails(newEmails)
  }

  useEffect(()=>{
    if (checkedAll) {
      setSelectedEmails([...contacts.map((c)=>c.email)].filter((v,i,s)=>s.indexOf(v)===i))
    } else {
      setSelectedEmails([])
    }
    // eslint-disable-next-line
  },[checkedAll])

  return <BaseDialog
    title={'Select contacts'}
    // secondaryAction={handleClose}
    // handleEditTitle={handleEditTitle}
    primaryAction={()=>{
      setSelectedContacts(selectedEmails)
      handleClose()
    }}
    primaryActionTitle={'Add selected contacts'}
    // deleteAction={
    //   deleteEntity && form.id ? () => setOpenDelete(true) : null
    // }
    // busy={busy}
    open={open}
    handleClose={handleClose}
    scrollable={true}
    size='lg'
    // contents={contents}
  >
    {contacts&&<Row>
      <Col lg="12">
        <div className="">
          <div className="table-responsive">
            <Table className="project-list-table table-nowrap table-centered table-borderless table-zebra">
              <thead>
              <tr>
                <th scope="col" style={{ width: "100px" }}>
                  <input
                    type={"checkbox"}
                    checked={checkedAll}
                    onChange={(e)=>setCheckedAll(e.target.checked)}
                  />
                </th>
                <th scope="col">First name</th>
                <th scope="col">Last name</th>
                <th scope="col">Email</th>
                <th scope="col">Organization</th>
              </tr>
              </thead>
              <tbody>

              {contacts.map((item,i)=>{
                return <tr className='c-item-list' key={i} onClick={()=>toggleEmail(item.email)}>
                  <td>
                    <input
                      type={"checkbox"}
                      checked={selectedEmails.includes(item.email)}
                      onChange={()=>toggleEmail(item.email)}
                    />
                  </td>
                  <td>
                    {item.firstName?item.lastName:'-'}
                  </td>
                  <td>
                    {item.lastName?item.lastName:'-'}
                  </td>
                  <td>
                    {item.email?item.email:'-'}
                  </td>
                  <td>
                    {item.organization?item.organization:'-'}
                  </td>
                </tr>
              })}

              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    </Row>}
  </BaseDialog>
}


export const SendDialog = (props) => {
  const {uuid} = useParams()

  const {open, handleClose, surveyData} = props
  const [embedHeight, setEmbedHeight] = useState(surveyData?300+surveyData.questions.length*100:300)
  const [embedWidth, setEmbedWidth] = useState(640)
  const [activeTab, setActiveTab] = useState(1);
  const [busy, setBusy] = useState(false)
  const [uploadedContacts, setUploadedContacts] = useState()
  const [selectContactsOpen, setSelectContactsOpen] = useState(false)

  const [emails, setEmails] = useState([])

  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const [sending, setSending] = useState(false)

  const [openContact, setOpenContact] = useState(false)

  const sendEmail = () => {
    // const tenantHeader =  { ...headers, 'X-TenantID': getTenantId() }

    setSending(true)
    axios.post(`${API_URL}/addons/srv/surveys/${uuid}/share`,{
      message,
      subject,
      toRecipients: emails
    },getAxiosDefaultConfig())
      .then((response) => {
        setSending(false);
        handleClose()
        toastr.success(`Successfully shared survey`);
      })
      .catch(error => {
        setSending(false);
        toastr.error(error.message)
      })
  }

  const changeSubject = (e) =>{
    const value = e.target.value
    setSubject(value)
  }

  const changeMessage = (e) =>{
    const value = e.target.value
    setMessage(value)
  }

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const onDrop = async (selectedFiles) => {
      const url = API_URL + "/addons/srv/contacts/load"
      const axiosConfig = getAxiosDefaultConfig();
      const formData = new FormData();
      formData.append("file", selectedFiles[0]);

      try {
        setBusy(true);
        const result = await axios
          .post(url, formData, axiosConfig)
          .then((res) => res.data)
          .catch(handleError);

        if (result.contacts) {
          setUploadedContacts(result.contacts)
        }

        if (result.errors[0]) {
          toastr.error("The file you uploaded has some errors. Please resolve them and reupload.");
        }
        if (result.warnings[0]) {
          toastr.error("The file you uploaded has some warnings");
        }
        setBusy(false);
      } catch (e) {
        setBusy(false);
        toastr.error(e.message);
      }
    }

    useEffect(()=>{
      if (uploadedContacts) {
        setSelectContactsOpen(true)
      }
    },[uploadedContacts])

  useEffect(()=>{
    if (selectContactsOpen===false) {
      setUploadedContacts(null)
    }
  },[selectContactsOpen])

  const surveyURL = `https://${window.location.host}/${getTenantId()}/survey/${uuid}`
  const summaryURL = `https://${window.location.host}/${getTenantId()}/survey/summary/${uuid}`
  const surveyEmbedURL = `<iframe src="${surveyURL}?embedded=true" width="${embedWidth}" height="${embedHeight}" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`
  const summaryEmbedURL = `<iframe src="${summaryURL}?embedded=true" width="${embedWidth}" height="${embedHeight}" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`


  const copySurveyURL = () => {
    navigator.clipboard.writeText(surveyURL)
    toastr.info('Link copied to clipboard!')
  }

  const copySummaryURL = () => {
    navigator.clipboard.writeText(summaryURL)
    toastr.info('Link copied to clipboard!')
  }

  const copySurveyEmbedURL = () => {
    navigator.clipboard.writeText(surveyEmbedURL)
    toastr.info('Embedding code copied to clipboard!')
  }

  const copySummaryEmbedURL = () => {
    navigator.clipboard.writeText(summaryEmbedURL)
    toastr.info('Link copied to clipboard!')
  }

  const primaryActions = {
    1: {
      title: 'Send email',
      action: sendEmail
    },
    2: {
      title: 'Copy',
      action: copySurveyURL
    },
    3: {
      title: 'Copy',
      action: copySurveyEmbedURL
    }
  }

  const secondaryActions = {
    1: {},
    2: {
      title: 'Copy summary link',
      action: copySummaryURL
    },
    3: {
      title: 'Copy summary link',
      action: copySummaryEmbedURL
    }
  }

  const openContacts = () => {
    setOpenContact(true)
  }

  const handleCloseContacts = (contacts) => {
    if (contacts&&contacts.length!==0) {
      setEmails(contacts.map((c)=>c.email))
    }
    setOpenContact(false)
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return <BaseDialog
    title={'Share Survey'}
    // secondaryAction={handleClose}
    // handleEditTitle={handleEditTitle}
    primaryAction={primaryActions[activeTab].action}
    primaryActionTitle={primaryActions[activeTab].title}
    secondaryAction={secondaryActions[activeTab].action}
    secondaryActionTitle={secondaryActions[activeTab].title}
    isValid={activeTab!==2&&activeTab!==3?(emails.length!==0&&subject!==''&&message!==''):true}
    // deleteAction={
    //   deleteEntity && form.id ? () => setOpenDelete(true) : null
    // }
    busy={busy||sending}
    open={open}
    handleClose={handleClose}
    scrollable={true}
    // contents={contents}
  >
    {uploadedContacts&&<SelectContacts contacts={uploadedContacts} open={selectContactsOpen} setSelectedContacts={(emails)=>setEmails(emails)} handleClose={()=>setSelectContactsOpen(false)} />}
    {openContact&&<AddressBook open={openContact} handleClose={handleCloseContacts} />}
    <Container style={{minHeight: '300px'}}>
    <Row className={' survey-nav'} style={{marginBottom: 16}}>
      <Col >
        <div>
          <Nav tabs style={{display:'block'}}>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 1 })}
                onClick={() => { toggle(1); }}
              >
                <i className='bx bx-mail-send font-size-24'></i>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 2 })}
                onClick={() => { toggle(2); }}
              >
                <i className='bx bx-link font-size-24'></i>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 3 })}
                onClick={() => { toggle(3); }}
              >
                <i className='bx bx-code font-size-24'></i>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </Col>
    </Row>

    {activeTab===1&&<>
    <Row  style={{marginBottom: 8}}>
      <Col col={10}>
        <Label for='to'>To*</Label>
        <ReactMultiEmail
          placeholder="Add multiple recipients here"
          emails={emails}
          onChange={(emails)=>{
            setEmails(emails)
          }}
          validateEmail={email => {
            return isEmail(email); // return boolean
          }}
          getLabel={(
              email,
              index,
              removeEmail
            ) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />

      </Col>

    </Row>

      <Row>
        <Col xs={12}>
          <div {...getRootProps()}  className='float-right mb-4'>
            <input {...getInputProps()} />
            <Button>
              <div className='d-flex justify-content-center align-items-center align-content-center'>
                <div className='d-flex mb-1'>
                  {/*<i className='bx bx-upload font-size-20'></i>*/}
                  <i className='bx bxs-file-import font-size-20'></i>
                </div>
                <div className='d-flex ml-2'>
                  Select from excel file
                </div>
              </div>
            </Button>
          </div>
          {' '}
          <Button className='float-right mr-2' color='primary' onClick={openContacts}>
            <div className='d-flex justify-content-center align-items-center align-content-center'>
              <div className='d-flex mb-1'>
                <i className='bx bxs-user-detail font-size-20'></i>
              </div>
              <div className='d-flex ml-2'>
                Select from contacts
              </div>
            </div>
          </Button>
        </Col>

      </Row>

      <Row  style={{marginBottom: 8}}>
        <Col>
          <Label for='subject'>Subject*</Label>
          <Input type="text" name='subject' id='subject'
                 value={subject} placeholder={'A topic for your survey'}
                 onChange={changeSubject}
          />
        </Col>
      </Row>

      <Row  style={{marginBottom: 8}}>
        <Col>
          <Label for='message'>Message*</Label>
          <Input type="textarea" name='message' id='message' placeholder={'Your message'}
                 value={message}
                 onChange={changeMessage}
          />
        </Col>
      </Row>
    </>}

      {activeTab===2&&<>

        <Row>
          <Col xs>
            <Label for='link'>Survey link</Label>
            <Input type="text" name='link' id='link'
                   disabled
                   value={surveyURL}
            />
          </Col>

          <div style={{marginTop:27}}>
            <Button color='secondary' outline href={surveyURL} target='_blank'> <i className='bx bx-link-external'></i> </Button>
          </div>

        </Row>

      <Row className='mt-4'>
        <Col xs>
            <Label for='link'>Summary link</Label>
            <Input type="text" name='link' id='link'
                   disabled
                   value={summaryURL}
            />
        </Col>

          <div style={{marginTop:27}}>
            <Button color='secondary' outline href={summaryURL} target='_blank'> <i className='bx bx-link-external'></i> </Button>
          </div>

      </Row>
      </>}

      {activeTab===3&&<>
      <Row  style={{marginBottom: 8}}>
        <Col>
          <Label for='link'>Survey embed code</Label>
          <Input type="text" name='link' id='link'
                 disabled
                 value={surveyEmbedURL}
          />
        </Col>
      </Row>

      <Row  style={{marginBottom: 8}}>
        <Col>
          <Label for='link'>Summary embed code</Label>
          <Input type="text" name='link' id='link'
                 disabled
                 value={summaryEmbedURL}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Label for='width'>Width</Label>
          <Input min={640} type="number" step="10" name='width' id='width'
                 value={embedWidth}
                 onChange={(e)=>setEmbedWidth(parseInt(e.target.value))}
          />
        </Col>

          <Col>
            <Label for='height'>Height</Label>
            <Input min={300} type="number" step="10" name='height' id='height'
                   value={embedHeight}
                   onChange={(e)=>setEmbedHeight(parseInt(e.target.value))}
            />
          </Col>
        </Row>

        </>}

  </Container>


  </BaseDialog>
}

