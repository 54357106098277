import React from 'react';
import {withRouter} from "react-router-dom";
import {FormGroup} from "reactstrap";
import ListOfText from "../common/ListOfText";

const ShortAnswer = (props) => {
  const {field, fieldNumber} = props

  return <>
    <FormGroup row>
      <ListOfText field={field} fieldNumber={fieldNumber}/>
    </FormGroup>
  </>
}

export default withRouter(ShortAnswer);
