import {
  call,
  delay,
  fork,
  put,
  race,
  take,
  takeEvery,
} from "redux-saga/effects";
import * as ActionTypes from "../core/actionTypes";
import {
    buildActionType,
    createCustomAction,
    createDeleteFailureMethod,
    createDeleteSuccessMethod,
    createGetAllFailureMethod,
    createGetAllSuccessMethod,
    createGetFailureMethod,
    createGetMethod,
    createGetSuccessMethod,
    createPostFailureMethod,
    createPostSuccessMethod,
    createPutFailureMethod,
    createPutSuccessMethod, customEntityGen,
    delEntityGen,
    fetchAllGen,
    fetchEntityGen,
    postEntityGen,
    putEntityGen,
} from "./factory";
import { ApiEndpoint } from "./endpoint";
import { Operation } from "./operation";

const getEntitySuccess = (entityType, result) => createGetSuccessMethod(entityType, result);
const getEntityFailure = (entityType, result) => createGetFailureMethod(entityType, result);
const getAllEntitySuccess = (entityType, result) => createGetAllSuccessMethod(entityType, result);
const getAllEntityFailure = (entityType, result) => createGetAllFailureMethod(entityType, result);
const createEntitySuccess = (entityType, result) => createPostSuccessMethod(entityType, result);
const createEntityFailure = (entityType, result) => createPostFailureMethod(entityType, result);
const updateEntitySuccess = (entityType, result) => createPutSuccessMethod(entityType, result);
const updateEntityFailure = (entityType, result) => createPutFailureMethod(entityType, result);
const deleteEntitySuccess = (entityType, result) => createDeleteSuccessMethod(entityType, result);
const deleteEntityFailure = (entityType, result) => createDeleteFailureMethod(entityType, result);
const getEntity = (entityType, params) => createGetMethod(entityType, params)
const customAction = (entityType, result) => createCustomAction(entityType, result);

const getAllEntitiesGen = (entity) =>
    fetchAllGen(ApiEndpoint[entity],
        (result) => getAllEntitySuccess(entity, result),
        (result) => getAllEntityFailure(entity, result));

const getEntityGen = (entity, {params}) =>
    fetchEntityGen(ApiEndpoint[entity], params,
        (result) => getEntitySuccess(entity, result),
        (result) => getEntityFailure(entity, result));

const createEntityGen = (entity, {payload}) =>
    postEntityGen(ApiEndpoint[entity], payload,
        (result) => createEntitySuccess(entity, result),
        (result) => createEntityFailure(entity, result));

const updateEntityGen = (entity, {payload}) =>
    putEntityGen(ApiEndpoint[entity], payload,
        (result) => updateEntitySuccess(entity, result),
        (result) => updateEntityFailure(entity, result));

const deleteEntityGen = (entity, {payload}) =>
    delEntityGen(ApiEndpoint[entity], payload,
        (result) => deleteEntitySuccess(entity, result),
        (result) => deleteEntityFailure(entity, result));

const customEntity = (entity, {payload}) =>
    customEntityGen(payload,
        (result) => customAction(entity, result),);

export function* watchAllEntities(entity) {
    const actionType = buildActionType(entity, ActionTypes.GET_ALL);
    yield takeEvery(actionType, () => getAllEntitiesGen(entity));
}

export function* watchEntityById(entity) {
    const actionType = buildActionType(entity, ActionTypes.GET);
    yield takeEvery(actionType, (params) => getEntityGen(entity, params));
}

export function* watchEntityCreation(entity) {
    const actionType = buildActionType(entity, ActionTypes.POST);
    yield takeEvery(actionType, (payload => createEntityGen(entity, payload)));
}

export function* watchEntityUpdate(entity) {
    const actionType = buildActionType(entity, ActionTypes.UPDATE);
    yield takeEvery(actionType, (payload) => updateEntityGen(entity, payload));
}

export function* watchEntityDelete(entity) {
    const actionType = buildActionType(entity, ActionTypes.DELETE);
    yield takeEvery(actionType, (payload) => deleteEntityGen(entity, payload));
}
export function* watchEntityCustom(entity) {
    const actionType = buildActionType(entity, ActionTypes.CUSTOM);
    yield take(actionType, (payload) => customEntity(entity, payload));
}

function* pollTask({params}, pollingDelay, entity) {
    while (true) {
        yield put(getEntity(entity, params));
        yield delay(pollingDelay || 5000);
    }
}

function* pollTaskWatcher(entity, pollingDelay) {
    const startPoll = buildActionType(entity, ActionTypes.START_POLL);
    const endPoll = buildActionType(entity, ActionTypes.END_POLL);
    while (true) {
        const action = yield take(startPoll)
        yield race([
            call(pollTask, action, pollingDelay, entity),
            take(endPoll)
        ]);
    }
}

function getCoreSagas(entityType, entityOperations, pollingDelay) {

    const coreSagas = [];
    if (entityOperations.includes(Operation.get))
        coreSagas.push(fork(() => watchEntityById(entityType)))
    if (entityOperations.includes(Operation.getAll))
        coreSagas.push(fork(() => watchAllEntities(entityType)))
    if (entityOperations.includes(Operation.create))
        coreSagas.push(fork(() => watchEntityCreation(entityType)))
    if (entityOperations.includes(Operation.update))
        coreSagas.push(fork(() => watchEntityUpdate(entityType)))
    if (entityOperations.includes(Operation.poll))
        coreSagas.push(fork(() => pollTaskWatcher(entityType, pollingDelay)))
    if (entityOperations.includes(Operation.delete))
        coreSagas.push(fork(() => watchEntityDelete(entityType)))
    if (entityOperations.includes(Operation.custom))
        coreSagas.push(fork(() => watchEntityCustom(entityType)));
    return coreSagas;
}

export default getCoreSagas;
