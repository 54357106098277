import React, {useState} from "react";
import {Badge, Card, CardBody, Col, Media, Row} from "reactstrap";
import ActionsContextPropMenu from "../../../../Containers/ActionsContextPropMenu";
import OverflowTip from "../../../../../components/Common/OverflowTip";

function PropCardSettings(props)
{

  const {isDataStream} = props;
  const [menuIndex, setMenuIndex] = useState(null);
  const [pointerPosition, setPointerPosition] = useState({x: 0, y: 0});
  const handleContextMenu = (e, setting, key, id) => {
    e.preventDefault();
    // const cardPosition = document.getElementById(id).getBoundingClientRect().bottom
    const windowHeight = window.innerHeight - e.clientY;
    if (windowHeight < 271) setPointerPosition({x: e.clientX, y: e.clientY, overflow: true});
    else setPointerPosition({x: e.clientX, y: e.clientY});
    props.handleContextPropClick(e, setting);

    setMenuIndex(key);
  };

    return (
      <Row>
        {props?.properties?.map((property, key) => { 
          const borderColor = props.selectedProp?.id === property.id ? "primary" : "light";
          return (
            <Col xl="4" sm="6" key={"_project_" + key}>
              <Card
                className={`cursor-pointer border border-${borderColor}`}
                //onClick={property.handleClick}
                onContextMenu={(e) => {
                  e.stopPropagation();
                  if (menuIndex !== key) {
                    props.handlePropSelect(e, property);
                  }
                  handleContextMenu(e, property, key, "_project_" + key);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (menuIndex !== key) {
                    props.handlePropSelect(e, property);
                  }
                }}
                onDoubleClick={() => props.handlePropClick(property.route)}
              >
                <CardBody>
                  <Media className={"align-items-center"}>
                    {props.starred && (
                      <div className="position-absolute" style={{right: 0, bottom: 0, margin: 8}}>
                        <i className={`font-weight-bold bx bxs-star font-size-20`} style={{color: "#ffca00"}} />
                      </div>
                    )}
                    <div className="avatar-sm mr-4">
                      {!isDataStream ? (
                        <span className="avatar-title rounded-circle bg-light text-primary font-size-24">
                          <i className={`bx ${property.icon}`} />
                        </span>
                      ) : (
                        property.thumbnail
                      )}
                    </div>
                    <Media className="overflow-hidden" body>
                      <h5 className="text-truncate font-size-15 text-dark d-flex align-items-center" id={"label" + key}>
                        <OverflowTip>{property.label}</OverflowTip>
                        {property.published && <i className="font-weight-bold bx bxs-check-circle text-success ml-1" />}
                      </h5>
                      {property.info && (
                        <Badge color={"primary"} style={{backgroundColor: property.color}}>
                          {property.info}
                        </Badge>
                      )}
                      {property?.type && (
                        <Badge color={"primary"} style={{fontSize: 12, fontWeight: 400}}>
                          {property.type}
                        </Badge>
                      )}
                    </Media>
                    {props.hasNext && <i className="font-weight-bold bx bx-chevron-right text-primary font-size-18" />}
                  </Media>
                </CardBody>
              </Card>
              {menuIndex === key && <ActionsContextPropMenu container={props.selectedProp} setIsShown={setMenuIndex} handleClick={property.handleClick} position={pointerPosition} {...props} />}
            </Col>
          );
        })}
      </Row>
    );
}

export default PropCardSettings;
