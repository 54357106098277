import React, {useEffect, useRef, useState} from "react";
import BaseContent from "../../components/Common/BaseContent";
import ContactsList from "../Contacts/ContactsList";
import axios from "axios";
import {API_URL} from "../../config";
import {getAxiosDefaultConfig} from "../../utils";
import {Button, Col, Input, Row} from "reactstrap";
import DeleteDialog from "../Containers/DeleteDialog";
import toastr from "toastr";
import PaginationComponent from "react-reactstrap-pagination";

const Contacts = (props) => {

  const [contacts, setContacts] = useState({content:[]})
  const [selectedEmails, setSelectedEmails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [searchValue,setSearchValue] = useState('')

  const {setSelectedContacts, addressBook} = props

  useEffect(()=>{
    if (setSelectedContacts&&selectedEmails&&contacts&&contacts.content.length!==0) {
      setSelectedContacts(contacts.content.filter((c)=>selectedEmails.includes(c.uuid)))
    }
    // eslint-disable-next-line
  },[selectedEmails])

  const getData = (page=0,searchValue='') => {
    setIsLoading(true)
    setSelectedEmails([])
    axios.get(`${API_URL}/addons/srv/contacts?page=${page}&q=${encodeURIComponent(searchValue)}&sort=email`,getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setContacts(data)
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  const deleteContacts = () => {
    setOpenDeleteDialog(true)
  }

  const handleDelete = () => {

    setDeleteLoading(true)
    axios.delete(`${API_URL}/addons/srv/contacts`, {
      ...getAxiosDefaultConfig(),
      data: {
        uuids: selectedEmails
      }
    })
      .then((response) => {
        setDeleteLoading(false);
        toastr.success(`Successfully deleted contact${selectedEmails.length>1?'s':''}`);
        getData()
      })
      .catch(error => {
        setDeleteLoading(false);
        toastr.error(error.message)
      })
  }

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleSelectPage = (page) => {
    setPage(page-1)
  }

  const changeSearchValue = (e) => {
    const value = e.target.value

    setSearchValue(value)

    if (value.length>3) {
      clearTimeout(searchTimeout.current)
      searchTimeout.current = setTimeout(()=>{
        getData(0,searchValue)
      },800)
    } else if (value==='') {
      getData(0,'')
    }

  }

  const searchTimeout = useRef()

  useEffect(()=>{
    getData(page,searchValue)
    // eslint-disable-next-line
  },[page])

  const breadcrumbs = [{title:'Contacts'}]

  const isSearch = searchValue&&searchValue.length!==0

  return (
    <BaseContent
      page={!addressBook}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
      renderActions={()=>{
        return <Row>
          <Col xs={6} style={{textAlign:'right', width:'250px'}}>
            {selectedEmails.length!==0&&!addressBook&&<Button outline color="danger" disabled={selectedEmails.length===0||deleteLoading} onClick={()=>deleteContacts()}>Delete contact{selectedEmails.length>1?`s (${selectedEmails.length})`:''}</Button>}
          </Col>
        {<Col xs={6} style={{textAlign:'right'}}><Input type="text" name={`search`} id={`search`}
                 placeholder={'Search...'}
                 value={searchValue}
                 onChange={changeSearchValue}
        /></Col>}
        </Row>
      }}
    >
      <DeleteDialog open={openDeleteDialog} handleClose={closeDeleteDialog} handleDelete={handleDelete} />
      <ContactsList contacts={contacts} addressBook={addressBook} isLoading={isLoading} reload={getData} setSelectedEmails={setSelectedEmails} selectedEmails={selectedEmails} isSearch={isSearch}/>
      {contacts&&contacts.content.length!==0&&!isLoading&&!isSearch&&<Row>
        <Col xs={{size:6, offset:6}} className='responses-navigation'>
          <PaginationComponent totalItems={contacts.totalElements} pageSize={contacts.pageable.pageSize} onSelect={handleSelectPage} />
        </Col>
      </Row>}
    </BaseContent>
  );
}

export default Contacts
