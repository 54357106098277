import React, { useCallback, useMemo, useState } from "react";
import BaseContent from "../../../components/Common/BaseContent";
import { connect } from "react-redux";
import { Schema } from "../../../store/core/schema";
import { EntityType } from "../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import CardSettings from "../../../components/Common/card-settings";
import {useListToggle, useToggle} from "../../../helpers/hooks";
import { createTag, deleteTag, updateTag } from "../../../store/tag/actions";
import ListToggle from "../../../components/Common/ListToggle";
import ListSettings from "../../../components/Common/ListSettings";

const entityType = EntityType.Tag;

const breadcrumbs = [
  { title: "Settings", link: "/settings" },
  { title: "Tags", link: "#" },
];

const Tags = (props) => {
  const { context, createTag, deleteTag, updateTag } = props;
  const { result, isLoading, error } = context.Tag.getAll;
  const currentProperty = context.Tag.get.result;
  const [openModal, toggleModal] = useToggle(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const [isList, toggleList] = useListToggle();

  const handleEntityClick = useCallback(
    (entity) => {
      setEntity(entity);
      toggleModal();
    },
    [toggleModal]
  );

  React.useEffect(() => {
    setEntity(currentProperty);
  }, [currentProperty]);

  const handleCreate = useCallback(() => {
    setEntity(Schema[entityType]);
    toggleModal();
  }, [toggleModal]);

  const propertyCards = useMemo(() => {
    return result.map((tag) => ({
      label: tag.name,
      icon: "bx-purchase-tag",
      color: tag.color,
      info: tag.color,
      handleClick: () => handleEntityClick(tag),
    }));
  }, [handleEntityClick, result]);

  const list = useMemo(() => {
    return result.map((tag) => ({
      label: tag.name,
      color: tag.color,
      handleClick: () => handleEntityClick(tag),
    }));
  }, [handleEntityClick, result]);

  const listColumns = [
    { name: "Name", prop: "label" },
    { name: "Color", prop: "color" },
  ];

  return (
    <>
      <BaseContent
        title={"Tags"}
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
      >
        {!isList && <CardSettings settings={propertyCards} />}
        {isList && <ListSettings contents={list} columns={listColumns} />}
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={openModal}
          handleClose={toggleModal}
          createEntity={createTag}
          updateEntity={updateTag}
          deleteEntity={deleteTag}
          context={context.Tag}
        />
      </BaseContent>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createTag,
  updateTag,
  deleteTag,
})(Tags);
