import React, {useEffect, useState} from 'react';
import BaseContent from '../../../components/Common/BaseContent';
import {Button, Col, Container, FormGroup, Input, Label, Nav, NavItem, Row} from "reactstrap";
import classnames from "classnames";
import {Redirect, useParams} from "react-router";
import toastr from "toastr";
import DeleteDialog from "../../Containers/DeleteDialog";
import {assetThumb, getAxiosDefaultConfig, getTenantId} from "../../../utils";
import axios from "axios";
import {API_URL} from "../../../config";
import {Link} from "react-router-dom";
import Activity from "./Activity";
import BaseDialog from "../../../components/Common/BaseDialog";
import {SelectStoryDialog} from "./components/SelectStoryDialog";
import StoryItem from "../Stories/Slideshow/components/StoryItem";

const WebsiteEditNew = (props) => {
  const {id,page} = useParams()

  const [changed, setChanged] = useState()
  const [websiteData, setWebsiteData] = useState()
  const [deleted, setDeleted] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState()
  const [activeTab, setActiveTab] = useState()
  const [deleteLoading, setDeleteLoading] = useState()
  const [isLoading, setIsLoading] = useState()
  const [EmbedOpen, setEmbedOpen] = useState(false)
  const [editingPage, setEditingPage] = useState()
  const [openAssignStory, setOpenAssignStory] = useState()
  const [assignedStory, setAssignedStory] = useState()
  const [embedWidth, setEmbedWidth] = useState(640)
  const [embedHeight, setEmbedHeight] = useState(480)
  const [thumb, setThumb] = useState()
  const [embedResponsive, setEmbedResponsive] = useState(false)

  //eslint-disable-next-line
  const [storyData, setStoryData] = useState({
    title: 'No story assigned'
  })

  useEffect(()=>{
    if (page==='activity') {
      setActiveTab(2)
    } else {
      setActiveTab(1)
    }
  },[page])

  const [breadcrumbs,setBreadcrumbs] = useState(
    [
      {title: 'Websites', link: '/websites'},
      {title: 'New', link: '/websites/new'}
    ]
  )
  // Updates breadcrumbs to show /edit or /new links
  const updateBreadcrumbs = (websiteData) => {
    let newBreadcrumbs
    if (id) {
      newBreadcrumbs = [
        {title: 'Websites', link: '/websites'},
      ]
    } else {
      newBreadcrumbs = [
        {title: 'Websites', link: '/websites'},
        {title: 'New', link: '/websites/new'}
      ]
    }
    if (websiteData&&websiteData.name!=='') {
      newBreadcrumbs.push({title: `${changed?'*':''}${websiteData.name}`, link: id?`/websites/edit/${id}`:'#'})
    }
    setBreadcrumbs(newBreadcrumbs)
  }

  const getWebsite = (id) => {
    setIsLoading(true)

    axios.get(`${API_URL}/str/websites/${id}`,getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setWebsiteData({...websiteData,...data});
        setStoryData(data.story);
        setIsLoading(false);
        setAssignedStory(data.storyId)
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  // Load defaults or load from BE
  useEffect(()=>{
    if (id) {
      getWebsite(id)
    } else {
      setChanged(true)
      setWebsiteData({
        name: '',
        description: ''
      })
    }
    // eslint-disable-next-line
  },[])

  // Update breadcrumbs appropriately
  useEffect(()=>{
    if (websiteData&&!!websiteData.name){
      updateBreadcrumbs(websiteData)
    }
    // eslint-disable-next-line
  },[websiteData,changed,id])

  const createWebsite = (payLoad) => {
    const newPayload = {
      name: payLoad.name,
      description: payLoad.description
    }
    setIsLoading(true)
    axios.post(`${API_URL}/str/websites`,newPayload, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setWebsiteData(data);
        setStoryData(data.story);
        setEditingPage(true);
        setIsLoading(false);
        setChanged(false)
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  const updateWebsite = (payLoad) => {
    const updatePayload = {
      name: payLoad.name,
      description: payLoad.description
    }
    setIsLoading(true)
    axios.put(`${API_URL}/str/websites/${id}`,updatePayload, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setWebsiteData({...websiteData,...data});
        setIsLoading(false);
        setChanged(false)
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  const saveWebsite = () => {
    if (!id) {
      createWebsite(websiteData)
    } else {
      updateWebsite(websiteData)
    }
  }

  const handleDelete = () => {

    setDeleteLoading(true)
    axios.delete(`${API_URL}/str/websites/${id}`,getAxiosDefaultConfig())
      .then((response) => {
        setDeleteLoading(false);
        setDeleted(true)
        toastr.success(`Successfully deleted website`);
      })
      .catch(error => {
        setDeleteLoading(false);
        toastr.error(error.message)
      })
  }

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const setName = (e) => {
    const value = e.target.value
    setWebsiteData({
      ...websiteData,
      name: value
    })
    setChanged(true)
  }

  const setDescription = (e) => {
    const value = e.target.value
    setWebsiteData({
      ...websiteData,
      description: value
    })
    setChanged(true)
  }

  const handleClose = () => {
    setEmbedOpen(false)
  }

  const handleCloseAssignStory = (refresh) => {
    if (refresh) {
      getStory()
    }
    setOpenAssignStory(false)
  }

  // const getDelayedStatus = () => {
  //   setTimeout(getStatus,2000)
  // }
  //
  // const getStatus = (closeAssign) => {
  //   axios.get(`${API_URL}/str/websites/${id}/status`, getAxiosDefaultConfig())
  //     .then((response) => {
  //       const {data} = response;
  //       const {websiteshot} = data
  //       setWebsiteshotData(websiteshot?`data:image/bmp;base64,${data.websiteshot}`:``)
  //       setWebsiteData((f)=>({...f, ...data}))
  //       setAssignedStory(data.storyId)
  //       setThumb((f)=>(data.storyId?f:null))
  //       if (closeAssign) setOpenAssignStory(false)
  //     })
  //     .catch(error => {
  //       if (error.response) {
  //         console.log(`An error occurred:\n${error.response.data}`);
  //       }
  //     })
  // }

  const getStory = () => {
    axios.get(`${API_URL}/str/websites/${id}/story`, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response
        setStoryData(data)
        setWebsiteData((f)=>({...f, storyId: data.uuid}))
      })
      .catch(error => {
        if (error.response) {
          console.log(`An error occurred:\n${error.response.data}`)
        }
      })
  }

  const handleRemoveStory = () => {
    setIsLoading(true)
    axios.post(`${API_URL}/str/websites/${id}/unlink`,{},getAxiosDefaultConfig())
      .then((response) => {
        setIsLoading(false)
        getWebsite(id)
      })
      .catch(error => {
        setIsLoading(false)
      })
  }

  useEffect(()=>{
    if (assignedStory) {
      getStory()
    }
    // eslint-disable-next-line
  },[assignedStory])

  useEffect(() => {
    if (storyData) {
      assetThumb(storyData.coverPage, setThumb,'stories')
    }
  }, [storyData])

  const storyURL = `https://${window.location.host}/${getTenantId()}/story-embed/${id}`
  const embedURL = `<iframe title="Repox Stories" src="${storyURL}" width="${embedResponsive?'100%':embedWidth}" height="${embedResponsive?'100%':embedHeight}" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`

  const copyStoryURL = () => {
    navigator.clipboard.writeText(embedURL)
    toastr.info('Link copied to clipboard!')
  }

  return (
    <BaseContent
      // isLoading={isLoading}
      breadcrumbs={breadcrumbs}
      renderActions={()=> {
        return <Row style={{minHeight:73}}>
          {websiteData&&websiteData.uuid&&<Col xs={12} style={{textAlign: 'right'}}>
            <Button color="warning" onClick={handleRemoveStory} className='mr-2' disabled={!websiteData.storyId}>Remove story</Button>
            <Button color="info" onClick={()=>{setOpenAssignStory(true)}}>Assign story</Button>
            <div style={{display: 'inline', height: '100px', borderLeft: '2px solid #cdcdcd', margin: '0 10px'}}> </div>
            <Button color="success" onClick={()=>{setEmbedOpen(true)}}>Embed code</Button>
          </Col>}
        </Row>
      }}
    >
      {editingPage&&<Redirect to={`/websites/edit/${websiteData.uuid}`} />}

      {openAssignStory&&<SelectStoryDialog open={openAssignStory} handleClose={handleCloseAssignStory}/>}

      {EmbedOpen&&<BaseDialog
        title={'Embed code'}
        primaryAction={copyStoryURL}
        primaryActionTitle={'Copy'}
        isValid={true}
        open={EmbedOpen}
        handleClose={handleClose}
        scrollable={true}
        // contents={contents}
      >

        <Row  style={{marginBottom: 8}}>
          <Col>
            <Label for='link'>Story mbed code</Label>
            <Input type="textarea" name='link' id='link' style={{minHeight:90}}
                   disabled
                   value={embedURL}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label for='width'>Width</Label>
            <Input min={640} type="number" step="10" name='width' id='width'
                   value={embedWidth}
                   onChange={(e)=>setEmbedWidth(parseInt(e.target.value))}
            />
          </Col>

          <Col>
            <Label for='height'>Height</Label>
            <Input min={300} type="number" step="10" name='height' id='height'
                   value={embedHeight}
                   onChange={(e)=>setEmbedHeight(parseInt(e.target.value))}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Input type="checkbox" name={`checkbox-responsive`} id={`checkbox-responsive`}
                     checked={embedResponsive}
                     value={embedResponsive}
                     onChange={()=>setEmbedResponsive(!embedResponsive)}
              />
              <Label for={`checkbox-responsive`} check>Responsive</Label>
            </FormGroup>
          </Col>
        </Row>

      </BaseDialog>}

      {(isLoading||deleteLoading)&&<div className='custom-loading-overlay'> </div>}

      {deleted&&<Redirect to={`/websites`} />}

      <DeleteDialog open={openDeleteDialog} handleClose={closeDeleteDialog} handleDelete={handleDelete} />

      <Row className={'justify-content-center survey-nav'} style={{marginBottom: 16}}>
        <Col >
          <div>
            <Nav tabs style={{display:'block'}}>
              <NavItem>
                <Link to={`/websites/edit/${id}`} className={classnames({ active: activeTab === 1, 'nav-link':true })} > Website </Link>
              </NavItem>
              <NavItem >
                <Link to={!!id&&'new'&&`/websites/edit/${id}/activity`} className={classnames({ active: activeTab === 2, 'nav-link':true, 'link-disabled': !id})} > Activity </Link>
              </NavItem>
            </Nav>
          </div>
        </Col>
      </Row>

      <Container style={{paddingTop: 30, minHeight: '60vh'}}>

        {activeTab===1&&<Container>
          {websiteData&&<Row className=''>
             <Col xs={6}>

               <Row xs={12}>
                 <Col xs={12}>
                   <Label for={`name`}>Website name <b>*</b></Label>
                   <Input type="text" name={`name`} id={`name`}
                          placeholder={`Website name`}
                          value={websiteData.name}
                          onChange={setName}
                   />
                 </Col>
               </Row>

               <Row xs={12} style={{marginTop: 8}}>
                 <Col xs={12}>
                   <Label for={`description`}>Website description <b>*</b></Label>
                   <Input type="textarea" description={`description`} id={`description`}
                          placeholder={`Website description`}
                          value={websiteData.description}
                          onChange={setDescription}
                   />
                 </Col>
               </Row>

               {websiteData.uuid&&<Row xs={12} style={{marginTop: 16}}>
                 <Col xs={12}>
                   <h6>Assigned story </h6>
                   <div className='d-flex justify-content-center align-items-center align-content-center flex-column mt-4'>
                     <div style={{width:300}}>
                       <StoryItem story={storyData?storyData:{title:'No story assigned'}} thumb={thumb} disabled={!storyData||(!storyData&&storyData.uuid)} />
                     </div>
                   </div>
                 </Col>
               </Row>}

             </Col>

            <Col xs={6} style={{padding:16}} >
              <div className='previewBox d-flex justify-content-center align-items-center align-content-center flex-column h-100'>
                {storyData&&storyData.uuid&&<div className='d-flex w-100 h-100' key={storyData.uuid}>
                  <iframe title="Repox Stories" src={`http://${window.location.host}/${getTenantId()}/story-embed/${id}`} width="100%"
                          height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                  </iframe>
                </div>}

                {(!storyData||(storyData&&!storyData.uuid))&&<div className='d-flex'>
                    Assign a story to see a preview here
                </div>}

              </div>
            </Col>

           </Row>}
         </Container>}

        {activeTab===2&&<Activity />}

      </Container>
      {id&&<Button
        title={'Delete website'}
        color={'danger'}
        onClick={()=>setOpenDeleteDialog(true)}
        className={
          'btn-radius d-flex align-items-center justify-content-center shadow-lg'
        }
        style={{
          position: 'fixed',
          margin: 16,
          bottom: 90,
          right: 16,
          borderRadius: 50,
          width: 40,
          height: 40,
          zIndex: 1
        }}
      >
        <i className='bx bxs-trash font-size-16'></i>
      </Button>}
      <Button
        title={!changed?'No changes to save':'Save changes'}
        color={'primary'}
        onClick={saveWebsite}
        className={
          'btn-radius d-flex align-items-center justify-content-center shadow-md'
        }
        style={{
          position: 'fixed',
          margin: 16,
          bottom: 16,
          right: 16,
          borderRadius: 50,
          width: 60,
          height: 60,
          zIndex: 1
        }}
        disabled={!changed}
      >
        <i className='bx bxs-save font-size-24'></i>
      </Button>
    </BaseContent>
  );
}

export default WebsiteEditNew
