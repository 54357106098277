export const Operation = {
    get: "get",
    getAll: "getAll",
    update: "update",
    create: "create",
    delete: "delete",
    getAllPaging: "getAllPaging",
    poll: "poll",
    custom: "custom"
}
