import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,  
} from 'reactstrap';
import {Box} from '@material-ui/core'
class Breadcrumbs extends Component {
  state = {
    openPaths: false,
  };

  
  renderPaths = () => {
    if (!this.props.paths) return null;
    const finalPaths = this.props.paths
      .slice(0, this.props.paths.length - 1)
      .filter((x) => x.title);
    const parentPaths = finalPaths.map((path, index) => (
      <div className={'d-flex align-items-center'} key={index}>
        <Link to={path.link} disabled={!path.title}>
          <Button color={'light'} className="btn btn-path btn-sm d-flex mr-2">
            <h4 className="mb-0 font-size-18">
              {path.title
                .replace(/Ά|Α|ά/g, 'α')
                .replace(/Έ|ε|έ/g, 'ε')
                .replace(/Ό|Ο|ό/g, 'ο')
                .replace(/Ύ|ΰ|ύ/g, 'υ')
                .replace(/ΐ|ί|ΐ/g, 'ι')
                .replace(/ώ|Ώ|ω/g, 'ω')
                .replace(/Ή|Η|ή/g, 'η').substr(0,40)
              }
              {path.title.length >30 && '...'}</h4>
            {path.icon && <i className={`bx ${path.icon}`}/>}
          </Button>
        </Link>
        <h4 className="mb-0 font-size-18">
          <i className="bx bx-chevron-right mr-2"/>
        </h4>
      </div>
    ));
    const currentPath = this.props.paths[this.props.paths.length - 1]
    return (
      <div className={'d-flex align-items-center'}>
        {parentPaths}
        <Dropdown
          isOpen={this.state.openPaths}
          toggle={() => this.setState({openPaths: !this.state.openPaths})}
        >
          <DropdownToggle
            disabled={!this.props.paths[this.props.paths.length - 1].title}
            color={'light'}
            className="btn btn-path btn-sm d-flex"
            caret
          >
            <h4 className="mb-0 font-size-18">
              {currentPath.icon && <i className={`bx ${currentPath.icon}`}/>}
              {currentPath.title
                .replace(/Ά|Α|ά/g, 'α')
                .replace(/Έ|ε|έ/g, 'ε')
                .replace(/Ό|Ο|ό/g, 'ο')
                .replace(/Ύ|ΰ|ύ/g, 'υ')
                .replace(/ΐ|ί|ΐ/g, 'ι')
                .replace(/ώ|Ώ|ω/g, 'ω')
                .replace(/Ή|Η|ή/g, 'η').substr(0,40)
              }
              {currentPath.title.length >30 && '...'}
              <i className="mdi mdi-chevron-down"/>
            </h4>
          </DropdownToggle>
          <DropdownMenu>
            {this.props.entityActions &&
              this.props.entityActions.map((entity, key) => (
              <>                
                {entity.title ==="divider" ?  <DropdownItem divider key={key}/>
                : entity.title !== "Add Color" ? <DropdownItem
                  key={key}
                  disabled={!entity.action}
                  className={`d-flex align-items-center text-${
                    entity.color || 'default'
                  }`}
                  onClick={entity.action}
                >
                  <i className={`bx ${entity.icon} mr-2 font-size-18`}/>{' '}
                  {entity.title!=="divider" && entity.title 
                    .replace(/Ά|Α|ά/g, 'α')
                    .replace(/Έ|ε|έ/g, 'ε')
                    .replace(/Ό|Ο|ό/g, 'ο')
                    .replace(/Ύ|ΰ|ύ/g, 'υ')
                    .replace(/ΐ|ί|ΐ/g, 'ι')
                    .replace(/ώ|Ώ|ω/g, 'ω')
                    .replace(/Ή|Η|ή/g, 'η').substr(0,40)
                  }
                  {entity.title!=="divider" && entity.title.length > 30 && '...'}              
                    </DropdownItem> :
 <UncontrolledDropdown key={key}>
            <DropdownToggle href="#" className="'d-flex align-items-center" tag={'i'}>
              <DropdownItem className={'d-flex align-items-center'}>
                <i className="bx bx-palette mr-2 font-size-18"/>Add Color
              </DropdownItem>
            </DropdownToggle>
            <DropdownMenu style={{padding:20,borderRadius:5,marginLeft:30}} onDoubleClick={(e)=> {
              e.preventDefault()
              e.stopPropagation()
            }}>
            <div style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#D83A56',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#D83A56')
               this.setState({openPaths: !this.state.openPaths})
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#FF616D',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#FF616D')
              this.setState({openPaths: !this.state.openPaths}); 
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#FC5404',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#FC5404')
              this.setState({openPaths: !this.state.openPaths}); 
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#F98404',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#F98404')
              this.setState({openPaths: !this.state.openPaths}); 
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#F9B208',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#F9B208')
               this.setState({openPaths: !this.state.openPaths});
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#F7FD04',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#F7FD04')
              this.setState({openPaths: !this.state.openPaths}); 
              }}/>
            </div>

              <div style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center',marginTop:10}}>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#2D6187',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#2D6187')
               this.setState({openPaths: !this.state.openPaths});
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#4E89AE',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#4E89AE')
               this.setState({openPaths: !this.state.openPaths});
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#28ABB9',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#28ABB9')
               this.setState({openPaths: !this.state.openPaths});
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#01937C',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#01937C')
               this.setState({openPaths: !this.state.openPaths});
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#A8DDA8',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#A8DDA8')
              this.setState({openPaths: !this.state.openPaths}); 
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#B6C867',marginRight:4}} onClick={(e)=>{
                entity.handleAddColor('#B6C867')
               this.setState({openPaths: !this.state.openPaths});
              }}/>
              </div>
              <div style={{display:'flex',justifyContent:'flex-start',alignContent:'flex-start',alignItems:'flex-start',marginTop:10}}>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#FFFFFF',border:'1px solid #000',float:'left'}} onClick={(e)=>{
                entity.handleAddColor('#fff')
               this.setState({openPaths: !this.state.openPaths});
              }}/>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>                      
                    } 
              </>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  };

  renderBreadCrumbs = () => {
    return (
      this.props.breadcrumbs &&
      this.props.breadcrumbs.map((breadcrumb, index) => {
        const isLast = this.props.breadcrumbs.length === index + 1;
        return (
          <div className={'d-flex align-items-center'} key={index}>
            <Link to={breadcrumb.link}>
              <Button
                color={'light'}
                className="btn btn-path btn-sm d-flex mr-2"
              >
                <h4 className="mb-0 font-size-18">
                  {breadcrumb.title
                    .replace(/Ά|Α|ά/g, 'α')
                    .replace(/Έ|ε|έ/g, 'ε')
                    .replace(/Ό|Ο|ό/g, 'ο')
                    .replace(/Ύ|ΰ|ύ/g, 'υ')
                    .replace(/ΐ|ί|ΐ/g, 'ι')
                    .replace(/ώ|Ώ|ω/g, 'ω')
                    .replace(/Ή|Η|ή/g, 'η').substr(0,40)
                  }
                  {breadcrumb.title.length >30 && '...'}
                </h4>
              </Button>
            </Link>
            {!isLast && (
              <h4 className="mb-0 font-size-18">
                <i className="bx bx-chevron-right mr-2"/>
              </h4>
            )}
          </div>
        );
      })
    );
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <div className="page-title-box d-flex justify-content-between align-items-start">
              <div className="d-flex justify-content-start">
                {this.renderBreadCrumbs()}
                {this.renderPaths()}
              </div>
              <div className="page-title-right">
                <div className={'d-flex'}>
                  {this.props.renderActions && (
                    <div
                      className={'align-flex-end'}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {this.props.renderActions()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Breadcrumbs;
