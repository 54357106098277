import React from 'react'
import {Col, Container, Row} from 'reactstrap'
import {ReactComponent as NoData} from '../../assets/images/no_data.svg'

const NoFiles = ({overview,props}) =>{
  return  <Container style={{borderRadius:'350px',backgroundColor:'rgb(221,221,225)',padding:'80px',height:'350px',width:'350px'}} className='mb-4'>
    <Row>
      <Col xs={12} className='mt-n2'>
        <NoData width='180px' height='180px'/>
      </Col>
      {!overview ?
      <Col xs={12} className='text-center mt-2'>
        <p className='h5'> No Content added</p>
        <p className='text-muted'> Please create a new container</p>
      </Col>
        :
        <Col xs={12} className='text-center mt-2'>
          <p className='h5'> No data found</p>
          <p className='text-muted'> Please create a new container or datastream</p>
        </Col>
      }
    </Row>
  </Container>
}

export default NoFiles
