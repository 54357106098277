import React, {useEffect, useState} from "react";
import BaseDialog from "../../../components/Common/BaseDialog";
import {Col, Row, Table} from "reactstrap";

const SelectContacts = (props) => {

  const {open, handleClose, contacts, setSelectedContacts} = props

  const [checkedAll, setCheckedAll] = useState()
  const [selectedEmails, setSelectedEmails] = useState([])

  const toggleEmail = (email) => {
    const newEmails = [...selectedEmails]
    const emailIndex = contacts.indexOf(email)
    if (emailIndex!==-1) {
      newEmails.splice(emailIndex,1)
    } else {
      newEmails.push(email)
    }
    setSelectedEmails(newEmails)
  }

  useEffect(()=>{
    if (checkedAll) {
      setSelectedEmails([...contacts.map((c)=>c.email)].filter((v,i,s)=>s.indexOf(v)===i))
    } else {
      setSelectedEmails([])
    }
    // eslint-disable-next-line
  },[checkedAll])

  return <BaseDialog
    title={'Select contacts'}
    primaryAction={()=>{
      setSelectedContacts([
        ...contacts.filter((c)=>selectedEmails.includes(c.email))
      ])
      handleClose()
    }}
    primaryActionTitle={'Add selected contacts'}
    open={open}
    handleClose={handleClose}
    scrollable={true}
    size='lg'
  >
    {contacts&&<Row>
      <Col lg="12">
        <div className="">
          <div className="table-responsive">
            <Table className="project-list-table table-nowrap table-centered table-borderless table-zebra">
              <thead>
              <tr>
                <th scope="col" style={{ width: "100px" }}>
                  <input
                    type={"checkbox"}
                    checked={checkedAll}
                    onChange={(e)=>setCheckedAll(e.target.checked)}
                  />
                </th>
                <th scope="col">First name</th>
                <th scope="col">Last name</th>
                <th scope="col">Email</th>
                <th scope="col">Organization</th>
              </tr>
              </thead>
              <tbody>

              {contacts.map((item,i)=>{
                return <tr className='c-item-list' key={i} onClick={()=>toggleEmail(item.email)}>
                  <td>
                    <input
                      type={"checkbox"}
                      checked={selectedEmails.includes(item.email)}
                      onChange={()=>toggleEmail(item.email)}
                    />
                  </td>
                  <td>
                    {item.firstName?item.lastName:'-'}
                  </td>
                  <td>
                    {item.lastName?item.lastName:'-'}
                  </td>
                  <td>
                    {item.email?item.email:'-'}
                  </td>
                  <td>
                    {item.organization?item.organization:'-'}
                  </td>
                </tr>
              })}

              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    </Row>}
  </BaseDialog>
}

export default SelectContacts
