import React   from 'react';
import SurveyItem from "./components/listing/SurveyItem";
import {Container, Row} from "reactstrap";
import SurveyNewItem from "./components/listing/SurveyNewItem";
const SurveysList = ({result, isLoading}) => {

  if (isLoading) return <> </>

  return <>
    <Container style={{paddingTop: 30}}>
      <Row xs={12} md={3}>
        {result.map((survey,i)=><div className='c-item-wrapper'><SurveyItem survey={survey} /></div>)}
        <div className='c-item-wrapper'><SurveyNewItem /></div>
      </Row>
    </Container>
  </>

};

export default SurveysList;
