import {
    createDeleteMethod,
    createGetAllMethod,
    createGetMethod,
    createPostMethod,
    createPutMethod,
} from "../core/factory";
import {entityType} from "./module";

export const getContainerType = (params) => createGetMethod(entityType, params)
export const getAllContainerTypes = () => createGetAllMethod(entityType)
export const createContainerType = (payload) => createPostMethod(entityType, payload)
export const updateContainerType = (payload) => createPutMethod(entityType, payload)
export const deleteContainerType = (payload) => createDeleteMethod(entityType, payload)

