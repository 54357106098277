import React, {useState} from 'react';
import {withRouter} from "react-router-dom";
import {ButtonToggle, Col, Row} from "reactstrap";
import {HorizontalBar, Pie} from "react-chartjs-2";
import {truncateString} from "../../../../../utils";

const MultipleChoices = (props) => {
  const {field, fieldNumber} = props
  const {questionTitle,answerCount,answerSummary} = field

  const [graph, setGraph] = useState('bar')
  const optionsLabels = answerSummary.map((o)=>o.label)
  const optionsData = answerSummary.map((o)=>o.value)
  const data = {
    labels: optionsLabels,
    datasets: [
      {
        data: optionsData,
        backgroundColor: [
          "#556ee6",
          "#ebeff2",
          "#99e655",
          "#905eca",
        ],
        hoverBackgroundColor: [
          "#556ee6",
          "#ebeff2",
          "#99e655",
          "#905eca",
        ],
        hoverBorderColor: "#fff"
      }]
  }

  const optionsBar = {
    legend: {
      display: false,

    },
    scales: {
      xAxes: [
        {
          ticks: {
            stepSize: 1,
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function(value) {
              return truncateString(value,20)
            }
          },
        },
      ],
    },
  }

  const optionsPie = {
    legend: {
      display: true,
      labels: {
        generateLabels: (chart) => {
          const labels = chart.config.data.labels
          const colors = chart.config.data.datasets[0].backgroundColor
          return [
            ...labels.map((l,i)=>{
              return {
                fillStyle: colors[i],
                strokeStyle: colors[i],
                text: truncateString(l,20)
              }
            })
          ]
        }
      }
    }
  }

  return <Row style={{minHeight:440}}>
    <Col xs={12}>
      <div style={{fontSize: 14, marginTop: -8, marginBottom: 8, fontWeight: 'bold'}}>{fieldNumber+1}. {questionTitle}</div>
      <div style={{fontSize: 14, marginTop: -8, marginBottom: 8}}>{answerCount} responses</div>
    </Col>

    <Col xs={6}>

    </Col>

    <Col xs={6} style={{textAlign:'right',paddingRight: '18px'}}>
      <ButtonToggle size="sm" color='primary' outline={graph!=='bar'} onClick={()=>setGraph('bar')}>Bar</ButtonToggle>{'  '}
      <ButtonToggle size="sm" color='primary' outline={graph!=='pie'} onClick={()=>setGraph('pie')}>Pie</ButtonToggle>
    </Col>

    <Col xs={12}>
      {graph==='pie'&&<Pie width={50} height={20} data={data} options={optionsPie} />}
      {graph==='bar'&&<HorizontalBar width={50} height={20} data={data} options={optionsBar} />}
    </Col>
  </Row>
}

export default withRouter(MultipleChoices);
