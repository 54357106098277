import React, {useCallback,useState} from "react";
import {Col, Row} from "reactstrap";
import Container from "reactstrap/lib/Container";
import 'react-multi-email/style.css';
import BaseDialog from "../../../../../components/Common/BaseDialog";
import axios from "axios";
import {API_URL} from "../../../../../config";
import {getAxiosDefaultConfig} from "../../../../../utils";
import toastr from 'toastr'
import {useDropzone} from "react-dropzone";
import {handleError} from "../../../../../store/core/api";
import ImportedTermsList from './ImportedTermsList'
// import SelectContacts from "./SelectContacts";

export const ImportTerms = (props) => {

  const {open, handleClose,uuid,refresh} = props
  const [busy, setBusy] = useState(false)
  const [terms, setTerms] = useState([])
  const [importedTerms, setImportedTerms] = useState([])
  const [,setFile] = useState()

  const addTerms = useCallback(() => {
    setBusy(true)
    const payload = {data:terms}
    axios.post(`${API_URL}/vocabularies/${uuid}/import`,payload,getAxiosDefaultConfig())
      .then((response) => {
        setBusy(false);
        refresh()
        handleClose(true)
        console.log(response.data)
        if (response.data.valid)
        toastr.success(`Successfully imported terms`);
        else{
          toastr.error(response.data.errors.map((msg)=>msg+'\n'))

        }
      })
      .catch(error => {
        setBusy(false);
        toastr.error(error.message)
      })
    // eslint-disable-next-line
  },[terms])

  const onDrop = async (selectedFiles) => {
    const url = API_URL + `/vocabularies/${uuid}/load`
    const axiosConfig = getAxiosDefaultConfig();
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("uuid", uuid);
    setFile(selectedFiles[0])
    try {
      setBusy(true);
      const result = await axios
        .post(url, formData, axiosConfig)
        .then((res) => res.data)
        .catch(handleError);

      setImportedTerms(result.rows)
      setBusy(false);
    } catch (e) {
      setBusy(false);
      toastr.error(e.message);
    }
  }

  const importTerms = () => {
    addTerms()
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return <BaseDialog
    title={'Import terms from file'}
    primaryActionDisabled={terms.length<=0}
    primaryAction={importTerms}
    primaryActionTitle={`Import`}
    size='lg'
    isValid={true}
    busy={busy}
    open={open}
    handleClose={()=>{
      handleClose(false)
      setImportedTerms([])
      setTerms([])
    }}
    scrollable={true}
    extraClassName={"modal-full-height"}
  >

    {/*<SelectContacts open={uploadedContacts.length!==0} handleClose={()=>setUploadedContacts([])} contacts={uploadedContacts} setSelectedContacts={setContacts} />*/}

    <Container>

      <Row className='mt-2'>

        <Col xs={12}>
          {importedTerms.length===0&&<>
          <div {...getRootProps()} className={`d-flex flex-column justify-content-center align-items-center align-content-center drop-file-box ${isDragActive?'active-drop':''}`}>
            <input {...getInputProps()} accept={'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}/>


              <div className='d-flex mb-4'>
                <i className='bx bxs-file-import font-size-42'></i>
              </div>
              <div className='d-flex ml-2'>
                <span className='drop-link'>Choose an excel file</span>&nbsp; or drag it here.
              </div>
          </div>
            </>}

            {importedTerms.length>0&&<div className={`d-flex flex-column justify-content-center align-items-center align-content-center`}>
              <div className='d-flex ml-2'>
                {terms.length!==0&&<> Selected {terms.length} terms. </>}
              </div>
              <ImportedTermsList terms={importedTerms} setTerms={setTerms}/>
            </div>}


          {/*<Row className='justify-content-center align-items-center align-content-center'>*/}
          {/*  <Col xs='auto' className='font-size-24'>*/}
          {/*    {contacts.length!==0&&<> Selected {contacts.length} contacts. </>}*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Col>

      </Row>
    </Container>


  </BaseDialog>
}

export default ImportTerms
