import React, {useEffect, useState} from 'react'
import {Col, Row} from 'reactstrap'
import BaseDialog from '../../../../components/Common/BaseDialog'
import {connect} from 'react-redux'
import {slideShowElements} from '../../../../constants/enums'
import {makeid} from '../../../../utils'

const ElementBlock = (props) => {
  const {title, description, icon,isSelected,type,isDisabled,setSelected} = props

  return <Col xs={3} style={{
    border: isSelected?  '1px solid rgb(85, 110, 230)' : '1px solid #d2d2d2',
    boxShadow: '#d2d2d2 0px 1px 3px 0px',
    borderRadius: '10px',
    width: '160px',
    height: '160px',
    margin: '5px',
    cursor: isDisabled?'not-allowed':'pointer',
    padding:'10px',
    textAlign: 'center'
  }}
  onClick={()=>!isDisabled && setSelected(type)}
  >
    <Row>
      <Col lg={12} style={{fontSize:'30px'}}>
        <i className={`${icon}`} />
      </Col>
      <Col lg={12}>
        <div className='font-weight-bold mt-1 mb-1' style={{userSelect:'none'}}> {title}</div>
      </Col>
      <Col lg={12}>
        <div style={{fontSize: '9px',userSelect:'none'}} className='font-weight-light'> {description}</div>
      </Col>
    </Row>
  </Col>
}


const ElementDialog = (props) => {
  const {open, handleClose, handleChangeSlide,selectedSlide,slides} = props
  const [selectedElementType, setSelectedElementType] = useState(null);
  const [activeTab,] = useState(0)

  useEffect(()=>{
    setSelectedElementType(null)
  },[open])

  const initialElementData = (type) =>{
    switch (type) {
      case 'IMAGE' :
        return {uuid:null,from:'stories',lockAspectRatio:true,dimensionsAreSet:false}
      case 'TEXT' :
        return {text:'Enter your text',color:'#101010',fontSize:20,textAlign:'left',fontWeight: 300,fontFamily:'Roboto',textDecoration:'none',fontStyle:'normal'}
      case 'TITLE' :
        return {text:'Enter a title',color:'#101010',fontSize:20,textAlign:'left',fontWeight:300}
      case 'YOUTUBE':
        return {id:'M56RtC_cXxE',lockAspectRatio:true}
      default:
        return ''
    }
  }

  const handleElement = () =>{
    const newElement = {
      id:makeid(),
      type:selectedElementType,
      name:'',
      x:0,
      y:0,
      zIndex:1,
      height:50,
      width:200,
      rotation:0,
      animation:{
        editorElementDuration:1,
        enterAnimation:'fadeIn',
        leaveAnimation:'fadeOut',
        enterAnimationDelay:1000,
        leaveAnimationDelay:3000,
        durationEnter:1000,
        durationLeave:1000
      },
      data:initialElementData(selectedElementType)
    }
    // elements.push({...newElement})
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))

    // const elementsTmp = [...slidesTmp[slideIndex].elements]
    //
    // if (elementsTmp.length > 0)
    // {
    //   newElement.animation.leaveAnimationDelay = elementsTmp[elementsTmp.length-1].animation.leaveAnimationDelay + 1000
    // }

    const allElements = slidesTmp[slideIndex].elements
    allElements.push({...newElement})
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: allElements}
    handleChangeSlide(slidesTmp)
    handleClose()
  }

  return <BaseDialog
    scrollable={true}
    title={'Select your element'}
    open={open}
    handleClose={() => {
      handleClose()
    }}
    size={'lg'}
    primaryAction={handleElement}
    primaryActionTitle={'Add Element'}
    primaryActionDisabled={selectedElementType === null}
    tabChange={activeTab}
  >
    <Row className={'justify-content-center'}>
    {slideShowElements.map((element)=>{
      return <ElementBlock key={element.type} isDisabled={element.disabled} isSelected={selectedElementType===element.type} type={element.type} setSelected={setSelectedElementType} title={element.title} description={element.description} icon={element.icon}/>
    })}
    </Row>
  </BaseDialog>

}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {})(ElementDialog);
