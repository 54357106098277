import React from 'react'
import {Row, Col} from 'reactstrap'


const GroupCard = (props) => {
  const {group} = props
  const {name} = group

  return <Col lg={'auto'} className='ml-2'>
    <Row className='justify-content-start align-content-center'>
      <Col xs='auto'>
        <h6>{name}</h6>
      </Col>
    </Row>
  </Col>
}

export default GroupCard