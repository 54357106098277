import React, {useCallback, useMemo, useState} from "react";
import BaseContent from "../../../components/Common/BaseContent";
import {connect} from "react-redux";
import {Schema} from "../../../store/core/schema";
import {EntityType} from "../../../store/core/entityType";
import {useToggle} from "../../../helpers/hooks";
import ListSettings from "../../../components/Common/ListSettings";
import {updateSystemSetting} from "../../../store/systemSetting/actions";
import EntityDialog from "./EntityDialog";
import {compareProp} from "../../../utils";

const entityType = EntityType.SystemSetting;

const breadcrumbs = [
  { title: "Settings", link: "/settings" },
  { title: "General Settings", link: "#" },
];

const GeneralSettings = (props) => {
  const { context, updateSystemSetting } = props;
  const { result, isLoading, error } = context.SystemSetting.getAll;
  const [openModal, toggleModal] = useToggle(false);
  const [entity, setEntity] = useState(Schema[entityType]);

  const handleEntityClick = useCallback(
    (entity) => {
      setEntity(entity);
      toggleModal();
    },
    [toggleModal]
  );

  const list = useMemo(() => {
    return result
        .sort((a, b) => compareProp(a, b, "label"))
        .map((setting) => ({
      ...setting,
      handleClick: () => handleEntityClick(setting),
    }));
  }, [handleEntityClick, result]);


  const listColumns = [
    { name: "Label", prop: "label" },
    { name: "Value", prop: "settingValue" },
  ];

  return (
    <>
      <BaseContent
        title={"Users"}
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
      >
        <ListSettings contents={list} columns={listColumns} />
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={openModal}
          handleClose={toggleModal}
          updateEntity={updateSystemSetting}
          context={context.SystemSetting}
        />
      </BaseContent>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  updateSystemSetting,
})(GeneralSettings);
