import React, {useEffect, useRef} from 'react'
import {Rnd} from 'react-rnd'
import RenderElement from './components/RenderElement'
import GoogleFontLoader from 'react-google-font-loader'
import Loader from '../../Viewer/components/Loader'
import {googleFontsWeights} from '../../../../constants/enums'

const SliderProjection = (props) => {
  const {
    slides,
    currentSlide,
    scale,
    openElementEditAction,
    loading,
    selectedEditElement,
    setSelectedEditElement,
    handleChangeSlide,
    elements,
    storyScreen
  } = props

  useEffect(() => {
    setSelectedEditElement({})
    // eslint-disable-next-line
  }, [currentSlide.id])


  const updateElementsPosition = (id, d) => {
    const slideIndex = slides.findIndex((slide) => slide.id === currentSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = elements.findIndex((element) => element.id === id)
    const elementsTmp = [...elements]

    elementsTmp[elementIndex].x = d.x
    elementsTmp[elementIndex].y = d.y
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: [...elementsTmp]}
    handleChangeSlide(slidesTmp)
  }

  const resizeElement = (id, ref,position) => {

    const slideIndex = slides.findIndex((slide) => slide.id === currentSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = elements.findIndex((element) => element.id === id)
    const elementsTmp = [...elements]
    elementsTmp[elementIndex].width = parseInt(ref.style.width, 10)
    elementsTmp[elementIndex].height = parseInt(ref.style.height, 10)
    elementsTmp[elementIndex].x = position.x
    elementsTmp[elementIndex].y = position.y
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: [...elementsTmp]}
    handleChangeSlide(slidesTmp)
  }

  const initWidth=(elementType)=>{
    switch (elementType){
      case 'IMAGE':
        return 100
      case 'YOUTUBE':
        return 650
      default:
        return 40
    }
  }

  const initHeight=(elementType)=>{
    switch (elementType){
      case 'IMAGE':
        return 100
      case 'YOUTUBE':
        return 350
      default:
        return 80
    }
  }

  const divReference = useRef()
  if (loading && !scale)
    return <div className='d-flex align-items-center align-content-center justify-content-center'>
      <Loader/>
    </div>
  return <div>
    <GoogleFontLoader
      fonts={googleFontsWeights}
      subsets={['cyrillic-ext', 'greek']}
    />
    {elements?.map((element) => {
      return <div key={`${currentSlide.id}&${element.id}`} onClick={()=>setSelectedEditElement(element)} onDoubleClick={()=>{
        openElementEditAction(element.type==="IMAGE"?element:{})
      }}>
        <Rnd
          default={{
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
          }}
          disableDragging={currentSlide?.disabled}
          onDragStart={() => setSelectedEditElement(element)}
          onResizeStart={() => setSelectedEditElement(element)}
          onDragStop={(event, data) => updateElementsPosition(element.id, data)}
          onResizeStop={(e, direction, ref, delta, position) => {
            resizeElement(element.id, ref, position)
          }}
          size={{width: element.width, height: element.height}}
          minWidth={initWidth(element.type)}
          minHeight={initHeight(element.type)}
          lockAspectRatio={element?.data?.lockAspectRatio}
          scale={scale}
          // bounds=".projectionArea"
        >
          <RenderElement elementRef={divReference} selectedSlide={currentSlide} slides={slides}
                         style={{border: selectedEditElement?.id === element.id ? '3px dashed #556ee6' : '3px dashed #ececec'}}
                         element={element} handleChange={handleChangeSlide} storyScreen={storyScreen}/>
        </Rnd>
      </div>
    })}
  </div>
}

export default SliderProjection