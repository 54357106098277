import {combineReducers} from "redux";
// Front
import Layout from "./layout/reducer";
// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Container from "./container/reducer";
import ContainerType from "./containerType/reducer";
import DataStreamType from "./dataStreamType/reducer";
import Property from "./property/reducer";
import PropertyType from "./propertyType/reducer";
import User from "./user/reducer";
import Tag from "./tag/reducer";
import Group from "./group/reducer";
import DataStream from "./dataStream/reducer";
import Namespace from "./namespace/reducer";
import PropertyGroup from "./propertyGroup/reducer";
import Qualifier from './qualifier/reducer'
import SystemSetting from './systemSetting/reducer'
import Survey from './survey/reducer'
import Asset from './asset/reducer'
import Story from './story/reducer'
import SurveyResponse from './surveyResponse/reducer'
import Permissions from './permissions/reducer'
import MyPermissions from './myPermissions/reducer'
import MyProfile from './myProfile/reducer'
import Vocabulary from './vocabulary/reducer'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Container,
  ContainerType,
  DataStreamType,
  Property,
  PropertyType,
  User,
  Tag,
  Group,
  DataStream,
  Namespace,
  PropertyGroup,
  Qualifier,
  SystemSetting,
  Survey,
  Asset,
  Story,
  SurveyResponse,
  Permissions,
  MyPermissions,
  MyProfile,
  Vocabulary,
});

export default rootReducer;
