import React, {useEffect, useState} from "react";
import {FIELD_DEFAULTS} from "../consts";
import Email from "../fields/Email";

export const EmailTypeField = (props) => {
  const {setEmail, email, formDisabled} = props

  const [field,setField] = useState({...FIELD_DEFAULTS.EMAIL,required: true, value: email})

  useEffect(()=>{
    if (field) {
      if (field.errors&&field.errors.invalidFormat) {
        setEmail(null)
      } else {
        setEmail(field.value)
      }
    }
    // eslint-disable-next-line
  },[field])

  return <Email field={field} setField={setField} answerable={!email} standalone formDisabled={formDisabled} />
}
