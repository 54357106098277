import React, {Fragment, useEffect, useState} from "react";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useForm} from "../../../../helpers/hooks";
import EditDialog from "../../../../components/Common/EditDialog";
import {SortableList} from "../../../../components/Common/Sortable";
import {buildPropertiesPayload} from "../../../../utils";
import {ViewLayouts} from "../../../../constants/enums";
import Select from "react-select";
import {Schema} from '../../../../store/core/schema'

const EntityDialog = (props) => {
  const {
    context,
    entity,
    open,
    properties,
    entityType,
    propertyGroups,
    handleCreatePropertyGroup,
    handleEditPropertyGroup
  } = props;
  const [form, handleChange, updateInitial] = useForm(Schema[entityType]);
  const [state, setState] = useState([]);


  useEffect(() => {
    updateInitial(entity);
  }, [entity, updateInitial, open]);

  const handleCreate = () => {
    const payload = {
      dataStream: {
        code: form.code.toLowerCase(),
        name: form.name,
        layout: form.layout,
      },
      properties: buildPropertiesPayload(state),
    };
    props.createEntity(payload);
  };

  const handleUpdate = () => {
    const payload = {
      dataStream: {
        id: entity.code,
        name: form.name,
        layout: form.layout,
      },
      properties: buildPropertiesPayload(state),
    };
    props.updateEntity(payload);
  };

  const renderStepOne = () => (
    <Row>
      <Col sm={"6"}>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="Enter name"
            value={form.name}
            onChange={handleChange("name")}
          />
        </FormGroup>
      </Col>
      <Col sm={"6"}>
        <FormGroup>
          <Label>Code</Label>
          <Input
            disabled={form.id}
            type="text"
            className="form-control"
            placeholder="Enter code"
            value={form.code}
            onChange={handleChange("code")}
          />
        </FormGroup>
      </Col>      
    </Row>
  );

  const renderStepTwo = () => (
    <Row>
      <Col>
        <SortableList
          state={state}
          setState={setState}
          properties={properties}
          propertyGroups={propertyGroups}
          form={form}
          handleEditPropertyGroup={handleEditPropertyGroup}
          handleCreatePropertyGroup={handleCreatePropertyGroup}
        />
      </Col>
    </Row>
  );

  const activeLayout = ViewLayouts.find((x) => x.value === form.layout);

  const renderStepThree = () => (
      <Fragment>
        <FormGroup>
          <Label>Select layout</Label>
          <Select
              value={activeLayout}
              onChange={handleChange("layout")}
              name="containers"
              options={ViewLayouts}
              classNamePrefix="select"
          />
        </FormGroup>
        <Row>
          <Col sm={"6"}>
            <img
                className={"shadow-md"}
                style={{ width: 400 }}
                src={activeLayout && activeLayout.src}
                alt="Layout"
            />
          </Col>
          <Col className={"p-4"}>
            <h5 className={"font-size-14"}>Description</h5>
            <p>{activeLayout && activeLayout.description}</p>
          </Col>
        </Row>
      </Fragment>
  );

  const contents = [renderStepOne(), renderStepTwo(), renderStepThree()];

  return (
    <EditDialog
      {...props}
      form={form}
      context={context}
      createEntity={handleCreate}
      updateEntity={handleUpdate}
      tabs={[
        { title: "General" },
        { title: "Properties" },
        { title: "Layout" },
      ]}
      contents={contents}
      size={"lg"}
      extraClassName={"modal-full-height"}
    />
  );
};

export default EntityDialog;
