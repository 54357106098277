import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';

const PropertyDialog = (props) => {
  const {form,handleChange} = props;


  return (
      <form>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter description"
            value={form?.name}
            onChange={handleChange('name')}
          />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input
            type="textarea"
            className="form-control"
            // placeholder="Enter label"
            value={form?.description}
            onChange={handleChange('description')}
          />
        </FormGroup>
        <FormGroup>
          <Label>Vocabulary URI</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter label"
            value={form?.vocUri}
            onChange={handleChange('vocUri')}
          />
        </FormGroup>
      </form>
  );
};

export default PropertyDialog;
