import React, {useEffect, useState} from 'react'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import UserItem from './UserItem'
import {Table} from 'reactstrap'

const UsersList = (props) => {
  const {allUsers, form, handleChange} = props
  const [users, setUsers] = useState(null)
  useEffect(() => {
    if (form.iamPermissions)
      setUsers(form.users.map(p => p.uuid))
  }, [form])

  const addRemoveUser = (uuid) => {
    if (users.find(p => p === uuid)) {
      const removeUsers = form.users.filter((p) => p.uuid !== uuid)
      handleChange('users')(removeUsers)
    } else {
      const addUsers = [...form.users]
      addUsers.push(allUsers.find(p => p.uuid === uuid))
      handleChange('users')(addUsers)
    }
  }

  return <>
    <Row>
      <Col lg="12">
          <div className="table-responsive">
            <Table className="project-list-table table-nowrap table-centered table-borderless table-zebra">
              <thead>
              <tr>
                <th scope="col" style={{width: '100px'}}>
                  {/*<input*/}
                  {/*  type={'checkbox'}*/}
                  {/*  checked={isChecked('ALL')}*/}
                  {/*  onChange={() => handleCheck('ALL')}*/}
                  {/*/>*/}
                </th>
                <th scope="col">Firstname</th>
                <th scope="col">Lastname</th>
                <th scope="col">Username</th>
                <th scope="col">e-mail</th>
              </tr>
              </thead>
              <tbody>
              {users && allUsers?.sort((a,b)=>{
                const textA = a.firstname.toUpperCase();
                const textB = b.firstname.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              })?.map((u) => {
                return <UserItem key={u.uuid} checked={users.includes(u.uuid)} user={u}
                                 handleChange={() => addRemoveUser(u.uuid)}/>
              })}
              </tbody>
            </Table>
          </div>
      </Col>
    </Row>
  </>
}


export default UsersList