import React from 'react';
import {withRouter} from "react-router-dom";
import {FormGroup} from "reactstrap";
import ListOfText from "../common/ListOfText";

const Time = (props) => {
  const {field,fieldNumber} = props

  let timeSelected = new Date(field.value+'Z')

  return <>
    <FormGroup row>
      <ListOfText field={{...field,value:timeSelected.toLocaleDateString()}} fieldNumber={fieldNumber} />
    </FormGroup>
  </>
}

export default withRouter(Time);
