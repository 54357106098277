import React from "react";
import {Media} from "reactstrap";
import moment from "moment";
import BusyIndicator from "./BusyIndicator";
import {getIconForDatastream} from "../../utils";
import EntityTags from "./EntityTags";
import {ObjectType} from "../../constants/enums";
import ContainerPublishControl from "../../pages/Containers/container-publish-control";
import {TextRecord} from "./Record";

export default function EntityDetails({
  entity,
  busy,
  isFolder,
  objectType,
  entityTags,
}) {
  // TODO: Fix this
  if (!entity || !entity.createdBy) return <div />;

  if (busy) return <BusyIndicator busy={busy} />;
  const typeName = entity.type ? entity.type.name : "";
  const icon = isFolder ? "bx-folder" : getIconForDatastream(typeName);

  return (
    <>
      <Media>
        <div className="avatar-lg mx-auto">
          <span className="avatar-title rounded-circle bg-light text-danger">
            <i className={`bx ${icon} text-primary`} style={{ fontSize: 48 }} />
          </span>
        </div>
      </Media>
      <div className={"border border-light my-4"} />
      <TextRecord label={"Type"} value={entity.type.name} showBadge/>
      <TextRecord label={"Owner"} value={entity.createdBy.lastname} />
      <TextRecord
        label={"Created"}
        value={moment(entity.createdAt).format("Do MMMM YYYY")}
      />
      <TextRecord
        label={"Modified"}
        value={moment(entity.modifiedAt).format("Do MMMM YYYY")}
      />
      <TextRecord label={"Modified by"} value={entity.modifiedBy.lastname} />
      {objectType === ObjectType.container && (
        <ContainerPublishControl container={entity} />
      )}
      <EntityTags
        entityId={entity.uuid}
        objectType={objectType}
        entityTags={entityTags}
      />
    </>
  );
}
