import React, {useState, useEffect} from "react";
import {Card, CardBody, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table, Tooltip} from "reactstrap";
import {Link} from "react-router-dom";
import {ApiEndpoint} from "../../store/core/endpoint";
import {bytesToSize, downloadFile, downloadThumb, getIconForDatastream} from "../../utils";
import {useToggle} from "../../helpers/hooks";
import { EntityType } from "../../store/core/entityType";
import {withStyles} from "@material-ui/core/styles";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const styles = (theme) => ({
  stageOuter: {
    overflow: "unset",
    overflowX: "clip",
  },
});


const DataStreamCarousel = withStyles(styles)(({classes,files, handleFileClick, handleFileEdit, handleDeleteFile, handleMoveFile, handleFileRename, handleLinkFile, type = "list"}) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  // const [thumbnail, setThumbnail] = useState([])
  const [pointerPosition, setPointerPosition] = useState({x: 0, y: 0});

  const handleDownloadFile = (file) => (e) => {
    e.stopPropagation();
    setDownloadLoading({
      ...downloadLoading,
      [file.uuid]: true,
    });
    const downloadUrl = ApiEndpoint[EntityType.DataStream] + `/${file.uuid}/download`;
    downloadFile(downloadUrl, file, downloadLoading, setDownloadLoading);
  };

  // useEffect(() => {
  //   if (files && files.length > 0) {
  //     files.forEach((file) => {
  //       const downloadUrl = ApiEndpoint[EntityType.DataStream] + `/${file.uuid}/download`;
  //       downloadThumb(downloadUrl,file,thumbnail,setThumbnail)
  //     })
  //   }
  //   // eslint-disable-next-line
  // }, [files])

  if (files.length === 0) return null;

  if (type === "list")
    return (
      <Col lg="12">
        <Card onContextMenu={(e) => e.preventDefault()}>
          <CardBody>
            <CardTitle className="mb-4">Files</CardTitle>
            <div className="table-responsive">
              <Table className="table table-nowrap table-centered table-hover mb-0">
                <tbody>
                  {files.map((file, index) => (
                    <ListItem
                      key={index}
                      file={file}
                      handleFileClick={handleFileClick}
                      handleDownloadFile={handleDownloadFile}
                      handleFileRename={handleFileRename}
                      downloadLoading={downloadLoading}
                      // thumbnail={thumbnail[file.uuid]}
                      handleMoveFile={handleMoveFile}
                      handleLinkFile={handleLinkFile}
                      handleFileEdit={handleFileEdit}
                      handleDeleteFile={handleDeleteFile}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  else if (type === "grid") {return (
    <OwlCarousel className="owl-theme" stageOuterClass={classes.stageOuter} margin={5} loop nav>
      {files &&
        files.map((file, index) => {
          return (
            <div className="item" key={index}>
              <GridItem
                //key={index}
                file={file}
                handleFileClick={handleFileClick}
                handleDownloadFile={handleDownloadFile}
                handleFileRename={handleFileRename}
                downloadLoading={downloadLoading}
                pointerPosition={pointerPosition}
                setPointerPosition={setPointerPosition}
                // thumbnail={thumbnail[file.uuid]}
                handleMoveFile={handleMoveFile}
                handleLinkFile={handleLinkFile}
                handleFileEdit={handleFileEdit}
                handleDeleteFile={handleDeleteFile}
              />
            </div>
          );
        })}
    </OwlCarousel>
  );
  } else {
    return null;
  }
});

export default DataStreamCarousel;

function ListItem({file, handleFileClick, handleDownloadFile, handleFileRename, handleFileEdit, handleDeleteFile, handleMoveFile, handleLinkFile, downloadLoading}) {
  const [open, toggle] = useToggle(false);
  return (
    <tr key={"_file_" + file.uuid}>
      <td style={{width: "45px"}}>
        {!file.img ? (
          <>
            <div className="avatar-sm mx-auto mb-2">
              <span style={{cursor: "pointer"}} className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24" onClick={() => handleFileClick(file)}>
                <DataStreamThumb key={file.uuid} file={file} thumbnail={file.thumbnail} />
              </span>
            </div>
          </>
        ) : (
          <div className="mb-2">
            <img onClick={() => handleFileClick(file)} className="rounded-circle avatar-sm" src={file.img} alt="" />
          </div>
        )}
      </td>
      <td>
        <h5 className="font-size-14 mb-1">
          <Link to="#" className="text-dark">
            {file.label} <i className={`ml-2 bx ${getIconForDatastream(file.mimeType)}`} />
          </Link>
        </h5>
        <small>Type : {file.type.name}</small>
        <small className={"ml-2"}>Size: {bytesToSize(file.filesize)}</small>
        <small className={"ml-2"}>Creator : {file.createdBy.lastname}</small>
      </td>
      <td>
        <div className={"d-flex"} style={{position: "absolute", marginTop: "-20px"}}>
          <Dropdown isOpen={open} toggle={toggle} direction="left" className="btn-group">
            <DropdownToggle className="btn waves-light waves-effect dropdown-toggle" tag="div">
              <i className="fa fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleFileClick(file)}>
                <i className={"bx bx-show"} /> View file
              </DropdownItem>
              <DropdownItem onClick={() => handleFileEdit(file)}>
                <i className={"bx bx-edit"} /> Edit
              </DropdownItem>
              <DropdownItem onClick={() => handleFileRename(file)}>
                <i className="bx bx-edit-alt" /> Rename
              </DropdownItem>
              <DropdownItem onClick={handleDownloadFile(file)}>
                <i className={"bx bx-download"} /> Download
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => handleMoveFile(file)}>
                <i className={"bx bx-transfer"} /> Move
              </DropdownItem>
              <DropdownItem onClick={() => handleLinkFile(file)}>
                <i className={"bx bx-link"} /> Link
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem className={"text-danger"} onClick={() => handleDeleteFile(file)}>
                <i className={"bx bx-trash"} /> Remove
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
}

function GridItem({file, handleFileClick, handleDownloadFile, handleFileEdit, handleFileRename, handleDeleteFile, handleMoveFile, handleLinkFile}) {
  const [open, toggle] = useToggle(false);
  return (
    <React.Fragment>
      <Col xl="12" sm="12" className="p-1">
        <Card className="text-center">
          <CardBody>
            {!file.img ? (
              <>
                <div className="avatar-sm mx-auto mb-2">
                  <span style={{cursor: "pointer"}} className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24" onClick={() => handleFileClick(file)}>
                    {/*{thumbnail && thumbnail.length > 0 ?  <img src={thumbnail} className="avatar-title" alt={'thumbnail'} /> : */}
                    {/*  <i className={`bx ${getIconForDatastream(file.type.name)}`}/>}*/}
                    <DataStreamThumb key={file.uuid} file={file} thumbnail={file.thumbnail} />
                  </span>
                </div>
                <i className={`bx ${getIconForDatastream(file.mimeType)}`} />
              </>
            ) : (
              <div className="mb-2">
                <img onClick={() => handleFileClick(file)} className="rounded-circle avatar-sm" src={file.img} alt="" />
              </div>
            )}
            <div className={"d-flex"} style={{position: "absolute", right: 0, top: 0}}>
              <Dropdown isOpen={open} toggle={toggle} direction="left" className="btn-group">
                <DropdownToggle className="btn waves-light waves-effect dropdown-toggle" tag="div">
                  <i className="fa fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleFileClick(file)}>
                    <i className={"bx bx-show"} /> View file
                  </DropdownItem>
                  <DropdownItem onClick={() => handleFileEdit(file)}>
                    <i className={"bx bx-edit"} /> Edit
                  </DropdownItem>
                  <DropdownItem onClick={() => handleFileRename(file)}>
                    <i className="bx bx-edit-alt" /> Rename
                  </DropdownItem>
                  <DropdownItem onClick={handleDownloadFile(file)}>
                    <i className={"bx bx-download"} /> Download
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => handleMoveFile(file)}>
                    <i className={"bx bx-transfer"} /> Move
                  </DropdownItem>
                  <DropdownItem onClick={() => handleLinkFile(file)}>
                    <i className={"bx bx-link"} /> Link
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className={"text-danger"} onClick={() => handleDeleteFile(file)}>
                    <i className={"bx bx-trash"} /> Remove
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <h5 className="font-size-15 mb-0 text-dark" onClick={() => handleFileClick(file)}>
              {file.label}
            </h5>
            <p className="text-muted mb-0">{bytesToSize(file.filesize)}</p>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

const DataStreamThumb = ({file, thumbnail}) => {
  const [imageThumb, setImageThumb] = useState("");
  const [openTooltip, handleToggle] = useToggle();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (file.thumbnail.length > 0) downloadThumb(file, thumbnail, setImageThumb, isLoading, setIsLoading);
    // eslint-disable-next-line
  }, [file.uuid]);

  if (isLoading)
    return (
      <>
        <i className={`bx ${getIconForDatastream(file.type.name)}`} />
      </>
    );

  return (
    <>
      {imageThumb == null ? (
        <i className={`bx ${getIconForDatastream(file.type.name)}`} />
      ) : (
        <>
          <Tooltip placement="right" target={`fileId-${file.uuid}`} toggle={handleToggle} isOpen={openTooltip} style={{maxWidth: "400px"}}>
            <img src={imageThumb} alt={`${thumbnail} thumb`} style={{maxWidth: "300px"}} />
          </Tooltip>
          <img id={`fileId-${file.uuid}`} src={imageThumb} alt={`${thumbnail} thumb`} className="avatar-title" />
        </>
      )}
    </>
  );
};
