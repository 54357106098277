import React, {useEffect, useState} from 'react';
import {FormGroup, Input, Label} from "reactstrap";
import {useForm} from "../../../helpers/hooks";
import EditDialog from "../../../components/Common/EditDialog";
import GroupCard from './components/GroupCard'
const PropertyDialog = (props) => {
  const { entity, open ,groups} = props;

  const [form, handleChange, updateInitial] = useForm({});
  const [locked, setLocked] = useState(false)
  const [groupsArr, setGroupsArr] = useState([])

  const handleSetLocked = (e) =>  {
    setLocked(e.target.checked);
    form.locked = e.target.checked;
    handleChange("locked");
  }
  
  useEffect(() => {
    updateInitial(entity);
    setLocked(entity.locked);
    if(open)
    {
      setGroupsArr(groups)
    }
    // eslint-disable-next-line
  }, [entity, updateInitial, open]);

  return (
		<EditDialog {...props} size={"md"} form={form} scrollable>
			<form>
				<FormGroup>
					<Label>First name</Label>
					<Input
						type="text"
						className="form-control"
						// placeholder="First name"
						value={form.firstname}
						onChange={handleChange("firstname")}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Last name</Label>
					<Input
						type="text"
						className="form-control"
						// placeholder="Enter description"
						value={form.lastname}
						onChange={handleChange("lastname")}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Username</Label>
					<Input
						type="text"
						className="form-control"
						// placeholder="Enter enums with spase seperated"
						value={form.username}
						onChange={handleChange("username")}
					/>
				</FormGroup>
				{!form.id && (
					<FormGroup>
						<Label>Password</Label>
						<Input
							type="text"
							className="form-control"
							// placeholder="Enter enums with spase seperated"
							value={form.password}
							onChange={handleChange("password")}
						/>
					</FormGroup>
				)}
				<FormGroup>
					<Label>Email</Label>
					<Input
						type="text"
						className="form-control"
						// placeholder="Enter enums with spase seperated"
						value={form.email}
						onChange={handleChange("email")}
					/>
				</FormGroup>
				{form.uuid && (
					<FormGroup>
						<Label>Groups</Label>
						{groupsArr?.length <= 0 ? (
							<div>Assigned in no groups</div>
						) : (
							groupsArr?.map((g) => {
								return <GroupCard group={g} key={g.id} />;
							})
						)}
					</FormGroup>
				)}
				<FormGroup>
					<div
						className="custom-control custom-switch mb-2 mr-3 z-depth-0"
						dir="ltr"
					>
						<input
							type="checkbox"
							className="custom-control-input"
							id={"locked" + form.uuid}
							checked={locked}
							value={locked}							
							onChange={(e) => handleSetLocked(e)}
						/>
						<label
							className="custom-control-label"
							htmlFor={"locked" + form.uuid}
						>
							User is locked
						</label>
					</div>
				</FormGroup>
			</form>
		</EditDialog>
	);
};

export default PropertyDialog;
