import {getCommonState, handleCommonState} from "../core/factory";
import {entityType} from "./module";

const initialState = getCommonState();


const Qualifier = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0]

  if (actionEntity !== entityType) return state
  const actionType = action.type.split(".")[1]

  return  handleCommonState(state, actionType, action, entityType);
};

export default Qualifier;
