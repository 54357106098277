import React from 'react';
import {withRouter} from "react-router-dom";
import {Button, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useEffect, useState} from "react";
import {reorder} from "../../../../utils";


const MultipleChoiceGrid = (props) => {
  const {field, setField, selected, answerable, formDisabled, fieldNumber} = props
  const {title,description, index, details, required} = field
  const {columns,rows} = details
  const [newOptionRow, setNewOptionRow] = useState('')
  const [newOptionColumn, setNewOptionColumn] = useState('')

  const [addedNew, setAddedNew] = useState(false)

  // Handle re-ordering on dnd
  const onDragEnd = (result, type) => {

    // dropped outside the list
    if (!result.destination) {
      return
    }

    setField({
      ...field,
      details: {
        ...details,
        [type]: reorder(
          details[type],
          result.source.index,
          result.destination.index
        )
      }
    })
  }

  // Helper to set new options
  const setOptionsRow = (newOptions) => {
    setField({
      ...field,
      details: {
        ...details,
        rows: [...newOptions]
      }
    })
  }

  // Helper to set new options
  const setOptionsColumn = (newOptions) => {
    setField({
      ...field,
      details: {
        ...details,
        columns: [...newOptions]
      }
    })
  }

  // Handle change
  const changeOptionRow = (e,index) => {
    const value = e.target.value
    let newOptions = [...rows]
    newOptions[index] = {
      id: newOptions[index].id,
      value
    }
    setOptionsRow(newOptions)
  }

  // Handle change
  const changeOptionColumn = (e,index) => {
    const value = e.target.value
    let newOptions = [...columns]
    newOptions[index] = {
      id: newOptions[index].id,
      value
    }
    setOptionsColumn(newOptions)
  }

  // Handle deletion
  const deleteOptionRow = (index) => {
    let newOptions = [...rows]
    newOptions.splice(index,1)
    setOptionsRow(newOptions)
  }

  // Handle deletion
  const deleteOptionColumn = (index) => {
    let newOptions = [...columns]
    newOptions.splice(index,1)
    setOptionsColumn(newOptions)
  }

  // Handle addition
  const changeNewOption = (e,type) => {
    const value = e.target.value
    if (type==='column') {
      setNewOptionColumn(value)
    } else {
      setNewOptionRow(value)
    }
  }

  // Create new entry on change
  useEffect(()=>{
    if (newOptionRow&&newOptionRow!=='') {
      let newOptionRows = [...rows]
      newOptionRows.push({
        id: `option-row-${new Date().valueOf()}`,
        value: newOptionRow
      })
      setOptionsRow(newOptionRows)
      setNewOptionRow('')
      setAddedNew(true)
    }
    // eslint-disable-next-line
  },[newOptionRow])

  // Create new entry on change
  useEffect(()=>{
    if (newOptionColumn&&newOptionColumn!=='') {
      let newOptionColumns = [...columns]
      newOptionColumns.push({
        id: `option-column-${new Date().valueOf()}`,
        value: newOptionColumn
      })
      setOptionsColumn(newOptionColumns)
      setNewOptionColumn('')
      setAddedNew(true)
    }
    // eslint-disable-next-line
  },[newOptionColumn])

  // Blur when previewing/editing
  useEffect(()=>{
    setAddedNew(false)
  },[selected,answerable])

  const setValue = (row,column) => {
    const newValues = [...field.value]
    const rowObjIndex = newValues.findIndex((r)=>r.id===row.id)

    if (rowObjIndex !== -1 ) {
      if ( newValues[rowObjIndex].value === column.id ) {
        newValues.splice(rowObjIndex,1)
      } else {
        newValues[rowObjIndex].value = [column.id]
      }
    } else {
      newValues.push({
        id: row.id,
        value: [column.id]
      })
    }

    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable&&required&&newValues.length===0
      },
      value: newValues
    })
  }

  const checkErrors = (e) => {
    if (!answerable) return
    if (required) {
      if (field.value.length===0) {
        setField({
          ...field,
          errors: {
            required: true
          }
        })
      }
    }
  }

  const requiredError = field.errors&&field.errors.required
  const hasError = requiredError

  // The view / preview part
  //////////////////////////
  if ((!selected) || answerable) return <>
    <legend className="col-form-label" style={{fontWeight: 500}}>{fieldNumber+1}. {title} {required&&<b>*</b>}</legend>
    <div style={{fontSize: 12, marginTop: -8, marginBottom: 8, opacity: 0.8}}>{description}</div>

    <Row>
      <Col lg="12">
        <div className="">
          <div className="table-responsive">
            <Table className="table-centered table-borderless multiple-choice-grid">
              <thead>
              <tr>
                <th width={'60%'}> &nbsp; </th>
                {details.columns.map((column,i)=><th scope="col" key={i} className='text-center'>{column.value}</th>)}
              </tr>
              </thead>
              <tbody>
              {details.rows.map((row,x)=>{
                  return <tr key={x}>
                    <td>{row.value}</td>
                    {details.columns.map((column,y)=>{

                      const checked = field.value&&field.value.find((r)=>r.id===row.id&&r.value[0]===column.id)

                      return <td key={y} className='text-center'>
                        <FormGroup check inline>
                          <Label check>
                            <Input type="radio" name={`radio-${index}-${x}-${y}`} id={`radio-${index}-${x}-${y}`}
                                   disabled={formDisabled}
                                   checked={checked}
                                   onChange={()=>setValue(row,column)}
                                   onBlur={checkErrors}
                                   invalid={hasError}
                            />
                          </Label>
                        </FormGroup>

                      </td>
                    })}

                  </tr>
              })}
              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    </Row>
  </>

  // The editor part
  ///////////////////
  return <FormGroup row>
    <Col xs={6}>
      <h6>Rows</h6>
      <DragDropContext onDragEnd={(r)=>onDragEnd(r,'rows')}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {details.rows.map((option, optionIndex) => {
                const shouldFocus = addedNew&&optionIndex===details.rows.length-1

                return <Draggable key={`radio-${index}-option-${optionIndex}`} draggableId={`radio-${index}-option-${optionIndex}`} index={optionIndex}>
                  {(provided) => (<div key={optionIndex} {...provided.draggableProps} ref={provided.innerRef}>
                      <div style={{position: 'relative', marginBottom: 16}} >

                        <div {...provided.dragHandleProps} style={{color: '#5c5c5c', fontSize: 18, marginBottom: 2, position: 'absolute', left: -18, top: -8}}>
                          <div style={{marginBottom: -22}}><b>..</b></div>
                          <div style={{marginBottom: -22}}><b>..</b></div>
                          <div><b>..</b></div>
                        </div>

                        <div style={{paddingRight: 70}}>

                          <Input type="text" name={`radio-${index}-option-${optionIndex}-label`} id={`radio-${index}-option-${optionIndex}-label`} placeholder={'Label'}
                                 value={option.value} onChange={(e)=>changeOptionRow(e,optionIndex)}
                                 autoFocus={shouldFocus} disabled={formDisabled}
                          />
                        </div>

                        <div style={{position: 'absolute', right: 0, top: 0}}>
                          <Button outline color="danger" onClick={()=>deleteOptionRow(optionIndex)}>Delete</Button>
                        </div>

                      </div>

                    </div>
                  )}
                </Draggable>
              })}
              {provided.placeholder}
            </div>)}
        </Droppable>
      </DragDropContext>

      <div style={{position: 'relative', marginBottom: 16}} >
        <div>
          <Input type="text" name={`new-option`} id={`new-option`} placeholder={'Add new option'} value={newOptionRow} onChange={(e)=>changeNewOption(e,'row')} disabled={formDisabled} />
        </div>
      </div>
    </Col>

    <Col xs={6}>
      <h6>Columns</h6>
      <DragDropContext onDragEnd={(r)=>onDragEnd(r,'columns')}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {details.columns.map((option, optionIndex) => {
                const shouldFocus = addedNew&&optionIndex===details.columns.length-1

                return <Draggable key={`radio-${index}-option-${optionIndex}`} draggableId={`radio-${index}-option-${optionIndex}`} index={optionIndex}>
                  {(provided) => (<div key={optionIndex} {...provided.draggableProps} ref={provided.innerRef}>
                      <div style={{position: 'relative', marginBottom: 16}} >

                        <div {...provided.dragHandleProps} style={{color: '#5c5c5c', fontSize: 18, marginBottom: 2, position: 'absolute', left: -18, top: -8}}>
                          <div style={{marginBottom: -22}}><b>..</b></div>
                          <div style={{marginBottom: -22}}><b>..</b></div>
                          <div><b>..</b></div>
                        </div>

                        <div style={{paddingRight: 70}}>
                          <Input type="text" name={`radio-${index}-option-${optionIndex}-label`} id={`radio-${index}-option-${optionIndex}-label`} placeholder={'Label'}
                                 value={option.value} onChange={(e)=>changeOptionColumn(e,optionIndex)}
                                 autoFocus={shouldFocus} disabled={formDisabled}
                          />
                        </div>

                        <div style={{position: 'absolute', right: 0, top: 0}}>
                          <Button outline color="danger" onClick={()=>deleteOptionColumn(optionIndex)}>Delete</Button>
                        </div>

                      </div>

                    </div>
                  )}
                </Draggable>
              })}
              {provided.placeholder}
            </div>)}
        </Droppable>
      </DragDropContext>

      <div style={{position: 'relative', marginBottom: 16}} >
        <div>
          <Input type="text" name={`new-option`} id={`new-option`} placeholder={'Add new option'} value={newOptionColumn} onChange={(e)=>changeNewOption(e,'column')} disabled={formDisabled} />
        </div>
      </div>
    </Col>

  </FormGroup>
}

export default withRouter(MultipleChoiceGrid);
