import React, {useEffect, useState} from "react";
import {FIELD_DEFAULTS} from "../consts";
import ShortAnswer from "../fields/ShortAnswer";

export const FirstnameTypeField = (props) => {
  const {setFirstname, firstName, formDisabled} = props

  const [field,setField] = useState({
    ...FIELD_DEFAULTS.SHORT_ANSWER,
    required: true,
    title: 'First name',
    value: firstName
  })

  useEffect(()=>{
    if (field) {
      if (field.errors&&field.errors.required) {
        setFirstname(null)
      } else {
        setFirstname(field.value)
      }
    }
    // eslint-disable-next-line
  },[field])

  return <ShortAnswer field={field} setField={setField} answerable={!firstName} standalone formDisabled={formDisabled} />
}
