import {all, call, fork, put, takeEvery} from "redux-saga/effects";
// Login Redux States
import {LOGIN_USER, LOGOUT_USER} from "./actionTypes";
import {apiError, loginSuccess} from "./actions";
//Include Both Helper File with needed methods
import {postLogin} from "./api";


function* loginUser({payload: {user, history}}) {
    try {
        const response = yield call(postLogin, user);
        localStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginSuccess(response));
        history.push("/containers");
    } catch (error) {
        yield put(apiError(error.message));
    }
}

function* logoutUser({payload: {history}}) {
    try {
        localStorage.removeItem("authUser");
        history.push("/login");
    } catch (error) {
        yield put(apiError(error.message));
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
