import React from "react";

const ListToggle = ({ toggle, isList }) => {
  return (
    <button
      type="button"
      onClick={toggle}
      className="btn font-size-18"
      data-toggle="fullscreen"
    >
      {!isList && <i className="bx bx-list-ul" />}
      {isList && <i className="bx bx-grid" />}
    </button>
  );
};

export default ListToggle;
