import {EntityType} from "../core/entityType";
import {Operation} from "../core/operation";

export const entityType = EntityType.ContainerType;
export const entityOperations = [
  Operation.getAll,
  Operation.get,
  // Operation.create, Custom create function added
  // Operation.update, Custom update function added
  Operation.delete,
];
