import React, {useEffect, useRef, useState} from 'react';
import BaseDialog from '../../components/Common/BaseDialog'
import {Col, Container, FormGroup, Input, Label, Row} from 'reactstrap'
import MoveLinkFireInput from '../../components/Common/MoveLinkFireInput'
import {ApiEndpoint} from '../../store/core/endpoint'
import {EntityType} from '../../store/core/entityType'
import axios from 'axios'
import {getAxiosDefaultConfig} from '../../utils'
import {handleError} from '../../store/core/api'
import moment from 'moment'
import toastr from 'toastr'
import SearchNavigation from "../../components/Common/SearchNavigation";

const MoveFileDialog = ({open, handleClose, selectedDatastream, parentContainer}) => {

  const [moveDestination, setMoveDestination] = useState('');

  useEffect(()=>{
    setMoveDestination('')
  },[open])

  const handleMoveFile = () => {
    if (moveDestination === "") {
      toastr.error("Please select a destination");
    } else if (parentContainer.uuid === moveDestination.uuid || (parentContainer.uuid === undefined && moveDestination.uuid === null) || moveDestination.uuid === null) {
      toastr.error("Select a different destination");
    } else if (parentContainer.uuid !== moveDestination.uuid) {
      const url = `${ApiEndpoint[EntityType.DataStream]}/${selectedDatastream.uuid}/move`;
      const payload = {
        from: parentContainer.uuid ? parentContainer.uuid : "ROOT",
        to: moveDestination.uuid ? moveDestination.uuid : "ROOT",
      };
      try {
        axios
          .post(url, payload, getAxiosDefaultConfig())
          .then(() => {
            toastr.success(`Moved successfully to ${moveDestination.label.length > 50 ? moveDestination.label.substring(0, 50) + "..." : moveDestination.label}`);
            handleClose();
          })
          .catch((e) => {
            console.log(e);
            toastr.error(e.response.data.message);
          });
      } catch (e) {
        toastr.error(e);
      }
    }
  }

  return (
    <BaseDialog title={`Move datastream ${selectedDatastream.label}`} size={"md"} handleClose={handleClose} open={open} primaryAction={handleMoveFile}>
      <form>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>From</Label>
              <Input type="text" className="form-control" value={parentContainer.label ? parentContainer.label : "ROOT"} disabled />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label>Select destination</Label>
            <SearchNavigation selectedContainer={selectedDatastream} parentContainer={parentContainer} setMoveDestination={setMoveDestination} />
          </Col>
        </Row>
      </form>
    </BaseDialog>
  );

};

export default MoveFileDialog;