import React from 'react';
import {withRouter} from "react-router-dom";
import {Col, FormGroup, Input, Label} from "reactstrap";

const Paragraph = (props) => {

  const {field, setField, selected, answerable, formDisabled, fieldNumber} = props
  const {title,description,value,required, index} = field

  const setValue = (e) => {
    const value = e.target.value
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable&&required&&value===''
      },
      value
    })
  }

  const checkErrors = (e) => {
    if (!answerable) return
    if (required) {
      if (field.value===''||field.value===null) {
        setField({
          ...field,
          errors: {
            required: true
          }
        })
      }
    }
  }

  const requiredError = field.errors&&field.errors.required
  const hasError = requiredError

  if ((!selected) || answerable) return <>
    <FormGroup row>
      <Col xs={12}>
        <Label for={`paragraph-${index}`}>{fieldNumber+1}. {title} {required&&<b>*</b>}</Label>
        <Input type="textarea" name={`paragraph-${index}`} id={`paragraph-${index}`} style={{minHeight:130}}
               placeholder={description}
               value={value}
               onChange={setValue}
               disabled={formDisabled}
               onBlur={checkErrors}
               invalid={hasError}
        />
      </Col>
    </FormGroup>
  </>

  return <> </>
}

export default withRouter(Paragraph);
