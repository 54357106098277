import React, { useCallback, useMemo, useState } from "react";
import BaseContent from "../../../components/Common/BaseContent";
import { connect } from "react-redux";
import { Schema } from "../../../store/core/schema";
import { EntityType } from "../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import CardSettings from "../../../components/Common/card-settings";
import {
  createUser,
  deleteUser, getUser,
  updateUser,
} from '../../../store/user/actions';
import {useListToggle, useToggle} from "../../../helpers/hooks";
import ListToggle from "../../../components/Common/ListToggle";
import ListSettings from "../../../components/Common/ListSettings";

const entityType = EntityType.User;

const breadcrumbs = [
  { title: "Settings", link: "/settings" },
  { title: "Users", link: "#" },
];

const Users = (props) => {
  const { context, getUser,createUser, deleteUser, updateUser } = props;
  const { result, isLoading, error } = context.User.getAll;
  const groups = context.User.get.result.groups
  const currentUser = context.User.get.result;
  const [openModal, toggleModal] = useToggle(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const userIdParam = props.match.params.id;
  const routedUser = useMemo(() => {
    return result.find((x) => x.id.toString() === userIdParam);
  }, [result, userIdParam]);
  const [isList, toggleList] = useListToggle();


  const handleEntityClick = useCallback(
    (entity) => {
      setEntity(entity);
      toggleModal();
      getUser(entity.uuid)
    },
    // eslint-disable-next-line
    [toggleModal]
  );

  React.useEffect(() => {
    if (routedUser) {
      setEntity(routedUser);
      toggleModal();
    } else {
      setEntity(currentUser);
    }
  }, [currentUser, routedUser, toggleModal]);

  const handleCreate = useCallback(() => {
    setEntity(Schema[entityType]);
    toggleModal();
  }, [toggleModal]);

  const userCards = useMemo(() => {
    return result.map((user) => ({
      label: `${user.firstname} ${user.lastname}`,
      info: user.email,
      icon: "bx-user",
      handleClick: () => handleEntityClick(user),
    }));
  }, [handleEntityClick, result]);

  const list = useMemo(() => {
    return result.map((user) => ({
      label: `${user.firstname} ${user.lastname}`,
      email: user.email,
      handleClick: () => handleEntityClick(user),
    }));
  }, [handleEntityClick, result]);

  const listColumns = [
    { name: "Name", prop: "label" },
    { name: "Email", prop: "email" },
  ];

  const handleClose = () => {
    toggleModal();
    props.history.push("/settings/users");
  };

  return (
    <>
      <BaseContent
        title={"Users"}
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
      >
        {!isList && <CardSettings settings={userCards} />}
        {isList && <ListSettings contents={list} columns={listColumns} />}
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={openModal}
          handleClose={handleClose}
          createEntity={createUser}
          updateEntity={updateUser}
          deleteEntity={deleteUser}
          context={context.User}
          groups={groups}
        />
      </BaseContent>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getUser,
  createUser,
  updateUser,
  deleteUser,
})(Users);
