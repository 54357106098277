import React from "react";
import {Redirect} from "react-router-dom";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// Dashboard

import RecentContainers from "../pages/Recent/recent-containers";
import StarredContainers from "../pages/Starred/starred-containers";
import DeletedContainers from "../pages/Trash/deleted-containers";
import GeneralSettings from "../pages/Settings/GeneralSettings/general-settings";
import Users from "../pages/Settings/Users/users";

import ContainersGrid from "../pages/Containers/containers-root";
import ContainerOverview from "../pages/Containers/container-overview";
import ContainerTypes from "../pages/Settings/ContentModel/ContainerTypes/container-types";
import DataStreamTypes from "../pages/Settings/ContentModel/DataStreamTypes/dataStream-types";
import {ContentModel} from "../pages/Settings/ContentModel/content-model";
import Properties from "../pages/Settings/ContentModel/Properties/properties";
import Namespaces from "../pages/Settings/ContentModel/Namespaces/namespaces";
import Qualifiers from "../pages/Settings/ContentModel/Qualifiers/qualifiers";
import Vocabularies from "../pages/Settings/ContentModel/Vocabularies/vocabularies";
import EditVocabulary from '../pages/Settings/ContentModel/Vocabularies/EditVocabulary'

import Groups from "../pages/Settings/Groups/groups";
import {Settings} from "../pages/Settings/settings";
import Tags from "../pages/Settings/Tags/tags";
import DataStreamView from "../pages/DataStreams/datastream-view";
import TagObjects from "../pages/Tag/tag-objects";
import StorageFiles from "../pages/Storage/storage-files";
import Reports from '../pages/Reports';
import Surveys from '../pages/Surveys';
import SurveyEditNew from "../pages/Surveys/SurveyEditNew";
import SurveyView from "../pages/Surveys/public/SurveyView";
import SurveySummary from "../pages/Surveys/public/SurveySummary";
import Contacts from "../pages/Contacts/Contacts";
import StoriesRoot from '../pages/Story/Stories/stories-root'
import StoryOverview from '../pages/Story/Stories/story-overview'
import NewStory from '../pages/Story/Stories/NewStory'
import Screens from "../pages/Story/Screens";
import ScreenEditNew from "../pages/Story/Screens/ScreenEditNew";
import StoryViewer from "../pages/Story/Viewer";
import ScreenApp from "../pages/Story/Screens/ScreenApp";
import Websites from "../pages/Story/Websites";
import WebsiteEditNew from "../pages/Story/Websites/WebsiteEditNew";
import EmbeddedViewer from "../pages/Story/Viewer/embedded_viewer";
import TemplateEditNew from '../pages/Story/Stories/Templates/TemplateEditNew'
import TemplatesRoot from '../pages/Story/Stories/Templates'
import GuideDirectory from "../pages/Guide";
import GuideCreationWizard from "../pages/Guide/GuideCreationWizard";


// Content Model v2

// import ContainerTypesv2 from "../pages/Settings/ContentModelv2/ContainerTypes/container-types";
// import DataStreamTypesv2 from "../pages/Settings/ContentModelv2/DataStreamTypes/dataStream-types";
// import Attributes from "../pages/Settings/ContentModelv2/Attributes";
// import {ContentModelv2} from "../pages/Settings/ContentModelv2/content-model";
// import Propertiesv2 from "../pages/Settings/ContentModelv2/Properties/properties";
// import Namespacesv2 from "../pages/Settings/ContentModelv2/Namespaces/namespaces";
//
// import Vocabulariesv2 from "../pages/Settings/ContentModelv2/Vocabularies/vocabularies";
// import EditVocabularyv2 from '../pages/Settings/ContentModelv2/Vocabularies/EditVocabulary'
// import SchemasRootv2 from '../pages/Settings/ContentModelv2/Schemas'

const authProtectedRoutes = [

	// { path: "/dashboard", component: Dashboard },
	{ path: "/containers", component: ContainersGrid },
	{ path: "/containers/:id", component: ContainerOverview },
	{ path: "/data-streams/:id", component: DataStreamView },
	{ path: "/tagged/:id", component: TagObjects },
	{ path: "/recent", component: RecentContainers },
	{ path: "/starred", component: StarredContainers },
	{ path: "/reports", component: Reports },
	{ path: "/trash", component: DeletedContainers },
	{ path: "/storage", component: StorageFiles },
	// Surveys
	{ path: "/surveys", component: Surveys },
	{ path: "/surveys/new", component: SurveyEditNew },
	{ path: "/surveys/edit/:uuid/:page?/:subpage?/:responseUuid?", component: SurveyEditNew },
	{ path: "/surveys/edit/:uuid", component: SurveyEditNew },
	{ path: "/contacts", component: Contacts},

	//Stories
	{ path: "/stories/", component: StoriesRoot },
	{ path: "/stories/edit/:id", component: StoryOverview },
	{ path: "/stories/new",component: NewStory },
	{path: "/stories/templates",component:TemplatesRoot},
	{path: "/stories/template-editor",component:TemplateEditNew},

	// Screens
	{ path: "/screen-app", component: ScreenApp },
	{ path: "/screens/edit/:id/:page?", component: ScreenEditNew },
	{ path: "/screens/edit/:id", component: ScreenEditNew },
	{ path: "/screens", component: Screens },
	{ path: "/screens/new", component: ScreenEditNew },

	// Websites
	{ path: "/websites/edit/:id/:page?/", component: WebsiteEditNew },
	{ path: "/websites/edit/:id", component: WebsiteEditNew },
	{ path: "/websites", component: Websites },
	{ path: "/websites/new", component: WebsiteEditNew },

	// Gudie

	{path:'/guide',component: GuideDirectory},
	{path:'/guide/new',component: GuideCreationWizard},

	// { path: "/upload", component: Upload },
	{ path: "/settings/content-model/container-types", component: ContainerTypes },
	{ path: "/settings/content-model/datastream-types", component: DataStreamTypes },
	{ path: "/settings/content-model/properties", component: Properties },
	{ path: "/settings/tags", component: Tags },
	{ path: "/settings/content-model/namespaces", component: Namespaces },
	{ path: "/settings/content-model/qualifiers", component: Qualifiers },
	// Vocabularies
	{ path: "/settings/content-model/vocabularies", component: Vocabularies },
	{ path: "/settings/content-model/vocabulary/:id", component: EditVocabulary },
	// { path: "/settings/content-model/vocabulary/:id", component: EditVocabulary },


	// Content Model v 2.0,
	// { path: "/settings/content-model-v2/container-types", component: ContainerTypesv2 },
	// { path: "/settings/content-model-v2/datastream-types", component: DataStreamTypesv2 },
	// { path: "/settings/content-model-v2/properties", component: Propertiesv2 },
	// { path: "/settings/tags", component: Tags },
	// { path: "/settings/content-model-v2/namespaces", component: Namespacesv2 },
	// { path: "/settings/content-model-v2/attributes", component: Attributes },
	// // Vocabularies
	// { path: "/settings/content-model-v2/vocabularies", component: Vocabulariesv2 },
	// { path: "/settings/content-model-v2/vocabulary/:id", component: EditVocabularyv2 },
	// // Schemas
	// { path: "/settings/content-model-v2/schemas", component: SchemasRootv2 },


	{ path: "/settings/general", component: GeneralSettings },
	{ path: "/settings/users", component: Users },
	{ path: "/settings/users/:id", component: Users },
	{ path: "/settings/groups", component: Groups },
	{ path: "/settings/groups/:id", component: Groups },
	{ path: "/settings/content-model", component: ContentModel  },
	// { path: "/settings/content-model-v2", component: ContentModelv2  },
	{ path: "/settings", exact: true, component: Settings  },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/containers" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	// Surveys
	{ path: "/survey/summary/:uuid", component: SurveySummary},
	{ path: "/survey/:uuid", component: SurveyView},
	// Viewer
	{ path: "/repox-story/:uuid", component: StoryViewer},
	{ path: "/story-embed/:uuid", component: EmbeddedViewer},


];

export { authProtectedRoutes, publicRoutes };
