import React, {useState} from 'react';
import ErrorBoundary from '../../components/Common/ErrorBoundary';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import {Button} from 'reactstrap';
import ReactPlayer from 'react-player';
import DocViewer, {DocViewerRenderers} from "react-doc-viewer";

const FilePreview = ({fileBase64, fileType, isLoading}) => {
  const isImage = fileType && fileType.includes('image');
  const isPDF = fileType && fileType.includes('pdf');
  const isVideo = fileType && fileType.includes('video');
  const isDocument = fileType?.includes("document");
  const isTXT = fileType && fileType?.includes("text");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  if (isLoading)
    return (
      <div className="text-white font-size-15 text-center mt-5">Loading...</div>
    );

  const docs = [
    {
      uri: fileBase64,
    },
  ];

  return (
    <ErrorBoundary>
      <div
        style={{height: '100%'}}
        onClick={(e) => e.stopPropagation()}
      >
        {fileBase64 && !isLoading && fileType && (
          <>
            <div
              style={{
                border: 0,
                overflow: 'auto',
                minWidth: '900px',
                minHeight: '800px',
                height: 'auto',
                width: 'auto',
                textAlign: 'center'
              }}
            >
              {isImage && (
                <div style={{maxWidth:'80vw',maxHeight:'90vh'}}>
                <img
                  src={fileBase64}
                  title={'preview'}
                  height={'100%'}
                  width={'90%'}
                  alt={'file preview'}
                />
                </div>
              )}
              {isPDF && (
                <Container className='align-items-center align-content-center justify-content-center'>
                  <Button
                    type="button"
                    color="light"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    style={{position: 'absolute', top: '300px', left: 0}}
                  >
                    Previous
                  </Button>
                  <Row>
                    <Col>
                      <div className="text-center text-white">
                        Page {pageNumber || (numPages ? 1 : '--')} of{' '}
                        {numPages || '--'}
                      </div>
                    </Col>
                  </Row>
                  <Row className='flex-center flex-fill'>
                    <Col className='col-auto' style={{margin: '0 auto'}}>
                      <Document
                        file={fileBase64}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page
                          pageNumber={pageNumber}
                          renderAnnotationLayer={false}
                        />
                      </Document>
                    </Col>
                  </Row>
                  <Button
                    type="button"
                    color="light"
                    style={{position: 'absolute', top: '300px', right: 0}}
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    Next
                  </Button>
                </Container>
              )}
              {isTXT && (
                <Container className='align-items-center align-content-center justify-content-center'>

                  <Row className='flex-center flex-fill'>
                    <Col className='col-auto' style={{margin: '0 auto'}}>
                      <DocViewer                      
                        documents={docs}        
                         pluginRenderers={DocViewerRenderers}
                      />
                    </Col>
                  </Row>

                </Container>
              )}              
              {isVideo && (
                  <Col className='col-lg-auto'>
                    <ReactPlayer
                      url={fileBase64}
                      controls={true}
                      width={'100%'}
                      height={500}
                      playing
                    />
                  </Col>
              )}
              { isDocument && (
                <div style={{ color: 'white', fontSize: 18 }}>Doc Viewer is coming soon</div>
                
              )
              }
            </div>
          </>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default FilePreview;
