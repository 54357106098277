import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {entityOperations, entityType} from "./module";
import getCoreSagas from "../core/saga";
import {
    buildActionType, createDeleteFailureMethod, createDeleteSuccessMethod,
    createPostFailureMethod,
    createPostSuccessMethod, createPutSuccessMethod,
} from "../core/factory";
import * as ActionTypes from "../core/actionTypes";
import {ApiEndpoint} from "../core/endpoint";
import {deleteEntity, postEntity, putEntity} from "../core/api";

const coreSagas = getCoreSagas(entityType, entityOperations)
const errorCallback = (result) => createPostFailureMethod(entityType, result);
const successCallback = (result) => createPostSuccessMethod(entityType, result);
const successUpdateCallback = (result) => createPutSuccessMethod(entityType, result);
const errorUpdateCallback = (result) => createPostFailureMethod(entityType, result);
const successDeleteCallback = (result) => createDeleteSuccessMethod(entityType, result);
const errorDeleteCallback = (result) => createDeleteFailureMethod(entityType, result);

export function* postVocabularyType({ payload }) {
    try {
        const url = ApiEndpoint[entityType];
        const response = yield call(postEntity,url,payload);
        yield put(successCallback(response));
    } catch (error) {
        yield put(errorCallback(error.message));
    }
}

export function* putVocabularyType({ payload }) {
    try {
        const url = ApiEndpoint[entityType]
        const response = yield call(putEntity, url, payload);
        yield put(successUpdateCallback(response));
    } catch (error) {
        yield put(errorUpdateCallback(error.message));
    }
}

export function* deleteVocabularyType({ payload }) {
    try {
        const url = ApiEndpoint[entityType]
        const response = yield call(deleteEntity, url, payload);
        yield put(successDeleteCallback(response));
    } catch (error) {
        yield put(errorDeleteCallback(error.message));
    }
}

export function* watchEntityCreation() {
    const actionType = buildActionType(entityType, ActionTypes.POST);
    yield takeEvery(actionType, postVocabularyType);
}

export function* watchEntityUpdate() {
    const actionType = buildActionType(entityType, ActionTypes.UPDATE);
    yield takeEvery(actionType, putVocabularyType);
}

export function* watchEntityDelete() {
    const actionType = buildActionType(entityType, ActionTypes.DELETE);
    yield takeEvery(actionType, deleteVocabularyType);
}

function* sagas() {
    coreSagas.push(fork(watchEntityCreation));
    coreSagas.push(fork(watchEntityUpdate));
    coreSagas.push(fork(watchEntityDelete));

    yield all(coreSagas);
}

export default sagas;
