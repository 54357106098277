import React from 'react'

const UserItem = (props) => {
  const {handleChange, checked, user} = props
  return <tr onClick={handleChange} style={{cursor:'pointer'}}>
    <th>
      <input type={'checkbox'} checked={checked} onChange={handleChange}/>
    </th>
    <td>
      <h5 className="font-size-12 mb-0">{user.firstname}</h5>
    </td>
    <td>
      <h5 className="font-size-12 mb-0">{user.lastname}</h5>
    </td>
    <td>
      <h5 className="font-size-12 mb-0">{user.username}</h5>
    </td>
    <td>
      <h5 className="font-size-12 mb-0">{user.email}</h5>
    </td>
  </tr>
}

export default UserItem