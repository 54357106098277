import React, {useState} from 'react';
import { Badge, Card, CardBody, Col, Media, Row } from 'reactstrap';
import ActionsContextVocMenu from "../../../../Containers/ActionsContextVocMenu";

function VocCardSettings(props) {
  const { isDataStream } = props
    const [menuIndex, setMenuIndex] = useState(null);
    const [pointerPosition, setPointerPosition] = useState({x: 0, y: 0});
    const handleContextMenu = (e, setting, key, id) => {
      e.preventDefault();
      // const cardPosition = document.getElementById(id).getBoundingClientRect().bottom
      const windowHeight = window.innerHeight - e.clientY;
      if (windowHeight < 271) setPointerPosition({x: e.clientX, y: e.clientY, overflow: true});
      else setPointerPosition({x: e.clientX, y: e.clientY});
      props.handleContextVocClick(e, setting);

      setMenuIndex(key);
    };

    // const selectedVoc = {
    //   ...(menuIndex !== null && props.settings[menuIndex]),
    //   properties: props.selectedVoc?.properties,
    // };
  
  return (
    <Row>
      {props?.settings?.map((setting, key) => {                  
         const borderColor = props.selectedVoc?.uuid === setting.uuid ? "primary" : "light";       
        return (
          <Col xl="4" sm="6" key={"_project_" + key}>
            {/* <Link to={setting.route}> */}
            <Card
              className={`cursor-pointer border border-${borderColor}`}
              //onDoubleClick={setting.handleClick}
              onContextMenu={(e) => {
                handleContextMenu(e, setting, key, "_project_" + key);
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (menuIndex !== key) {
                  props.handleVocSelect(e, setting);
                }
              }}
              onDoubleClick={() => props.handleVocClick(setting.route)}
            >
              <CardBody>
                <Media className={"align-items-center"}>
                  {props.starred && (
                    <div className="position-absolute" style={{right: 0, bottom: 0, margin: 8}}>
                      <i className={`font-weight-bold bx bxs-star font-size-20`} style={{color: "#ffca00"}} />
                    </div>
                  )}
                  <div className="avatar-sm mr-4">
                    {!isDataStream ? (
                      <span className="avatar-title rounded-circle bg-light text-primary font-size-24">
                        <i className={`bx ${setting.icon}`} />
                      </span>
                    ) : (
                      setting.thumbnail
                    )}
                  </div>
                  <Media className="overflow-hidden" body>
                    <h5 className="text-truncate font-size-15 text-dark d-flex align-items-center">
                      {setting.label}
                      {setting.published && <i className="font-weight-bold bx bxs-check-circle text-success ml-1" />}
                    </h5>
                    {setting.info && (
                      <Badge color={"primary"} style={{backgroundColor: setting.color}}>
                        {setting.info}
                      </Badge>
                    )}
                  </Media>
                  {props.hasNext && <i className="font-weight-bold bx bx-chevron-right text-primary font-size-18" />}
                </Media>
              </CardBody>
            </Card>
            {menuIndex === key && (
              <ActionsContextVocMenu container={props.selectedVoc} setIsShown={setMenuIndex} openEditVoc={props.openEditVoc} setOpenEditVoc={props.setOpenEditVoc} position={pointerPosition} {...props} />
            )}
            {/* </Link> */}
          </Col>
        );
      })}
    </Row>
  );
}

export default VocCardSettings;
