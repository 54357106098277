import {
    createCustomAction,
    createDeleteMethod,
    createGetAllMethod,
    createGetAllSuccessMethod,
    createGetMethod,
    createGetSuccessMethod,
    createPostMethod,
    createPutMethod,
} from "../core/factory";
import {entityType} from "./module";
import {DELETE_DATASTREAM_FROM_CONTAINER, PUBLISH_CONTAINER, STAR_CONTAINER} from "./actionTypes";

export const getAllContainers = () => createGetAllMethod(entityType)
export const getContainer = (params) => createGetMethod(entityType, params)
export const createContainer = (payload) => createPostMethod(entityType, payload)
export const updateContainer = (payload) => createPutMethod(entityType, payload)
export const deleteContainer = (payload) => createDeleteMethod(entityType, payload)
export const clearContainer = () => createGetSuccessMethod(entityType, {})
export const clearContainers = () => createGetAllSuccessMethod(entityType, [])
export const starContainer = (payload) => createCustomAction(entityType, {data: payload, actionType: STAR_CONTAINER})
export const publishContainer = (payload) => createCustomAction(entityType, {data: payload, actionType: PUBLISH_CONTAINER})
export const deleteDataStreamFromContainer = (payload) => createCustomAction(entityType, {data: payload, actionType: DELETE_DATASTREAM_FROM_CONTAINER})

