import {all} from "redux-saga/effects";
import {entityOperations, entityType} from "./module";
import getCoreSagas from "../core/saga";

const coreSagas = getCoreSagas(entityType, entityOperations)

function* sagas() {
    yield all(coreSagas);
}

export default sagas;
