import React, {Component, useEffect, useState} from 'react';

import {connect} from 'react-redux';
// Reactstrap

import {Link} from 'react-router-dom';
// Import menuDropdown
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
// import logoLightPng from "../../assets/images/store_wording.png";
// import logoLightSvg from "../../assets/images/store_wording.png";
// import logoDark from "../../assets/images/store_wording.png";

import logo_store from '../../assets/images/store.png'
import logo_story from '../../assets/images/story.png'
// import logo_survey from "../../assets/images/survey.png"
import logo_store_wording from '../../assets/images/store_wording.png'
import logo_story_wording from '../../assets/images/story_wording.png'
import logo_surveys_wording from '../../assets/images/survey_wording.png'

import story from '../../assets/images/story.png'
import survey from '../../assets/images/survey.png'

// import images
//i18n
import {withNamespaces} from 'react-i18next';
// Redux Store
import {toggleRightSidebar} from '../../store/actions';
import {Col, Dropdown, DropdownMenu, DropdownToggle, Row} from 'reactstrap';
import Searchbar from './Search'
import {useLocation} from 'react-router';
import {usePermission} from '../../helpers/hooks'
import {permissionsEnums} from '../../constants/enums'

const Logo = ({size}) => {

  let location = useLocation();
  let routes = location.pathname.split('/')
  const page = routes[1] ? routes[1] : ''
  const [logo, setLogo] = useState()

  useEffect(() => {

    let pageGroup = page

    if (page === 'stories' || page === 'screens' || page === 'screen-app' || page === 'websites') {
      pageGroup = 'stories'
    }

    if (page === 'surveys' || page === 'contacts') {
      pageGroup = 'surveys'
    }

    switch (pageGroup) {
      case 'stories': {
        if (size === 'sm') {
          setLogo(logo_story)
        } else {
          setLogo(logo_story_wording)
        }
        break
      }
      case 'surveys': {
        if (size === 'sm') {
          setLogo(survey)
        } else {
          setLogo(logo_surveys_wording)
        }
        break
      }

      default: {
        if (size === 'sm') {
          setLogo(logo_store)
        } else {
          setLogo(logo_store_wording)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  if (!logo) return <> </>

  return <img src={logo} alt="" height={size === 'sm' ? 36 : 38}/>
}

const LogoArea = () => {
  let location = useLocation()
  let routes = location.pathname.split('/')
  let page = routes[1] ? routes[1] : '/'

  if (page === 'contacts') page = 'surveys'
  if (['screens', 'websites', 'screen-app'].includes(page)) page = 'stories'

  return <>
    <Link to={`/${page}`} className="logo logo-dark">
        <span className="logo-sm">
          <Logo size={'sm'}/>
        </span>
      <span className="logo-lg">
          <Logo size={'lg'}/>
        </span>
    </Link>

    <Link to={`/${page}`} className="logo logo-light">
      <span className="logo-sm">
          <Logo size={'sm'}/>
        </span>
      <span className="logo-lg">
        <Logo size={'lg'}/>
      </span>
    </Link>
  </>
}

const RepoxSuite = (props) => {
  const {context,isOpen} = props
  const [checkPermission] = usePermission(context)
  const [showStory, setShowStory] = useState(false)
  const [showSurvey, setShowSurvey] = useState(false)

  useEffect(() => {
    if(isOpen) {
      setShowStory(checkPermission(permissionsEnums.STORY.show))
      setShowSurvey(checkPermission(permissionsEnums.SURVEY.show))
    }
    // eslint-disable-next-line
  }, [isOpen])

  return <>
    <Col>
      <Link className="dropdown-icon-item" to="/containers">
        <img src={logo_store} alt="Store"/>
        <span>Store</span>
      </Link>
    </Col>
    {/*<Col>*/}
    {/*  <Link className="dropdown-icon-item" to="#">*/}
    {/*    <img src={logo} alt="Repox flow" />*/}
    {/*    <span>Repox Flow</span>*/}
    {/*  </Link>*/}
    {/*</Col>*/}
    {showSurvey &&
    <Col>
      <Link className="dropdown-icon-item" to="/surveys">
        <img src={survey} alt="Repox Surveys"/>
        <span>Surveys</span>
      </Link>
    </Col>
    }
    {showStory &&
    <Col>
      <Link className="dropdown-icon-item" to="/stories">
        <img src={story} alt="Repox Stories"/>
        <span>Stories</span>
      </Link>
    </Col>
    }
    <Col>
        <Link className="dropdown-icon-item" to="/guide">
          <img src={story} alt="Repox Guide"/>
          <span>Guide</span>
        </Link>
      </Col>
  </>
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }


  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  currentLogo(size) {

  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <LogoArea/>
              </div>

              <button type="button" onClick={this.toggleMenu}
                      className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                <i className="fa fa-fw fa-bars"></i>
              </button>

              <Searchbar {...this.props}/>
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <button onClick={() => {
                  this.setState({isSearch: !this.state.isSearch});
                }} type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={this.state.isSearch ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show' : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'}
                  aria-labelledby="page-header-search-dropdown">

                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search ..."
                               aria-label="Recipient's username"/>
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <Dropdown className="d-none d-lg-inline-block ml-1" isOpen={this.state.socialDrp} toggle={() => {
                this.setState({socialDrp: !this.state.socialDrp})
              }}>
                <DropdownToggle className="btn header-item noti-icon waves-effect" caret tag="button">
                  <i className="bx bx-customize"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg" right>
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      <RepoxSuite {...this.props} isOpen={this.state.socialDrp}/>
                    </Row>
                  </div>
                </DropdownMenu>
              </Dropdown>

              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" onClick={this.toggleFullscreen} className="btn header-item noti-icon waves-effect"
                        data-toggle="fullscreen">
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>

              <NotificationDropdown/>
              <ProfileMenu/>

            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {toggleRightSidebar})(withNamespaces()(Header));
