import React, {useEffect, useState} from 'react'
import {FormGroup, Label} from 'reactstrap'
import Select from 'react-select'
import axios from 'axios'
import {API_URL} from '../../../../../config'
import {getAxiosDefaultConfig} from '../../../../../utils'
import toastr from 'toastr'

const VocabularyProperty = (props) =>{
  const {handleChangeArray,entity} = props
  const [vocabularies,setVocabularies] = useState([])
  const [selectedVoc,setSelectedVoc] = useState({})
  const [options,setOptions] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  const [error,setError] = useState(false)

  const getAllVocabularies = () =>{
    axios.get(`${API_URL}/vocabularies`, getAxiosDefaultConfig())
      .then((response) => {
        setVocabularies(response.data.content)
        setIsLoading(false)
      })
      .catch((e) => {
        setError(true)
        setIsLoading(false)
        toastr.error(e)
      });
  }

  useEffect(()=>{
    getAllVocabularies()
  },[])

  useEffect(()=>{
    if(vocabularies.length>0) {
      if (entity?.propertyDetails) {
        const selectedUuid = JSON.parse(entity.propertyDetails)?.vocabularyId
        if (selectedUuid) {
          console.log(selectedUuid)
          const foundVoc = vocabularies.find((v) => v.uuid === selectedUuid)
          setSelectedVoc({label: foundVoc.name, value: foundVoc.uuid})
        }}

      const optionsList = []
      vocabularies.forEach((v) => {
        optionsList.push({value: v.uuid, label: v.name})
      })
      setOptions(optionsList)
    }
    // eslint-disable-next-line
  },[vocabularies])

  useEffect(()=>{
    handleChangeArray('propertyDetails', JSON.stringify({values:[],vocabularyId:selectedVoc.value}))
    // eslint-disable-next-line
  },[selectedVoc])

  return <FormGroup>
    <Label>Vocabularies</Label>
    <Select
      value={selectedVoc}
      onChange={(value) => setSelectedVoc(value)}
      name="containers"
      disabled={isLoading || error}
      options={options}
      classNamePrefix="select"
    />
  </FormGroup>
}

export default VocabularyProperty