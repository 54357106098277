import React from 'react';
import {withRouter} from "react-router-dom";
import {Col, FormGroup, Input, Label} from "reactstrap";

const Email = (props) => {
  const {field, setField, selected, answerable, formDisabled, standalone, fieldNumber} = props
  const {title,description,value,required, index} = field

  const invalidEmail = (value) =>{
    if (value!==''&&value!==null) {
      // eslint-disable-next-line
      const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return !expression.test(String(value).toLowerCase())
    } else {
      return false
    }
  }

  const setValue = (e) => {
    const value = e.target.value
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable&&required&&value==='',
        invalidFormat: !!invalidEmail(value)
      },
      value
    })
  }

  const checkErrors = (e) => {
    if (!answerable) return

    let errors = {}
    if (field.errors) errors = {...field.errors}

    if (required) {
      if (field.value===''||field.value===null) {
        errors.required = true
      }
    }

    errors.invalidFormat = invalidEmail(field.value)

    setField({
      ...field,
      errors
    })
  }

  const requiredError = field.errors&&field.errors.required
  const invalidFormat = field.errors&&field.errors.invalidFormat
  const hasError = requiredError || invalidFormat

  if ((!selected) || answerable) return <>
      <FormGroup row>
        <Col xs={12}>
          <Label for={`email-${index}`}>{!standalone?`${fieldNumber+1}.`:``} {title} {required&&<b>*</b>} </Label>
          <div style={{fontSize: 12, marginTop: -8, marginBottom: 8, opacity: 0.8}}>{description}</div>
          <Input type="email" name={`email-${index}`} id={`email-${index}`}
                 placeholder={'example@email.com'}
                 disabled={formDisabled}
                 value={value}
                 onChange={setValue}
                 onBlur={checkErrors}
                 invalid={hasError}
          />
        </Col>
      </FormGroup>
    </>

  return <> </>
}

export default withRouter(Email);
