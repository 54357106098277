import React from "react";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";

const ScrollLoader = ({ loading }) => {
  return (
    loading && (
      <Row>
        <Col xs="12">
          <div className="text-center my-3">
            <Link to="#" className="text-success">
              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
              Loading content
            </Link>
          </div>
        </Col>
      </Row>
    )
  );
};

export default ScrollLoader;
