import React from "react";

const BusyIndicator = ({ busy }) => {
  return (
    busy && (
      <div className="spinner-chase">
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
      </div>
    )
  );
};

export default BusyIndicator;
