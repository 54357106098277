import React, {useState} from "react";
import BaseDialog from "../../../components/Common/BaseDialog";
import Contacts from "../Contacts";

const AddressBook = (props) => {

  const {open, handleClose } = props
  const [selectedContacts, setSelectedContacts] = useState([])

  return <BaseDialog
    title={'Select contacts'}
    primaryAction={()=>{
      handleClose(selectedContacts)
    }}
    primaryActionTitle={'Add selected contacts'}
    open={open}
    handleClose={()=>handleClose()}
    scrollable={true}
    size='lg'
  >
    <Contacts setSelectedContacts={setSelectedContacts} addressBook />
  </BaseDialog>
}

export default AddressBook
