import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const DeleteDialog = ({open, handleDelete, handleClose, title = "Remove confirmation", question = `Are you sure you want to move the container to trash ?`}) => {
  return open ? (
    <SweetAlert
      title={title}
      warning
      onConfirm={() => {
        handleDelete();
        handleClose();
      }}
      onCancel={handleClose}
    >
      {question}
    </SweetAlert>
  ) : null;
};

export default DeleteDialog;
