import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {entityOperations, entityType} from "./module";
import getCoreSagas from "../core/saga";
import {
  buildActionType, createGetFailureMethod, createGetSuccessMethod,
  createPostFailureMethod,
  createPostSuccessMethod, createPutSuccessMethod,
} from "../core/factory";
import * as ActionTypes from "../core/actionTypes";
import {ApiEndpoint} from "../core/endpoint";
import {postEntity, putEntity, fetchEntity} from "../core/api";

const coreSagas = getCoreSagas(entityType, entityOperations)
const errorCallback = (result) => createPostFailureMethod(entityType, result);
const successCallback = (result) => createPostSuccessMethod(entityType, result);
const successUpdateCallback = (result) => createPutSuccessMethod(entityType, result);
const errorUpdateCallback = (result) => createPostFailureMethod(entityType, result);
const successGetCallback = (result) => createGetSuccessMethod(entityType, result);
const errorGetCallback = (result) => createGetFailureMethod(entityType, result);

export function* postSurveyResponseType({ payload }) {
  try {
    const url = ApiEndpoint[entityType];
    const response = yield call(postEntity,url,payload);
    yield put(successCallback(response));
  } catch (error) {
    yield put(errorCallback(error.message));
  }
}

export function* putSurveyResponseType({ payload }) {
  try {
    const url = ApiEndpoint[entityType]
    const response = yield call(putEntity, url, payload);
    yield put(successUpdateCallback(response));
  } catch (error) {
    yield put(errorUpdateCallback(error.message));
  }
}

export function* getSurveyResponseType({ params }) {
  try {
    const url = ApiEndpoint[entityType] + `/${params}/responses_uuids`
    const response = yield call(fetchEntity, url);
    yield put(successGetCallback(response));
  } catch (error) {
    yield put(errorGetCallback(error.message));
  }
}

export function* watchEntityCreation() {
  const actionType = buildActionType(entityType, ActionTypes.POST);
  yield takeEvery(actionType, postSurveyResponseType);
}

export function* watchEntityUpdate() {
  const actionType = buildActionType(entityType, ActionTypes.UPDATE);
  yield takeEvery(actionType, putSurveyResponseType);
}

export function* watchEntityGet() {
  const actionType = buildActionType(entityType, ActionTypes.GET);
  yield takeEvery(actionType, getSurveyResponseType);
}

function* sagas() {
  coreSagas.push(fork(watchEntityGet));
  coreSagas.push(fork(watchEntityCreation));
  coreSagas.push(fork(watchEntityUpdate));

  yield all(coreSagas);
}

export default sagas;
