import React, {useEffect, useState} from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import {connect} from 'react-redux';

import {starContainer} from '../../store/container/actions';
import {Box} from '@material-ui/core'

const ActionsContextMenu = ({
                              container,
                              position,
                              isShown,
                              setIsShown,
                              toggleDelete,
                              starContainer,
                              ...props
                            }) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [showSelectedContainer, setShowSelectedContainer] = useState({});

  useEffect(() => {
    setOpen(true);
    setShowSelectedContainer(container);
  }, [showSelectedContainer, container]);

  const toggle = () => {
    if (dropdownOpen) {
      setIsShown(false);
    }
    setOpen(!dropdownOpen);
  };
  const handleEditClick = (e) => {
    e.preventDefault();
    props.handleEditClick(container);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    toggleDelete();
    // props.handleDelete(container)
  };

  const handleReportClick = (e) =>{
    e.preventDefault();
    props.handleReportClick(container)
  };

  const handlePrintPreviewClick = (e) => {
    e.preventDefault();
    props.handlePrintPreviewClick(container);
  }; 
  
  const handleMoveContainer = (e) => {
    e.preventDefault();
    props.handleMoveClick(container)
  };

  const handleLinkContainer = (e) => {
    e.preventDefault();
    props.handleLinkClick(container);
  };
  
  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{
          zIndex: 1000,
          position: 'fixed',
          top: position.overflow ? position.y - 40 : position.y,
          left: position.x
        }}
      >
        <DropdownToggle caret style={{display: 'none'}}>
          test
        </DropdownToggle>
        <DropdownMenu
          style={{
            height: position.overflow ? '140px' : 'auto',
            overflow: position.overflow ? 'auto' : '',
          }}
        >
          <DropdownItem
            className={'d-flex align-items-center'}
            onClick={(e) => handleEditClick(e)}
          >
            <i className="bx bx-edit mr-2 font-size-18"/> Edit
          </DropdownItem>
          {/*<DropdownItem className={"d-flex align-items-center"}>*/}
          {/*  <i className="bx bx-user-plus mr-2 font-size-18" /> Rights*/}
          {/*</DropdownItem>*/}
          {/*<DropdownItem className={"d-flex align-items-center"}>*/}
          {/*  <i className="bx bx-link mr-2 font-size-18" /> Share*/}
          {/*</DropdownItem>*/}
          <DropdownItem
            className={'d-flex align-items-center'}
            onClick={() => props.handleStarContainers(!container.starred)}
          >
            <i
              className={`bx ${
                !container.starred ? 'bx-star' : 'bxs-star'
              } mr-2 font-size-18`}
            />{' '}
            {!container.starred ? 'Add to starred' : 'Remove from starred'}
          </DropdownItem>
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="'d-flex align-items-center" tag={'i'}>
              <DropdownItem className={'d-flex align-items-center'}>
                <i className="bx bx-palette mr-2 font-size-18"/>Add Color
              </DropdownItem>
            </DropdownToggle>
            <DropdownMenu style={{padding:20,borderRadius:5,marginLeft:30}} onDoubleClick={(e)=> {
              e.preventDefault()
              e.stopPropagation()
            }}>
            <div style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#D83A56',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#D83A56')
                toggle()
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#FF616D',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#FF616D')
                toggle()
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#FC5404',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#FC5404')
                toggle()
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#F98404',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#F98404')
                toggle()
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#F9B208',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#F9B208')
                toggle()
              }}/>
              <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#F7FD04',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#F7FD04')
                toggle()
              }}/>
            </div>

              <div style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center',marginTop:10}}>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#2D6187',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#2D6187')
                toggle()
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#4E89AE',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#4E89AE')
                toggle()
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#28ABB9',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#28ABB9')
                toggle()
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#01937C',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#01937C')
                toggle()
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#A8DDA8',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#A8DDA8')
                toggle()
              }}/>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#B6C867',marginRight:4}} onClick={(e)=>{
                props.handleAddColor('#B6C867')
                toggle()
              }}/>
              </div>
              <div style={{display:'flex',justifyContent:'flex-start',alignContent:'flex-start',alignItems:'flex-start',marginTop:10}}>
                <Box className={'colorBoxContainer'} style={{width:'25px',height:'25px',backgroundColor:'#FFFFFF',border:'1px solid #000',float:'left'}} onClick={(e)=>{
                props.handleAddColor('#fff')
                toggle()
              }}/>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
          <DropdownItem
            className={'d-flex align-items-center'}
            onClick={props.openRename}
          >
            <i className="bx bx-edit-alt mr-2 font-size-18"/> Rename
          </DropdownItem>
          <DropdownItem divider/>
          <DropdownItem
            className={'d-flex align-items-center'}
            onClick={props.openImport}
          >
            <i className="bx bx-import mr-2 font-size-18"/> Import
          </DropdownItem>
          <DropdownItem
            className={'d-flex align-items-center'}
            onClick={props.openExport}
          >
            <i className="bx bx-export mr-2 font-size-18"/> Export
          </DropdownItem>
          <DropdownItem divider />
           <DropdownItem
            className={'d-flex align-items-center'}
            onClick={(e) => handleMoveContainer(e)}
          >
            <i className="bx bx-transfer mr-2 font-size-18"/> Move
          </DropdownItem>  
          <DropdownItem
            className={'d-flex align-items-center'}
            onClick={(e) => handleLinkContainer(e)}
          >
            <i className="bx bx bx-link mr-2 font-size-18"/> Link
          </DropdownItem>          
          <DropdownItem divider />
          <DropdownItem
            className={'d-flex align-items-center'}
            onClick={(e)=> handleReportClick(e)}
          >
            <i className="bx bxs-report mr-2 font-size-18"/> Report
          </DropdownItem>  
          <DropdownItem
            className={'d-flex align-items-center'}
            onClick={(e)=> handlePrintPreviewClick(e)}
          >
            <i className="bx bx-printer mr-2 font-size-18"/> Print preview
          </DropdownItem>           
          <DropdownItem divider/>
          <DropdownItem
            className="text-danger d-flex align-items-center"
            onClick={(e) => handleDeleteClick(e)}
          >
            <i className="bx bx-trash mr-2 font-size-18"/>
            Remove
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default connect(null, {
  starContainer,
})(ActionsContextMenu);
