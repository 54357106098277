import React, {useState} from 'react';
import {Badge, Card, CardBody, Col, Media} from "reactstrap";
import ActionsContextMenu from './ActionsContextMenu';
import { starContainer } from '../../store/container/actions';
import { connect } from 'react-redux'
import OverflowTip from "../../components/Common/OverflowTip";

function ContainerCards(props) {
  const [menuIndex, setMenuIndex] = useState(null);
  const [pointerPosition, setPointerPosition] = useState({x: 0, y: 0});
  const handleContextMenu = (e, container, key,id) => {
    e.preventDefault();
    // const cardPosition = document.getElementById(id).getBoundingClientRect().bottom
    const windowHeight = window.innerHeight - e.clientY
    if ((windowHeight) < 271)
      setPointerPosition({x: e.clientX, y: e.clientY,overflow:true});
    else
      setPointerPosition({x: e.clientX, y: e.clientY});
    props.handleContextClick(e, container);

    setMenuIndex(key);
  };

  const selectedContainer = {
    ...menuIndex !== null && props.containers[menuIndex],
    properties: props.currentContainer.properties
  }
  
  return (
    <React.Fragment>
      {props.containers.map((container, key) =>
      {
        const bgColor = container.color !== null && container.color !== undefined && container.color !== "#fff" ? `${container.color}8C` : "#fff";
        const selected = props.isSelected(container.uuid)
        const borderColor = selected ? 'primary' : 'light';
        return (
          <Col xl={props.colSize || 4} sm="6" key={"_project_" + key} id={"_project_" + key}>
            <Card
              className={`cursor-pointer border border-${borderColor}`}
              style={{position: "relative", backgroundColor: bgColor}}
              onContextMenu={(e) => {
                handleContextMenu(e, container, key, "_project_" + key);
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (menuIndex !== key) {
                  props.handleContainerSelect(e, container);
                }
              }}
              onDoubleClick={() => props.handleContainerClick(container)}
            >
              <CardBody>
                <Media className={"align-items-center"}>
                  {container.starred && (
                    <div className="position-absolute" style={{right: 0, bottom: 0, margin: 8}}>
                      <i className={`font-weight-bold bx bxs-star font-size-20`} style={{color: "#ffca00"}} />
                    </div>
                  )}
                  <div className="avatar-sm mr-4">
                    <span className="avatar-title rounded-circle bg-light text-primary font-size-24">
                      <i className="bx bx-folder-open" />
                    </span>
                  </div>
                  <Media className="overflow-hidden" body>
                    <h5 className="text-truncate font-size-15" id={"label" + key}>
                      <div className="text-dark d-flex align-items-center">
                        <OverflowTip>{container.label}</OverflowTip>
                        {container.published && <i className="font-weight-bold bx bxs-check-circle text-success ml-1" />}
                      </div>
                    </h5>
                    <Badge color={"primary"}>{container.type.name}</Badge>
                  </Media>
                </Media>
              </CardBody>
              {menuIndex === key && <ActionsContextMenu container={selectedContainer} setShowDeleteModal={props.setShowDeleteModal} setIsShown={setMenuIndex} position={pointerPosition} {...props} />}
            </Card>
          </Col>
        );
      })}
    </React.Fragment>
  );
}

export default connect(null, {
  starContainer,
})(ContainerCards);
