import React from "react";
import BaseContent from "../../components/Common/BaseContent";
import { useGroupedEntitiesFetch, useListToggle } from "../../helpers/hooks";
import { ApiEndpoint } from "../../store/core/endpoint";
import { EntityType } from "../../store/core/entityType";
import { createMap } from "../../utils";
import { connect } from "react-redux";
import GroupedEntities from "../../components/Common/GroupedEntities";
import ListToggle from "../../components/Common/ListToggle";

const TagObjects = (props) => {
  const id = props.match.params.id;
  const url = ApiEndpoint[EntityType.Tag] + `/${id}/objects`;
  const [isList, toggleList] = useListToggle();
  const result = useGroupedEntitiesFetch(url);

  const tagMap = createMap(props.tags);
  const currentTag = tagMap.get(parseInt(id));

  const breadcrumbs = React.useMemo(() => {
    const title = currentTag ? "Tagged  (" + currentTag.name + ")" : "Tagged";
    return [{ title, link: "#" }];
  }, [currentTag]);

  return (
    <BaseContent
      breadcrumbs={breadcrumbs}
      isLoading={result.isLoading}
      renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
    >
      <GroupedEntities result={result} starred={true} isList={isList} />
    </BaseContent>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.Tag.getAll.result,
  };
};
export default connect(mapStateToProps, null)(TagObjects);
