import {getCommonState, getEntityIdentifier, handleCommonState} from "../core/factory";
import {entityType} from "./module";
import * as ActionTypes from "../core/actionTypes";

const initialState = getCommonState();


const Project = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0]

  if (actionEntity !== entityType) return state
  const actionType = action.type.split(".")[1]

  switch (actionType) {
    case ActionTypes.UPDATE_SUCCESS:
      const identifierProp = getEntityIdentifier(entityType);
      return {
        ...state,
        getAll: {
          ...state.getAll,
          result: action.result.map((el) => ({
            ...el,
            id: el[identifierProp],
          })),
        },
        update: {
          ...state.update,
          isLoading: false,
          error: null,
        }
      }
    default: break;
  }

  return  handleCommonState(state, actionType, action, entityType);
};

export default Project;
