import React, {useEffect, useState} from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {ApiEndpoint} from "../../store/core/endpoint";
import {EntityType} from "../../store/core/entityType";
import axios from "axios";
import {getAxiosDefaultConfig} from "../../utils";
import toastr from "toastr";
import SearchNavigation from "../../components/Common/SearchNavigation";

const LinkFileDialog = ({open, handleClose, selectedDatastream, parentContainer}) => {
  const [moveDestination, setMoveDestination] = useState("");

  useEffect(() => {
    setMoveDestination("");
  }, [open]);

  const handleLinkFile = () => {
    if (moveDestination === "") {
      toastr.error("Please select a destination");
    } else if (parentContainer.uuid === moveDestination.uuid || (parentContainer.uuid === undefined && moveDestination.uuid === null) || moveDestination.uuid === null) {
      toastr.error("Select a different destination");
    } else if (parentContainer.uuid !== moveDestination.uuid) {
      const url = `${ApiEndpoint[EntityType.DataStream]}/${selectedDatastream.uuid}/link?to=${moveDestination.uuid}`;
      try {
        axios
          .post(url, null, getAxiosDefaultConfig())
          .then(() => {
            toastr.success(`Linked successfully to ${moveDestination.label.length > 50 ? moveDestination.label.substring(0, 50) + "..." : moveDestination.label}`);
            handleClose();
          })
          .catch((e) => {
            toastr.error(e.response.data.message);
          });
      } catch (e) {
        toastr.error(e);
      }
    }
  };

  return (
    <BaseDialog title={`Link datastream ${selectedDatastream.label}`} size={"md"} handleClose={handleClose} open={open} primaryAction={handleLinkFile}>
      <form>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>From</Label>
              <Input type="text" className="form-control" value={parentContainer.label} disabled />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label>Select destination</Label>
            <SearchNavigation selectedContainer={selectedDatastream} parentContainer={parentContainer} setMoveDestination={setMoveDestination} />
          </Col>
        </Row>
      </form>
    </BaseDialog>
  );
};

export default LinkFileDialog;
