import React, {  useEffect, useState } from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import {Input} from 'reactstrap';


const RenameDatastreamDialog = (props) => {
  const [title,setTitle] = useState('')

  useEffect(()=>{
    if (props?.datastreamUuid && props.open) {
      const container = props.context.Container.get.result
      console.log(props?.datastreamUuid)
      const datastream = container.datastreams.find((d) => d.uuid === props.datastreamUuid)
      console.log(datastream)
      setTitle(datastream.label)
    }
    //eslint-disable-next-line
  },[props.datastreamUuid,props.open])

  const handleTextChange = (e)=>{
    setTitle(e.target.value)
  }

  const handleRenameContainer = () =>{
    props.handleRename(title,props.datastreamUuid)
  }

  return (
    <BaseDialog
      scrollable
      size={"sm"}
      title={"Rename Datastream"}
      open={props.open}
      primaryAction={handleRenameContainer}
      handleClose={props.handleClose}
    >
      <Input
        type="text"
        className='form-control'
        value={title}
        onChange={handleTextChange}
      />
    </BaseDialog>
  );
};

export default RenameDatastreamDialog
