import { Card, CardBody, CardTitle } from "reactstrap";
import React from "react";

export const OptionCard = ({ checked, option, handleClick, disabled }) => {
  return (
    <Card
      onClick={() => {
        if (!disabled) handleClick(option);
      }}
      style={{ opacity: disabled ? 0.5 : 1 }}
      className={`text-white-50 my-2 border border-${checked ? "primary" : "light"}`}
    >
      <CardBody className={"d-flex align-items-center"}>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              e.stopPropagation();
              handleClick(option);
            }}
            className="custom-control-input"
            id={"source-radio_" + option.label + "_"}
          />
          <label className="custom-control-label" />
        </div>
        <CardTitle className={"my-0 ml-1 text-dark"}>{option.label}</CardTitle>
      </CardBody>
    </Card>
  );
};
