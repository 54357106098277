import { Schema } from "../../store/core/schema";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {compareProp, fetchData, makeid} from "../../utils";
import { EntityType } from "../../store/core/entityType";
import { ApiEndpoint } from "../../store/core/endpoint";
import _ from "lodash";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CardSelect from "../../components/Common/CardSelect";
import {PropertyColumn} from "../../components/Common/PropertyColumn";
import { useForm } from "../../helpers/hooks";

const useDataStreamDialogLogic = (entity, types, entityType, isOpen) => {
  const [form, handleChange, updateInitial] = useForm(Schema[entityType]);

  const handleChangeArray = (prop, value) => {
    handleChange(prop)(value);
  };
  const [properties, setProperties] = useState([]);

  const joinProps = useMemo(() => {
    return properties.map((property) => {
      const match =
        entity.properties &&
        entity.properties.find((x) => x.key === property.propertyKey);
      const id = match ? match.id : makeid();
      return {
        ...match,
        ...property,
        id,
      };
    });
  }, [entity.properties, properties]);

  useEffect(() => {
    const state = {
      id: entity.id,
      label: entity.label,
      type: entity.type && entity.type.code,
    };
    entity.properties &&
      entity.properties.forEach((prop) => {
        if (prop.key === "property-rich-text") {
          state[prop.id] = JSON.parse(prop.value);
        } else {
          state[prop.id] = prop.value;
        }
      });
    updateInitial(state);
  }, [entity, updateInitial]);

  const payload = useMemo(() => {
    return {
      id: form.id,
      label: form.label,
      type: form.type,
      properties: joinProps.map((prop) => {
        return {
          key: prop.propertyKey,
          value: form[prop.id] || "",
        };
      }),
    };
  }, [form, joinProps]);

  const shortPayload = useMemo(() => {
    return {
      ...entity,
      id: form.id,
      label: form.label,
    };
  }, [entity, form.id, form.label]);

  const fetchProperties = useCallback(
    async (dataStreamType) => {
      const typesEntity =
        entityType === EntityType.DataStream
          ? EntityType.DataStreamType
          : EntityType.ContainerType;
      const url = ApiEndpoint[typesEntity] + `/${dataStreamType}`;
      try {
        const dataStreamType = await fetchData(url);
        setProperties(dataStreamType.properties);
      } catch (e) {
        setProperties([]);
      }
    },
    [entityType]
  );

  useEffect(() => {
    if (form.type && isOpen) {
      fetchProperties(form.type);
    } else {
      setProperties([]);
    }
  }, [fetchProperties, form.type, isOpen]);

  return {
    joinProps,
    payload,
    form,
    handleChange,
    handleChangeArray,
    shortPayload,
  };
};

const useDataStreamDialogLayout = (
  entity,
  entityType,
  types,
  open,
  createEntity,
  updateEntity,
  propertyGroups
) => {
  const {
    form,
    handleChange,
    payload,
    handleChangeArray,
    joinProps,
    shortPayload,
  } = useDataStreamDialogLogic(entity, types, entityType, open);

  const typeOptions = types.map((type) => ({
    label: type.name,
    value: type.code,
  }));

  const selectedType = typeOptions.find((x) => x.value === form.type);

  const formGroups = useMemo(() => {
    if (!joinProps[0] || !propertyGroups[0]) return [];
    return _.chain(joinProps)
      .groupBy("groupId")
      .map((value, key) => ({
        groupId: parseInt(key),
        ...propertyGroups.find((x) => x.id === parseInt(key)),
        properties: value.sort(compareProp),
      }))
      .value();
  }, [joinProps, propertyGroups]);

  const handleCreate = () => {
    createEntity(payload);
  };

  const handleUpdate = () => {
    updateEntity(payload);
  };
  const handleShortUpdate = () => {
    updateEntity(shortPayload);
  };

  const basicLayout = !form.id && (
    <Row>
      <Col xs={12}>
        <FormGroup>
          <Label>Label</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="Enter label"
            value={form.label}
            onChange={handleChange("label")}
          />
        </FormGroup>
      </Col>
      <Col xs={12}>
        <FormGroup>
          <Label>Container type</Label>
          <CardSelect
            orientation={"column"}
            isDisabled={form.id}
            options={typeOptions}
            value={selectedType}
            onChange={handleChange("type")}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const shortLayout = (
    <Row>
      <Col xs={12}>
        <FormGroup>
          <Label>Label</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="Enter label"
            value={form.label}
            onChange={handleChange("label")}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const contents = formGroups.map((formGroup) => {
    return (
      <Row className={"my-3"}>
        {formGroup.properties.map((property) => {
          return (
            <PropertyColumn
              key={property.id}
              property={property}
              value={form[property.id]}
              handleChange={handleChange}
              handleChangeArray={handleChangeArray}
              className={"mb-2"}
              // createRepeatable={createRepeatable}
              // deleteRepeatable={deleteRepeatable}
              entityProperties={joinProps}
              joinProps={joinProps}
              // qualifiers={qualifiers}
              open={open}
            />
          );
        })}
      </Row>
    );
  });

  const steps = useMemo(() => {
    if (!form.id) return null;
    return formGroups.map((group) => ({
      title: group.name || group.groupId,
    }));
  }, [form.id, formGroups]);

  return {
    steps,
    contents,
    basicLayout,
    shortLayout,
    form,
    handleCreate,
    handleUpdate,
    handleShortUpdate,
  };
};

export { useDataStreamDialogLayout, useDataStreamDialogLogic };
