import DefaultLayout from "../assets/images/layout-default.svg";
import RecordLayout from "../assets/images/layout-record.svg";
import CataloguingLayout from "../assets/images/layout-record.svg";
import BlankImg from '../assets/images/stories/templateCovers/blank.png'
import OneTitleOneContentImg from '../assets/images/stories/templateCovers/oneTitleoneContent.png'
import ComparisonImg from '../assets/images/stories/templateCovers/comparison.png'
import PictureWithCaptionImg from '../assets/images/stories/templateCovers/pictureWithCaption.png'
import TitleWithCaptionImg from '../assets/images/stories/templateCovers/titleWithCaption.png'
import TwoContentImg from '../assets/images/stories/templateCovers/twoContent.png'


// Repox Story SlideShow Animations

export const ANIMATION_ENTER_TYPES = [
  // {label:'bounce',value:'bounce'},
  // {label:'flash',value:'flash'},
  // {label:'pulse',value:'pulse'},
  // {label:'rubberBand',value:'rubberBand'},
  // {label:'shake',value:'shake'},
  // {label:'headShake',value:'headShake'},
  // {label:'swing',value:'swing'},
  // {label:'tada',value:'tada'},
  // {label:'wobble',value:'wobble'},
  // {label:'jello',value:'jello'},
  // {label:'bounceIn',value:'bounceIn'},
  // {label:'bounceInDown',value:'bounceInDown'},
  // {label:'bounceInLeft',value:'bounceInLeft'},
  // {label:'bounceInRight',value:'bounceInRight'},
  // {label:'bounceInUp',value:'bounceInUp'},
  // {label:'bounceOut',value:'bounceOut'},
  // {label:'bounceOutDown',value:'bounceOutDown'},
  // {label:'bounceOutLeft',value:'bounceOutLeft'},
  // {label:'bounceOutRight',value:'bounceOutRight'},
  // {label:'bounceOutUp',value:'bounceOutUp'},
  {label:'fadeIn',value:'fadeIn'},
  {label:'fadeInDown',value:'fadeInDown'},
  {label:'fadeInDownBig',value:'fadeInDownBig'},
  {label:'fadeInLeft',value:'fadeInLeft'},
  {label:'fadeInLeftBig',value:'fadeInLeftBig'},
  {label:'fadeInRight',value:'fadeInRight'},
  {label:'fadeInRightBig',value:'fadeInRightBig'},
  {label:'fadeInUp',value:'fadeInUp'},
  {label:'fadeInUpBig',value:'fadeInUpBig'},
  // {label:'flipInX',value:'flipInX'},
  // {label:'flipInY',value:'flipInY'},
  // {label:'flipOutX',value:'flipOutX'},
  // {label:'flipOutY',value:'flipOutY'},
  // {label:'lightSpeedIn',value:'lightSpeedIn'},
  // {label:'lightSpeedOut',value:'lightSpeedOut'},
  // {label:'rotateIn',value:'rotateIn'},
  // {label:'rotateInDownLeft',value:'rotateInDownLeft'},
  // {label:'rotateInDownRight',value:'rotateInDownRight'},
  // {label:'rotateInUpLeft',value:'rotateInUpLeft'},
  // {label:'rotateInUpRight',value:'rotateInUpRight'},
  // {label:'rotateOut',value:'rotateOut'},
  // {label:'rotateOutDownLeft',value:'rotateOutDownLeft'},
  // {label:'rotateOutDownRight',value:'rotateOutDownRight'},
  // {label:'rotateOutUpLeft',value:'rotateOutUpLeft'},
  // {label:'rotateOutUpRight',value:'rotateOutUpRight'},
  // {label:'hinge',value:'hinge'},
  // {label:'jackInTheBox',value:'jackInTheBox'},
  // {label:'rollIn',value:'rollIn'},
  // {label:'rollOut',value:'rollOut'},
  // {label:'zoomIn',value:'zoomIn'},
  // {label:'zoomInDown',value:'zoomInDown'},
  // {label:'zoomInLeft',value:'zoomInLeft'},
  // {label:'zoomInRight',value:'zoomInRight'},
  // {label:'zoomInUp',value:'zoomInUp'},
  // {label:'zoomOut',value:'zoomOut'},
  // {label:'zoomOutDown',value:'zoomOutDown'},
  // {label:'zoomOutLeft',value:'zoomOutLeft'},
  // {label:'zoomOutRight',value:'zoomOutRight'},
  // {label:'zoomOutUp',value:'zoomOutUp'},
  // {label:'slideInDown',value:'slideInDown'},
  // {label:'slideInLeft',value:'slideInLeft'},
  // {label:'slideInRight',value:'slideInRight'},
  // {label:'slideInUp',value:'slideInUp'},
  // {label:'slideOutDown',value:'slideOutDown'},
  // {label:'slideOutLeft',value:'slideOutLeft'},
  // {label:'slideOutRight',value:'slideOutRight'},
  // {label:'slideOutUp',value:'slideOutUp'},
]
export const ANIMATION_LEAVE_TYPES = [
  {label:'fadeOut',value:'fadeOut'},
  {label:'fadeOutDown',value:'fadeOutDown'},
  {label:'fadeOutDownBig',value:'fadeOutDownBig'},
  {label:'fadeOutLeft',value:'fadeOutLeft'},
  {label:'fadeOutLeftBig',value:'fadeOutLeftBig'},
  {label:'fadeOutRight',value:'fadeOutRight'},
  {label:'fadeOutRightBig',value:'fadeOutRightBig'},
  {label:'fadeOutUp',value:'fadeOutUp'},
  {label:'fadeOutUpBig',value:'fadeOutUpBig'},
]
const googleFonts = [
  {label:'GFS Didot',value:'GFS Didot'},
  {label:'GFS Neohellenic',value:'GFS Neohellenic'},
  {label:'Oi',value:'Oi'},
  {label:'Open Sans',value:'Open Sans'},
  {label:'Roboto',value:'Roboto'},
  {label:'Roboto Mono',value:'Roboto Mono'},
  {label:'Source Sans Pro',value:'Source Sans Pro'},
  {label:'Ubuntu',value:'Ubuntu'},
]
const googleFontsWeights = [
  {
    font: 'Oi',
    weights: [400],
  },
  {
    font: 'Open Sans',
    weights: [300, 400, 600, 700, 800],
  },
  {
    font: 'Source Sans Pro',
    weights: [200, 300, 400, 600, 700, 900],
  },
  {
    font: 'Roboto',
    weights: [100,300, 400,500,700,900],
  },
  {
    font: 'Roboto Mono',
    weights: [100,200,300,400,500,600,700],
  },
  {
    font: 'GFS Didot',
    weights: [400],
  },
  {
    font: 'GFS Neohellenic',
    weights: [400,700],
  },
  {
    font: 'Ubuntu',
    weights: [300, 400, 500, 700],
  },
]

// Repox Story Slideshow Elements
const slideShowElements = [
  {title:'Text',icon:'bx bx-detail',description:'Type your text',type:"TEXT"},
  // {title:'Title',icon:'bx bx-text',description:'Type a title',type:"TITLE"},
  {title:'Image',icon:'bx bxs-image',description:'Select an image',type:"IMAGE"},
  {title:'Gallery',icon:'bx bx-grid-small',description:'Add an image Gallery',type:"GALLERY",disabled:true},
  {title:'Youtube',icon:'bx bxl-youtube',description:'Add an embedded video from Youtube',type:"YOUTUBE",disabled:true},
  {title:'Vimeo',icon:'bx bxl-vimeo',description:'Add an embedded video from Vimeo',type:"VIMEO",disabled:true},
]

const permissionsEnums = {
  STORE: {
    publishContainer:'repox.store.container.publish',
    sidebar:{
    settings:{
      showGeneralSettings:'repox.store.settings.system.show',
      showContentModel:'repox.store.settings.content_model.show',
      showIam:'repox.store.settings.iam.show'
    },
      showReports:'repox.store.reports.show'
    }

  },
  STORY:{
    show:'repox.stories.show'
  },
  SURVEY:{
    show:'repox.surveys.show'
  }


}

const storiesSlideTemplates =[
  {
    aspectRatio: "16:9",
    coverPage: BlankImg,
    createdAt: null,
    createdBy: 1,
    description: "Blank",
    orientation: "LANDSCAPE",
    slideDetails: {
      id: "VAngQmOOCQ",
      templateId: 'pre1',
      name: "Slide 1",
      duration: 10000,
      background: {
        color: "#fff",
        position: "",
        uuid: "",
        size: ""
      },
      animation: {
        enterAnimation: null,
        leaveAnimation: null,
        editorSlideDuration: 5,
        enterAnimationDelay: 11000,
        leaveAnimationDelay: 20000,
        durationEnter: 1000,
        durationLeave: 1000
      },
      elements: []
    },
    storyType: "SLIDESHOW",
    title: "Blank",
    updatedAt: null,
    updatedBy: 1,
    disabled:false,
    uuid: 'blank'},
  {
    aspectRatio: "16:9",
    coverPage: OneTitleOneContentImg,
    createdAt: null,
    createdBy: 1,
    description: "One Title, One Content",
    orientation: "LANDSCAPE",
    slideDetails: {
      id: "VAngQmOOCQ",
      templateId: 'pre1',
      name: "Slide 1",
      duration: 10000,
      background: {
        color: "#fff",
        position: "",
        uuid: "",
        size: ""
      },
      animation: {
        enterAnimation: null,
        leaveAnimation: null,
        editorSlideDuration: 5,
        enterAnimationDelay: 11000,
        leaveAnimationDelay: 20000,
        durationEnter: 1000,
        durationLeave: 1000
      },
      elements: [
        {
          id: "cBwyamPnIZ",
          type: "TEXT",
          name: "",
          x: 461.50612959719757,
          y: 232.0139952490915,
          zIndex: 1,
          height: 224,
          width: 1634,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 100,
            textAlign: "center",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "weRz2Sk7f1",
          type: "TEXT",
          name: "",
          x: 466.5499124343257,
          y: 494.05427545925056,
          zIndex: 1,
          height: 718,
          width: 1634,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 40,
            textAlign: "left",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        }
      ]
    },
    storyType: "SLIDESHOW",
    title: "One Title, One Content",
    updatedAt: null,
    updatedBy: 1,
    disabled:false,
    uuid: 'pre1'},
  {aspectRatio: "16:9",
    coverPage: TwoContentImg,
    createdAt: null,
    createdBy: 1,
    description: "Two Content",
    orientation: "LANDSCAPE",
    slideDetails: {
      id: "Zln0A0ndpq",
      templateId: "pre2",
      name: "Slide 3",
      duration: 10000,
      background: {
        color: "#fff",
        position: "",
        uuid: "",
        size: ""
      },
      animation: {
        enterAnimation: "fadeIn",
        leaveAnimation: "fadeOut",
        editorSlideDuration: 5,
        enterAnimationDelay: 11000,
        leaveAnimationDelay: 20000,
        durationEnter: 1000,
        durationLeave: 1000
      },
      elements: [
        {
          id: "5XQ1z5WyEx",
          type: "TEXT",
          name: "",
          x: 461.5061295971976,
          y: 232.01399524909152,
          zIndex: 1,
          height: 224,
          width: 1634,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 100,
            textAlign: "center",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "vGrCQgPJtP",
          type: "TEXT",
          name: "",
          x: 466.54989744772,
          y: 515.2112396392483,
          zIndex: 1,
          height: 718,
          width: 807,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 40,
            textAlign: "left",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "xTvlX8SDe2",
          type: "TEXT",
          name: "",
          x: 1298.0743872948347,
          y: 516.0578424709186,
          zIndex: 1,
          height: 715,
          width: 795,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 40,
            textAlign: "left",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        }
      ]
    },
    storyType: "SLIDESHOW",
    title: "Two Content",
    updatedAt: null,
    updatedBy: 1,
    disabled:false,
    uuid: 'pre2'},
  {aspectRatio: "16:9",
    coverPage: ComparisonImg,
    createdAt: null,
    createdBy: 1,
    description: "Comparison",
    orientation: "LANDSCAPE",
    slideDetails: {
      id: "2IIvWs3Non",
      templateId: "blank",
      name: "Slide 4",
      duration: 10000,
      background: {
        color: "#fff",
        position: "",
        uuid: "",
        size: ""
      },
      animation: {
        enterAnimation: null,
        leaveAnimation: null,
        editorSlideDuration: 5,
        enterAnimationDelay: 21000,
        leaveAnimationDelay: 30000,
        durationEnter: 1000,
        durationLeave: 1000
      },
      elements: [
        {
          id: "BTLHdeexMT",
          type: "TEXT",
          name: "",
          x: 248.59504132231424,
          y: 126.94214876033055,
          zIndex: 1,
          height: 323,
          width: 1948,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "\nEnter your text",
            color: "#101010",
            fontSize: 100,
            textAlign: "center",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "ROJbDddamH",
          type: "TEXT",
          name: "",
          x: 248.59504132231422,
          y: 462.8099173553718,
          zIndex: 1,
          height: 85,
          width: 988,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 30,
            textAlign: "left",
            fontWeight: 700,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "eU1WgyFdEx",
          type: "TEXT",
          name: "",
          x: 248.5950413223141,
          y: 555.3719008264462,
          zIndex: 1,
          height: 768,
          width: 989,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 30,
            textAlign: "left",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "2khbh0Ytvu",
          type: "TEXT",
          name: "",
          x: 1263.9090848055744,
          y: 465.4545759721241,
          zIndex: 1,
          height: 82,
          width: 935,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 30,
            textAlign: "left",
            fontWeight: 700,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "6LsubBpJja",
          type: "TEXT",
          name: "",
          x: 1264.132261922538,
          y: 558.016559443198,
          zIndex: 1,
          height: 765,
          width: 935,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 30,
            textAlign: "left",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        }
      ]
    },
    storyType: "SLIDESHOW",
    title: "Title with Caption",
    updatedAt: null,
    updatedBy: 1,
    disabled:false,
    uuid: 'pre3'},
  {aspectRatio: "16:9",
    coverPage: TitleWithCaptionImg,
    createdAt: null,
    createdBy: 1,
    description: "Title with Caption",
    orientation: "LANDSCAPE",
    slideDetails: {
      id: "sL1iGC723X",
      templateId: "pre4",
      name: "Slide 4",
      duration: 10000,
      background: {
        color: "#fff",
        position: "",
        uuid: "",
        size: ""
      },
      animation: {
        enterAnimation: null,
        leaveAnimation: null,
        editorSlideDuration: 5,
        enterAnimationDelay: 21000,
        leaveAnimationDelay: 30000,
        durationEnter: 1000,
        durationLeave: 1000
      },
      elements: [
        {
          id: "04hoaZeBS9",
          type: "TEXT",
          name: "",
          x: 461.50610429359585,
          y: 280.0140172775515,
          zIndex: 1,
          height: 472,
          width: 1634,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "\n\nEnter your text",
            color: "#101010",
            fontSize: 100,
            textAlign: "center",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "dTsz2l3NPg",
          type: "TEXT",
          name: "",
          x: 466.5498954190913,
          y: 766.054280969723,
          zIndex: 1,
          height: 446,
          width: 1634,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 40,
            textAlign: "left",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        }
      ]
    },
    storyType: "SLIDESHOW",
    title: "Picture with Caption",
    updatedAt: null,
    updatedBy: 1,
    disabled:false,
    uuid: 'pre4'},
  {aspectRatio: "16:9",
    coverPage: PictureWithCaptionImg,
    createdAt: null,
    createdBy: 1,
    description: "Picture with Caption",
    orientation: "LANDSCAPE",
    slideDetails: {
      id: "ZaeU0cT9jR",
      templateId: "pre4",
      name: "Slide 3",
      duration: 10000,
      background: {
        color: "#fff",
        position: "",
        uuid: "",
        size: ""
      },
      animation: {
        enterAnimation: null,
        leaveAnimation: null,
        editorSlideDuration: 5,
        enterAnimationDelay: 11000,
        leaveAnimationDelay: 20000,
        durationEnter: 1000,
        durationLeave: 1000
      },
      elements: [
        {
          id: "nxVW64DXUC",
          type: "TEXT",
          name: "",
          x: 109.77056439590172,
          y: 89.60079783342397,
          zIndex: 1,
          height: 472,
          width: 1020,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "\n\n\n\nEnter your text",
            color: "#101010",
            fontSize: 60,
            textAlign: "left",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "jxukx93Nwr",
          type: "TEXT",
          name: "",
          x: 114.8143602576377,
          y: 583.574942554547,
          zIndex: 1,
          height: 800,
          width: 1015,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            text: "Enter your text",
            color: "#101010",
            fontSize: 40,
            textAlign: "left",
            fontWeight: 300,
            fontFamily: "Roboto",
            textDecoration: "none",
            fontStyle: "normal"
          }
        },
        {
          id: "GF0SsyyWvB",
          type: "IMAGE",
          name: "",
          x: 1211.2396999390642,
          y: 187.76859504132247,
          zIndex: 1,
          height: 1195,
          width: 1263,
          rotation: 0,
          animation: {
            editorElementDuration: 1,
            enterAnimation: "fadeIn",
            leaveAnimation: "fadeOut",
            enterAnimationDelay: 1000,
            leaveAnimationDelay: 3000,
            durationEnter: 1000,
            durationLeave: 1000
          },
          data: {
            uuid: null,
            from: "stories",
            lockAspectRatio: false,
            dimensionsAreSet: false
          }
        }
      ]
    },
    storyType: "SLIDESHOW",
    title: "Comparison",
    updatedAt: null,
    updatedBy: 1,
    disabled:false,
    uuid: 'pre5'}

]

// Repox Story Download Options
const storyDownloadOptions = [
  {title:'Static .zip file (FHD)',icon:'bx bx-images',description:'Download story as images in Full High Definition',type:"STATIC_FHD"},
  {title:'Static .zip file (QHD)',icon:'bx bx-images',description:'Download story as images in Quad High Definition',type:"STATIC_QHD"},
  {title:'Video (FHD)',icon:'bx bx-video',description:'Download your story as a Full High Definition video. A link will be sent to your email',type:"VIDEO_FHD"},
  {title:'Screen app .zip file',icon:'bx bx-tv',description:'Download story formatted for our screen app',type:"SCREEN_APP"},
]

const layoutTemplates = [
  {
    type: '1',
    title: ['1/1'],
    size: [12]
  },
  {
    type: '2',
    title: ['1/2', '1/2'],
    size: [6, 6]
  },
  {
    type: '3',
    title: ['2/3', '1/3'],
    size: [8, 4]
  },
  {
    type: '4',
    title: ['2/3', '1/3'],
    size: [4, 8]
  },
  {
    type: '5',
    title: ['1/4', '3/4'],
    size: [2, 10]
  },
  {
    type: '6',
    title: ['3/4', '1/4'],
    size: [10,2]
  }
]
const ViewLayout = {
  default: "DEFAULT",
  record: "RECORD",
  // cataloguing: "CATALOGUING",
};
const ObjectType = {
  container: "CONTAINER",
  dataStream: "DATASTREAM",
};

const SystemSettingType = {
  enum: "ENUM",
  bool: "BOOLEAN",
  number: "NUMBER",
  text: "TEXT"
}

const SearchOperators = {
  default: "OP_DEFAULT",
  container: "OP_CONTAINER",
  datastream: "OP_DATASTREAM",
  container_property: "OP_CONTAINER_PROPERTY",
  datastream_property: "OP_DATASTREAM_PROPERTY",
  datastream_filetype: "OP_DATASTREAM_FILETYPE",
  datastream_filesize: "OP_DATASTREAM_FILESIZE",
  fulltext: "OP_DATASTREAM_FULLTEXT",
};

const ViewLayouts = [
  {
    value: ViewLayout.default,
    label: "Default",
    src: DefaultLayout,
    description: "The default layout displays the content using a container datastream hierarchy.",
  },
  {
    value: ViewLayout.record,
    label: "Record",
    src: RecordLayout,
    description: "The record layout shows primarily the container properties and the datastreams sub-containers as secondary. It can be used to show record-level containers",
  },
  // {
  //   value: ViewLayout.cataloguing,
  //   label: "Cataloguing",
  //   src: CataloguingLayout,
  //   description: "The cataloguing layout shows the container properties and the datastreams sub-containers as a slider. Below the slider there is a preview section of the selected datastreams.",
  // },
];

const AttributeTypes = [
  {label:'Text',value:'TEXT'},
  {label:'Numeric',value:'NUMERIC'},
  {label:'Enumeration',value:'ENUM'},
]
const PropertyUse = ["REQUIRED","OPTIONAL","RECOMMENDED"]

export { ViewLayouts, ViewLayout, ObjectType, SystemSettingType, SearchOperators,layoutTemplates,slideShowElements, storyDownloadOptions,googleFonts, googleFontsWeights,storiesSlideTemplates,permissionsEnums,AttributeTypes,PropertyUse };
