import React, {useEffect, useState} from 'react'
import { Col, Input} from 'reactstrap'

const YoutubeEditBlock = (props) =>{

  const {selectedElement, setSelectedElement,slides, selectedSlide, handleChange} = props
  const [youtubeUrl,setYoutubeUrl] = useState(null)
  // const [lockAspectRatio, setLockAspectRatio] = useState(true)

  useEffect(() => {
    setYoutubeUrl(selectedElement?.data?.url)
    //eslint-disable-next-line
  }, [selectedElement.id])

  const handleYoutubeIdChange = (e) => {
    let re = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
    let video_id = e.target.value.match(re);
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.url = e.target.value
    setYoutubeUrl(e.target.value)
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    elementsTmp[elementIndex].data.id = video_id ? video_id[1] : ''
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)

  }


  // const handleLockAspectRatio = () => {
  //   setLockAspectRatio(!lockAspectRatio)
  //   const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
  //   const slidesTmp = [...slides]
  //   const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
  //   const elementsTmp = [...slidesTmp[slideIndex].elements]
  //   elementsTmp[elementIndex].data.lockAspectRatio = !lockAspectRatio
  //   slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
  //   handleChange(slidesTmp)
  // }

  const handleDeleteElement = () => {
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = [...slides]
    const elementsTmp = slidesTmp[slideIndex].elements.filter((element) => element.id !== selectedElement.id)
    // console.log('on delete', elementsTmp)
    // console.log('seleceted element on delete', selectedElement.id)
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
    setSelectedElement({})
  }


  return <>
    <Col lg={12} className='mt-2'>
      <div className='font-size-13 font-weight-bold mb-2'>Youtube Url</div>
    </Col>
    <Col lg={12} className='mt-2'>
      <Input
        type="text"
        className="form-control"
        value={youtubeUrl}
        onChange={handleYoutubeIdChange}
      />
    </Col>
    <Col lg={12} className='mt-4'>
      <i className='bx bx-trash warning-color font-size-24 mdi-cursor-pointer' onClick={handleDeleteElement}/>
    </Col>
  </>
}


export default  YoutubeEditBlock