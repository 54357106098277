import React, { useState } from "react";
import { Badge, Col, Row, Tooltip } from "reactstrap";
import { ApiEndpoint } from "../../store/core/endpoint";
import { EntityType } from "../../store/core/entityType";
import { getAxiosDefaultConfig } from "../../utils";
import toastr from "toastr";
import axios from "axios";
import { handleError } from "../../store/core/api";
import { usePermission, useToggle} from '../../helpers/hooks';
import { connect } from "react-redux";
import { publishContainer } from "../../store/container/actions";
import {permissionsEnums} from '../../constants/enums'

const ContainerPublishControl = ({ container, publishContainer,context }) => {
  const [busy, setBusy] = useState();
  const [published, setPublished] = useState(false);
  const baseUrl = ApiEndpoint[EntityType.Container] + "/";
  const [tooltipOpen, toggleTooltip] = useToggle(false);
  const [checkPermission] = usePermission(context)
  const [canPublish,setCanPublish] = useState(false)

  const handleChange = async () => {
    const endpoint = published ? "/unpublish" : "/publish";
    const url = baseUrl + container.uuid + endpoint;

    setBusy(true);
    try {
      await axios.post(url, {}, getAxiosDefaultConfig()).catch(handleError);
      setPublished(!published);
      setBusy(false);
      publishContainer({ uuid: container.uuid, published: !published });
    } catch (e) {
      setBusy(false);
      toastr.error(e.message, "Error");
    }
  };

  React.useEffect(() => {
    setPublished(container && container.published);
    setCanPublish(checkPermission(permissionsEnums.STORE.publishContainer))
    // eslint-disable-next-line
  }, [container]);

  return (
    <div key={`details-${container.uuid}`}>
      <Row className="task-dates mt-2 align-items-center">
        <Col xs="4">
          <p className="text-muted mb-0">Status</p>
        </Col>
        <Col xs="8" className={"d-flex align-items-center"}>
          <Badge
            color={published ? "success" : "warning"}
            className="font-size-14 font-weight-medium"
          >
            {published ? "Published" : "Private"}
          </Badge>
          <button
            id={`Tooltip-publish-${container.uuid}`}
            disabled={busy || !canPublish}
            onClick={handleChange}
            className={"btn btn-sm ml-2 btn-outline-light"}
          >
            {!busy && (
              <span>{published ? "Unpublish" : "Publish"}</span>
            )}
            {busy && (
              <i className="bx bx-loader bx-spin font-size-16 align-middle" />
            )}
            <Tooltip
              placement={"top"}
              isOpen={tooltipOpen}
              target={`Tooltip-publish-${container.uuid}`}
              toggle={toggleTooltip}
            >
              {published ? "Make it private" : "Publish container"}
            </Tooltip>
          </button>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  publishContainer,
})(ContainerPublishControl);
