import React, {useCallback,useState} from "react";
import {Col, Row} from "reactstrap";
import Container from "reactstrap/lib/Container";
import 'react-multi-email/style.css';
import BaseDialog from "../../../components/Common/BaseDialog";
import axios from "axios";
import {API_URL} from "../../../config";
import {getAxiosDefaultConfig} from "../../../utils";
import toastr from 'toastr'
import {useDropzone} from "react-dropzone";
import {handleError} from "../../../store/core/api";
import SelectContacts from "./SelectContacts";

export const ImportContacts = (props) => {

  const {open, handleClose} = props
  const [busy, setBusy] = useState(false)
  const [contacts, setContacts] = useState([])
  const [uploadedContacts, setUploadedContacts] = useState([])
  const [file,setFile] = useState()

  const addContacts = useCallback(() => {
    setBusy(true)
    axios.post(`${API_URL}/addons/srv/contacts/import`,{to:'',contacts},getAxiosDefaultConfig())
      .then((response) => {
        setBusy(false);
        handleClose(true)
        toastr.success(`Successfully imported contacts`);
      })
      .catch(error => {
        setBusy(false);
        toastr.error(error.message)
      })
    // eslint-disable-next-line
  },[contacts])

  const onDrop = async (selectedFiles) => {
    const url = API_URL + "/addons/srv/contacts/load"
    const axiosConfig = getAxiosDefaultConfig();
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    setFile(selectedFiles[0])
    try {
      setBusy(true);
      const result = await axios
        .post(url, formData, axiosConfig)
        .then((res) => res.data)
        .catch(handleError);

      if (result.contacts) {
        const contactsObj = {}
        result.contacts.forEach((c)=>{
          contactsObj[c.email] = c
        })
        setUploadedContacts(Object.values(contactsObj))
      }

      if (result.errors[0]) {
        toastr.error("The file you uploaded has some errors. Please resolve them and reupload.");
      }
      if (result.warnings[0]) {
        toastr.error("The file you uploaded has some warnings");
      }
      setBusy(false);
    } catch (e) {
      setBusy(false);
      toastr.error(e.message);
    }
  }

  const importContacts = () => {
    addContacts()
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return <BaseDialog
    title={'Import contacts from file'}
    primaryAction={importContacts}
    primaryActionTitle={`Import`}
    isValid={true}
    busy={busy}
    open={open}
    handleClose={()=>handleClose(false)}
    scrollable={true}
  >

  <SelectContacts open={uploadedContacts.length!==0} handleClose={()=>setUploadedContacts([])} contacts={uploadedContacts} setSelectedContacts={setContacts} />

  <Container>

    <Row className='mt-2'>

      <Col xs={12}>
        <div {...getRootProps()} className={`d-flex flex-column justify-content-center align-items-center align-content-center drop-file-box ${isDragActive?'active-drop':''}`}>
          <input {...getInputProps()} accept={'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}/>

          {contacts.length===0&&<>
              <div className='d-flex mb-4'>
                <i className='bx bxs-file-import font-size-42'></i>
              </div>
              <div className='d-flex ml-2'>
                <span className='drop-link'>Choose an excel file</span>&nbsp; or drag it here.
              </div>
          </>}

          {contacts.length!==0&&<>
            <div className='d-flex mb-4'>
              <i className='bx bxs-file font-size-42'></i>
            </div>
            <div className='d-flex mb-4'>
              {file&&file.name}
            </div>
            <div className='d-flex ml-2'>
              {contacts.length!==0&&<> Selected {contacts.length} contacts. </>}
            </div>
          </>}

        </div>

        {/*<Row className='justify-content-center align-items-center align-content-center'>*/}
        {/*  <Col xs='auto' className='font-size-24'>*/}
        {/*    {contacts.length!==0&&<> Selected {contacts.length} contacts. </>}*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Col>

    </Row>
  </Container>


  </BaseDialog>
}

export default ImportContacts
