import React from "react";
import CardSettings from "../../components/Common/card-settings";
import BaseContent from "../../components/Common/BaseContent";

const settings = [
  {
    label: "General",
    route: "/settings/general",
    icon: "bx-cog",
  },
  {
    label: "Content Model",
    route: "/settings/content-model",
    icon: "bx-data",
  },
  {
    label: "Groups",
    route: "/settings/groups",
    icon: "bx-group",
  },
  {
    label: "Users",
    route: "/settings/users",
    icon: "bx-user",
  }, {
    label: "Tags",
    route: "/settings/tags",
    icon: "bx-purchase-tag",
  },
];

const breadcrumbs = [
  { title: "Settings", link: "/settings" }
];

export const Settings = () => {
  return (
    <BaseContent title={"Settings"} breadcrumbs={breadcrumbs}>
      <CardSettings settings={settings} hasNext/>
    </BaseContent>
  );
};
