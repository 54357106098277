import {EntityType} from './entityType';
import {ViewLayouts} from '../../constants/enums';

const MyProfile = {
  firstname: '',
  lastname: '',
  email: '',
  username: ''
}

const Vocabulary = {
  name: '',
  description: '',
  vocUri: '',
}

const Story = {
  coverPage: '',
  description: '',
  storyDetails: {
    slides: [],
  },
  storyType: '',
  title: '',
  uuid: ''
}

const ContainerType = {
  name: '',
  code: '',
  layout: ViewLayouts[0].value,
  containerAcceptance: '',
  datastreamAcceptance: '',
};
const DataStreamType = {
  name: '',
  code: '',
  layout: ViewLayouts[0].value,
  datastreamAcceptance: '',
};
const PropertyType = {};
const Property = {
  propertyKey: '',
  displayName: '',
  propertyType: '',
  propertyEnum: '',
  propertyRefDomain: '',
  description: '',
  mandatory: false,
  editable: true,
  namespace: '',
  qualifiers: ''
};
const Container = {
  uuid: '',
  label: '',
  type: '',
  properties: [],
};

const User = {
  firstname: '',
  lastname: '',
  username: '',
  password: '',
  email: '',
};

const Group = {
  name: '',
  description: '',
  iamPermissions: [],
  users: []
};

const Tag = {
  name: '',
  description: '',
  color: '#000',
  scope: ''
};
const DataStream = {
  label: '',
  type: '',
};

const Namespace = {
  code: '',
  name: '',
  prefix: '',
  url: '',
};

const Qualifier = {
  qualifierKey: '',
  qualifierName: '',
  qualifierType: 'enum',
  qualifierDetails: '',
  description: '',
  externalSource: false
};

const PropertyGroup = {
  description: '',
  name: '',
};

const SystemSetting = {
  description: '',
  editable: true,
  label: '',
  mandatory: true,
  settingDetails: '',
  settingKey: '',
  settingType: '',
  settingValue: '',
}

const SchemaEntity = {
  code: '',
  name: '',
  description: ''
}

const Attribute = {
  code: '',
  name: '',
  description: '',
  attributeType: '',
  attributeDetails: ''
}

const PropertyV2 = {
  attributes: [],
  code: null,
  name: '',
  description: '',
  propertyType: '',
  propertyDetails: null,
  namespace: '',
  tagName: '',
}

const ContainerTypev2 = {
  code: '',
  name: '',
  allowedContainers: [],
  allowedDatastreams: [],
  allowedAtRoot: false,
  viewLayout: 'DEFAULT',
  schemaCode: ''
}

export const Schema = {
  [EntityType.ContainerType]: ContainerType,
  [EntityType.DataStreamType]: DataStreamType,
  [EntityType.PropertyType]: PropertyType,
  [EntityType.Property]: Property,
  [EntityType.Container]: Container,
  [EntityType.User]: User,
  [EntityType.Group]: Group,
  [EntityType.Tag]: Tag,
  [EntityType.DataStream]: DataStream,
  [EntityType.Namespace]: Namespace,
  [EntityType.PropertyGroup]: PropertyGroup,
  [EntityType.Qualifier]: Qualifier,
  [EntityType.SystemSetting]: SystemSetting,
  [EntityType.Story]: Story,
  [EntityType.MyProfile]: MyProfile,
  [EntityType.Vocabulary]: Vocabulary,
  [EntityType.Schema]: SchemaEntity,
  [EntityType.Attribute]: Attribute,
  [EntityType.PropertyV2]: PropertyV2,
  [EntityType.ContainerTypev2]: ContainerTypev2,
};
