import {
    createDeleteMethod,
    createGetAllMethod,
    createGetMethod, createGetSuccessMethod,
    createPostMethod, createPostSuccessMethod,
    createPutMethod,
} from "../core/factory";
import {entityType} from "./module";

export const getSurvey = (params) => createGetMethod(entityType, params)
export const getAllSurveys = () => createGetAllMethod(entityType)
export const createSurvey = (payload) => createPostMethod(entityType, payload)
export const updateSurvey = (payload) => createPutMethod(entityType, payload)
export const deleteSurvey = (payload) => createDeleteMethod(entityType, payload)
export const clearSurveyPost = () => createPostSuccessMethod(entityType, {})
export const clearSurveyGet = () => createGetSuccessMethod(entityType,{})
