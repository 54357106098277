import React, {useEffect, useRef, useState} from 'react';
import BaseContent from '../../../components/Common/BaseContent';
import {Button, CardImg, Col, Container, Input, Label, Nav, NavItem, Row} from "reactstrap";
import classnames from "classnames";
import {Redirect, useParams} from "react-router";
import toastr from "toastr";
import DeleteDialog from "../../Containers/DeleteDialog";
import {assetThumb, getAxiosDefaultConfig} from "../../../utils";
import axios from "axios";
import {API_URL} from "../../../config";
import {Link} from "react-router-dom";
import Activity from "./Activity";
import moment from "moment";
import BaseDialog from "../../../components/Common/BaseDialog";
import SweetAlert from "react-bootstrap-sweetalert";
import {SelectStoryDialog} from "./components/SelectStoryDialog";
import StoryItem from "../Stories/Slideshow/components/StoryItem";

const ScreenEditNew = (props) => {
  const {id, page} = useParams()

  const [changed, setChanged] = useState()
  const [screenData, setScreenData] = useState()
  const [deleted, setDeleted] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState()
  const [activeTab, setActiveTab] = useState()
  const [deleteLoading, setDeleteLoading] = useState()
  const [isLoading, setIsLoading] = useState()
  const [activationCode, setActivationCode] = useState('')
  const [pairingOpen, setPairingOpen] = useState(false)
  const [isLoadingActivation, setIsLoadingActivation] = useState()
  const [editingPage, setEditingPage] = useState()
  const [openUnpairDialog, setOpenUnpairDialog] = useState(false)
  const [screenshotData, setScreenshotData] = useState()
  const [openAssignStory, setOpenAssignStory] = useState()
  const [assignedStory, setAssignedStory] = useState()
  const [thumb, setThumb] = useState()
  //eslint-disable-next-line
  const [storyData, setStoryData] = useState()

  const screenshotInterval = useRef()

  useEffect(() => {
    if (page === 'activity') {
      setActiveTab(2)
    } else {
      setActiveTab(1)
    }
  }, [page])

  const [breadcrumbs, setBreadcrumbs] = useState(
    [
      {title: 'Screens', link: '/screens'},
      {title: 'New', link: '/screens/new'}
    ]
  )
  // Updates breadcrumbs to show /edit or /new links
  const updateBreadcrumbs = (screenData) => {
    let newBreadcrumbs
    if (id) {
      newBreadcrumbs = [
        {title: 'Screens', link: '/screens'},
      ]
    } else {
      newBreadcrumbs = [
        {title: 'Screens', link: '/screens'},
        {title: 'New', link: '/screens/new'}
      ]
    }
    if (screenData && screenData.name !== '') {
      newBreadcrumbs.push({title: `${changed ? '*' : ''}${screenData.name}`, link: id ? `/screens/edit/${id}` : '#'})
    }
    setBreadcrumbs(newBreadcrumbs)
  }

  const getScreen = (id) => {
    setIsLoading(true)

    axios.get(`${API_URL}/str/screens/${id}`, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setScreenData({...screenData, ...data});
        setIsLoading(false);
        setAssignedStory(data.storyId)
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  // Load defaults or load from BE
  useEffect(() => {
    if (id) {
      getScreen(id)
    } else {
      setChanged(true)
      setScreenData({
        name: '',
        description: '',
        token: ''
      })
    }
    // eslint-disable-next-line
  }, [id])

  // Update breadcrumbs appropriately
  useEffect(() => {
    if (screenData && !!screenData.name) {
      updateBreadcrumbs(screenData)
    }
    // eslint-disable-next-line
  }, [screenData, changed,id])

  const createScreen = (payLoad) => {
    const newPayload = {
      name: payLoad.name,
      description: payLoad.description
    }
    setIsLoading(true)
    axios.post(`${API_URL}/str/screens`, newPayload, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setScreenData(data);
        setEditingPage(true);
        setIsLoading(false);
        setChanged(false)
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  const updateScreen = (payLoad) => {
    const updatePayload = {
      name: payLoad.name,
      description: payLoad.description
    }
    setIsLoading(true)
    axios.put(`${API_URL}/str/screens/${id}`, updatePayload, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setScreenData({...screenData, ...data});
        setIsLoading(false);
        setChanged(false)
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  const saveScreen = () => {
    if (!id) {
      createScreen(screenData)
    } else {
      updateScreen(screenData)
    }
  }

  const handleDelete = () => {

    setDeleteLoading(true)
    axios.delete(`${API_URL}/str/screens/${id}`, getAxiosDefaultConfig())
      .then((response) => {
        setDeleteLoading(false);
        setDeleted(true)
        toastr.success(`Successfully deleted screen`);
      })
      .catch(error => {
        setDeleteLoading(false);
        toastr.error(error.message)
      })
  }

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const setName = (e) => {
    const value = e.target.value
    setScreenData({
      ...screenData,
      name: value
    })
    setChanged(true)
  }

  const setDescription = (e) => {
    const value = e.target.value
    setScreenData({
      ...screenData,
      description: value
    })
    setChanged(true)
  }

  const changeActivationCode = (e) => {
    const value = e.target.value
    setActivationCode(value)
  }

  const handleClose = () => {
    setPairingOpen(false)
    setActivationCode('')
  }

  const handleCloseAssignStory = (refresh) => {
    if (refresh) {
      getStory()
    }
    setOpenAssignStory(false)
  }

  const reqCommand = (command) => {
    getDelayedStatus()
    setIsLoading(true)
    axios.post(`${API_URL}/str/screens/${id}/commands`, {
      type: command
    }, getAxiosDefaultConfig())
      .then((response) => {
        setIsLoading(false)
        getScreen(screenData.id)
      })
      .catch(error => {
        setIsLoading(false)
        if (error.response) {
          console.log(`An error occurred:\n${error.response.data}`);
        }
      })
  }

  const doPair = () => {
    setIsLoading(true)
    setIsLoadingActivation(true)

    axios.post(`${API_URL}/str/screens/${id}/pair`, {
      activationCode
    }, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setScreenData(data)
        setIsLoading(false)
        setIsLoadingActivation(false)
        setPairingOpen(false)
        setActivationCode('')
        setAssignedStory(data.storyId)
        getDelayedStatus()
      })
      .catch(error => {
        setIsLoading(false)
        setPairingOpen(false)
        setIsLoadingActivation(false)

        if (error.response) {
          console.log(`An error occurred:\n${error.response.data}`);
        }
      })
  }

  const getDelayedStatus = () => {
    setTimeout(getStatus, 2000)
  }

  const getStatus = (closeAssign) => {
    axios.get(`${API_URL}/str/screens/${id}/status`, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        const {screenshot} = data
        setScreenshotData(screenshot ? `data:image/bmp;base64,${data.screenshot}` : ``)
        setScreenData((f) => ({...f, ...data}))
        setAssignedStory(data.storyId)
        setThumb((f) => (data.storyId ? f : null))
        if (closeAssign) setOpenAssignStory(false)
      })
      .catch(error => {
        if (error.response) {
          console.log(`An error occurred:\n${error.response.data}`);
        }
      })
  }

  const getStory = () => {
    axios.get(`${API_URL}/str/screens/${id}/story`, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response
        setStoryData(data)
        getDelayedStatus()
      })
      .catch(error => {
        if (error.response) {
          console.log(`An error occurred:\n${error.response.data}`)
        }
      })
  }

  const handlePush = () => {
    // if (!screenData.storyId) {
    //   reqCommand('DELETE')
    // } else {
    setOpenAssignStory(true)
    // }
  }

  const handleRemoveStory = () => {
    setIsLoading(true)
    axios.post(`${API_URL}/str/screens/${id}/unlink`, {}, getAxiosDefaultConfig())
      .then((response) => {
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (assignedStory) {
      getStory()
    }
    // eslint-disable-next-line
  }, [assignedStory])

  useEffect(() => {
    if (storyData) {
      assetThumb(storyData.coverPage, setThumb,'stories')
    }
  }, [storyData])

  useEffect(() => {
    clearInterval(screenshotInterval.current)
    screenshotInterval.current = setInterval(getStatus, 5000)
    return () => {
      clearInterval(screenshotInterval.current)
    }
    // eslint-disable-next-line
  }, [])

  const isPaired = screenData && screenData.token

  return (
    <BaseContent
      // isLoading={isLoading}
      breadcrumbs={breadcrumbs}
      renderActions={() => {
        return <Row style={{minHeight: 73}}>
          {screenData && <Col xs={12} style={{textAlign: 'right'}}>
            {!isPaired && <Button color="info" onClick={() => {
              setPairingOpen(true)
            }}>Pair screen</Button>}
            {isPaired && <>
              <Button color="danger" onClick={() => setOpenUnpairDialog(true)}>Unpair</Button>
              <div
                style={{display: 'inline', height: '100px', borderLeft: '2px solid #cdcdcd', margin: '0 10px'}}> </div>
              <Button color="warning" onClick={handleRemoveStory} className='mr-2' disabled={!screenData.storyId}>Remove
                story </Button>
              <Button color="success" onClick={handlePush}>Push story</Button>
            </>}
          </Col>}
        </Row>
      }}
    >
      {editingPage && <Redirect to={`/screens/edit/${screenData.id}`}/>}

      {openAssignStory && <SelectStoryDialog open={openAssignStory} handleClose={handleCloseAssignStory}/>}

      {pairingOpen && <BaseDialog
        title={'Pair screen'}
        primaryAction={doPair}
        primaryActionTitle={'Pair'}
        isValid={activationCode !== '' && activationCode.length > 5}
        busy={isLoadingActivation}
        open={pairingOpen}
        handleClose={handleClose}
        scrollable={true}
        // contents={contents}
      >

        <Row xs={12}>
          <Col xs={12}>
            <Label for={`pin`}>PIN number</Label>
            <Input type="text" name={`pin`} id={`pin`}
                   placeholder={`e.g. 132134`}
                   value={activationCode}
                   onChange={changeActivationCode}
            />
          </Col>
        </Row>

      </BaseDialog>}
      {(isLoading || deleteLoading) && <div className='custom-loading-overlay'></div>}

      {deleted && <Redirect to={`/screens`}/>}

      <DeleteDialog open={openDeleteDialog} handleClose={closeDeleteDialog} handleDelete={handleDelete}/>

      {openUnpairDialog && <SweetAlert
        title={"Unpair confirmation"}
        warning
        onConfirm={() => {
          reqCommand('UNPAIR')
          setOpenUnpairDialog(false)
        }}
        onCancel={() => setOpenUnpairDialog(false)}
      >
        Are you sure you wish to unpair this screen?
      </SweetAlert>}

      <Row className={'justify-content-center survey-nav'} style={{marginBottom: 16}}>
        <Col>
          <div>
            <Nav tabs style={{display: 'block'}}>
              <NavItem>
                <Link to={`/screens/edit/${id}`}
                      className={classnames({active: activeTab === 1, 'nav-link': true})}> Screen </Link>
              </NavItem>
              <NavItem>
                <Link to={!!id && 'new' && `/screens/edit/${id}/activity`} className={classnames({
                  active: activeTab === 2,
                  'nav-link': true,
                  'link-disabled': !id
                })}> Activity </Link>
              </NavItem>
            </Nav>
          </div>
        </Col>
      </Row>

      <Container style={{paddingTop: 30, minHeight: '60vh'}}>

        {activeTab === 1 && screenData && <Container>
          <Row>
            <Col xs={6}>
              <div style={{background: screenData.token && screenshotData ? 'none' : '#cdcdcd', minHeight: 300}}>

                {screenData.token && screenshotData ? <div style={{position: 'relative'}}>
                    <CardImg width="100%" height='auto' src={screenshotData} alt="Screen preview"/>
                    <div style={{position: 'absolute', right: 16, bottom: 24}}>
                      <Button color="info" onClick={() => reqCommand('SCREENSHOT')} disabled={!screenData.token}>
                        <i className={`bx bx-refresh font-size-24 ${isLoading ? 'bx-spin' : ''}`}> </i>
                      </Button>
                    </div>
                  </div>
                  : <>
                    <div style={{minHeight: 270, position: 'relative'}}
                         className='d-flex flex-column justify-content-center align-items-center align-content-center'>

                      <div className='d-flex justify-content-center align-items-center align-content-center'
                           style={{fontSize: 62}}>
                        <i className='bx bx-image'></i> <br/>
                      </div>
                      <div className='d-flex justify-content-center align-items-center align-content-center'>
                        No image available
                      </div>
                    </div>

                  </>}

              </div>

              {/*<div className='d-flex justify-content-end align-items-end align-content-center mt-2'>*/}
              {/*  /!*<div className='d-flex mr-1'>*!/*/}
              {/*  /!*  <Button color="primary" onClick={()=>reqCommand('PLAYSTOP')} disabled={!screenData.token}>Play/Stop</Button>*!/*/}
              {/*  /!*</div>*!/*/}

              {/*  <div className='d-flex'>*/}
              {/*    <Button color="info" onClick={()=>reqCommand('IDENTIFY')} disabled={!screenData.token}>Identify</Button>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div style={{marginTop: 8}}>
                <Col xs={12} style={{marginTop: 16}}>
                  <Row xs={12}>
                    <h4>
                      {screenData.token && <span className="badge badge-success">PAIRED</span>}
                      {!screenData.token && <span className="badge badge-info">NOT PAIRED</span>}
                    </h4>
                  </Row>
                </Col>
                Last seen
                at: {screenData.lastSeenAt ? moment(screenData.lastSeenAt).format("DD/MM/YYYY, h:mm a") : ' -'}
              </div>

            </Col>

            <Col xs={6}>
              <Row xs={12}>
                <Col xs={12}>
                  <Label for={`name`}>Screen name <b>*</b></Label>
                  <Input type="text" name={`name`} id={`name`}
                         placeholder={`Screen name`}
                         value={screenData.name}
                         onChange={setName}
                  />
                </Col>
              </Row>
              <Row xs={12} style={{marginTop: 8}}>
                <Col xs={12}>
                  <Label for={`description`}>Screen description <b>*</b></Label>
                  <Input type="textarea" description={`description`} id={`description`}
                         placeholder={`Screen description`}
                         value={screenData.description}
                         onChange={setDescription}
                  />
                </Col>
              </Row>

              {id && isPaired && <Row xs={12} style={{marginTop: 16}}>
                <Col xs={12}>
                  <h6>Assigned story </h6>
                  <div
                    className='d-flex justify-content-center align-items-center align-content-center flex-column mt-4'>
                    {/*<div*/}
                    {/*  sm={'auto'} className={`c-item storyBox no-cursor`} style={{background:`#434343 url(${thumb}) no-repeat center center`}}*/}
                    {/*  // onClick={()=>setOpenAssignStory(true)}*/}
                    {/*>*/}
                    {/*  <Container>*/}
                    {/*    <div className={`row d-flex ${screenData.storyId?'mt-2 ml-2':'justify-content-center align-items-center align-content-center'} flex-column`} style={{height:'200px'}}>*/}

                    {/*        <div className={'storyTitle d-flex'}>*/}
                    {/*          {(storyData&&storyData.title&&screenData.storyId) ? <>*/}
                    {/*          {storyData.title}*/}
                    {/*          </>:<>*/}
                    {/*            {!screenData.storyId&&<>No story</>}*/}
                    {/*          </>}*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*  </Container>*/}
                    {/*</div>*/}
                    {/*<div className='d-flex mt-2'>*/}
                    {/*  <Button color="info" onClick={()=>setOpenAssignStory(true)}>*/}
                    {/*    {screenData.storyId?'Change story':'Assign story'}*/}
                    {/*  </Button>*/}
                    {/*</div>*/}
                    {storyData && <StoryItem story={storyData} thumb={thumb}/>}
                  </div>
                </Col>
              </Row>}

            </Col>


          </Row>
        </Container>}

        {activeTab === 2 && <Activity/>}

      </Container>
      {id && <Button
        title={'Delete screen'}
        color={'danger'}
        onClick={() => setOpenDeleteDialog(true)}
        className={
          'btn-radius d-flex align-items-center justify-content-center shadow-lg'
        }
        style={{
          position: 'fixed',
          margin: 16,
          bottom: 90,
          right: 16,
          borderRadius: 50,
          width: 40,
          height: 40,
          zIndex: 1
        }}
      >
        <i className='bx bxs-trash font-size-16'></i>
      </Button>}
      <Button
        title={!changed ? 'No changes to save' : 'Save changes'}
        color={'primary'}
        onClick={saveScreen}
        className={
          'btn-radius d-flex align-items-center justify-content-center shadow-md'
        }
        style={{
          position: 'fixed',
          margin: 16,
          bottom: 16,
          right: 16,
          borderRadius: 50,
          width: 60,
          height: 60,
          zIndex: 1
        }}
        disabled={!changed}
      >
        <i className='bx bxs-save font-size-24'></i>
      </Button>
    </BaseContent>
  );
}

export default ScreenEditNew
