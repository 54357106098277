import React from 'react';
import {withRouter} from "react-router-dom";

const ShortAnswer = (props) => {
  const {field, selected, answerable} = props
  const {title,description} = field

  if ((!selected) || answerable) return <>
    <div style={{fontSize: 18, marginTop: -8, marginBottom: 8, opacity: 1}}>{title}</div>
    <div style={{fontSize: 12, marginTop: -8, marginBottom: 8, opacity: 0.8}}>{description}</div>
  </>

  return <>

    </>
}

export default withRouter(ShortAnswer);
