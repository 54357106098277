import React, {useState} from 'react'
import {motion} from 'framer-motion'

const svgVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
}

const slideBox ={
  hide:{
    x:0
  },
  show:{
    x:[0,300],
    transition:{
      delay:1,
      duration:2,
      ease:'easeIn',
      yoyo:10
    }
  }
}

const SlideShowAnimateSVG = (props) => {
  const [hovered, setHovered] = useState(false)
  return <motion.div layout style={{width: '100%', height: '180px'}}>
    <motion.svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={'100%'} height={'100%'}
                viewBox="0 0 300 166.9"
                variants={svgVariants}
                initial={'hidden'}
                animate={'visible'}
                onHoverStart={() => setHovered(true)}
                onHoverEnd={() => setHovered(false)}
    >
      <motion.g variants={slideBox} animate={hovered?'show':'hide'} id="Layer_2">
        <rect x="18.71" y="8.34" className="st0" width="260.65" height="137.37"/>
      </motion.g>
      <g id="Layer_1">
        <circle className="st0" cx="140.47" cy="162.19" r="2.65"/>
        <circle className="st0" cx="149.18" cy="162.19" r="2.65"/>
        <circle className="st0" cx="157.9" cy="162.19" r="2.65"/>
      </g>
      <motion.g  variants={slideBox} animate={hovered ? 'show' : 'hide'} id ="Layer_3">
        <path fillOpacity={1} className="st1" d="M123.66,127.61H39.25c-6.6,0-12-5.4-12-12V27.38c0-6.6,5.4-12,12-12h84.41c6.6,0,12,5.4,12,12v88.23
		C135.66,122.21,130.26,127.61,123.66,127.61z"/>
        <path fillOpacity={1} className="st1" d="M233.8,47.55h-81.55c-1.24,0-2.25-1.01-2.25-2.25v0c0-1.24,1.01-2.25,2.25-2.25h81.55
		c1.24,0,2.25,1.01,2.25,2.25v0C236.06,46.54,235.04,47.55,233.8,47.55z"/>
        <path fillOpacity={1} className="st1" d="M268.05,57.07H150.48c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C268.52,56.85,268.31,57.07,268.05,57.07z"/>
        <path fillOpacity={1} className="st1" d="M268.05,61.95H150.48c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C268.52,61.73,268.31,61.95,268.05,61.95z"/>
        <path fillOpacity={1} className="st1" d="M268.05,66.83H150.48c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C268.52,66.61,268.31,66.83,268.05,66.83z"/>
        <path fillOpacity={1} className="st1" d="M268.05,71.71H150.48c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C268.52,71.49,268.31,71.71,268.05,71.71z"/>
        <path fillOpacity={1} className="st1" d="M268.05,76.59H150.48c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C268.52,76.37,268.31,76.59,268.05,76.59z"/>
        <path fillOpacity={1} className="st1" d="M268.05,81.47H150.48c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C268.52,81.25,268.31,81.47,268.05,81.47z"/>
        <path fillOpacity={1} className="st1" d="M268.05,86.35H150.48c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C268.52,86.13,268.31,86.35,268.05,86.35z"/>
        <path fillOpacity={1} className="st1" d="M268.05,91.23H150.48c-0.26,0-0.48-0.22-0.48-0.48v-2.52c0-0.26,0.22-0.48,0.48-0.48h117.57
		c0.26,0,0.48,0.22,0.48,0.48v2.52C268.52,91.01,268.31,91.23,268.05,91.23z"/>
      </motion.g>
      <g id="arrows">
        <polyline className="st1" points="285.41,72.26 293.32,80.18 285.16,88.34 	"/>
        <polyline className="st1" points="12.66,92.23 4.74,84.31 12.91,76.14 	"/>
      </g>
    </motion.svg>
  </motion.div>
}

export default SlideShowAnimateSVG