import React, {useState, useEffect} from 'react'
import {Progress, Row, Col,Container,Tooltip  } from 'reactstrap'
import {useFetch, useToggle} from '../../helpers/hooks'
import {ApiEndpoint} from '../../store/core/endpoint'
import {EntityType} from '../../store/core/entityType'
import {bytesToSize} from '../../utils'

const StorageInfo = (props) => {
  const {open} = props
  const storageInfoUrl = ApiEndpoint[EntityType.StorageInformation]
  const [, storageInfo]  = useFetch(storageInfoUrl,[])
  const [openTooltip, handleToggle] = useToggle();
  const sessionUsage = sessionStorage.getItem('usage')
  const sessionTotalSpace = sessionStorage.getItem('totalSpace')
  const sessionProgress = sessionStorage.getItem('percentage')

  const [progressValue,setProgressValue] = useState(sessionProgress?sessionProgress:0)
  const [totalSpace,setTotalSpace] = useState(sessionTotalSpace?sessionTotalSpace:0)
  const [usageValue,setUsageValue] = useState(sessionUsage?sessionUsage:0)
  const [statusColor,setStatusColor] = useState('primary')



  useEffect(()=>{
    if (sessionProgress)
    {
      setUsageValue(sessionUsage)
      setTotalSpace(sessionTotalSpace)
      setProgressValue(sessionProgress)
    }
    if (storageInfo.freeSpace)
    {
      const difference = storageInfo.totalSpace - storageInfo.freeSpace

      if (sessionUsage !== bytesToSize(difference))
        setUsageValue(bytesToSize(difference))

      if (sessionTotalSpace !== bytesToSize(storageInfo.totalSpace))
        setTotalSpace(bytesToSize(storageInfo.totalSpace))

      const percentage = (difference/storageInfo.totalSpace) * 100

      if (percentage >=80 && percentage <90)
        setStatusColor('warning')
      else if (percentage >=90)
        setStatusColor('danger')
      else setStatusColor('primary')

      if (sessionProgress !== percentage)
        setProgressValue(percentage)

      sessionStorage.setItem('percentage',percentage)
      sessionStorage.setItem('totalSpace',bytesToSize(storageInfo.totalSpace))
      sessionStorage.setItem('usage',bytesToSize(difference))
    }
  },[storageInfo, sessionProgress,sessionTotalSpace,sessionUsage])

  return <>
    {open&&
    <Container className={'mb-4'}>
      <Row className={'ml-1 mt-2 mb-2'}>
        <Col lg = {12} className='mb-2'>
          <p className="text-muted mb-0 font-size-10 font-weight-medium">{`${usageValue} of ${totalSpace} used`}</p>
        </Col>
        <Col lg={11}>
          <Progress color={statusColor} value={progressValue} id="storageProgress" tag={'storage-progress'}/>
          <Tooltip placement="right" isOpen={openTooltip} target="storageProgress" toggle={handleToggle}>
            {storageInfo && storageInfo.storageHome}
          </Tooltip>
        </Col>
      </Row>
    </Container>
    }
  </>
}
export default StorageInfo
