import React, {useEffect, useState} from 'react'
import {googleFonts, googleFontsWeights} from '../../../../../../constants/enums'
import {Button, Col, FormGroup, Input, Row} from 'reactstrap'
import {ClickAwayListener, Divider} from '@material-ui/core'
import {SketchPicker} from 'react-color'
import Select from 'react-select'

const TextElementOptions = (props) => {
  const {selectedElement,setSelectedElement, slides, selectedSlide, handleChange} = props
  const [elementText, setElementText] = useState('')
  const [fontSize, setFontSize] = useState()
  const [textAlign, setTextAlign] = useState()
  const [textDecoration,setTextDecoration] = useState()
  const [fontStyle,setFontStyle] = useState()
  const [fontColor,setFontColor] = useState(null)
  const [fontFamily,setFontFamily] = useState(null)
  const [fontWeight,setFontWeight] = useState({label:null,value:null})
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [rotation,setRotation] = useState(0)
  const [optionsFontWeights,setOptionsFontWeights] = useState([])

  useEffect(() => {

    setElementText(selectedElement?.data?.text)
    setFontSize(selectedElement?.data?.fontSize)
    setFontWeight({label:selectedElement?.data?.fontWeight,value:selectedElement?.data?.fontWeight})
    setTextAlign(selectedElement?.data?.textAlign)
    setFontColor(selectedElement?.data?.color)
    setFontStyle(selectedElement?.data?.fontStyle)
    setTextDecoration(selectedElement?.data?.textDecoration)
    const selectedFont = googleFonts.filter((googleFont)=>googleFont.value === selectedElement?.data?.fontFamily)
    if (selectedFont[0])
      setFontFamily(selectedFont[0])
    //eslint-disable-next-line
  }, [selectedElement.id])


  useEffect(()=>{
    if (fontFamily) {
      const weightsFound =  googleFontsWeights.find((googleFont) => googleFont.font === fontFamily.label)
      const weightsOptions = []
        weightsFound.weights.forEach((val)=>{
          weightsOptions.push({label:val,value:val})
        })
      const initWeight = weightsOptions.find((val)=>val.label === fontWeight.label)
      if (initWeight)
        setFontWeight(initWeight)
      else
        setFontWeight(weightsOptions[0])

      setOptionsFontWeights(weightsOptions)
    }
    //eslint-disable-next-line
  },[fontFamily])

  const handleChangeText = (e) => {
    setElementText(e.target.value)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))

    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.text = e.target.value

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const increaseRotation = () => {
    let increasedRotation = 0
    if (rotation >= 315) {
      increasedRotation = 0
      setRotation(increasedRotation)
    }
    else
    {
      increasedRotation = rotation + 45
      setRotation(increasedRotation)
    }
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].rotation = increasedRotation
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }
  const decreaseRotation = () => {
    let decreasedRotation = 0
    if (rotation <= -315)
    {
      decreasedRotation = 0
      setRotation(decreasedRotation)
    }
    else
    {
      decreasedRotation = rotation - 45
      setRotation(decreasedRotation)
    }

    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].rotation = decreasedRotation
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeFontSize = (e) => {
    setFontSize(e.target.value)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.fontSize = parseInt(e.target.value)

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeFontColor = (val) => {
    setFontColor(val.hex)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.color = val.hex
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeTextAlignment = (align) => {
    setTextAlign(align)
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]

    elementsTmp[elementIndex].data.textAlign = align

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeFontStyle = (style) => {

    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]

    if (elementsTmp[elementIndex].data.fontStyle !== style) {
      elementsTmp[elementIndex].data.fontStyle = style
      setFontStyle(style)
    }
    else
    {
      elementsTmp[elementIndex].data.fontStyle = 'normal'
      setFontStyle('normal')
    }

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeTextDecoration = (decoration) => {

    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]

    if (elementsTmp[elementIndex].data.textDecoration !== decoration) {
      elementsTmp[elementIndex].data.textDecoration = decoration
      setTextDecoration(decoration)
    }
    else
    {
      elementsTmp[elementIndex].data.textDecoration = 'none'
      setTextDecoration('none')
    }

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }


  const handleChangeFontWeight = (e) => {
    const weight = e.value
    setFontWeight({label:e.label,value:e.value})
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.fontWeight = weight

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleChangeFontFamily = (family) => {
    const selectedFont = googleFonts.filter((googleFont)=>googleFont.value === family.value)
    if (selectedFont[0])
      setFontFamily(selectedFont[0])
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementIndex = slidesTmp[slideIndex].elements.findIndex((element) => element.id === selectedElement.id)
    const elementsTmp = [...slidesTmp[slideIndex].elements]
    elementsTmp[elementIndex].data.fontFamily = family.value

    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
  }

  const handleDeleteElement = () => {
    const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    const elementsTmp = slidesTmp[slideIndex].elements.filter((element) => element.id !== selectedElement.id)
    slidesTmp[slideIndex] = {...slidesTmp[slideIndex], elements: elementsTmp}
    handleChange(slidesTmp)
    setSelectedElement({})
  }

  return <>
    <Col lg={12}>
      <div className='font-size-13 font-weight-bold mb-2'>Text</div>
    </Col>
    <Col lg={12}>
      <Input type={'textarea'} value={elementText} onChange={handleChangeText}/>
    </Col>
    <Col lg={12}>
      <div className='font-size-13 font-weight-bold mt-2 mb-2'>Font Family</div>
    </Col>
    <Col lg={12}>
      <FormGroup>
        <Select
          value={fontFamily}
          onChange={handleChangeFontFamily}
          options={googleFonts}
          placeholder="Select Font Family"
        />
      </FormGroup>
    </Col>
    <Col lg={4}>
      <Row className='align-items-center'>
        <Col lg={12}>
          <div className='font-size-13 font-weight-bold mt-2 mb-2'>Text Color</div>
        </Col>
        <Col lg={12}>
          <div className='slidePickColor' style={{backgroundColor: fontColor}}
               onClick={() => setShowColorPicker(!showColorPicker)}/>
          {showColorPicker &&
          <ClickAwayListener onClickAway={() => setShowColorPicker(!showColorPicker)}>
            <SketchPicker
              color={fontColor}
              disableAlpha={true}
              onChangeComplete={handleChangeFontColor}
            />
          </ClickAwayListener>
          }
        </Col>
      </Row>
    </Col>
    <Col lg={4}>
      <Row className='align-items-center'>
        <Col lg={12}>
          <div className='font-size-13 font-weight-bold mt-2 mb-2'>Font Size</div>
        </Col>
        <Col lg={10}>
          <Input type={'number'} value={fontSize} onChange={handleChangeFontSize}/>
        </Col>
      </Row>
    </Col>
    <Col lg={4}>
      <Row className='align-items-center'>
        <Col lg={12}>
          <div className='font-size-13 font-weight-bold mt-2 mb-2'>Font Weight</div>
        </Col>
        <Col lg={10}>
          <Select
            value={fontWeight}
            onChange={handleChangeFontWeight}
            options={optionsFontWeights}
            placeholder="Select Font Family"
          />
        </Col>
      </Row>
    </Col>
    <Col lg={6}>
      <div className='font-size-13 font-weight-bold mt-2 mb-2'>Text Alignment</div>
    </Col>
    <Col lg={6}>
      <div className='font-size-13 font-weight-bold mt-2 mb-2'>Text Decoration</div>
    </Col>
    <Col lg={6} className={'mt-2'}>
      <Row>
        <Col lg={3} onClick={() => handleChangeTextAlignment('left')}>
          <i
            className={`bx bx-align-left font-size-18 ${textAlign === 'left' ? 'textAlignmentButtonSelected' : 'textAlignmentButton'}`}/>
        </Col>
        <Col lg={3} onClick={() => handleChangeTextAlignment('center')}>
          <i
            className={`bx bx-align-middle font-size-18 ${textAlign === 'center' ? 'textAlignmentButtonSelected' : 'textAlignmentButton'}`}/>
        </Col>
        <Col lg={3} onClick={() => handleChangeTextAlignment('right')}>
          <i
            className={`bx bx-align-right font-size-18 ${textAlign === 'right' ? 'textAlignmentButtonSelected' : 'textAlignmentButton'}`}/>
        </Col>
        <Col lg={3} onClick={() => handleChangeTextAlignment('justify')}>
          <i
            className={`bx bx-align-justify font-size-18 ${textAlign === 'justify' ? 'textAlignmentButtonSelected' : 'textAlignmentButton'}`}/>
        </Col>
      </Row>
    </Col>
    <Col lg={4} className='mt-2'>
      <Row>
        <Col lg={4} onClick={()=>handleChangeFontStyle('italic')}>
          <i
            className={`bx bx-italic font-size-18 ${fontStyle === 'italic' ? 'textDecorationButtonSelected' : 'textDecorationButton'}`}/>
        </Col>
        <Col lg={4} onClick={()=>handleChangeTextDecoration('underline')}>
          <i
            className={`bx bx-underline font-size-18 ${textDecoration === 'underline' ? 'textDecorationButtonSelected' : 'textDecorationButton'}`}/>
        </Col>
      </Row>
    </Col>
    <Divider className='mt-1 mb-1'/>
    <Col lg={12} className={'mt-2'}>
      <div className='font-size-13 font-weight-bold mb-2'>Rotate</div>
    </Col>
    <div className='d-flex'>
      <Col lg={'auto'} className={'mr-2'}>
        <Button color='secondary' onClick={decreaseRotation}>
          <i className='bx bx-rotate-left'/>
        </Button>
      </Col>
      <Col lg={'auto'} onClick={increaseRotation}>
        <Button color='secondary'>
          <i className='bx bx-rotate-right'/>
        </Button>
      </Col>
    </div>
    <Col lg={12} className='mt-4'>
      <i className='bx bx-trash warning-color font-size-24 mdi-cursor-pointer' onClick={handleDeleteElement}/>
    </Col>
  </>
}

export default TextElementOptions
