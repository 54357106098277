import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'reactstrap'
import {getAxiosDefaultConfig} from "../../../utils";
import axios from "axios";
import {API_URL} from "../../../config";
import {useParams} from "react-router";
import AnswerRenderer from "./AnswerRenderer";
import BusyIndicator from "../../../components/Common/BusyIndicator";
import {ANSWER_COMPONENTS} from "./consts";
import NoResponses from "../responses/NoResponses";

const Summary = () =>{

  const {uuid} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [summary, setSummary] = useState()

  const getSummary = () => {

    setIsLoading(true)
    axios.get(`${API_URL}/addons/srv/surveys/${uuid}/summary`,getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setSummary(data)
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  const [isVisible, setIsVisible] = useState();
  const onVisibilityChange = () => setIsVisible(document.hidden);

  useEffect(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  });

  useEffect(()=>{
    if (!isVisible) {
      getSummary();
    }
    // eslint-disable-next-line
  },[isVisible])

  useEffect(()=>{
    getSummary()
    // eslint-disable-next-line
  },[])

  const createMarkup = (htmlcode) => {
    return {__html: htmlcode}
  }

  return  <Container>
    <Row>
      <Col xs={12}>

        {isLoading&&<Row style={{minHeight:350, position: 'relative'}} className='d-flex justify-content-center align-items-center align-content-center'>

          <div className='d-flex'>
            <BusyIndicator busy={isLoading}/>
          </div>

        </Row>}

        {!isLoading&&summary&&summary.totalResponseCount!==0&&<>

          {summary.sections.map((section,sectionIndex)=>{
            const sectionFields = summary.totalResponseSummary.filter((f)=>section.questions.includes(f.questionId))

            return <>
              {section.title&&<Container style={{marginBottom: 16, marginTop: 32}}>
                <h4>{section.title}</h4>
                {section.description!==''&&<h6 style={{whiteSpace: "pre-wrap"}}>
                  <div dangerouslySetInnerHTML={createMarkup(section.description)} />
                </h6>}
              </Container>}

              {!section.title&&<br/>}

              {sectionFields.map((response,sectionFieldIndex)=>{
                if (!ANSWER_COMPONENTS[{...response}.questionType]) return <> </>

                return <div className="card-body card-answer">
                  <AnswerRenderer field={response} fieldNumber={sectionFieldIndex} />
                </div>
              })}

              </>
          })}

        </>}

        {!isLoading&&summary&&summary.totalResponseCount===0&&<NoResponses />}

      </Col>
    </Row>
  </Container>
}

export default Summary
