import React   from 'react';
import WebsiteItem from "./components/listing/WebsiteItem";
import {Row} from "reactstrap";
import WebsiteNewItem from "./components/listing/WebsiteNewItem";
const WebsitesList = ({result, isLoading}) => {

  return <>
      {!isLoading&&result&&<Row xs={12} md={3}>
        {result.map((website,i)=><div className='c-item-wrapper'><WebsiteItem website={website} /></div>)}
        <div className='c-item-wrapper'><WebsiteNewItem /></div>
      </Row>}
  </>

};

export default WebsitesList;
