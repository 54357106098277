import React from 'react';
import {withRouter} from "react-router-dom";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useEffect, useState} from "react";

const LinearScale = (props) => {
  const {field, setField, selected, answerable, formDisabled, fieldNumber} = props
  const {title, index, details, required} = field
  const {from, to, fromLabel, toLabel} = details

  const allOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [fromList, setFromList] = useState(allOptions)
  const [toList, setToList] = useState(allOptions)

  useEffect(() => {
    setToList([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].splice(parseInt(from) + 1))
    setFromList([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].splice(0, parseInt(to)))
  }, [from, to])

  const changeFrom = (e) => {
    const value = e.target.value
    setField({
      ...field,
      details: {
        ...details,
        from: parseInt(value)
      }
    })
  }

  const changeTo = (e) => {
    const value = e.target.value
    setField({
      ...field,
      details: {
        ...details,
        to: parseInt(value)
      }
    })
  }

  const changeToLabel = (e) => {
    const value = e.target.value
    setField({
      ...field,
      details: {
        ...details,
        toLabel: value
      }
    })
  }

  const changeFromLabel = (e) => {
    const value = e.target.value
    setField({
      ...field,
      details: {
        ...details,
        fromLabel: value
      }
    })
  }

  const setValue = (e) => {
    if (formDisabled) return
    const value = parseInt(e.target.value)
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable&&required&&value===''
      },
      value
    })
  }

  const checkErrors = (e) => {
    if (!answerable) return
    if (required) {
      if (field.value===''||field.value===null) {
        setField({
          ...field,
          errors: {
            required: true
          }
        })
      }
    }
  }

  const requiredError = field.errors&&field.errors.required
  const hasError = requiredError


  if ((!selected) || answerable) return <>

    <legend className="col-form-label" style={{fontWeight: 500}}>{fieldNumber+1}. {title} {required&&<b>*</b>}</legend>

    <Row xs={12} className={'justify-content-between'}>
      <Col style={{textAlign: 'left', wordBreak: "break-all"}}>
        {fromLabel}
      </Col>
      <Col xs={8}>
        <FormGroup tag="fieldset" row className={'justify-content-center'}>

          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].splice(from, to - from + 1).map((option, i) => <Col key={i} style={{padding:0}}><FormGroup
            check>
            <Label check>
              <Input type="radio" name={`linearScale-${index}`}
                     disabled={formDisabled}
                     onChange={setValue}
                     onBlur={checkErrors}
                     invalid={hasError}
                     checked={option===field.value}
                     value={option}
              />{' '}
              <span style={{marginTop:2, display: 'inline-block'}}>{option}</span>
            </Label>
          </FormGroup> </Col>)}

        </FormGroup>
      </Col>
      <Col style={{textAlign: 'right', wordBreak: "break-all"}}>
        {toLabel}
      </Col>
    </Row>

  </>

  return <>

    <FormGroup row>

      <Col xs={12} sm={2} style={{marginBottom: 8}}>
        <Label for={`linear-scale-from-${index}`}> From </Label>
        <Input type="select" name={`linear-scale-from-${index}`} id={`linear-scale-from-${index}`} value={from}
               onChange={changeFrom}>
          {fromList.map((option, i) => <option value={option} key={i}>{option}</option>)}
        </Input>
      </Col>

      <Col xs={12} sm={2} style={{marginBottom: 8}}>
        <Label for={`linear-scale-to-${index}`}> To </Label>
        <Input type="select" name={`linear-scale-to-${index}`} id={`linear-scale-to-${index}`} value={to}
               onChange={changeTo} disabled={formDisabled}>
          {toList.map((option, i) => <option value={option} key={i}>{option}</option>)}
        </Input>
      </Col>

      <Col xs={12} sm={{size: 6, offset: 2}} style={{marginBottom: 8}}>
        <Label for={`linear-scale-from-label-${index}`}> From Label</Label>
        <Input type="text" name={`linear-scale-from-${index}`} id={`linear-scale-from-${index}`} value={fromLabel}
               onChange={changeFromLabel} disabled={formDisabled} />
      </Col>

      <Col xs={12} sm={{size: 6, offset: 6}} style={{marginBottom: 8}}>
        <Label for={`linear-scale-to-label-${index}`}> To Label</Label>
        <Input type="text" name={`linear-scale-to-label-${index}`} id={`linear-scale-to-label-${index}`} value={toLabel}
               onChange={changeToLabel} disabled={formDisabled}/>
      </Col>

    </FormGroup>

  </>
}

export default withRouter(LinearScale);
