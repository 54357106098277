import React, {useCallback, useMemo, useState} from "react";
import BaseContent from "../../../../components/Common/BaseContent";
import {connect} from "react-redux";
import {Schema} from "../../../../store/core/schema";
import {EntityType} from "../../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import CardSettings from "../../../../components/Common/card-settings";
import {useListToggle, useToggle} from "../../../../helpers/hooks";
import {createNamespace, deleteNamespace, updateNamespace} from "../../../../store/namespace/actions";
import ListSettings from "../../../../components/Common/ListSettings";
import ListToggle from "../../../../components/Common/ListToggle";

const entityType = EntityType.Namespace;

const breadcrumbs = [
  { title: "Settings", link: "/settings" },
  { title: "Content Model", link: "/settings/content-model" },
  { title: "Namespaces", link: "#" },
];

const Namespaces = (props) => {
  const { context, createNamespace, deleteNamespace, updateNamespace } = props;
  const { result, isLoading, error } = context.Namespace.getAll;
  const currentProperty = context.Namespace.get.result;
  const [openModal, toggleModal] = useToggle(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const [isList, toggleList] = useListToggle();

  const handleEntityClick = useCallback(
    (entity) => {
      setEntity(entity);
      toggleModal();
    },
    [toggleModal]
  );

  React.useEffect(() => {
    setEntity(currentProperty);
  }, [currentProperty]);

  const handleCreate = useCallback(() => {
    setEntity(Schema[entityType]);
    toggleModal();
  }, [toggleModal]);

  const cards = useMemo(() => {
    return result.map((nameSpace) => ({
        label: nameSpace.name,
        icon: "bx-world",
        handleClick: () => handleEntityClick(nameSpace),
      }));
  }, [handleEntityClick, result]);

  const list = useMemo(() => {
    return result.map((nameSpace) => ({
      label: nameSpace.name,
      handleClick: () => handleEntityClick(nameSpace),
    }));
  }, [handleEntityClick, result]);

  const listColumns = [
    {name: "Name", prop: "label"}
  ]
  return (
    <>
      <BaseContent
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
      >
        {!isList && <CardSettings settings={cards}/>}
        {isList && <ListSettings contents={list} columns={listColumns}/>}
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={openModal}
          handleClose={toggleModal}
          createEntity={createNamespace}
          updateEntity={updateNamespace}
          deleteEntity={deleteNamespace}
          context={context.Namespace}
        />
      </BaseContent>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createNamespace,
  updateNamespace,
  deleteNamespace,
})(Namespaces);
