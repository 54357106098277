import React from "react";

const ContactListItem = ({ item, selectContact, selected, toggleContact, addressBook }) => {

  const selectItem = () => {
    if (addressBook) {
      toggleContact(item.uuid)
    } else {
      selectContact(item)
    }
  }

  return (
    <tr className='c-item-list'>
      <td onClick={()=>toggleContact(item.uuid)}>
        <input
          type={"checkbox"}
          checked={selected}
          onChange={()=>toggleContact(item.uuid)}
        />
      </td>
      <td onClick={selectItem}>
        {item.firstName?item.firstName:'-'}
      </td>
      <td onClick={selectItem}>
        {item.lastName?item.lastName:'-'}
      </td>
      <td onClick={selectItem}>
        {item.email?item.email:'-'}
      </td>
      <td onClick={selectItem}>
        {item.organization?item.organization:'-'}
      </td>
    </tr>
  );
};

export default ContactListItem;
