import {ApiEndpoint} from "../store/core/endpoint";
import {EntityType} from "../store/core/entityType";
import {postData} from "../utils";
import toastr from "toastr";

const handleStar = async (entity, objectType, callback) => {
  const url = ApiEndpoint[EntityType.Tag] + "/starred";
  try {
    await postData(url, {
      starred: !entity.starred,
      objectId: entity.uuid,
      objectType,
    });
    callback({ uuid: entity.uuid, starred: !entity.starred });
    const message = `${entity.label} ${
      entity.starred ? "removed from starred" : "added to starred"
    }`;
    toastr.success(message);
  } catch (e) {
    console.log(e);
  }
};

export {handleStar}
