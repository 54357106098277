import React, {useEffect, useRef, useState} from 'react'
import {Button, Col, Row, UncontrolledTooltip} from 'reactstrap'
import SliderProjection from '../../pages/Story/Stories/Slideshow/SliderProjection'
import {assetFull, makeid} from '../../utils'
import ElementDialog from '../../pages/Story/Stories/Slideshow/ElementDialog'
import Reorder from 'react-reorder'
import {useWindowSize} from '../../helpers/hooks'
import SlideTemplateDialog from '../../pages/Story/Stories/Slideshow/components/SlideTemplateDialog'
// import {API_URL} from '../../config'
// import Container from 'reactstrap/lib/Container'

const SlideShowEditor = (props) => {
  const {
    story,
    handleChangeSlide,
    aspectRatio,
    orientation,
    handleDeleteSlide,
    handleDuplicateSlide,
    initialSelectedSlide,
    openElementEditAction,
    loading,
    slides,
    addNewSlide,
    updateSlides,
    selectedEditElement,
    setSelectedEditElement,
    handleSelectedSlide,
  } = props
  const [windowWidth, windowHeight] = useWindowSize();

  const [open, setOpen] = useState(false)
  const [openSlideTemplates, setOpenSlideTemplates] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('#fff')
  const [selectedSlide, setSelectedSlide] = useState({})
  const [elements,setElements] = useState([])
  const [previousBackground, setPreviousBackground] = useState(null)
  const [backgroundImage, setBackgroundImage] = useState(null)
  const [scaleCalculated, setScaleCalculated] = useState(0.29)
  const projectionRef = useRef()
  useEffect(() => {
    if (initialSelectedSlide.id) {
      setSelectedSlide(initialSelectedSlide)
      handleSelectedSlide(initialSelectedSlide)
    }
    //eslint-disable-next-line
  }, [initialSelectedSlide])

  useEffect(() => {
    if (slides && selectedSlide.id) {
      const slideIndex = slides.findIndex((slide) => slide.id === selectedSlide.id)
      const slidesTmp = [...slides]
      setBackgroundColor(slidesTmp[slideIndex]?.background?.color)
      setElements(slidesTmp[slideIndex]?.elements)
      if (slidesTmp[slideIndex]?.background?.uuid) {
        // load image for the first time
        if (selectedSlide?.background?.uuid && !backgroundImage) {
          setPreviousBackground(slidesTmp[slideIndex]?.background?.uuid)
          assetFull(slidesTmp[slideIndex]?.background?.uuid, setBackgroundImage,slidesTmp[slideIndex]?.background?.from)
        }
        //prevent downloading the same image if slide state changes
        else if (previousBackground !== slidesTmp[slideIndex]?.background.uuid) {
          setPreviousBackground(slidesTmp[slideIndex]?.background.uuid)
          assetFull(slidesTmp[slideIndex]?.background?.uuid, setBackgroundImage,slidesTmp[slideIndex]?.background?.from)
        }
      } else setBackgroundImage(null)
    }
    //eslint-disable-next-line
  }, [slides, selectedSlide.id])


  const resizeToFit = () => {
    let scale = Math.min(projectionRef.current.offsetWidth / story?.screen?.width, projectionRef.current.offsetHeight / story?.screen?.height)
    setScaleCalculated(scale)
  }

  useEffect(() => {
    if (story) {
      resizeToFit()
    }
    //eslint-disable-next-line
  }, [windowWidth, windowHeight, story])


  const handleAddSlide = (selectedTemplate) => {
    let enterAnimationCalc = 0
    let leaveAnimationCalc = 10000
    const slidesIndexing = slides?.length ? slides?.length : 0
    if (slidesIndexing > 0) {
      const slidesTmp = [...slides]
      enterAnimationCalc = slidesTmp[slidesIndexing - 1].animation.leaveAnimationDelay + 1000
      leaveAnimationCalc = slidesTmp[slidesIndexing - 1].animation.leaveAnimationDelay + 10000
    }
    if (selectedTemplate?.slideDetails)
    {
      selectedTemplate.slideDetails.elements.map((elementFromTemplate)=>{
        return elementFromTemplate.id = makeid()
      })
    }

    const newSlide = {
      id: makeid(),
      templateId:selectedTemplate?.uuid ? selectedTemplate?.uuid : null,
      name: selectedTemplate?.name ? selectedTemplate.name : `Slide ${slidesIndexing + 1}`,
      duration: selectedTemplate?.slideDetails?.duration ? selectedTemplate?.slideDetails?.duration : 10000,
      background:  selectedTemplate?.uuid ? {...selectedTemplate?.slideDetails?.background} : { color: '#fff',  position: '', uuid: '', size: ''},
      animation: {
        enterAnimation: selectedTemplate?.slideDetails?.animation ? selectedTemplate?.slideDetails?.animation.enterAnimation : null,
        leaveAnimation: selectedTemplate?.slideDetails?.animation ? selectedTemplate?.slideDetails?.animation.leaveAnimation : null,
        editorSlideDuration: 5,
        enterAnimationDelay: enterAnimationCalc,
        leaveAnimationDelay: leaveAnimationCalc,
        durationEnter: 1000,
        durationLeave: 1000
      },
      elements: selectedTemplate?.slideDetails ? selectedTemplate.slideDetails.elements : []
    }

    addNewSlide(newSlide)
    setOpenSlideTemplates(!openSlideTemplates)
  }

  const handleReOrder = (e, previousIndex, nextIndex) => {
    setSelectedSlide(slides[previousIndex])
    handleSelectedSlide(slides[previousIndex])
    const slidesTemp = [...slides]
    const previousSlide = {...slidesTemp[previousIndex]}
    slidesTemp[previousIndex] = slidesTemp[nextIndex]
    slidesTemp[nextIndex] = previousSlide
    updateSlides(slidesTemp)

  }

  return <div>
    {/*<RootRef rootRef={projectionRef}>*/}
    <div className={'projectionWrapper'} ref={projectionRef}>
      <div className={'projectionArea'}
           id={'projectionAreaId'}
           key={`${selectedSlide.id}`}
           style={{
             width: story?.screen?.width,
             backgroundColor: backgroundColor,
             backgroundImage: backgroundImage ? `url("${backgroundImage}")` : '',
             backgroundSize: 'cover',
             height: story?.screen?.height,
             border: '3px dashed #d2d2d2',
             transform: `translate(-50%, -50%) scale(${scaleCalculated})`,

           }}>
        <SliderProjection slides={slides} storyScreen={story?.screen} key={`${selectedSlide.id}-${story.uuid}`}
                          currentSlide={selectedSlide}
                          openElementEditAction={openElementEditAction}
                          loading = {loading}
                          selectedEditElement={selectedEditElement}
                          scale={scaleCalculated}
                          elements={elements}
                          setSelectedEditElement={setSelectedEditElement} handleChangeSlide={handleChangeSlide}/>
      </div>
    </div>
    {/*</RootRef>*/}
    <Row>
      <div className='mt-4'
           style={{width: 'calc(100vw - 55em)', textAlign: 'right', position: 'absolute', bottom: '130px'}}>
        <Button className='mr-3' color='primary' outline disabled={!selectedSlide?.id} onClick={() => setOpen(!open)}>Add
          Element +</Button>
      </div>
      <div className='mt-4 slidesContainer' style={{
        height: '7.8em',
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        position: 'fixed',
        bottom: 4
      }}>
        <div style={{width: 'auto', minWidth: '200px', maxWidth: '100vw'}}>
          <div onClick={() => setOpenSlideTemplates(!openSlideTemplates)} key={'-add'}
               className='slideshow-editor-add-slide'>
            <div>Add Slide +</div>
          </div>
          <Reorder
            reorderId={'slidesList'}
            autoScroll
            draggedClassName="draggedSlide"
            onReorder={handleReOrder}
            holdTime={50}
            disableContextMenus={true}
          >
            {slides?.map((slide) => {
              return <div
                className={`draggedSlide slideshow-editor-slide ${selectedSlide.id === slide.id ? 'slideshow-editor-slide-selected' : ''}`}
                key={slide.id}
                onClick={() => {
                  setSelectedSlide(slide)
                  handleSelectedSlide(slide)
                }}
              >
                <div className='slideshow-editor-slide-layoutName text-wrap'>{slide.name}</div>
                <div className={'slideshow-editor-slide-actions'}>
                  <Row>
                    <Col>
                      <UncontrolledTooltip target={`duplicateIcon${slide.id}`} placement={'top'}>
                        Duplicate Slide
                      </UncontrolledTooltip>
                      <i className='bx bx-duplicate' id={`duplicateIcon${slide.id}`} onClick={(e) => handleDuplicateSlide(e, slide.id)}/>
                    </Col>
                    <Col>
                      <UncontrolledTooltip target={`deleteIcon${slide.id}`} placement={'top'}>
                        Delete Slide
                      </UncontrolledTooltip>
                      <i className='bx bx-trash' id={`deleteIcon${slide.id}`} onClick={(e) => handleDeleteSlide(e, slide.id)}/>
                    </Col>
                  </Row>
                </div>
                <div className='slideColorIndicator' style={{backgroundColor: slide?.background.color}}/>
              </div>
            })}
          </Reorder>
        </div>
      </div>
    </Row>
    <SlideTemplateDialog aspectRatio={aspectRatio} orientation={orientation} open={openSlideTemplates}
                         handleClose={() => setOpenSlideTemplates(!openSlideTemplates)}
                         handleCreateSlide={handleAddSlide}/>
    <ElementDialog slides={slides} open={open} selectedSlide={selectedSlide} handleChangeSlide={handleChangeSlide}
                   handleClose={() => setOpen(!open)}/>
  </div>
}


export default SlideShowEditor
