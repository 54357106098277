import React, { Fragment, useEffect } from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import { useEntityProgress } from "../../helpers/hooks";
import SweetAlert from "react-bootstrap-sweetalert";

const EditDialog = (props) => {
  const {
    handleClose,
    open,
    createEntity,
    updateEntity,
    context,
    form,
    // entity,
    entityType,
    deleteEntity,
    // scrollable,
    showDeleteModal,
    setShowDeleteModal,
    tabs,
    steps,
    contents,
    extraClassName,
    isValid,
    primaryActionTitle,
    handleEditTitle,
    keepOpen,
    titleOverride,
    subtitle,
    currentContainer
  } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleCreate = () => {
    return form.id ? updateEntity(form) : createEntity(form);
  };

  const handleDelete = () => {
    deleteEntity(form,currentContainer);
    showDeleteModal && open && props.handleClose();
    setOpenDelete(false);
    setShowDeleteModal && setShowDeleteModal(false); // Only for cases not using generic functionality
  };

  const onClose = keepOpen ? function () {} : handleClose;
  const [busy, error] = useEntityProgress(context, onClose);

  useEffect(() => setErrorMessage(error), [error]);
  useEffect(() => {
    if (!open) {
      setErrorMessage(null);
    }
  }, [open]);

  return (
    <Fragment>
      <BaseDialog
        title={titleOverride || `${form.id ? "Edit" : "Create new"} ${entityType}`}
        secondaryAction={handleClose}
        handleEditTitle={handleEditTitle}
        primaryAction={handleCreate}
        primaryActionTitle={primaryActionTitle}
        deleteAction={
          deleteEntity && form.id ? () => setOpenDelete(true) : null
        }
        busy={busy}
        open={open}
        size={props.size}
        handleClose={handleClose}
        scrollable={true}
        tabs={tabs}
        steps={steps}
        contents={contents}
        extraClassName={extraClassName}
        isValid={isValid}
        subtitle={subtitle}
        errorMessage={errorMessage}
        dismissError={() => setErrorMessage(null)}
      >
        {props.children}
      </BaseDialog>
      {openDelete || showDeleteModal ? (
        <SweetAlert
          title={"Remove confirmation"}
          warning
          onConfirm={handleDelete}
          onCancel={() => {
            setShowDeleteModal && setShowDeleteModal(false); // Only for cases not using generic functionality
            setOpenDelete(false);
          }}
        >
          Are you sure you want to remove{" "}
          {form.label || form.name || form.title || `this ${entityType}`}?
        </SweetAlert>
      ) : null}
    </Fragment>
  );
};

export default EditDialog;
