import React, {Component} from 'react';

import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap";
// Redux
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
// availity-reactstrap-validation
import {AvField, AvForm} from 'availity-reactstrap-validation';
// actions
import {apiError, loginUser} from '../../store/actions';
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-light.png";
import copyrightLogo from '../../assets/images/copyright_logo.png'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {}

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.loginUser(values, this.props.history);
    }

    componentDidMount()
    {
        this.props.apiError("");
    }

    render() {

        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
                </div>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p>Sign in to continue to Repox.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={logo} alt="" className="rounded-circle" height="34" />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">

                                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}

                                                <div className="form-group">
                                                    <AvField name="username" label="Username" value="" className="form-control" placeholder="Enter username" type="text" required />
                                                </div>

                                                <div className="form-group">
                                                    <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                                </div>

                                                {/*<div className="custom-control custom-checkbox">*/}
                                                {/*    <input type="checkbox" className="custom-control-input" id="customControlInline" />*/}
                                                {/*    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>*/}
                                                {/*</div>*/}

                                                <div className="mt-3">
                                                    <button disabled={this.props.loading} className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">

                                    <p>© {new Date().getFullYear()} <div className='d-inline-flex'><a href='https://datawise.ai' target='_blank' rel="noopener noreferrer" > <img src={copyrightLogo} height='10' alt='datawise logo'/> </a></div></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { error , loading} = state.Login;
    return { error, loading};
}

export default withRouter(connect(mapStatetoProps, { loginUser,apiError })(Login));

