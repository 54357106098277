import React, {Fragment, useEffect} from "react";
import {FormGroup, Label} from "reactstrap";
import {useForm} from "../../../helpers/hooks";
import EditDialog from "../../../components/Common/EditDialog";
import {SystemSettingType} from "../../../constants/enums";
import {ApiEndpoint} from "../../../store/core/endpoint";
import {EntityType} from "../../../store/core/entityType";
import CardSelect from "../../../components/Common/CardSelect";

const PropertyDialog = (props) => {
  const { entity, open } = props;

  const [form, handleChange, updateInitial] = useForm({});

  useEffect(() => {
    updateInitial(entity);
  }, [entity, updateInitial, open]);

  const handleUpdate = () => {
    const payload = [form];
    const __custom_url__ = ApiEndpoint[EntityType.SystemSetting];
    const data = { payload, __custom_url__ };
    props.updateEntity(data);
  };

  const invalidInput = form.settingType !== SystemSettingType.bool && !form.settingValue

  return (
    <EditDialog
      {...props}
      size={"md"}
      isValid={form.editable && !invalidInput}
      primaryActionTitle={"Update"}
      form={form}
      updateEntity={handleUpdate}
    >
      <form>
        <FormGroup>
          <SettingEl form={form} handleChange={handleChange} />
          <span className="font-13 text-muted">
            {!form.editable && `This option cannot be edited`}
          </span>
        </FormGroup>
      </form>
    </EditDialog>
  );
};

export default PropertyDialog;

const SettingEl = ({ form, handleChange }) => {
  if (form.settingType === SystemSettingType.bool) {
    const options = [
      {
        label: form.label,
        value: form.settingValue,
      },
    ];
    return (
      <CardSelect
        isDisabled={!form.editable}
        options={options}
        value={{ value: "TRUE" }}
        onChange={() =>
          handleChange("settingValue")(
            form.settingValue === "TRUE" ? "FALSE" : "TRUE"
          )
        }
      />
    );
  } else if (form.settingType === SystemSettingType.enum) {
    const enums = JSON.parse(form.settingDetails);
    const options = enums.values.map((en) => ({ label: en, value: en }));
    return (
      <CardSelect
        orientation={"column"}
        isDisabled={!form.editable}
        options={options}
        value={{ value: form.settingValue }}
        onChange={handleChange("settingValue")}
      />
    );
  } else {
    const type = form.settingType.toLowerCase();
    return (
      <Fragment>
        <Label>{form.label}</Label>
        <input
          type={type}
          disabled={!form.editable}
          className="form-control"
          // placeholder="First name"
          value={form.settingValue}
          onChange={handleChange("settingValue")}
        />
      </Fragment>
    );
  }
};
