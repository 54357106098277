import React, {useState} from 'react'
import {motion} from 'framer-motion'
// const story1Variants = {
//   hide:{
//     fillOpacity:1
//   },
//   show:{
//     fillOpacity: [1,0],
//     transition: {
//       duration: 2
//     }
//   }
// }
// const story2Variants = {
//   hide:{
//     fillOpacity:0
//   },
//   show:{
//     fillOpacity: [0,1],
//     transition: {
//       delay:1,
//       duration: 1
//     }
//   }
// }
const hotspot1 = {
  hide:{
    fillOpacity:1
  },
  show:{
    fillOpacity:0.5,
    transition:{
      duration:1,
      yoyo:10
    }
  }
}

const hotspot2 = {
  hide:{
    fillOpacity:1
  },
  show:{
    fillOpacity:0.5,
    transition:{
      delay:2,
      duration:1,
      yoyo:10
    }
  }
}

const hotspot3 = {
  hide:{
    fillOpacity:1
  },
  show:{
    fillOpacity:0.5,
    transition:{
      delay:4,
      duration:1,
      yoyo:10
    }
  }
}

const tooltip1Variants = {
  initial:{
    scale:0
  },
  hide:{
    scale:0
  },
  show:{
    scale:1,
      transition:{
        duration:1,
    }
  }
}

const tooltip2Variants = {
  initial:{
    scale:0
  },
  hide:{
    scale:0
  },
  show:{
    scale:1,
    transition:{
      delay:2,
      duration:1,
    }
  }
}

const tooltip3Variants = {
  initial:{
    scale:0
  },
  hide:{
    scale:0
  },
  show:{
    scale:1,
    transition:{
      delay:4,
      duration:1,
    }
  }
}

const ObjectsAnimateSVG = (props) => {
  const [hovered, setHovered] = useState(false)
  return <motion.div layout style={{width: '100%', height: '180px'}}>
    <motion.svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 300 166.9"
                width={'100%'}
                height={'100%'}
                onHoverStart={() => setHovered(true)} onHoverEnd={() => setHovered(false)}
    >
      <g id="Object">
        <path className="st0" d="M251.15,161.33H48.85c-6.6,0-12-5.4-12-12V26.64c0-6.6,5.4-12,12-12h202.31c6.6,0,12,5.4,12,12v122.69
		C263.15,155.93,257.75,161.33,251.15,161.33z"/>
      </g>
      <g id="hotspot1">
        <motion.circle variants={hotspot1} animate={hovered?'show':'hide'} fillOpacity={1} className="st1" cx="49.75" cy="146.01" r="6.63"/>
      </g>
      <g id="tooltip1">
        <motion.g variants={tooltip1Variants} animate={hovered?'show':'hide'}>
          <path className="st1" d="M102.96,139.08H54.6v-33.4c0-2.21,1.79-4,4-4h44.35c2.21,0,4,1.79,4,4v29.4
			C106.96,137.29,105.17,139.08,102.96,139.08z"/>
          <rect x="62.8" y="109.65" className="st0" width="34.51" height="2.76"/>
          <rect x="62.8" y="116.21" className="st0" width="34.51" height="2.76"/>
          <rect x="62.8" y="122.76" className="st0" width="34.51" height="2.76"/>
        </motion.g>
      </g>
      <g id="hotspot2">
        <motion.circle variants={hotspot2} animate={hovered?'show':'hide'} fillOpacity={1} className="st1" cx="237.22" cy="104.27" r="6.63"/>
      </g>
      <motion.g id="tooltip2" variants={tooltip2Variants} animate={hovered?'show':'hide'}>
        <g>
          <path className="st1"
                d="M231.35,97.51H183c-2.21,0-4-1.79-4-4v-29.4c0-2.21,1.79-4,4-4h44.35c2.21,0,4,1.79,4,4V97.51z"/>
          <rect x="187.19" y="68.08" className="st0" width="34.51" height="2.76"/>
          <rect x="187.19" y="74.64" className="st0" width="34.51" height="2.76"/>
          <rect x="187.19" y="81.19" className="st0" width="34.51" height="2.76"/>
        </g>
      </motion.g>
      <g id="hotspot3">
        <motion.circle variants={hotspot3} animate={hovered?'show':'hide'} fillOpacity={1}  className="st1" cx="56.39" cy="49.5" r="6.63"/>
      </g>
      <motion.g id="tooltip3" variants={tooltip3Variants} animate={hovered?'show':'hide'}>
        <g>
          <path className="st1"
                d="M112.61,40H64.25V6.61c0-2.21,1.79-4,4-4h44.35c2.21,0,4,1.79,4,4V36C116.61,38.21,114.82,40,112.61,40z"/>
          <rect x="72.45" y="10.58" className="st0" width="34.51" height="2.76"/>
          <rect x="72.45" y="17.13" className="st0" width="34.51" height="2.76"/>
          <rect x="72.45" y="23.69" className="st0" width="34.51" height="2.76"/>
        </g>
      </motion.g>
    </motion.svg>
  </motion.div>
}

export default ObjectsAnimateSVG