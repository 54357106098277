import React from "react";
import { Button } from "reactstrap";

const MoreButton = ({ isLoading, handleMore, hasMore }) => {
  return hasMore ? (
    <div className={"d-flex justify-content-center my-4"}>
      <Button style={{width: 150}} color={"light"} onClick={handleMore} disabled={isLoading}>
        View More
        {isLoading && (
          <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"/>
        )}
      </Button>
    </div>
  ) : null;
};

export default MoreButton;
