import React, { useState } from "react";
import { Media } from "reactstrap";
import moment from "moment";
import BusyIndicator from "./BusyIndicator";
import MoreButton from "./MoreButton";
import { useActivities } from "../../helpers/hooks";

function ActivityComp({ entityId, entityType, isLoadingCurrent }) {
  const [page, setPage] = useState(0);
  const { isLoading, activities, content } = useActivities(
    entityId,
    entityType,
    page,
    setPage,
    isLoadingCurrent
  );

  if (isLoading && content.length === 0)
    return <BusyIndicator busy={isLoading} />;

  return (
    <React.Fragment>
      <ul className="verti-timeline list-unstyled">
        {content.map((activity, key) => (
          <li className="event-list" key={key}>
            <div className="event-timeline-dot">
              <i className="bx bx-right-arrow-circle font-size-18" />
            </div>
            <Media>
              <div className="mr-3">
                <h5 className="font-size-14">
                  {moment(activity.createdAt).format("DD/MM/YYYY")}{" "}
                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2" />
                </h5>
              </div>
              <Media body>
                <div>{activity.description}</div>
              </Media>
            </Media>
          </li>
        ))}
      </ul>
      <MoreButton
        hasMore={!activities.last}
        handleMore={() => setPage(page + 1)}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
}

export default ActivityComp;
