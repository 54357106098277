import React, {useEffect} from 'react';
import BaseContent from '../../components/Common/BaseContent';
import SurveysList from "./SurveysList";
import {clearSurveyGet, createSurvey, getAllSurveys} from "../../store/survey/actions";
import {connect} from "react-redux";

const breadcrumbs = [{title: 'Surveys', link: '/surveys'}]

const Surveys = (props) => {
  const {context, getAllSurveys, createSurvey, clearSurveyGet} = props;
  const {result, isLoading} = context.Survey.getAll;

  useEffect(()=>{
    getAllSurveys()
    clearSurveyGet()
    // eslint-disable-next-line
  },[])

  const addNewSurvey = (data) => {
    createSurvey(data)
  }

  return (
    <BaseContent
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
      // renderActions={()=> {
      //   return <Row>
      //     <Col xs={12} style={{textAlign: 'right'}}>
      //
      //       <Button outline color="info" onClick={() =>setGoToContacts(true)}><i className='bx bxs-contact'></i> Contacts</Button>
      //     </Col>
      //   </Row>
      // }}
    >
      <SurveysList result={result} isLoading={isLoading} addNewSurvey={addNewSurvey} />
    </BaseContent>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getAllSurveys,
  createSurvey,
  clearSurveyGet
})(Surveys);
