import React, {useMemo, useState} from 'react';
import BaseContent from '../../components/Common/BaseContent';
import {useListToggle, usePaging} from '../../helpers/hooks';
import {ApiEndpoint} from '../../store/core/endpoint';
import {EntityType} from '../../store/core/entityType';
import CardSettings from '../../components/Common/card-settings';
import {connect} from 'react-redux';
import MoreButton from '../../components/Common/MoreButton';
import ListSettings from '../../components/Common/ListSettings';
import ListToggle from '../../components/Common/ListToggle';
import moment from 'moment';
import {bytesToSize} from '../../utils'

const breadcrumbs = [{title: 'Recent', link: '#'}];

const TagObjects = (props) => {
  const url = ApiEndpoint[EntityType.Container] + '/recent';
  const urlDatastream = ApiEndpoint[EntityType.DataStream] + '/recent'
  const [isContainer, setIsContainer] = useState(true)
  const [page, setPage] = useState(0);
  const [pageDatastream, setPageDatastream] = useState(0);
  const [isList, toggleList] = useListToggle();
  const [isLoading, data, contents] = usePaging(url, {
    size: 18,
    page,
    identifier: 'uuid',
  });

  const [isLoadingDatastream, dataDatastream, contentsDatastream] = usePaging(urlDatastream, {
    size: 18,
    page:pageDatastream,
    identifier: 'uuid',
  });

  const containerData = contents.map((container) => ({
    label: container.label,
    route: '/containers/' + container.uuid,
    icon: 'bx-folder-open',
    color: container.color,
  }));

  const datastreamData = contentsDatastream.map((datastream) => ({
    label: datastream.label,
    route: `/containers/${datastream.membership[0]?.uuid}/?datastream=${datastream.uuid}`,
    icon: "bx-file",
    color: datastream.color,
  }));


  const containerList = useMemo(() => {
    return contents.map((container) => ({
      label: container.label,
      totalFiles: container.datastreamCount + container.childCount,
      creator: `${container.createdBy.firstname} ${container.createdBy.lastname}`,
      createdAt: moment(container.createdAt).format("Do MMMM YYYY"),
      _published: container.published,
      _route: "/containers/" + container.uuid,
      _icon: "bx-folder",
      // handleClick: () => handleEntityClick(containerType),
      color: container.color,
    }));
  }, [contents]);

  const datastreamList = useMemo(() => {
    return contentsDatastream.map((datastream) => ({
      label: datastream.label,
      creator: `${datastream.createdBy.firstname} ${datastream.createdBy.lastname}`,
      createdAt: moment(datastream.createdAt).format("Do MMMM YYYY"),
      fileSize: bytesToSize(datastream.filesize),
      _published: datastream.published,
      _route: `/containers/${datastream.membership[0]?.uuid}/?datastream=${datastream.uuid}`,
      _icon: "bx-file",
      // handleClick: () => handleEntityClick(containerType),
      color: datastream.color,
    }));
  }, [contentsDatastream]);

  const listColumns = [
    {name: 'Name', prop: 'label'},
    {name: 'Created At', prop: 'createdAt'},
    {name: 'Creator', prop: 'creator'},
    {name: 'Total files', prop: 'totalFiles'},
  ];

  const listDatastreamColumns = [
    {name: 'Name', prop: 'label'},
    {name: 'Created At', prop: 'createdAt'},
    {name: 'Creator', prop: 'creator'},
    {name: 'File size', prop: 'fileSize'},
  ];

  const handleMore = () => {
    if (isContainer === true)
      setPage(page + 1)
    else
      setPageDatastream(pageDatastream + 1)
  }

  return (
    <BaseContent
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      renderActions={() => {
        return <>
          <button
            type="button"
            onClick={() => setIsContainer(true)}
            className="btn font-size-18"
            data-toggle="fullscreen"
          >
            <div className={`font-size-11 ${isContainer? 'recent-selected-filter':'text-muted'}`}>Containers</div>
          </button>
          <button
            type="button"
            onClick={() => setIsContainer(false)}
            className="btn font-size-18"
            data-toggle="fullscreen"
          >
            <div className={`font-size-11 ${!isContainer? 'recent-selected-filter':'text-muted'}`}>Datastreams</div>
          </button>
          <ListToggle isList={isList} toggle={toggleList}/>
        </>
      }}>
      {!isList && <CardSettings settings={isContainer ? containerData : datastreamData}/>}
      {isList && (
        <ListSettings contents={isContainer ? containerList : datastreamList}
                      columns={isContainer ? listColumns : listDatastreamColumns}/>
      )}
      <MoreButton
        isLoading={isLoading || isLoadingDatastream}
        hasMore={isContainer ? !data.last : !dataDatastream.last}
        handleMore={handleMore}
      />
    </BaseContent>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.Tag.getAll.result,
  };
};
export default connect(mapStateToProps, null)(TagObjects);
