import React, { useEffect, useState } from "react";
import { Badge, Table } from "reactstrap";
import { Link } from "react-router-dom";
import ArrowUpIcon from "@icons/material/ArrowUpIcon";
import ArrowDownIcon from "@icons/material/ArrowDownIcon";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

const MSwitch = withStyles({
  switchBase: {
    "&$disabled": {
      opacity: 1,
      "&$checked": {
        // thumb when checked and disabled
        color: "#556ee6",
        opacity: 1,
        // track when checked and disabled
        "& + $track": {
          backgroundColor: "#556ee6",
          opacity: 0.2,
        },
      },
    },
  },
  track: {},
  disabled: {},
  checked: {},
})(Switch);

const safeValue = (value) => (value ? value : "");
const ListSettings = ({ contents, columns }) => {
  const history = useHistory();
  
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([])

  useEffect(() =>
  { 
    const result = contents.filter((x) => [x.label].find((value) => safeValue(value).toLowerCase().includes(query.toLowerCase())));
    setResults(result);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents,query])
  
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("label");

	function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleTableHeadSort = (value) => {
    const isAsc = orderBy === value && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(value);
  };

  const handleClick = (value) => {
    history.push(value);
  }
  
 // const results = contents.filter((x) => [x.label].find((value) => safeValue(value).toLowerCase().includes(query.toLowerCase())));
  
  return (
    <div>
      <div className={"search-box-container"}>
        <form>
          <input type="text" className="form-control" value={query} placeholder={"Search.."} onChange={(e) => setQuery(e.target.value)} />
        </form>
      </div>
      <div className={"table-responsive"}>
        <Table className="project-list-table table-nowrap table-centered table-borderless">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th
                  key={index}
                  scope="col"
                  style={{cursor: "pointer", textAlign: col.prop!=="label" ? "center":''}}
                  onClick={() => {
                    handleTableHeadSort(col.prop);
                  }}
                >
                  {col.name}{" "}
                  {orderBy === col.prop ? (
                    order === "asc" ? (
                      <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                    ) : (
                      <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                    )
                  ) : (
                    <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {results &&
              stableSort(results, getComparator(order, orderBy)).map((file, key) => {
                const bgColor = file.color !== null && file.color !== undefined && file.color !== "#fff" ? `${ file.color }8C` : "#fff";
                //const selected = props.isSelected(file.uuid);
                //const borderColor = selected ? "primary" : "light";
                return (
                  <tr
                    onClick={file.route ? () => handleClick(file.route) : file.editable === undefined ? file.handleClick : file.editable ? file.handleClick : null}
                    className={`cursor-pointer border border-primary  ${file.editable === undefined ? file.handleClick : file.editable ? "listTableRow" : "listTableRowNonEditable"}`}
                    key={key}
                    style={{position: "relative", backgroundColor: bgColor, cursor: file.route || file.editable || file.handleClick ? "pointer" : "default"}}
                  >
                    {columns.map((col, index) => {
                      if (col.prop === "icon") {
                        return (
                          <td style={{width: "100px"}} key={index}>
                            <div className="avatar-sm mr-4">
                              <span className="avatar-title rounded-circle bg-light text-primary font-size-24">
                                <i className={`bx ${file.icon}`} />
                              </span>
                            </div>
                          </td>
                        );
                      } else if (col.prop?.badge) {
                        return (
                          <td key={index}>
                            <Badge color={"primary"}>{file[col.prop]}</Badge>
                          </td>
                        );
                      } else if (col.prop === "label" && file._route) {
                        return (
                          <td key={index}>
                            <Link to={file._route}>
                              <h5 className="text-truncate font-size-14">
                                {file._icon && <i className={`font-weight-bold bx ${file._icon} mr-1 font-size-18`} />}
                                {file.label}
                                {file._published && <i className="font-weight-bold bx bxs-check-circle text-success ml-1" />}
                              </h5>
                            </Link>
                          </td>
                        );
                      } else if (col.prop === "label") {
                        return (
                          <td key={index}>
                            {file[col.prop]} {file?.mandatory ? " *" : ""}
                          </td>
                        );
                      } else if (col.prop === "allowedAtRoot") {
                        return (
                          <td key={index} style={{textAlign: "center"}}>
                            <MSwitch disabled size="small" color="default" inputProps={{"aria-label": "true/false"}} checked={file[col.prop]} />
                          </td>
                        );
                      }
                      return (
                        <td key={index} style={{textAlign: "center"}}>
                          {file[col.prop]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ListSettings;
