import React, {useEffect, useState} from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {ApiEndpoint} from "../../store/core/endpoint";
import {EntityType} from "../../store/core/entityType";
import axios from "axios";
import {getAxiosDefaultConfig} from "../../utils";
import toastr from "toastr";
import SearchNavigation from "../../components/Common/SearchNavigation";

const MoveDialog = ({open, handleClose, selectedContainer, actionFromBreacrumb, setActionFromBreacrumb, parentContainer}) => {
  const [moveDestination, setMoveDestination] = useState("");

  useEffect(() => {
    setMoveDestination("");
  }, [open]);

  const handleMoveFile = () => {
    if (moveDestination === "") {
      toastr.error("Please select a destination");
    } else if (parentContainer.uuid === moveDestination.uuid || (parentContainer.uuid === undefined && moveDestination.uuid === null) || selectedContainer.uuid === moveDestination.uuid) {
      toastr.error("Select a different destination");
    } else if (parentContainer.uuid !== moveDestination.uuid) {
      const url = `${ApiEndpoint[EntityType.Container]}/${selectedContainer.uuid}/move`;
      const payload = {
        from: actionFromBreacrumb ? (parentContainer.paths.length > 0 ? parentContainer.paths[0].nodes[0].uuid : "ROOT") : parentContainer.uuid ? parentContainer.uuid : "ROOT",
        to: moveDestination.uuid ? moveDestination.uuid : "ROOT",
      };
      try {
        axios
          .post(url, payload, getAxiosDefaultConfig())
          .then(() => {
            toastr.success(`Moved successfully to ${moveDestination.label.length > 50 ? moveDestination.label.substring(0, 50) + "..." : moveDestination.label}`);
            handleClose();
            if (setActionFromBreacrumb) setActionFromBreacrumb(false);
          })
          .catch((e) => {
            console.log(e);
            toastr.error(e.response.data.message);
            if (setActionFromBreacrumb) setActionFromBreacrumb(false);
          });
      } catch (e) {
        toastr.error(e);
      }
    }
  };

  return (
    <BaseDialog title={`Move container ${selectedContainer?.label}`} size={"md"} handleClose={handleClose} open={open} primaryAction={handleMoveFile}>
      <form>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>From </Label>
              <Input
                type="text"
                className="form-control"
                value={actionFromBreacrumb ? (parentContainer.paths.length > 0 ? parentContainer.paths[0].nodes[0].label : "ROOT") : parentContainer.label ? parentContainer.label : "ROOT"}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label>Select destination</Label>
            <SearchNavigation selectedContainer={selectedContainer} parentContainer={parentContainer} setMoveDestination={setMoveDestination} />
          </Col>
        </Row>
      </form>
    </BaseDialog>
  );
};

export default MoveDialog;
