import React, {useCallback, useMemo, useState, useEffect} from "react";
import BaseContent from "../../../../components/Common/BaseContent";
import {connect} from "react-redux";
import {Schema} from "../../../../store/core/schema";
import {EntityType} from "../../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import toastr from "toastr";
import axios from "axios";
import {API_URL} from "../../../../config";
import {getAxiosDefaultConfig} from "../../../../utils";
import PropCardSettings from "./components/PropCardSettings";
import UsagesReport from "./components/UsagesReport";
import {createProperty, deleteProperty, updateProperty} from "../../../../store/property/actions";
import ListToggle from "../../../../components/Common/ListToggle";
import {useListToggle, useContainers} from "../../../../helpers/hooks";
import ListSettings from "../../../../components/Common/ListSettings";

const entityType = EntityType.Property;

const breadcrumbs = [
  {title: "Settings", link: "/settings"},
  {title: "Content Model", link: "/settings/content-model"},
  {title: "Properties", link: "#"},
];

const Properties = (props) => {
  const uuid = props.match.params.id;
  const containerGetter = props.context.Container.get;
  const {context, createProperty, updateProperty, deleteProperty} = props;
  const {result, isLoading, error} = context.Property.getAll;
  const currentProperty = context.Property.get.result;
  const namespaces = context.Namespace.getAll.result;
  const qualifiers = context.Qualifier.getAll.result;
  const propertyTypes = context.PropertyType.getAll.result;
  const [openModal, setOpenModal] = useState(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const [isList, toggleList] = useListToggle();
  const [selectedProps, setSelectedProps] = useState([]);
  const selectedProp = selectedProps[0];
  const [openUsages, setOpenUsages] = useState(false);
  const [usages, setUsages] = useState();

  useEffect(() => {
    setEntity([]);
  }, []);

  const handleEntityClick = useCallback((entity) => {
    setEntity(entity);
    setOpenModal(true);
  }, []);

  useEffect(() => {
    setEntity(currentProperty);
  }, [currentProperty]);

  const handleCreate = () => {
    setEntity(Schema[entityType]);
    setOpenModal(true);
  };

  const toggleModal = () => {
    setOpenModal((prevState) => !prevState);
    setEntity([]);
  };

  const {handleContainerClick, handleContextClick} = useContainers({
    history: props.history,
    thisContainerUuid: uuid,
    setEntity,
    containerGetter,
  });

  const handlePropClick = (route) => {
    props.history.push({
      pathname: route,
    });
  };

  const handlePropSelect = (e, property) => {
    const multi = e.metaKey || e.ctrlKey;
    setSelectedProps((prevState) => {
      return !multi ? [property] : prevState.includes(property.id) ? prevState.filter((x) => x !== property.id) : [...prevState, property];
    });
  };

  const handleContextPropClick = (e, property) => {
    setSelectedProps((prevState) => {
      const exist = prevState.includes(property.id);
      if (prevState.length > 1 && exist) {
        return [...prevState.filter((x) => x !== property.id), property.id];
      } else {
        return [property];
      }
    });
  };

  const handleUsage = async () => {
    const payload = {};
    await axios
      .post(`${API_URL}/properties/${selectedProp.propertyKey}/usages`, payload, getAxiosDefaultConfig())
      .then((res) => {
       if (res.data.containers.length > 0 || res.data.datastreams.length > 0) {
         setOpenUsages(true);
         setUsages(res.data);
       } else {
         toastr.warning("No usage of the selected property");
       }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleUsageCm = async () => {
    const payload = {};
    await axios
      .post(`${API_URL}/properties/${selectedProp.propertyKey}/usages_cm`, payload, getAxiosDefaultConfig())
      .then((res) => {
       if (res.data.containerTypes.length > 0 || res.data.datastreamTypes.length > 0) {
         setOpenUsages(true);
         setUsages(res.data);
       } else {
         toastr.warning("No content model usage of the selected property");
       }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const getIcon = (type) => {
    switch (type) {
      case "text":
        return "bx-detail";
      case "spatial-map":
        return "bxs-map-alt";
      case "boolean":
        return "bx-toggle-right";
      case "url":
        return "bx-link";
      case "enum":
        return "bx-menu";
      case "date":
        return "bx-calendar-alt";
      case "time":
        return "bx-time";
      case "ip-address-v4":
        return "bx-network-chart";
      case "number":
        return "bxs-detail";
      case "text-area":
        return "bx-detail";
      case "json":
        return "bx-code-curly";
      case "rich-text":
        return "bx-detail";
      case "multi-select":
        return "bx-list-plus";
      case "vocabulary-flat":
        return "bx-book-alt";
      case "reference":
        return "bx-link-external";
      default:
        return "bx-detail";
    }
  };

  const propertyCards = useMemo(() => {
    return result.map((containerType) => ({
      label: containerType.displayName,
      type: containerType.propertyType,
      icon: getIcon(containerType.propertyType),
      handleClick: () => handleEntityClick(containerType),
      propertyKey: containerType.propertyKey,
      description: containerType.description,
      propertyType: containerType.propertyType,
      id: containerType.id,
    }));
  }, [handleEntityClick, result]);

  const propertyList = useMemo(() => {
    return result.map((containerType) => ({
      label: containerType.displayName,
      type: containerType.propertyType,
      key: containerType.propertyKey,
      handleClick: () => handleEntityClick(containerType),
      description: containerType.description,
      id: containerType.id,
    }));
  }, [handleEntityClick, result]);

  const listColumns = [
    {name: "Name", prop: "label"},
    {name: "Type", prop: "type"},
    {name: "Code", prop: "key"},
  ];

  return (
    <>
      <BaseContent
        title={"Properties"}
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
        handleContextClick={handleContextClick}
        handleContainerClick={handleContainerClick}
      >
        {!isList && (
          <PropCardSettings
            properties={propertyCards}
            handlePropClick={handlePropClick}
            handlePropSelect={handlePropSelect}
            handleContextPropClick={handleContextPropClick}
            selectedProp={selectedProp}
            handleUsage={handleUsage}
            handleUsageCm={handleUsageCm}
            // setDeleteProp={setDeleteProp}
            // deleteProp={deleteProp}
          />
        )}
        {isList && <ListSettings contents={propertyList} columns={listColumns} />}
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={openModal}
          handleClose={toggleModal}
          createEntity={createProperty}
          updateEntity={updateProperty}
          deleteEntity={deleteProperty}
          propertyTypes={propertyTypes}
          context={context.Property}
          namespaces={namespaces}
          qualifiers={qualifiers}
        />
      </BaseContent>
      {usages && <UsagesReport selectedProp={selectedProp} open={openUsages} handleClose={() => setOpenUsages()} usages={usages} setUsages={setUsages} />}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createProperty,
  updateProperty,
  deleteProperty,
})(Properties);
