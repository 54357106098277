import React from "react";

export default function Toolbar(props) {
  return (
    <React.Fragment>
      {props.togglePanel && (
        <button
          onClick={props.togglePanel}
          type="button"
          disabled={props.busy}
          className="btn font-size-18"
          data-toggle="fullscreen"
        >
          <i className="bx bx-info-circle" />
        </button>
      )}
      {props.toggleListLayout && (
        <button
          type="button"
          onClick={props.toggleListLayout}
          className="btn font-size-18"
          data-toggle="fullscreen"
        >
          {!props.showList && <i className="bx bx-list-ul" />}
          {props.showList && <i className="bx bx-grid" />}
        </button>
      )}
      {props.handleUpload && (
        <button
          type="button"
          disabled={props.busy}
          onClick={props.handleUpload}
          className="btn font-size-18"
          data-toggle="fullscreen"
        >
          <i className="bx bx-cloud-upload" />
        </button>
      )}
      {props.handleDownload && (
        <button
          type="button"
          disabled={props.busy}
          onClick={props.handleDownload}
          className="btn font-size-18"
          data-toggle="fullscreen"
        >
          <i className="bx bx-cloud-download" />
        </button>
      )}      
      {props.handleReset && (
        <button
          type="button"
          disabled={props.busy}
          onClick={props.handleReset}
          className="btn font-size-18"
          data-toggle="fullscreen"
        >
          <i className="bx bx-reset" />
        </button>
      )}
      {props.handleEdit && (
        <button
          type="button"
          disabled={props.busy}
          onClick={props.handleEdit}
          className="btn font-size-18"
          data-toggle="fullscreen"
        >
          <i className="bx bx-edit" />
        </button>
      )}
      {/*<button*/}
      {/*  type="button"
      disabled={props.busy} */}
      {/*  className="btn font-size-18"*/}
      {/*  data-toggle="fullscreen"*/}
      {/*>*/}
      {/*  <i className="bx bx-user-plus" />*/}
      {/*</button>*/}
      {/*<button*/}
      {/*  type="button"
      disabled={props.busy} */}
      {/*  className="btn font-size-18"*/}
      {/*  data-toggle="fullscreen"*/}
      {/*>*/}
      {/*  <i className="bx bx-show" />*/}
      {/*</button>*/}
      {props.handleDelete && (
        <button
          onClick={props.handleDelete}
          type="button"
          disabled={props.busy}
          className="btn font-size-18"
          data-toggle="fullscreen"
        >
          <i className="bx bx-trash" />
        </button>
      )}
    </React.Fragment>
  );
}
