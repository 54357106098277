import React, {useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import axios from 'axios';
import {downloadThumb, exportFile, fetchData, getAxiosDefaultConfig, getIconForDatastream, postData,} from '../utils';
import {ApiEndpoint} from '../store/core/endpoint';
import {EntityType} from '../store/core/entityType';
import toastr from 'toastr';
import {Schema} from '../store/core/schema';
import {ObjectType} from '../constants/enums';
import {API_URL} from '../config';
import moment from 'moment';
import {Col} from 'reactstrap'

const DataStreamThumb = ({file, thumbnail}) => {

  const [imageThumb, setImageThumb] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (file.thumbnail.length > 0)
      downloadThumb(file, thumbnail, setImageThumb, isLoading, setIsLoading)
    // eslint-disable-next-line
  }, [file.uuid])

  if (isLoading) return <Col xs={2} className="p-1">
    <i className={`bx ${getIconForDatastream(file.type.name)} avatar-title`}
       style={{width: '45px', height: '45px', borderRadius: '10px', backgroundColor: '#bec0d0', color: '#606371'}}/>
  </Col>

  return <>
    {imageThumb == null ? <Col xs={2} className="p-1">
        <i className={`bx ${getIconForDatastream(file.type.name)} avatar-title`}/>
      </Col> :
      <Col xs={2} className="p-1">
        <div style={{width: '40px', height: '40px', borderRadius: '20px'}}>
          <img id={`fileId-${file.uuid}`} src={imageThumb} alt={`${thumbnail} thumb`} className="avatar-title"
               style={{width: '45px', height: '45px', borderRadius: '10px'}}/>
        </div>
      </Col>
    }
  </>
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (prop) => (e) => {
    const value = e.target ? e.target.value : e.value || e;
    setValues({
      ...values,
      [prop]: value,
    });
  };
  const handleChangeArray = (prop, array) => {
    setValues({
      ...values,
      [prop]: array,
    });
  };
  const updateInitial = React.useCallback((values) => {
    setValues(values);
  }, []);

  return [values, handleChange, updateInitial, handleChangeArray];
};

function useProgress(
  startedCallback,
  successCallback,
  errorCallback,
  monitored,
  dbg
) {
  const inProgress = useRef(monitored.isLoading);

  useEffect(() => {
    const {isLoading, error, result} = monitored;
    dbg &&
    console.log(
      `Effect executing for ${dbg} { isLoading: ${isLoading}, inProgress.current: ${inProgress.current}, error: ${error} }`
    );

    // If loading just started
    if (isLoading && !inProgress.current) {
      inProgress.current = true;
    }

    if (inProgress.current) {
      if (isLoading) {
        startedCallback && startedCallback();
      } else {
        inProgress.current = false;
        if (error) {
          // Loader has finished and an error was dispatched
          errorCallback && errorCallback(error);
        } else {
          // Loader has finished successfully
          successCallback && successCallback(result);
        }
      }
    }
  }, [monitored, startedCallback, successCallback, errorCallback, dbg]);
}

// Creates the entity progress hooks
const useEntityProgress = (context, onClose) => {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    if (busy) setError(null);
  }, [busy]);

  // Methods for tracking progress
  const showLoader = useCallback((_) => setBusy(true), []);
  const hideLoaderAndClose = useCallback(
    (_) => {
      setBusy(false);
      onClose(true);
    },
    [onClose]
  );
  const hideLoaderAndError = useCallback((e) => {
    setBusy(false);
    setError(e);
    console.log('Operation failed ', e);
  }, []);

  // Hooks for showing the busy indicator
  useProgress(
    showLoader,
    hideLoaderAndClose,
    hideLoaderAndError,
    context.update
  );
  useProgress(
    showLoader,
    hideLoaderAndClose,
    hideLoaderAndError,
    context.create
  );
  useProgress(
    showLoader,
    hideLoaderAndClose,
    hideLoaderAndError,
    context.delete
  );

  return [busy, error];
};

const useFetch = (url, initialState, pollTimeout) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const getData = useCallback((url, callback) => {
    axios
      .get(url, getAxiosDefaultConfig())
      .then((res) => {
        setData(res.data);
        setError(null);
        callback && callback();
      })
      .catch((ex) => {
        setError(ex);
        setData(null);
        callback && callback();
      });
  }, []);

  useEffect(() => {
    getData(url, () => setIsLoading(false));
  }, [url, getData]);

  const pollRef = useRef();
  useEffect(() => {
    clearInterval(pollRef.current);
    if (pollTimeout) {
      pollRef.current = setInterval(() => {
        getData(url);
      }, pollTimeout * 1000);
    }
    return () => {
      clearInterval(pollRef.current);
    };
  }, [pollTimeout, url, getData]);

  if (!url) {
    // Fix for not make requests with invalid url
    // returns the default state
    return [false, initialState, '', setData];
  }
  return [isLoading, data || initialState, error, setData];
};

const useFiltered = (array, query, filterProps) => {
  return useMemo(() => {
    try {
      return array.filter((x) => {
        const arrayOfProps = filterProps.map((prop) => x[prop]);
        return arrayOfProps.find((value) =>
          value.toLowerCase().includes(query.toLowerCase())
        );
      });
    } catch (e) {
      return [];
    }
  }, [array, filterProps, query]);
};

const useToggle = (initialValue = false) => {
  const [open, setOpen] = useState(initialValue);

  const handleToggle = useCallback(
    () => setOpen((prevState) => !prevState),
    []
  );

  return [open, handleToggle];
};

const usePaging = (
  url,
  {size, page, identifier},
  params,
  pollTimeout = null
) => {
  const pollRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [allContents, setAllContents] = useState([]);
  const [data, setData] = useState({
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: size,
    totalElements: 0,
    totalPages: 0,
  });

  const getData = useCallback((url, size, page, params, callback) => {
    let finalUrl = url + '?size=' + size + '&page=' + page;
    if (params) {
      finalUrl += params;
    }
    axios
      .get(finalUrl, getAxiosDefaultConfig())
      .then((res) => {
        setData(res.data);
        callback && callback();
      })
      .catch(() => {
        callback && callback();
      });
  }, []);

  useEffect(() => {
    setAllContents((prevAllData) => {
      const newContent = data.content.filter(
        (x) =>
          prevAllData.findIndex((z) => z[identifier] === x[identifier]) === -1
      );
      return [...prevAllData, ...newContent];
    });
  }, [data.content, identifier]);

  useEffect(() => {
    setAllContents([]);
  }, [url]);

  useEffect(() => {
    setIsLoading(true);
    if (url) getData(url, size, page, params, () => setIsLoading(false));
  }, [url, size, page, getData, params]);

  useEffect(() => {
    clearInterval(pollRef.current);
    if (pollTimeout) {
      pollRef.current = setInterval(() => {
        getData(url, size, page, params);
      }, pollTimeout * 1000);
    }
    return () => {
      clearInterval(pollRef.current);
    };
  }, [pollTimeout, url, size, page, getData, params]);

  if (!url) {
    // Fix for not make requests with invalid url
    // returns the default state
    return [
      false,
      {
        content: [],
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 0,
        size: size,
        totalElements: 0,
        totalPages: 0,
      },
      [],
    ];
  }
  return [isLoading, data, allContents];
};

const useContainers = ({
                         history,
                         thisContainerUuid,
                         setEntity,
                         showList,
                         containerGetter,
                       }) => {
  // const [selectedContainer, setSelectedContainer] = React.useState(null);
  const [currentContainer, setCurrentContainer] = useState({});
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [busy, setBusy] = useState(false);
  const selectedContainer = selectedContainers[0];

  const uuid = selectedContainer || thisContainerUuid;
  const url = uuid ? ApiEndpoint[EntityType.Container] + '/' + uuid : null;
  const tagsUrl = url ? url + '/tags' : null;

  const [loadingTags, tags] = useFetch(tagsUrl, []);

  const handleContainerClick = (container) => {
    history.push({
      pathname: '/containers/' + container.uuid,
    });
  };

  const fetchContainer = useCallback(async (uuid) => {
    const url = ApiEndpoint[EntityType.Container] + '/' + uuid;
    try {
      setBusy(true);
      const container = await fetchData(url);
      setCurrentContainer({...container, id: container.uuid});
      setBusy(false);
    } catch (e) {
      setCurrentContainer({});
      setBusy(false);
    }
  }, []);

  React.useEffect(() => {
    if (
      thisContainerUuid &&
      (containerGetter.isLoading || !containerGetter.result)
    ) {
      setCurrentContainer({});
    } else if (
      thisContainerUuid &&
      selectedContainer === containerGetter.result.uuid
    ) {
      setCurrentContainer({...containerGetter.result, id: thisContainerUuid});
    } else if (selectedContainer || thisContainerUuid) {
      fetchContainer(selectedContainer || thisContainerUuid);
    } else {
      setCurrentContainer({});
    }
  }, [
    containerGetter.isLoading,
    containerGetter.result,
    fetchContainer,
    selectedContainer,
    thisContainerUuid,
  ]);

  const handleContainerSelect = (e, container) => {
    const multi = e.metaKey || e.ctrlKey;
    setSelectedContainers((prevState) => {
      return !multi
        ? [container.uuid]
        : prevState.includes(container.uuid)
          ? prevState.filter((x) => x !== container.uuid)
          : [...prevState, container.uuid];
    });
  };

  const handleContextClick = (e, container) => {
    setSelectedContainers((prevState) => {
      const exist = prevState.includes(container.uuid);
      if (prevState.length > 1 && exist) {
        return [
          ...prevState.filter((x) => x !== container.uuid),
          container.uuid,
        ];
      } else {
        return [container.uuid];
      }
    });
  };

  const handlePageClick = useCallback(() => {
    setSelectedContainers([thisContainerUuid] || null);
  }, [thisContainerUuid]);

  React.useEffect(() => {
    setSelectedContainers([thisContainerUuid] || null);
  }, [showList, thisContainerUuid]);

  useEffect(() => {
    if (currentContainer.id && currentContainer.id !== thisContainerUuid) {
      setEntity(currentContainer);
    } else {
      setEntity(Schema[EntityType.Container]);
    }
  }, [currentContainer, setEntity, thisContainerUuid]);

  const isSelected = (id) => selectedContainers.includes(id);

  return {
    selectedContainer,
    selectedContainers,
    currentContainer,
    tags,
    busy: busy || loadingTags,
    handleContainerSelect,
    handlePageClick,
    handleContainerClick,
    handleContextClick,
    isSelected,
  };
};

const useContainerActions = (parentContainer, containers, refreshCallback,isRoot=null) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleStarContainers = (starred) => {
    const url = ApiEndpoint[EntityType.Tag] + '/starred';

    const payloads = containers
      .filter((x) => x.starred !== starred)
      .map((container) => ({
        starred: starred,
        objectId: container.uuid,
        objectType: 'CONTAINER',
      }));

    const requests = payloads.map((payload) =>
      axios.post(url, payload, getAxiosDefaultConfig())
    );

    setIsLoading(true);
    Promise.all(requests)
      .then(() => {
        setIsLoading(false);
        refreshCallback && refreshCallback();
      })
      .catch((ex) => {
        toastr.error(ex.message, 'Error');
        setIsLoading(false);
      });
  };

  const handleDeleteContainers = () => {
    
    const requests = containers.map((container) => {
      //const url = isRoot ? ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove` : ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove?from=${parentContainer.uuid}`;
      const url =
        isRoot || (!!container.paths && container.paths.length === 0)
          ? ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove`
          : !!container.paths && container.paths.length > 0
          ? ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove?from=${container.paths[0].nodes[container.paths[0].nodes.length - 1].uuid}`
          : ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove?from=${parentContainer.uuid}`;
      return axios.post(url, null, getAxiosDefaultConfig());
    });

    setIsLoading(true);
    Promise.all(requests)
      .then(() => {
        setIsLoading(false);
        refreshCallback && refreshCallback();
      })
      .catch((ex) => {
        toastr.error(ex.message, 'Error');
        setIsLoading(false);
      });
  };

  const handleContainersExport = async (mode, callback) => {
    const url = ApiEndpoint[EntityType.Export];
    const requests = containers.map((container) => {
     // const data = {uuid: container.uuid, mode: mode};
      const data = { ...mode, containers: [container.uuid] }
      return exportFile(url, data, container.label);
    });

    setIsLoading(true);

    if (containers.length > 0) {
      // Export selected containers
      Promise.all(requests)
        .then(() => {
          setIsLoading(false);
          callback(null, {});
        })
        .catch((ex) => {
          setIsLoading(false);
          callback(ex, null);
        });
    } else {
      try {
        // Export root directory
        await exportFile(url, {mode}, 'root');
        setIsLoading(false);
      } catch (e) {
        callback(e);
        setIsLoading(false);
      }
    }
  };

  return {
    isLoading,
    handleStarContainers,
    handleDeleteContainers,
    handleContainersExport,
  };
};

const useDataStreams = (dataStreamUuid) => {
  const url = ApiEndpoint[EntityType.DataStream] + '/' + dataStreamUuid;
  const previewUrl = url + '/preview';
  const tagsUrl = url + '/tags';
  const [preview, setPreview] = useState('')
  const [loadingPreview, setLoadingPreview] = useState(true)

  useEffect(() => {
    if (dataStreamUuid) {
      setLoadingPreview(true)
      axios.get(previewUrl, getAxiosDefaultConfig({blob: true}))
        .then(response => {

          const {data} = response;
          const url = URL.createObjectURL(data);
          setPreview(url)
          setLoadingPreview(false)
        })
    }
  }, [dataStreamUuid, previewUrl])


  const [loadingTags, tags] = useFetch(dataStreamUuid && tagsUrl, []);

  return {
    preview,
    busy: loadingPreview || loadingTags,
    tags,
  };
};

const useActivities = (uuid, entityType, page, setPage, isLoadingCurrent) => {
  const url =
    !isLoadingCurrent && uuid ? ApiEndpoint[entityType] + '/' + uuid : null;
  const activityUrl = url ? url + '/activity' : null;
  const [isLoading, activities, content] = usePaging(activityUrl, {
    size: 20,
    page: page,
    identifier: 'id',
  });

  useEffect(() => {
    setPage(0);
  }, [uuid, setPage]);

  return {
    isLoading,
    activities,
    content,
  };
};

const useEntityErrorToast = (containerContext) => {
  const error =
    (containerContext.get && containerContext.get.error) ||
    (containerContext.getAll && containerContext.getAll.error) ||
    (containerContext.delete && containerContext.delete.error) ||
    (containerContext.update && containerContext.update.error) ||
    (containerContext.create && containerContext.create.error) ||
    (containerContext.getAll && containerContext.getAll.error);

  useEffect(() => {
    if (error) toastr.error(error, 'Error');
  }, [error]);
};

const useGroupedEntitiesFetch = (url) => {
  const [isLoading, data] = useFetch(url, []);

  const containers = data.filter((x) => x.objectType === ObjectType.container);

  const dataStreams = data.filter(
    (x) => x.objectType === ObjectType.dataStream
  );

  const containerData = containers.map((container) => ({
    label: container.label,
    route: '/containers/' + container.uuid,
    icon: 'bx-folder-open',
    published: container.published,
    color:container.color
  }));

  const dataStreamData = dataStreams.map((dataStream) => ({
    label: dataStream.label,
    route: `/containers/${dataStream.membership[0]?.uuid}/?datastream=${dataStream.uuid}`,
    icon: getIconForDatastream(dataStream.type.code),
    thumbnail: <DataStreamThumb file={dataStream} thumbnail={dataStream.thumbnail}/>,
  }));

  const containerList = useMemo(() => {
    return containers.map((container) => ({
      label: container.label,
      creator: `${container.createdBy.firstname} ${container.createdBy.lastname}`,
      createdAt: moment(container.createdAt).format('Do MMMM YYYY'),
      _published: container.published,
      _route: '/containers/' + container.uuid,
      _icon: 'bx-folder',
      // handleClick: () => handleEntityClick(containerType),
      color: container.color
    }));
  }, [containers]);

  const dataStreamList = useMemo(() => {
    return dataStreams.map((dataStream) => ({
      label: dataStream.label,
      creator: `${dataStream.createdBy.firstname} ${dataStream.createdBy.lastname}`,
      createdAt: moment(dataStream.createdAt).format("Do MMMM YYYY"),
      thumbnail: <DataStreamThumb file={dataStream} thumbnail={dataStream.thumbnail} />,
      _published: dataStream.published,
      _route: `/containers/${dataStream.membership[0]?.uuid}/?datastream=${dataStream.uuid}`,
      _icon: getIconForDatastream(dataStream.type.code),
      // handleClick: () => handleEntityClick(containerType),
      color: dataStream.color,
    }));
  }, [dataStreams]);

  return {
    isLoading,
    containerData,
    dataStreamData,
    dataStreams,
    containers,
    containerList,
    dataStreamList,
  };
};

const typeDelimiter = ';'

const useAppValidation = (loginSuccess, logoutUser) => {
  const [initializing, setInitizalizing] = useState(true);

  const validateUser = React.useCallback(async () => {
    const authUser = localStorage.getItem('authUser');
    if (authUser) {
      try {
        const result = await postData(API_URL + '/auth/status');
        if (result.valid) loginSuccess(authUser);
        else logoutUser();
        setInitizalizing(false);
      } catch (e) {
        logoutUser();
        setInitizalizing(false);
      }
    } else {
      setInitizalizing(false);
    }
  }, [loginSuccess, logoutUser]);

  React.useEffect(() => {
    validateUser();
  }, [validateUser]);

  return initializing;
};

const useCheckList = (itemIds) => {
  const [list, setList] = useState([]);

  const handleCheck = (id) => {
    if (id === 'ALL') {
      if (list.length === itemIds.length) {
        setList([]);
      } else {
        setList(itemIds);
      }
    } else {
      setList((prevState) => {
        const exist = prevState.includes(id);
        return exist ? prevState.filter((x) => x !== id) : [...prevState, id];
      });
    }
  };

  useEffect(() => setList([]), [itemIds]);

  const isChecked = (id) =>
    id !== 'ALL'
      ? list.includes(id)
      : itemIds.length === list.length && itemIds.length > 0;

  return [list, handleCheck, isChecked];
};

const useAcceptedTypes = (container, context, open, isRoot) => {
  const [parentContainerType, setParentContainerType] = useState({
    containerAcceptance: '',
    datastreamAcceptance: '',
  });

  let containerTypes = []
  let dataStreamTypes = []

  useEffect(() => {
    if (open && container && container.type) {
      const url =
        ApiEndpoint[EntityType.ContainerType] + `/${container.type.code}`;
      fetchData(url)
        .then((data) => setParentContainerType(data))
        .catch((ex) => console.log(ex));
    }
  }, [container, open]);

  if (!isRoot) {
    const splittedContainerTypes = parentContainerType?.containerAcceptance.split(typeDelimiter)

    if (splittedContainerTypes[0] !== "")
    containerTypes = context.ContainerType.getAll.result.filter((x) => {
      return splittedContainerTypes.indexOf(x.code) !== -1;
    })
    else
      containerTypes = context.ContainerType.getAll.result

    const splittedDataStreamTypes = parentContainerType.datastreamAcceptance.split(typeDelimiter)
    if (splittedDataStreamTypes[0] !== "")
    dataStreamTypes = context.DataStreamType.getAll.result.filter((x) => {
      return splittedDataStreamTypes.indexOf(x.code) !== -1;
    })
    else
      dataStreamTypes = context.DataStreamType.getAll.result
  }

  else {
    containerTypes = context.ContainerType.getAll.result.filter((x) => {
      return x.allowedAtRoot === true
    })

    dataStreamTypes = context.DataStreamType.getAll.result.filter((x) => {
      return x.allowedAtRoot === true
    })
  }

  return {
    containerTypes,
    dataStreamTypes,
  };
};

const usePermission = (context) => {

  const checkPermission = (permission) => {

    const found = context.MyPermissions.getAll.result.find((p) => p.code === permission)
    if (found)
      return found?.code === permission
    else
      return false
  }

  return [checkPermission]
}

const useListToggle = () => {
  const layout = sessionStorage.getItem('repox_layout');
  const [isList, toggle] = useToggle(layout === 'LIST');

  useEffect(() => {
    if (isList) {
      sessionStorage.setItem('repox_layout', 'LIST');
    } else {
      sessionStorage.setItem('repox_layout', 'DEFAULT');
    }
  }, [isList]);

  return [isList, toggle];
};

export {
  useForm,
  useEntityProgress,
  useProgress,
  useFetch,
  useFiltered,
  useToggle,
  usePaging,
  useContainers,
  useDataStreams,
  useEntityErrorToast,
  useActivities,
  useGroupedEntitiesFetch,
  useAppValidation,
  useCheckList,
  useAcceptedTypes,
  useListToggle,
  useContainerActions,
  usePermission
};
