import React from 'react';
import {withRouter} from "react-router-dom";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useEffect, useState} from "react";
import {reorder} from "../../../../utils";


const MultipleChoice = (props) => {
  const {field, setField, selected, answerable, formDisabled, fieldNumber} = props
  const {title,description, index, details, required} = field
  const [newOption, setNewOption] = useState('')
  const [addedNew, setAddedNew] = useState(false)

  // Field extra details
  const {options, hasOtherOption} = details

  // Handle re-ordering on dnd
  const onDragEnd = (result) => {

    // dropped outside the list
    if (!result.destination) {
      return
    }

    setField({
      ...field,
      details: {
        ...details,
        options: reorder(
          options,
          result.source.index,
          result.destination.index
        )
      }
    })
  }

  // Helper to set new options
  const setOptions = (newOptions) => {
    setField({
      ...field,
      details: {
        ...details,
        options: newOptions
      }
    })
  }

  // Handle change
  const changeOption = (e,index) => {
    const value = e.target.value
    let newOptions = [...options]
    newOptions[index] = value
    setOptions(newOptions)
  }

  // Handle deletion
  const deleteOption = (index) => {
    let newOptions = [...options]
    newOptions.splice(index,1)
    setOptions(newOptions)
  }

  // Handle addition
  const changeNewOption = (e) => {
    const value = e.target.value
    setNewOption(value)
  }

  // Handle "other" option
  const handleOtherOption = (e) => {
    setField({
      ...field,
      details: {
        ...details,
        hasOtherOption: !hasOtherOption
      }
    })
  }

  // Create new entry on change
  useEffect(()=>{
    if (newOption&&newOption!=='') {
      let newOptions = [...options]
      newOptions.push(newOption)
      setOptions(newOptions)
      setNewOption('')
      setAddedNew(true)
    }
    // eslint-disable-next-line
  },[newOption])

  // Blur when previewing/editing
  useEffect(()=>{
    setAddedNew(false)
  },[selected,answerable])

  const setValue = (value) => {
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable&&required&&value===''
      },
      value
    })
  }

  const checkErrors = (e) => {
    if (!answerable) return
    if (required) {
      if (field.value===''||field.value===null) {
        setField({
          ...field,
          errors: {
            required: true
          }
        })
      }
    }
  }

  const requiredError = field.errors&&field.errors.required
  const hasError = requiredError
  const hasOtherResponse = options.indexOf(field.value) === -1 && (field.value !== null)

  // The view / preview part
  //////////////////////////
  if ((!selected) || answerable) return <>
    <legend className="col-form-label" style={{fontWeight: 500}}>{fieldNumber+1}. {title} {required&&<b>*</b>}</legend>
    <div style={{fontSize: 12, marginTop: -8, marginBottom: 8, opacity: 0.8}}>{description}</div>
    {options.map((option,i)=><FormGroup check key={i} style={{marginBottom: 8}}>
      <Label check style={{width:'100%'}}>
        <Input type="radio" name={`radio-${index}`} id={`radio-${index}`}
               disabled={formDisabled}
               checked={field.value===option}
               onChange={()=>setValue(option)}
               onBlur={checkErrors}
               invalid={hasError}
        />
        {option}
      </Label>
    </FormGroup>)}
    {hasOtherOption&&<FormGroup style={{marginLeft:20}}>
      <Input type="radio" name={`radio-${index}`} id={`radio-${index}`} style={{ marginTop: '0.8rem'}} checked={hasOtherResponse} onClick={()=>setValue('')}/>

      <Label check >
        <Input type="text" name={`radio-other-${index}`} id={`radio-other-${index}`} placeholder={'Other...'} disabled={formDisabled}
               onBlur={checkErrors}
               invalid={hasError}
               value={hasOtherResponse?field.value:''}
               onChange={(e)=>setValue(e.target.value)}
        />
      </Label>
    </FormGroup>}
  </>

  // The editor part
  ///////////////////
  return <FormGroup row>
    <Col xs={8}>
    <h6>Options</h6>
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {options.map((option, optionIndex) => {
              const shouldFocus = addedNew&&optionIndex===options.length-1

              return <Draggable key={`radio-${index}-option-${optionIndex}`} draggableId={`radio-${index}-option-${optionIndex}`} index={optionIndex}>
                {(provided) => (<div key={optionIndex} {...provided.draggableProps} ref={provided.innerRef}>
                    <div style={{position: 'relative', marginBottom: 16}} >

                      <div {...provided.dragHandleProps} style={{color: '#5c5c5c', fontSize: 18, marginBottom: 2, position: 'absolute', left: -18, top: -8}}>
                        <div style={{marginBottom: -22}}><b>..</b></div>
                        <div style={{marginBottom: -22}}><b>..</b></div>
                        <div><b>..</b></div>
                      </div>

                      <div>

                        <Input type="text" name={`radio-${index}-option-${optionIndex}-label`} id={`radio-${index}-option-${optionIndex}-label`} placeholder={'Label'}
                               value={option} onChange={(e)=>changeOption(e,optionIndex)}
                               autoFocus={shouldFocus} disabled={formDisabled}
                        />
                      </div>

                      <div style={{position: 'absolute', right: 0, top: 0}}>
                        <Button outline color="danger" onClick={()=>deleteOption(optionIndex)}>Delete</Button>
                      </div>

                    </div>

                  </div>
                )}
              </Draggable>
            })}
            {provided.placeholder}
          </div>)}
      </Droppable>
    </DragDropContext>

    <div style={{position: 'relative', marginBottom: 16}} >
      <div>
        <Input type="text" name={`new-option`} id={`new-option`} placeholder={'Add new option'} value={newOption} onChange={changeNewOption} disabled={formDisabled} />
      </div>
    </div>
    </Col>

    <Col xs={12}>


    <Row className='justify-content-end text-sm-right'>
      <Col xs={12} sm={4}>
        <Input type="checkbox" name={`multiplechoice-${index}-other}`} id={`multiplechoice-${index}-other`} checked={hasOtherOption} onChange={handleOtherOption} disabled={formDisabled} />
        <Label for={`multiplechoice-${index}-other`} check> Show 'Other' option </Label>
      </Col>
    </Row>

    </Col>
  </FormGroup>
}

export default withRouter(MultipleChoice);
