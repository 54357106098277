import React, {useEffect, useState} from 'react'
import BaseDialog from '../../../../../components/Common/BaseDialog'
import {FormGroup} from '@material-ui/core'
import {Input, Label} from 'reactstrap'

const EditTerm = (props) =>{
  const {term,open,handleClose,handleUpdate,handleDelete} = props
  const [values,setValues] = useState({termId:'',label:'',termUri:''})

  useEffect(()=>{
    if(open) {
      setValues(term)
    }
    // eslint-disable-next-line
  },[open])

  return <BaseDialog
    title={'Edit term'}
    primaryAction={()=>{
      handleUpdate(values)
      handleClose()
    }}
    deleteAction={()=> {
      handleDelete(term.termId)
      handleClose()
    }}
    primaryActionTitle={'Save'}
    open={open}
    handleClose={handleClose}
    scrollable={true}
    size='md'

    // contents={contents}
  >
    <FormGroup>
      <Label>Term Id</Label>
      <Input disabled type={'text'}  value={values.termId}/>
    </FormGroup>
    <FormGroup>
      <Label>Label</Label>
      <Input type={'text'} value={values.label} onChange={(e)=>{setValues({...values,label: e.target.value})}}/>
    </FormGroup>
    <FormGroup>
      <Label>Term Uri</Label>
      <Input type={'text'} value={values.termUri} onChange={(e)=>{setValues({...values,termUri: e.target.value})}}/>
    </FormGroup>
  </BaseDialog>
}

export default EditTerm