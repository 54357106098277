import React from 'react';
import {withRouter} from "react-router-dom";
import {Col, Row} from "reactstrap";
import {Bar} from "react-chartjs-2";
import {GRAPH_COLORS} from "../../../components/consts";
import {truncateString} from "../../../../../utils";

const MultipleChoicesGrid = (props) => {
  const {field, fieldNumber} = props
  const {questionTitle, answerCount, answerSummary} = field

  const optionsLabels = answerSummary.rows.map((o)=>o.label)
  const optionsData = answerSummary.columns.map((o,i)=>{
    return {
      label: o.label,
      data: o.data,
      backgroundColor: GRAPH_COLORS[i]
    }
  })

  const data = {
    labels: optionsLabels,
    datasets: optionsData,
  }

  const optionsBar = {
    barValueSpacing: 20,
    scales: {
      xAxes: [
        {
          ticks: {
            stepSize: 1,
            callback: function(value) {
              return truncateString(value,20)
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1

          },
        },
      ],
    },
  }

  return <Row style={{minHeight:440}}>
    <Col xs={12}>
      <div style={{fontSize: 14, marginTop: -8, marginBottom: 8, fontWeight: 'bold'}}>{fieldNumber+1}. {questionTitle}</div>
      <div style={{fontSize: 14, marginTop: -8, marginBottom: 8}}>{answerCount} responses</div>
    </Col>

    <Col xs={6}>

    </Col>

    <Col xs={12}>
      <Bar width={50} height={20} data={data} options={optionsBar} />
    </Col>
  </Row>
}

export default withRouter(MultipleChoicesGrid);
