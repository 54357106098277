import React, {useCallback, useMemo, useRef, useState} from 'react'
import BaseContent from "../../../components/Common/BaseContent";
import Container from "reactstrap/lib/Container";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import {Col, Input, Label, Row, Button} from "reactstrap";
import MobileMockup from "../components/MobileMockup";
import ReactCountryFlag from "react-country-flag"
import 'react-image-crop/dist/ReactCrop.css'
import {ArrowBack} from "@material-ui/icons";
import SlideAssetsDialog from "../../Story/Stories/Slideshow/components/SlideAssetsDialog";
import {assetThumb, reorder} from "../../../utils";
import Carousel from 'react-elastic-carousel';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import Paper from "@material-ui/core/Paper";


const languages = [{countryCode: 'GR'}, {countryCode: 'US'}, {countryCode: 'IT'}, {countryCode: 'FR'}]

const CreationSection = (props) => {
  const {title, description = "", expanded, handleChange, section} = props
  return <Grid item xs={12} style={{padding: 10, borderRadius: '6px'}}>
    <Accordion expanded={expanded === section} onChange={() => handleChange(section)}>
      <AccordionSummary
        expandIcon={<i className='bx bxs-chevron-down'></i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        style={{backgroundColor: '#dce1fb'}}
      >
        <Grid container alignContent={'center'} alignItems={'center'} spacing={2}>
          <Grid item>
            <Typography style={{fontWeight: 'bold', color: '#4e60b8'}}>{title}</Typography>
          </Grid>
          <Grid item>
            <Typography style={{fontSize: 10, color: '#716e6e'}}>{description}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{backgroundColor: '#f0f3ff',}}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  </Grid>
}

const useStyles = makeStyles(() => ({
  draggingListItem: {
    background: "rgb(235,235,235)"
  },
  flexPaper: {
    flex: 1,
    margin: 16,
    minWidth: 350
  }
}));

const GuideCreationWizard = (props) => {
  const classes = useStyles();

  const [sectionOpened, setSectionOpened] = useState('section1')
  const [selectedLanguages, setSelectedLanguages] = useState([])

  const [logoImage, setLogoImage] = useState(undefined);
  const [coverImage, setCoverImage] = useState(undefined);
  const [onboardingImages, setOnboardingImages] = useState([]);

  const [openAssetsDialog, setOpenAssetsDialog] = useState(false)
  const [handleImage, setHandleImage] = useState('')

  const [appTitle, setAppTitle] = useState('')
  const [appDescription, setAppDescription] = useState('')

  const [welcomeText, setWelcomeText] = useState([])

  const handleImageChange = useCallback(async (uuid, from) => {

    switch (handleImage) {
      case 'logo': {
        await assetThumb(uuid, setLogoImage, from)
        return true
      }
      case 'cover': {
        await assetThumb(uuid, setCoverImage, from)
        return true
      }
      case 'onboarding': {
        await assetThumb(uuid, setOnboardingImages, from, onboardingImages, true)
        return true
      }
      default: {
        return true
      }
    }
  }, [handleImage, onboardingImages])

  const handleChangeOpenedSection = (sectionSelected) => {

    if (sectionSelected === sectionOpened)
      setSectionOpened('')
    else
      setSectionOpened(sectionSelected)
  }

  const handleSelectLanguage = useCallback((countryCode) => {
    if (selectedLanguages.find((c) => c === countryCode)) {
      const deleteSelectedLanguage = selectedLanguages.filter((c) => c !== countryCode)
      setSelectedLanguages(deleteSelectedLanguage)
      const finalWelcomeTexts = {}

      Object.keys(welcomeText).map((lc) => {
        if (lc !== countryCode)
          finalWelcomeTexts[lc] = welcomeText[lc]
      })

      setWelcomeText(finalWelcomeTexts)
    } else
      setSelectedLanguages((prevState) => [...prevState, countryCode])
  }, [selectedLanguages, welcomeText])

  const renderMobileView = useCallback((section) => {

    if (section === 'section2' || section === 'section3' || section === '') {
      return <Grid container style={{
        height: '525px',
        width: '280px',
        backgroundImage: coverImage && `url(${coverImage})`,
        backgroundSize: 'cover',
      }}>
        <Grid item style={{
          marginTop: 210,
          marginLeft: 20,
          backgroundImage: logoImage && `url(${logoImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          width: 340,
          height: 61
        }} xs={10}/>
      </Grid>
    } else if (section === 'section4') {
      return <div style={{marginTop: 10, width: 280, padding: 10}}>
        <Grid container direction={'column'}>
          <Grid item>
            <Grid container direction={'row'} alignItems={'center'} alignContent={'center'} spacing={1}>
              <Grid item>
                <ArrowBack/>
              </Grid>
              <Grid item>
                <Typography variant={'h5'}>Select Language</Typography>
              </Grid>
            </Grid>
          </Grid>
          {onboardingImages?.length > 0 ?
            <Carousel
              showArrows={false}
            >
              {onboardingImages?.map((onBoardingImage, idx) => {
                return <Grid key={idx} item style={{
                  backgroundColor: '#d5d5d5',
                  height: 360,
                  width: '100%',
                  overflow: 'hidden'
                }}>
                  <img src={onBoardingImage} height={360}/>
                </Grid>
              })}
            </Carousel>
            : <Grid item style={{
              marginTop: 30,
              backgroundColor: '#d5d5d5',
              height: 360,
              width: '100%',
              overflow: 'hidden'
            }}/>
          }

          <Grid item style={{marginTop: 10, backgroundColor: '#d5d5d5', width: '100%'}}>
            <Button style={{backgroundColor: '#000', color: '#fff', width: '100%', fontWeight: 'bold'}}>SKIP</Button>
          </Grid>
        </Grid>

      </div>
    }
  }, [logoImage, coverImage, welcomeText, onboardingImages])

  const handleChangeWelcomeText = useCallback((e, lang) => {
    setWelcomeText({...welcomeText, [lang]: e?.target?.value})
  }, [welcomeText])

  const isCreateDisabled = useMemo(() => {
    return Object.values(welcomeText)?.length === 0 || appTitle === '' || selectedLanguages?.length === 0 || !logoImage || !coverImage
  }, [welcomeText, appTitle, selectedLanguages, logoImage, coverImage])


  const onDragEnd = ({destination, source}) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(onboardingImages, source.index, destination.index);
    setOnboardingImages(newItems);
  };

  return <BaseContent
  >
    <Container style={{paddingTop: 30, minHeight: '120px'}}>
      <Grid container spacing={1}>
        <Grid item lg={8} spacing={2}>
          <Grid container direction={'column'}>
            <CreationSection title={'Basic App Information'}
                             description={'Add Basic information as it will be shown on App Stores'}
                             handleChange={handleChangeOpenedSection} section={'section1'} expanded={sectionOpened}>
              <Row xs={12}>
                <Col xs={8}>
                  <Label for={`guideTitle`} required>Title <b>*</b></Label>
                  <Input type="text" name={`guideTitle`} onChange={(e) => setAppTitle(e.target.value)} value={appTitle}
                         required id={`guideTitle`}
                         placeholder={`Repox Guide`}
                  />
                </Col>
                <Col xs={10} style={{marginTop: 15}}>
                  <Label for={`guideDescription`}>Description</Label>
                  <Input type="textarea" name={`guideDescription`} id={`guideDescription`}
                         value={appDescription}
                         onChange={(e) => setAppDescription(e.target.value)}
                         placeholder={`Repox Guide`}
                         rows={2}
                  />
                </Col>
                <Col xs={10} style={{marginTop: 15}}>
                  <Label>Languages <b>*</b></Label>
                  <Grid container spacing={6}>
                    {languages.map((country) =>
                      <Grid item xs={1} key={country.countryCode}>
                        <IconButton
                          style={{backgroundColor: selectedLanguages.find((i) => i === country.countryCode) ? '#8cd780' : '#fff'}}
                          onClick={() => handleSelectLanguage(country.countryCode)}><ReactCountryFlag
                          countryCode={country.countryCode}/></IconButton>
                      </Grid>
                    )}

                  </Grid>
                </Col>
              </Row>
            </CreationSection>
            <CreationSection title={'Splash Screen'} description={'Initial Screen of the mobile app'}
                             handleChange={handleChangeOpenedSection} section={'section2'} expanded={sectionOpened}>
              <Row xs={12}>
                <Col xs={8}>
                  <Label required>Logo (1148×249) <b>*</b></Label>
                </Col>
                <Col xs={12}>
                  <div onClick={() => {
                    setHandleImage('logo')
                    setOpenAssetsDialog(true)
                  }
                  } style={{
                    borderRadius: 25,
                    width: 280,
                    height: 80,
                    border: '1px dashed #b3b3b3',
                    textAlign: 'center',
                    cursor: 'pointer',
                    userSelect: 'none',
                    fontSize: 20,
                    fontWeight: 100,
                    color: '#767676ee',
                    padding: 20,
                    backgroundImage: logoImage && `url(${logoImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center'
                  }}>
                    {logoImage === undefined && <span>+</span>}
                  </div>
                </Col>
              </Row>
              <Row xs={12}>
                <Col xs={8}>
                  <Label required>Cover Image (1920x1200) <b>*</b></Label>
                </Col>
                <Col xs={12}>
                  <div onClick={() => {
                    setOpenAssetsDialog(true)
                    setHandleImage('cover')
                  }
                  } style={{
                    borderRadius: 25,
                    width: 180,
                    height: 240,
                    border: '1px dashed #b3b3b3',
                    textAlign: 'center',
                    cursor: 'pointer',
                    userSelect: 'none',
                    fontSize: 20,
                    fontWeight: 100,
                    color: '#767676ee',
                    padding: 100,
                    backgroundImage: coverImage && `url(${coverImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center'
                  }}>
                    {coverImage === undefined && <span>+</span>}
                  </div>
                </Col>
              </Row>
            </CreationSection>
            <CreationSection title={'Welcome Text'} description={'Type a welcome message'}
                             handleChange={handleChangeOpenedSection} section={'section3'} expanded={sectionOpened}>
              <Container>
                {selectedLanguages?.length > 0 ? selectedLanguages.map((language) => {
                    return <Row xs={12} key={language} style={{marginTop: 10}}>
                      <Col xs={12}>
                        <Label id={`welcome${language}`}>Welcome Text in <ReactCountryFlag countryCode={language}/>
                          <b>*</b></Label>
                        <Input type="text"
                               value={welcomeText[language]}
                               className="form-control"
                               width={'100%'}
                               id={`welcome${language}`}
                               placeholder={`Type a welcome message`}
                               onChange={(e) => handleChangeWelcomeText(e, language)}
                        />
                      </Col>
                    </Row>
                  })
                  :
                  <Row>
                    <Col xs={12}>
                      <Typography variant={'body2'}>You have no language selected <span
                        style={{cursor: 'pointer', color: '#4e60b8', userSelect: 'none'}}
                        onClick={() => handleChangeOpenedSection('section1')}>Select a language</span></Typography>
                    </Col>
                  </Row>
                }
              </Container>
            </CreationSection>
            <CreationSection title={'Onboarding'} description={'Onboard your users'}
                             handleChange={handleChangeOpenedSection} section={'section4'} expanded={sectionOpened}>
              <Container>
                    <Paper className={classes.flexPaper}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable-list" direction="horizontal">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {onboardingImages.map((onboardingImage, index) => (
                                <Draggable
                                  draggableId={`image-${index}`}
                                  index={index}
                                  key={`image-${index}`}
                                >
                                  {(provided, snapshot) => (
                                    <img src = {onboardingImage}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      width={100} height={100}
                                      className={
                                        snapshot.isDragging ? classes.draggingListItem : ""
                                      }
                                    />
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Paper>
                  <>
                    <Typography variant={'caption'}>Add images</Typography>
                    <div onClick={() => {
                      setHandleImage('onboarding')
                      setOpenAssetsDialog(true)
                    }} style={{
                      borderRadius: 20,
                      width: 40,
                      height: 40,
                      border: '1px dashed #b3b3b3',
                      textAlign: 'center',
                      cursor: 'pointer',
                      userSelect: 'none',
                      fontSize: 20,
                      fontWeight: 100,
                      color: '#767676ee',
                      padding: 2,
                    }}>
                      <span>+</span>
                    </div>
                  </>
              </Container>
            </CreationSection>
          </Grid>
          <div style={{float: 'right', marginRight: 10}}>
            <Button color={'primary'} disabled={isCreateDisabled}>
              Create</Button>
          </div>
        </Grid>
        <Grid item lg={4} style={{position: 'fixed', right: 60}}>
          <MobileMockup>
            {renderMobileView(sectionOpened)}
          </MobileMockup>
        </Grid>
      </Grid>
    </Container>
    <SlideAssetsDialog open={openAssetsDialog}
                       multiple={true}
                       handleChange={handleImageChange}
                       handleClose={() => {
                         setOpenAssetsDialog(!openAssetsDialog)
                       }}/>
  </BaseContent>
}

export default GuideCreationWizard