import React   from 'react';
import {Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";

const ScreenNewItem = () => {

  return <Card className='c-item-add-new' style={{minHeight:300}}>
    <Link to={`/screens/new`} style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}> </Link>
    <CardBody className='d-flex justify-content-center align-content-center align-items-center flex-column'>
      <div className='d-flex'>
        <i className='bx bx-plus'></i>
      </div>
      <div className='d-flex'>
        Add new screen
      </div>
    </CardBody>
  </Card>

};

export default ScreenNewItem;
