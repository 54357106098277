import ShortAnswer from "./fields/ShortAnswer";
import MultipleChoice from "./fields/MultipleChoice";
import DropdownList from "./fields/DropdownList";
import Checkboxes from "./fields/Checkboxes";
import Paragraph from "./fields/Paragraph";
import LinearScale from "./fields/LinearScale";
import DateSelect from "./fields/DateSelect";
import Time from "./fields/Time";
import MultipleChoiceGrid from "./fields/MultipleChoiceGrid";
import CheckboxGrid from "./fields/CheckboxGrid";
import Email from "./fields/Email";
import Label from "./fields/Label";

// Safer usage of BE strings- make sure these are synced
export const FIELD_TYPE = {
  DROPDOWN: 'DROPDOWN',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  CHECKBOXES: 'CHECKBOXES',
  SHORT_ANSWER: 'SHORT_ANSWER',
  PARAGRAPH: 'PARAGRAPH',
  LINEAR_SCALE: 'LINEAR_SCALE',
  DATE: 'DATE',
  TIME: 'TIME',
  MULTIPLE_CHOICE_GRID: 'MULTIPLE_CHOICE_GRID',
  CHECKBOX_GRID: 'CHECKBOX_GRID',
  EMAIL: 'EMAIL',
  LABEL: 'LABEL'
}

// For drop-down selection
export const FIELD_TYPES = [
  // {
  //   label: 'Label',
  //   value: FIELD_TYPE.LABEL
  // },
  {
    label: 'Short answer',
    value: FIELD_TYPE.SHORT_ANSWER
  },
  {
    label: 'Paragraph',
    value: FIELD_TYPE.PARAGRAPH
  },
  {
    label: 'Dropdown',
    value: FIELD_TYPE.DROPDOWN
  },
  {
    label: 'Multiple choice',
    value: FIELD_TYPE.MULTIPLE_CHOICE
  },
  {
    label: 'Multiple choice grid',
    value: FIELD_TYPE.MULTIPLE_CHOICE_GRID
  },
  {
    label: 'Checkboxes',
    value: FIELD_TYPE.CHECKBOXES
  },
  {
    label: 'Checkboxes grid',
    value: FIELD_TYPE.CHECKBOX_GRID
  },
  {
    label: 'Date',
    value: FIELD_TYPE.DATE
  },
  {
    label: 'Time',
    value: FIELD_TYPE.TIME
  },
  {
    label: 'Linear Scale',
    value: FIELD_TYPE.LINEAR_SCALE
  },
  {
    label: 'Email',
    value: FIELD_TYPE.EMAIL
  }
]

// This is used to render components depending on type
export const FIELD_COMPONENTS = {
  DROPDOWN: DropdownList,
  MULTIPLE_CHOICE: MultipleChoice,
  CHECKBOXES: Checkboxes,
  SHORT_ANSWER: ShortAnswer,
  PARAGRAPH: Paragraph,
  LINEAR_SCALE: LinearScale,
  DATE: DateSelect,
  TIME: Time,
  MULTIPLE_CHOICE_GRID: MultipleChoiceGrid,
  CHECKBOX_GRID: CheckboxGrid,
  EMAIL: Email,
  LABEL: Label
}

export const FIELD_DEFAULTS = {
  DROPDOWN: {
    title: 'Drop down list',
    type: FIELD_TYPE.DROPDOWN,
    description: '',
    required: false,
    value: '',
    details: {
      options: []
    }
  },
  MULTIPLE_CHOICE: {
    title: 'Multiple choice',
    type: FIELD_TYPE.MULTIPLE_CHOICE,
    description: '',
    required: false,
    value: null,
    details: {
      options: []
    }
  },
  MULTIPLE_CHOICE_GRID: {
    title: 'Multiple choice',
    type: FIELD_TYPE.MULTIPLE_CHOICE_GRID,
    description: '',
    required: false,
    value: [],
    details: {
      columns: [],
      rows: []
    }
  },
  LABEL: {
    title: 'Label',
    type: FIELD_TYPE.LABEL,
    description: '',
    required: false,
    value: ''
  },
  SHORT_ANSWER: {
    title: 'Short answer',
    type: FIELD_TYPE.SHORT_ANSWER,
    description: '',
    required: false,
    value: ''
  },
  PARAGRAPH: {
    title: 'Paragraph',
    type: FIELD_TYPE.PARAGRAPH,
    description: '',
    required: false,
    value: ''
  },
  CHECKBOXES: {
    title: 'Checkboxes',
    type: FIELD_TYPE.CHECKBOXES,
    description: '',
    required: false,
    value: [],
    details: {
      options: []
    }
  },
  CHECKBOX_GRID: {
    title: 'Checkboxes grid',
    type: FIELD_TYPE.CHECKBOX_GRID,
    description: '',
    required: false,
    value: [],
    details: {
      columns: [],
      rows: []
    }
  },
  DATE: {
    title: 'Date',
    type: FIELD_TYPE.DATE,
    description: '',
    required: false,
    value: null
  },
  TIME: {
    title: 'Time',
    type: FIELD_TYPE.TIME,
    description: '',
    required: false,
    value: null
  },
  LINEAR_SCALE: {
    title: 'Linear scale',
    type: FIELD_TYPE.LINEAR_SCALE,
    description: '',
    required: false,
    value: null,
    details: {
      from: 1,
      to: 2,
      fromLabel: '',
      toLabel: ''
    }
  },
  EMAIL: {
    title: 'Email',
    type: FIELD_TYPE.EMAIL,
    description: '',
    required: true,
    value: ''
  },
}

export const SURVEY_TYPES = {
  ANONYMOUS : 'ANONYMOUS',
  EMAIL_ONLY : 'EMAIL_ONLY',
  USER_DETAILS : 'USER_DETAILS',
  SIGNED_URL : 'SIGNED_URL'
}

export const SURVEY_TYPES_LIST = [
  {
    label: 'Anonymous',
    value: SURVEY_TYPES.ANONYMOUS
  },
  {
    label: 'Email only',
    value: SURVEY_TYPES.EMAIL_ONLY
  },
  {
    label: 'User details',
    value: SURVEY_TYPES.USER_DETAILS
  },
  {
    label: 'Signed url',
    value: SURVEY_TYPES.SIGNED_URL
  }
]

export const SURVEY_DEFAULTS = {
  label: 'Untitled survey',
  description : 'Describe your survey',
  submissionMessage: 'Thank you for filling out our survey!',
  surveyType: SURVEY_TYPES.ANONYMOUS,
  questions: [],
  openAt: new Date(),
  closeAt: new Date(),
  locked: true,
  lockedMessage: 'This survey is not accepting responses',
  email: null,
  organization: null,
  firstName: null,
  lastName: null
}

export const THEMES = [
  [
    '#ffa600',
    '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600',
    '#0078ad',
    '#557ac3',
    '#9575ca',
    '#cf6bbd',
    '#fb64a0',
    '#ff6b77',
    '#ff8448'
  ],
  [
    '#6d0000',
    '#812b18',
    '#94482e',
    '#a66547',
    '#b88062',
    '#ca9c80',
    '#dbb9a0',
    '#edd5c2',
    '#fff2e6'
  ],
  [
    '#2a6d01',
    '#467e24',
    '#5f903e',
    '#77a258',
    '#8fb471',
    '#a7c68c',
    '#bfd9a6',
    '#d7ecc2',
    '#efffde'
  ],
  [
    '#00686d',
    '#287a7e',
    '#418c8f',
    '#589ea1',
    '#6eb1b3',
    '#84c4c5',
    '#9ad7d8',
    '#b0ebeb',
    '#c6ffff'
  ],
  [
    '#00146d',
    '#3b2a7d',
    '#5e438d',
    '#7c5e9e',
    '#997aaf',
    '#b498c1',
    '#ceb7d5',
    '#e7d7e9',
    '#fff9ff'
  ],
  [
    '#44016d',
    '#5e257d',
    '#77428e',
    '#8f5ea0',
    '#a77bb2',
    '#bd98c4',
    '#d4b7d7',
    '#ead6eb',
    '#fff6ff'
  ],
  [
    '#6d0064',
    '#7f2c77',
    '#914a8a',
    '#a3669e',
    '#b581b1',
    '#c79dc5',
    '#dab9d8',
    '#ecd6ec',
    '#fff3ff'
  ],
  [
    '#6d0000',
    '#812b18',
    '#94482e',
    '#a66547',
    '#b88062',
    '#ca9c80',
    '#dbb9a0',
    '#edd5c2',
    '#fff2e6'
  ],
  [
    '#bd0000',
    '#c82c00',
    '#d34400',
    '#dd5900',
    '#e76d00',
    '#ef8000',
    '#f89300',
    '#ffa600'
  ]
]

export const GRAPH_COLORS = [
  ...THEMES[0]
]
