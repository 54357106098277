import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useForm} from "../../../../helpers/hooks";
import EditDialog from "../../../../components/Common/EditDialog";
// Using an ES6 transpiler like Babel
import Select from "react-select";
import {SortableList} from "../../../../components/Common/Sortable";
import {buildPropertiesPayload, unwrapAcceptanceTypes, wrapAcceptanceContainers,} from "../../../../utils";
import {Schema} from "../../../../store/core/schema";
import {ViewLayouts} from "../../../../constants/enums";

const EntityDialog = (props) => {
  const {
    context,
    entity,
    properties,
    entityType,
    dataStreamTypes,
    propertyGroups,
    handleCreatePropertyGroup,
    handleEditPropertyGroup,
  } = props;
  const containerTypes = context.getAll.result;
  const [form, handleChange, updateInitial] = useForm(Schema[entityType]);
  const [acceptedContainers, setAcceptedContainers] = useState([]);
  const [acceptedDataStreams, setAcceptedDataStreams] = useState([]);
  const [allowedAtRoot,setAllowedAtRoot] = useState(false)
  const [state, setState] = useState([]);

  const containerTypeOptions = useMemo(() => {
    return containerTypes.map((container) => ({
      label: container.name,
      value: container.code,
    }));
  }, [containerTypes]);

  const dataStreamTypeOptions = useMemo(() => {
    return dataStreamTypes.map((container) => ({
      label: container.name,
      value: container.code,
    }));
  }, [dataStreamTypes]);

  useEffect(() => {
    updateInitial(entity);
  }, [entity, updateInitial]);

  useEffect(()=>{
    setAllowedAtRoot(form.allowedAtRoot)
  },[form.allowedAtRoot])

  useEffect(() => {
    const containers = unwrapAcceptanceTypes(
      entity.containerAcceptance,
      containerTypeOptions
    );
    const dataStreams = unwrapAcceptanceTypes(
      entity.datastreamAcceptance,
      dataStreamTypeOptions
    );
    setAcceptedContainers(containers);
    setAcceptedDataStreams(dataStreams);
  }, [
    containerTypeOptions,
    dataStreamTypeOptions,
    entity.containerAcceptance,
    entity.datastreamAcceptance,
    props.open,
  ]);

  const handleCreate = () => {
    const payload = {
      container: {
        code: form.code.toLowerCase(),
        name: form.name,
        containerAcceptance: wrapAcceptanceContainers(acceptedContainers),
        datastreamAcceptance: wrapAcceptanceContainers(acceptedDataStreams),
        layout: form.layout,
        allowedAtRoot:allowedAtRoot
      },
      properties: buildPropertiesPayload(state),
    };
    props.createEntity(payload);
  };

  const handleUpdate = () => {
    const payload = {
      container: {
        id: entity.code,
        name: form.name,
        containerAcceptance: wrapAcceptanceContainers(acceptedContainers),
        datastreamAcceptance: wrapAcceptanceContainers(acceptedDataStreams),
        allowedAtRoot:allowedAtRoot,
        layout: form.layout,
      },
      properties: buildPropertiesPayload(state),
    };
    props.updateEntity(payload);
  };

  const renderStepOne = () => (
    <Row>
      <Col sm={"6"}>
        <FormGroup>
          <Label>Label</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="Enter label"
            value={form.name}
            onChange={handleChange("name")}
          />
        </FormGroup>
      </Col>
      <Col sm={"6"}>
        <FormGroup>
          <Label>Code</Label>
          <Input
            disabled={entity.id}
            type="text"
            className="form-control"
            placeholder="Enter code"
            value={form.code}
            onChange={handleChange("code")}
          />
        </FormGroup>
      </Col>
      <Col sm={"12"}>
        <FormGroup>
          <Label>Accepted Containers</Label>
          <Select
            value={acceptedContainers}
            isMulti
            onChange={(value) => setAcceptedContainers(value)}
            name="containers"
            options={containerTypeOptions}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </FormGroup>
      </Col>
      <Col sm={"12"}>
        <FormGroup>
          <Label>Accepted Datastreams</Label>
          <Select
            value={acceptedDataStreams}
            isMulti
            onChange={(value) => setAcceptedDataStreams(value)}
            name="datastreams"
            options={dataStreamTypeOptions}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </FormGroup>
      </Col>
      <Col sm={'12'}>
        <FormGroup>
          <div
            className="custom-control custom-switch mb-2 mr-3 z-depth-0"
            dir="ltr"
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={'allowAtroot'}
              checked={allowedAtRoot}
              value={allowedAtRoot}
              onClick={() => setAllowedAtRoot(!allowedAtRoot)}
            />
            <label className="custom-control-label" htmlFor={'allowAtroot'}>
              Allow creation at root
            </label>
          </div>
        </FormGroup>
      </Col>
    </Row>
  );

  const renderStepTwo = () => (
    <Row>
      <Col>
        <SortableList
          state={state}
          setState={setState}
          properties={properties}
          propertyGroups={propertyGroups}
          form={form}
          handleEditPropertyGroup={handleEditPropertyGroup}
          handleCreatePropertyGroup={handleCreatePropertyGroup}
        />
      </Col>
    </Row>
  );

  const activeLayout = ViewLayouts.find((x) => x.value === form.layout);

  const renderStepThree = () => (
    <Fragment>
      <FormGroup>
        <Label>Select layout</Label>
        <Select
          value={activeLayout}
          onChange={handleChange("layout")}
          name="containers"
          options={ViewLayouts}
          classNamePrefix="select"
        />
      </FormGroup>
      <Row>
        <Col sm={"6"}>
          <img
            className={"shadow-md"}
            style={{ width: 400 }}
            src={activeLayout && activeLayout.src}
            alt="Layout"
          />
        </Col>
        <Col className={"p-4"}>
          <h5 className={"font-size-14"}>Description</h5>
          <p>{activeLayout && activeLayout.description}</p>
        </Col>
      </Row>
    </Fragment>
  );

  const contents = [renderStepOne(), renderStepTwo(), renderStepThree()];

  return (
    <EditDialog
      {...props}
      createEntity={handleCreate}
      updateEntity={handleUpdate}
      size={"lg"}
      form={form}
      tabs={[
        { title: "General" },
        { title: "Properties" },
        { title: "Layout" },
      ]}
      contents={contents}
      context={context}
      scrollable={true}
      extraClassName={"modal-full-height"}
    />
  );
};

export default EntityDialog;
