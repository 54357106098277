import React from 'react'
import BaseContent from '../../../../components/Common/BaseContent'


const TemplateEditNew = (props) =>{
  return <BaseContent>
    edit new
  </BaseContent>
}

export default TemplateEditNew