import React from "react";
import BusyIndicator from "./BusyIndicator";
import {getDisplayPropertyView} from "./PropertyViews";

const EntityMetadata = ({ entity, busy, properties }) => {

  const joinProps = React.useMemo(() => {
    if (!entity.properties) return [];
    return entity.properties.map((property) => {
      const match = properties.find((x) => {
        return x.propertyKey === property.key
      });
      return {
        ...match,
        ...property,
      };
    });
  }, [properties, entity.properties]);

  if (busy) return <BusyIndicator busy={busy} />;

  return (
    <div>{joinProps.map((prop, index) => {
      const View = getDisplayPropertyView(prop.propertyType)
      return <View entity={prop} key={index}/>
    })}</div>
  );
};

export default EntityMetadata;
