import React, {useEffect, useRef, useState} from 'react'
import {Button, Col, Container, Input, Row, Table} from 'reactstrap'
import {FIELD_DEFAULTS, SURVEY_TYPES} from "../../components/consts";
import FieldRenderer from "../../components/FieldRenderer";
import {getAxiosDefaultConfig} from "../../../../utils";
import axios from "axios";
import {API_URL} from "../../../../config";
import {Redirect, useLocation, useParams} from "react-router";
import Email from "../../components/fields/Email";
import {UserInfoTypeFields} from "../../components/common/UserInfoTypeFields";
import ResponseListItem from "./ResponseListItem";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import PaginationComponent from "react-reactstrap-pagination";
import BusyIndicator from "../../../../components/Common/BusyIndicator";
import {connect} from "react-redux";
import {getSurveyResponse} from "../../../../store/surveyResponse/actions";
import NoResponses from "../NoResponses";
import Loader from "./Loader";
import DeleteDialog from "../../../Containers/DeleteDialog";
import moment from "moment";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TableView = ({UUIDs=[], ...props}) =>{

  const {uuid,responseUuid} = useParams()

  const [responses, setResponses] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [surveyResponse, setSurveyResponse] = useState()
  const [surveyData, setSurveyData] = useState(props.surveyData)
  const [openDelete, setOpenDelete] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const {getSurveyResponse} = props;

  let query = useQuery();
  const pageVar = query.get('page')

  const [page, setPage] = useState(pageVar?pageVar:0)

  useEffect(()=>{
    if (responseUuid) {
        if (responseUuid&&!surveyResponse) {
          setIsLoading(true)
          axios.get(`${API_URL}/addons/srv/surveys/${uuid}/responses/${responseUuid}`,getAxiosDefaultConfig())
            .then((response) => {
              const {data} = response;
              setSurveyResponse(data);
              setIsLoading(false);
            })
            .catch(error => {
              setIsLoading(false);
            })
        }
    }
    // eslint-disable-next-line
  },[responseUuid])

  useEffect(()=>{
    if (surveyResponse&&surveyData) {

      // Match Answers to Questions
      let newQuestions = [...surveyData.questions]
      surveyResponse.answers.forEach((answer)=>{
        let questionIndex = newQuestions.findIndex((q)=>q.id===answer.id)
        if (questionIndex!==-1) {
          newQuestions[questionIndex] = {
            ...newQuestions[questionIndex],
            value: answer.value
          }
        }
      })

      // Update local survey object with values
      setSurveyData({
        ...surveyData,
        questions: newQuestions
      })
    }
    //eslint-disable-next-line
  },[surveyResponse])

  const getData = (page,searchValue) => {
    setIsLoading(true)

    axios.get(`${API_URL}/addons/srv/surveys/${uuid}/responses?page=${page}&q=${encodeURIComponent(searchValue)}`,getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setResponses(data);
        setIsLoading(false);
        setDeleted(false);
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  useEffect(()=>{
    getData(page,searchValue)
    // eslint-disable-next-line
  },[page])

  const [isVisible, setIsVisible] = useState();
  const onVisibilityChange = () => setIsVisible(document.hidden);

  useEffect(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  });

  useEffect(()=>{
    if (!isVisible) {
      getData(page,searchValue)
    }
    // eslint-disable-next-line
  },[isVisible])

  const reqDelete = () => {
    setIsLoading(true)
    axios.delete(`${API_URL}/addons/srv/surveys/${uuid}/responses/${responseUuid}`,getAxiosDefaultConfig())
      .then((response) => {
        getSurveyResponse(uuid);
        setDeleted(true);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  useEffect(()=>{
    if (deleted) {
      getData(0,'')
    }
    // eslint-disable-next-line
  },[deleted])

  const closeDeleteDialog = () => {
    setOpenDelete(false)
  }

  const deleteResponse = () => {
    setOpenDelete(true)
  }

  const handleDelete = () => {
    reqDelete()
  }

  const handleSelectPage = (page) => {
    setPage(page-1)
  }

  const searchTimeout = useRef()

  const changeSearchValue = (e) => {
    const value = e.target.value

    setSearchValue(value)

    if (value.length>3) {
      clearTimeout(searchTimeout.current)
      searchTimeout.current = setTimeout(()=>{
          getData(0,searchValue)
      },800)
    } else if (value==='') {
      getData(0,'')
    }

  }

  if (!responses) return <Loader/>

  // if (responses&&responses.content.length===0) return <NoResponses />
  let creationDate = new Date(surveyData.createdAt+'Z')

  const createMarkup = (htmlcode) => {
    return {__html: htmlcode}
  }

  return <Container className='mt-4'>

    {deleted&&<Redirect to={`/surveys/edit/${uuid}/responses/list`} />}

    <Breadcrumbs
    // paths={paths}
    // entityActions={entityActions}
    // title={title}
    // renderActions={renderActions}
    breadcrumbs={!responseUuid?[{title: 'Responses', link: `/surveys/edit/${uuid}/responses/list`}]:[
      {title: 'Responses', link: `/surveys/edit/${uuid}/responses/list`},
      {title: `View response${surveyResponse&&surveyResponse.email?` (${surveyResponse.email})`:''}`}]
    }
    renderActions={()=>{
      if (responseUuid) return <>
        {!isLoading&&surveyData&&<Col xs={12} style={{textAlign:'right'}}>
          <Button outline color="danger" onClick={()=>deleteResponse()}>Delete</Button>
        </Col>}
      </>

      return <>
        <Input type="text" name={`search`} id={`search`}
               placeholder={'Search...'}
               value={searchValue}
               onChange={changeSearchValue}
        />
      </>
    }}
    // entityType={entityType}
  />



    {/*{responses&&responses.content.length===0&&!searchValue&&<NoResponses />}*/}

    {!responseUuid&&<Row style={{minHeight:415, position: 'relative'}}>
      <Col lg="12">
        <div className="">
          <div className="table-responsive">
            {!isLoading&&responses.content.length!==0&&<Table className="project-list-table table-nowrap table-centered table-borderless table-zebra">
              <thead>
              <tr>
                <th scope="col" style={{width:'15%'}}>Date</th>
                <th scope="col" style={{width:'21.25%'}}>Email</th>
                <th scope="col" style={{width:'21.25%'}}>First name</th>
                <th scope="col" style={{width:'21.25%'}}>Last name</th>
                <th scope="col" style={{width:'21.25%'}}>Organization</th>
                {/*<th scope="col">Deleted at</th>*/}
              </tr>
              </thead>
              <tbody>
              {!isLoading&&responses&&responses.content.map((item, key) => (
                <ResponseListItem
                  key={key}
                  item={item}
                  index={key}
                />
              ))}
              </tbody>
            </Table>}
            {!isLoading&&responses.content.length===0&&<NoResponses />}
            {isLoading&&<Row className='d-flex justify-content-center align-items-center align-content-center' style={{minHeight:320,width:'100%', position: 'relative'}}>
              <div className='d-flex'>
                <BusyIndicator busy={true}/>
              </div>
            </Row>}
          </div>
        </div>
      </Col>
    </Row>}


    {!responseUuid&&responses&&<Row>
      <Col xs={{size:6,offset:6}} className='responses-navigation'>
        <PaginationComponent defaultActivePage={responses.pageable.pageNumber+1} totalItems={responses.totalElements} pageSize={responses.pageable.pageSize} onSelect={handleSelectPage} />
      </Col>
    </Row>}

    {responseUuid&&<Row className='mt-4'>

      <Col xs={{size: 10, offset: 1}}>

        {isLoading&&<div className='custom-loading-overlay'> </div>}

        {!isLoading&&surveyData&&<Container style={{marginBottom: 16}}>
          <h2>{surveyData.label}</h2>
          <h5>{surveyData.description}</h5>
          {!isLoading&&surveyData&&<>
            Responded on  {moment(creationDate).format("DD/MM/YYYY, h:mm a")}
          </>}
        </Container>}

        {!isLoading&&surveyData&&!SURVEY_TYPES.ANONYMOUS&&<div className='survey-field-view'>
          <Container style={{textAlign:'left', position:'relative'}}>
            {surveyData&&surveyData.surveyType===SURVEY_TYPES.EMAIL_ONLY&&
              <Email field={{...FIELD_DEFAULTS.EMAIL,value:surveyResponse&&surveyResponse.email}} setField={()=>{}} standalone />
            }
            {surveyData&&surveyData.surveyType===SURVEY_TYPES.USER_DETAILS&&
              <UserInfoTypeFields surveyData={surveyData} />
            }
          </Container>
        </div>}

        {surveyData.sections.map((section,sectionIndex)=>{
          const sectionFields = surveyData.questions.filter((f)=>section.questions.includes(f.id))
          const questionsSoFar = surveyData.sections.slice(0,sectionIndex).reduce((total=0, s)=>total+s.questions.length,0)

          return <>
            {section.title&&<Container style={{marginBottom: 16, marginTop: 32}}>
              <h4>{section.title}</h4>
              {section.description!==''&&<h6 style={{whiteSpace: "pre-wrap"}}>
                <div dangerouslySetInnerHTML={createMarkup(section.description)} />
              </h6>}
            </Container>}

            {sectionFields.map((field, sectionFieldIndex) => {
              const index = surveyData.questions.findIndex((f)=>f.id===field.id)
              return <div className='survey-field-view' key={index}>
                <FieldRenderer field={{...field, index}} setField={()=>{}}
                               answerable={true} key={index} formDisabled={isLoading} fieldNumber={questionsSoFar+sectionFieldIndex} />
              </div>
            })}
          </>
        })}

      </Col>
    </Row>}

    {<DeleteDialog open={openDelete} handleClose={closeDeleteDialog} handleDelete={handleDelete} />}

  </Container>
}


const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getSurveyResponse,
})(TableView);
