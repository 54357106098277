import React, {useEffect} from 'react';
import {FormGroup, Input, Label, Row} from 'reactstrap';
import {useForm} from '../../../helpers/hooks';
import EditDialog from '../../../components/Common/EditDialog';
import PermissionsList from './components/PermissionsList'
import UsersList from './components/UsersList'
import Col from 'reactstrap/lib/Col'
import UserCard from './components/UserCard'
import {Divider} from '@material-ui/core'
import story from "../../../assets/images/story.png"
import survey from "../../../assets/images/survey.png"
import store from "../../../assets/images/store.png"

const PropertyDialog = (props) => {
  const {entity, open, allUsers, allPermissions} = props;

  const [form, handleChange, updateInitial] = useForm({});

  useEffect(() => {
    updateInitial(entity);
  }, [entity, updateInitial, open]);

  const renderOne = () => {
    return <>
      <Row>
        <FormGroup className='col-lg-12'>
          <Label>Group Name</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="First name"
            value={form.name}
            onChange={handleChange('name')}
          />
        </FormGroup>
        <FormGroup className='col-lg-12'>
          <Label>Description</Label>
          <Input
            type="textarea"
            className="form-control"
            // placeholder="Enter description"
            value={form.description}
            onChange={handleChange('description')}
          />
        </FormGroup>
      </Row>
      <Row>
        {entity?.users?.length === 0 &&
        <Col lg={12} className='font-size-14' style={{fontWeight:500}}>No Users added yet</Col>
        }
      </Row>
      {entity?.users?.length > 0 && <>
        <Row>
          <Col lg={12} className='font-size-14' style={{fontWeight:500}}>Users ({entity.users.length})</Col>
        </Row>
        <Row className='mt-2'>
          {entity.users.map((u) => {
            return <UserCard user={u} key={u.uuid} lg={4}/>
          })}
        </Row>
      </>
      }
    </>
  }

  const renderTwo = () => {
    return <>
      <UsersList allUsers={allUsers} form={form} handleChange={handleChange}/>
    </>
  }
  const renderThree = () => {
    return <>
      {allPermissions?.storePermissions?.length>0 &&
        <PermissionsList key={'storeList'} logo={store} title={'Store'} allPermissions={allPermissions?.storePermissions} form={form} handleChange={handleChange}/>
      }
      <Divider className='mt-1 mb-1'/>
      {allPermissions?.surveyPermissions?.length > 0 &&
      <PermissionsList key={'surveysList'} logo={survey} title={'Surveys'}
                       allPermissions={allPermissions?.surveyPermissions} form={form} handleChange={handleChange}/>
      }
      <Divider className='mt-1 mb-1'/>
      {allPermissions?.storiesPermissions?.length>0 &&
        <PermissionsList key={'storiesList'} logo={story} title={'Stories'} allPermissions={allPermissions?.storiesPermissions} form={form} handleChange={handleChange}/>
      }
    </>
  }


  const contents = [renderOne(), renderTwo(), renderThree()]

  return (
    <EditDialog {...props} contents={contents} form={form}/>
  );
};


export default PropertyDialog;
