import { useToggle } from "../../helpers/hooks";
import { ApiEndpoint } from "../../store/core/endpoint";
import { EntityType } from "../../store/core/entityType";
import { downloadFile, getIconForDatastream } from "../../utils";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import React from "react";

export const PortalToolbar = ({
  result,
  handleClose,
  handleEdit,
  toggleRightSidebar,
  handleDelete,
  handleStar,
}) => {
  const [openMore, toggleMore] = useToggle();
  const handleDownloadFile = () => {
    const downloadUrl =
      ApiEndpoint[EntityType.DataStream] + `/${result.uuid}/download`;
    downloadFile(downloadUrl, result);
  };

  return (
    <div className={"d-flex p-2 align-items-center"}>
      <div className="btn-group">
        <Button
          type="button"
          color="link"
          onClick={handleClose}
          className="waves-light waves-effect text-white"
        >
          <i className="fa fa-chevron-left" />
        </Button>
      </div>
      <h4 className="text-white m-0 ml-2">
        <i
          className={`bx ${getIconForDatastream(
            result.type && result.type.name
          )}`}
        />{" "}
        {result.label}
      </h4>
      <Button
        type="button"
        color="link"
        onClick={handleDownloadFile}
        className="waves-light waves-effect ml-auto text-white"
      >
        <i className="fa fa-download" />
      </Button>
      <Button
        type="button"
        color="link"
        onClick={toggleRightSidebar}
        className="waves-light waves-effect text-white"
      >
        <i className="fa fa-info-circle" />
      </Button>
      <Dropdown
        isOpen={openMore}
        toggle={toggleMore}
        direction="left"
        className="btn-group"
      >
        <DropdownToggle
          className="btn btn-link waves-light waves-effect dropdown-toggle text-white"
          tag="div"
        >
          <i className="fa fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleEdit}>
            <i className={"bx bx-edit"} /> Edit
          </DropdownItem>
          <DropdownItem onClick={handleStar}>
            <i className={`bx ${!result.starred ? "bx-star" : "bxs-star"}`} />{" "}
            {!result.starred ? "Add to starred" : "Remove from starred"}
          </DropdownItem>
          <DropdownItem disabled>
            <i className={"bx bx-share"} /> Share
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={handleDelete} className={"text-danger"}>
            <i className={"bx bx-trash"} /> Remove
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
