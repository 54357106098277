import {getCommonState, handleCommonState} from "../core/factory";
import {entityType} from "./module";
import * as ActionTypes from "../core/actionTypes";
import {STAR_DATASTREAM} from "./actionTypes";

const initialState = getCommonState();

const Project = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0];

  if (actionEntity !== entityType) return state;
  const actionType = action.type.split(".")[1];
  switch (actionType) {
    case ActionTypes.CUSTOM:
      switch (action.payload.actionType) {
        case STAR_DATASTREAM:
          return {
            ...state,
            get: {
              ...state.get,
              result: {
                ...state.get.result,
                starred:
                  state.get.result.uuid === action.payload.data.uuid
                    ? action.payload.data.starred
                    : state.get.result.starred,
              },
            },
          };
        default:
          break;
      }
      break;
    default:
      break;
  }

  return handleCommonState(state, actionType, action, entityType);
};

export default Project;
