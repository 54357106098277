import React from 'react';
import { Bar } from 'react-chartjs-2';
//import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from "chart.js";




const TableChart = ({options, width = 474, height = 260 }) =>
{
  
  //console.log(options)  
  const option = {
    legend: {
      display: false, //This will do the task
    },
  };

  const labels = options.map((o)=>o.label)
  const optionsData = options.map((o) => o.value);
  const max = Math.max(...optionsData);
  const data = {
    labels,
    datasets: [
      {
        label: "types",
        data: [...optionsData, max * 1.5, 0],
        backgroundColor: ["#556ee6", "#ebeff2", "#99e655", "#905eca", "#b4d002", "#f9e4bf", "#ddb98d", "#ff4c00", "#8f3113", "#405cd8", "#66edb8", "#e3b766", "#b35109", "#cb0a4b"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2", "#99e655", "#905eca", "#b4d002", "#f9e4bf", "#ddb98d", "#ff4c00", "#8f3113", "#405cd8", "#66edb8", "#e3b766", "#b35109", "#cb0a4b"],
        hoverBorderColor: "#fff",
      },
    ],
  };

    return (
      <React.Fragment>
        <Bar width={width} height={height} options={option} data={data} />
      </React.Fragment>
    );
}

export default TableChart;
