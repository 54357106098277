import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {entityOperations, entityType} from "./module";
import getCoreSagas from "../core/saga";
import {
  buildActionType,
  createPostFailureMethod,
  createPostSuccessMethod,
  createPutSuccessMethod,
} from "../core/factory";
import * as ActionTypes from "../core/actionTypes";
import {postEntity, putEntity} from "../core/api";
import {ApiEndpoint} from "../core/endpoint";

const coreSagas = getCoreSagas(entityType, entityOperations);
const successCallback = (result) => createPostSuccessMethod(entityType, result);
const errorCallback = (result) => createPostFailureMethod(entityType, result);
const successUpdateCallback = (result) => createPutSuccessMethod(entityType, result);
const errorUpdateCallback = (result) => createPostFailureMethod(entityType, result);

export function* postContainerType({ payload }) {
  try {
    const url = ApiEndpoint[entityType];
    yield call(postEntity, url, payload.container);
    const propertiesUrl = url + "/" + payload.container.code + "/properties";
    const response = yield call(postEntity, propertiesUrl, payload.properties);
    yield put(successCallback(response));
  } catch (error) {
    yield put(errorCallback(error.message));
  }
}

export function* putContainerType({ payload }) {
  try {
    const id = payload.container.id
    const url = ApiEndpoint[entityType]
    yield call(putEntity, url, payload.container);
    const propertiesUrl = `${url}/${id}/properties`
    const response = yield call(postEntity, propertiesUrl, payload.properties);
    yield put(successUpdateCallback(response));
  } catch (error) {
    yield put(errorUpdateCallback(error.message));
  }
}

export function* watchEntityCreation() {
  const actionType = buildActionType(entityType, ActionTypes.POST);
  yield takeEvery(actionType, postContainerType);
}

export function* watchEntityUpdate() {
  const actionType = buildActionType(entityType, ActionTypes.UPDATE);
  yield takeEvery(actionType, putContainerType);
}

function* sagas() {
  coreSagas.push(fork(watchEntityCreation));
  coreSagas.push(fork(watchEntityUpdate));
  yield all(coreSagas);
}

export default sagas;
