import React from 'react';
import {withRouter} from "react-router-dom";
import MultipleChoicesGrid from "../common/MultipleChoicesGrid";

const MultipleChoiceGrid = (props) => {
  const {field, fieldNumber} = props

  return <MultipleChoicesGrid field={field} fieldNumber={fieldNumber} />
}

export default withRouter(MultipleChoiceGrid);
