import React   from 'react';
import CardSettings from './card-settings';
import ListSettings from './ListSettings';
import NoStars from '../../pages/Starred/NoStars'

const GroupedEntities = ({result, isList}) => {
  const {isLoading, containerData, dataStreamData, starred, containerList, dataStreamList} = result
  const listColumns = [
    {name: 'Label', prop: 'label'},
    {name: 'Created At', prop: 'createdAt'},
    {name: 'Creator', prop: 'creator'},
  ];

  const fullList = [...containerList, ...dataStreamList]

  if (isList) {
    return <ListSettings contents={fullList} columns={listColumns}/>
  }

  return (
    <>
      {containerData[0] && (
        <>
          <div className="menu-title">Containers</div>
          <CardSettings settings={containerData} starred={starred}/>
        </>
      )}
      {dataStreamData[0] && (
        <>
          <div className="menu-title">Datastreams</div>
          <CardSettings isDataStream={true} settings={dataStreamData} starred={starred}/>
        </>
      )}
      {!isLoading && fullList.length === 0 &&
      <NoStars/>
      }
    </>
  );
};

export default GroupedEntities;
