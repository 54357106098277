import React, {useEffect, useState, useRef, useCallback} from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import {Col, Row} from "reactstrap";
import {Box} from "@material-ui/core";
import PrintPreview from "./PrintPreview";
import {useReactToPrint} from "react-to-print";
import Switch from "@material-ui/core/Switch";
import toastr from "toastr";

const PrintPreviewDialog = ({open, handleClose, selectedContainer, properties}) => {
  const [printSelections, setPrintSelections] = useState([true, true, true, true]);
  const [notes, setNotes] = useState("");

  const handleChange = useCallback((value) => {
    let newValue = [...printSelections];
    newValue[value] = !printSelections[value];
    setPrintSelections(newValue);
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const newPrintSelection = printSelections.filter((item) => item === true);
    if (newPrintSelection.length === 0) {
      toastr.error("You must include at least one selection.");
      let newValue = [...printSelections];
      newValue[0] = true;
      setPrintSelections(newValue);
    }
  }, [printSelections, handleChange]);

  return (
    <BaseDialog
      title={`Print Preview ${selectedContainer?.label}`}
      size={"xl"}
      handleClose={handleClose}
      open={open}
      primaryActionTitle={"Print"}
      primaryAction={handlePrint}
      secondaryAction={handleClose}
      secondaryActionTitle={"Close"}
    >
      <Box style={{background: "#fcfcfc"}}>
        <Row>
          <Col xs={9}>
            <Box style={{padding: "20px", width: "100%", height: "78vh", overflowY: "scroll", position: "relative", display: "inline-block", border: "1px solid #eff2f7"}}>
              <PrintPreview ref={componentRef} entity={selectedContainer} printSelections={printSelections} notes={notes} properties={properties} />
            </Box>
          </Col>
          <Col xs={3}>
            <Box style={{fontSize: "15px", textAlign: "left", lineHeight: "35px", padding: "20px 10px", position: "relative", display: "inline-block"}}>
              <Row>
                <Col xs={12}>
                  <h1 style={{fontSize: "19px", fontWeight: "600", marginBottom: "30px"}}>Select information to include in print preview</h1>
                </Col>
                <Col xs={12}>
                  <Switch size="small" color="primary" inputProps={{"aria-label": "true/false"}} checked={printSelections[0]} onChange={() => handleChange(0)} /> <span>include basic information</span>
                </Col>
                <Col xs={12}>
                  <Switch size="small" color="primary" inputProps={{"aria-label": "true/false"}} checked={printSelections[1]} onChange={() => handleChange(1)} /> <span>include metadata information</span>
                </Col>
                <Col xs={12}>
                  <Switch size="small" color="primary" inputProps={{"aria-label": "true/false"}} checked={printSelections[2]} onChange={() => handleChange(2)} /> <span>include container list</span>
                </Col>
                <Col xs={12}>
                  <Switch size="small" color="primary" inputProps={{"aria-label": "true/false"}} checked={printSelections[3]} onChange={() => handleChange(3)} /> <span>include datastream list</span>
                </Col>
                <Col xs={12}>
                  {" "}
                  <p>&nbsp;</p>
                  <span>Include notes</span>
                  <br />
                  <textarea
                    className="form-control"
                    id="notes"
                    rows="8"
                    placeholder="Enter notes..."
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                  ></textarea>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </Box>
    </BaseDialog>
  );
};

export default PrintPreviewDialog;
