import {
    createCustomAction,
    createDeleteMethod,
    createGetAllMethod,
    createGetAllSuccessMethod,
    createGetMethod,
    createGetSuccessMethod,
    createPostMethod,
    createPutMethod,
} from '../core/factory';
import {entityType} from "./module";
import {STAR_DATASTREAM} from "./actionTypes";

export const getAllDataStreams = () => createGetAllMethod(entityType)
export const deleteDataStream = (payload) => createDeleteMethod(entityType, payload)
export const getDataStream = (payload) => createGetMethod(entityType, payload)
export const clearDataStream = () => createGetSuccessMethod(entityType, {})
export const clearDataStreams = () => createGetAllSuccessMethod(entityType, [])
export const createDataStream = (payload) => createPostMethod(entityType, payload)
export const updateDataStream = (payload) => createPutMethod(entityType, payload)
export const starDataStream = (payload) => createCustomAction(entityType, {data: payload, actionType: STAR_DATASTREAM})

