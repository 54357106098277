import React   from 'react';
import {Card, CardBody, CardImg, CardSubtitle, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";

const ScreenItem = ({screen}) => {
  const {name, description,id, token, screenshot} = screen

  return <Card className='c-item'>
    <Link to={`/screens/edit/${id}`} style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}> </Link>

    <div style={{backgroundColor: '#cdcdcd',height:169}}>

      {screenshot?<CardImg width="100%" height={169} src={screenshot?`data:image/bmp;base64,${screenshot}`:``} alt="Screen preview" />:<>
      <div style={{minHeight:169}} className='d-flex flex-column justify-content-center align-items-center align-content-center'>

        <div className='d-flex justify-content-center align-items-center align-content-center' style={{fontSize:62}}>
          <i className='bx bx-image'></i> <br/>
        </div>
        <div className='d-flex justify-content-center align-items-center align-content-center'>
          No image available
        </div>

      </div></>}

    </div>

    <CardBody>

      <h5>
        {token&&<span className="badge badge-success">PAIRED</span>}
        {!token&&<span className="badge badge-info">NOT PAIRED</span>}
      </h5>

      <CardTitle tag="h4">
        {name}
      </CardTitle>
      <CardSubtitle style={{minHeight:40}}>{description}</CardSubtitle>
    </CardBody>

  </Card>
};

export default ScreenItem;
