import React from 'react';
import {withRouter} from "react-router-dom";
import {Button, Col, FormGroup, Input, Label} from "reactstrap";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useEffect, useState} from "react";
import {reorder} from "../../../../utils";


const DropdownList = (props) => {
  const {field, setField, selected, answerable, formDisabled, fieldNumber} = props
  const {title,description, index, required, details} = field
  const [newOption, setNewOption] = useState('')
  const [addedNew, setAddedNew] = useState(false)

  // Field extra details
  const {options} = details

  // Handle re-ordering on dnd
  const onDragEnd = (result) => {

    // dropped outside the list
    if (!result.destination) {
      return
    }

    setField({
      ...field,
      details: {
        ...details,
        options: reorder(
          options,
          result.source.index,
          result.destination.index
        )
      }
    })
  }

  // Helper to set new options
  const setOptions = (newOptions) => {
    setField({
      ...field,
      details: {
        ...details,
        options: newOptions
      }
    })
  }

  // Handle change
  const changeOption = (e,index) => {
    const value = e.target.value
    let newOptions = [...options]
    newOptions[index] = value
    setOptions(newOptions)
  }

  // Handle deletion
  const deleteOption = (index) => {
    let newOptions = [...options]
    newOptions.splice(index,1)
    setOptions(newOptions)
  }

  // Handle addition
  const changeNewOption = (e) => {
    const value = e.target.value
    setNewOption(value)
  }

  // Create new entry on change
  useEffect(()=>{
    if (newOption&&newOption!=='') {
      let newOptions = [...options]
      newOptions.push(newOption)
      setOptions(newOptions)
      setNewOption('')
      setAddedNew(true)
    }
    // eslint-disable-next-line
  },[newOption])

  // Blur when previewing/editing
  useEffect(()=>{
    setAddedNew(false)
  },[selected,answerable])

  const setValue = (e) => {
    const value = e.target.value
    setField({
      ...field,
      value
    })
  }

  useEffect(()=>{
    if (required) {
      setField({
        ...field,
        value: options[0]
      })
    }
    // eslint-disable-next-line
  },[])

  // The view / preview part
  //////////////////////////
  if ((!selected) || answerable) return <>

    <FormGroup row>
      <Col xs={12}>
        <Label for={`dropdown-list-${index}`}>{fieldNumber+1}. {title} {required&&<b>*</b>} </Label>
        <div style={{fontSize: 12, marginTop: -8, marginBottom: 8, opacity: 0.8}}>{description}</div>
        <Input type="select" name={`dropdown-list-${index}`} id={`dropdown-list-${index}`} defaultValue={!required?'':options[0]} disabled={formDisabled} onChange={setValue} value={field.value}>
          {!required&&<option value={''}> None </option>}
          {options.map((option,i)=><option value={option} key={i}>{option}</option>)}
        </Input>
      </Col>
    </FormGroup>

  </>

  // The editor part
  ///////////////////
  return <>
    <h6>Options</h6>
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {options.map((option, optionIndex) => {
              const shouldFocus = addedNew&&optionIndex===options.length-1

              return <Draggable key={`dropdown-list-${index}-option-${optionIndex}`} draggableId={`dropdown-list-${index}-option-${optionIndex}`} index={optionIndex}>
                {(provided) => (<div key={optionIndex} {...provided.draggableProps} ref={provided.innerRef}>
                    <div style={{position: 'relative', marginBottom: 16}} >

                      <div {...provided.dragHandleProps} style={{color: '#5c5c5c', fontSize: 18, marginBottom: 2, position: 'absolute', left: -15, top: -8}}>
                        <div style={{marginBottom: -22}}><b>..</b></div>
                        <div style={{marginBottom: -22}}><b>..</b></div>
                        <div><b>..</b></div>
                      </div>

                      <div>

                        <Input type="text" name={`dropdown-list-${index}-option-${optionIndex}-label`} id={`dropdown-list-${index}-option-${optionIndex}-label`} placeholder={'Label'}
                               value={option} onChange={(e)=>changeOption(e,optionIndex)}
                               autoFocus={shouldFocus} disabled={formDisabled}
                        />
                      </div>

                      <div style={{position: 'absolute', right: 0, top: 0}}>
                        <Button outline color="danger" onClick={()=>deleteOption(optionIndex)}>Delete</Button>
                      </div>

                    </div>

                  </div>
                )}
              </Draggable>
            })}
            {provided.placeholder}
          </div>)}
      </Droppable>
    </DragDropContext>

    <div style={{position: 'relative', marginBottom: 16}} >

      <div>
        <Input type="text" name={`new-option`} id={`new-option`} placeholder={'Add new option'} value={newOption} onChange={changeNewOption} />
      </div>

    </div>

  </>
}

export default withRouter(DropdownList);
