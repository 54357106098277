export const EntityType = {
  Container: "Container",
  ContainerType: "ContainerType",
  DataStream: "DataStream",
  DataStreamType: "DataStreamType",
  Property: "Property",
  PropertyType: "PropertyType",
  Utility: "Utility",
  Label: "Label",
  Search:"Search",
  User: "User",
  Group: "Group",
  Tag: "Tag",
  StorageInformation:"StorageInformation",
  Trash:"Trash",
  Namespace: "Namespace",
  PropertyGroup: "PropertyGroup",
  Archive:"Archive",
  Qualifier:"Qualifier",
  SystemSetting:"SystemSetting",
  Export:"Export",
  Surveys:"Surveys",
  SurveyResponses:"SurveyResponses",
  Assets:"Assets",
  Story:"Story",
  Permissions:"Permissions",
  MyPermissions:"MyPermissions",
  MyProfile:"MyProfile",
  Vocabulary:"Vocabulary",
  Schema:"Schema",
  Attribute:'Attribute',

  //v2
  PropertyV2:"PropertyV2",
  ContainerTypev2:"ContainerTypev2",
};
