import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {entityOperations, entityType} from "./module";
import getCoreSagas from "../core/saga";
import {
    buildActionType,
    createPostFailureMethod,
    createPostSuccessMethod,
    createPutSuccessMethod
} from "../core/factory";
import {ApiEndpoint} from "../core/endpoint";
import {postEntity, putEntity} from "../core/api";
import * as ActionTypes from "../core/actionTypes";


const coreSagas = getCoreSagas(entityType, entityOperations);
const successCallback = (result) => createPostSuccessMethod(entityType, result);
const errorCallback = (result) => createPostFailureMethod(entityType, result);
const successUpdateCallback = (result) => createPutSuccessMethod(entityType, result);
const errorUpdateCallback = (result) => createPostFailureMethod(entityType, result);

export function* postDataStreamType({ payload }) {
    try {
        const url = ApiEndpoint[entityType];
        yield call(postEntity, url, payload.dataStream);
        const propertiesUrl = url + "/" + payload.dataStream.code + "/properties";
        const response = yield call(postEntity, propertiesUrl, payload.properties);
        yield put(successCallback(response));
    } catch (error) {
        yield put(errorCallback(error.message));
    }
}

export function* putDataStreamType({ payload }) {
    try {
        const id = payload.dataStream.id
        console.log(id)
        const url = ApiEndpoint[entityType]
        yield call(putEntity, url, payload.dataStream);
        const propertiesUrl = `${url}/${id}/properties`
        const response = yield call(postEntity, propertiesUrl, payload.properties);
        yield put(successUpdateCallback(response));
    } catch (error) {
        yield put(errorUpdateCallback(error.message));
    }
}

export function* watchEntityCreation() {
    const actionType = buildActionType(entityType, ActionTypes.POST);
    yield takeEvery(actionType, postDataStreamType);
}

export function* watchEntityUpdate() {
    const actionType = buildActionType(entityType, ActionTypes.UPDATE);
    yield takeEvery(actionType, putDataStreamType);
}

function* sagas() {
    coreSagas.push(fork(watchEntityCreation));
    coreSagas.push(fork(watchEntityUpdate));
    yield all(coreSagas);
}

export default sagas;
