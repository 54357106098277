import React, {useEffect, useState} from 'react'
import BaseContent from '../../../components/Common/BaseContent'
import StoryConfigurationBlock from './Slideshow/StoryConfigurationBlock'

import toastr from 'toastr'
import SlideShowEditor from '../../../components/Common/SlideShowEditor'
import {getAxiosDefaultConfig, makeid} from '../../../utils'
import Row from 'reactstrap/lib/Row'
import {
  Button,
  Container,
} from 'reactstrap'
import {Link} from 'react-router-dom'
import {API_URL} from '../../../config'
import axios from 'axios';
import DeleteDialog from '../../Containers/DeleteDialog';
import {Redirect} from 'react-router';
import DownloadModal from './Slideshow/components/DownloadModal'

const StoryOverview = (props) => {
  const uuid = props.match.params.id
  const [story, setStory] = useState({})
  const [loadingExport, setLoadingExport] = useState(false)
  const [slides, setSlides] = useState([])
  const [selectedSlide, setSelectedSlide] = useState({})
  const [initSelectedSlide, setInitSelectedSlide] = useState({})
  const [selectedEditElement, setSelectedEditElement] = useState({})
  const [storyFlatValues,setStoryFlatValues] = useState({})
  const [storyTags,setStoryTags] = useState(null)
  const [allStoryTags,setAllStoryTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [screensPairing,] = useState([])
  const [openElementEditAction,setOpenElementEditAction] = useState({})
  const [openDownload,setOpenDownload] = useState(false)

  const [openDeleteDialog, setOpenDeleteDialog] = useState()
  const [redirectToListing, setRedirectToListing] = useState(false)

  const handleDeleteItem = (item) => {
    setOpenDeleteDialog(item)
  }

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleDelete = () => {
    axios.delete(`${API_URL}/stories/${uuid}`, {
      ...getAxiosDefaultConfig(),
      data: {}
    })
      .then((response) => {
        toastr.success(`Successfully deleted story`);
        setRedirectToListing(true)
      })
      .catch(error => {
        toastr.error(error.message)
      })

  }

  useEffect(() => {
    if (uuid) {
      setLoading(true)
      axios.get(`${API_URL}/stories/${uuid}`, getAxiosDefaultConfig()).then((response) => {
        const {storyDetails} = response.data
        const flatData = response.data
        setStoryFlatValues({title:flatData.title,description:flatData.description,aspectRatio:flatData.aspectRatio,orientation:flatData.orientation,coverPage:flatData.coverPage,isTemplate:flatData.isTemplate})
        setStory(storyDetails)
        if (storyDetails?.screen?.slides?.length > 0) {
          setInitSelectedSlide(storyDetails?.screen?.slides[0])
        }
        setSlides(storyDetails?.screen?.slides)
        axios.get(`${API_URL}/stories/${uuid}/tags`, getAxiosDefaultConfig()).then((response) => {
          setStoryTags(response.data)
          axios.get(`${API_URL}/story_tags`, getAxiosDefaultConfig()).then((response) => {
            setAllStoryTags(response.data.content)
            setLoading(false)
          }).catch((e) => {
            toastr.error(e)
            setLoading(false)
          })
        }).catch((e) => {
          toastr.error(e)
        })
      }).catch((e) => {
        toastr.error(e)
      })


    }
  }, [uuid])


  const handleUpdate = () => {
    const slidesState = slides ? [...slides] : []
    const updatePayload = {
      id: uuid,
      title: storyFlatValues.title,
      description: storyFlatValues.description,
      coverPage: storyFlatValues.coverPage,
      storyType: storyFlatValues.storyType ? storyFlatValues.storyType : 'SLIDESHOW',
      aspectRatio:storyFlatValues.aspectRatio,
      orientation:storyFlatValues.orientation,
      isTemplate:storyFlatValues?.isTemplate,
      tags:storyTags.map((storyTag)=>storyTag.id),
      storyDetails: {
        uuid: uuid,
        ...story,
        screen: {...story.screen, slides: slidesState}
      }
    }

    setLoading(true)

      axios.put(`${API_URL}/stories/${uuid}`, updatePayload, getAxiosDefaultConfig()).then(() => {
        setLoading(false)
        toastr.success(`Successfully saved changes`);
      })
        .catch(error => {
          setLoading(false)
          toastr.error(error.message)
        })

  }

  const handleChangeSlide = (slidesArr) => {
    setSlides(slidesArr)
  }

  const updateSlides = (slidesArr) => {
    setSlides(slidesArr)
  }

  const handleDeleteSlide = (e, slideId) => {
    e.stopPropagation()
    e.preventDefault()
    const newSlides = slides.filter((slide) => slide.id !== slideId)
    setSlides(newSlides)
  }

  const handleDuplicateSlide = (e, slideId) => {
    e.stopPropagation()
    e.preventDefault()
    const copiedSlidesArray = JSON.parse(JSON.stringify([...slides]))
    const newSlides = copiedSlidesArray.filter((slide) => slide.id === slideId)
    const duplicatedSlide = {...newSlides[0], id: makeid(), name: !newSlides[0].name.endsWith('(copy)') ? `${newSlides[0].name}(copy)` : `${newSlides[0].name}`}
    const slidesTmp = JSON.parse(JSON.stringify([...slides]))
    slidesTmp.push({...duplicatedSlide})
    // setSelectedSlide(slidesTmp[slidesTmp.length-1])
    setSlides(slidesTmp)
  }

  const addNewSlide = (slideObj) => {
    const currentSlides = slides ? [...slides] : []
    setSelectedSlide({})
    currentSlides.push({...slideObj})
    // setSelectedSlide(currentSlides[currentSlides.length-1])
    setSlides(currentSlides)
    // console.log('new slide', slideObj)
    // setSlides([{...slides,...slideObj}])
  }

  useEffect(() => {
    if (storyFlatValues) {
      if (story?.uuid) {
        const storyTmp = {...story}
        storyTmp.storyFlatValues = storyFlatValues
        setStory(storyTmp)
      }
    }
    //eslint-disable-next-line
  }, [storyFlatValues])

  const handleChangeFlatValues = (flatValueChanged) => {
    setStoryFlatValues({...storyFlatValues, ...flatValueChanged})
  }

  return <React.Fragment>
    <div
      // onClick={handlePageClick}
      style={{height: '100vh', backgroundColor: 'rgb(243 243 243)'}}
    >
      <DeleteDialog open={openDeleteDialog} handleClose={closeDeleteDialog} handleDelete={handleDelete}/>
      {redirectToListing && <Redirect to={`/stories`}/>}

      <BaseContent
        title={'New Story'}
        isRoot={true}
        renderActions={() => {
          return <div className='story-toolbar'>
            <Link component={Button} color='default' className='d-inline-block mr-2' to={`/repox-story/${uuid}`}
                  target='_blank' id='preview-story'> Preview </Link>
            <Button onClick={()=>setOpenDownload(!openDownload)} disabled={loadingExport}>
              {loadingExport && (
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
            )}
              Download
            </Button>

          </div>
        }}
        breadcrumbs={[
          {title: 'Stories', link: '/stories'},
          {title: `${storyFlatValues?.title ? storyFlatValues.title : ''}`, link: `/stories/edit/${uuid}`}
        ]}
      >
        <Container fluid>
          <Row>
            <div>

              <SlideShowEditor key={`storyUUID-${uuid}`} handleDeleteSlide={handleDeleteSlide}
                               handleDuplicateSlide={handleDuplicateSlide}
                               updateSlides={updateSlides}
                               openElementEditAction={setOpenElementEditAction}
                               story={story}
                               aspectRatio={storyFlatValues.aspectRatio}
                               orientation={storyFlatValues.orientation}
                               selectedEditElement={selectedEditElement}
                               setSelectedEditElement={setSelectedEditElement} slides={slides}
                               initialSelectedSlide={initSelectedSlide}
                               handleSelectedSlide={setSelectedSlide}
                               loading={loading}
                               addNewSlide={addNewSlide} handleChangeSlide={handleChangeSlide} {...props}/>

            </div>
            <div><StoryConfigurationBlock slides={slides} selectedSlide={selectedSlide}
                                          selectedEditElement={selectedEditElement}
                                          setSelectedEditElement={setSelectedEditElement}
                                          openElementEditAction={openElementEditAction}
                                          setOpenElementEditAction={setOpenElementEditAction}
                                          handleChangeSlide={handleChangeSlide}
                                          handleChangeConfigurationBlocks={handleChangeFlatValues}
                                          story={story}
                                          storyTags={storyTags}
                                          setStoryTags={setStoryTags}
                                          allStoryTags={allStoryTags}
                                          configurationBlocks={storyFlatValues}
                                          handleUpdate={handleUpdate}
                                          screensPairing={screensPairing}
                                          loading={loading}
                                          handleDeleteItem={handleDeleteItem}
            /></div>
          </Row>
        </Container>
      </BaseContent>
      <DownloadModal
        open={openDownload}
        handleClose={()=>setOpenDownload(!openDownload)}
        uuid={uuid}
        loadingExport = {loadingExport}
        setLoadingExport = {setLoadingExport}
      />
    </div>
  </React.Fragment>
}
export default StoryOverview
