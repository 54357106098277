import {
  createDeleteMethod,
  createGetAllMethod,
  createGetMethod,
  createPostMethod,
  createPutMethod,
} from '../core/factory';
import {entityType} from "./module";

export const getAllDataStreamTypes = () => createGetAllMethod(entityType)
export const getDataStreamType = (params) => createGetMethod(entityType, params)
export const deleteDataStreamType = (payload) => createDeleteMethod(entityType, payload)
export const createDataStreamType = (payload) => createPostMethod(entityType, payload)
export const updateDataStreamType = (payload) => createPutMethod(entityType, payload)

