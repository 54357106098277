import React from 'react'
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import BaseContent from "../../components/Common/BaseContent";
import guidesEmpty from '../../assets/svg/guide_empty.svg'
import {Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";



const GuideDirectory = () =>{
  return <BaseContent
  >
    <Container style={{paddingTop: 30, minHeight: '120px'}}>
      <Grid container direction={'column'} alignContent={'center'} alignItems={'center'}>
        <Grid item lg={4} style={{height:300}}>
          <img src={guidesEmpty} height={'280'}/>
        </Grid>
        <Grid item lg={12} style={{marginTop:10}}>
          <Typography>No Guides found</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
      <Card className='c-item-add-new' style={{minHeight:263, minWidth:200}}>
        <Link to={`/guide/new`} style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}> </Link>
        <CardBody className='d-flex justify-content-center align-content-center align-items-center flex-column'>
          <div className='d-flex'>
            <i className='bx bx-plus'></i>
          </div>
          <div className='d-flex'>
            Add new Guide
          </div>
        </CardBody>
      </Card>
        </Grid>
      </Grid>
    </Container>
  </BaseContent>
}


export default GuideDirectory