import {
  createDeleteMethod,
  createGetAllMethod,
  createGetMethod,
  createPostMethod,
  createPutMethod,
} from '../core/factory';
import {entityType} from "./module";

export const getAllVocabularies = () => createGetAllMethod(entityType)
export const getVocabulary = (params) => createGetMethod(entityType,params)
export const deleteVocabulary = (payload) => createDeleteMethod(entityType, payload)
export const createVocabulary = (payload) => createPostMethod(entityType, payload)
export const updateVocabulary = (payload) => createPutMethod(entityType, payload)
