import React, {useMemo, useState, useRef} from 'react';
import {Row} from 'reactstrap';
import ContainerCards from './container-cards';
import EntitySidebar from '../../components/Common/entity-sidebar';
import {
  createContainer,
  deleteContainer,
  getAllContainers,
  updateContainer,
} from '../../store/container/actions';
import {connect} from 'react-redux';
import BaseContent from '../../components/Common/BaseContent';
import Toolbar from '../../components/Common/Toolbar';
import {EntityDialog} from './EntityDialog';
import {
  useContainerActions,
  useContainers,
  useListToggle,
  useToggle,
} from '../../helpers/hooks';
import {EntityType} from '../../store/core/entityType';
import {Schema} from '../../store/core/schema';
import {ObjectType} from '../../constants/enums';
import {toggleRightSidebar} from '../../store/layout/actions';
import NoFiles from './NoFiles';
import ImportDialog from './import-dialog';
import ExportDialog from './ExportDialog';
import ContainerList from './container-list';
import MoveDialog from "./MoveDialog";
import LinkDialog from "./LinkDialog";
import ReportDialog from './report-dialog';
import DeleteDialog from './DeleteDialog';
import PrintPreviewDialog from "./PrintPreviewDialog";
import {createOperationState} from '../../store/core/factory';
import {MandatoryContext} from './MandatoryContext'
import RenameDialog from './rename-dialog'
import axios from 'axios'
import {API_URL} from '../../config'
import { getAxiosDefaultConfig } from '../../utils'
import {useReactToPrint} from "react-to-print";

const entityType = EntityType.Container;

function ContainersRoot(props) {
  const {
    context,
    createContainer,
    updateContainer,
    deleteContainer,
    getAllContainers,
    toggleRightSidebar,
  } = props;
  const [open, setOpen] = useState(false);
  const {result, isLoading, error} = props.context.Container.getAll;
  const propertyGroups = props.context.PropertyGroup.getAll.result;
  const containerGetter = createOperationState()
  const properties = props.context.Property.getAll.result;
  const {showRightSidebar} = props.context.Layout;
  const [entity, setEntity] = useState(Schema[entityType]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const containerTypes = props.context.ContainerType.getAll.result;
  const [parentContainer, setParentContainer] = useState({});
  const [openImport, setOpenImport] = useState(false);
  const [openRename,setOpenRename] = useState(false)
  const [openExport, setOpenExport] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openPrintPreview, setOpenPrintPreview] = useState(false);
  const [showList, toggleListLayout] = useListToggle();
  const [showDelete, toggleDelete] = useToggle(false);
  const [mandatoryNotice, setMandatoryNotice] = useState(false)
  const {
    selectedContainer,
    selectedContainers,
    currentContainer,
    busy,
    handleContainerSelect,
    handlePageClick,
    handleContainerClick,
    handleContextClick,
    tags,
    isSelected,
  } = useContainers({
    history: props.history,
    thisContainerUuid: null,
    setEntity,
    showList,
    containerGetter
  });

  const fullSelectedContainersObject = useMemo(() => {
    return result.filter((container) =>
      selectedContainers.includes(container.uuid)
    );
  }, [result, selectedContainers]);

  const {handleStarContainers, handleDeleteContainers, handleContainersExport} = useContainerActions(
    currentContainer,
    fullSelectedContainersObject,
    getAllContainers,
    true//isRoot
  );

  const handleRename = (label,uuid) =>{
    const payload = {
      label:label
    }
    axios.put(`${API_URL}/containers/${uuid}/label`,payload,getAxiosDefaultConfig()).then((res)=>{
      getAllContainers()
      setOpenRename(false)
    })
  }

  React.useEffect(() => {
    getAllContainers();
  }, [getAllContainers,openMove]);

  React.useEffect(() => {
    if (currentContainer && currentContainer.id) {
      setEntity(currentContainer);
    }
  }, [currentContainer]);

  const handleEditClick = (container) => {
    setEntity(container);
    setOpen(true);
  };
  const handleAddColor = (color) =>{
    const payload = {
      color:color
    }
    axios.put(`${API_URL}/containers/${selectedContainer}/color`,payload,getAxiosDefaultConfig()).then((res)=>{
      getAllContainers()
      setOpenRename(false)
    })
  }

  const handleToolbarEdit = () => {
    handleEditClick(currentContainer);
  };

  const handleCreate = () => {
    setEntity(Schema[entityType]);
    setOpen(true);
  };

  const contentClass = showRightSidebar
    ? 'content-with-sidebar'
    : 'content-with-no-sidebar';

  const paths = [{title: 'Content', link: '#'}];

  const entityActions = [
    {title: 'New Content', action: handleCreate, icon: 'bx-plus'},
    {
      title: 'Import files',
      action: () => setOpenImport(true),
      icon: 'bx-import',
    },
    {
      title: 'Export files',
      action: () => setOpenExport(true),
      icon: 'bx-export',
    },
  ];
  
  const handleReportClick = (container) => {
    setEntity(container);
    setOpenReport(true);
  };


  const handlePrintPreviewClick = (container) => {
    setEntity(container);
    setOpenPrintPreview(true);
  };  
  const handleMoveClick = (container) => {
    setEntity(container);
    setOpenMove(true);
  };
  const handleLinkClick = (container) => {
    setEntity(container);
    setOpenLink(true);
  };  
  const importCallback = () => {
    if (!selectedContainer) {
      getAllContainers();
    } else {
      props.history.push('/containers/' + selectedContainer);
    }
  };

  const ContainerItemsLayout = useMemo(() => {
    return showList ? ContainerList : ContainerCards;
  }, [showList]);

  return (
    <React.Fragment>
      <MandatoryContext.Provider value={{mandatoryNotice, setMandatoryNotice}}>
        <div
          className={`${contentClass}`}
          onClick={handlePageClick}
          style={{minHeight: '100vh'}}
          onContextMenu={(e) => e.preventDefault()}
        >
          <BaseContent
            title={'My Content'}
            error={error}
            entityActions={entityActions}
            handleCreate={handleCreate}
            paths={paths}
            entityType={entityType}
            isLoading={isLoading}
            isRoot={true}
            renderActions={() => (
              <Toolbar
                togglePanel={toggleRightSidebar}
                handleDelete={selectedContainer && toggleDelete}
                handleEdit={selectedContainer && handleToolbarEdit}
                showList={showList}
                toggleListLayout={toggleListLayout}
              />
            )}
          >
            <Row>
              <ContainerItemsLayout
                handleContainerClick={handleContainerClick}
                containers={result}
                selectedContainer={selectedContainer}
                handleContainerSelect={handleContainerSelect}
                handleEditClick={handleEditClick}
                handleAddColor={handleAddColor}
                currentContainer={currentContainer}
                handleDelete={deleteContainer}
                openImport={() => setOpenImport(true)}
                openRename={()=>setOpenRename(true)}
                openExport={() => setOpenExport(true)}
                openMove={() => setOpenMove(true)}
                openLink={() => setOpenLink(true)}
                openReport={() => setOpenReport(true)}
                toggleDelete={toggleDelete}
                showList={showList}
                handleContextClick={handleContextClick}
                isSelected={isSelected}
                handleStarContainers={handleStarContainers}
                handleReportClick={handleReportClick}
                handlePrintPreviewClick={handlePrintPreviewClick}
                handleMoveClick={handleMoveClick}
                handleLinkClick={handleLinkClick}                
                {...props}
              />
            </Row>
            {result && result.length === 0 && !isLoading && <NoFiles/>}
            <EntitySidebar
              open={showRightSidebar}
              entity={currentContainer}
              handleClose={toggleRightSidebar}
              objectType={ObjectType.container}
              entityTags={tags}
              busy={busy}
              entityType={entityType}
              properties={properties}
            />
          </BaseContent>
        </div>
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={open}
          isRoot = {true}
          handleClose={() => setOpen(false)}
          createEntity={createContainer}
          updateEntity={updateContainer}
          // deleteEntity={selectedContainer && deleteContainer}
          containerTypes={containerTypes}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          context={context.Container}
          rootContext={context}
          propertyGroups={propertyGroups}
           handlePageClick={handlePageClick}
        />
        <ImportDialog
          open={openImport}
          handleClose={() => setOpenImport(false)}
          callback={importCallback}
          containerUuid={selectedContainer}
        />
        <RenameDialog
          handleClose = {()=>setOpenRename(false)}
          open={openRename}
          handleRename = {handleRename}
          containerUuid={selectedContainer}
        />
        <ExportDialog
          open={openExport}
          handleClose={() => setOpenExport(false)}
          handleContainersExport={handleContainersExport}
        />
      <MoveDialog
          open={openMove}
          handleClose={() => setOpenMove(false)}
          selectedContainer={fullSelectedContainersObject[0]}
          parentContainer={parentContainer}
        />  
      <LinkDialog
          open={openLink}
          handleClose={() => setOpenLink(false)}
          selectedContainer={fullSelectedContainersObject[0]}
          parentContainer={parentContainer}
      />        
      <ReportDialog
          open={openReport}
          handleClose={() => setOpenReport(false)}
          uuid={selectedContainer}
      />        
        <DeleteDialog
          handleClose={toggleDelete}
          open={showDelete}
          handleDelete={handleDeleteContainers}
        />
        <PrintPreviewDialog
          handleClose={() => setOpenPrintPreview(false)}
          open={openPrintPreview}
          selectedContainer={fullSelectedContainersObject[0]}
          properties={properties}
        />
      </MandatoryContext.Provider>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createContainer,
  updateContainer,
  deleteContainer,
  getAllContainers,
  toggleRightSidebar,
})(ContainersRoot);
