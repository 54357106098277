import React, {Fragment, useMemo, useState} from "react";
import BaseContent from "../../components/Common/BaseContent";
import {ApiEndpoint} from "../../store/core/endpoint";
import {EntityType} from "../../store/core/entityType";
import {useCheckList, useFetch} from "../../helpers/hooks";
import {Col, Row, Label, Table} from "reactstrap";
import TrashListItem from "./trash-list-item";
import {connect} from "react-redux";
import {createMap, deleteData, getAxiosDefaultConfig, postData} from "../../utils";
import toastr from "toastr";
import BaseDialog from "../../components/Common/BaseDialog";
import AsyncSelect from "react-select/async";
import SweetAlert from "react-bootstrap-sweetalert";
import ArrowUpIcon from "@icons/material/ArrowUpIcon";
import ArrowDownIcon from "@icons/material/ArrowDownIcon";
import SearchNavigation from "../../components/Common/SearchNavigation";

const breadcrumbs = [{title: "Trash", link: "#"}];

function DeletedContainers(props) {
  const url = ApiEndpoint[EntityType.Trash] + "/items";
  const [isLoading, items, error, setState] = useFetch(url, []);
  const itemIds = useMemo(() => items.map((item) => item.uuid), [items]);
  const [selectedItems, handleCheck, isChecked] = useCheckList(itemIds);
  const users = props.context.User.getAll.result;
  const userMap = useMemo(() => createMap(users), [users]);
  const [openRevert, setOpenRevert] = useState(false);
  const [busy, setBusy] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const joinItems = useMemo(() => {
    return items.map((item) => ({
      ...item,
      user: {
        ...userMap.get(item.deletedBy.toString()),
      },
    }));
  }, [items, userMap]);

  const itemsMap = useMemo(() => createMap(joinItems, "uuid"), [joinItems]);

  const handleCommonAction = async (url, request) => {
    // if (selectedContainer !== null) {
      const finalItems = currentItem ? [currentItem] : selectedItems;
      const payload = {
        container: selectedContainer,
        objects: finalItems.map((itemId) => {
          const item = itemsMap.get(itemId);
          return {
            objectType: item.objectType,
            objectId: item.uuid,
          };
        }),
      };

      try {
        setBusy(true);
        await request(url, payload);
        setBusy(false);
        setOpenRevert(false);
        setOpenDelete(false);
        setCurrentItem(null);
        setState((prevState) => {
          return prevState.filter((item) => {
            return !payload.objects.find((x) => x.objectId === item.uuid);
          });
        });
        setSelectedContainer(null);
      } catch (e) {
        setCurrentItem(null);
        setBusy(false);
        toastr.error(e.message);
      }
    // } else {
    //   toastr.error("Select a destination");
    // }
  };

  const handlePutBackMultiple = () => {
    handleCommonAction(ApiEndpoint[EntityType.Trash] + "/items/restore", postData);
  };

  const handleRemoveMultiple = () => {
    handleCommonAction(ApiEndpoint[EntityType.Trash] + "/items", deleteData);
  };

  const handlePutBack = (itemId) => {
    setCurrentItem(itemId);
    setOpenRevert(true);
  };

  const handleRemove = (itemId) => {
    setCurrentItem(itemId);
    setOpenDelete(true);
  };

  const loadOptions = async (inputValue, callback) => {
    const searchUrl = `${ApiEndpoint[EntityType.Search]}?q=${inputValue}`;
    const payload = {
      field:'',
      operation: 'OP_CONTAINER',
      value:inputValue
    }
    try {
      const result = await postData(searchUrl, payload, getAxiosDefaultConfig());
      callback(
        result.containers.map((container) => ({
          label: container.label,
          value: container.uuid,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("label");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleTableHeadSort = (value) => {
    const isAsc = orderBy === value && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(value);
  };

  return (
    <BaseContent
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      error={error}
      renderActions={() => (
        <Fragment>
          <button disabled={selectedItems.length === 0} type="button" onClick={() => setOpenRevert(true)} className="btn font-size-18" data-toggle="fullscreen">
            <i className="bx bx-reset" />
          </button>
          <button onClick={() => setOpenDelete(true)} disabled={selectedItems.length === 0} type="button" className="btn font-size-18" data-toggle="fullscreen">
            <i className="bx bx-trash" />
          </button>
        </Fragment>
      )}
    >
      <Row>
        <Col lg="12">
          <div className="">
            <div className="table-responsive">
              <Table className="project-list-table table-nowrap table-centered table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{width: "100px"}}>
                      <input type={"checkbox"} checked={isChecked("ALL")} onChange={() => handleCheck("ALL")} />
                    </th>
                    <th
                      scope="col"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        handleTableHeadSort("label");
                      }}
                    >
                      File{" "}
                      {orderBy === "label" ? (
                        order === "asc" ? (
                          <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                        ) : (
                          <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                        )
                      ) : (
                        <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                      )}
                    </th>
                    <th
                      scope="col"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        handleTableHeadSort("objectType");
                      }}
                    >
                      Type{" "}
                      {orderBy === "objectType" ? (
                        order === "asc" ? (
                          <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                        ) : (
                          <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                        )
                      ) : (
                        <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                      )}
                    </th>
                    <th
                      scope="col"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        handleTableHeadSort("deletedAt");
                      }}
                    >
                      Deleted at{" "}
                      {orderBy === "deletedAt" ? (
                        order === "asc" ? (
                          <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                        ) : (
                          <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                        )
                      ) : (
                        <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                      )}
                    </th>
                    <th
                      scope="col"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        handleTableHeadSort("deletedBy");
                      }}
                    >
                      Deleted by{" "}
                      {orderBy === "deletedBy" ? (
                        order === "asc" ? (
                          <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                        ) : (
                          <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                        )
                      ) : (
                        <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                      )}
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {stableSort(joinItems, getComparator(order, orderBy)).map((item, key) => (
                    <TrashListItem key={key} item={item} index={key} isChecked={isChecked(item.uuid)} handleCheck={() => handleCheck(item.uuid)} handlePutBack={handlePutBack} handleRemove={handleRemove} />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <BaseDialog title={"Select container to put back your files"} open={openRevert} busy={busy} primaryAction={handlePutBackMultiple} primaryTitle={"Revert"} handleClose={() => setOpenRevert(false)}>
        <Row style={{height: 80}}>
          <Col>
            <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions onChange={(opt) => setSelectedContainer(opt.value)} />
          </Col>
        </Row>
        {/* <Row>
          <Col xs={12}>
            <Label>Select destination</Label>
            <SearchNavigation selectedContainer={selectedContainer} parentContainer={selectedContainer} setMoveDestination={setSelectedContainer} />
          </Col>
        </Row> */}
      </BaseDialog>
      {openDelete ? (
        <SweetAlert title={"Delete confirmation"} warning onConfirm={handleRemoveMultiple} onCancel={() => setOpenDelete(false)}>
          Are you sure to delete the selected items permanently? This action cannot be undone.
        </SweetAlert>
      ) : null}
    </BaseContent>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};
export default connect(mapStateToProps, {})(DeletedContainers);
