import React, {useEffect, useState} from 'react';
import {Button, Col, Container, CustomInput, Input, Label, Nav, NavItem, Row} from "reactstrap";
import classnames from "classnames";
import {connect} from "react-redux";
import {getSurvey} from "../../../store/survey/actions";
import {Redirect, useParams} from "react-router";

import Summary from "../summary/Summary";
import Individual from "./Individual";
import {
  clearSurveyResponse,
  createSurveyResponse,
  getSurveyResponse,
  updateSurveyResponse
} from "../../../store/surveyResponse/actions";
import TableView from "./tableView/TableView";
import {Link} from "react-router-dom";
import {downloadFileGeneral, getAxiosDefaultConfig} from "../../../utils";
import {API_URL} from "../../../config";
import axios from "axios";
import DeleteDialog from "../../Containers/DeleteDialog";
import toastr from "toastr";

const Index = (props) => {
  const {uuid, subpage} = useParams()

  const {context, getSurveyResponse, surveyData, setSurvey, setTotalResponses} = props;
  const {result, isLoading} = context.SurveyResponse.get

  const [activeTab, setActiveTab] = useState(1);
  const [isLoadingDel, setIsLoadingDelete] = useState();
  const [deletedAll, setDeletedAll] = useState(false)


  const [isVisible, setIsVisible] = useState();
  const onVisibilityChange = () => setIsVisible(document.hidden);

  useEffect(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  });

  useEffect(()=>{
    if (!isVisible) {
      getSurveyResponse(uuid);
    }
    // eslint-disable-next-line
  },[isVisible])

  const setLocked = () => {
    setSurvey({...surveyData, locked: !surveyData.locked})
  }

  const setLockMessage = (e) => {
    const value = e.target.value
    setSurvey({...surveyData, lockedMessage: value})
  }

  const [downloadLoading, setDownloadLoading] = useState()

  const downloadExcel = () => {
    downloadFileGeneral(`${API_URL}/addons/srv/surveys/${uuid}/export`,downloadLoading, setDownloadLoading)
  }

  const deleteAll = () => {
    setIsLoadingDelete(true)
    axios.delete(`${API_URL}/addons/srv/surveys/${uuid}/responses`,getAxiosDefaultConfig())
      .then((response) => {
        getSurveyResponse(uuid);
        setIsLoadingDelete(false);
        setDeletedAll(true)
        toastr.success(`Successfully deleted all responses`);
      })
      .catch(error => {
        toastr.error(`An error occurred deleting responses`);
        setIsLoadingDelete(false);
        setDeletedAll(false)
      })
  }

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  useEffect(() => {
    switch (subpage) {
      case 'summary': {
        setActiveTab(1)
        break;
      }
      case 'individual': {
        setActiveTab(2)
        break;
      }
      case 'list' : {
        setActiveTab(3)
        break;
      }
      default:
        setActiveTab(1)
        break;
    }
  }, [subpage])

  useEffect(() => {
    getSurveyResponse(uuid)
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (result&&result.responseUuids) setTotalResponses(result.responseUuids.length)
    // eslint-disable-next-line
  },[result])

  if (!surveyData) return <> </>

  return (
    <Container>
      {deletedAll&&<Redirect to={`/surveys/edit/${uuid}`} />}
      <DeleteDialog open={openDeleteDialog} handleClose={closeDeleteDialog} handleDelete={deleteAll} question={`Are you sure you want to remove all of responses?`}/>
      <Row>
        <Col xs={12} md={{size: 10, offset: 1}} className='responses-wrapper'>

          <div className='mb-4'>
            <Container>
              <Row className='mb-4'>
                <Col xs={12} sm={6}>
                  <h3 className='mt-2'>{result && result.responseUuids && <>{result.responseUuids.length} Responses</>} &nbsp;</h3>
                </Col>
                <Col style={{textAlign: 'right'}} className='mt-1'>

                  <Row>
                    {result && result.responseUuids && <><Col xs={12}  className='mb-4'>
                      <Button outline color="info" onClick={downloadExcel}>{downloadLoading?<i className='bx bx-loader-circle'></i>:<i className='bx bx-download'></i>} &nbsp; Download Excel</Button> {' '}
                      {' '}
                      <Button outline color="danger" onClick={()=>setOpenDeleteDialog(true)}> <i className='bx bxs-trash-alt'></i> &nbsp; Delete all</Button> {' '}
                    </Col></>}
                    <Col xs={12}>
                      <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="Accepting responses"
                                   checked={!surveyData.locked} onChange={setLocked}/>
                    </Col>
                  </Row>

                </Col>
              </Row>

              {surveyData.locked && <>
                <Row>
                  <Col xs={12}>
                    <Label for="locked-message">Message for respondents*</Label>
                    <Input type='text' name='locked-message' placeholder='e.g. Submissions are closed for now'
                           onChange={setLockMessage} value={surveyData.lockedMessage}/>
                  </Col>
                </Row>
              </>}

            </Container>
          </div>

          <div className='survey-nav'>
            <Nav tabs style={{display: 'block'}}>
              <NavItem>
                <Link to={`/surveys/edit/${uuid}/responses/summary`}
                      className={classnames({active: activeTab === 1, 'nav-link': true})}> Summary </Link>
              </NavItem>
              <NavItem>
                <Link to={`/surveys/edit/${uuid}/responses/individual`}
                      className={classnames({active: activeTab === 2, 'nav-link': true})}> Individual </Link>
              </NavItem>
              <NavItem>
                <Link to={`/surveys/edit/${uuid}/responses/list`}
                      className={classnames({active: activeTab === 3, 'nav-link': true})}> List </Link>
              </NavItem>
            </Nav>
          </div>

          <div >
            {(isLoading || isLoadingDel) && <div className='custom-loading-overlay'></div>}
            {activeTab === 1 && <Summary key={surveyData.responseCount}/>}
            {activeTab === 2 && <Individual UUIDs={result.responseUuids} surveyData={surveyData} key={surveyData.responseCount}/>}
            {activeTab === 3 && <TableView surveyData={surveyData} key={surveyData.responseCount}/>}
          </div>

        </Col>

      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getSurvey,
  getSurveyResponse,
  createSurveyResponse,
  updateSurveyResponse,
  clearSurveyResponse
})(Index);
