import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  clearDataStream,
  deleteDataStream,
  getDataStream,
  starDataStream,
  updateDataStream,
} from "../../store/dataStream/actions";
import {
  clearContainer,
  clearContainers,
  deleteDataStreamFromContainer,
  getContainer,
} from "../../store/container/actions";
import { useDataStreams, useToggle } from "../../helpers/hooks";
import { Schema } from "../../store/core/schema";
import { EntityType } from "../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import { Modal } from "reactstrap";
import FilePreview from "./FilePreview";
import { toggleRightSidebar } from "../../store/layout/actions";
import { ObjectType } from "../../constants/enums";
import EntitySidebar from "../../components/Common/entity-sidebar";
import { PortalToolbar } from "./PortalToolbar";
import { handleStar } from "../../helpers/actions";

const entityType = EntityType.DataStream;

const DataStreamPortal = (props) => {
  const {
    getDataStream,
    clearDataStream,
    clearContainer,
    uuid,
    deleteDataStream,
    deleteDataStreamFromContainer,
    starDataStream,
  } = props;
  const { context, updateDataStream, dismiss } = props;
  const { result, isLoading } = props.context.DataStream.get;
  const deleteFinished = props.context.DataStream.delete.isLoading;
  const properties = props.context.Property.getAll.result;
  const propertyGroups = props.context.PropertyGroup.getAll.result;
  const dataStreamTypes = props.context.DataStreamType.getAll.result;
  const [entity, setEntity] = useState(Schema[entityType]);
  const [openModal, setOpenModal] = useState(false);

  const { busy, preview, tags } = useDataStreams(uuid);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSidebar, toggleSidebar] = useToggle();

  const handleToolbarDelete = () => {
    setEntity(result);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    if (uuid) {
      getDataStream(uuid);
    }
  }, [clearContainer, clearDataStream, getDataStream, uuid]);

  const handleEditClick = (dataStream) => {
    setEntity(dataStream);
    setOpenModal(true);
  };

  const handleStarDataStream = () => {
    handleStar(result, ObjectType.dataStream, starDataStream);
  };

  const handleToolbarEditClick = () => {
    handleEditClick(result);
  };

  useEffect(() => {
    if (deleteFinished) {
      dismiss();
      deleteDataStreamFromContainer({ uuid: result.uuid });
    }
  }, [deleteDataStreamFromContainer, deleteFinished, dismiss, result.uuid]);

  return (
    <Modal
      isOpen={uuid}
      toggle={dismiss}
      modalClassName={"portal"}
      backdropClassName={"portal"}
    >
      <PortalToolbar
        result={result}
        handleClose={props.dismiss}
        handleEdit={handleToolbarEditClick}
        handleDelete={handleToolbarDelete}
        toggleRightSidebar={toggleSidebar}
        handleStar={handleStarDataStream}
      />
      <div
        onClick={props.dismiss}
        className={"d-flex justify-content-center p-2"}
        style={{ height: "100%", width: "100%" }}
      >
        <FilePreview
          isLoading={busy}
          fileBase64={preview}
          fileType={result.mimeType}
        />
        <EntitySidebar
          isFolder={false}
          open={showSidebar}
          entity={result}
          entityType={entityType}
          handleClose={toggleSidebar}
          entityTags={tags}
          objectType={ObjectType.dataStream}
          properties={properties}
          busy={busy}
          showProperties={true}
          isLoadingCurrent={isLoading}
        />
      </div>
      <EntityDialog
        entity={entity}
        entityType={entityType}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        // createEntity={createGroup}
        deleteEntity={deleteDataStream}
        updateEntity={updateDataStream}
        context={context.DataStream}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        dataStreamTypes={dataStreamTypes}
        propertyGroups={propertyGroups}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getDataStream,
  clearDataStream,
  clearContainer,
  clearContainers,
  getContainer,
  updateDataStream,
  deleteDataStream,
  toggleRightSidebar,
  deleteDataStreamFromContainer,
  starDataStream,
})(DataStreamPortal);
