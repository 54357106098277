import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

const TagList = (props) => {
  const [topTags, setTopTags] = useState([])
  useEffect(() => {
    if (props.tags?.length > 3)
      setTopTags(props.tags.slice(0, 3).map((tag) => tag))
    else
      setTopTags(props.tags)
  }, [props.tags])

  return (
    <Fragment>
      <ul className={'metismenu list-unstyled'}>
        <li className="menu-title">{props.t('Tags')}</li>
        {topTags.map((tag,i) => (
          <li key={i}>
            <Link to={`/tagged/${tag.id}`} className="waves-effect">
              <i
                className="mdi mdi-circle align-middle mr-1"
                style={{color: tag.color}}
              />
              <span>
                {props.t(tag.name)}
              </span>
            </Link>
          </li>
        ))}
        {props.tags.length > 3 &&
        <li className='mt-1 font-size-10'>
          <Link to={'/settings/tags'}> Show all tags</Link>
        </li>
        }
      </ul>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.Tag.getAll.result,
  };
};
export default connect(mapStateToProps, null)(TagList);
