import React, {Fragment, useState} from 'react';
import EditDialog from "../../components/Common/EditDialog";
import { EntityType } from "../../store/core/entityType";
import {useDataStreamDialogLayout} from "./hooks";
import {getAxiosDefaultConfig} from '../../utils'
import axios from 'axios'
import {handleError} from '../../store/core/api'
import {API_URL} from '../../config'
import toastr from 'toastr'

const EntityDialog = (props) => {
  const {
    entity,
    dataStreamTypes,
    open,
    createEntity,
    deletedEntity,
    updateEntity,
    propertyGroups,
    currentContainer,
  } = props;
  const [openShortForm, setOpenShortForm] = useState(false);

  const {
    steps,
    contents,
    basicLayout,
    form,
    handleCreate,
    handleUpdate,
    handleShortUpdate,
    shortLayout,
  } = useDataStreamDialogLayout(
    entity,
    EntityType.DataStream,
    dataStreamTypes,
    open,
    createEntity,
    updateEntity,
    propertyGroups
  );

  const subtitle = form.id && (form.type.name || form.type);
  const handleDeleteEntity = () =>{
    const url = `${API_URL}/datastreams/${form.id}/remove?from=${currentContainer}`
    return axios
      .post(url, null,getAxiosDefaultConfig())
      .then((response) => {
        toastr.success('Removed successfully')
        deletedEntity(true)
      })
      .catch(handleError);
  }
  return (
    <Fragment>
      <EditDialog
        {...props}
        scrollable={true}
        createEntity={handleCreate}
        subtitle={subtitle}
        updateEntity={handleUpdate}
        titleOverride={form.id ? `Edit ${form.label}` : "Create datastream"}
        handleEditTitle={() => setOpenShortForm(true)}
        form={form}
        size={"lg"}
        steps={steps}
        contents={contents}
        extraClassName={"modal-full-height"}
        keepOpen={openShortForm}
        // isValid={isValid}
      >
        {basicLayout}
      </EditDialog>
      <EditDialog
        {...props}
        form={form}
        open={openShortForm}
        currentContainer={currentContainer}
        handleClose={() => setOpenShortForm(false)}
        updateEntity={handleShortUpdate}
        deleteEntity={handleDeleteEntity}
        size={"sm"}
        isValid={!!form.label}
      >
        {shortLayout}
      </EditDialog>
    </Fragment>
  );
};

export default EntityDialog;
