import {all} from 'redux-saga/effects';
//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import ContainerSaga from './container/saga';
import ContainerTypeSaga from './containerType/saga';
import DataStreamTypeSaga from './dataStreamType/saga';
import PropertyTypeSaga from './propertyType/saga';
import PropertySaga from './property/saga';
import UserSaga from './user/saga';
import GroupSaga from './group/saga';
import TagSaga from './tag/saga';
import DataStreamSaga from './dataStream/saga';
import NamespaceSaga from './namespace/saga';
import PropertyGroupSaga from './propertyGroup/saga';
import QualifierSaga from './qualifier/saga'
import SystemSettingSaga from './systemSetting/saga'
import SurveySaga from './survey/saga';
import AssetSaga from './asset/saga'
import StorySaga from './story/saga'
import SurveyResponseSaga from './surveyResponse/saga';
import PermissionsSaga from './permissions/saga';
import MyPermissionsSaga from './myPermissions/saga';
import MyProfile from './myProfile/saga'
import Vocabulary from './vocabulary/saga'

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        LayoutSaga(),
        ContainerSaga(),
        ContainerTypeSaga(),
        DataStreamTypeSaga(),
        PropertyTypeSaga(),
        PropertySaga(),
        UserSaga(),
        GroupSaga(),
        TagSaga(),
        DataStreamSaga(),
        NamespaceSaga(),
        PropertyGroupSaga(),
        QualifierSaga(),
        SystemSettingSaga(),
        SurveySaga(),
        AssetSaga(),
        StorySaga(),
        SurveyResponseSaga(),
        PermissionsSaga(),
        MyPermissionsSaga(),
        MyProfile(),
        Vocabulary(),
    ])
}
