import React, {useState, useEffect} from "react";
import CardSettings from "../../../components/Common/card-settings";
import BaseDialog from "../../../components/Common/BaseDialog";
import BaseContent from "../../../components/Common/BaseContent";
import Toolbar from "../../../components/Common/Toolbar";
import {useListToggle} from "../../../helpers/hooks";
import {Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import axios from "axios";
import {API_URL} from "../../../config";
import {getAxiosDefaultConfig} from "../../../utils";
import toastr from "toastr";
import ListSettings from "../../../components/Common/ListSettings";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";

const settings = [
  {
    label: "Container types",
    route: "/settings/content-model/container-types",
    icon: "bx-folder",
  },
  {
    label: "Datastream types",
    route: "/settings/content-model/datastream-types",
    icon: "bx-data",
  },
  {
    label: "Properties",
    route: "/settings/content-model/properties",
    icon: "bx-detail",
  },
  {
    label: "Namespaces",
    route: "/settings/content-model/namespaces",
    icon: "bx-world",
  },
  {
    label: "Qualifiers",
    route: "/settings/content-model/qualifiers",
    icon: "bx-grid-horizontal",
  },
  {
    label: "Vocabularies",
    route: "/settings/content-model/vocabularies",
    icon: "bx-book",
  },
];

const breadcrumbs = [
  {title: "Settings", link: "/settings"},
  {title: "Content Model", link: "#"},
];

export const ContentModel = () => {
  // const [payload, setPayload] = useState([
  //   {name:"exportContainerTypes", value: true},
  //   {name:"exportDatastreamTypes", value: true},
  //   {name:"exportProperties", value: true},
  //   {name:"exportNamespaces", value: true},
  //   {name:"exportQualifiers", value: false},
  //   {name:"exportVocabularies", value: true}
  // ]);
  const [checked, setChecked] = useState([false, false, false, false, false, false]);
  const [isBusy, setIsBusy] = useState(true);
  useEffect(() =>
  {
    const checkValid = checked.filter((Checkbox) => Checkbox === true)
    if(checkValid.length!==0){setIsBusy(false)}else{setIsBusy(true)}
  }, [checked]);

  const handleChange = (isChecked, index) => {
    let newChecked = [...checked];
    newChecked[index] = isChecked;
    setChecked(newChecked);
  };

  const [showList, toggleListLayout] = useListToggle();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setChecked([false, false, false, false, false, false]);
    setOpen(false);
  };

  const entityActions = [
    {
      title: "Export files",
      //action: () => setOpenExport(true),
      icon: "bx-cloud-download",
    },
  ];

  // const exportContentModel = () => {
  //   const payload = {
  //     "exportContainerTypes": checked[0],
  //     "exportDatastreamTypes": checked[1],
  //     "exportProperties": checked[2],
  //     "exportNamespaces": checked[3],
  //    // "exportQualifiers": checked[4],
  //     "exportVocabularies": checked[5],
  //   };

  //   axios
  //     .post(`${API_URL}/cm/export`, payload, getAxiosDefaultConfig())
  //     .then((response) => {
  //       toastr.success(`Successfully downloaded`);
  //     })
  //     .catch((error) => {
  //       toastr.error(error.message);
  //     });
  // };

  const exportContentModel = () =>
  {
        const payload = {
          exportContainerTypes: checked[0],
          exportDatastreamTypes: checked[1],
          exportProperties: checked[2],
          exportNamespaces: checked[3],
          // "exportQualifiers": checked[4],
          exportVocabularies: checked[5],
        };

    
     axios
       .post(`${API_URL}/cm/export`, payload, getAxiosDefaultConfig())
       .then((response) =>
       {
          const fileName = "content-model";
          const json = JSON.stringify(response.data, null, 2);
          const blob = new Blob([json],{type:'application/json'});
          const href = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.download = fileName + ".json";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);         
         handleClose()
       })
       .catch((e) => {
         console.log(e);
         toastr.error(`Something went wrong. Error code: ${e.request.status}.`, "Error");
       });
  
    
  };


  const listColumns = [{name: "Name", prop: "label"}];

  return (
    <BaseContent title={"Content Model"} breadcrumbs={breadcrumbs} entityActions={entityActions} renderActions={() => <Toolbar handleDownload={handleOpen} showList={showList} toggleListLayout={toggleListLayout} />}>
      {!showList && <CardSettings settings={settings} hasNext />}
      {showList && <ListSettings contents={settings} columns={listColumns} hasNext />}

      <BaseDialog
        title={`Export content model `}
        size={"md"}
        handleClose={handleClose}
        open={open}
        primaryAction={exportContentModel}
        primaryActionTitle={"Export"}
        primaryActionDisabled={isBusy}
        secondaryAction={handleClose}
        secondaryActionTitle={"Close"}
      >
        <div style={{padding: "0px 40px"}}>
          <Row>
            <Col xs={12}>
              <FormControlLabel
                label="Container Types"
                style={{height: "35px", padding: "0px", margin: "0px"}}
                control={<Checkbox color={"primary"} checked={checked[0]} onChange={(e) => handleChange(e.target.checked, 0)} inputProps={{"aria-label": "controlled"}} />}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormControlLabel
                label="Datastream Types"
                style={{height: "35px", padding: "0px", margin: "0px"}}
                control={<Checkbox color={"primary"} checked={checked[1]} onChange={(e) => handleChange(e.target.checked, 1)} inputProps={{"aria-label": "controlled"}} />}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormControlLabel
                label="Properties"
                style={{height: "35px", padding: "0px", margin: "0px"}}
                control={<Checkbox color={"primary"} checked={checked[2]} onChange={(e) => handleChange(e.target.checked, 2)} inputProps={{"aria-label": "controlled"}} />}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormControlLabel
                label="Namespaces"
                style={{height: "35px", padding: "0px", margin: "0px"}}
                control={<Checkbox color={"primary"} checked={checked[3]} onChange={(e) => handleChange(e.target.checked, 3)} inputProps={{"aria-label": "controlled"}} />}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12}>
              <FormControlLabel
                label="Qualifiers"
                style={{height: "35px", padding: "0px", margin: "0px"}}
                control={
                  <Checkbox color={"primary"}  checked={checked[4]} onChange={(e) => handleChange(e.target.checked, 4)} inputProps={{"aria-label": "controlled"}} />
                }
              />
            </Col>
          </Row> */}
          <Row>
            <Col xs={12}>
              <FormControlLabel
                label="Vocabularies"
                style={{height: "35px", padding: "0px", margin: "0px"}}
                control={<Checkbox color={"primary"} checked={checked[5]} onChange={(e) => handleChange(e.target.checked, 5)} inputProps={{"aria-label": "controlled"}} />}
              />
            </Col>
          </Row>
        </div>
      </BaseDialog>
    </BaseContent>
  );
};
