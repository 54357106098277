import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, FormGroup, Input, Label, Row, Tooltip} from 'reactstrap';
import {GoogleMap, Marker, withGoogleMap} from 'react-google-maps';
import {useToggle} from '../../helpers/hooks';
import Select from 'react-select';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import Geocode from "react-geocode";
import InputMask from 'react-input-mask';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {getAxiosDefaultConfig, makeid} from '../../utils';
import {Editor} from 'react-draft-wysiwyg';
import MaterialInput from '@material-ui/core/Input';
import axios from 'axios'
import {handleError} from '../../store/core/api'
import {ApiEndpoint} from '../../store/core/endpoint'
import {EntityType} from '../../store/core/entityType'
import {ArrayRecord, JSONRecord, Record, TextRecord, UrlRecord, BooleanRecord} from "./Record";
import {MandatoryContext} from '../../pages/Containers/MandatoryContext'
import {API_URL} from '../../config'
import toastr from 'toastr'
import {Autocomplete} from '@material-ui/lab'
import {TextField} from '@material-ui/core'
//import { result } from 'lodash';


const MapWithAMarker = withGoogleMap((props) => {
  const {latState, lngState} = props;
  return (
    <>
      <GoogleMap defaultZoom={8} center={{lat: latState, lng: lngState}}>
        <Marker position={{lat: latState, lng: lngState}} draggable onDragEnd={props.onMarkerDragEnd} />
      </GoogleMap>
    </>
  );
});

const PropertyQualifier = ({property, qualifier, setQualifierValue, value}) => {
  const [qualifierOptions, setQualifierOptions] = useState([])
  const [qualifierSelected, setQualifierSelected] = useState('')

  useEffect(() => {
    if (qualifier.qualifierDetails) {
      const initialValues = JSON.parse(qualifier.qualifierDetails)
      const optionValues = initialValues.values.map((value) => ({
        label: value,
        value: value,
      }));
      setQualifierOptions(optionValues)
    }


// eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (value && value.qualifierValue)
      if (qualifierOptions.length > 0 && qualifier.qualifierDetails.length > 0) {
        setQualifierSelected(qualifierOptions.find((x) => x.value === value.qualifierValue))
      }
    // eslint-disable-next-line
  }, [qualifierOptions])

  const handleQualifierSelected = (e) => {
    setQualifierSelected(qualifierOptions.find((x) => x.value === e.value))
    setQualifierValue({qualifierKey: qualifier.qualifierKey, qualifierValue: e.value})
  }
  return (
    <>
      <Select
        options={qualifierOptions}
        value={qualifierSelected}
        onChange={handleQualifierSelected}
        placeholder={qualifier.description}
        isDisabled={!property.editable}
      />
    </>
  )
}

// const PropertyLanguage = ({property, languages, value, setSelectedLang}) => {
//   const [languagesOptions, setLanguagesOptions] = useState([])
//   const [languageSelected, setLanguageSelected] = useState('')
//   useEffect(() => {
//     if (languages.length > 0) {
//       const optionValues = languages.map((language) => ({
//         label: language.name,
//         value: language.iso639_1,
//       }));
//       setLanguagesOptions(optionValues)
//     }
//     // eslint-disable-next-line
//   }, [languages])
//
//   useEffect(() => {
//     if (languagesOptions && value)
//       if (languagesOptions.length > 0 && value.length > 0) {
//         const propValues = JSON.parse(value)
//         if (propValues.languageCode) {
//           setLanguageSelected(languagesOptions.find((x) => x.value === propValues.languageCode))
//         }
//       }
//     // eslint-disable-next-line
//   }, [value, languagesOptions])
//
//   const handleLanguageChange = (e) => {
//     setLanguageSelected(languagesOptions.find((x) => x.value === e.value))
//     setSelectedLang(e.value)
//   }
//   return (
//     <>
//       <Select
//         options={languagesOptions}
//         value={languageSelected}
//         onChange={handleLanguageChange}
//         placeholder='Language'
//         isDisabled={!property.editable}
//       />
//     </>
//   )
// }

const PropertyToolTip = ({property}) => {
  const [openTooltip, handleToggle] = useToggle();
  return (
    property.description && (
      <>
        <i
          className="bx bx-info-circle ml-2 font-size-12 text-primary"
          id={`textId-${property.id}`}
        />
        <Tooltip
          placement="right"
          isOpen={openTooltip}
          target={`textId-${property.id}`}
          toggle={handleToggle}
        >
          {property.description}
        </Tooltip>
      </>
    )
  );
};

const PropertyTitle = ({property}) => {
  return <>
    {property.displayName} {property.mandatory ?
    <div className='font-size-12 ml-2 d-inline'>*</div>
    :
    ''} <PropertyToolTip property={property}/>
  </>
}

const BooleanProp = ({
                       property,
                       value,
                       handleChangeArray,
                       qualifiers,
                       createRepeatable,
                       deleteRepeatable,
                       joinProps
                     }) => {
  const [id, setId] = useState();
  const [booleanValue, setBooleanValue] = useState(false)

  useEffect(() => {
    const idDate = new Date().toISOString();
    setId(idDate);
  }, [property.id]);

  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [qualifierValue, setQualifierValue] = useState('')
  const [propertyQualifiers, setPropertyQualifiers] = useState([])

  useEffect(() => {
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value)
      let qualifiersToBeShown = []
      setBooleanValue(initialValues.values)
      let initialQualifiers = []
      qualifiers && qualifiers.forEach((qualifier) => {

        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      if (initialValues.qualifiers && initialValues.qualifiers.length > 0) {
        initialValues.qualifiers.forEach((qualifier) => {
          initialQualifiers[qualifier.qualifierKey] = {
            qualifierKey: qualifier.qualifierKey,
            qualifierValue: qualifier.qualifierValue
          }
        })
        setQualifierValue(initialQualifiers)
      }
      setPropertyQualifiers(qualifiersToBeShown)
    } else {
      setBooleanValue(false)
      let qualifiersToBeShown = []
      qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      setPropertyQualifiers(qualifiersToBeShown)
    }
    // eslint-disable-next-line
  }, [property.id])


  const handleChangeBoolean = () => {
    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : []
    const booleanPayload = JSON.stringify({values: !booleanValue, qualifiers: qualifyValues})
    setBooleanValue(!booleanValue)
    handleChangeArray([property.id], booleanPayload)
  }

  useEffect(() => {
    if (qualifierValue) {
      const qualifyValues = Object.values(qualifierValue)
      const booleanPayload = JSON.stringify({values: booleanValue, qualifiers: qualifyValues})
      handleChangeArray([property.id], booleanPayload)
    }
// eslint-disable-next-line
  }, [qualifierValue])

  const handleChangeQualifier = (qualifier) => {

    setQualifierValue({...qualifierValue, [qualifier.qualifierKey]: qualifier})

  }
  const checkTotalElements = () => {
    const findTotalElements = joinProps.reduce((r, n, i) => {
      n.propertyKey === property.propertyKey && r.push(i);
      return r;
    }, [])
    if (findTotalElements.length === 1) {
      setCanBeDeleted(false)
    } else if (findTotalElements.length > 1)
      setCanBeDeleted(true)
  }

  useEffect(() => {
    checkTotalElements()
    // eslint-disable-next-line
  }, [property.id, joinProps])

  return (
    <FormGroup>
      <Row className='mb-2'>
        {propertyQualifiers && propertyQualifiers.map((qualifier) => {
          return <Col xs={3} key={property.id + qualifier.id} className='mt-n2 float-sm-right'>
            <PropertyQualifier property={property} qualifier={qualifier}
                               value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []}
                               setQualifierValue={handleChangeQualifier}/>
          </Col>
        })}
        {property.repeatable && property.editable &&
        <Col xs={propertyQualifiers && propertyQualifiers.length > 0 ? 2 : 12}>
          <Button
            color='primary'
            outline={true}
            size='sm'
            disabled={!canBeDeleted}
            className={'float-sm-right'}
            onClick={() => {
              checkTotalElements()
              deleteRepeatable({id: property.id, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-minus"/>
          </Button>
          <Button
            color='primary'
            outline={true}
            size='sm'
            className='ml-3 mr-1 float-sm-right'
            onClick={() => {
              setCanBeDeleted(true)
              createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-plus"/>
          </Button>
        </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <div className="custom-control custom-switch mb-2" dir="ltr">
            <Input
              type="checkbox"
              className="custom-control-input"
              id={id}
              checked={booleanValue}
              value={booleanValue}
              disabled={!property.editable}
              onClick={handleChangeBoolean}
            />
            <label
              className="custom-control-label"
              htmlFor={id}
            >
              <PropertyTitle property={property}/>
            </label>
          </div>
        </Col>
      </Row>
    </FormGroup>
  );
};

const TextProp = ({property, value, handleChangeArray, qualifiers, createRepeatable, deleteRepeatable, joinProps}) => {
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [textValue, setTextValue] = useState('')
  const [qualifierValue, setQualifierValue] = useState('')
  const [propertyQualifiers, setPropertyQualifiers] = useState([])
  const {mandatoryNotice} = useContext(MandatoryContext)
  useEffect(() => {
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value)
      let qualifiersToBeShown = []
      setTextValue(initialValues.values)
      let initialQualifiers = []
      qualifiers && qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      if (initialValues.qualifiers && initialValues.qualifiers.length > 0) {
        initialValues.qualifiers.forEach((qualifier) => {
          initialQualifiers[qualifier.qualifierKey] = {
            qualifierKey: qualifier.qualifierKey,
            qualifierValue: qualifier.qualifierValue
          }
        })
        setQualifierValue(initialQualifiers)
      }
      setPropertyQualifiers(qualifiersToBeShown)
    }
    if (qualifiers) {
      let qualifiersToBeShown = []
      qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      setPropertyQualifiers(qualifiersToBeShown)
    }
    // eslint-disable-next-line
  }, [property.id])


  const handleTextChange = (e) => {
    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : []
    const textPayload = JSON.stringify({values: e.target.value, qualifiers: qualifyValues})
    handleChangeArray([property.id], textPayload)
    setTextValue(e.target.value)
  }
  useEffect(() => {
    if (qualifierValue) {
      const qualifyValues = Object.values(qualifierValue)
      const textPayload = JSON.stringify({values: textValue, qualifiers: qualifyValues})
      handleChangeArray([property.id], textPayload)
    }
// eslint-disable-next-line
  }, [qualifierValue])

  const handleChangeQualifier = (qualifier) => {
    setQualifierValue({...qualifierValue, [qualifier.qualifierKey]: qualifier})
  }
  const checkTotalElements = () => {
    const findTotalElements = joinProps.reduce((r, n, i) => {
      n.propertyKey === property.propertyKey && r.push(i);
      return r;
    }, [])
    if (findTotalElements.length === 1) {
      setCanBeDeleted(false)
    } else if (findTotalElements.length > 1)
      setCanBeDeleted(true)
  }

  useEffect(() => {
    checkTotalElements()
    // eslint-disable-next-line
  }, [property.id, joinProps])

  return <FormGroup>
    <Row className='mb-2'>
      <Col xs={4}>
        <Label>
          <PropertyTitle property={property}/>
        </Label>
      </Col>
      {propertyQualifiers && propertyQualifiers.map((qualifier) => {
        return <Col xs={3} key={property.id + qualifier.id} className='mt-n2 float-sm-right'>
          <PropertyQualifier property={property} qualifier={qualifier}
                             value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []}
                             setQualifierValue={handleChangeQualifier}/>
        </Col>
      })}
      {property.repeatable && property.editable &&
      <Col xs={propertyQualifiers && propertyQualifiers.length > 0 ? 2 : 8}>
        <Button
          color='primary'
          outline={true}
          size='sm'
          disabled={!canBeDeleted}
          className={'float-sm-right'}
          onClick={() => {
            checkTotalElements()
            deleteRepeatable({id: property.id, mandatory: property.mandatory})
          }
          }
        >
          <i className="bx bx-minus"/>
        </Button>
        <Button
          color='primary'
          outline={true}
          size='sm'
          className='ml-3 mr-1 float-sm-right'
          onClick={() => {
            setCanBeDeleted(true)
            createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
          }
          }
        >
          <i className="bx bx-plus"/>
        </Button>
      </Col>
      }
    </Row>
    <Row>
      <Col xs={12}>
        <Input
          type="text"
          className={`form-control ${mandatoryNotice && property.mandatory && textValue.length === 0 ? 'required-field' : ''}`}
          value={textValue}
          onChange={handleTextChange}
          // valid={property.mandatory ? textValue.length === 0 :  false}
        />
      </Col>
    </Row>
  </FormGroup>
}

const UrlProp = ({property, value, handleChangeArray, createRepeatable}) => {
  const [urlValue, setUrlValue] = useState('')
  const {mandatoryNotice} = useContext(MandatoryContext)
  useEffect(() => {
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value)
      setUrlValue(initialValues.values)
    }
    // eslint-disable-next-line
  }, [property.id])


  const handleUrlChange = (e) => {
    const urlPayload = JSON.stringify({values: e.target.value, qualifiers: []})
    setUrlValue(e.target.value)
    handleChangeArray([property.id], urlPayload)
  }
  return (
    <FormGroup>
      <Label>
        <PropertyTitle property={property}/>
      </Label>
      {property.repeatable && property.editable &&
      <Button
        color='primary'
        outline={true}
        size='sm'
        className='ml-3 mr-1'
        onClick={() =>
          createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
        }
      >
        <i className="bx bx-plus"/>
      </Button>
      }
      <Input
        type="text"
        className={`form-control ${mandatoryNotice && property.mandatory && urlValue.length === 0 ? 'required-field' : ''}`}
        value={urlValue}
        onChange={handleUrlChange}
      />
    </FormGroup>
  );
}

const TextAreaProp = ({
                        property,
                        value,
                        handleChangeArray,
                        createRepeatable,
                        deleteRepeatable,
                        joinProps,
                        qualifiers
                      }) => {
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [textValue, setTextValue] = useState('')
  const [qualifierValue, setQualifierValue] = useState([])
  const [propertyQualifiers, setPropertyQualifiers] = useState([])
  const {mandatoryNotice} = useContext(MandatoryContext)

  useEffect(() => {
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value)
      setTextValue(initialValues.values)
      let qualifiersToBeShown = []
      let initialQualifiers = []
      qualifiers && qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      if (initialValues.qualifiers && initialValues.qualifiers.length > 0) {
        initialValues.qualifiers.forEach((qualifier) => {
          initialQualifiers[qualifier.qualifierKey] = {
            qualifierKey: qualifier.qualifierKey,
            qualifierValue: qualifier.qualifierValue
          }
        })
        setQualifierValue(initialQualifiers)
      } else
        setQualifierValue(null)
      setPropertyQualifiers(qualifiersToBeShown)
    }

    if (qualifiers) {
      let qualifiersToBeShown = []
      qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      setPropertyQualifiers(qualifiersToBeShown)
    }
    // eslint-disable-next-line
  }, [property.id])


  const checkTotalElements = () => {
    const findTotalElements = joinProps.reduce((r, n, i) => {
      n.propertyKey === property.propertyKey && r.push(i);
      return r;
    }, [])
    if (findTotalElements.length === 1) {
      setCanBeDeleted(false)
    } else if (findTotalElements.length > 1)
      setCanBeDeleted(true)
  }

  const handleTextChange = (e) => {
    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : []
    const textAreaPayload = JSON.stringify({values: e.target.value, qualifiers: qualifyValues})
    handleChangeArray([property.id], textAreaPayload)
    setTextValue(e.target.value)
  }
  useEffect(() => {
    let textAreaPayload = ''
    let qualifyValues = ''
    if (qualifierValue) {
      qualifyValues = Object.values(qualifierValue)
      textAreaPayload = JSON.stringify({values: textValue, qualifiers: qualifyValues})
    } else
      textAreaPayload = JSON.stringify({values: textValue, qualifiers: qualifyValues})
    handleChangeArray([property.id], textAreaPayload)
    // eslint-disable-next-line
  }, [qualifierValue])

  const handleChangeQualifier = (qualifier) => {
    setQualifierValue({...qualifierValue, [qualifier.qualifierKey]: qualifier})
  }

  useEffect(() => {
    checkTotalElements()
    // eslint-disable-next-line
  }, [property.id, joinProps])

  return (
    <FormGroup>
      <Row className='mb-2'>
        <Col xs={4}>
          <Label>
            <PropertyTitle property={property}/>
          </Label>
        </Col>
        {propertyQualifiers && propertyQualifiers.map((qualifier) => {
          return <Col xs={3} key={property.id + qualifier.id} className='mt-n2 float-sm-right'>
            <PropertyQualifier property={property} qualifier={qualifier}
                               value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []}
                               setQualifierValue={handleChangeQualifier}/>
          </Col>
        })}
        {property.repeatable && property.editable &&
        <Col xs={propertyQualifiers && propertyQualifiers.length > 0 ? 2 : 8}>
          <Button
            color='primary'
            outline={true}
            size='sm'
            disabled={!canBeDeleted}
            className={'float-sm-right'}
            onClick={() => {
              checkTotalElements()
              deleteRepeatable({id: property.id, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-minus"/>
          </Button>
          <Button
            color='primary'
            outline={true}
            size='sm'
            className='ml-3 mr-1 float-sm-right'
            onClick={() => {
              setCanBeDeleted(true)
              createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-plus"/>
          </Button>
        </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <Input
            type="textarea"
            className={`form-control ${mandatoryNotice && property.mandatory && textValue.length === 0 ? 'required-field' : ''}`}
            value={textValue}
            onChange={handleTextChange}
          />
        </Col>
      </Row>
    </FormGroup>
  );
}

const EnumProp = ({
                    property,
                    value,
                    handleChange,
                    handleChangeArray,
                    createRepeatable,
                    deleteRepeatable,
                    joinProps,
                    qualifiers
                  }) => {

  const [selectedEnum, setSelectedEnum] = useState([])
  const [options, setOptions] = useState([])
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [qualifierValue, setQualifierValue] = useState('')
  const [propertyQualifiers, setPropertyQualifiers] = useState([])
  const {mandatoryNotice} = useContext(MandatoryContext)

  const checkTotalElements = () => {
    const findTotalElements = joinProps.reduce((r, n, i) => {
      n.propertyKey === property.propertyKey && r.push(i);
      return r;
    }, [])
    if (findTotalElements.length === 1) {
      setCanBeDeleted(false)
    } else if (findTotalElements.length > 1)
      setCanBeDeleted(true)
  }

  useEffect(() => {
    checkTotalElements()
    // eslint-disable-next-line
  }, [property.id, joinProps])

  useEffect(() => {
    let initialValues;
    let optionsValues;

    if (property.propertyDetails && property.propertyDetails.length > 0) {
      initialValues = JSON.parse(property.propertyDetails);
      optionsValues = initialValues.values.map((enumValue) => ({
        label: enumValue,
        value: enumValue,
      }));
      setOptions(optionsValues)
    }

    if (value && value.length > 0) {
      const initialValues = JSON.parse(value)
      let qualifiersToBeShown = []
      setSelectedEnum(optionsValues.find((x) => x.value === initialValues.values));
      let initialQualifiers = []
      qualifiers && qualifiers.forEach((qualifier) => {

        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      if (initialValues.qualifiers && initialValues.qualifiers.length > 0) {
        initialValues.qualifiers.forEach((qualifier) => {
          initialQualifiers[qualifier.qualifierKey] = {
            qualifierKey: qualifier.qualifierKey,
            qualifierValue: qualifier.qualifierValue
          }
        })
        setQualifierValue(initialQualifiers)
      }
      setPropertyQualifiers(qualifiersToBeShown)
    }
    if (qualifiers) {
      let qualifiersToBeShown = []
      qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      setPropertyQualifiers(qualifiersToBeShown)
    }
    // eslint-disable-next-line
  }, [property.id])

  useEffect(() => {
    if (qualifierValue && selectedEnum) {
      const qualifyValues = Object.values(qualifierValue)
      // const enumPayload = JSON.stringify({values: selectedEnum && selectedEnum.value ? selectedEnum.value:'', qualifiers: qualifyValues})
      const enumPayload = JSON.stringify({values: selectedEnum.value, qualifiers: qualifyValues})
      handleChangeArray([property.id], enumPayload)
    }
    // eslint-disable-next-line
  }, [qualifierValue])

  const handleChangeQualifier = (qualifier) => {
    setQualifierValue({...qualifierValue, [qualifier.qualifierKey]: qualifier})
  }

  const handleChangeEnum = (option) => {
    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : []
    const enumPayload = JSON.stringify({ values: option.value, qualifiers: qualifyValues })
    setSelectedEnum(option)
    handleChangeArray([property.id], enumPayload)
  }

  const customSelectStyle = {
    menu: (provided) => ({
      ...provided,
      minHeight: '180px',
      maxHeight: '220px',
      height: 'auto'
    }),
    menuList: (provided) => ({
      ...provided,
      minHeight: '160px',
      maxHeight: '180px',
      height: 'auto'
    }),
  }
  const handleReset = () => {
    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : [];
    const enumPayload = JSON.stringify({values: null, qualifiers: qualifyValues});
    setSelectedEnum({label: null, value: null});
    handleChangeArray([property.id], enumPayload);
  }
  return (
    <FormGroup>
      <Row className="mb-2">
        <Col xs={4}>
          <Label>
            <PropertyTitle property={property} />
          </Label>
        </Col>
        {propertyQualifiers &&
          propertyQualifiers.map((qualifier) => {
            return (
              <Col xs={3} key={property.id + qualifier.id} className="mt-n2 float-sm-right">
                <PropertyQualifier property={property} qualifier={qualifier} value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []} setQualifierValue={handleChangeQualifier} />
              </Col>
            );
          })}
        {property.repeatable && property.editable && (
          <Col xs={propertyQualifiers && propertyQualifiers.length > 0 ? 2 : 8}>
            <Button
              color="primary"
              outline={true}
              size="sm"
              disabled={!canBeDeleted}
              className={"float-sm-right"}
              onClick={() => {
                checkTotalElements();
                deleteRepeatable({id: property.id, mandatory: property.mandatory});
              }}
            >
              <i className="bx bx-minus" />
            </Button>
            <Button
              color="primary"
              outline={true}
              size="sm"
              className="ml-3 mr-1 float-sm-right"
              onClick={() => {
                setCanBeDeleted(true);
                createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory});
              }}
            >
              <i className="bx bx-plus" />
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <Select
            isDisabled={!property.editable}
            className={`${mandatoryNotice && property.mandatory && selectedEnum.length === 0 ? "required-field" : ""}`}
            options={options}
            value={selectedEnum}
            styles={customSelectStyle}
            onChange={handleChangeEnum}
          />
          <i style={{top: "5px", background: "#fff",padding: "5px", color: "#b3b4b5", right: "50px", cursor: "pointer",  position: "absolute"}} className="bx bx-x font-size-17 align-middle" name="resetSelection" onClick={handleReset} id="clearenum"></i>          
        </Col>
      </Row>
    </FormGroup>
  );
}

const RefProp = ({property, value, createRepeatable, handleChangeArray, deleteRepeatable, joinProps, qualifiers}) => {
  const [options, setOptions] = useState([])
  const [selectedRef, setSelectedRef] = useState([])
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [qualifierValue, setQualifierValue] = useState('')
  const [propertyQualifiers, setPropertyQualifiers] = useState([])
  const {mandatoryNotice} = useContext(MandatoryContext)

  const checkTotalElements = () => {
    const findTotalElements = joinProps.reduce((r, n, i) => {
      n.propertyKey === property.propertyKey && r.push(i);
      return r;
    }, [])
    if (findTotalElements.length === 1) {
      setCanBeDeleted(false)
    } else if (findTotalElements.length > 1)
      setCanBeDeleted(true)
  }

  useEffect(() => {
    checkTotalElements()
    // eslint-disable-next-line
  }, [property.id, joinProps])

  useEffect(() => {
    let refOptions = ''
    if (property.propertyDetails && property.propertyDetails.length > 0) {
      const initialValues = JSON.parse(property.propertyDetails)
      const url = ApiEndpoint[EntityType.Container] + '/search';
      const payload = {containerTypes: initialValues.containerTypes}
      try {
        axios.post(url, payload, getAxiosDefaultConfig())
          .then((res) => {
            const result = res.data
            refOptions = result.map((refValue) => ({
              label: refValue.label,
              value: refValue.uuid,
            }));
            setOptions(refOptions)

          })
          .catch(handleError);
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line
  }, [property.id])

  useEffect(() => {
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value)
      if (options && options.length > 0) {
        const preSelectedRef = options.find((x) => x.value === initialValues.values.value);
        setSelectedRef(preSelectedRef)
      }

      let qualifiersToBeShown = []
      let initialQualifiers = []
      qualifiers && qualifiers.forEach((qualifier) => {

        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      if (initialValues.qualifiers && initialValues.qualifiers.length > 0) {
        initialValues.qualifiers.forEach((qualifier) => {
          initialQualifiers[qualifier.qualifierKey] = {
            qualifierKey: qualifier.qualifierKey,
            qualifierValue: qualifier.qualifierValue
          }
        })
        setQualifierValue(initialQualifiers)
      }
      setPropertyQualifiers(qualifiersToBeShown)
    }
    if (qualifiers) {
      let qualifiersToBeShown = []
      qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      setPropertyQualifiers(qualifiersToBeShown)
    }
    // eslint-disable-next-line
  }, [options])

  useEffect(() => {

    if (qualifierValue && selectedRef) {
      const qualifyValues = Object.values(qualifierValue)
      const refPropPayload = JSON.stringify({values: selectedRef, qualifiers: qualifyValues})
      handleChangeArray([property.id], refPropPayload)
    }
// eslint-disable-next-line
  }, [qualifierValue])

  const handleRefSelect = (selectedReference) => {
    setSelectedRef(selectedReference)
    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : []
    const refPropPayload = JSON.stringify({values: selectedReference, qualifiers: qualifyValues});
    handleChangeArray([property.id], refPropPayload);
  };
  const handleChangeQualifier = (qualifier) => {
    setQualifierValue({...qualifierValue, [qualifier.qualifierKey]: qualifier})
  }
  return (
    <FormGroup>
      <Row className='mb-2'>
        <Col xs={4}>
          <Label>
            <PropertyTitle property={property}/>
          </Label>
        </Col>
        {propertyQualifiers && propertyQualifiers.map((qualifier) => {
          return <Col xs={3} key={property.id + qualifier.id} className='mt-n2 float-sm-right'>
            <PropertyQualifier property={property} qualifier={qualifier}
                               value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []}
                               setQualifierValue={handleChangeQualifier}/>
          </Col>
        })}
        {property.repeatable && property.editable &&
        <Col xs={propertyQualifiers && propertyQualifiers.length > 0 ? 2 : 8}>
          <Button
            color='primary'
            outline={true}
            size='sm'
            disabled={!canBeDeleted}
            className={'float-sm-right'}
            onClick={() => {
              checkTotalElements()
              deleteRepeatable({id: property.id, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-minus"/>
          </Button>
          <Button
            color='primary'
            outline={true}
            size='sm'
            className='ml-3 mr-1 float-sm-right'
            onClick={() => {
              setCanBeDeleted(true)
              createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-plus"/>
          </Button>
        </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <Select
            isDisabled={!property.editable}
            className={`${mandatoryNotice && property.mandatory && selectedRef.length === 0 ? 'required-field' : ''}`}
            options={options}
            value={selectedRef}
            onChange={handleRefSelect}
          />
        </Col>
      </Row>
    </FormGroup>
  );
}
const VocabularyFlat = (props)=>{
  // eslint-disable-next-line
  const {property,handleChangeArray,value,joinProps} = props
  // eslint-disable-next-line
  const {propertyDetails,repeatable,editable,mandatory} = property
  const {vocabularyId} = JSON.parse(propertyDetails)
  const [options,setOptions] = useState([])
  const [terms,setTerms] = useState([])
  const [selectedTerms,setSelectedTerms] = useState([])
  const {mandatoryNotice} = useContext(MandatoryContext)
  const [valueAutocomplete, setValueAutoComplete] = useState([]);
  // const [multiple, setMultiple] = useState();
  // const [canBeDeleted, setCanBeDeleted] = useState(false);
  //
  // const [qualifierValue, setQualifierValue] = useState([])
  // const [propertyQualifiers, setPropertyQualifiers] = useState([])
  const searchTimeout = useRef()
  //
  // const checkTotalElements = () => {
  //   const findTotalElements = joinProps.reduce((r, n, i) => {
  //     n.propertyKey === property.propertyKey && r.push(i);
  //     return r;
  //   }, [])
  //   if (findTotalElements.length === 1) {
  //     setCanBeDeleted(false)
  //   } else if (findTotalElements.length > 1)
  //     setCanBeDeleted(true)
  // }

  const getAllTerms = () =>{
    axios.get(`${API_URL}/vocabularies/${vocabularyId}/terms`, getAxiosDefaultConfig())
      .then((response) => {
        setTerms(response.data.content)
      })
      .catch((e) => {
        toastr.error(e)
      });
  }

  const searchTerm = (searchTerm) =>{
    clearTimeout(searchTimeout.current)
    if (searchTerm.length > 2) {
      searchTimeout.current = setTimeout(() => {
        axios.get(`${API_URL}/vocabularies/${vocabularyId}/terms?q=${searchTerm}`, getAxiosDefaultConfig()).then((response) => {
          const options = response.data.content.map((item)=>{
            return {label: item.label,id:item.termId}
          })
          setOptions(options)
        }).catch((e)=>{
          toastr.error(e)
        })

      }, 600)
    } else if (searchTerm === '') {
      axios.get(`${API_URL}/vocabularies/${vocabularyId}/terms?q=`, getAxiosDefaultConfig()).then((response) => {
        const options = response.data.content.map((item)=>{
          return {label: item.label,id:item.termId}
        })
        setOptions(options)
      }).catch((e)=>{
        toastr.error(e)
      })
    }
  }

  useEffect(()=>{
    getAllTerms()
    if(value) {
      const {values} = JSON.parse(value)
      setSelectedTerms(values)
      setValueAutoComplete(values)
    }
    // eslint-disable-next-line
  },[property.id])
  //
  // useEffect(()=>{
  //   checkTotalElements()
  //   // eslint-disable-next-line
  // },[property.id,joinProps])

  const addTerm = (newValue) =>{
    setSelectedTerms(newValue)
    setValueAutoComplete(newValue)
  }

  useEffect(()=>{
    // console.log(property,selectedTerms)
    const propValues = JSON.parse(propertyDetails)
    const payload = JSON.stringify({...propValues,values:selectedTerms})
    handleChangeArray([property.id],payload)

// eslint-disable-next-line
  },[selectedTerms])

  useEffect(()=>{
    if(terms.length>0) {
      // if (values) {
      //   const selectedUuid = values
      //   if (selectedUuid) {
      //     console.log(selectedUuid)
      //     const foundVoc = terms.find((v) => v.termId === selectedUuid)
      //     // setSelectedVoc(foundVoc)
      //   }}

      const optionsList = []
      terms.forEach((v) => {
        optionsList.push({id: v.termId, label: v.label})
      })
      setOptions(optionsList)
    }
  },[terms])

  return   <FormGroup>
    <Row className='mb-2'>
      <Col xs={4}>
        <PropertyTitle property={property}/>
      </Col>
      {/*{propertyQualifiers && propertyQualifiers.map((qualifier) => {*/}
      {/*  return <Col xs={3} key={property.id + qualifier.id} className='mt-n2 float-sm-right'>*/}
      {/*    <PropertyQualifier property={property} qualifier={qualifier}*/}
      {/*                       value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []}*/}
      {/*                       setQualifierValue={handleChangeQualifier}/>*/}
      {/*  </Col>*/}
      {/*})}*/}
      {repeatable && editable &&
      <Col xs={2}>
        <Button
          color='primary'
          outline={true}
          size='sm'
          // disabled={!canBeDeleted}
          className={'float-sm-right'}
          // onClick={() => {
          //   checkTotalElements()
          //   deleteRepeatable({id: property.id, mandatory: property.mandatory})
          // }
          // }
        >
          <i className="bx bx-minus"/>
        </Button>
        <Button
          color='primary'
          outline={true}
          size='sm'
          className='ml-3 mr-1 float-sm-right'
          // onClick={() => {
          //   setCanBeDeleted(true)
          //   createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
          // }
          // }
        >
          <i className="bx bx-plus"/>
        </Button>
      </Col>
      }
    </Row>
    <Row>
      <Col xs={12}>
        <Autocomplete
          multiple
          className={`${mandatoryNotice && property.mandatory && selectedTerms.length === 0 ? 'required-field' : ''}`}
          id="size-small-filled-multi"
          size="small"
          options={[...options].sort((a,b)=>a.label>b.label ? 1 : -1,)}
          // disabled={loading}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={option => option?.label}
          filterOptions={(options) => options}
          value={valueAutocomplete}
          onInput={(event)=>searchTerm(event.target.value)}
          onChange={(event, newValue) => addTerm(newValue)}
          renderInput={params => {
            return (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            );
          }}
        />
      </Col>
    </Row>
  </FormGroup>
}

const MultiProp = ({
                     property,
                     value,
                     handleChangeArray,
                     createRepeatable,
                     deleteRepeatable,
                     joinProps,
                     qualifiers,
                     open
                   }) => {
  const [options, setOptions] = useState([]);
  const [multiple, setMultiple] = useState();
  const [canBeDeleted, setCanBeDeleted] = useState(false);

  const [qualifierValue, setQualifierValue] = useState([])
  const [propertyQualifiers, setPropertyQualifiers] = useState([])
  const {mandatoryNotice} = useContext(MandatoryContext)

  const checkTotalElements = () => {
    const findTotalElements = joinProps.reduce((r, n, i) => {
      n.propertyKey === property.propertyKey && r.push(i);
      return r;
    }, [])
    if (findTotalElements.length === 1) {
      setCanBeDeleted(false)
    } else if (findTotalElements.length > 1)
      setCanBeDeleted(true)
  }

  useEffect(() => {
    checkTotalElements()
    // eslint-disable-next-line
  }, [property.id, joinProps])

  useEffect(() => {

    if (property.propertyDetails && property.propertyDetails.length > 0) {
      const initialValues = JSON.parse(property.propertyDetails);
      const optionValues = initialValues.values.map((propValue) => ({
        label: propValue,
        value: propValue,
      }));
      setOptions(optionValues);
    }
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value);
      const optionValues =
        initialValues.values &&
        initialValues.values.map((propValue) => ({
          label: propValue,
          value: propValue,
        }));

      optionValues && optionValues.length > 0 && setMultiple(optionValues);
      const qualifiersToBeShown = []
      const initialQualifiers = []
      qualifiers && qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      if (initialValues.qualifiers && initialValues.qualifiers.length > 0) {
        initialValues.qualifiers.forEach((qualifier) => {
          initialQualifiers[qualifier.qualifierKey] = {
            qualifierKey: qualifier.qualifierKey,
            qualifierValue: qualifier.qualifierValue
          }
        })
        setQualifierValue(initialQualifiers)
      } else
        setQualifierValue(null)
      setPropertyQualifiers(qualifiersToBeShown)
    }
    if (qualifiers) {
      let qualifiersToBeShown = []
      qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      setPropertyQualifiers(qualifiersToBeShown)
    }
    // eslint-disable-next-line
  }, [property.id]);

  useEffect(() => {

    if (qualifierValue && multiple) {
      const selectedValues =
        multiple &&
        multiple.map((option) => {
          return option.value;
        });
      const qualifyValues = Object.values(qualifierValue)
      const multiPropPayload = JSON.stringify({values: selectedValues, qualifiers: qualifyValues});
      handleChangeArray([property.id], multiPropPayload)
    } else if (qualifierValue && !multiple) {
      if (value) {
        const previousValues = JSON.parse(value)
        const qualifyValues = Object.values(previousValues.qualifiers)
        const multiPropPayload = JSON.stringify({values: previousValues.values, qualifiers: qualifyValues});
        handleChangeArray([property.id], multiPropPayload)
      }
    }
    // eslint-disable-next-line
  }, [qualifierValue])

  useEffect(() => {
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value);
      const optionValues =
        initialValues?.values &&
        initialValues?.values.map((propValue) => ({
          label: propValue,
          value: propValue,
        }));
      optionValues && optionValues.length > 0 && setMultiple(optionValues);
      if (initialValues.values === null) setMultiple([]);
      else if (initialValues.values && initialValues.values.length === 0) setMultiple([]);      
    //   optionValues && optionValues.length > 0 && setMultiple(optionValues);
    //   if (initialValues.values === null) setMultiple([]);
    //   else if (initialValues.values && initialValues.values.length === 0) setMultiple([]);
    // } else
    // {
    //   const initialValues = JSON.parse(value);
    //   const optionValues ={
    //       label: initialValues?.values,
    //       value: initialValues?.values,
    //     };
    //   optionValues && optionValues.length > 0 && setMultiple(optionValues);
    //   if (initialValues.values === null) setMultiple([]);
    //   else if (initialValues.values && initialValues.values.length === 0) setMultiple([]);      

    }
// eslint-disable-next-line
  }, [value])

  const handleMultiSelect = (selectedOptions) => {

    const selectedValues =
      selectedOptions &&
      selectedOptions.map((option) => {
        return option.value;
      });

    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : []
    const multiPropPayload = JSON.stringify({values: selectedValues, qualifiers: qualifyValues});

    handleChangeArray([property.id], multiPropPayload);
  };

  const handleChangeQualifier = (qualifier) => {
    setQualifierValue({...qualifierValue, [qualifier.qualifierKey]: qualifier})
  }

  return (
    <FormGroup>
      <Row className='mb-2'>
        <Col xs={4}>
          <PropertyTitle property={property}/>
        </Col>
        {propertyQualifiers && propertyQualifiers.map((qualifier) => {
          return <Col xs={3} key={property.id + qualifier.id} className='mt-n2 float-sm-right'>
            <PropertyQualifier property={property} qualifier={qualifier}
                               value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []}
                               setQualifierValue={handleChangeQualifier}/>
          </Col>
        })}
        {property.repeatable && property.editable &&
        <Col xs={2}>
          <Button
            color='primary'
            outline={true}
            size='sm'
            disabled={!canBeDeleted}
            className={'float-sm-right'}
            onClick={() => {
              checkTotalElements()
              deleteRepeatable({id: property.id, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-minus"/>
          </Button>
          <Button
            color='primary'
            outline={true}
            size='sm'
            className='ml-3 mr-1 float-sm-right'
            onClick={() => {
              setCanBeDeleted(true)
              createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-plus"/>
          </Button>
        </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <Select
            value={multiple}
            options={options}
            className={`${mandatoryNotice && property.mandatory && multiple.length === 0 ? 'required-field' : ''}`}
            onChange={handleMultiSelect}
            isMulti
            isDisabled={!property.editable}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

const MapProp = ({property, value, handleChangeArray, createRepeatable}) => {
  const [mapPlaceName, setMapPlaceName] = useState();
  const [mapLong, setMapLong] = useState();
  let [mapLat, setMapLat] = useState();
  const {mandatoryNotice} = useContext(MandatoryContext)

  useEffect(() => {
    if (value && value.length > 0) {
      const mapValues = JSON.parse(value);
      setMapPlaceName(mapValues.values.placename);
      setMapLong(mapValues.values.longitude);
      setMapLat(mapValues.values.langitude);
    } else if (
      property.propertyDetails &&
      property.propertyDetails.length > 0
    ) {
      const mapValues = JSON.parse(property.propertyDetails);
      setMapPlaceName(mapValues.values.placename);
      setMapLong(mapValues.values.longitude);
      setMapLat(mapValues.values.langitude);
    }
  }, [property.propertyDetails, value]);

  const handleSelectMap = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({lat, lng}) => {
        setMapLat(lat);
        setMapLong(lng);
        const spatialMapPayload = {
          values: {placename: address, langitude: lat, longitude: lng},
        };
        handleChangeArray([property.id], JSON.stringify(spatialMapPayload));
      })
      .catch((error) => console.error(error));
  };
const onMarkerDragEnd = (event) => {
  let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
  Geocode.setApiKey("AIzaSyDcahpw6UyfMizUlzx7lgv8oEB--FQ38IY");
  Geocode.setLocationType("ROOFTOP");
  Geocode.fromLatLng(newLat, newLng).then(
   (response) => {
     const address = response.results[0].formatted_address;
      setMapPlaceName(address)
              const spatialMapPayload = {
                values: {placename: address, langitude: newLat, longitude: newLng},
              };
              handleChangeArray([property.id], JSON.stringify(spatialMapPayload));
   },
   (error) => {
     console.log(error);
      Geocode.setLocationType("APPROXIMATE");
   
      Geocode.fromLatLng(newLat, newLng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setMapPlaceName(address);
          const spatialMapPayload = {
            values: {placename: address, langitude: newLat, longitude: newLng},
          };
          handleChangeArray([property.id], JSON.stringify(spatialMapPayload));
        },
        (error) => {
          console.log(error);
        },
      );


   },
 ); 
  setMapLong(newLng);
  setMapLat(newLat);
};
  

  return (
    <Row key={property.id ? property.id : makeid()}>
      <Col lg={12}>
        <FormGroup>
          <Label>
            <PropertyTitle property={property} />
          </Label>
          {property.repeatable && property.editable && (
            <Button color="primary" outline={true} size="sm" className="ml-3 mr-1" onClick={() => createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})}>
              <i className="bx bx-plus" />
            </Button>
          )}
          <GooglePlacesAutocomplete
            initialValue={mapPlaceName}
            onSelect={({description}) => {
              handleSelectMap(description);
            }}
            inputClassName="form-control"
            disabled={!property.editable}
          />
          <FormGroup>
            <Row>
              <Col lg="6">
                <Label>Longitude</Label>
                <Input
                  type="text"
                  placeholder=""
                  className={`form-control ${mandatoryNotice && property.mandatory && mapLong.length === 0 ? "required-field" : ""}`}
                  value={mapLong}
                  onChange={(e) => setMapLong(e.target.value)}
                  disabled={!property.editable}
                />
              </Col>
              <Col lg="6">
                <Label>Latitude</Label>
                <Input
                  type="text"
                  placeholder=""
                  className={`form-control ${mandatoryNotice && property.mandatory && mapLat.length === 0 ? "required-field" : ""}`}
                  value={mapLat}
                  onChange={(e) => setMapLat(e.target.value)}
                  disabled={!property.editable}
                />
              </Col>
            </Row>
          </FormGroup>
        </FormGroup>
      </Col>
      <Col lg={12} className={`mb-2`}>
        {mapPlaceName && mapLat && mapLong && (
          <MapWithAMarker
            defaultCenter={{lat: mapLat, lng: mapLong}}
            containerElement={<div style={{height: `400px`}} />}
            mapElement={<div style={{height: `100%`}} />}
            loadingElement={<div style={{height: `100%`}} />}
            latState={mapLat}
            lngState={mapLong}
            draggable={true}
            onMarkerDragEnd={onMarkerDragEnd}
            // onDragEnd={onDragMarker}
          />
        )}
      </Col>
    </Row>
  );
};

function NumberProp({property, value, handleChange, createRepeatable}) {
  const {mandatoryNotice} = useContext(MandatoryContext)
  return (
    <FormGroup>
      <Label>
        <PropertyTitle property={property}/>
      </Label>
      {property.repeatable && property.editable &&
      <Button
        color='primary'
        outline={true}
        size='sm'
        className='ml-3 mr-1'
        onClick={() =>
          createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
        }
      >
        <i className="bx bx-plus"/>
      </Button>
      }
      <Input
        type="number"
        className={`form-control ${mandatoryNotice && property.mandatory && value.length === 0 ? 'required-field' : ''}`}
        value={value}
        onChange={handleChange([property.id])}
        disabled={!property.editable}
      />
    </FormGroup>
  );
}

const DateProp = ({property, value, handleChangeArray, qualifiers, createRepeatable, deleteRepeatable, joinProps}) => {
  const id = new Date().toISOString();
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [dateValue, setDateValue] = useState('')
  const [qualifierValue, setQualifierValue] = useState('')
  const [propertyQualifiers, setPropertyQualifiers] = useState([])
  const {mandatoryNotice} = useContext(MandatoryContext)

  useEffect(() => {
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value)
      setDateValue(initialValues.values)

      let qualifiersToBeShown = []

      let initialQualifiers = []
      qualifiers && qualifiers.forEach((qualifier) => {

        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      if (initialValues.qualifiers && initialValues.qualifiers.length > 0) {
        initialValues.qualifiers.forEach((qualifier) => {
          initialQualifiers[qualifier.qualifierKey] = {
            qualifierKey: qualifier.qualifierKey,
            qualifierValue: qualifier.qualifierValue
          }
        })
        setQualifierValue(initialQualifiers)
      }
      setPropertyQualifiers(qualifiersToBeShown)
    }
    if (qualifiers) {
      let qualifiersToBeShown = []
      qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
    }
    // eslint-disable-next-line
  }, [property.id])

  const handleChangeDate = (e) => {
    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : []
    const datePayload = JSON.stringify({values: e.target.value, qualifiers: qualifyValues})
    handleChangeArray([property.id], datePayload)
    setDateValue(e.target.value)
  }

  useEffect(() => {
    if (qualifierValue) {
      const qualifyValues = Object.values(qualifierValue)
      const datePayload = JSON.stringify({values: dateValue, qualifiers: qualifyValues})
      handleChangeArray([property.id], datePayload)
    }
// eslint-disable-next-line
  }, [qualifierValue])

  const handleChangeQualifier = (qualifier) => {
    setQualifierValue({...qualifierValue, [qualifier.qualifierKey]: qualifier})
  }
  const checkTotalElements = () => {
    const findTotalElements = joinProps.reduce((r, n, i) => {
      n.propertyKey === property.propertyKey && r.push(i);
      return r;
    }, [])
    if (findTotalElements.length === 1) {
      setCanBeDeleted(false)
    } else if (findTotalElements.length > 1)
      setCanBeDeleted(true)
  }

  useEffect(() => {
    checkTotalElements()
    // eslint-disable-next-line
  }, [property.id, joinProps])


  return <FormGroup>
    <Row className='mb-2'>
      <Col xs={4}>
        <Label>
          <PropertyTitle property={property}/>
        </Label>
      </Col>
      {propertyQualifiers && propertyQualifiers.map((qualifier) => {
        return <Col xs={3} key={property.id + qualifier.id} className='mt-n2 float-sm-right'>
          <PropertyQualifier property={property} qualifier={qualifier}
                             value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []}
                             setQualifierValue={handleChangeQualifier}/>
        </Col>
      })}
      {property.repeatable && property.editable &&
      <Col xs={propertyQualifiers && propertyQualifiers.length > 0 ? 2 : 8}>
        <Button
          color='primary'
          outline={true}
          size='sm'
          disabled={!canBeDeleted}
          className={'float-sm-right'}
          onClick={() => {
            checkTotalElements()
            deleteRepeatable({id: property.id, mandatory: property.mandatory})
          }
          }
        >
          <i className="bx bx-minus"/>
        </Button>
        <Button
          color='primary'
          outline={true}
          size='sm'
          className='ml-3 mr-1 float-sm-right'
          onClick={() => {
            setCanBeDeleted(true)
            createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
          }
          }
        >
          <i className="bx bx-plus"/>
        </Button>
      </Col>
      }
    </Row>
    <Row>
      <Col xs={4}>
        <Input
          className={`form-control ${mandatoryNotice && property.mandatory && dateValue.length === 0 ? 'required-field' : ''}`}
          type="date"
          value={dateValue}
          onChange={handleChangeDate}
          id={id}
          disabled={!property.editable}
        />
      </Col>
    </Row>
  </FormGroup>
}

const TimeProp = ({
                    property,
                    value,
                    handleChange,
                    handleChangeArray,
                    qualifiers,
                    createRepeatable,
                    deleteRepeatable,
                    joinProps
                  }) => {
  const id = new Date().toISOString();
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [timeValue, setTimeValue] = useState('')
  const [qualifierValue, setQualifierValue] = useState('')
  const [propertyQualifiers, setPropertyQualifiers] = useState([])
  const {mandatoryNotice} = useContext(MandatoryContext)

  useEffect(() => {
    if (value && value.length > 0) {
      const initialValues = JSON.parse(value)
      setTimeValue(initialValues.values)

      let qualifiersToBeShown = []

      let initialQualifiers = []
      qualifiers && qualifiers.forEach((qualifier) => {

        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      if (initialValues.qualifiers && initialValues.qualifiers.length > 0) {
        initialValues.qualifiers.forEach((qualifier) => {
          initialQualifiers[qualifier.qualifierKey] = {
            qualifierKey: qualifier.qualifierKey,
            qualifierValue: qualifier.qualifierValue
          }
        })
        setQualifierValue(initialQualifiers)
      }
      setPropertyQualifiers(qualifiersToBeShown)
    }

    if (qualifiers) {
      let qualifiersToBeShown = []
      qualifiers.forEach((qualifier) => {
        if (property.qualifiers.find((x) => x === qualifier.qualifierKey))
          qualifiersToBeShown.push(qualifier)
      })
      setPropertyQualifiers(qualifiersToBeShown)
    }
    // eslint-disable-next-line
  }, [property.id])

  const handleTimeChange = (e) => {
    const qualifyValues = qualifierValue ? Object.values(qualifierValue) : []
    const timePayload = JSON.stringify({values: e.target.value, qualifiers: qualifyValues})
    handleChangeArray([property.id], timePayload)
    setTimeValue(e.target.value)
  }

  useEffect(() => {
    if (qualifierValue) {
      const qualifyValues = Object.values(qualifierValue)
      const timePayload = JSON.stringify({values: timeValue, qualifiers: qualifyValues})
      handleChangeArray([property.id], timePayload)
    }
// eslint-disable-next-line
  }, [qualifierValue])

  const handleChangeQualifier = (qualifier) => {
    setQualifierValue({...qualifierValue, [qualifier.qualifierKey]: qualifier})
  }
  const checkTotalElements = () => {
    const findTotalElements = joinProps.reduce((r, n, i) => {
      n.propertyKey === property.propertyKey && r.push(i);
      return r;
    }, [])
    if (findTotalElements.length === 1) {
      setCanBeDeleted(false)
    } else if (findTotalElements.length > 1)
      setCanBeDeleted(true)
  }

  useEffect(() => {
    checkTotalElements()
    // eslint-disable-next-line
  }, [property.id, joinProps])

  return (
    <FormGroup>
      <Row className='mb-2'>
        <Col xs={4}>
          <Label>
            <PropertyTitle property={property}/>
          </Label>
        </Col>
        {propertyQualifiers && propertyQualifiers.map((qualifier) => {
          return <Col xs={3} key={property.id + qualifier.id} className='mt-n2 float-sm-right'>
            <PropertyQualifier property={property} qualifier={qualifier}
                               value={qualifierValue ? qualifierValue[qualifier.qualifierKey] : []}
                               setQualifierValue={handleChangeQualifier}/>
          </Col>
        })}
        {property.repeatable && property.editable &&
        <Col xs={propertyQualifiers && propertyQualifiers.length > 0 ? 2 : 8}>
          <Button
            color='primary'
            outline={true}
            size='sm'
            disabled={!canBeDeleted}
            className={'float-sm-right'}
            onClick={() => {
              checkTotalElements()
              deleteRepeatable({id: property.id, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-minus"/>
          </Button>
          <Button
            color='primary'
            outline={true}
            size='sm'
            className='ml-3 mr-1 float-sm-right'
            onClick={() => {
              setCanBeDeleted(true)
              createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
            }
            }
          >
            <i className="bx bx-plus"/>
          </Button>
        </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <Input
            className={`form-control ${mandatoryNotice && property.mandatory && timeValue.length === 0 ? 'required-field' : ''}`}
            type="time"
            value={timeValue}
            onChange={handleTimeChange}
            id={id}
            disabled={!property.editable}
          />
        </Col>
      </Row>
    </FormGroup>
  );
}

function IpV4Prop({property, value, handleChange, createRepeatable}) {
  const {mandatoryNotice} = useContext(MandatoryContext)
  return (
    <Row>
      <FormGroup className="col-lg-6">
        <Label>
          <PropertyTitle property={property}/>
        </Label>
        {property.repeatable && property.editable &&
        <Button
          color='primary'
          outline={true}
          size='sm'
          className='ml-3 mr-1'
          onClick={() =>
            createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
          }
        >
          <i className="bx bx-plus"/>
        </Button>
        }
        <InputMask
          mask="999.999.999.999"
          value={value}
          className={`form-control ${mandatoryNotice && property.mandatory && value.length === 0 ? 'required-field' : ''}`}
          onChange={handleChange([property.id])}
          disabled={!property.editable}
        >
          {(inputProps) => (
            <MaterialInput {...inputProps} type="tel" disableUnderline/>
          )}
        </InputMask>
        <span className="font-13 text-muted">192.168.110.310</span>
      </FormGroup>
    </Row>
  );
}

function RichTextProp({property, value, handleChangeArray, createRepeatable}) {
  const initHtml = value ? JSON.parse(value) : {values: '<p> </p>'}
  const initContentBlock = htmlToDraft(initHtml.values)
  const initContentState = ContentState.createFromBlockArray(initContentBlock.contentBlocks)
  const initEditorState = EditorState.createWithContent(initContentState)
  const [editorState, setEditorState] = useState(initEditorState);

  const editorChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleChangeEditor = (e) => {
    const editorPayload = {values: draftToHtml(e)}
    handleChangeArray([property.id], JSON.stringify(editorPayload))
  }

  return (
    <FormGroup>
      <Row>
        <Col xs={8}>
          <Label>
            <PropertyTitle property={property}/>
            {property.repeatable && property.editable &&
            <Button
              color='primary'
              outline={true}
              size='sm'
              className='ml-3 mr-1'
              onClick={() =>
                createRepeatable({id: makeid(), key: property.propertyKey, mandatory: property.mandatory})
              }
            >
              <i className="bx bx-plus"/>
            </Button>
            }
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={'d-flex'}>
            <div style={{flex: 1, zIndex: 0}} className={'mr-2'}>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                editorClassName="demo-editor"
                onEditorStateChange={(editorState) => editorChange(editorState)}
                onChange={handleChangeEditor}
                disabled={!property.editable}
              />
            </div>
          </div>
        </Col>
      </Row>
    </FormGroup>
  );
}

function getPropertyView(type) {
  switch (type) {
    case 'boolean':
      return BooleanProp;
    case 'url':
      return UrlProp;
    case 'enum':
      return EnumProp;
    case 'date':
      return DateProp;
    case 'time':
      return TimeProp;
    case 'ip-address-v4':
      return IpV4Prop;
    case 'number':
      return NumberProp;
    case 'text-area':
      return TextAreaProp;
    case 'json':
      return TextAreaProp;
    case 'text':
      return TextProp;
    case 'rich-text':
      return RichTextProp;
    case 'spatial-map':
      return MapProp;
    case 'multi-select':
      return MultiProp;
    case 'vocabulary-flat':
      return VocabularyFlat;
    case 'reference':
      return RefProp;
    default:
      return TextProp;
  }
}

function TextDisplayProp({entity}) {
  const key = entity.displayName
  try {
    const json = JSON.parse(entity.value).values
    return (
      <TextRecord label={(key || entity.key) + ':'} value={json || '-'}/>
    );
  } catch (e) {
    return (
      <TextRecord label={(key || entity.key) + ':'} value={entity.value || '-'}/>
    );
  }

}

function RefDisplayProp({entity}) {
  const key = entity.displayName
  try {
    const json = JSON.parse(entity.value).values
    return (
      <TextRecord label={(key || entity.key) + ':'} value={json || '-'} referenceLink={true}/>
    );
  } catch (e) {
    return (
      <TextRecord label={(key || entity.key) + ':'} value={entity.value || '-'}/>
    );
  }

}

function UrlDisplayProp({entity}) {
  const key = entity.displayName
  try {
    const json = JSON.parse(entity.value).values
    return (
      <UrlRecord label={(key || entity.key) + ':'} value={json || '-'} link={true}/>
    );
  } catch (e) {
    return (
      <UrlRecord label={(key || entity.key) + ':'} value={entity.value || '-'}/>
    );
  }

}

function RichTextDisplayProp({entity}) {
  const key = entity.displayName
  try {
    const json = JSON.parse(entity.value).values;
    return <Record label={(key || entity.key) + ':'} record={<div dangerouslySetInnerHTML={{__html: json}}/>}/>
  } catch (e) {
    return (
      <TextRecord
        label={(key || entity.key) + ':'}
        value={'-'}
      />
    );
  }
}

function BoolDisplayProp({entity}) {
  const key = entity.displayName
  try {
    const booleanValue = JSON.parse(entity.value).values;
    // return <TextRecord
    //   label={(key || entity.key) + ':'}
    //   value={booleanValue ? 'true' : 'false'} showBadge
    // />
    return <BooleanRecord
      label={(key || entity.key) + ":"}
      value={booleanValue ? "true" : "false"} showBadge 
      />;
  } catch (e) {
    return (
      <TextRecord
        label={(key || entity.key) + ':'}
        value={'-'}
      />
    );
  }
}

function MultiDisplayProp({entity}) {
  const key = entity.displayName
  try {
    const array = JSON.parse(entity.value).values;
    return <ArrayRecord label={(key || entity.key) + ':'} value={array}/>
  } catch (e) {
    return (
      <TextRecord
        label={(key || entity.key) + ':'}
        value={'-'}
      />
    );
  }
}

function MapDisplayProp({entity}) {
  let json
  if (!entity.value)
    return null
  else
    json = JSON.parse(entity.value).values
  return <Row className="task-dates mt-3">
    <Col xs={"12"}>
      <p className="text-muted mb-0">{`${entity?.displayName}:`}</p>
    </Col>
    <Col xs={"12"}>
      <MapWithAMarker
        defaultCenter={{lat: json?.langitude, lng: json?.longitude}}
        containerElement={<div style={{height: `400px`}}/>}
        mapElement={<div style={{height: `100%`}}/>}
        loadingElement={<div style={{height: `100%`}}/>}
        latState={json?.langitude}
        lngState={json?.longitude}
      />
    </Col>
  </Row>
}

function JsonDisplayProp({entity}) {
  const key = entity.displayName
  try {
    const json = JSON.parse(entity.value);
    return (
      <JSONRecord
        label={(key || entity.key) + ':'}
        value={json || '-'}
        fullWidth
      />
    );
  } catch (e) {
    return (
      <TextRecord
        label={(key || entity.key) + ':'}
        value={entity.value || '-'}
      />
    );
  }
}

function getDisplayPropertyView(type) {
  switch (type) {
    case 'rich-text':
      return RichTextDisplayProp;
    case 'boolean':
      return BoolDisplayProp;
    case 'json':
      return JsonDisplayProp;
    case 'multi-select':
      return MultiDisplayProp;
    case 'spatial-map':
      return MapDisplayProp;
    case 'reference':
      return RefDisplayProp
    case 'url':
      return UrlDisplayProp
    // case "property-time":
    // case "property-ip-address-v4":
    // case "property-number":
    // case "property-text-area":
    default:
      return TextDisplayProp;
  }
}

export {
  BooleanProp,
  RichTextProp,
  IpV4Prop,
  TimeProp,
  DateProp,
  NumberProp,
  MapProp,
  MultiProp,
  RefProp,
  EnumProp,
  TextAreaProp,
  TextProp,
  getPropertyView,
  getDisplayPropertyView,
  VocabularyFlat
};
