import React, {useEffect, useState} from 'react';
import BaseContent from '../../components/Common/BaseContent';
import { Row, Col, CardBody, CardTitle, Card } from 'reactstrap'
import CardActions from "@material-ui/core/CardActions";
import DoughnutChart from './DougnhnutChart'
import TableChart from "./TableChart";
import Switch from "@material-ui/core/Switch";
import axios from 'axios'
import {getAxiosDefaultConfig} from '../../utils'
import {handleError} from '../../store/core/api'
import {API_URL} from '../../config'
// import RepoxPieChart from './RepoxPieChart'


const Reports = (props) => {
  const [containerTypeOptions,setContainerTypeOptions] = useState([])
  // const [dataStreamsSize,setDataStreamsSize] = useState([])
  const [dataStreamTypes,setDataStreamTypes] = useState([])
  const [chartView, setChartView] = useState([false,false]);

    useEffect(() => {
      setChartView(JSON.parse(localStorage.getItem("chartView")));
    }, []);

    useEffect(() => {
      localStorage.setItem("chartView", JSON.stringify(chartView));
    }, [chartView]);
  
  useEffect(()=>{
    let refOptions = ''
    const url = `${API_URL}/reports/container_types`
    try {
      axios.post(url, {}, getAxiosDefaultConfig())
        .then((res) => { 
          const result = res.data
          refOptions = result.map((refValue) => ({
            label: refValue.label,
            value: refValue.value,
          }));
          setContainerTypeOptions(refOptions)
        })
        .catch(handleError);
    } catch (e) {
      console.log(e);
    }
  },[])

  // useEffect(()=>{
  //   let refOptions = ''
  //   const url = `${API_URL}/reports/datastream_size`
  //   try {
  //     axios.post(url, {}, getAxiosDefaultConfig())
  //       .then((res) => {
  //         const result = res.data
  //         refOptions = result.map((refValue) => ({
  //           label: refValue.label,
  //           value: refValue.value,
  //         }));
  //         setDataStreamsSize(refOptions)
  //       })
  //       .catch(handleError);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },[])

  useEffect(()=>{
    let refOptions = ''
    const url = `${API_URL}/reports/datastream_types`
    try {
      axios.post(url, {}, getAxiosDefaultConfig())
        .then((res) => {
          const result = res.data
          refOptions = result.map((refValue) => ({
            label: refValue.label,
            value: refValue.value,
          }));
          setDataStreamTypes(refOptions)
        })
        .catch(handleError);
    } catch (e) {
      console.log(e);
    }
  },[])

  const handleChange = (value) => {
    let newValue = [...chartView];
    newValue[value] = !chartView[value]
     setChartView(newValue);
}

  return (
    <BaseContent
      breadcrumbs={[{title: "Reports", link: "#"}]}
      // renderActions={() =>
      //   <Toolbar
      //     showList={showList}
      //     toggleListLayout={toggleListLayout}
      //   />
      // }
    >
      <Row>
        <Col lg={6}>
          <Card>
            <CardActions disableSpacing style={{position: "absolute", right: "10px", top: "10px"}}>
              <small>Change view</small> <Switch size="small" color="primary" inputProps={{"aria-label": "true/false"}} checked={chartView[0]} onChange={() => handleChange(0)} />
            </CardActions>
            <CardBody>
              <CardTitle className="mb-4">Container Types</CardTitle>
              {!chartView[0] ? <DoughnutChart options={containerTypeOptions} /> : <TableChart options={containerTypeOptions} />}
            </CardBody>
          </Card>
        </Col>
        {/*<Col lg={6}>*/}
        {/*  <Card>*/}
        {/*    <CardBody>*/}
        {/*      <CardTitle className="mb-4">Datastreams Size</CardTitle>*/}
        {/*      <DoughnutChart options={dataStreamsSize}/>*/}
        {/*    </CardBody>*/}
        {/*  </Card>*/}
        {/*</Col>*/}
        <Col lg={6}>
          <Card>
            <CardActions disableSpacing style={{position: "absolute", right: "10px", top: "10px"}}>
              <small>Change view</small> <Switch size="small" color="primary" inputProps={{"aria-label": "true/false"}} checked={chartView[1]} onChange={() => handleChange(1)} />
            </CardActions>
            <CardBody>
              <CardTitle className="mb-4">Datastream Types</CardTitle>
              {!chartView[1] ? <DoughnutChart options={dataStreamTypes} /> : <TableChart options={dataStreamTypes} />}
            </CardBody>
          </Card>
        </Col>
        {/*<Col lg={6}>*/}
        {/*  <Card>*/}
        {/*    <CardBody>*/}
        {/*      <CardTitle className="mb-4">Container Types</CardTitle>*/}
        {/*      <RepoxPieChart options={containerTypeOptions}/>*/}
        {/*    </CardBody>*/}
        {/*  </Card>*/}
        {/*</Col>*/}
      </Row>
    </BaseContent>
  );
};

export default Reports;
