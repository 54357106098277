import React   from 'react';
import {Card, CardBody, CardImg, CardSubtitle, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";

const WebsiteItem = ({website}) => {
  const {name, description, uuid, websiteshot} = website

  return <Card className='c-item'>
    <Link to={`/websites/edit/${uuid}`} style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}> </Link>

    <div style={{backgroundColor: '#cdcdcd',height:200}}>

      {websiteshot?<CardImg width="100%" height={169} src={websiteshot?`data:image/bmp;base64,${websiteshot}`:``} alt="Website preview" />:<>
      <div style={{minHeight:169}} className='d-flex flex-column justify-content-center align-items-center align-content-center'>

        <div className='d-flex justify-content-center align-items-center align-content-center' style={{fontSize:62}}>
          <i className='bx bx-image'></i> <br/>
        </div>
        <div className='d-flex justify-content-center align-items-center align-content-center'>
          No image available
        </div>

      </div></>}

    </div>

    <CardBody>
      <CardTitle tag="h4">
        {name}
      </CardTitle>
      <CardSubtitle style={{minHeight:40}}>{description}</CardSubtitle>
    </CardBody>

  </Card>
};

export default WebsiteItem;
