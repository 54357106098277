import React, {useEffect, useState} from 'react';
import BaseDialog from '../../../../../components/Common/BaseDialog'
import axios from 'axios'
import {API_URL} from '../../../../../config'
import {getAxiosDefaultConfig} from '../../../../../utils'
import toastr from 'toastr'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import {storiesSlideTemplates} from '../../../../../constants/enums'


const SlideTemplateDialog = (props) => {
  const {handleClose, handleCreateSlide, open, aspectRatio, orientation} = props
  // const {handleClose,handleCreateSlide,open,aspectRatio,orientation} = props
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [loading, setLoading] = useState(true)
  const [slideTemplates, setSlideTemplates] = useState([])

  // useEffect(()=>{
  //   if(open)
  //   {
  //      setSlideTemplates(slideTemplatesHardCoded.filter((slideTemplate)=>slideTemplate.aspectRatio === aspectRatio && slideTemplate.orientation === orientation))
  //   }
  //   //eslint-disable-next-line
  // },[open])


  useEffect(() => {
    if (open) {
      setLoading(true)
      axios.get(`${API_URL}/slide_templates?aspectRatio=${aspectRatio}&orientation=${orientation}`, getAxiosDefaultConfig())
        .then((response) => {
          setSlideTemplates(response.data.content)
          setLoading(false)
        })
        .catch((e) => {
          toastr.error(e)
          setLoading(false)
        });
    }
    //eslint-disable-next-line
  }, [open])

  const handleSelectedTemplate = (slideData) => {
    setSelectedTemplate(slideData)
  }

  const renderStepOne = () => {
    return <Row style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
      {storiesSlideTemplates.map((slideTemp, idx) => {
        return <div className={selectedTemplate && selectedTemplate.uuid === slideTemp.uuid ? 'slideTemplateSelected' : 'slideTemplate'}
                    onClick={() => handleSelectedTemplate(slideTemp)} style={{cursor: 'pointer'}} key={idx}>
              <Row className='align-items-center align-content-center justify-content-center' style={{textAlign:'center'}}>
                <Col lg={12}>
              <img src={slideTemp.coverPage} height={'100px'} alt={slideTemp.description}/>
                </Col>
                <Col lg={12}>
              <div className="text-muted">{slideTemp.description}</div>
                </Col>
              </Row>
        </div>
      })}
    </Row>
  }
  const renderStepTwo = () => {
    return <Row style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
      {slideTemplates.map((slideTemplate) => {
        return <Col lg={'auto'}
                    className={selectedTemplate && selectedTemplate.uuid === slideTemplate.uuid ? 'slideTemplateSelected' : 'slideTemplate'}
                    key={slideTemplate.uuid}
                    style={{cursor: 'pointer'}}
                    onClick={() => handleSelectedTemplate(slideTemplate)}>
          <Row>
            <Col lg={12} style={{textAlign: 'center'}}>
              <img src={slideTemplate.coverPage} alt={`${slideTemplate.uuid}`}
                   width={slideTemplate.orientation === 'LANDSCAPE' ? '100%' : '80px'}
                   height={slideTemplate.orientation === 'LANDSCAPE' ? '80px' : '100%'}/>
            </Col>
            <Col lg={12} className="mt-2">
              <div style={{textAlign: 'center', userSelect: 'none'}}>{slideTemplate?.title}</div>
            </Col>
          </Row>
        </Col>
      })}
    </Row>
  }

  const contents = [renderStepOne(), renderStepTwo()];

  return <BaseDialog
    scrollable={true}
    title={'Select a Slide Template'}
    open={open}
    handleClose={() => {
      handleClose()
    }}
    size={'lg'}
    primaryAction={() => handleCreateSlide(selectedTemplate)}
    primaryActionTitle={'Add Slide'}
    primaryActionDisabled={selectedTemplate === null}
    busy={loading}
    tabs={[
      {title: 'Default Templates'},
      {title: 'Saved Templates'},
    ]}
    contents={contents}
  />

}

export default SlideTemplateDialog
