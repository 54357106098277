import React from 'react';
import {withRouter} from "react-router-dom";
import {Col, FormGroup, Input, Label} from "reactstrap";

const Time = (props) => {
  const {field, setField, selected, answerable, formDisabled, fieldNumber} = props
  const {title,description, index, required} = field

  const setValue = (e) => {
    const value = e.target.value
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable&&required&&value===''
      },
      value
    })
  }

  if ((!selected) || answerable) return <>

    <FormGroup row>
      <Col xs={12}>
       <Label for={`time-${index}`}>{fieldNumber+1}. {title} {required&&<b>*</b>} </Label>
        <div style={{fontSize: 12, marginTop: -8, marginBottom: 8, opacity: 0.8}}>{description}</div>
        <Input
          type='time'
          name={`time-${index}`}
          id={`time-${index}`}
          placeholder={description}
          disabled={formDisabled}
          onChange={setValue}
          value={field.value?field.value:''}
        />
      </Col>
    </FormGroup>

  </>

  return <> </>
}

export default withRouter(Time);
