import React, { Fragment } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ErrorBoundary from "./ErrorBoundary";

const BaseDialog = (props) => {
  const {
    title,
    subtitle,
    primaryAction,
    primaryActionTitle,
    primaryActionDisabled,
    secondaryAction,
    secondaryActionTitle,
    deleteAction,
    open,
    handleClose,
    busy = false,
    size = "md",
    isValid = true,
    scrollable = false,
    tabs,
    steps,
    contents,
    extraClassName,
    errorMessage,
    dismissError,
    onTabChanged,
    changeTab,
  } = props;

  const [activeTab, setActiveTab] = React.useState(0);

  React.useEffect(() => {
    if (open) setActiveTab(0);
    onTabChanged && onTabChanged(0);
  }, [onTabChanged, open]);

  React.useEffect(() => {
    onTabChanged && onTabChanged(activeTab);
  }, [activeTab, onTabChanged]);

  React.useEffect( () =>{
    if (open && changeTab)
      setActiveTab(changeTab)
    //eslint-disable-next-line
  },[changeTab])

  const renderTabs = () =>
    tabs && (
      <Nav tabs className="nav-tabs-custom nav-justified">
        {tabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink
              disabled={tab.disabled}
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === index,
              })}
              onClick={() => {
                setActiveTab(index);
              }}
            >
              {tab.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    );

  const renderTabContents = () => {
    return (
      contents && (
        <TabContent
          activeTab={activeTab}
          style={{ overflow: "auto", minHeight: 400, flex: 1 }}
          className={"p-2"}
        >
          {contents.map((content, index) => {
            return (
              <TabPane tabId={index} className="p-1" key={index}>
                <Row>
                  <Col sm="12">{content}</Col>
                </Row>
              </TabPane>
            );
          })}
        </TabContent>
      )
    );
  };

  const renderSteps = () => {
    return (
      <div id="basic-pills-wizard" className="twitter-bs-wizard">
        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
          {contents && contents.length === 0 && steps && steps.length ===0 &&
          <div className='text-muted font-size-12 noProps'>There are no properties</div>
          }
          {steps &&
            steps.map((step, index) => (
              <NavItem key={index}>
                <NavLink
                  disabled={step.disabled}
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === index,
                  })}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  <span className="step-number mr-2">{index + 1}</span>
                  {step.title}
                </NavLink>
              </NavItem>
            ))}
        </ul>
      </div>
    );
  };

  const error =
    errorMessage && errorMessage ? (
      <Alert color="danger" toggle={dismissError}>
        {errorMessage}
      </Alert>
    ) : null;

  const renderBody = () => {
    if (tabs) {
      return (
        <Fragment>
          {error}
          {props.children}
          {renderTabs()}
          {renderTabContents()}
        </Fragment>
      );
    } else if (steps) {
      return (
        <Fragment>
          <ModalHeader toggle={handleClose}>
            <span className={"d-flex flex-column align-items-start"}>
              <span>
                {title}{" "}
              </span>
              {subtitle && <Badge color={"primary"}>{subtitle}</Badge>}
            </span>
          </ModalHeader>
          <ModalBody>

            <Row>
              <Col sm="12">
                {error}
                {props.children}
                {renderSteps()}
              </Col>
            </Row>

            <Row>
             <Col lg="12">
                
                {renderTabContents()}
              
              </Col>
              {/* <Col lg="6">if this is a datastream show me a preview</Col> */}
            </Row>



          </ModalBody>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <ModalHeader toggle={handleClose}>{title}</ModalHeader>
          <ModalBody>
            {error}
            {props.children}
          </ModalBody>
        </Fragment>
      );
    }
  };

  const handlePrimaryActionClick = () => {
    if (steps && activeTab < steps.length - 1) {
      setActiveTab((prevState) => prevState + 1);
    } else {
      primaryAction();
    }
  };

  const buttonTitle =
    steps && activeTab < steps.length - 1
      ? "Next"
      : primaryActionTitle || "Confirm";

  return (
    <Modal
      isOpen={open}
      scrollable={scrollable}
      role="dialog"
      autoFocus={true}
      size={size}
      centered={true}
      tabIndex="-1"
      toggle={handleClose}
      className={`${extraClassName}`}
    >
      <ErrorBoundary>
        {renderBody()}
        <ModalFooter>
          {deleteAction && (
            <Link
              to="#"
              className="text-danger font-size-18 mr-auto"
              onClick={deleteAction}
              disabled={busy}
            >
              <i className="bx bx-trash"></i>
            </Link>
          )}

          {steps && activeTab > 0 && (
            <Button
              type="button"
              color="light"
              onClick={() => setActiveTab(activeTab - 1)}
              disabled={busy}
            >
              {busy && (
                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
              )}
              Previous
            </Button>
          )}

          {secondaryAction&&secondaryActionTitle&&(
              <Button
                  type="button"
                  color="primary"
                  onClick={secondaryAction}
                  disabled={busy || !isValid}
              >
                {busy && (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                )}
                {secondaryActionTitle}
              </Button>
          )}

          {props.uploadProcess && (          
                <Button
                color="primary"
                onClick={() => {
                  props.setUploadProcess(false);
                  props.cancelUpload();
                }}
              >
                Cancel
              </Button>
          )}
          
          {primaryAction && (
            <Button
              type="button"
              color="primary"
              onClick={handlePrimaryActionClick}
              disabled={busy || !isValid || primaryActionDisabled || props.uploadProcess}
            >
              {busy && (
                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
              )}
              {buttonTitle}
            </Button>
          )}


        </ModalFooter>
      </ErrorBoundary>
    </Modal>
  );
};

export default BaseDialog;
