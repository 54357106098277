// This is used to render results depneding on type
import DropdownList from "./components/fields/DropdownList";
import MultipleChoice from "./components/fields/MultipleChoice";
import Checkboxes from "./components/fields/Checkboxes";
import ShortAnswer from "./components/fields/ShortAnswer";
import Paragraph from "./components/fields/Paragraph";
import LinearScale from "./components/fields/LinearScale";
import DateSelect from "./components/fields/DateSelect";
import Time from "./components/fields/Time";
import Email from "./components/fields/Email";
import MultipleChoiceGrid from "./components/fields/MultipleChoiceGrid";
import CheckboxesGrid from "./components/fields/CheckboxesGrid";

export const ANSWER_COMPONENTS = {
  DROPDOWN: DropdownList,
  MULTIPLE_CHOICE: MultipleChoice,
  CHECKBOXES: Checkboxes,
  SHORT_ANSWER: ShortAnswer,
  PARAGRAPH: Paragraph,
  LINEAR_SCALE: LinearScale,
  DATE: DateSelect,
  TIME: Time,
  MULTIPLE_CHOICE_GRID: MultipleChoiceGrid,
  CHECKBOX_GRID: CheckboxesGrid,
  EMAIL: Email
}
