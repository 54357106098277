import React, {useState} from "react";
import {connect} from "react-redux";
import {Row} from "reactstrap";
import {ApiEndpoint} from "../../store/core/endpoint";
import {EntityType} from "../../store/core/entityType";
import axios from "axios";
import {getAxiosDefaultConfig, getTextColor} from "../../utils";

const EntityTags = (props) => {
  const [currentTags, setCurrentTags] = useState([]);

  React.useEffect(() => {
    setCurrentTags(props.entityTags.map(tag => tag.id))
  }, [props.entityTags])

  const handleUpdateTag = (tagId) => {
    const exist = currentTags.includes(tagId);
    const url =
      ApiEndpoint[EntityType.Tag] + "/" + tagId + (exist ? "/remove" : "/add");
    setCurrentTags((prevState) => {
      return exist
        ? prevState.filter((x) => x !== tagId)
        : [...prevState, tagId];
    });
    console.log(url);
    const payload = {
      objectId: props.entityId,
      objectType: props.objectType,
    };
    axios.post(url, payload, getAxiosDefaultConfig());
  };

  return (
    <div>
      <div className={"d-flex mt-4 align-items-center"}>
        <i className="bx bx-purchase-tag h3 mr-2" />
        <h5>Tags ({currentTags.length})</h5>
      </div>
      <Row className={"p-2"}>
        {props.tags.map((tag) => {
          const exist = currentTags.includes(tag.id);
          return (
            <div key={tag.id}>
              <div
                onClick={() => handleUpdateTag(tag.id)}
                className="px-2 rounded mr-2 mb-2"
                style={{ backgroundColor: tag.color, opacity: exist ? 1 : 0.4, padding: 1 ,cursor:'pointer',userSelect:'none'}}
              >
                <h6 className="my-2" style={{color: getTextColor(tag.color)}}>{tag.name}</h6>
              </div>
            </div>
          );
        })}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.Tag.getAll.result,
  };
};
export default connect(mapStateToProps, null)(EntityTags);
