import { getPropertyView } from "./PropertyViews";
import { Col } from "reactstrap";
import React from 'react';

export  function PropertyColumn({
  property,
  value,
  handleChange,
  handleChangeArray,
  className,
  joinProps,
}) {
  const PropertyView = getPropertyView(property.propertyType);
  return (
    <Col xs={12} className={className}>
      <PropertyView
        property={property}
        value={value}
        handleChange={handleChange}
        handleChangeArray={handleChangeArray}
        joinProps={joinProps}
      />
    </Col>
  );
}
