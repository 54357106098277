import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from "reactstrap";
import {useLocation, useParams} from "react-router";
import axios from "axios";
import FieldRenderer from "../components/FieldRenderer";
import {API_URL} from "../../../config";
import {getAxiosDefaultConfig, getTenantId} from "../../../utils";
import {SURVEY_TYPES} from "../components/consts";
import {EmailTypeField} from "../components/common/Email";
import toastr from "toastr";
import {UserInfoTypeFields} from "../components/common/UserInfoTypeFields";
// import faker from "faker"

const headers = {
  'Content-Type': 'application/json'
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SurveyView = (props) => {
  const {uuid} = useParams();
  let query = useQuery();
  let t = query.get('t');

  // const [isLoading, data] = useFetch(ApiEndpoint[EntityType.Surveys] + '/' + uuid);
  const [surveyData, setSurveyData] = useState()
  const [isLoading, setIsLoading] = useState()
  const [responded, setResponded] = useState()
  const [hasError, setError] = useState()

  const setFields = (newFields) => {
    const currentData = {...surveyData}
    currentData.questions = newFields
    setSurveyData(currentData)
  }

  // Will change the field
  const setField = (i, data) => {
    const nFields = [...surveyData.questions]
    nFields[i] = data
    setFields(nFields)
  }

  const setEmail = (email) => {
    setSurveyData({
      ...surveyData,
      email
    })
  }

  const setUserInfo = (info) => {
    setSurveyData({
      ...surveyData,
      ...info
    })
  }

  useEffect(() => {

    setIsLoading(true)
    axios.get(API_URL + `/addons/srv/public/${uuid}?t=${t}`, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setSurveyData(data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response) {
          setError(error.response.data);
        }
      })

    // eslint-disable-next-line
  }, [])

  const submitResponse = () => {
    const tenantHeader = {...headers, 'X-TenantID': getTenantId()}

    const answers = []
    surveyData.questions.forEach((question) => {
      answers.push({
        id: question.id,
        value: question.value
      })
    })

    const payload = {
      token: t,
      "firstName": surveyData.firstname,
      "lastName": surveyData.lastname,
      "email": surveyData.email,
      "organization": surveyData.organization,
      answers
    }

    setIsLoading(true)
    axios.post(API_URL + `/addons/srv/public/${uuid}/responses`, payload, {
      headers: tenantHeader
    }).then(r => {
      setIsLoading(false);
      setResponded(true);
    }).catch((err) => {
      setIsLoading(false);
      if (err.response) {
        // client received an error response (5xx, 4xx)
        // alert(JSON.stringify(err.response))
        if (err.response.data) {
          toastr.error(err.response.data.message)
        }
      } else if (err.request) {
        // client never received a response, or request never left
      } else {
        // anything else
      }
    });

  }

  if (hasError) return <>
    <Container className='survey-view-page'>
      <div style={{minHeight: 300}} className='d-flex justify-content-center align-items-center align-content-center'>
        <div className='d-flex' xs={{size: 8, offset: 2}}>
          <h5> {hasError.message} </h5>
        </div>
      </div>
    </Container>
  </>

  if (!surveyData) return <div className='custom-loading-overlay'></div>


  // Check if required fields are all filled in
  const missingRequired = surveyData.questions.filter((f) => f.required && (f.value === '' || (Array.isArray(f.value) && f.value.length === 0))).length !== 0
  // Check if there are any fields with any kind of error set to true
  const fieldsWithErrors = surveyData.questions.filter((f) => f.errors && Object.values(f.errors).filter((e) => e === true).length !== 0).length !== 0

  const missingEmailWhenEmailType = surveyData.surveyType === SURVEY_TYPES.EMAIL_ONLY && !surveyData.email

  const missingWhenUserdetailsType = surveyData.surveyType === SURVEY_TYPES.USER_DETAILS && !(surveyData.email && surveyData.firstname && surveyData.lastname && surveyData.organization)

  // All errors
  const hasErrors = fieldsWithErrors || missingRequired || missingEmailWhenEmailType || missingWhenUserdetailsType

  const closeDate = new Date(surveyData.closeAt + 'Z')
  const openDate = new Date(surveyData.openAt + 'Z')
  const nowDate = new Date()
  const dateExpired = !(closeDate > nowDate)
  const willOpen = (openDate > nowDate)

  if (willOpen) return <>
    <Container className='survey-view-page'>
      <div style={{minHeight: 300}} className='d-flex justify-content-center align-items-center align-content-center'>
        <div className='d-flex' xs={{size: 8, offset: 2}}>
          <h5> This survey is not open yet. </h5>
        </div>
      </div>
    </Container>
  </>

  if (dateExpired && !surveyData.locked) return <>
    <Container className='survey-view-page'>
      <div style={{minHeight: 300}} className='d-flex justify-content-center align-items-center align-content-center'>
        <div className='d-flex' xs={{size: 8, offset: 2}}>
          <h5> This survey is closed. </h5>
        </div>
      </div>
    </Container>
  </>

  if (surveyData.locked) return <>
    <Container className='survey-view-page'>
      <div style={{minHeight: 300}} className='d-flex justify-content-center align-items-center align-content-center'>
        <div className='d-flex' xs={{size: 8, offset: 2}}>
          <h5> {surveyData.lockedMessage} </h5>
        </div>
      </div>
    </Container>
  </>

  const createMarkup = (htmlcode) => {
    return {__html: htmlcode}
  }

  return (
    <Container className='survey-view-page'>
      {/*{!surveyData && <div className='custom-loading-overlay'></div>}*/}

      {!responded && <form aria-disabled={isLoading}>
        <Row>
          <Col xs={{size: 8, offset: 2}}>

            <Container style={{marginBottom: 16}}>
              <h2>{surveyData.label}</h2>
              <h5  style={{whiteSpace: "pre-wrap"}}>{surveyData.description}</h5>
            </Container>

            {(surveyData.surveyType === SURVEY_TYPES.EMAIL_ONLY || surveyData.surveyType === SURVEY_TYPES.USER_DETAILS) &&
            <div className='survey-field-view  survey-user-details'>
              <Container style={{textAlign: 'left', position: 'relative'}}>
                {surveyData.surveyType === SURVEY_TYPES.EMAIL_ONLY &&
                <EmailTypeField setEmail={setEmail}/>
                }
                {surveyData.surveyType === SURVEY_TYPES.USER_DETAILS &&
                <UserInfoTypeFields setUserInfo={setUserInfo}/>
                }
              </Container>
            </div>}

            {surveyData.sections.map((section,sectionIndex)=>{
              const sectionFields = surveyData.questions.filter((f)=>section.questions.includes(f.id))
              const questionsSoFar = surveyData.sections.slice(0,sectionIndex).reduce((total=0, s)=>total+s.questions.length,0)

              return <>
                {section.title&&<Container style={{marginBottom: 16, marginTop: 32}}>
                  <h4>{section.title}</h4>
                  {section.description!==''&&<h6  style={{whiteSpace: "pre-wrap"}}>
                    {/*{section.description}*/}
                    <div dangerouslySetInnerHTML={createMarkup(section.description)} />
                  </h6>}
                </Container>}

                {!section.title&&<br/>}

                {sectionFields.map((field, sectionFieldIndex) => {
                  const index = surveyData.questions.findIndex((f)=>f.id===field.id)
                  // const absoluteFieldIndex = sectionFieldIndex +
                  return <div className='survey-field-view' key={index}>
                    <FieldRenderer field={{...field, index}} setField={(data) => setField(index, data)}
                                   answerable={true} key={index} formDisabled={isLoading} fieldNumber={sectionFieldIndex+questionsSoFar} />
                  </div>
                })}
              </>
            })}

          </Col>
        </Row>

        <Row>
          <Col style={{textAlign: 'center', marginTop: 16}}>
            <Button outline color='primary' onClick={submitResponse} disabled={hasErrors}>Submit response</Button>
          </Col>
        </Row>
      </form>}

      {responded &&
      <div style={{minHeight: 300}} className='d-flex justify-content-center align-items-center align-content-center'>
        <div className='d-flex survey-submit-message' xs={{size: 8, offset: 2}}>
          <h3> {surveyData.submissionMessage} </h3>
        </div>
      </div>}

    </Container>
  );
}

export default SurveyView
