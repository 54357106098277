import React, {useCallback, useMemo, useState} from "react";
import BaseContent from "../../../../components/Common/BaseContent";
import {connect} from "react-redux";
import {Schema} from "../../../../store/core/schema";
import {EntityType} from "../../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import CardSettings from "../../../../components/Common/card-settings";
import {useListToggle, useToggle} from "../../../../helpers/hooks";
import {createQualifier, updateQualifier, deleteQualifier} from "../../../../store/qualifier/actions";
import ListSettings from '../../../../components/Common/ListSettings'
import ListToggle from '../../../../components/Common/ListToggle'

const entityType = EntityType.Qualifier;

const breadcrumbs = [
  { title: "Settings", link: "/settings" },
  { title: "Content Model", link: "/settings/content-model" },
  { title: "Qualifiers", link: "#" },
];

const Qualifiers = (props) => {
  const { context, createQualifier, deleteQualifier, updateQualifier } = props;
  const { result, isLoading, error } = context.Qualifier.getAll;
  const currentProperty = context.Qualifier.get.result;
  const [openModal, toggleModal] = useToggle(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const [isList, toggleList] = useListToggle();

  const handleEntityClick = useCallback(
    (entity) => {
      setEntity(entity);
      toggleModal();
    },
    [toggleModal]
  );

  React.useEffect(() => {
    setEntity(currentProperty);
  }, [currentProperty]);

  const handleCreate = useCallback(() => {
    setEntity(Schema[entityType]);
    toggleModal();
  }, [toggleModal]);

  const cards = useMemo(() => {
    return result.map((qualifier) => ({
        label: qualifier.description,
        icon: "bx-grid-horizontal",
        handleClick: () => handleEntityClick(qualifier),
      }));
  }, [handleEntityClick, result]);

  const list = useMemo(() => {
    return result.map((qualifier) => ({
      label: qualifier.description,
      handleClick: () => handleEntityClick(qualifier),
    }));
  }, [handleEntityClick, result]);

  const listColumns = [
    {name: "Name", prop: "label"}
  ]

  return (
    <>
      <BaseContent
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
      >
        {!isList && <CardSettings settings={cards}/>}
        {isList && <ListSettings contents={list} columns={listColumns}/>}
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={openModal}
          handleClose={toggleModal}
          createEntity={createQualifier}
          updateEntity={updateQualifier}
          deleteEntity={deleteQualifier}
          context={context.Qualifier}
        />
      </BaseContent>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createQualifier,
  updateQualifier,
  deleteQualifier,
})(Qualifiers);
