import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {API_URL} from "../../../config";
import {getTenantId} from "../../../utils";
import {useParams} from "react-router";
import Loader from "./components/Loader";
import '@datawise-ai/repox-stories-viewer/dist/index.css'
import '@datawise-ai/repox-stories-viewer/dist/animate.min.css'
import {StoriesViewer} from "@datawise-ai/repox-stories-viewer"

const getAxiosOnlyTenant = () => {
  return {
    headers: {
      "X-TenantID": getTenantId()
    }
  }
}

const EmbeddedViewer = (props) => {
  const {uuid} = useParams()
  const [story, setStory] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [assets, setAssets] = useState()

  const getStory = () => {
    setIsLoading(true)
    axios.get(`${API_URL}/str/pub/websites/${uuid}/story`,getAxiosOnlyTenant())
      .then((response) => {
        const {data} = response;
        setStory(data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  useEffect(()=>{
    if (story) {

      const nassets = []

      // Story background
      if (story.storyDetails.screen.background&&story.storyDetails.screen.background.uuid) {
        nassets.push({
          uuid: story.storyDetails.screen.background.uuid,
          from: story.storyDetails.screen.background.from
        })
      }

      story.storyDetails.screen.slides.forEach((slide)=>{

        // Slide background
        if (slide.background.uuid) {
          nassets.push({
            uuid: slide.background.uuid,
            from: slide.background.from
          })
        }

        // Image asset
        slide.elements.forEach((element)=>{
          if (element.data&&element.data.uuid) {
            nassets.push({
              uuid: element.data.uuid,
              from: element.data.from
            })
          }
        })

      })

      setAssets(nassets)
    }
  },[story])

  useEffect(()=>{
    if (assets) {
      preDownload()
    }
    //eslint-disable-next-line
  },[assets])


  useEffect(()=>{
    getStory()
    document.body.style = 'overflow: hidden; backgroundColor: #fff;'
    // eslint-disable-next-line
  },[])

  const downloadedAssets = useRef(0)

  const preDownload = () => {
    assets.forEach((asset)=>{
      const from = asset.from
      const asset_uuid = asset.uuid
      const URI = from==='store'?`${API_URL}/str/pub/websites/${uuid}/datastreams/${asset_uuid}`:`${API_URL}/str/pub/websites/${uuid}/assets/${asset_uuid}`
      getData(URI).then(()=>{
        downloadedAssets.current++
        if (downloadedAssets.current===assets.length) {
          setHasLoaded(true)
        }
      }).catch(()=>{
        downloadedAssets.current++
        if (downloadedAssets.current===assets.length) {
          setHasLoaded(true)
        }
      })
    })
  }

  async function getData(url = '', data = {}) {

    const storyCache = await caches.open('story-cache');

    const headers = getAxiosOnlyTenant().headers
    headers['Cache-Control'] = 'max-age=50000'

    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'force-cache',
      credentials: 'same-origin',
      headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    }

    const getRequest = new Request(url,options)
    const cacheResponse = await storyCache.match(getRequest,options)

    if (!cacheResponse) {
      await storyCache.add(getRequest)
      const cacheResponse = await storyCache.match(getRequest,options)
      return cacheResponse.blob()
    } else {
      return cacheResponse.blob()
    }
  }

  const getDataFromCache = (asset_uuid,setData,from) => {
    const URI = from==='store'?`${API_URL}/str/pub/websites/${uuid}/datastreams/${asset_uuid}`:`${API_URL}/str/pub/websites/${uuid}/assets/${asset_uuid}`
    getData(URI).then((data)=>{
      setData(URL.createObjectURL(data))
    })
  }

  const containerRef = useRef()

  return <>
    {(isLoading ||!story||!hasLoaded)&&<Loader />}
    {story&&hasLoaded&&<div ref={containerRef} style={{width:'100vw', height:'100vh', backgroundColor: 'white'}}>
      <StoriesViewer containerRef={containerRef} story={story.storyDetails.screen} getData={getDataFromCache} />
    </div>}
  </>

}

export default EmbeddedViewer
