import React from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import ActivityComp from "./ActivityComp";
import EntityDetails from "./EntityDetails";
import { getIconForDatastream } from "../../utils";
import EntityMetadata from "./EntityMetadata";
import ErrorBoundary from "./ErrorBoundary";

const EntitySidebar = ({
  open,
  entity,
  busy,
  handleClose,
  isFolder = true,
  objectType,
  entityTags,
  entityType,
  showProperties,
  properties,
  isLoadingCurrent,
  color,
}) => {
  const [activeTab, setActiveTab] = React.useState("1");
  const classes = open ? "right-sidebar-show" : "right-sidebar-hide";
  const typeName = entity.type ? entity.type.name : "";
  const icon = isFolder ? "bx-folder" : getIconForDatastream(typeName);
  const colorBgClass = color === "dark" ? "bg-dark" : "bg-white";
  return (
    <Card
      className={`d-flex flex-column right-sidebar ${classes} ${colorBgClass}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={"d-flex p-3 align-items-center"}>
        <i className={`bx ${icon} h3 mr-2`}></i>
        <h5>{entity.label}</h5>
        <button
          style={{ position: "absolute", right: 0, top: 0 }}
          onClick={handleClose}
          type="button"
          className="btn font-size-18 m-2"
          data-toggle="fullscreen"
        >
          <i className="bx bx-x" />
        </button>
      </div>
      <div className={"p-1"}>
        <Nav tabs className={"nav-tabs-custom nav-justified"}>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: activeTab === "1" })}
              onClick={() => setActiveTab("1")}
            >
              Details
            </NavLink>
          </NavItem>
          {showProperties && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "2" })}
                onClick={() => setActiveTab("2")}
              >
                Properties
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: activeTab === "3" })}
              onClick={() => setActiveTab("3")}
            >
              Activity
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <CardBody style={{ overflow: "auto", flex: 1 }}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="p-1">
            <ErrorBoundary>
              <EntityDetails
                isFolder={isFolder}
                entity={entity}
                busy={busy}
                objectType={objectType}
                entityTags={entityTags}
              />
            </ErrorBoundary>
          </TabPane>
          {showProperties && (
            <TabPane tabId="2" className="p-1">
              <ErrorBoundary>
                <EntityMetadata
                  entity={entity}
                  busy={busy}
                  properties={properties}
                />
              </ErrorBoundary>
            </TabPane>
          )}
          <TabPane tabId="3" className="p-1">
            <ErrorBoundary>
              <ActivityComp entityId={entity.id} entityType={entityType} isLoadingCurrent={isLoadingCurrent}/>
            </ErrorBoundary>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default EntitySidebar;
