import React, {useEffect, useState} from 'react'
import {Col, Container, Media, Row} from 'reactstrap'
import axios from "axios";
import {useLocation, useParams} from "react-router";
import moment from "moment";
import PaginationComponent from "react-reactstrap-pagination";
import BusyIndicator from "../../../components/Common/BusyIndicator";
import {API_URL} from "../../../config";
import {getAxiosDefaultConfig} from "../../../utils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Activity = () =>{

  let query = useQuery();
  const pageVar = query.get('page')

  const {id} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [activity, setActivity] = useState()
  const [page, setPage] = useState(pageVar?pageVar:0)

  const getActivity = () => {

    setIsLoading(true)
    axios.get(`${API_URL}/str/screens/${id}/activity?page=${page}`,getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setActivity(data)
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  const handleSelectPage = (page) => {
    setPage(page-1)
  }

  useEffect(()=>{
    getActivity()
    // eslint-disable-next-line
  },[page])

  return  <Container>
    <Row>
          <Col xs={12} md={{size: 10, offset: 1}} className='responses-wrapper'>

        {isLoading&&<Row style={{minHeight:350, position: 'relative'}} className='d-flex justify-content-center align-items-center align-content-center'>

          <div className='d-flex'>
            <BusyIndicator busy={isLoading}/>
          </div>

        </Row>}

            {activity&&activity.content.length===0&&<Row style={{minHeight:350, position: 'relative'}} className='d-flex justify-content-center align-items-center align-content-center'>

              <div className='d-flex'>
                No activity yet
              </div>

            </Row>}

        {activity&&!isLoading&&<Row>
          <Col xs={12} className='p-4'>
            <ul className="verti-timeline list-unstyled">
              {activity.content.map((activity, key) => (
                <li className="event-list" key={key}>
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18" />
                  </div>
                  <Media>
                    <div className="mr-3">
                      <h5 className="font-size-14" title={moment(activity.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}>
                        {moment(activity.createdAt).format("DD/MM/YYYY")}{" "}
                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2" />
                      </h5>
                    </div>
                    <Media body>
                      <div>{activity.description}</div>
                    </Media>
                  </Media>
                </li>
              ))}
            </ul>
          </Col>
        </Row>}

        {activity&&<Row>
          <Col xs={{size:6,offset:6}} className='responses-navigation'>
            <PaginationComponent defaultActivePage={activity.pageable.pageNumber+1} totalItems={activity.totalElements} pageSize={activity.pageable.pageSize} onSelect={handleSelectPage} />
          </Col>
        </Row>}

      </Col>
    </Row>
  </Container>
}

export default Activity
