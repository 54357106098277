import {
    createDeleteMethod,
    createGetAllMethod,
    createGetMethod,
    createPostMethod,
    createPutMethod,
    createPostSuccessMethod
} from "../core/factory";
import {entityType} from "./module";

export const getAllStories = () => createGetAllMethod(entityType)
export const getStory = (params) => createGetMethod(entityType, params)
export const createStory = (payload) => createPostMethod(entityType, payload)
export const updateStory = (payload) => createPutMethod(entityType, payload)
export const deleteStory = (payload) => createDeleteMethod(entityType, payload)
export const clearStories = () => createPostSuccessMethod(entityType, {})
// export const publishStory = (payload) => createCustomAction(entityType, {data: payload, actionType: PUBLISH_STORY})
// export const deleteDataStreamFromContainer = (payload) => createCustomAction(entityType, {data: payload, actionType: DELETE_DATASTREAM_FROM_CONTAINER})

