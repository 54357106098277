import React, { Fragment, useCallback, useEffect, useState } from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import Dropzone from "react-dropzone";
import { Card, Col, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { formatBytes, getAxiosDefaultConfig, postData } from "../../utils";
import axios from "axios";
import { API_URL } from "../../config";
import { handleError } from "../../store/core/api";
import toastr from "toastr";
import ScrollLoader from "../../components/Common/ScrollLoader";

const ImportDialog = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [busy, setBusy] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [tab, setTab] = useState(0);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  };

  useEffect(() => {
    setSelectedFiles([]);
    setPreviewData(null);
    setTab(0);
  }, [props.open]);

  const fileUpload = useCallback(
    async (selectedFiles) => {
      const params =
        (props.containerUuid && `?to=${props.containerUuid}`) || "";
      const url = API_URL + "/import/preview" + params;
      const axiosConfig = getAxiosDefaultConfig();
      const formData = new FormData();
      formData.append("file", selectedFiles[0]);

      try {
        setBusy(true);
        const result = await axios
          .post(url, formData, axiosConfig)
          .then((res) => res.data)
          .catch(handleError);
        setPreviewData(result);
        if (result.errors[0]) {
          toastr.error("The file you uploaded has some errors. Please resolve them and reupload.");
        }
        if (result.warnings[0]) {
          toastr.error("The file you uploaded has some warnings");
        }
        setBusy(false);
      } catch (e) {
        setBusy(false);
        toastr.error(e.message);
      }
    },
    [props.containerUuid]
  );

  const onTabChanged = useCallback(
    (tabIndex) => {
      setTab((prevTab) => {
        if (tabIndex === 1 && selectedFiles[0] && prevTab === 0) {
          fileUpload(selectedFiles);
        } else if (tabIndex === 0) {
          setPreviewData(null);
        }
        return tabIndex;
      });
    },
    [fileUpload, selectedFiles]
  );

  const handleSubmit = async () => {
    const url = API_URL + "/import/submit";
    const payload = {
      to: props.containerUuid,
      containers: previewData && previewData.containers,
    };
    try {
      setBusy(true);
      await postData(url, payload);
      setBusy(false);
      props.handleClose();
      props.callback();
    } catch (e) {
      setBusy(false);
      toastr.error(e.message);
    }
  };

  const isValid = () => {
    if (busy) return false;
    if (tab === 0) {
      return !!selectedFiles[0];
    } else if (tab === 2) {
      return previewData && previewData.errors.length === 0;
    } else {
      return previewData && previewData.containers.length > 0;
    }
  };

  const renderStepOne = () => (
    <Fragment>
      <Dropzone
        accept={".xls, .xlsl"}
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
              </div>
              <h3>Drop files here or click to upload.</h3>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f, i) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
      </div>
    </Fragment>
  );

  const renderStepTwo = () => {
    return (
      <div>
        <Table className="project-list-table table-nowrap table-centered table-borderless">
          <thead>
            <tr>
              <th scope="col" style={{ width: "100px" }}>
                #
              </th>
              <th scope="col">Label</th>
              <th scope="col">Type</th>
              <th scope="col">Properties</th>
            </tr>
          </thead>
          <tbody>
            {(previewData ? previewData.containers : []).map((item, key) => (
              <tr>
                <th>#{key + 1}</th>
                <td>
                  <h5 className="font-size-14 mb-0">{item.label}</h5>
                </td>
                <td>
                  <span className="badge badge-primary">{item.type}</span>
                </td>
                <td>{item.properties.length}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ScrollLoader loading={busy} />
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div>
        <Table className="project-list-table table-nowrap table-centered table-borderless">
          {previewData && previewData.errors[0] && (
            <thead>
              <tr>
                <th scope="col" style={{ width: "100px" }}>
                  #
                </th>
                <th scope="col">Errors</th>
              </tr>
            </thead>
          )}
          <tbody>
            {(previewData ? previewData.errors : []).map((error, key) => (
              <tr>
                <th>#{key + 1}</th>
                <td>{error}</td>
              </tr>
            ))}
          </tbody>
          {previewData && previewData.warnings[0] && (
            <thead>
              <tr>
                <th scope="col" style={{ width: "100px" }}>
                  #
                </th>
                <th scope="col">Errors</th>
              </tr>
            </thead>
          )}
          <tbody>
            {(previewData ? previewData.warnings : []).map((warning, key) => (
              <tr>
                <th>#{key + 1}</th>
                <td>{warning}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const contents = [renderStepOne(), renderStepTwo()];
  const steps = [{ title: "Upload" }, { title: "Preview & Submit" }];

  if (previewData && (previewData.errors[0] || previewData.warnings[0])) {
    steps.push({ title: "Errors / Warnings" });
    contents.push(renderStepThree());
  }

  return (
    <BaseDialog
      scrollable
      size={"lg"}
      title={"Import files"}
      open={props.open}
      primaryAction={handleSubmit}
      isValid={isValid()}
      handleClose={props.handleClose}
      contents={contents}
      onTabChanged={onTabChanged}
      steps={steps}
    />
  );
};

export default ImportDialog;
