import {
  createDeleteMethod,
  createGetAllMethod,
  createGetMethod,
  createPostMethod,
  createPutMethod,
} from '../core/factory';
import {entityType} from "./module";

export const getAllUsers = () => createGetAllMethod(entityType)
export const getUser = (params) => createGetMethod(entityType,params)
export const deleteUser = (payload) => createDeleteMethod(entityType, payload)
export const createUser = (payload) => createPostMethod(entityType, payload)
export const updateUser = (payload) => createPutMethod(entityType, payload)
