import React, {useState} from 'react'
import BaseContent from '../../../components/Common/BaseContent'
import {Button, Col, Container, Row} from 'reactstrap'
import {motion} from 'framer-motion'
import SlideShowAnimateSVG from '../../../components/Common/SlideShowAnimateSVG'
import TimelineAnimateSVG from '../../../components/Common/TimelineAnimateSVG'
import ObjectsAnimateSVG from '../../../components/Common/ObjectsAnimateSVG'
import {Divider} from '@material-ui/core'
import {connect} from 'react-redux'
import {createStory, getAllStories, clearStories} from '../../../store/story/actions'
import axios from 'axios'
import {handleError} from '../../../store/core/api'
import {API_URL} from '../../../config'
import {getAxiosDefaultConfig} from '../../../utils'

const breadcrumbs = [
  {title: 'Stories', link: '/stories'},
  {title: 'New', link: '/stories/new'}
]


const storyTypeVariants = {
  initial: {
    scale: 0.9,
    backgroundColor: 'white', borderRadius: '20px', width: '300px', height: '320px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    border: 'none',
    transition: {
      type: 'tween'
    }
  },
  selected: {
    scale: 1,
    boxShadow: '',
    borderRadius: '10px', border: '2px solid rgb(224 224 224)',
    transition: {
      type: 'tween'
    }
  },
  hover: {
    scale: 1,
    transition: {
      delay: 0.3,
      type: 'tween',
      ease: 'easeOut'
    }
  }
}

const NewStory = (props) => {
  const [storyTitle, setStoryTitle] = useState('')
  const [storyType, setStoryType] = useState('')
  const [screenOptions,setScreenOptions] = useState({aspectRatio:null,orientation:''})
  const [screen,setScreen] = useState({width:0,height:0,slides:[]})

  const handleSelectedStoryType = (storyTypeVal) => {
    setStoryType(storyTypeVal)
  }

  const handleCreateStory = () => {
    // const newSlide = {
    //   id: makeid(),
    //   name: `Slide 1`,
    //   duration: 3000,
    //   background: {
    //     color: '#fff',
    //     position: '',
    //     uuid: '',
    //     size: ''
    //   },
    //   animation: {
    //     enterAnimation: null,
    //     leaveAnimation: null,
    //     editorSlideDuration: 5,
    //     enterAnimationDelay: 0,
    //     leaveAnimationDelay: 5000,
    //     durationEnter: 1000,
    //     durationLeave: 1000
    //   },
    //   elements: []
    // }
    const payload = {
      title: storyTitle,
      description: '',
      storyType: storyType,
      aspectRatio:screenOptions.aspectRatio,
      orientation:screenOptions.orientation,
      storyDetails: {
        screen: {
          width: screen.width,
          height: screen.height,
          slides: []
        },
      }
    }
    axios
      .post(`${API_URL}/stories`, payload, getAxiosDefaultConfig())
      .then((res) => {
        const data = res.data
        if (data.uuid)
          props.history.push(`/stories/edit/${data.uuid}`)
      })
      .catch(handleError);
  }

  const changeOrientation = (orientation) => {
    const newOrientation = {width: 0, height: 0}
    if (screenOptions.aspectRatio === '16:9')
    {
      newOrientation.width = 2560
      newOrientation.height = 1440
    }
    else
    {
      newOrientation.width = 2560
      newOrientation.height = 1920
    }

    if (orientation === 'LANDSCAPE') {
      setScreenOptions({...screenOptions, orientation: 'LANDSCAPE'})
      setScreen({...screen, width: newOrientation.width, height: newOrientation.height})
    }
    else {
      setScreenOptions({...screenOptions, orientation: 'PORTRAIT'})
      setScreen({...screen, width: newOrientation.height, height: newOrientation.width})
    }
  }

  const changeRatio = (ratio) =>{
    const newRatio = {width:0,height:0}

    if (ratio === '16:9') {
      newRatio.width = 2560
      newRatio.height = 1440
    }
    else if (ratio === '4:3')
    {
      newRatio.width = 2560
      newRatio.height = 1920
    }

    if (screenOptions.orientation === 'LANDSCAPE') {
      setScreen({...screen, width: newRatio.width, height: newRatio.height,})
      setScreenOptions({aspectRatio: ratio,orientation: 'LANDSCAPE'})
    }
    else {
      setScreen({...screen, width: newRatio.height, height: newRatio.width,})
      setScreenOptions({aspectRatio: ratio,orientation: 'PORTRAIT'})
    }
  }

  return <React.Fragment>
    <div
      style={{minHeight: '100vh', backgroundColor: 'rgb(243 243 243)'}}
    >
      <BaseContent
        breadcrumbs={breadcrumbs}
        isRoot={true}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className='text-muted font-size-13 mb-3'>Story Title</div>
            </Col>
            <Col lg={4} xs={12}>
              <input onChange={(e) => {
                setStoryTitle(e.target.value)
              }} className='form-control'/>
            </Col>
          </Row>
          <Row>
            <Col xs={10} className='mt-2 mb-2'>
              <Divider/>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className='text-muted font-size-13 mb-3'>Choose your story type</div>
            </Col>
          </Row>
          <Row>
            <motion.div className='col-xs-4 mr-2' variants={storyTypeVariants}
                        initial={'initial'}
                        animate={storyType === 'SLIDESHOW' ? 'selected' : 'initial'}
                        whileHover={'hover'}
                        style={{cursor: 'pointer'}}
                        onClick={() => handleSelectedStoryType('SLIDESHOW')}
            >
              <SlideShowAnimateSVG/>
              <div className='mt-2 ml-2' style={{fontSize: '16px', fontWeight: '600'}}>Slideshow</div>
              <div className='mt-2 ml-2' style={{fontSize: '12px', fontWeight: '300'}}>Use slideshow to present images
                and text in a carousel
              </div>
            </motion.div>
            <motion.div className='col-xs-4 mr-2' variants={storyTypeVariants}
                        initial={'initial'}
                        animate={storyType === 'TIMELINE' ? 'selected' : 'initial'}
                        whileHover={'hover'}
                        style={{cursor: 'not-allowed'}}

            >
              <TimelineAnimateSVG/>
              <div className='mt-2 ml-2' style={{fontSize: '16px', fontWeight: '600'}}>Timeline</div>
              <div className='mt-2 ml-2' style={{fontSize: '12px', fontWeight: '300'}}>Describe a story by using steps
                such as dates. Timeline walks along a line of steps showing different content
              </div>
            </motion.div>
            <motion.div className='col-xs-4 mr-2' variants={storyTypeVariants}
                        initial={'initial'}
                        animate={storyType === 'OBJECTS' ? 'selected' : 'initial'}
                        whileHover={'hover'}
                        style={{cursor: 'not-allowed'}}
            >
              <ObjectsAnimateSVG/>
              <div className='mt-2 ml-2' style={{fontSize: '16px', fontWeight: '600'}}>Objects and hotspots</div>
              <div className='mt-2 ml-2' style={{fontSize: '12px', fontWeight: '300'}}>Identical for 3D objects such as
                prehistoric statues
              </div>
            </motion.div>
          </Row>
          {storyType === 'SLIDESHOW' &&
          <>
            <Row className='justify-content-start align-items-center align-content-center mb-2'>
              <Col lg={12}>
                <div className='text-muted font-size-13 mt-2 mb-2'>Screen Type</div>
              </Col>
              <Col lg={2}>
                <div className='font-size-13 font-weight-light mb-2'>Orientation</div>
              </Col>
              <Col className={screenOptions?.orientation === 'LANDSCAPE' ? 'screenOptionSelected' : 'screenOption'}
                   onClick={() => changeOrientation('LANDSCAPE')}>
                <i className='bx bx-tv font-size-15'/>
                <p> Landscape </p>
              </Col>
              <Col className={screenOptions?.orientation === 'PORTRAIT' ? 'screenOptionSelected' : 'screenOption'}
                   onClick={() => changeOrientation('PORTRAIT')}>
                <i className='bx bx-tv font-size-15'/>
                <p> Portrait </p>
              </Col>
            </Row>
            <Row className='justify-content-start align-items-center align-content-center'>
              <Col lg={2}>
                <div className='font-size-13 font-weight-light mb-2 mt-2'>Aspect Ratio</div>
              </Col>
              <Col className={screenOptions?.aspectRatio === '16:9' ? 'screenOptionSelected' : 'screenOption'}
                   onClick={()=>changeRatio('16:9')}>
                <i className='bx bx-tv font-size-15'/>
                <p> 16:9 </p>
              </Col>
              <Col className={screenOptions?.aspectRatio === '4:3' ? 'screenOptionSelected' : 'screenOption'}
                   onClick={() =>changeRatio('4:3')}>
                <i className='bx bx-tv font-size-15'/>
                <p> 4:3 </p>
              </Col>
            </Row>
          </>
          }
          <Row className='d-flex flex-column justify-content-end align-items-end align-content-end'>
            <div className={'d-flex'}>
              <Button onClick={handleCreateStory} className='pull-right mt-2' color='primary' size='small'
                      disabled={storyType.length <= 0 || storyTitle.length <= 0 || storyTitle === '' || !screenOptions?.aspectRatio || !screenOptions?.orientation}>
                Next
              </Button>
            </div>
          </Row>
        </Container>
      </BaseContent>
    </div>
  </React.Fragment>
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createStory,
  clearStories,
  getAllStories
})(NewStory);
