import React from "react";
import {Badge, Col, Row} from "reactstrap";
import ReactJson from "react-json-view";
import {Link} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from "@material-ui/core/styles";

const MCheckbox = withStyles({
  disabled: {
    "&$checked": {
      // thumb when checked and disabled
      color: "#556ee6",
      padding: "0",
      margin: "0",
    },
  },
  checked: {},
})(Checkbox);

function Record({label, record, fullWidth}) {
  return (
    <Row className="task-dates mt-3">
      <Col xs={fullWidth ? "12" : "4"}>
        <p className="text-muted mb-0">{label}</p>
      </Col>
      <Col xs={fullWidth ? "12" : "8"}>{record}</Col>
    </Row>
  );
}

function TextRecord({label, value, showBadge, fullWidth, link, referenceLink}) {
  //   const record = showBadge ? (
  //     <Badge color={"primary"} className="font-size-14 font-weight-medium">
  //       {value}
  //     </Badge>
  //   ) : referenceLink ? (
  //     <Link to={`/containers/${value.value}`}>
  //       <h5 className="font-size-14" style={{color: "#4659d7"}}>
  //         {value.label} <i className="bx bx-link-external" />
  //       </h5>
  //     </Link>
  //   ) : (
  //     <h5 className="font-size-14">{value}</h5>
  // );
  //  return <Record label={label} record={record} fullWidth={fullWidth} />;

  // if (typeof value === "string" || value instanceof String) {
  //   const record = showBadge ? (
  //     <Badge color={"primary"} className="font-size-14 font-weight-medium">
  //       {value}
  //     </Badge>
  //   ) : referenceLink ? (
  //     <Link to={`/containers/${value.value}`}>
  //       <h5 className="font-size-14" style={{color: "#4659d7"}}>
  //         {value.label} <i className="bx bx-link-external" />
  //       </h5>
  //     </Link>
  //   ) : (
  //     <h5 className="font-size-14">{value}</h5>
  //   );
  //   return <Record label={label} record={record} fullWidth={fullWidth} />;
  // } else if (typeof value === "object" && !value.placename ) {
  //   let newValue = [...value];
  //   const record = !showBadge
  //     ? newValue.map((item) => {
  //         return (
  //           <>
  //             <Badge color={"primary"} className="font-size-14 font-weight-medium">
  //               {item.label}
  //             </Badge> {" "}
  //           </>
  //         );
  //       })
  //     : newValue.map((item) => {
  //         return (
  //           <>
  //             <h5 className="font-size-14">{item.label},</h5>
  //           </>
  //         );
  //       });

  //   return <Record label={label} record={record} fullWidth={fullWidth} />;
  // } else
  // {
  //   const record = showBadge ? (
  //     <Badge color={"primary"} className="font-size-14 font-weight-medium">
  //       {value}
  //     </Badge>
  //   ) : referenceLink ? (
  //     <Link to={`/containers/${value.value}`}>
  //       <h5 className="font-size-14" style={{color: "#4659d7"}}>
  //         {value.label} <i className="bx bx-link-external" />
  //       </h5>
  //     </Link>
  //   ) : (
  //     <h5 className="font-size-14">{value}</h5>
  //   );
  //   return <Record label={label} record={record} fullWidth={fullWidth} />;
  // }

  if (typeof value === "object" && value.length !== undefined) {
    let newValue = [...value];
    const record = !showBadge
      ? newValue?.map((item) => {
          return (
            <>
              <Badge color={"primary"} className="font-size-14 font-weight-medium">
                {item.label ? item.label : item}
              </Badge>{" "}
            </>
          );
        })
      : newValue?.map((item) => {
          return (
            <>
              <h5 className="font-size-14">{item.label},</h5>
            </>
          );
        });

    return <Record label={label} record={record} fullWidth={fullWidth} />;
  } else {
    const record = showBadge ? (
      <Badge color={"primary"} className="font-size-14 font-weight-medium">
        {value}
      </Badge>
    ) : referenceLink ? (
      <Link to={`/containers/${value.value}`}>
        <h5 className="font-size-14" style={{color: "#4659d7"}}>
          {value.label} <i className="bx bx-link-external" />
        </h5>
      </Link>
    ) : (
      <h5 className="font-size-14">{value}</h5>
    ); 
    return <Record label={label} record={record} fullWidth={fullWidth} />;
  }
}

function UrlRecord({label, value, fullWidth, link}) {
  const record = link ? (
    <a href={value} target="_blank" rel="noopener noreferrer">
      <h5 style={{color: "#4659d7"}} className="font-size-14">
        Go to URL
        <i className="bx bx-globe" />
      </h5>
    </a>
  ) : (
    <h5 className="font-size-14">{value}</h5>
  );

  return <Record label={label} record={record} fullWidth={fullWidth} />;
}

function JSONRecord({label, value}) {
  return <Record label={label} record={<ReactJson src={value} />} fullWidth={true} />;
}

// function ArrayRecord({label, value}) {
//   if (value && typeof value === "object") {
//     const record = value?.map((v) => {
//       return (
//         <Badge color={"primary"} key={v} className="font-size-14 font-weight-medium mr-2 mb-2">
//           {v}
//         </Badge>
//       );
//     });
//     return <Record label={label} record={record} />;
//   } else if (value === null || value === undefined) {
//     const record = "-";
//     return <Record label={label} record={record} />;
//   } else {
//     const record = value;
//     return <Record label={label} record={record} />;
//   }
// }
function ArrayRecord({label, value}) {
  if (value) {
    const record = value?.map((v) => {
      return (
        <Badge color={"primary"} key={v} className="font-size-14 font-weight-medium mr-2 mb-2">
          {v}
        </Badge>
      );
    });
    return <Record label={label} record={record} />;
  }  else {
    const record = "-";
    return <Record label={label} record={record} />;
  }
}
function BooleanRecord({label, value, showBadge, fullWidth, link, referenceLink}) {
  const record = showBadge ? <MCheckbox {...label} disabled checked={value} /> : <h5 className="font-size-14">{value}</h5>;
  return <Record label={label} record={record} fullWidth={fullWidth} />;
}

export {TextRecord, JSONRecord, ArrayRecord, Record, UrlRecord, BooleanRecord};
