import {
  createDeleteMethod,
  createGetAllMethod,
  createGetMethod,
  createPostMethod,
  createPutMethod,
} from '../core/factory';
import {entityType} from "./module";

export const getAllGroups = () => createGetAllMethod(entityType)
export const getGroup = (params) => createGetMethod(entityType, params)
export const deleteGroup = (payload) => createDeleteMethod(entityType, payload)
export const createGroup = (payload) => createPostMethod(entityType, payload)
export const updateGroup = (payload) => createPutMethod(entityType, payload)
