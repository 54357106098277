import React, {useEffect, useState} from 'react';
import BaseContent from '../../../components/Common/BaseContent';
import axios from 'axios';
import {API_URL} from '../../../config';
import {getAxiosDefaultConfig} from '../../../utils';
import {Col, Container, Row} from 'reactstrap';
import PaginationComponent from 'react-reactstrap-pagination';
import {useLocation} from 'react-router';
import StoriesList from './StoriesList'
import StoriesFilter from './components/StoriesFilter'
import ListToggle from '../../../components/Common/ListToggle'
import {useListToggle} from '../../../helpers/hooks'
import StoriesTableList from './components/StoriesTableList'

const breadcrumbs = [{title: 'Stories', link: '/stories'}]
const listColumns = ['Name','Description','Tags','Aspect Ratio','Orientation','Updated at']


function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Stories = (props) => {

  const {browser, selectStory, selectedStory} = props

  let query = useQuery()

  const pageVar = query.get('page')
  const [page, setPage] = useState(pageVar ? pageVar : 0)
  const [sortFilter, setSortFilter] = useState('title')
  const [sortBy, setSortBy] = useState('DESC')
  const [stories, setStories] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [breadcrumbsStories, setBreadCrumbsStories] = useState(breadcrumbs)
  const [isList, toggleList] = useListToggle();

  const getAllStories = (page = 0, searchQuery = '') => {
    setIsLoading(true)
    axios.get(`${API_URL}/stories?page=${page}&q=${encodeURIComponent(searchQuery)}&sort=${sortFilter},${sortBy}`, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setStories(data);
        setBreadCrumbsStories([{
          title: `Stories(${data?.totalElements})`
        }])
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false);
      })
  }

  useEffect(() => {
    getAllStories(page, '')
    // eslint-disable-next-line
  }, [page, sortBy, sortFilter])

  const handleSelectPage = (page) => {
    setPage(page - 1)
  }

  return (
    <BaseContent
      // isLoading={isLoading||!websites}
      breadcrumbs={breadcrumbsStories}
      page={!browser}
      busy={isLoading}
      renderActions={() => <ListToggle isList={isList} toggle={toggleList}/>}
    >
      <Container style={{paddingTop: 30, minHeight: '120px'}}>
        <StoriesFilter getAllStories={getAllStories} sortBy={sortBy} setSortBy={setSortBy} sortFilter={sortFilter}
                       setSortFilter={setSortFilter}/>
      </Container>

      {stories && <Container style={{paddingTop: 30, minHeight: '55vh'}}>
        {!isList &&
        <StoriesList result={stories.content} isLoading={isLoading} browser={browser} selectedStory={selectedStory}
                     selectStory={selectStory}/>
        }

        {isList &&
        <StoriesTableList browser={browser} selectedStory={selectedStory} selectStory={selectStory}
                          columns={listColumns} result={stories.content} isLoading={isLoading} {...props}/>
        }

        <Row>
          <Col xs={{size: 6, offset: 6}} className='listing-navigation'>
            <PaginationComponent totalItems={stories.totalElements} pageSize={stories.pageable.pageSize}
                                 onSelect={handleSelectPage}/>
          </Col>
        </Row>

      </Container>}

    </BaseContent>
  )
}

export default Stories
