import {Button, ButtonGroup, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {useState} from "react";
import React from 'react'
import {
  withRouter
} from "react-router-dom";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {FIELD_DEFAULTS, SURVEY_TYPES, SURVEY_TYPES_LIST} from "./consts";
import FieldRenderer from "./FieldRenderer";
import {reorder} from "../../../utils";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";

const selectedColor = '#556ee6'

const SelectedIndicator = () => <div style={
  {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 8,
    backgroundColor: `${selectedColor}`,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2
  }
}>
</div>

const AddNewField = ({sectionIndex, questionIndex, addNew}) => {

  return <div className="col-md-10 col-xs-12 offset-md-1 pr-5 pl-5" onClick={()=>addNew(sectionIndex,questionIndex)}>
    <div style={{position: 'relative'}}>
      <div className="card survey-field-box survey-field-add-new" style={{
        position: 'relative',
        marginBottom: 15,
        boxSizing: 'border-box',
        overflow: 'hidden',
        boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 6px -1px'
      }}>

        <div className="card-body">
          <div className="d-flex justify-content-center align-content-center align-items-center">
            <div className='d-flex'>
              <i className='bx bx-plus' style={{fontSize:36}}></i>
            </div>
            <div className='d-flex'>
              &nbsp;ADD NEW FIELD
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
}

const Form = (props) => {

  const {fields, setFields, survey, setSurvey, disabled} = props;
  const {label, description, surveyType, openAt, closeAt, submissionMessage} = survey;
  const [selected, setSelected] = useState(-1)

  const changeLabel = (e) => {
    const value = e.target.value
    setSurvey({...survey, label: value})
  }

  const changeDescription = (e) => {
    const value = e.target.value
    setSurvey({...survey, description: value})
  }

  const changeType = (e) => {
    const value = e.target.value
    setSurvey({...survey, surveyType: value})
  }

  const changeMessage = (e) => {
    const value = e.target.value
    setSurvey({...survey, submissionMessage: value})
  }

  const changeSectionTitle = (e,index) => {
    const value = e.target.value
    const sections = [...survey.sections]
    sections[index].title = value
    setSurvey({...survey, sections})
  }

  const changeSectionDescription = (e,index) => {
    const value = e.target.value
    const sections = [...survey.sections]
    sections[index].description = value
    setSurvey({...survey, sections})
  }

  // Sets question as selected (preview / edit mode)
  const selectQuestion = (i) => {
    setSelected(i)
    // const titleField = document.getElementById('title')
    // if (titleField) titleField.focus()
  }

  // Add new using defaults (short answer)
  const addNew = (sectionIndex, questionIndex) => {
    const fieldId = `field-${new Date().valueOf()}`
    const sections = [...survey.sections]
    const questions = [...survey.questions]
    const newIndex = questionIndex===0?questions.length:questionIndex
    sections[sectionIndex].questions.push(fieldId)

    questions.splice(newIndex, 0,{
      ...FIELD_DEFAULTS.SHORT_ANSWER,
      id: fieldId
    })

    setSurvey({
      ...survey,
      sections,
      questions
    })

    setSelected(fieldId)
  }

  // Add new section
  const addNewSection = () => {
    const sectionId = `section-${new Date().valueOf()}`

    const sections = [...survey.sections]

    sections.push({
      id: sectionId,
      title: '',
      questions: []
    })

    setSurvey({
      ...survey,
      sections
    })

    setSelected(fields.length)
  }

  // Duplicate Field
  const duplicateField = (fieldIndex) => {
    const nFields = [...fields]
    nFields.splice(fieldIndex, 0, {...nFields[fieldIndex],id: `field-${new Date().valueOf()}`})
    setFields(nFields)
  }

  // Delete field
  const deleteField = (fieldIndex, sectionIndex, sectionFieldIndex) => {
    const nFields = [...fields]
    const sections = [...survey.sections]
    console.log(sections)

    sections[sectionIndex].questions.splice(sectionFieldIndex, 1)
    nFields.splice(fieldIndex, 1)
    // setFields(nFields)
    setSelected(-1)
    setSurvey({
      ...survey,
      questions: nFields,
      sections
    })
  }

  // Will change the field
  const setField = (i, data) => {
    const nFields = [...fields]
    nFields[i] = data
    setFields(nFields)
  }

  const goUp = (e, index, sectionIndex) => {
    e.preventDefault()
    e.stopPropagation()
    if (sectionIndex===0) return false
    const selectedField = fields[index].id
    const newIndex = sectionIndex-1
    setFields(reorder(
      fields,
      index,
      newIndex,
    ))
    setSelected(selectedField)
  }

  const goDown = (e,index, sectionLength) => {
    e.preventDefault()
    e.stopPropagation()
    if (index===sectionLength.length-1) return false
    const newIndex = index+1
    const selectedField = fields[index].id
    setFields(reorder(
      fields,
      index,
      newIndex,
    ))
    setSelected(selectedField)
  }

  const goUpSection = (e,index) => {
    e.preventDefault()
    e.stopPropagation()
    if (index===0) return false
    const newIndex = index-1
    setSurvey({
      ...survey,
      sections: reorder(
        survey.sections,
        index,
        newIndex,
      )
    })
  }

  const goDownSection = (e,index) => {
    e.preventDefault()
    e.stopPropagation()
    if (index===survey.sections.length) return false
    const newIndex = index+1
    setSurvey({
      ...survey,
      sections:reorder(
        survey.sections,
        index,
        newIndex,
      )})
  }

  // Handle re-ordering on dnd
  const onDragEnd = (result) => {

    const {source,destination} = result

    // dropped outside the list
    if (!destination) {
      return
    }

    // If not in the same section
    if (source.droppableId !== destination.droppableId) {

      // Get section IDs
      const fromSection = source.droppableId.split('_')[1]
      const toSection = destination.droppableId.split('_')[1]

      // Find position in sections
      const fromSectionPos = survey.sections.findIndex((s)=>s.id===fromSection)
      const toSectionPos = survey.sections.findIndex((s)=>s.id===toSection)

      const newSections = [...survey.sections]

      // Add to destination section
      newSections[toSectionPos].questions.push(fields[source.index].id)

      // Remove from original section
      newSections[fromSectionPos].questions.splice(newSections[fromSectionPos].questions.findIndex((q)=>q===fields[source.index].id),1)

      // Update sections
      setSurvey({
        ...survey,
        sections: [...newSections]
      })

    }


    // Keep the one dropped, selected
    if (selected === source.index) {
      setSelected(destination.index)
    }

    // In case it replaces a selected one, change which one is selected
    if (selected === destination.index) {
      setSelected(source.index)
    }

    setFields(reorder(
      fields,
      source.index,
      destination.index
    ))
  }

  const changeOpenAt = (datetime) => {
    setSurvey({
      ...survey,
      openAt: datetime.toISOString()
    })
  }

  const changeCloseAt = (datetime) => {
    setSurvey({
      ...survey,
      closeAt: datetime.toISOString()
    })
  }

  const openDays = (current) => {
    let closeDay = moment(survey.closeAt)
    return current.isBefore(closeDay)
  }

  const closeDays = (current) => {
    let openDay = moment(survey.openAt)
    return current.isAfter(openDay)
  }

  const createMarkup = (htmlcode) => {
    return {__html: htmlcode}
  }

  return <Row>
    <Col xs={12} md={{size: 10, offset: 1}} style={{position: 'relative', marginBottom: 15}} onClick={() => selectQuestion(-1)}>
      <div className="card survey-field-box">
        {selected === -1 && <SelectedIndicator/>}

        <div className="card-body" style={{textAlign: 'left'}}>

          {selected === -1 && <Row xs={12}>

            <Col xs={12} sm={8}>
              <FormGroup>
                <Label for='label'>Survey name*</Label>
                <Input id='label' name='label' placeholder="Untitled survey" value={label} onChange={changeLabel} autoFocus={true} />
                <div style={{fontSize: 12, marginTop: 4, opacity: 0.8}}>This will be shown at the top of the survey</div>
              </FormGroup>
            </Col>

            <Col xs={12} sm={4}>
              <FormGroup>
                <Label for={`dropdown-list-form-type`}> Survey type  </Label>
                <Input type="select" name={`dropdown-list-form-type`} id={`dropdown-list-form-type`} value={surveyType} disabled={disabled} onChange={changeType}>
                  {SURVEY_TYPES_LIST.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
                </Input>
                <div style={{fontSize: 12, opacity: 0.8, marginTop: 4}}>
                  {surveyType===SURVEY_TYPES.ANONYMOUS&&'Allow anyone to respond'}
                  {surveyType===SURVEY_TYPES.EMAIL_ONLY&&'Require an e-mail to respond'}
                  {surveyType===SURVEY_TYPES.USER_DETAILS&&'Require personal info to respond'}
                  {surveyType===SURVEY_TYPES.SIGNED_URL&&'Allow anyone with a link to respond'}
                </div>
              </FormGroup>
            </Col>

            <Col xs={12} sm={9}>
              <FormGroup>
                <Label for={'description'}> Description </Label>
                <Input type='textarea' id='description' name={'description'} placeholder="Description" value={description} onChange={changeDescription}/>
              </FormGroup>
            </Col>

            <Col xs={12} sm={3}>

              <FormGroup>
              <Label for={`date-openFrom`}> Open date </Label>
                <Datetime value={new Date(openAt)} onChange={changeOpenAt} isValidDate={openDays}/>
              </FormGroup>
            </Col>

            <Col xs={12} sm={9}>
              <FormGroup>
                <Label for={'submission-message'}> Submission message </Label>
                <Input type='textarea' id='submission-message' name={'submission-message'} placeholder="Submission message" value={submissionMessage} onChange={changeMessage}/>
              </FormGroup>
            </Col>

            <Col xs={12} sm={{size:3}}>
              <FormGroup>
                <Label for={`date-openFrom`}> Close date </Label>
                <Datetime value={new Date(closeAt)} onChange={changeCloseAt} isValidDate={closeDays} />
              </FormGroup>
            </Col>

          </Row>}

          {selected !== -1 && <>
            <h2>{label}</h2>
            <h5>{description}</h5>
          </>}

        </div>

      </div>

    </Col>
    <DragDropContext onDragEnd={onDragEnd}>
    {
      survey.sections&&survey.sections.map((section,sectionIndex)=>{
        const sectionFields = fields.filter((f)=>section.questions.includes(f.id))
        const isSelected = selected === section.id

        return <>
          <Col xs={12} md={{size: 10, offset: 1}} style={{position: 'relative'}} onClick={() => selectQuestion(section.id)}>

            <div className="card survey-field-box section-box" style={{marginBottom: 15}}>

              {isSelected && <SelectedIndicator/>}
              {isSelected&&<div style={{width:80, position:'absolute', marginLeft: -35}}>
                <ButtonGroup vertical>
                  <Button style={{padding: '2px 5px'}} onClick={(e)=>goUpSection(e,sectionIndex)}><i className='bx bxs-chevron-up'></i></Button>
                  <Button style={{padding: '2px 5px'}} onClick={(e)=>goDownSection(e,sectionIndex)}><i className='bx bxs-chevron-down'></i></Button>
                </ButtonGroup>
              </div>}

              <div className="card-body" style={{textAlign: 'left'}}>

                {!isSelected&&<>
                <h3>{section.title?section.title:`Untitled Section #${sectionIndex+1}`}</h3>
                {!section.title&&<h6 style={{whiteSpace: "pre-wrap"}}>Click to add a title, if needed.</h6>}
                {!section.description&&<h6 style={{whiteSpace: "pre-wrap"}}>Click to add a description, if needed.</h6>}
                {section.description&&<h6 style={{whiteSpace: "pre-wrap"}}>
                  <div dangerouslySetInnerHTML={createMarkup(section.description)} />
                </h6>}
              </>}

                {isSelected&&<>
                  <Col xs={12} sm={9}>
                    <FormGroup>
                      <Label for={'title'}> Title </Label>
                      <Input type='text' id='title' name={'title'} placeholder="Title" value={section.title} onChange={(e)=>changeSectionTitle(e,sectionIndex)} autoFocus={true}/>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={9}>
                    <FormGroup>
                      <Label for={'description'}> Description </Label>
                      <Input type='textarea' id='description' name={'description'} placeholder="Description" value={section.description} onChange={(e)=>changeSectionDescription(e,sectionIndex)} autoFocus={section.title!==''&&section.description===''}/>
                    </FormGroup>
                  </Col>
                </>}

            </div>


          </div>
        </Col>


            <Droppable droppableId={`droppable_${section.id}`}>
              {(provided) => (
                <div className="col-md-10 col-xs-12 offset-md-1 pl-4 pr-4" {...provided.droppableProps} ref={provided.innerRef}>
                  {sectionFields&&sectionFields.map((field, sectionFieldIndex) => {
                    const isSelected = selected === field.id
                    const index = fields.findIndex((f)=>f.id===field.id)
                    const questionsSoFar = survey.sections.slice(0,sectionIndex).reduce((total=0, s)=>total+s.questions.length,0)
                    const lastOneSelected = isSelected&&(sectionFieldIndex===sectionFields.length-1)

                    return <Draggable key={`${field.type}-${index}`} draggableId={`${field.type}-${index}`} index={index}>
                      {(provided,snapshot) => (<div onClick={() => selectQuestion(field.id)} key={index} {...provided.draggableProps}
                                           ref={provided.innerRef}>

                          {isSelected&&<div style={{width:80, position:'absolute', marginLeft: -35}}>
                            <ButtonGroup vertical>
                              <Button style={{padding: '2px 5px'}} onClick={(e)=>goUp(e,index,sectionFieldIndex)}><i className='bx bxs-chevron-up'></i></Button>
                              <Button style={{padding: '2px 5px'}} onClick={(e)=>goDown(e,index,section.questions.length)}><i className='bx bxs-chevron-down'></i></Button>
                            </ButtonGroup>
                          </div>}

                          <div style={{position: 'relative'}}>
                            <div className="card survey-field-box" style={{
                              position: 'relative',
                              marginBottom: 15,
                              boxSizing: 'border-box',
                              overflow: 'hidden',
                              boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 6px -1px'
                            }}>

                              <div {...provided.dragHandleProps} className='survey-dnd-handle' style={{color: '#5c5c5c', fontSize: 18, marginBottom: 2, textAlign: 'center'}}>
                                <div style={{marginBottom: -22}}><b>...</b></div>
                                <div><b>...</b></div>
                              </div>

                              {isSelected && <SelectedIndicator/>}

                              <div className="card-body">
                                <FieldRenderer field={{...field, index}} setField={(data) => setField(index, data)} selected={isSelected}
                                               answerable={false} duplicateField={() => duplicateField(index)}
                                               deleteField={() => deleteField(index, sectionIndex, sectionFieldIndex)} key={field.id} formDisabled={disabled} fieldNumber={questionsSoFar+sectionFieldIndex} />
                              </div>

                            </div>

                          </div>

                        {isSelected&&!lastOneSelected&&!snapshot.isDragging&&<>

                          {snapshot.isDragging&&<>Is dragging </>}

                          <div className="col-md-10 col-xs-12 offset-md-1" onClick={()=>addNew(sectionIndex, index)}>
                            <div style={{position: 'relative'}}>
                              <div className="card survey-field-box survey-field-add-new" style={{
                                position: 'relative',
                                marginBottom: 15,
                                boxSizing: 'border-box',
                                overflow: 'hidden',
                                boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 6px -1px'
                              }}>

                                <div className="card-body">
                                  <div className="d-flex justify-content-center align-content-center align-items-center">
                                    <div className='d-flex'>
                                      <i className='bx bx-plus' style={{fontSize:36}}></i>
                                    </div>
                                    <div className='d-flex'>
                                      &nbsp;ADD NEW FIELD
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                        </>}

                        </div>
                      )}
                    </Draggable>
                  })}
                  {provided.placeholder}
                </div>)}
            </Droppable>

          {<div className='col-md-10 col-xs-12 offset-md-1 pr-1 pl-1'>
            <AddNewField sectionIndex={sectionIndex} questionIndex={0} addNew={addNew} />
          </div>}

        </>

      })

    }
    </DragDropContext>

    <div className="col-md-10 col-xs-12 offset-md-1 mt-5" onClick={addNewSection}>
      <div style={{position: 'relative'}}>
        <div className="card survey-field-box survey-field-add-new" style={{
          position: 'relative',
          marginBottom: 15,
          boxSizing: 'border-box',
          overflow: 'hidden',
          boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 6px -1px'
        }}>

          <div className="card-body">
            <div className="d-flex justify-content-center align-content-center align-items-center">
              <div className='d-flex'>
                <i className='bx bx-plus' style={{fontSize:36}}></i>
              </div>
              <div className='d-flex'>
                &nbsp;ADD NEW SECTION
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>



  </Row>
}

export default withRouter(Form);
