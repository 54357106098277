import React from "react";
import {OptionCard} from "./OptionCard";
import {Col, Row} from "reactstrap";

const CardSelect = (props) => {
  return (
    <Row className={`flex-${props.orientation || "row"}`}>
      {props.options.map((option,index) => (
        <Col key={index}>
          <OptionCard
            option={option}
            handleClick={props.onChange}
            disabled={option.isDisabled || props.isDisabled}
            checked={(props.value && props.value.value) === option.value}
          />
        </Col>
      ))}
    </Row>
  );
};

export default CardSelect;
