import React   from 'react';
import {Card, CardBody, CardSubtitle, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {SURVEY_TYPES_LIST} from "../consts";

const SurveyItem = ({survey}) => {
  const {label, description,uuid, locked, closeAt, openAt, surveyType} = survey

  const closeDate = new Date(closeAt+'Z')
  const openDate = new Date(openAt+'Z')
  const nowDate = new Date()
  const dateExpired = !(closeDate > nowDate)
  const isOpen = !locked && !dateExpired
  const willOpen = (openDate>nowDate)
  const willClose = (closeDate>nowDate)

  return <Card className='c-item'>
    <Link to={`/surveys/edit/${uuid}`} style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}> </Link>
    <CardBody>

      {isOpen&&<h5>
        <span className="badge badge-success">OPEN</span> {' '}
        <span className="badge badge-secondary font-size-10">{surveyType&&SURVEY_TYPES_LIST.find((s)=>s.value===surveyType).label}</span>
      </h5>}

      {!isOpen&&<h5><span className="badge badge-danger">CLOSED</span>
        {' '}
        {dateExpired&&!locked&&<span className="badge badge-info font-size-10">Expired</span>}
        {locked&&<span className="badge badge-info font-size-10">Not accepting responses</span>}
        {' '} <span className="badge badge-secondary font-size-10">{surveyType&&SURVEY_TYPES_LIST.find((s)=>s.value===surveyType).label}</span>
      </h5>}

      <CardTitle tag="h4">{label}</CardTitle>
      <CardSubtitle style={{minHeight:80}}>{description}</CardSubtitle>

      {willOpen&&<h6><span className="font-size-10"> Opens at {moment(openDate).format("DD/MM/YYYY, h:mm a")}  </span></h6>}
      {willClose&&<h6><span className="font-size-10"> Closes at {moment(closeDate).format("DD/MM/YYYY, h:mm a")}  </span></h6>}

    </CardBody>
  </Card>
};

export default SurveyItem;
