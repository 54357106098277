import React, {useCallback, useEffect, useMemo, useState} from 'react';
import BaseContent from "../../../components/Common/BaseContent";
import { connect } from "react-redux";
import { Schema } from "../../../store/core/schema";
import { EntityType } from "../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import CardSettings from "../../../components/Common/card-settings";
import {useListToggle, useToggle} from "../../../helpers/hooks";
import {
  createGroup,
  deleteGroup,
  getGroup,
  updateGroup,
} from '../../../store/group/actions';
import ListToggle from "../../../components/Common/ListToggle";
import ListSettings from "../../../components/Common/ListSettings";
import {getAllMyPermissions} from '../../../store/myPermissions/actions'

const entityType = EntityType.Group;

const breadcrumbs = [
  { title: "Settings", link: "/settings" },
  { title: "Groups", link: "#" },
];

const Groups = (props) => {
  const { context, createGroup, deleteGroup,getGroup,getAllMyPermissions,updateGroup } = props;
  const { result, isLoading, error } = context.Group.getAll;
  const currentGroup = context.Group.get.result;
  const groupCreated = context.Group.create
  const groupUpdated = context.Group.update
  const [openModal, toggleModal] = useToggle(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const groupIdParam = props.match.params.id
  const routedGroup = useMemo(() => {
    return  result.find(x => x.id.toString() === groupIdParam);
  }, [result, groupIdParam])
  const [isList, toggleList] = useListToggle();

  const handleEntityClick = useCallback(
    (entity) => {
      setEntity(entity);
      toggleModal();
      getGroup(entity.id)
    },
    //eslint-disable-next-line
    [toggleModal]
  );

  useEffect(() => {
    if (routedGroup) {
      setEntity(routedGroup)
      toggleModal()
    } else {
      setEntity(currentGroup);
    }
  }, [currentGroup, routedGroup, toggleModal])

  useEffect(()=>{
    if (groupCreated?.successResult)
    {
      getAllMyPermissions()
    }
    //eslint-disable-next-line
  },[groupCreated])

  useEffect(()=>{
    if (groupUpdated?.successResult)
    {
      getAllMyPermissions()
    }
    //eslint-disable-next-line
  },[groupUpdated])

  const handleCreate = useCallback(() => {
    setEntity(Schema[entityType]);
    toggleModal();
  }, [toggleModal]);

  const handleUpdate = useCallback((p)=>{
    //we need only code object for iamPermissions and uuid from users object
    const payload = {...p,iamPermissions: p.iamPermissions.map(p=>p.code),users:p.users.map(p=>p.uuid)}
    updateGroup(payload)
    //eslint-disable-next-line
  },[toggleModal])

  const handleCreateGroup = useCallback((p)=>{
    const payload = {name:p.name,description:p.description,users:p.users.map(p=>p.uuid)}
    createGroup(payload)
    //eslint-disable-next-line
  },[toggleModal])

  const cards = useMemo(() => {
    return result.map((group) => ({
        label: group.name,
        icon: "bx-user",
        handleClick: () => handleEntityClick(group),
      }));
  }, [handleEntityClick, result]);

  const list = useMemo(() => {
    return result.map((group) => ({
      label: group.name,
      handleClick: () => handleEntityClick(group),
    }));
  }, [handleEntityClick, result]);

  const listColumns = [
    { name: "Name", prop: "label" },
  ];

  const handleClose = () => {
    toggleModal()
    props.history.push("/settings/groups")
  }

  return (
    <>
      <BaseContent
        title={"Users"}
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
      >
        {!isList && <CardSettings settings={cards} />}
        {isList && <ListSettings contents={list} columns={listColumns} />}
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={openModal}
          extraClassName={"modal-full-height"}
          handleClose={handleClose}
          createEntity={handleCreateGroup}
          updateEntity={handleUpdate}
          deleteEntity={deleteGroup}
          context={context.Group}
          currentGroup={currentGroup}
          allUsers = {context.User.getAll.result}
          allPermissions = {context.Permissions.getAll.result}
          size={'lg'}
          tabs={[
            {title: 'Group Info'},
            {title: 'Members'},
            {title: 'Permissions',disabled:!entity.id},
          ]}
        />
      </BaseContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createGroup,
  updateGroup,
  deleteGroup,
  getGroup,
  getAllMyPermissions,
})(Groups);
