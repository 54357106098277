import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = ({options}) => {
  const optionsLabels = options.map((o)=>o.label)
  const optionsData = options.map((o)=>o.value)
    const data = {
      labels: optionsLabels,
      datasets: [
        {
          data: optionsData,
          backgroundColor: [
            "#556ee6",
            "#ebeff2",
            "#99e655",
            "#905eca",
            '#b4d002',
            '#f9e4bf',
            '#ddb98d',
            '#ff4c00',
            '#8f3113',
            '#405cd8',
              '#66edb8',
            '#e3b766',
            '#b35109',
            '#cb0a4b'

          ],
          hoverBackgroundColor: [
            "#556ee6",
            "#ebeff2",
            "#99e655",
            "#905eca",
            '#b4d002',
            '#f9e4bf',
            '#ddb98d',
            '#ff4c00',
            '#8f3113',
            '#405cd8',
            '#66edb8',
            '#e3b766',
            '#b35109',
            '#cb0a4b'
          ],
          hoverBorderColor: "#fff"
        }]
    }
    return (
      <React.Fragment>
        <Doughnut width={474} height={260} data={data}/>
      </React.Fragment>
    );
}

export default DoughnutChart;