import React, {useEffect, useState} from "react";
import {FIELD_DEFAULTS} from "../consts";
import ShortAnswer from "../fields/ShortAnswer";

export const OrganizationTypeField = (props) => {
  const {setOrganization, organization, formDisabled} = props

  const [field,setField] = useState({
    ...FIELD_DEFAULTS.SHORT_ANSWER,
    required: true,
    title: 'Organization',
    value: organization
  })

  useEffect(()=>{
    if (field) {
      if (field.errors&&field.errors.required) {
        setOrganization(null)
      } else {
        setOrganization(field.value)
      }
    }
    // eslint-disable-next-line
  },[field])

  return <ShortAnswer field={field} setField={setField} answerable={!organization} standalone formDisabled={formDisabled} />
}
