import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {useFetch, useForm} from '../../../../helpers/hooks';
import EditDialog from '../../../../components/Common/EditDialog';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';
import {ApiEndpoint} from '../../../../store/core/endpoint';
import {EntityType} from '../../../../store/core/entityType';
import VocabularyProperty from './components/VocabularyProperty'

const PropertyDialog = (props) => {
  const {entity, namespaces, qualifiers} = props;
  const url = ApiEndpoint[EntityType.ContainerType];
  const [, containerTypesData] = useFetch(url, []);
  const [form, handleChange, updateInitial, handleChangeArray] = useForm({});
  const [enumValues, setEnumValues] = useState([]);
  const [multiProp, setMultiProp] = useState([]);
  const [mapValues, setMapValues] = useState();
  const [referenceData, setReference] = useState([]);
  const [referenceOptions, setReferenceOptions] = useState([]);

  const [mapLat, setMapLat] = useState('');
  const [mapLong, setMapLong] = useState('');

  const [selectedQualifiers, setSelectedQualifiers] = useState([])
  const [qualifiersPayload,setQualifiersPayload]=useState([])

  useEffect(() => {
    const referenceOptions = containerTypesData.map((containerType) => ({
      key: containerType.code,
      label: containerType.name,
      value: containerType.code,
    }));
    setReferenceOptions(referenceOptions);
    // eslint-disable-next-line
  }, [containerTypesData]);

  useEffect(() => {
    updateInitial(entity);
  }, [entity, updateInitial]);

  const propertyTypesTypeOptions = props.propertyTypes.map((property) => ({
    label: property.name,
    value: property.code,
    languageAware: property.languageAware
  }));

  const selectedPropertyType = propertyTypesTypeOptions.find(
    (x) => x.value === form.propertyType
  );

  const namespacesOptions = namespaces.map((namespace) => ({
    label: namespace.name,
    value: namespace.code,
  }));

  const selectedNamespace = namespacesOptions.find(
    (x) => x.value === form.namespace
  );


  useEffect(() => {
    const enumPayload = {values: enumValues};
    handleChangeArray('propertyDetails', JSON.stringify(enumPayload));
    // eslint-disable-next-line
  }, [enumValues]);

  useEffect(() => {
    if (referenceData && referenceData.length > 0) {
      const referenceContainerTypes = referenceData.map((reference) => {
        return reference.value;
      });
      const referencePayload = {containerTypes: referenceContainerTypes};
      handleChangeArray('propertyDetails', JSON.stringify(referencePayload));
    }
    // eslint-disable-next-line
  }, [referenceData]);

  useEffect(() => {
    const multiPropPayload = {values: multiProp};
    handleChangeArray('propertyDetails', JSON.stringify(multiPropPayload));
    // eslint-disable-next-line
  }, [multiProp]);

  const handleSelectMap = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({lat, lng}) => {
        setMapLat(lat);
        setMapLong(lng);
        const spatialMapPayload = {
          values: {placename: address, langitude: lat, longitude: lng},
        };
        handleChangeArray('propertyDetails', JSON.stringify(spatialMapPayload));
      })
      .catch((error) => console.error(error));
  };
  useEffect(()=>{
    setQualifiersPayload(Object.keys(selectedQualifiers)
      .filter(key=>{return selectedQualifiers[key]})
      .map(String))
    //handleChangeArray('qualifiers', qualifiersPayload)
  },[selectedQualifiers])

  useEffect(()=>{
    handleChangeArray('qualifiers', qualifiersPayload);
    // eslint-disable-next-line
  },[qualifiersPayload])

  useEffect(() => {
    setSelectedQualifiers([])
    setMultiProp([])
    if (entity.qualifiers && entity.qualifiers.length > 0)
    {
      setQualifiersPayload(entity.qualifiers)
      const initialValuesOfQualifiers = []
      entity.qualifiers.forEach((qualifier)=>{
        initialValuesOfQualifiers[qualifier]=true
      })
      setSelectedQualifiers(initialValuesOfQualifiers)
    }

    if (entity.propertyType) {
      setEnumValues([])
      if (entity.propertyDetails && entity.propertyDetails.length > 0) {
        if (entity.propertyType === 'reference') {
          const reference = JSON.parse(entity.propertyDetails);
          const selectedValues = [];
          reference.containerTypes.forEach((referenceItem) => {
            const referenceName = containerTypesData.find(
              (x) => x.code === referenceItem
            );
            selectedValues.push({
              value: referenceItem,
              label: referenceName.name,
            });
          });
          setReference(selectedValues);
        } else if (entity.propertyType === 'spatial-map') {
          const mapValues = JSON.parse(entity.propertyDetails);

          setMapValues(mapValues.values.placename);
          setMapLong(mapValues.values.longitude);
          setMapLat(mapValues.values.langitude);
        } else if (entity.propertyType === 'multi-select') {
          const multiProp = JSON.parse(entity.propertyDetails);
          setMultiProp(multiProp.values);
        } else if (entity.propertyType === 'enum') {
          const enumValues = JSON.parse(entity.propertyDetails);
          setEnumValues(enumValues.values);
        }
      }
    }
    // eslint-disable-next-line
  }, [entity]);

  const handleQualifierChange = (qualifier) => {
    setSelectedQualifiers({...selectedQualifiers,[qualifier.qualifierKey]:!selectedQualifiers[qualifier.qualifierKey]})
  };

  const customSelectStyle = {
    menu: (provided) => ({
      ...provided,
      minHeight: '180px',
      maxHeight: '220px',
      height: 'auto'
    }),
    menuList: (provided) => ({
      ...provided,
      minHeight: '160px',
      maxHeight: '180px',
      height: 'auto'
    }),
  }


  return (
    <EditDialog {...props} size={'lg'} form={form}>
      <form>
        <Row>
          <Col sm={7}>
            <FormGroup>
              <Label>Label</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Enter label"
                value={form.displayName}
                onChange={handleChange('displayName')}
              />
            </FormGroup>
            <FormGroup>
              <Label>Description</Label>
              <div className='ml-2 font-size-10 text-muted d-lg-inline'>{`(${form.description && form.description.length > 0 ? form.description.length : 0}/255)`}</div>
              <Input
                type="textarea"
                className="form-control"
                placeholder="Enter description"
                value={form.description}
                onChange={handleChange('description')}
                maxLength='255'
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label>Type</Label>
              <Select
                styles={customSelectStyle}
                value={selectedPropertyType}
                onChange={handleChange('propertyType')}
                options={propertyTypesTypeOptions}
                placeholder="Select property type"
              />
            </FormGroup>
            <FormGroup>
              <Label>Property Code</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Enter key"
                value={form.propertyKey}
                onChange={handleChange('propertyKey')}
              />
            </FormGroup>
            {/*<FormGroup>*/}
            {/*  <div*/}
            {/*    className="custom-control custom-switch mb-2 mr-3 z-depth-0"*/}
            {/*    dir="ltr"*/}
            {/*  >*/}
            {/*    <input*/}
            {/*      type="checkbox"*/}
            {/*      className="custom-control-input"*/}
            {/*      id="languageAware"*/}
            {/*      checked={languageToggle}*/}
            {/*      value={languageToggle}*/}
            {/*      onClick={handleLanguageAware}*/}
            {/*      disabled={selectedPropertyType && !selectedPropertyType.languageAware}*/}
            {/*    />*/}
            {/*    <label className="custom-control-label" htmlFor="languageAware">*/}
            {/*      Language Aware*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*</FormGroup>*/}
          </Col>
          <Col sm={7}>
            {selectedPropertyType &&
            selectedPropertyType.value === 'reference' && (
              <FormGroup>
                <Label>Reference Container Type</Label>
                <Select
                  value={referenceData}
                  isMulti
                  onChange={(value) => setReference(value)}
                  name="containers"
                  options={referenceOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            )}
            {selectedPropertyType?.value ==='vocabulary-flat' &&
            <>
            <VocabularyProperty handleChangeArray={handleChangeArray} entity={entity}/>
            </>
              }
            {selectedPropertyType && selectedPropertyType.value === 'enum' && (
              <FormGroup>
                <Label>{`Values (${enumValues.length})`}</Label>
                <TagsInput
                  name="tags"
                  value={enumValues}
                  inputProps={{
                    className: 'react-tagsinput-input',
                    placeholder: 'Add a value',
                  }}
                  onChange={(tags) => {
                    handleChange('propertyDetails');
                    setEnumValues(tags);
                  }}
                />
              </FormGroup>
            )}
            {selectedPropertyType &&
            selectedPropertyType.value === 'multi-select' && (
              <FormGroup>
                <Label>{`Values (${multiProp.length})`}</Label>
                <TagsInput
                  name="tags"
                  value={multiProp}
                  inputProps={{
                    className: 'react-tagsinput-input',
                    placeholder: 'Add a value',
                  }}
                  onChange={(tags) => {
                    handleChange('propertyDetails');
                    setMultiProp(tags);
                  }}
                />
              </FormGroup>
            )}
            {selectedPropertyType &&
            selectedPropertyType.value === 'spatial-map' && (
              <>
                <FormGroup>
                  <Label>Spatial Map - (Default Value)</Label>

                  <GooglePlacesAutocomplete
                    initialValue={mapValues}
                    onSelect={({description}) => {
                      handleSelectMap(description);
                    }}
                    inputClassName="form-control"
                  />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col lg="6">
                      <Label>Longitude</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={mapLong}
                        onChange={(e) => setMapLong(e.target.value)}
                      />
                    </Col>
                    <Col lg="6">
                      <Label>Latitude</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={mapLat}
                        onChange={(e) => setMapLat(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </>
            )}
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label>Namespace</Label>
              <Select
                value={selectedNamespace}
                onChange={handleChange('namespace')}
                options={namespacesOptions}
                placeholder="Select namespace"
              />
            </FormGroup>
            <FormGroup>
              <Label>Qualifiers</Label>
            </FormGroup>
            {qualifiers.map((qualifier) => {
              return <FormGroup key={qualifier.id}>
                <div
                  className="custom-control custom-switch mb-2 mr-3 z-depth-0"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={qualifier.qualifierKey}
                    checked={selectedQualifiers[qualifier.qualifierKey]}
                    value={selectedQualifiers[qualifier.qualifierKey]}
                    onClick={() => handleQualifierChange(qualifier)}
                    disabled={selectedPropertyType && !selectedPropertyType.languageAware}
                  />
                  <label className="custom-control-label" htmlFor={qualifier.qualifierKey}>
                    {qualifier.description}
                  </label>
                </div>
              </FormGroup>
            })}
          </Col>
        </Row>
      </form>
    </EditDialog>
  );
};

export default PropertyDialog;
