import React, { Fragment, useMemo } from "react";
import ContainerCards from "./container-cards";
import { Row } from "reactstrap";
import DataStreamList from "../DataStreams/data-stream-list";
import NoFiles from "./NoFiles";
import ContainerList from "./container-list";

const ContainerDefaultLayout = (props) => {
  const {
    handleContainerClick,
    children,
    selectedContainer,
    handleContainerSelect,
    handleEditClick,
    deleteContainer,
    currentContainer,
    dataStreams,
    handleFileClick,
    handleFileEdit,
    handleFileRename,
    handleDeleteFile,
    showList,
    handleMoveFile,
    handleLinkFile,
  } = props;

  const ContainerItemsLayout = useMemo(() => {
    return showList ? ContainerList : ContainerCards;
  }, [showList]);

  return (
    <Fragment>
      <Row>
        <ContainerItemsLayout
          handleContainerClick={handleContainerClick}
          containers={children}
          selectedContainer={selectedContainer}
          handleContainerSelect={handleContainerSelect}
          handleEditClick={handleEditClick}
          handleDelete={deleteContainer}
          currentContainer={currentContainer}
          {...props}
        />
      </Row>
      <Row>
        <DataStreamList
          showList={showList}
          files={dataStreams}
          handleFileClick={handleFileClick}
          handleFileEdit={handleFileEdit}
          handleFileRename={handleFileRename}
          handleDeleteFile={handleDeleteFile}
          handleMoveFile={handleMoveFile}
          handleLinkFile={handleLinkFile}
        />
      </Row>
      <Row>{props.noFiles && <NoFiles overview={true} />}</Row>
    </Fragment>
  );
};

export default ContainerDefaultLayout;
