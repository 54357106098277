import {
  getCommonState,
  getEntityIdentifier,
  handleCommonState,
} from "../core/factory";
import { entityType } from "./module";
import * as ActionTypes from "../core/actionTypes";
import {DELETE_DATASTREAM_FROM_CONTAINER, PUBLISH_CONTAINER, STAR_CONTAINER} from "./actionTypes";

const initialState = getCommonState();

const Project = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0];

  if (actionEntity !== entityType) return state;
  const actionType = action.type.split(".")[1];
  const identifierProp = getEntityIdentifier(entityType);

  switch (actionType) {
    case ActionTypes.GET_SUCCESS:
      const children = action.result.children || [];
      return {
        ...state,
        get: {
          ...state.get,
          result: {
            ...action.result,
            id: action.result[identifierProp],
          },
          error: null,
          isLoading: false,
        },
        getAll: {
          ...state.getAll,
          result: children.map((child) => ({
            ...child,
            id: child[identifierProp],
          })),
        },
        delete: {
          ...state.delete,
          error: null,
        },
        update: {
          ...state.delete,
          error: null,
        },
      };
    case ActionTypes.UPDATE_SUCCESS:
      const isChildUpdate =
        state.get.result.id !== action.result[identifierProp];
      return {
        ...state,
        get: {
          ...state.get,
          result: isChildUpdate
            ? state.get.result
            : { ...action.result, id: action.result[identifierProp] },
        },
        update: {
          ...state.update,
          error: null,
          isLoading: false,
        },
        getAll: {
          ...state.getAll,
          result: state.getAll.result.map((item) => {
            if (item.id === action.result[identifierProp]) {
              return {
                ...action.result,
                id: item.id,
              };
            }
            return item;
          }),
        },
      };

    case ActionTypes.CUSTOM:
      switch (action.payload.actionType) {
        case PUBLISH_CONTAINER:
          return {
            ...state,
            get: {
              ...state.get,
              result: {
                ...state.get.result,
                published:
                  state.get.result.uuid === action.payload.data.uuid
                    ? action.payload.data.published
                    : state.get.result.published,
              },
            },
            getAll: {
              ...state.getAll,
              result: state.getAll.result.map((item) => {
                if (item.id === action.payload.data[identifierProp]) {
                  return {
                    ...item,
                    published: action.payload.data.published,
                  };
                }
                return item;
              }),
            },
          };
        case DELETE_DATASTREAM_FROM_CONTAINER:
          return {
            ...state,
            get: {
              ...state.get,
              result: {
                ...state.get.result,
                datastreams: state.get.result.datastreams.filter(d => d.uuid !== action.payload.data.uuid)
              }
            }
          }
        case STAR_CONTAINER:
          return {
            ...state,
            get: {
              ...state.get,
              result: {
                ...state.get.result,
                starred:
                  state.get.result.uuid === action.payload.data.uuid
                    ? action.payload.data.starred
                    : state.get.result.starred,
              },
            },
            getAll: {
              ...state.getAll,
              result: state.getAll.result.map((item) => {
                console.log(action.payload.data);
                if (item.id === action.payload.data[identifierProp]) {
                  return {
                    ...item,
                    starred: action.payload.data.starred,
                  };
                }
                return item;
              }),
            },
          };
        default:
          break;
      }
      break;
    default:
      break;
  }

  return handleCommonState(state, actionType, action, entityType);
};

export default Project;
