import React, {useState} from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import Select from "react-select";
import {FormGroup, Label} from "reactstrap";
import toastr from "toastr";
//import CardSelect from "../../components/Common/CardSelect";

// const options = [
//   { label: "Export this container only", value: "single" },
//   { label: "Export this container and all it's contents", value: "multiple" },
// ];

const ExportDialog = (props) => {
  const { open, handleClose } = props;
 // const [mode, setMode] = useState(null);
  const [busy, setBusy] = useState(false);
  
  // const handleExport = async () =>
  // {
  //   setBusy(true);
  //   props.handleContainersExport(mode, (err) => {
  //     setBusy(false);
  //     if (err) {
  //       toastr.error(err.message, "Error");
  //     } else {
  //       handleClose();
  //     }
  //   });
  // };

  const [format, setFormat] = useState("")
  const [metadata, setMetadata] = useState("");
  
  const payload = {
    archiveFormat: format.value,
    containers: [],
    datastreams: [],
    exportMetadata: metadata.value,
  };

  const handleExport = async () => {
    setBusy(true);
    props.handleContainersExport(payload, (err) => {
      setBusy(false);
      if (err) {
        toastr.error(err.message, "Error");
      } else {
        handleClose();
      }
    });
  };

  const archiveFormatTypeOptions = [
    { label : "ZIP",
      value : "ZIP",
    },
   {
      label : "TAR_GZIP",
      value : "TAR_GZIP",
    },   
  ];
  const exportMetadataOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];


  return (
    <BaseDialog
      open={open}
      title={"Export files"}
      handleClose={handleClose}
      //isValid={mode !== null}
      isValid={format !== null || metadata !== null}
      busy={busy}
      primaryAction={handleExport}
      primaryActionTitle={"Export"}
    >
      {/* <CardSelect
        orientation={"column"}
        value={options.find((x) => x.value === mode)}
        onChange={(opt) => setMode(opt.value)}
        options={options}
      /> */}
      <FormGroup>
        <Label>Archive Format </Label>
        <Select value={archiveFormatTypeOptions.value} onChange={(value) => setFormat(value)} name="archiveFormat" options={archiveFormatTypeOptions} className="basic-multi-select" classNamePrefix="select" />
      </FormGroup>
      <FormGroup>
        <Label>Export Metadata </Label>
        <Select value={exportMetadataOptions.value} onChange={(value) => setMetadata(value)} name="exportMetadata" options={exportMetadataOptions} className="basic-multi-select" classNamePrefix="select" />
      </FormGroup>
    </BaseDialog>
  );
};

export default ExportDialog;
