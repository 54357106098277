import React, {useEffect, useState} from "react";
import {EmailTypeField} from "./Email";
import {FirstnameTypeField} from "./Firstname";
import {LastnameTypeField} from "./Lastname";
import {OrganizationTypeField} from "./Organization";

export const UserInfoTypeFields = (props) => {
  const {setUserInfo, surveyData, formDisabled} = props

  const [firstname, setFirstname] = useState(surveyData?surveyData.firstName:null)
  const [lastname, setLastname] = useState(surveyData?surveyData.lastName:null)
  const [organization, setOrganization] = useState(surveyData?surveyData.organization:null)
  const [email, setEmail] = useState(surveyData?surveyData.email:null)

  useEffect(()=>{
    if (firstname||lastname||organization||email) {
      setUserInfo({
        firstname, lastname, organization, email
      })
    }
    // eslint-disable-next-line
  },[firstname,lastname,organization, email])

  return <>
    <EmailTypeField setEmail={setEmail} email={email} formDisabled={formDisabled} />
    <FirstnameTypeField setFirstname={setFirstname} firstName={firstname} formDisabled={formDisabled} />
    <LastnameTypeField setLastname={setLastname} lastName={lastname} formDisabled={formDisabled} />
    <OrganizationTypeField setOrganization={setOrganization} organization={organization} formDisabled={formDisabled} />
  </>
}
