import {
    createDeleteMethod,
    createGetAllMethod,
    createGetMethod,
    createPostMethod, createPostSuccessMethod,
    createPutMethod,
} from "../core/factory";
import {entityType} from "./module";

export const getSurveyResponse = (params) => createGetMethod(entityType, params)
export const getAllSurveyResponses = () => createGetAllMethod(entityType)
export const createSurveyResponse = (payload) => createPostMethod(entityType, payload)
export const updateSurveyResponse = (payload) => createPutMethod(entityType, payload)
export const deleteSurveyResponse = (payload) => createDeleteMethod(entityType, payload)
export const clearSurveyResponse = () => createPostSuccessMethod(entityType, {})
