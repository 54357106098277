import React, {useEffect, useState} from 'react'
import {Button, Col, Container, Input, Pagination, PaginationItem, PaginationLink, Row} from 'reactstrap'
import {FIELD_DEFAULTS, SURVEY_TYPES} from "../components/consts";
import FieldRenderer from "../components/FieldRenderer";
import {getAxiosDefaultConfig, getTenantId, getToken} from "../../../utils";
import axios from "axios";
import {API_URL} from "../../../config";
import {useParams} from "react-router";
import Email from "../components/fields/Email";
import {UserInfoTypeFields} from "../components/common/UserInfoTypeFields";
import BusyIndicator from "../../../components/Common/BusyIndicator";
import {connect} from "react-redux";
import {
  getSurveyResponse,
} from "../../../store/surveyResponse/actions";
import NoResponses from "./NoResponses";
import DeleteDialog from "../../Containers/DeleteDialog";
import moment from "moment";

const headers = {
  Authorization: "Bearer " + getToken(),
  'Content-Type': 'application/json'
}

const Individual = ({UUIDs, ...props}) =>{

  const {uuid} = useParams()

  const [responseNum, setResponseNum] = useState(1)
  const [totalNumOfResponses, setTotalNumOfResponses] = useState(UUIDs?UUIDs.length:0)
  const [selectedUUID, setSelectedUUID] = useState(UUIDs?UUIDs[0]:0)
  const [isLoading, setIsLoading] = useState(true)
  const [surveyResponse, setSurveyResponse] = useState()
  const [surveyData, setSurveyData] = useState(props.surveyData)
  const [openDelete, setOpenDelete] = useState(false)

  const {getSurveyResponse} = props;

  const getResponse = () => {

    setIsLoading(true)
    axios.get(`${API_URL}/addons/srv/surveys/${uuid}/responses/${selectedUUID}`,getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setSurveyResponse(data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    if (selectedUUID) {
      getResponse()
    }

    // eslint-disable-next-line
  }, [selectedUUID])

  useEffect(()=>{
    if (surveyResponse) {

      // Match Answers to Questions
      let newQuestions = [...surveyData.questions]
      surveyResponse.answers.forEach((answer)=>{
        let questionIndex = newQuestions.findIndex((q)=>q.id===answer.id)
        if (questionIndex!==-1) {
          newQuestions[questionIndex] = {
            ...newQuestions[questionIndex],
            value: answer.value
          }
        }
      })

      // Update local survey object with values
      setSurveyData({
        ...surveyData,
        ...surveyResponse,
        questions: newQuestions
      })
    }
    //eslint-disable-next-line
  },[surveyResponse])



  const closeDeleteDialog = () => {
    setOpenDelete(false)
  }

  const deleteResponse = () => {
    setOpenDelete(true)
  }

  const handleDelete = () => {
    reqDelete()
  }

  const changeResponseNum = (e) => {
    const value = e.target.value
    setResponseNum(parseInt(value))
  }

  const goToFirst = () => {
    setResponseNum(1)
  }

  const goToLast = () => {
    setResponseNum(totalNumOfResponses)
  }

  const decrementResponseNum = () => {
    let newValue = responseNum-1
    if (newValue<=0) {
      setResponseNum(1)
    } else {
      setResponseNum(newValue)
    }
  }

  const incrementResponseNum = () => {
    let newValue = responseNum+1
    if (newValue>totalNumOfResponses) {
      setResponseNum(totalNumOfResponses)
    } else {
      setResponseNum(newValue)
    }
  }

  const reqDelete = () => {
    const tenantHeader =  { ...headers, 'X-TenantID': getTenantId() }

    setIsLoading(true)
    axios.delete(`${API_URL}/addons/srv/surveys/${uuid}/responses/${selectedUUID}`,{
      headers: tenantHeader
    })
      .then(() => {
        getSurveyResponse(uuid);
        setIsLoading(false);
        decrementResponseNum()
      })
      .catch(error => {
        setIsLoading(false);
      })
  }

  useEffect(()=>{
    if (responseNum) {
      setSelectedUUID(UUIDs?UUIDs[responseNum-1]:0)
    }
    // eslint-disable-next-line
  },[responseNum])

  useEffect(()=>{
    if (UUIDs) {
      setTotalNumOfResponses(UUIDs.length)
    }
  },[UUIDs])

  if (totalNumOfResponses===0) {
    return <NoResponses />
  }

  let creationDate = new Date(surveyData.createdAt+'Z')

  const createMarkup = (htmlcode) => {
    return {__html: htmlcode}
  }

  return <Container className='mt-4'>
    <Row>
      <Col xs={6}>
        <Pagination aria-label="Answers navigation" size='md'>
          <PaginationItem>
            <PaginationLink first href="#" onClick={goToFirst}/>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink previous href="#" onClick={decrementResponseNum}/>
          </PaginationItem>
          <PaginationItem>
            <Input min={1} type="number" step="1" max={totalNumOfResponses} className='survey-number-input-response' defaultValue={1} value={responseNum} onChange={changeResponseNum}/>
          </PaginationItem>
          <PaginationItem>
            <div className='survey-pagination-item'>of</div>
          </PaginationItem>
          <PaginationItem>
            <div className='survey-pagination-item'>{totalNumOfResponses}</div>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink next href="#" onClick={incrementResponseNum}/>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink last href="#" onClick={goToLast}/>
          </PaginationItem>
        </Pagination>
      </Col>

      <Col style={{textAlign:'right'}}>
        <Button outline color="danger" onClick={()=>deleteResponse()}>Delete</Button>
      </Col>
    </Row>

    <Row className='mt-4'>
      <Col xs={{size: 10, offset: 1}}>

        {isLoading&&<div className='custom-loading-overlay'> </div>}

        {!isLoading&&surveyData&&<Container style={{marginBottom: 16}}>
          <h2>{surveyData.label}</h2>
          <h5>{surveyData.description}</h5>
          {!isLoading&&surveyData&&<>
            Responded on  {moment(creationDate).format("DD/MM/YYYY, h:mm a")}
          </>}
        </Container>}


        {!isLoading&&surveyData&&!SURVEY_TYPES.ANONYMOUS&&<div className='survey-field-view'>
          <Container style={{textAlign:'left', position:'relative'}}>
            {surveyData&&surveyData.surveyType===SURVEY_TYPES.EMAIL_ONLY&&
              <Email field={{...FIELD_DEFAULTS.EMAIL,value:surveyResponse&&surveyResponse.email}} setField={()=>{}} standalone />
            }
            {surveyData&&surveyData.surveyType===SURVEY_TYPES.USER_DETAILS&&
              <UserInfoTypeFields surveyData={surveyData} setUserInfo={()=>{}} formDisabled={true} />
            }
          </Container>
        </div>}

        {surveyData.sections.map((section,sectionIndex)=>{
          const sectionFields = surveyData.questions.filter((f)=>section.questions.includes(f.id))
          const questionsSoFar = surveyData.sections.slice(0,sectionIndex).reduce((total=0, s)=>total+s.questions.length,0)

          return <>
            {section.title&&<Container style={{marginBottom: 16, marginTop: 32}}>
              <h4>{section.title}</h4>
              {section.description!==''&&<h6 style={{whiteSpace: "pre-wrap"}}>
                <div dangerouslySetInnerHTML={createMarkup(section.description)} />
              </h6>}
            </Container>}

            {sectionFields.map((field, sectionFieldIndex) => {
              const index = surveyData.questions.findIndex((f)=>f.id===field.id)
              return <div className='survey-field-view' key={index}>
                <FieldRenderer field={{...field, index}} setField={()=>{}}
                               answerable={true} key={index} formDisabled={isLoading} fieldNumber={questionsSoFar+sectionFieldIndex} />
              </div>
            })}
          </>
        })}

      </Col>

    </Row>

    {isLoading&&<Row style={{minHeight:350, position: 'relative'}} className='d-flex justify-content-center align-items-center align-content-center'>

      <div className='d-flex'>
        <BusyIndicator busy={isLoading}/>
      </div>

    </Row>}


    {<DeleteDialog open={openDelete} handleClose={closeDeleteDialog} handleDelete={handleDelete} />}

  </Container>
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getSurveyResponse,
})(Individual);
