import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CardSelect from "../../components/Common/CardSelect";
import React, { useMemo } from "react";
import { getPropertyView } from "../../components/Common/PropertyViews";
import _ from "lodash";

function PropertyColumn({
  property,
  value,
  handleChange,
  handleChangeArray,
  className,
  createRepeatable,
  deleteRepeatable,
  joinProps,
  qualifiers,
}) {
  const PropertyView = getPropertyView(property.propertyType);

  return (
    <Col xs={12} className={className}>
      <PropertyView
        property={property}
        value={value}
        handleChange={handleChange}
        handleChangeArray={handleChangeArray}
        createRepeatable={createRepeatable}
        deleteRepeatable={deleteRepeatable}
        joinProps={joinProps}
        qualifiers={qualifiers}
      />
    </Col>
  );
}

const useContainerDialogLayout = ({
  form,
  handleChange,
  handleChangeArray,
  createRepeatable,
  deleteRepeatable,
  filteredProperties,
  qualifiers,
  open,
  joinProps,
  propertyGroups,
  acceptedTypes,
  isRoot=false,
}) => {



  let options = []

      if (isRoot)
      {
        const allowedAtRoot =  acceptedTypes.containerTypes.filter((containerType)=> containerType.allowedAtRoot)
        options = allowedAtRoot.map((container) => ({
          label: container.name,
          value: container.code,
        }));
      }
      else
      {
         options = acceptedTypes.containerTypes.map((container) => ({
           label: container.name,
           value: container.code,
         }));
      }

  const selectedType = options.find((x) => x.value === form.type);
  const allProps = useMemo(
    () => [...filteredProperties.mandatory, ...filteredProperties.optional],
    [filteredProperties.mandatory, filteredProperties.optional]
  );
  const formGroups = useMemo(() => {
    if (!allProps[0] || !propertyGroups[0]) return [];
    return _.chain(allProps)
      .groupBy("groupId")
      .map((value, key) => ({
        groupId: parseInt(key),
        ...propertyGroups.find((x) => x.id === parseInt(key)),
        properties: value,
      }))
      .value();
  }, [allProps, propertyGroups]);

  const basicLayout = !form.id && (
    <Row>
      <Col xs={12}>
        <FormGroup>
          <Label>Label</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="Enter label"
            value={form.label}
            onChange={handleChange("label")}
          />
        </FormGroup>
      </Col>
      <Col xs={12}>
        <FormGroup>
          <Label>Container type</Label>
          <CardSelect
            orientation={"column"}
            isDisabled={form.id}
            options={options}
            value={selectedType}
            onChange={handleChange("type")}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const contents = formGroups.map((formGroup) => {
    return (
      <Row className={"my-3"}>
        {formGroup.properties
          .sort(function (a, b) {
            const aGroup = a.groupId;
            const bGroup = b.groupId;
            const aPosition = a.position;
            const bPosition = b.position;

            if (aGroup === bGroup) {
              return aPosition < bPosition ? -1 : aPosition > bPosition ? 1 : 0;
            } else {
              return aGroup < bGroup ? -1 : 1;
            }
          })
          .map((property) => {
            return (
              <PropertyColumn
                key={property.id}
                property={property}
                value={form[property.id]}
                handleChange={handleChange}
                handleChangeArray={handleChangeArray}
                className={"mb-2"}
                createRepeatable={createRepeatable}
                deleteRepeatable={deleteRepeatable}
                joinProps={joinProps}
                qualifiers={qualifiers}
                open={open}
              />
            );
          })}
      </Row>
    );
  });

  const steps = useMemo(() => {
    if (!form.id) return null;
    return formGroups.map((group) => ({
      title: group.name || group.groupId,
    }));
  }, [form.id, formGroups]);

  return {
    steps,
    contents,
    basicLayout,
  };
};

export { useContainerDialogLayout };
