import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {ApiEndpoint} from '../../store/core/endpoint';
import {EntityType} from '../../store/core/entityType';
import {connect} from 'react-redux';
import {bytesToSize, downloadThumb, getAxiosDefaultConfig} from '../../utils';
import {Col, Row, Container} from 'reactstrap';
import {getIconForDatastream} from '../../utils';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import moment from 'moment';
import FireInput from '../Common/FireInput';
import {handleError} from '../../store/core/api';

const ScrollLoader = ({loading}) => {
  return (
    loading && (
      <Row>
        <Col xs="12">
          <div className="text-center my-3">
            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"/>{' '}
            Loading ...
          </div>
        </Col>
      </Row>
    )
  );
};

const DataStreamThumb = ({file, thumbnail}) => {

  const [imageThumb, setImageThumb] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (file.thumbnail.length > 0)
      downloadThumb(file, thumbnail, setImageThumb, isLoading, setIsLoading)
    // eslint-disable-next-line
  }, [file.uuid])

  if (isLoading) return <Col xs={2} className="p-1 mt-1">
    <i className={`bx ${getIconForDatastream(file.type.name)} avatar-title`} style={{width: '45px', height: '45px', borderRadius: '10px',backgroundColor:'#bec0d0',color:'#606371'}}/>
  </Col>

  return <>
    {imageThumb == null ? <Col xs={2} className="p-1 mt-3">
        <i className={`bx ${getIconForDatastream(file.type.name)} avatar-title`}/>
      </Col> :
      <Col xs={2} className="p-1 mt-1">
        <div style={{width: '40px', height: '40px', borderRadius: '20px'}}>
          <img id={`fileId-${file.uuid}`} src={imageThumb} alt={`${thumbnail} thumb`} className="avatar-title"
               style={{width: '45px', height: '45px', borderRadius: '10px'}}/>
        </div>
      </Col>
    }
  </>
}

const SearchResult = (props) => {
  const {results, isLoading, setShowResults, query} = props;

  const renderContainers = () =>
    results.containers.map((container) => {
      return (
        <Link
          to={`/containers/${container.uuid}`}
          key={container.uuid}
          onClick={() => setShowResults(false)}
        >
          <Row className="p-2 align-content-center row pointer">
            <Col xs={1} className="p-1 mt-3">
              <i className="bx bx-folder-open font-size-18"/>
            </Col>
            <Col className={'mt-2'} xs={7}>
              <h5 className="text-truncate font-size-15">{container.label}</h5>
              <p className="text-muted">
                {container.createdBy.firstname} {container.createdBy.lastname}
              </p>
            </Col>
            <Col className="mt-4 pull-right" xs={3}>
              <p className="text-muted font-size-10">
                {' '}
                {moment(container.createdAt).format('DD/MM/YYYY')}
              </p>
            </Col>
          </Row>
        </Link>
      );
    });


  const renderDatastreams = () =>
    results.datastreams.map((datastream) => {
      return (
        <Link
          to={`/containers/${datastream.membership[0].uuid}/?datastream=${datastream.uuid}`}
          key={datastream.uuid}
          onClick={() => setShowResults(false)}
        >
          <Row className="p-2 align-content-center row pointer">
            <DataStreamThumb key={datastream.uuid} file={datastream} thumbnail={datastream.thumbnail}/>
            <Col className={'mt-2'} xs={6}>
              <h5 className="text-truncate font-size-15">{datastream.label}</h5>
              <p className="text-muted">
                {datastream.createdBy.firstname} {datastream.createdBy.lastname}
              </p>
            </Col>
            <Col className="mt-4" xs={2}>
              <p className="text-muted font-size-10">
                {' '}
                {moment(datastream.createdAt).format('DD/MM/YYYY')}
              </p>
            </Col>
            <Col className="mt-4 pull-right" xs={2}>
              <p className="text-muted font-size-10">
                {bytesToSize(datastream.filesize)}
              </p>
            </Col>
          </Row>
        </Link>
      );
    });


  const renderUsers = () =>
    results.users.map((user) => {
      return (
        <Link
          to={`/settings/users/${user.uuid}`}
          key={user.uuid}
          onClick={() => setShowResults(false)}
        >
          <Row className="p-2 align-content-center row pointer">
            <Col xs={1} className="p-1 mt-3">
              <i className={'bx bx-user font-size-18'}/>
            </Col>
            <Col className={'mt-2'} xs={7}>
              <h5 className="text-truncate font-size-15">
                {user.firstname} {user.lastname}
              </h5>
              <p className="text-muted">{user.email}</p>
            </Col>
          </Row>
        </Link>
      );
    });
  const renderGroups = () =>
    results.groups.map((group) => {
      return (
        <Link
          to={`/settings/groups/${group.id}`}
          key={group.id}
          onClick={() => setShowResults(false)}
        >
          <Row className="p-2 align-content-center row pointer">
            <Col xs={1} className="p-1 mt-3">
              <i className={'bx bx-group font-size-18'}/>
            </Col>
            <Col className={'mt-2'} xs={7}>
              <h5 className="text-truncate font-size-15">{group.name}</h5>
              <p className="text-muted">{group.description}</p>
            </Col>
          </Row>
        </Link>
      );
    });

  const renderFooter = () =>
    results.containers.length === 0 &&
    results.datastreams.length === 0 &&
    !isLoading ? (
      <Row className={'p-4'}>
        <Col>{query ? 'No results' : ''}</Col>
      </Row>
    ) : null;

  return (
    <>
      <Container className={'searchContainer'} fluid>
        <ScrollLoader loading={isLoading}/>
        {renderContainers()}
        {renderDatastreams()}
        {renderUsers()}
        {renderGroups()}
        {renderFooter()}
      </Container>
    </>
  );
};

const Searchbar = (props) => {
  const divRef = useRef(null);
  const [query, setQuery] = useState([]);
  const [queryFilter,setQueryFilter] = useState('')
  const [searchField,setSearchField] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [fulltextType, setFulltextType] = useState("OR");
  const [fulltextToggleOpen, setFulltextToggleOpen] = useState(false);
  const [results, setResults] = useState({
    containers: [],
    datastreams: [],
    groups: [],
    users: [],
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowResults(false);
        setQuery('');
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);

  useEffect(() => {
    if (!showResults) setQuery('');
  }, [showResults]);

  useEffect(() => {
    if (queryFilter === "OP_DATASTREAM_FULLTEXT" && fulltextType === "OR") {
      setQueryFilter("OP_DATASTREAM_FULLTEXT");
    } else if (queryFilter === "OP_DATASTREAM_FULLTEXT" && fulltextType === "AND") {
      setQueryFilter("OP_DATASTREAM_FULLTEXT_EXACT");
    } 
  }, [queryFilter, fulltextType]);
  
  const onFire = async () => {
    if (query.length >= 3) {
      if (!query) {
        setResults({containers: [], datastreams: [], groups: [], users: []});
        return;
      }
      const searchUrl = ApiEndpoint[EntityType.Search]

      const payload = { operation: queryFilter, field: searchField, value: query }
      try {
        setIsLoading(true);
        const result = await axios
          .post(searchUrl, payload, getAxiosDefaultConfig())
          .then((res) => res.data)
          .catch(handleError);
        setResults(result);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={'search-box-container'}>
      <form
        className={`app-search d-none d-lg-block search-box ${
          showResults && query ? 'expanded' : ''
        }`}
        ref={divRef}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="position-relative">
          <FireInput
            value={query}
            queryFilter={setQueryFilter}
            fulltextType={fulltextType}
            setFulltextType={setFulltextType}            
            fulltextToggleOpen={fulltextToggleOpen}
            setFulltextToggleOpen={setFulltextToggleOpen}
            searchField={setSearchField}
            onFire={onFire}
            onClick={() => setShowResults(true)}
            placeholder={props.t('Search') + ' all...'}
            onChange={setQuery}
            timeout={500}
          />
          <span className="bx bx-search-alt pull"/>
          {showResults && query && (
            <SearchResult
              query={query}
              isLoading={isLoading}
              results={results}
              setShowResults={setShowResults}
            />
          )}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default withRouter(connect(mapStateToProps, null)(Searchbar));
