import React, {useEffect, useState} from 'react';
import {Col, Row, Table, UncontrolledTooltip} from 'reactstrap';

const ErrorTooltip = (props)=>{
  const {message,idx} = props

  return <>
    <i id={`error-${idx}`} style={{color:'#b84c4c'}} className='bx bx-error'/>
  <UncontrolledTooltip target={`error-${idx}`}>
    {message.map((m)=>m +' ')}
  </UncontrolledTooltip>
    </>
}

const ImportTermsList = (props) => {

  const {terms, setTerms} = props

  const [checkedAll, setCheckedAll] = useState()
  const [selectedTerms, setSelectedTerms] = useState([])

  const toggleTerm = (termId) => {
    let newTerms = [...selectedTerms]
    const newTerm = terms.find((c) => c.data.termId === termId)
    const termIndex = selectedTerms.findIndex((t) => t.termId === termId)
    if (termIndex === -1) {
      newTerms.push(newTerm.data)
    } else {
      newTerms.splice(termIndex, 1)
    }
    setSelectedTerms(newTerms)
  }

  useEffect(() => {
    if (checkedAll) {
      setSelectedTerms([])
      const newTerms = []
      terms.forEach((c) => {
        if (c.valid === true) {
          newTerms.push(c.data)
        }
      })
      setSelectedTerms(newTerms)
    } else {
      setSelectedTerms([])
    }
    // eslint-disable-next-line
  }, [checkedAll])

  useEffect(() => {
      setTerms(selectedTerms)
    // eslint-disable-next-line
  }, [selectedTerms])

  return <>
    <Row>
      <Col lg="12">
        <div className="">
          <div className="table-responsive">
            <Table className="project-list-table table-nowrap table-centered table-borderless table-zebra">
              <thead>
              <tr>
                <th scope="col" style={{width: '100px'}}>
                  <input
                    type={'checkbox'}
                    checked={checkedAll}
                    onChange={(e) => setCheckedAll(e.target.checked)}
                  />
                </th>
                <th scope="col">Term Id</th>
                <th scope="col">Label</th>
                <th scope="col">Term Uri</th>
                <th scope="col"style={{width: '100px'}}/>
              </tr>
              </thead>
              <tbody>
              {terms.map((item, i) => {
                return <tr className={`c-item-list ${item.valid?'':'c-item-error'}`} key={`${i}-${item?.data?.termId}`}
                           onClick={() => item.valid ? toggleTerm(item?.data?.termId) : null}>
                  <td>
                    <input
                      type={'checkbox'}
                      disabled={!item.valid}
                      checked={selectedTerms.findIndex((t) => t?.termId === item?.data?.termId) !== -1}
                      onChange={() => toggleTerm(item?.data?.termId)}
                    />
                  </td>
                  <td>
                    {item?.data?.termId}
                  </td>
                  <td>
                    {item?.data?.label}
                  </td>
                  <td>
                    {item?.data?.termUri}
                  </td>
                  <td>{item.valid? <i className='bx bx-check-circle' style={{color:'#8cbf73'}}/> : <ErrorTooltip message={item.errors} idx={i}/> }</td>
                </tr>
              })}
              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    </Row>
  </>
}

export default ImportTermsList
