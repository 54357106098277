import React from 'react';
import {CardBody, Collapse} from 'reactstrap';
import {useToggle} from '../../helpers/hooks';

function Accordian(props) {
  const {expandable = true} = props
  const [open, toggle] = useToggle();

  return (
    <div id="gen-ques-accordion" className="accordion custom-accordion">
      <div>
        <div className="d-flex accordion-list p-2 bg-white" onClick={expandable?toggle:null} style={{cursor: 'pointer'}}>
          <div className="flex-fill">{props.title}</div>
          {expandable &&
          < i
            style={{width: 24, height: 24}}
            className={
              open
                ? 'mdi mdi-minus accor-plus-icon'
                : 'mdi mdi-plus accor-plus-icon'
            }
          />
          }
        </div>

        <Collapse isOpen={open}>
          <CardBody className={'bg-white p-2'} style={{marginTop: 2, borderRadius: 8}}>{props.children}</CardBody>
        </Collapse>
      </div>
    </div>
  );
}

export default Accordian;
