import React, {  useEffect, useState } from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import {Input} from 'reactstrap';
import {connect} from 'react-redux'
import {getAllContainers} from '../../store/container/actions'

const RenameDialog = (props) => {
  const [title,setTitle] = useState('')

  useEffect(() => { 
    if (props?.containerUuid && props.open) {
      const container = props.context.Container.getAll.result.find((c) => c.uuid === props?.containerUuid)
      setTitle(container ? container.label : props.currentContainer.label);
    }
    //eslint-disable-next-line
  },[props.containerUuid,props.open])

  const handleTextChange = (e)=>{
    setTitle(e.target.value)
  }

  const handleRenameContainer = () =>{
    props.handleRename(title,props.containerUuid)
  }

  return (
    <BaseDialog
      scrollable
      size={"sm"}
      title={"Rename Container"}
      open={props.open}
      primaryAction={handleRenameContainer}
      handleClose={props.handleClose}
    >
      <Input
        type="text"
        className='form-control'
        value={title}
        onChange={handleTextChange}
      />
    </BaseDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getAllContainers
})(RenameDialog)
