import React, {useEffect, useState} from 'react';
import {Badge, FormGroup, Input, Label, Row, Col} from 'reactstrap';
import {useForm} from '../../../../helpers/hooks';
import EditDialog from '../../../../components/Common/EditDialog';
import TagsInput from 'react-tagsinput'
import {ApiEndpoint} from '../../../../store/core/endpoint'
import {EntityType} from '../../../../store/core/entityType'
import axios from 'axios'
import {getAxiosDefaultConfig} from '../../../../utils'
import toastr from 'toastr'

const PropertyDialog = (props) => {
  const {entity, open} = props;

  const [form, handleChange, updateInitial, handleChangeArray] = useForm({});
  const [enumValues, setEnumValues] = useState([]);
  const [languages, setLanguages] = useState([])
  const [selectedValue,setSelectedValue] = useState([])

  useEffect(() => {
    const languagesUrl = ApiEndpoint[EntityType.Utility] + '/languages'
    axios.get(languagesUrl, getAxiosDefaultConfig())
      .then((response) => {
        const languageOptions = response.data.map((lang) => ({
          label: lang.name,
          value: lang.iso639_1,
        }));
        setLanguages(languageOptions)

      })
      .catch((e) => {
        toastr.error(`Something went wrong. Error code: ${e.request.status}.`, 'Error');
      })
  }, [])

  useEffect(() => {
    if (languages)
    {
      const selectedValuesTemp = {...selectedValue}
      languages.forEach((language)=>{
        const searchCurrent = enumValues.find((x)=>x === language.label)
        if (searchCurrent && searchCurrent.length > 0)
        {
          selectedValuesTemp[language.label] = true
        }
        else
          selectedValuesTemp[language.label] = false
      })

      setSelectedValue(selectedValuesTemp)

    }
    const enumPayload = {values: enumValues};
    handleChangeArray('qualifierDetails', JSON.stringify(enumPayload));
    // eslint-disable-next-line
  }, [enumValues]);

  useEffect(() => {
    updateInitial(entity);
  }, [entity, updateInitial, open]);

  useEffect(() => {
    setEnumValues([])
    setSelectedValue([])
    if (entity && entity.qualifierDetails.length > 0) {
      const initialValues = JSON.parse(entity.qualifierDetails)
      setEnumValues(initialValues.values)
    }

// eslint-disable-next-line
  }, [entity.id,open])

  const handleLanguageChange = (language) =>{
    if (selectedValue[language.label]) {
      const previousValues = [...enumValues]
      const foundIndex = previousValues.findIndex((x) => x === language.label)
      previousValues.splice(foundIndex,1)
      setEnumValues(previousValues)
    }
    else
    {
      setEnumValues([...enumValues,language.label])
    }
    setSelectedValue({...selectedValue,[language.label]:!selectedValue[language.label]})
  }

  return (
    <EditDialog {...props} size={'md'} form={form}>
      <form>
        <FormGroup>
          <Label>Key</Label>
          <Input
            type="text"
            disabled={form.id}
            className="form-control"
            // placeholder="Enter description"
            value={form.qualifierKey}
            onChange={handleChange('qualifierKey')}
          />
        </FormGroup>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter label"
            value={form.qualifierName}
            onChange={handleChange('qualifierName')}
          />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input
            type="text"
            className="form-control"
            // placeholder="Enter label"
            value={form.description}
            onChange={handleChange('description')}
          />
        </FormGroup>
        <FormGroup>
          <Label>Values</Label>
          <TagsInput
            name="tags"
            value={enumValues}
            inputProps={{
              className: 'react-tagsinput-input',
              placeholder: 'Add a value',
            }}
            onChange={(tags) => {
              handleChange('qualifierDetails');
              setEnumValues(tags);
            }}
          />
        </FormGroup>
        {form.externalSource &&
        <FormGroup>
          <Label>Predefined Values</Label>
          <Row>
            {languages.map((language) => {
              return <Col xs={3} key={language.value}>
                <Badge color={selectedValue[language.label]? 'success' :'primary' }
                       className="font-size-12 font-weight-medium mr-3 pointer"
                       onClick = {()=>handleLanguageChange(language)}
                >
                  {language.label}
                </Badge>
              </Col>
            })}
          </Row>
        </FormGroup>
        }
      </form>
    </EditDialog>
  );
};

export default PropertyDialog;
