import React, {useEffect, useState} from 'react'
import {Table} from 'reactstrap';
import EditTerm from './EditTerm'
const TermsList = (props) => {
  const {terms,updateTerm,deleteTerm,setChecked} = props
  const [showEditTerm,setShowEditTerm] = useState(false)
  const [selectedTerm,setSelectedTerm] = useState({})
  const [checkedTerms,setCheckedTerms] = useState([])
  const [checkedAll, setCheckedAll] = useState()

  useEffect(() => {
    if (checkedAll) {
      setCheckedTerms(terms)
    } else {
      setCheckedTerms([])
    }
    // eslint-disable-next-line
  }, [checkedAll])

  useEffect(()=>{
    setChecked(checkedTerms)
    // eslint-disable-next-line
  },[checkedTerms])

  const handleEditTerm = (term) =>{
    setShowEditTerm(()=>!showEditTerm)
    setSelectedTerm(term)
  }

  const toggleTerm = (termId) =>{
    let newTerms = [...checkedTerms]
    const newTerm = terms.find((c) => c.termId === termId)
    const termIndex = checkedTerms.findIndex((t) => t?.termId === termId)

    if (termIndex === -1) {
      newTerms.push(newTerm)
    } else {
      newTerms.splice(termIndex, 1)
    }
    setCheckedTerms(newTerms)
  }

  return <>
    <Table className="project-list-table table-nowrap table-centered table-borderless table-zebra">
      <thead>
      <tr>
        <th scope="col" style={{width: '100px'}}>
          <input
            type={'checkbox'}
            checked={checkedAll}
            onChange={(e) => setCheckedAll(e.target.checked)}
          />
        </th>
        <th scope="col">Id</th>
        <th scope="col">Label</th>
        <th scope="col">Uri</th>
      </tr>
      </thead>
      <tbody>
      {terms?.map((term) => {
        return <tr key={term.termId} className='c-item-list'>
          <td
            onClick={() => toggleTerm(term.termId)}>
            <input
              type={'checkbox'}
              checked={checkedTerms.findIndex((t) => t?.termId === term.termId) !== -1}
              onChange={() => toggleTerm(term.termId)}
            />
          </td>
          <td onClick={()=>handleEditTerm(term)}>{term.termId}</td>
          <td onClick={()=>handleEditTerm(term)}>{term.label}</td>
          <td onClick={()=>handleEditTerm(term)}>{term.termUri}</td>
        </tr>
      })}
      </tbody>
    </Table>
    <EditTerm term={selectedTerm} handleUpdate={updateTerm} open={showEditTerm} handleClose={()=>setShowEditTerm(!showEditTerm)} handleDelete={deleteTerm}/>
  </>
}

export default TermsList