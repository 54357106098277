import React, { useEffect, useState, useRef } from 'react'
import Tooltip from "@material-ui/core/Tooltip";


  const OverflowTip = ({children}) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const textElementRef = useRef();
    useEffect(() => {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }, []);
    return (
      <Tooltip title={children} disableHoverListener={!isOverflowed} arrow placement="top">
        <div
          ref={textElementRef}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            verticalAlign: "text-bottom",
            lineHeight: "19px"
          }}
        >
          {children}
        </div>
      </Tooltip>
    );
};
  

export default OverflowTip
