import React   from 'react';
import ScreenItem from "./components/listing/ScreenItem";
import {Row} from "reactstrap";
import ScreenNewItem from "./components/listing/ScreenNewItem";
const ScreensList = ({result, isLoading}) => {

  return <>
      {!isLoading&&result&&<Row xs={12} md={3}>
        {result.map((screen,i)=><div className='c-item-wrapper'><ScreenItem screen={screen} /></div>)}
        <div className='c-item-wrapper'><ScreenNewItem /></div>
      </Row>}
  </>

};

export default ScreensList;
