import React, {useEffect, useState} from "react";
import {TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Modal, ModalBody, ModalHeader, ListGroup, ListGroupItem, Badge, UncontrolledTooltip} from "reactstrap";
import axios from "axios";
import {API_URL} from "../../config";
import {getAxiosDefaultConfig} from "../../utils";
import {withStyles} from "@material-ui/core/styles";

const styles = (theme) => ({
  content: {
    border: "none",
  },
  header: {
    background: "#496276",
    color: "#fff",
    border: "none",
    padding: "0.7rem 1rem",
    display: "block",
    textAlign: "center",
    "& h5": {
      color: "#fff",
    },
    "& .close": {
      color: "#fff",
      background: "red",
      borderRadius: "50%",
      padding: "1px",
      fontWeight: "100",
      content: "X",
      width: "20px",
      height: "20px",
      position: "absolute",
      top: "20px",
      right: "20px",
    },
  },
  footer: {
    border: "none",
    paddingTop: "0px",
  },
  title: {
    textAlign: "left",
    fontSize: "14px",
    lineHeight: "20px",
    padding: "5px",
    "& i": {
      fontSize: "20px",
      verticalAlign: "sub",
    },
  },
  value: {
    textAlign: "left",
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: "600",
    padding: "5px",
  },
  closeButton: {
    height: "31px",
    lineHeight: "14px",
  },
  conTypeIcon: {
    padding: "0px 0px",
    textAlign: "center",
    color: "#496276",
    border: "1px solid #e3e3e3",
    borderRadius: "5px",
    boxShadow: "0px 0px 4px 0px #e3e3e3",
    width: "50px",
    "& i": {
      fontSize: "38px",
    },
  },
  activeTab: {
    color: "#495057",
    backgroundColor: "#fff",
    borderColor: "#ced4da #ced4da #ffffff !important",
    borderWidth: "1px !important",
  },
  sign: {
    float: "right",
  },
});

const ReportDialog = withStyles(styles)(({classes, ...props}) => {
  const {uuid} = props;
  const [containerData, setContainerData] = useState();
  const [data, setData] = useState({
    containerCount: 0,
    datastreamCount: 0,
    totalStorageSpace: 0,
    totalStorageSpaceHR: 0,
  });

  const [state, setState] = useState({
    activeTab: "1",
  });

  const axiosConfig = getAxiosDefaultConfig();

  useEffect(() => {
    if (uuid) {
      const payload = {uuid: uuid};
      axios
        .post(`${API_URL}/containers/${uuid}/reports`, payload, axiosConfig)
        .then((res) => {
          setData(res.data);
        })
        .catch((e) => {
          setData();
        });
    }
    setState({
      activeTab: "1",
    });
    //eslint-disable-next-line
  }, [uuid]);

//   useEffect(() => {
//     if (uuid) {
//       axios
//         .get(`${API_URL}/containers/${uuid}`, axiosConfig)
//         .then((result) => {
//           setContainerData(result.data);
//         })
//         .catch((e) => {
//           setContainerData();
//         });
//     }
//     //eslint-disable-next-line
//   }, [data, uuid]);

  const toggle = (tab) => {
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      });
    }
  };
  return (
    <React.Fragment>
      <Modal centered contentClassName={classes.content} size={"md"} isOpen={props.open} toggle={props.handleClose}>
        <ModalHeader className={classes.header} toggle={props.handleClose}>
          Container Info
        </ModalHeader>
        <ModalBody>
          {containerData && (containerData.children.length !== 0 || containerData.datastreams.length !== 0) && (
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={state.activeTab === "1" ? classes.activeTab : ""}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  General {containerData.children.lenght}
                </NavLink>
              </NavItem>
              {containerData && containerData.children.length !== 0 && (
                <NavItem>
                  <NavLink
                    className={state.activeTab === "2" ? classes.activeTab : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <span style={{color: "#2d6187"}}>Containers</span>{" "}
                  </NavLink>
                </NavItem>
              )}
              {containerData && containerData.datastreams.length !== 0 && (
                <NavItem>
                  <NavLink
                    className={state.activeTab === "3" ? classes.activeTab : ""}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <span style={{color: "#d83a56"}}>Datastreams</span> {"  "}
                    <span>
                      <UncontrolledTooltip target={"info"} placement={"top"}>
                        List of datastreams in current container
                      </UncontrolledTooltip>
                      <i className={"bx bx-info-circle"} id="info"></i>
                    </span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          )}
          <TabContent activeTab={state.activeTab}>
            <TabPane tabId="1">
              {data && (
                <div className="pt-3 pb-3">
                  <Row>
                    <Col md="2" xs="12">
                      <div className={classes.conTypeIcon}>
                        <i className="bx bx-folder"></i>
                      </div>
                    </Col>
                    <Col md="10" xs="12">
                      <Row className="align-items-center">
                        <Col md="4" xs="12" className={classes.title}>
                          Name <span className={classes.sign}>:</span>
                        </Col>
                        <Col md="8" xs="12" className={classes.value}>
                          {data?.label}
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col md="4" xs="12" className={classes.title}>
                          Containers <span className={classes.sign}>:</span>
                        </Col>
                        <Col md="8" xs="12" className={classes.value}>
                          {data?.containerCount}
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col md="4" xs="12" className={classes.title}>
                          Datastreams <span className={classes.sign}>:</span>
                        </Col>
                        <Col md="8" xs="12" className={classes.value}>
                          {data?.datastreamCount}
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col md="4" xs="12" className={classes.title}>
                          Total disk space <span className={classes.sign}>:</span>
                        </Col>
                        <Col md="8" xs="12" className={classes.value}>
                          {data?.totalStorageSpaceHR === null ? data?.totalStorageSpace : data?.totalStorageSpaceHR}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
            <TabPane tabId="2">
              <ListGroup variant="flush">
                {containerData &&
                  containerData.children &&
                  containerData.children.map((container, i) => {
                    return (
                      <ListGroupItem key={i}>
                        <a href={`containers/${container.uuid}`} target="_blank" rel="noopener noreferrer">
                          {container.label}
                        </a>{" "}
                        <span style={{float: "right"}}>
                          <Badge style={{background: "#2d6187"}} pill>
                            {container.childCount}
                          </Badge>{" "}
                          <Badge style={{background: "#d83a56"}} pill>
                            {container.datastreamCount}
                          </Badge>
                        </span>
                      </ListGroupItem>
                    );
                  })}
              </ListGroup>
            </TabPane>
            <TabPane tabId="3">
              {/* <Row>                
                <Col>
                    <span style={{float:"right"}}>
                        <UncontrolledTooltip target={"info"} placement={"top"}>
                          List of datastreams in current container
                        </UncontrolledTooltip>
                        <i className={"bx bx-info-circle"} id="info"></i>
                    </span>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <ListGroup variant="flush">
                    {containerData &&
                      containerData.datastreams &&
                      containerData.datastreams.map((datastream, i) => {
                        return <ListGroupItem key={i}>{datastream.filename} </ListGroupItem>;
                      })}
                  </ListGroup>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
});

export default ReportDialog;
