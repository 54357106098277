import {withRouter} from "react-router-dom";
import React from 'react';
import {Button, Col, CustomInput, Row} from "reactstrap";
import {FIELD_TYPE} from "../consts";


const FieldFooter = (props) => {
  const {field, setField, duplicateField, deleteField} = props
  const {required,type} = field

  const setRequired = (e) => {

    // Disallow changing required for type email
    if (field.type===FIELD_TYPE.EMAIL) return

    const value = e.target.checked
    setField({...field, required: value})
  }

  return <>
    <hr/>
      <Row style={{alignItems:'center'}}>
        <Col xs={{size: 12}} md={{size:4,offset:5}} style={{textAlign:'right'}}>
          <Button outline color="primary" onClick={duplicateField}>Duplicate</Button>{' '}
          <Button outline color="danger" onClick={deleteField}>Delete</Button>
        </Col>
        {type!==FIELD_TYPE.LABEL&&<Col sm={{size:3}} style={{borderLeft: '1px solid #cdcdcd'}}>
          <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="Required" checked={required} onChange={setRequired} />
        </Col>}
      </Row>
    </>
}

export default withRouter(FieldFooter);
